import { useMutation as useRelayMutation } from 'react-relay/hooks'

export default function useMutation(query, mutationName, options = {}) {
  const [mutate, isInFlight] = useRelayMutation(query)

  // second param is object for passing params into query variables, which is useful
  // for specifying which data you want returned from the mutation
  const simpleMutate = (input, params = {}) =>
    new Promise((resolve, reject) => {
      mutate({
        variables: { input, ...params },
        onCompleted: (response) => resolve(response[mutationName]),
        onError: (error) => reject(error),
        ...options,
      })
    })

  return [simpleMutate, isInFlight]
}
