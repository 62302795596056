import { ReportSection } from '../../../types'
import { insights } from '../../../constants'
import getAnomalySubsections from './getAnomalySubsections'
import { TableConnected } from 'elements'

const { NumericCell } = TableConnected as any

type Props = {
  anomalyList: ReportSection[]
  configuredAnomalies: any[]
}

export default function getAnomalyReportSection(props: Props): ReportSection {
  const { anomalyList, configuredAnomalies } = props

  return {
    label: 'Transaction Flags',
    value: 'anomalyInsights',
    kind: 'insight',
    dataSections: [
      {
        key: 'anomalyByType',
        title: 'Flags By Type',
        data: configuredAnomalies,
        insights: insights.anomalyByType,
        detailRoute: 'anomaly-by-type',
        table: true,
        columns: [
          { Header: 'Flag Name', accessor: 'anomalyName', disableSortBy: true },
          { Header: 'Count', accessor: 'count', disableSortBy: true, Cell: NumericCell },
        ],
        tableOptions: {
          initialSortColumns: [{ id: 'count', desc: true }],
          pageSize: 3,
          pagination: true,
          highlightedColumn: 'Anomaly Name',
          preview: true,
          cellProps: () => {
            return {
              pt: 'xlarge',
              pb: 'xlarge',
            }
          },
        },
        subReportSections: getAnomalySubsections({
          anomalyList,
          configuredAnomalies,
        }),
      },
    ],
  }
}
