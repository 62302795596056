/**
 * @generated SignedSource<<f47724214e55833ab65b2b9a694c6c25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateReportDataSources_company$data = {
  readonly rowId: any;
  readonly id: string;
  readonly " $fragmentType": "CreateReportDataSources_company";
};
export type CreateReportDataSources_company$key = {
  readonly " $data"?: CreateReportDataSources_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateReportDataSources_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateReportDataSources_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "359ec01f4d047349a58b665b462a010f";

export default node;
