/**
 * @generated SignedSource<<2b5f27ccd10cede9edf96a1b01151a96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserOrganizationRole = "ADMIN" | "MEMBER" | "%future added value";
export type fetchCurrentUserQuery$variables = {};
export type fetchCurrentUserQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly rowId: any;
    readonly isAdmin: boolean;
    readonly name: string | null;
    readonly reportsByOwnerId: {
      readonly totalCount: number;
    };
    readonly userAuthentications: {
      readonly nodes: ReadonlyArray<{
        readonly service: string;
        readonly updatedAt: any;
      }>;
    };
    readonly userEmails: {
      readonly nodes: ReadonlyArray<{
        readonly email: string;
        readonly isPrimary: boolean;
      }>;
    };
    readonly organizationUsers: {
      readonly totalCount: number;
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly role: UserOrganizationRole;
        readonly organization: {
          readonly id: string;
          readonly rowId: any;
          readonly name: string;
          readonly slug: string;
          readonly organizationCompanies: {
            readonly totalCount: number;
            readonly nodes: ReadonlyArray<{
              readonly company: {
                readonly id: string;
              } | null;
            }>;
          };
        } | null;
      }>;
    };
  } | null;
};
export type fetchCurrentUserQuery = {
  variables: fetchCurrentUserQuery$variables;
  response: fetchCurrentUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ReportsConnection",
  "kind": "LinkedField",
  "name": "reportsByOwnerId",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "service",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrimary",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAuthenticationsConnection",
            "kind": "LinkedField",
            "name": "userAuthentications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAuthentication",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmailsConnection",
            "kind": "LinkedField",
            "name": "userEmails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmail",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUsersConnection",
            "kind": "LinkedField",
            "name": "organizationUsers",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "OrganizationCompaniesConnection",
                        "kind": "LinkedField",
                        "name": "organizationCompanies",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationCompany",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "organizationCompanies(first:1)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fetchCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAuthenticationsConnection",
            "kind": "LinkedField",
            "name": "userAuthentications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAuthentication",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmailsConnection",
            "kind": "LinkedField",
            "name": "userEmails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEmail",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUsersConnection",
            "kind": "LinkedField",
            "name": "organizationUsers",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "OrganizationCompaniesConnection",
                        "kind": "LinkedField",
                        "name": "organizationCompanies",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationCompany",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "organizationCompanies(first:1)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf4b0c950c261549df9e76f2ab8651a5",
    "id": null,
    "metadata": {},
    "name": "fetchCurrentUserQuery",
    "operationKind": "query",
    "text": "query fetchCurrentUserQuery {\n  currentUser {\n    id\n    rowId\n    isAdmin\n    name\n    reportsByOwnerId {\n      totalCount\n    }\n    userAuthentications {\n      nodes {\n        service\n        updatedAt\n        id\n      }\n    }\n    userEmails {\n      nodes {\n        email\n        isPrimary\n        id\n      }\n    }\n    organizationUsers {\n      totalCount\n      nodes {\n        id\n        role\n        organization {\n          id\n          rowId\n          name\n          slug\n          organizationCompanies(first: 1) {\n            totalCount\n            nodes {\n              company {\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c76f3a0e2302ca662d273ab5162494e";

export default node;
