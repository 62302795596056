import React, { useState } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import IntegrationBox from './IntegrationBox'
import { Text, FlexColumn, FlexRow, Button, TextInput } from 'elements'
import stripeLogo from 'theme/images/stripe-logo.svg'
import { useAuth } from 'hooks'
import useStripeIntegrationCreate from './hooks/useStripeIntegrationCreate'

type Props = {
  company: any
  onDelete: Function
}

export default function StripeIntegration(props: Props) {
  const { company, onDelete } = props
  const { currentCompany } = useAuth()
  const [createStripeIntegration, createInFlight]: any = useStripeIntegrationCreate()

  const [stripeApiKey, setStripeApiKey] = useState<string | null>(null)

  const data = useFragment(
    graphql`
      fragment StripeIntegration_company on Company
        @refetchable(queryName: "StripeIntegrationRefetchQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "Cursor" }
        ) {
        integrations(first: $count, after: $cursor)
          @connection(key: "StripeIntegration_integrations") {
          edges {
            node {
              id
              rowId
              kind
              status
              config
            }
          }
        }
      }
    `,
    company,
  )

  const [stripeIntegration] = data.integrations.edges
    .filter((edge: any) => edge.node.kind === 'stripe')
    .map((edge: any) => edge.node)

  const handleConnect = () =>
    createStripeIntegration({ apiKey: stripeApiKey, companyId: currentCompany!.id })

  return (
    <FlexColumn>
      <FlexRow gap="xxlarge" flexWrap="wrap">
        {stripeIntegration ? (
          <IntegrationBox
            userIntegration={stripeIntegration}
            logo={stripeLogo}
            logoWidth="75%"
            onDelete={() => onDelete(stripeIntegration)}
            integrationKind="stripe"
          />
        ) : (
          <FlexColumn>
            <Text fontSize="xxlarge" fontWeight="bold" mb="xxxlarge">
              Stripe Integration
            </Text>
            <FlexRow alignItems="center" mb="xlarge">
              <Text fontSize="large">Stripe API Key:</Text>
              <TextInput onChange={(e: any) => setStripeApiKey(e.target.value)} />
            </FlexRow>
            <Button onClick={handleConnect} disabled={createInFlight}>
              {createInFlight ? 'Connecting...' : 'Connect'}
            </Button>
          </FlexColumn>
        )}
      </FlexRow>
    </FlexColumn>
  )
}
