import React, { ReactElement } from 'react'
// @ts-ignore
import ReactDatePicker from 'react-datepicker'
import { createPortal } from 'react-dom'
import TextInput from '../TextInput'

const toDate = (value: string | Date) => {
  if (!value || value instanceof Date) {
    return value
  }

  return new Date(value)
}

type Props = {
  value: string | Date | null
  placeholder: string
  onChange: Function
  styleProps?: Record<string, any>
  customInput?: ReactElement
  disabled?: boolean
}

const DatePicker = (props: Props & any) => {
  const { value, placeholder, onChange, customInput } = props

  const selectedDate = value ? toDate(value) : null

  return (
    <ReactDatePicker
      {...props}
      autoComplete="off"
      todayButton="Today"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={selectedDate}
      placeholderText={placeholder}
      customInput={customInput || <TextInput {...props} />}
      onChange={onChange}
      popperContainer={({ children }: any) => createPortal(children, document.body)}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: true,
          boundariesElement: document.querySelector('.ReactModal__Content'),
        },
      }}
    />
  )
}

DatePicker.Field = ({
  value,
  placeholder,
  minDate,
  onChange,
  onBlur,
  onFocus,
  touched,
  error,
  ...props
}: Props & Record<string, any>) => {
  return (
    <DatePicker
      value={value}
      // @ts-ignore
      placeholderText={placeholder}
      minDate={minDate}
      customInput={<TextInput {...props} kind={touched && error ? 'warning' : undefined} />}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

export default DatePicker
