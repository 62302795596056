import React from 'react'
import { Modal, ModalNavHeader, FlexRow, Button, FlexColumn } from 'elements'
import { useAuth } from 'hooks'
import ChoiceButton from 'components/ui/input/ChoiceButton'

type Props = {
  modal: any
  selected: string
  onChange: Function
  onNext: Function
  onBack?: any
}

export default function SelectUploadSource(props: Props) {
  const { modal, selected, onChange, onNext, onBack } = props

  const { currentCompany } = useAuth()

  // this loads at the start so should be available first thing,
  // but sometimes with deep linking this modal tries to open before
  // the current company has been loaded
  if (!currentCompany) {
    return null
  }

  const modalActions = [
    {
      label: 'Help',
      value: 'help',
      onSelect: () => window.open('https://scrutinize.io/howitworks', '_blank'),
    },
  ]

  return (
    <>
      <ModalNavHeader
        onClose={modal.onClose}
        onBack={onBack}
        actionOptions={modalActions}
        titleText="Select file source"
      />
      <FlexColumn p="xxlarge" pt="large" pb="xgigantic">
        <FlexRow mb="large">
          <ChoiceButton
            icon="document-add"
            selected={selected === 'qbo'}
            onPress={() => onChange('qbo')}
          >
            QuickBooks® Online
          </ChoiceButton>
        </FlexRow>

        <FlexRow mb="large">
          <ChoiceButton
            icon="document-add"
            selected={selected === 'desktop'}
            onPress={() => onChange('desktop')}
          >
            QuickBooks® Desktop
          </ChoiceButton>
        </FlexRow>
      </FlexColumn>
      {/* @ts-ignore */}
      <Modal.Footer display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Button onPress={modal.onClose}>Cancel</Button>
        <Button kind="primary" onPress={onNext}>
          Continue
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
