import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useReportRun() {
  return useMutation(
    graphql`
      mutation useReportRunMutation($input: RunReportInput!) {
        runReport(input: $input) {
          success
        }
      }
    `,
    'runReport',
  )
}
