import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedDate } from 'react-intl'
import { Text, FlexRow, Anchor, Icon } from 'elements'
import { useAuth } from 'hooks'
import AppBanner from '../AppBanner'
import { TRIAL_NOTICE_THRESHOLD, TRIAL_WARNING_THRESHOLD } from '../../../utils/trial'
import { calculateDaysSince } from 'utils/date'

const DEFAULT_ANCHOR_MESSAGE = 'upgrade your account.'

export default function SubscriptionBanner() {
  const history = useHistory()
  const { currentOrg, freeTrial, trialDaysRemaining } = useAuth()

  // @ts-ignore
  const orgSubscription = currentOrg?.organizationSubscriptions?.nodes?.[0]

  const aboveThreshold = trialDaysRemaining > TRIAL_NOTICE_THRESHOLD
  const healthySubscription =
    orgSubscription && !orgSubscription.endsAt && !orgSubscription.deletedAt
  const customBillingPlan = orgSubscription && orgSubscription.billingPlan.slug !== 'team-monthly'

  // don't display banner for enterprise plans
  if (!currentOrg || aboveThreshold || healthySubscription || customBillingPlan) {
    return null
  }

  let message: any = ''
  let anchorMessage = DEFAULT_ANCHOR_MESSAGE
  let kind = 'neutral'

  if (orgSubscription) {
    const { endsAt, deletedAt } = orgSubscription

    if (deletedAt && !freeTrial) {
      kind = 'danger'

      const daysSinceEnd = calculateDaysSince(deletedAt)

      if (daysSinceEnd === 0) {
        message = 'Your subscription expired today. To enable your existing clients,'
      } else if (daysSinceEnd === 1) {
        message = `Your subscription expired ${daysSinceEnd} day ago. To enable your existing clients,`
      } else {
        message = `Your subscription expired ${daysSinceEnd} days ago. To enable your existing clients,`
      }
    } else if (endsAt) {
      kind = 'warning'
      message = (
        <Text color="inherit">
          Your subscription will end on <FormattedDate value={endsAt} />.
        </Text>
      )
      anchorMessage = 'Click here to renew.'
    }

    // some trial days remaining
  } else if (trialDaysRemaining >= 0) {
    if (trialDaysRemaining <= TRIAL_WARNING_THRESHOLD) {
      kind = 'warning'
    } else {
      kind = 'neutral'
    }

    if (trialDaysRemaining === 0) {
      message = `Your trial expires today. To enable your existing clients,`
    } else {
      message = `Your trial expires in ${trialDaysRemaining} days. To enable your existing clients,`
    }

    // no more trial
  } else {
    kind = 'danger'

    const expDays = Math.abs(trialDaysRemaining)

    if (expDays === 1) {
      message = `Your trial expired ${expDays} day ago. To enable your existing clients,`
    } else {
      message = `Your trial expired ${expDays} days ago. To enable your existing clients,`
    }
  }

  return (
    <AppBanner kind={kind}>
      <FlexRow>
        {typeof message === 'string' ? <Text color="inherit">{message}</Text> : message}
        <Anchor
          onClick={() => history.push(`/orgs/${currentOrg.slug}/settings/billing`)}
          color="inherit"
        >
          <FlexRow alignItems="center" ml="xsmall" color="inherit">
            <Text color="inherit" textDecoration="underline">
              {anchorMessage}
            </Text>
            <Icon name="arrow-right" ml="xsmall" size="xsmall" color="inherit" />
          </FlexRow>
        </Anchor>
      </FlexRow>
    </AppBanner>
  )
}
