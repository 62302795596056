import React, { useCallback, useState } from 'react'
import { PageContext } from 'contexts'
import { ResolutionStatus } from '../components/report/types'

export default function PageProvider({ children }: any) {
  const [pageProps, setPageProps] = useState({
    resolutionSection: 'forReview' as ResolutionStatus,
    resolutionAnomalyView: 'all',
  })

  const mergePageProps = useCallback(
    (values: Record<string, any>) => {
      setPageProps((previous: any) => ({ ...previous, ...values }))
    },
    [setPageProps],
  )

  return (
    <PageContext.Provider
      value={{
        pageProps,
        setPageProps: mergePageProps,
        // deprecated
        header: pageProps,
        setHeader: mergePageProps,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}
