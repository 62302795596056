import React from 'react'
import { useHistory } from 'react-router-dom'
import { FlexRow, FlexColumn, Box, Text, Image, Card, Button } from 'elements'
import gearsImg from 'theme/images/gears-graphic.svg'

type Props = {
  errorMessage?: string
}

const DEFAULT_ERROR_MESSAGE = "We couldn't find that page."

export default function NotFound(props: Props) {
  const { errorMessage = DEFAULT_ERROR_MESSAGE } = props
  const history = useHistory()

  return (
    <FlexRow justifyContent="center" alignItems="center" width="100%" height="100%">
      <Card
        position="relative"
        width="1157px"
        height="610px"
        overflow="hidden"
        justifyContent="center"
        p="xxxlarge"
      >
        <FlexColumn alignItems="center">
          <Text fontSize="xxlarge" fontWeight="bold" mb="large">
            404!
          </Text>
          <Text mb="xlarge">{errorMessage}</Text>
          <FlexRow justifyContent="space-between" zIndex="1">
            <Button kind="secondary" mr="medium" onClick={() => history.push('/')}>
              Return Home
            </Button>
            <Button
              kind="alt-neutral-2"
              as="a"
              target="_blank"
              href="https://scrutinize.freshdesk.com/support/home"
            >
              Contact Support
            </Button>
          </FlexRow>
        </FlexColumn>
        <Box position="absolute" top="gigantic" right="72px">
          <Image src={gearsImg} width="1450px" />
        </Box>
      </Card>
    </FlexRow>
  )
}
