import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Scrolls the element to the top left any time the location changes
 */
export default function ResetScroll({ current }) {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (current) {
        current.scrollTop = 0
        current.scrollLeft = 0
      }
    })

    return () => {
      unlisten()
    }
  }, [current, history])

  return null
}
