import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export default function IndexRedirect({ path, to }) {
  return (
    <Route
      exact
      path={path}
      render={({ match }) => {
        const path = [match.url, to].join('/').replace(/\/+/g, '/')
        return <Redirect to={path} />
      }}
    />
  )
}
