import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useRetryReportWithIntegration() {
  return useMutation(
    graphql`
      mutation useRetryReportWithIntegrationMutation($input: RetryReportWithIntegrationInput!) {
        retryReportWithIntegration(input: $input) {
          report {
            status
          }
        }
      }
    `,
    'retryReportWithIntegration',
  )
}
