import { get } from 'lodash'
import theme from 'theme'

const optionColor = (state) => {
  if (state.isSelected) {
    return {
      fontWeight: 'bold',
      backgroundColor: theme.colors.foreground[2],
    }
  }

  if (state.isFocused) {
    return {
      backgroundColor: theme.colors.foreground[2],
    }
  }

  if (state.isDisabled) {
    return { backgroundColor: 'transparent', color: theme.colors.neutral[0] }
  }

  return { backgroundColor: 'transparent' }
}

const borderColor = ({ isFocused, isOpen, isDisabled, kind }) => {
  if (isFocused || isOpen) {
    return theme.colors.secondary[0]
  }

  if (kind) {
    return get(theme.colors, [kind, '0'])
  }

  return theme.colors.foreground[3]
}

export const defaultStyles = (props) => ({
  control: (provided, state) => ({
    ...provided,
    border: `2px solid`,
    borderRadius: theme.radii.small,
    borderColor: `${borderColor({
      isFocused: state.isFocused,
      isOpen: state.selectProps.menuIsOpen,
      isDisabled: state.isDisabled,
      ...props,
    })} !important;`,
    backgroundColor: theme.colors.background[0],
    boxShadow: 'none',
    minHeight: 42,
    opacity: state.isDisabled ? get(theme, 'global.control.disabled.opacity', 0.5) : 1,
    ...props?.control,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '6px',
    color: state.isFocused || state.selectProps.menuIsOpen ? theme.colors.primary[0] : 'inherit',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    color: theme.colors.foreground[1],
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.foreground[3],
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: '0 6px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: theme.colors.foreground[1],
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#ebf5ff',
    border: '1px solid #c2e0ff',
    borderRadius: 0,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#007eff',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    marginLeft: 3,
    borderLeft: '1px solid #c2e0ff',
    color: '#007eff',
    ':hover': {
      ...provided[':hover'],
      backgroundColor: '#d8eafd',
      color: '#0071e6',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: 2,
    borderTop: 'none',
    boxShadow: theme.shadows.high,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: theme.space.medium,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  groupHeading: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    fontSize: theme.fontSizes.medium,
    color: theme.colors.foreground[2],
    textTransform: 'none',
    marginBottom: theme.space.medium,
  }),
  group: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: theme.space.xxsmall,
    fontSize: theme.fontSizes.medium,
    borderRadius: theme.radii.xsmall,
    color: theme.colors.foreground[1],
    ':active': {
      ...provided[':active'],
      backgroundColor: theme.colors.foreground[4],
      fontWeight: theme.fontWeights.bold,
    },
    cursor: props.interactive && props.interactive(provided, state) && 'pointer',
    ...optionColor(state),
  }),
})
