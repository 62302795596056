import { kebabCase } from 'lodash'
import { Column, ReportSection, DataSection } from '../../../types'
import { headerMap, insightDescriptions, insights } from '../../../constants'
import { TableConnected } from '../../../../../elements'

// @ts-ignore
const { NumericCell, LedgerCell, SeverityCell } = TableConnected

type AnomalySubSectionProps = {
  configuredAnomalies: any[]
  anomalyList: ReportSection[]
}

export default function getAnomalySubsections(props: AnomalySubSectionProps): ReportSection[] {
  const { configuredAnomalies, anomalyList } = props

  const anomalyColumns: Column[] = [
    { Header: 'Flag Name', accessor: 'anomalyName' },
    {
      Header: 'Count',
      accessor: 'count',
      Cell: NumericCell,
      sortDescFirst: true,
    },
    { Header: 'Amount', accessor: 'amount', Cell: LedgerCell, sortDescFirst: true },
  ]

  const hasSeverities = configuredAnomalies.some((anomaly) => anomaly.severity)
  if (hasSeverities) {
    anomalyColumns.unshift({
      id: 'severity',
      Header: 'Severity',
      accessor: 'severity',
      Cell: SeverityCell,
      width: '15px',
      centerSortButton: true,
    })
  }

  const anomalyByTypeDataSections: DataSection[] = [
    {
      key: 'anomalyCountByTypeDescription',
      title: 'Description',
      description: insightDescriptions.anomalyByType,
      gridColumn: '1',
    },
    {
      key: 'anomalyCountByTypeInsights',
      title: 'Insights',
      insights: insights.anomalyByType,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      key: 'anomalyCountByTypeTable',
      gridRow: '2',
      data: configuredAnomalies,
      columns: anomalyColumns,
      table: true,
      tableOptions: {
        highlightedColumn: headerMap.anomalyName,
        initialSortColumns: [{ id: 'severity', desc: true }],
        rowRoute: (row) => kebabCase(row.original.anomalyName),
      },
      subReportSections: anomalyList,
    },
  ]

  return [
    {
      label: 'Flags by Type',
      value: 'anomalyByType',
      route: 'anomaly-by-type',
      kind: 'insight',
      dataSections: anomalyByTypeDataSections,
    },
  ]
}
