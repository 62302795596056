/**
 * @generated SignedSource<<ab55923049f97201e2aac2356456e804>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompaniesOrderBy = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DELETED_AT_ASC" | "DELETED_AT_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_PERIOD_END_ASC" | "LATEST_PERIOD_END_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "ORGANIZATION_ID_ASC" | "ORGANIZATION_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type IncludeDeletedOption = "EXCLUSIVELY" | "INHERIT" | "NO" | "YES" | "%future added value";
export type ModulesCompaniesTablePaginationQuery$variables = {
  count?: number | null;
  cursor?: any | null;
  includeDeleted?: IncludeDeletedOption | null;
  order?: ReadonlyArray<CompaniesOrderBy> | null;
  id: string;
};
export type ModulesCompaniesTablePaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ModulesCompaniesTable_organization">;
  } | null;
};
export type ModulesCompaniesTablePaginationQuery = {
  variables: ModulesCompaniesTablePaginationQuery$variables;
  response: ModulesCompaniesTablePaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": "NO",
  "kind": "LocalArgument",
  "name": "includeDeleted"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "includeDeleted",
  "variableName": "includeDeleted"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/),
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModulesCompaniesTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ModulesCompaniesTable_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModulesCompaniesTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "CompaniesConnection",
                "kind": "LinkedField",
                "name": "companies",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompaniesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deletedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "legalName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [
                  "orderBy",
                  "includeDeleted"
                ],
                "handle": "connection",
                "key": "CompaniesTable_companies",
                "kind": "LinkedHandle",
                "name": "companies"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4734f3cbe5a096d5f48965c3e675459",
    "id": null,
    "metadata": {},
    "name": "ModulesCompaniesTablePaginationQuery",
    "operationKind": "query",
    "text": "query ModulesCompaniesTablePaginationQuery(\n  $count: Int = 10\n  $cursor: Cursor\n  $includeDeleted: IncludeDeletedOption = NO\n  $order: [CompaniesOrderBy!]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ModulesCompaniesTable_organization_3cI7li\n    id\n  }\n}\n\nfragment ModulesCompaniesTableRow_company on Company {\n  id\n  rowId\n  name\n  legalName\n  createdAt\n}\n\nfragment ModulesCompaniesTable_organization_3cI7li on Organization {\n  slug\n  companies(after: $cursor, first: $count, orderBy: $order, includeDeleted: $includeDeleted) {\n    edges {\n      node {\n        id\n        deletedAt\n        ...ModulesCompaniesTableRow_company\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "6de3b7abd117d47992668b1ecb3d8a61";

export default node;
