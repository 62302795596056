import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useStripeIntegrationCreateMutation($input: CreateStripeIntegrationInput!) {
    createStripeIntegration(input: $input) {
      integration {
        id
        company {
          ...StripeIntegration_company
        }
      }
    }
  }
`

export default function useStripeIntegrationCreate() {
  return useMutation(mutation, 'createStripeIntegration')
}
