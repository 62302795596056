import React, { useCallback } from 'react'
import { Text, FlexColumn, FlexRow, Icon, Button, SuspenseBoundary } from '../../elements'
import { map, last, camelCase } from 'lodash'
import { Formik } from 'formik'
import theme from 'theme'
import { usePage } from 'hooks'
import { StepBox } from './WorkflowConfiguration'
import { SectionUpdateFunction, SectionCreateFunction, SectionDeleteFunction } from './types'

const CustomQueryForm = React.lazy(() => import('./CustomQueryForm'))

type Props = {
  stateTemplate: Record<string, any>
  onSectionUpdate: SectionUpdateFunction
  onSectionCreate: SectionCreateFunction
  onSectionDelete: SectionDeleteFunction
  onSave: Function
  working: boolean
  editing: boolean
  onEditing: Function
  sectionKey: string | null
  onSectionKeyChange: Function
  onNewCustomQuery: () => void
}

export default function TransactionFlags(props: Props) {
  const {
    stateTemplate,
    onSectionUpdate,
    onNewCustomQuery,
    onSectionDelete,
    onSave,
    working,
    editing,
    onEditing,
    sectionKey,
    onSectionKeyChange,
  } = props

  const { setPageProps } = usePage()

  const flags =
    stateTemplate.config.workspaces.transactionRepair?.steps?.customTransactionFlags?.sections || {}

  const existingFlagNames = map(flags, (flag) => flag.name)

  const currentFlag = sectionKey ? flags[sectionKey] : null

  const validate = useCallback(
    (values: Record<string, any>) => {
      const errors: Record<string, any> = {}

      const duplicateName =
        existingFlagNames.includes(values.customQueryName) &&
        currentFlag.name !== values.customQueryName

      // creating a new query defaults to the name "New Query", so we can't allow that name
      if (duplicateName || values.customQueryName === 'New Query') {
        errors.customQueryName = 'Please choose a unique query name'
      }

      return errors
    },
    [existingFlagNames, currentFlag?.name],
  )

  const handleSubmit = useCallback(
    async (values: Record<string, any>, { setSubmitting, setFieldError }: Record<string, any>) => {
      const { customQueryName, customQueryDescription, severity, customQueryRules } = values

      try {
        const newSectionKey = camelCase(customQueryName)

        const newTemplate = onSectionUpdate({
          sectionKey: sectionKey!,
          values: {
            name: customQueryName,
            description: customQueryDescription,
            config: { severity },
            queryRules: customQueryRules,
          },
          newSectionKey,
        })

        await onSave(newTemplate?.config, sectionKey)

        onSectionKeyChange(newSectionKey)

        setSubmitting(false)
      } catch (err) {
        setFieldError('general', 'Error updating section')
        setSubmitting(false)
      }
    },
    [onSectionUpdate, onSave, sectionKey, onSectionKeyChange],
  )

  return (
    <>
      <FlexColumn
        flex="1"
        borderRight="solid 1px"
        borderColor="neutral.1"
        py="large"
        px="xxlarge"
        float="left"
        overflow="auto"
      >
        <FlexRow mb="medium" justifyContent="space-between" alignItems="center" width="100%">
          <Button
            onClick={() => {
              if (!editing || window.confirm('You have unsaved changes. Exit anyway?')) {
                setPageProps({ configurationSection: 'workflows' })
              }
            }}
          >
            <FlexRow alignItems="center">
              <Icon name="arrow-left" mr="small" />
              <Text>{`Back to ${stateTemplate.name}`}</Text>
            </FlexRow>
          </Button>
          <FlexColumn pl="xlarge">
            <FlexRow height="100%">
              <Button
                height="100%"
                onClick={() => {
                  if (!editing || window.confirm('You have unsaved changes. Exit anyway?')) {
                    onNewCustomQuery()
                  }
                }}
              >
                New Query
              </Button>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
        {map(flags, (flagData, key) => {
          const isLast = last(Object.keys(flags)) === key

          return (
            <StepBox
              key={key}
              onClick={() => onSectionKeyChange(key)}
              py="medium"
              px="medium"
              ml="xsmall"
              style={{
                backgroundColor: sectionKey === key && theme.colors.background[3],
              }}
            >
              <Text mb="small" fontWeight="bold">
                {flagData.name}
              </Text>
              <Text mb={isLast && 'xsmall'}>{flagData.description}</Text>
            </StepBox>
          )
        })}
      </FlexColumn>
      <FlexColumn flex="1.5" pt="xxxlarge" pb="xgigantic" px="xxlarge" float="left" overflow="auto">
        {currentFlag && (
          <Formik
            initialValues={{
              customQueryName: currentFlag.name,
              customQueryDescription: currentFlag.description,
              severity: currentFlag.config.severity,
              customQueryRules: currentFlag.queryRules,
            }}
            onSubmit={handleSubmit}
            validate={validate}
            enableReinitialize
          >
            <SuspenseBoundary>
              <CustomQueryForm
                working={working}
                stateTemplate={stateTemplate}
                customFlagKey={sectionKey!}
                onDirty={onEditing}
                onSectionDelete={onSectionDelete}
              />
            </SuspenseBoundary>
          </Formik>
        )}
      </FlexColumn>
    </>
  )
}
