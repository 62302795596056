import React from 'react'
import { useDecision } from '@optimizely/react-sdk'
import { FlexColumn, Text, FlexRow } from 'elements'
import Header from '../app/Header'
import { useAuth } from 'hooks'

export default function ModulesOrganizationHeader() {
  const { currentOrg } = useAuth()
  const canViewIds = useDecision('canViewIds')[0].enabled

  return (
    <FlexColumn>
      <Header>
        <FlexRow alignItems="center">
          <Text fontWeight="bold" fontSize="large" mr={canViewIds && 'large'}>
            {currentOrg?.name}
          </Text>
          {canViewIds && <Text>{currentOrg?.rowId}</Text>}
        </FlexRow>
      </Header>
    </FlexColumn>
  )
}
