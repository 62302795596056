import { camelCase, kebabCase, compact, last, isNil } from 'lodash'
import { DateTime } from 'luxon'
import {
  DataSection,
  AccountBalanceReport,
  ReportSection,
  Align,
  AccountBalances,
} from '../../../types'
import { ConfigSection } from '../../../../../shared/types'
import { TableConnected } from 'elements'
import { getAnomalyDataSections } from '../../../utils/anomalies'
import { formatAnomalyForExport } from '../../../export/formatters'
import { findObjectsWithProperty } from '../../../../../utils/object'
import { drilldownReportSection } from './constants'

const { LedgerCell, PercentageCell, ExpandSubRowsHeader } = TableConnected as any

type GetDataSectionsProps = {
  report: AccountBalanceReport
  configSections?: ConfigSection[]
  context: 'table' | 'export'
}

type DepthSection = {
  name: string
  sectionDepth: number
}

// stick after padding
const TABLE_STICKY_LEFT = '-32px'

const BALANCE_REPORTS = ['Balance Sheet Variance', 'Profit and Loss Variance']

// loop through account sections and assign depth
const getSectionDepths = (data: AccountBalances[]): DepthSection[] => {
  const accountSections: DepthSection[] = []

  let sectionDepth = 0

  data.forEach((row, idx) => {
    const previousRow = data[idx - 1]

    if (previousRow?.accountSection) {
      sectionDepth++
    }

    if (previousRow?.summary && sectionDepth > 0) {
      sectionDepth = sectionDepth - 1
    }

    if (row.accountSection) {
      accountSections.push({ name: row.accountSection, sectionDepth })
    }
  })

  return accountSections
}

// loop backwards through the array of account sections to find the last one with the correct depth (one higher)
const findPreviousParent = (currentParentSection: string, accountSections: DepthSection[]) => {
  const currentParent = accountSections.find((section) => section.name === currentParentSection)
  const sectionIdx = accountSections.indexOf(currentParent!)

  let previousParent: string | null = null
  let idx = sectionIdx - 1

  while (!previousParent && idx >= 0) {
    if (accountSections[idx]?.sectionDepth === currentParent!.sectionDepth - 1) {
      previousParent = accountSections[idx]?.name
    }

    idx = idx - 1
  }

  return previousParent
}

const getBalanceReportData = (report: AccountBalanceReport) => {
  const accountSections = getSectionDepths(report.data)

  const finalData: any[] = []
  let parentSection: string | null = null

  report.data!.forEach((row, idx) => {
    const previousRow = report.data![idx - 1]

    if (previousRow?.accountSection) {
      parentSection = previousRow.accountSection
    }

    if (previousRow?.summary) {
      parentSection = findPreviousParent(parentSection!, accountSections)
    }

    // populate row
    const finalRow: any = { ...row }

    // populate section header row
    if (row.accountSection) {
      finalRow.subRows = []
    } else {
      // populate data row
      finalRow.account = row.accountName

      for (const month of row.balanceByMonth!) {
        finalRow[`${month.monthName}-current`] = month.currentBalance
        finalRow[`${month.monthName}-previous`] = month.previousBalance
        finalRow[`${month.monthName}-balanceChange`] = month.balanceChange
        finalRow[`${month.monthName}-percentChange`] = month.ratioChange
      }
    }

    // determine where to put row
    if (!parentSection) {
      finalData.push(finalRow)
      return
    }

    // locate the account section with the given name. In case of accounts or sub-accounts with duplicate names,
    // take the last one to get the correct parent
    const parentObj = last(
      findObjectsWithProperty({
        object: finalData,
        key: 'accountSection',
        value: parentSection,
      }),
    )

    if (parentObj) {
      parentObj.subRows.push(finalRow)
    }
  })

  return finalData
}

type GetTableOptionsProps = {
  report: AccountBalanceReport
  configSections?: ConfigSection[]
}

function getBalanceReportTableOptions(props: GetTableOptionsProps) {
  const { report, configSections } = props

  const config = configSections?.find((section) => section.name === camelCase(report.reportName))
    ?.config

  return {
    startExpanded: true,
    cellProps: (cell: any) => {
      const { row, column } = cell
      const parentMonth = column.parent?.Header

      const props: any = {}

      if (!parentMonth?.length) {
        return props
      }

      // bold balance and change cells if over threshold
      if (config) {
        const { balanceChange, percentChange } = config

        const monthBalanceChange = row.original[`${parentMonth}-balanceChange`]
        const monthPercentChange = row.original[`${parentMonth}-percentChange`]

        if (
          (balanceChange && Math.abs(monthBalanceChange) >= balanceChange) ||
          (percentChange && Math.abs(monthPercentChange) >= percentChange)
        ) {
          props.fontWeight = 'bold'
        }
      }

      const month = DateTime.fromFormat(parentMonth, 'MMM yyyy')
      const accountId = row.original.accountId

      if (month?.isValid && accountId && !isNil(cell.value)) {
        const startDate = month.startOf('month').toISODate()
        const endDate = month.endOf('month').toISODate()

        props.cellRoute = `details?startDate=${startDate}&endDate=${endDate}&accountId=${accountId}`
      }

      return props
    },
    tableProps: { overflow: null },
    includeConfig: false,
  }
}

function getBalanceReportDataSections(props: GetDataSectionsProps): DataSection[] {
  const { report, context, configSections } = props
  const key = camelCase(report.reportName)

  const data = getBalanceReportData(report)

  const sampleMonths = report.data.find((account) => account.balanceByMonth)!.balanceByMonth!

  if (context === 'table') {
    return [
      {
        key,
        table: true,
        data,
        columns: [
          {
            Header: '',
            accessor: 'accountParentColumn',
            width: '350px',
            disableSortBy: true,
            sticky: true,
            stickyPosition: { left: TABLE_STICKY_LEFT },
            columns: [
              {
                id: 'expander',
                accessor: (row: any) => row.account ?? row.accountSection,
                Header: 'Account',
                Cell: ExpandSubRowsHeader,
                sticky: true,
                stickyPosition: { left: TABLE_STICKY_LEFT },
                disableSortBy: true,
                style: {
                  headCell: { borderRight: 'solid 1px' },
                  dataCell: { borderRight: 'solid 1px' },
                },
              },
            ],
            style: { headCell: { borderBottom: 'solid 1px' } },
          },
          ...sampleMonths.map((month: any) => ({
            Header: month.monthName,
            accessor: '',
            align: 'center' as Align,
            disableSortBy: true,
            columns: [
              {
                Header: 'Current',
                accessor: `${month.monthName}-current`,
                disableSortBy: true,
                Cell: LedgerCell,
              },
              {
                Header: month.previousMonthName,
                accessor: `${month.monthName}-previous`,
                disableSortBy: true,
                Cell: LedgerCell,
              },
              {
                Header: '% Change',
                accessor: `${month.monthName}-percentChange`,
                disableSortBy: true,
                Cell: PercentageCell,
                style: { headCell: { borderRight: 'solid 1px' } },
              },
            ],
            width: '400px',
            style: { headCell: { borderBottom: 'solid 1px' } },
          })),
        ],
        tableOptions: getBalanceReportTableOptions({ report, configSections }),
        subReportSections: [drilldownReportSection],
      },
    ]
  }

  //leave out export data for now
  return []
}

type GetDetailReportSectionProps = {
  reports: any[]
  configSections?: ConfigSection[]
  reportEndDate: string
  reportId: string
  integrationId?: string
}
export default function getDetailReportSections(
  props: GetDetailReportSectionProps,
): ReportSection[] {
  const { reports, configSections, reportEndDate, reportId, integrationId } = props

  return reports
    .filter((report) => (report.data?.length || report.transactions?.length) && report.reportName)
    .map((report) => {
      //account balance reports
      if (BALANCE_REPORTS.includes(report.reportName)) {
        return {
          label: report.reportName,
          value: camelCase(report.reportName),
          route: kebabCase(report.reportName),
          kind: 'report',
          dataSections: getBalanceReportDataSections({
            report,
            context: 'table',
            configSections,
          }),
          viewOptions: { disableViewModes: true },
          exportData: [],
        }
      }

      //ar and ap aging
      return {
        label: report.reportName || report.anomalyName,
        value: camelCase(report.reportName || report.anomalyName),
        kind: 'report',
        dataSections: getAnomalyDataSections({
          anomaly: report,
          context: 'table',
          configSections,
          reportEndDate,
          reportId,
          integrationId,
        }),
        exportData: compact([
          formatAnomalyForExport({ anomaly: report, reportEndDate, configSections, reportId }),
        ]),
      }
    })
}
