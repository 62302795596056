import { graphql, fetchQuery } from 'react-relay/hooks'

type Props = {
  environment: any
  companyId: string
  periodStart: string
  periodEnd: string
  workflowTemplateId: string
}

export default async function fetchReport(props: Props): Promise<any> {
  const { environment, companyId, periodStart, periodEnd, workflowTemplateId } = props

  const data = await fetchQuery<any>(
    environment,
    graphql`
      query fetchReportQuery(
        $companyId: ID!
        $periodStart: Date
        $periodEnd: Date
        $workflowTemplateId: UUID
      ) {
        companyById(id: $companyId) {
          reports(
            condition: {
              periodStart: $periodStart
              periodEnd: $periodEnd
              workflowTemplateId: $workflowTemplateId
            }
            orderBy: CREATED_AT_DESC
          ) {
            nodes {
              id
            }
          }
        }
      }
    `,
    {
      companyId,
      periodStart,
      periodEnd,
      workflowTemplateId,
    },
  ).toPromise()

  return data?.companyById?.reports?.nodes?.[0] || null
}
