/**
 * @generated SignedSource<<33dc3d29d8f9cc75ec001557376b20fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateReportModalQuery$variables = {
  compId: string;
};
export type CreateReportModalQuery$data = {
  readonly companyById: {
    readonly integrations: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
        readonly id: string;
        readonly kind: string;
        readonly config: any | null;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CreateReportDataSources_company">;
  } | null;
};
export type CreateReportModalQuery = {
  variables: CreateReportModalQuery$variables;
  response: CreateReportModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "compId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "compId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    }
  ],
  "concreteType": "IntegrationsConnection",
  "kind": "LinkedField",
  "name": "integrations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Integration",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "config",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "integrations(first:100)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateReportModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateReportDataSources_company"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateReportModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83431ef7b6063670f9fb4e6413343bcf",
    "id": null,
    "metadata": {},
    "name": "CreateReportModalQuery",
    "operationKind": "query",
    "text": "query CreateReportModalQuery(\n  $compId: ID!\n) {\n  companyById(id: $compId) {\n    ...CreateReportDataSources_company\n    integrations(first: 100) {\n      nodes {\n        rowId\n        id\n        kind\n        config\n      }\n    }\n    id\n  }\n}\n\nfragment CreateReportDataSources_company on Company {\n  rowId\n  id\n}\n"
  }
};
})();

(node as any).hash = "fbe34feafbdd3bd184a337c9de2fc75c";

export default node;
