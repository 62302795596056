/**
 * @generated SignedSource<<673bf34dc70b25dc47f6555e35b90082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstClientModalQuery$variables = {
  companyId: string;
  orgSlug: string;
};
export type FirstClientModalQuery$data = {
  readonly companyById: {
    readonly id: string;
    readonly organization: {
      readonly slug: string;
    } | null;
    readonly integrations: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly kind: string;
        readonly config: any | null;
        readonly " $fragmentSpreads": FragmentRefs<"FirstClientXeroConnectionChoices_integration">;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CreateClientStep_company" | "SelectDataSources_company">;
  } | null;
  readonly organizationBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"BillingWarning_organization">;
  } | null;
};
export type FirstClientModalQuery = {
  variables: FirstClientModalQuery$variables;
  response: FirstClientModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "orgSlug"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstClientModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateClientStep_company"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectDataSources_company"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationsConnection",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Integration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FirstClientXeroConnectionChoices_integration"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "integrations(first:100)"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BillingWarning_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstClientModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationsConnection",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Integration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "integrations(first:100)"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": "activeCompanies",
            "args": [
              {
                "kind": "Literal",
                "name": "includeDeleted",
                "value": "NO"
              }
            ],
            "concreteType": "CompaniesConnection",
            "kind": "LinkedField",
            "name": "companies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": "companies(includeDeleted:\"NO\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "includeDeleted",
                "value": "YES"
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "UPDATED_AT_DESC"
              }
            ],
            "concreteType": "OrganizationSubscriptionsConnection",
            "kind": "LinkedField",
            "name": "organizationSubscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationSubscription",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPlan",
                    "kind": "LinkedField",
                    "name": "billingPlan",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v3/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "interval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripePriceId",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationSubscriptions(includeDeleted:\"YES\",orderBy:\"UPDATED_AT_DESC\")"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2cbf413d2e9d93f9dce8577759ebfb2",
    "id": null,
    "metadata": {},
    "name": "FirstClientModalQuery",
    "operationKind": "query",
    "text": "query FirstClientModalQuery(\n  $companyId: ID!\n  $orgSlug: String!\n) {\n  companyById(id: $companyId) {\n    id\n    organization {\n      slug\n      id\n    }\n    ...CreateClientStep_company\n    ...SelectDataSources_company\n    integrations(first: 100) {\n      nodes {\n        id\n        kind\n        config\n        ...FirstClientXeroConnectionChoices_integration\n      }\n    }\n  }\n  organizationBySlug(slug: $orgSlug) {\n    ...BillingWarning_organization\n    id\n  }\n}\n\nfragment BillingWarning_organization on Organization {\n  activeCompanies: companies(includeDeleted: NO) {\n    totalCount\n  }\n  organizationSubscriptions(includeDeleted: YES, orderBy: UPDATED_AT_DESC) {\n    nodes {\n      billingPlan {\n        rowId\n        slug\n        name\n        price\n        type\n        interval\n        stripePriceId\n        config\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment CreateClientStep_company on Company {\n  rowId\n  id\n  name\n  integrations(first: 100) {\n    nodes {\n      status\n      id\n    }\n  }\n}\n\nfragment FirstClientXeroConnectionChoices_integration on Integration {\n  rowId\n  config\n}\n\nfragment SelectDataSources_company on Company {\n  rowId\n  id\n}\n"
  }
};
})();

(node as any).hash = "ec4fda7dc8f8f1443c6fd6412fa8d3c4";

export default node;
