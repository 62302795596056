import styled from 'styled-components'
import { print } from '../styles'
import {
  compose,
  space,
  layout,
  typography,
  color,
  background,
  border,
  shadow,
} from 'styled-system'

const DataTableFootCell = styled.td`
  ${print}
  ${compose(
    space,
    typography,
    color,
    layout,
    background,
    border,
    shadow,
  )}

  border-top-right-radius: 0;
  border-top-left-radius: 0;

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
  }

  &:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
  }
`

DataTableFootCell.defaultProps = {
  fontSize: 'medium',
  borderRadius: 'small',
  py: 'medium',
  px: 'xlarge',
  bg: 'secondary.1',
}

export default DataTableFootCell
