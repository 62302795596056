import React, { useState, useEffect } from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import {
  Text,
  FlexColumn,
  Switch,
  FlexRow,
  Box,
  Select,
  Button,
  LinkButton,
  Icon,
  Dropdown,
} from '../../elements'
import { cloneDeep, isEqual, forEach, mapKeys } from 'lodash'
import { useAuth, useModals, useNotifications, usePage } from 'hooks'
import theme from 'theme'
import { formatName } from 'utils/string'
import { anomalyDescriptions, workspaceDescriptions } from '../report/constants'
// @ts-ignore
import styled from 'styled-components'
// @ts-ignore
import css from '@styled-system/css'
import AnomalyEditModal from './AnomalyEditModal'
import useWorkflowTemplateUpdate from './hooks/useWorkflowTemplateUpdate'
import useWorkflowTemplateDuplicate from './hooks/useWorkflowTemplateDuplicate'
import useWorkflowTemplateArchive from './hooks/useWorkflowTemplateArchive'
import ChangeTemplateNameModal from './ChangeTemplateNameModal'
import TransactionFlags from './TransactionFlags'
import { WorkflowTemplate, UpdateSectionProps } from './types'
import { ConfigSection } from '../../shared/types'

export const StepBox = styled(Box)(
  css({
    borderRadius: 'xsmall',
    backgroundColor: 'transparent',
    '.active &, &:hover': {
      backgroundColor: 'background.3',
    },
  }),
)

const ActiveDot = styled(Box)(
  css({
    backgroundColor: theme.colors.secondary[0],
    borderRadius: '50%',
    width: '8px',
    height: '8px',
  }),
)

type Props = {
  organization: any
}

export default function WorkflowConfiguration(props: Props) {
  const { organization } = props

  const { currentOrg } = useAuth()
  const { openModal }: any = useModals()
  const [updateWorkflowTemplate, updateInFlight]: any = useWorkflowTemplateUpdate()
  const [duplicateWorkflowTemplate, duplicateInFlight]: any = useWorkflowTemplateDuplicate()
  const [archiveWorkflowTemplate]: any = useWorkflowTemplateArchive()
  const notifications = useNotifications()
  const { pageProps, setPageProps } = usePage()

  const working = updateInFlight || duplicateInFlight

  const [stateTemplate, setStateTemplate] = useState<WorkflowTemplate | null>(null)
  const [activeWorkspaceKey, setActiveWorkspaceKey] = useState<string | null>('')
  const [activeStepKey, setActiveStepKey] = useState<string | null>('')
  const [editing, setEditing] = useState(false)
  const [sectionKey, setSectionKey] = useState<string | null>(null)

  const data = useFragment<any>(
    graphql`
      fragment WorkflowConfiguration_organization on Organization {
        workflowTemplatesWithDefaults(first: 100, orderBy: CREATED_AT_DESC, includeDeleted: NO)
          @connection(key: "WorkflowConfiguration_workflowTemplatesWithDefaults") {
          edges {
            node {
              id
              name
              config
              organizationId
            }
          }
        }
      }
    `,
    organization,
  )

  const workflowTemplates = data?.workflowTemplatesWithDefaults?.edges?.map(
    (edge: any) => edge.node,
  )

  const dataTemplate = workflowTemplates?.find((template: any) => template.id === stateTemplate?.id)

  const monthEndCloseTemplate = workflowTemplates.find(
    (template: any) => !template.organizationId && template.name === 'Month-End Close',
  )

  const defaultFlagSections: Record<string, any> =
    monthEndCloseTemplate.config.workspaces.transactionRepair.steps.defaultTransactionFlags.sections

  // we want to run this effect whenever you return to the workflows section from the transaction
  // flags section because the form changes editing to true whenever it's dirty
  useEffect(() => {
    if (
      stateTemplate &&
      dataTemplate &&
      pageProps?.configurationSection === 'workflows' &&
      !isEqual(stateTemplate.config, dataTemplate.config)
    ) {
      setEditing(true)
    } else {
      setEditing(false)
    }
  }, [stateTemplate, dataTemplate, pageProps?.configurationSection])

  const workflowChange = (selection: any) => {
    if (!editing || window.confirm('You have unsaved changes. Change workflows anyway?')) {
      setStateTemplate(cloneDeep(selection.value))
      setActiveWorkspaceKey(null)
      setActiveStepKey(null)
    }
  }

  useEffect(() => {
    if (workflowTemplates && !dataTemplate) {
      setStateTemplate(cloneDeep(workflowTemplates[0]))
    }
  }, [workflowTemplates, dataTemplate, setStateTemplate])

  useEffect(() => {
    if (pageProps?.activeWorkspaceKey) {
      setActiveWorkspaceKey(pageProps.activeWorkspaceKey)
    }

    if (pageProps?.activeStepKey) {
      setActiveStepKey(pageProps?.activeStepKey)
    }
  }, [pageProps?.activeWorkspaceKey, pageProps?.activeStepKey])

  if (!data || !stateTemplate || !dataTemplate) {
    return null
  }

  const isDefaultWorkflow = !stateTemplate.organizationId

  const options = workflowTemplates.map((template: any) => {
    return {
      label: template.name,
      value: template,
    }
  })

  const handleStepChange = (stepKey: string, workspaceKey: string) => {
    setActiveStepKey(stepKey)
    setActiveWorkspaceKey(workspaceKey)
  }

  const handleRename = () =>
    openModal(
      ChangeTemplateNameModal,
      {
        workflowTemplateId: stateTemplate.id,
        onDone: async (name: string) =>
          updateWorkflowTemplate({ id: stateTemplate.id, patch: { name } }),
      },
      'full',
    )

  const duplicate = async (name: string) => {
    const { workflowTemplate } = await duplicateWorkflowTemplate({
      id: stateTemplate.id,
      name,
      organizationId: currentOrg!.id,
    })

    return workflowTemplate
  }

  const handleDuplicate = () => {
    const onDone = async (name: string) => {
      const workflowTemplate = await duplicate(name)

      setStateTemplate(
        cloneDeep(
          workflowTemplates.find((dataTemplate: any) => workflowTemplate.id === dataTemplate.id),
        ),
      )
    }

    openModal(
      ChangeTemplateNameModal,
      {
        workflowTemplateId: stateTemplate.id,
        onDone,
        working,
      },
      'full',
    )
  }

  const handleDelete = async () => {
    await archiveWorkflowTemplate({ id: stateTemplate.id })

    notifications.info('Workflow deleted.')
  }

  let saveText = 'Save Changes'

  if (isDefaultWorkflow) {
    saveText = 'Save as New'
  }

  if (working) {
    saveText = 'Saving...'
  }

  const activeStep =
    activeWorkspaceKey && activeStepKey
      ? stateTemplate.config.workspaces[activeWorkspaceKey].steps[activeStepKey]
      : null

  // pass in the template config because in TransactionFlags, we're updating the state and then immediately
  // updating. The state is not up to date yet when calling handleSave
  const handleSave = async (templateConfig: Record<string, any>, sectionKey?: string) => {
    if (isDefaultWorkflow) {
      const onDone = async (name: string) => {
        const workflowTemplate = await duplicate(name)

        await updateWorkflowTemplate({
          id: workflowTemplate.id,
          patch: { config: templateConfig },
        })

        setStateTemplate(
          cloneDeep(
            workflowTemplates.find((dataTemplate: any) => workflowTemplate.id === dataTemplate.id),
          ),
        )
      }

      openModal(
        ChangeTemplateNameModal,
        {
          workflowTemplateId: stateTemplate.id,
          onDone,
          working,
        },
        'full',
      )

      return
    }

    const workflowTemplate = await updateWorkflowTemplate({
      id: stateTemplate.id,
      patch: { config: templateConfig },
    })

    setStateTemplate(cloneDeep(workflowTemplate))

    if (sectionKey) {
      setSectionKey(sectionKey)
    }
  }

  // update section in state
  const handleUpdateSection = (props: UpdateSectionProps) => {
    const { sectionKey, values, newSectionKey } = props

    if (activeStep) {
      const newTemplate = cloneDeep(stateTemplate)

      const step = newTemplate.config.workspaces[activeWorkspaceKey!].steps[activeStepKey!]

      const section = step.sections[sectionKey]

      forEach(values, (val, key) => {
        section[key] = val
      })

      // update section key if we need to, e.g. when changing the name of a custom query
      if (newSectionKey) {
        step.sections = mapKeys(step.sections, (val, key) =>
          key === sectionKey ? newSectionKey : key,
        )
      }

      setStateTemplate(newTemplate)

      return newTemplate
    }

    return null
  }

  // create section in state
  const handleCreateSection = (
    newSectionKey: string,
    values: ConfigSection,
  ): WorkflowTemplate | null => {
    if (activeStep) {
      const newTemplate = cloneDeep(stateTemplate)

      const stepSections =
        newTemplate.config.workspaces[activeWorkspaceKey!].steps[activeStepKey!].sections

      stepSections[newSectionKey] = values

      setStateTemplate(newTemplate)

      return newTemplate
    }

    return null
  }

  const handleDeleteSection = (sectionKey: string) => {
    if (activeStep && window.confirm('Delete this section?')) {
      const newTemplate = cloneDeep(stateTemplate)

      delete newTemplate.config.workspaces[activeWorkspaceKey!].steps[activeStepKey!].sections[
        sectionKey
      ]

      handleSave(newTemplate.config)
    }
  }

  const handleNewCustomQuery = () => {
    // values determined later in the form on the right side
    handleCreateSection('newQuery', {
      name: 'New Query',
      description: '',
      config: { severity: 0 },
      queryRules: {},
      enabled: true,
    })

    setSectionKey('newQuery')
  }

  if (pageProps?.configurationSection === 'transactionFlags') {
    return (
      <TransactionFlags
        stateTemplate={stateTemplate}
        onSectionUpdate={handleUpdateSection}
        onSectionCreate={handleCreateSection}
        onSectionDelete={handleDeleteSection}
        onSave={handleSave}
        working={working}
        editing={editing}
        onEditing={setEditing}
        sectionKey={sectionKey}
        onSectionKeyChange={setSectionKey}
        onNewCustomQuery={handleNewCustomQuery}
      />
    )
  }

  return (
    <>
      <FlexColumn
        flex="2"
        borderRight="solid 1px"
        borderColor="neutral.1"
        py="large"
        px="xxlarge"
        float="left"
        overflow="auto"
      >
        <Text lineHeight="medium" mb="xsmall">
          Select a workflow:
        </Text>
        <FlexRow mb="medium">
          <FlexColumn flex="9">
            {/* @ts-ignore */}
            <Select
              value={{ value: stateTemplate, label: stateTemplate.name }}
              options={options}
              onChange={(selection: any) => workflowChange(selection)}
              styles={{
                option: (provided: any, state: any) => ({
                  ...provided,
                  cursor: 'pointer',
                  backgroundColor: state.isFocused ? theme.colors.background[2] : 'transparent',
                  color: 'foreground.0',
                }),
              }}
            />
          </FlexColumn>
          <FlexColumn flex="3" pl="xlarge">
            <FlexRow height="100%">
              <Button
                kind="primary"
                onClick={() => handleSave(stateTemplate.config)}
                height="100%"
                disabled={!editing}
              >
                {saveText}
              </Button>
              <Box>
                <Dropdown
                  position="bottom right"
                  fontWeight="normal"
                  options={[
                    {
                      label: 'Edit Name',
                      onSelect: handleRename,
                    },
                    {
                      label: 'Duplicate',
                      onSelect: handleDuplicate,
                    },
                    {
                      label: 'Delete',
                      onSelect: handleDelete,
                      kind: 'danger',
                      disabled: isDefaultWorkflow,
                    },
                  ]}
                  stopPropagation
                  renderButton={() => (
                    // @ts-ignore
                    <LinkButton padding={0} border={0}>
                      <FlexRow
                        height="44px"
                        width="44px"
                        alignItems="center"
                        justifyContent="center"
                        bg="background.2"
                        ml="xlarge"
                        borderRadius="small"
                        border={'1px solid ' + theme.colors.neutral[11]}
                      >
                        <Icon size="medium" name="dots-vertical" color={'secondary.2'} />
                      </FlexRow>
                    </LinkButton>
                  )}
                />
              </Box>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
        {Object.entries(stateTemplate.config.workspaces).map(([workspaceKey, workspace]: any) => {
          return (
            <Box key={workspaceKey} ml="medium">
              <Text fontSize="large" fontWeight="bold" py="small" mb="xsmall">
                {workspace.name || formatName(workspaceKey)}
              </Text>
              {Object.entries(workspace.steps).map(([stepKey, step]: any, idx) => {
                const isLast = idx === Object.entries(workspace.steps).length - 1

                return (
                  <StepBox
                    key={stepKey}
                    onClick={() => handleStepChange(stepKey, workspaceKey)}
                    py="medium"
                    px="medium"
                    ml="xsmall"
                    style={{
                      backgroundColor: activeStepKey === stepKey && theme.colors.background[3],
                    }}
                  >
                    <Text mb="small" fontWeight="bold">
                      {step.name}
                    </Text>
                    <Text mb={isLast && 'xsmall'}>{workspaceDescriptions[stepKey]}</Text>
                  </StepBox>
                )
              })}
            </Box>
          )
        })}
      </FlexColumn>
      <FlexColumn flex="2" pt="xxxlarge" pb="xgigantic" px="xxlarge" float="left" overflow="auto">
        {activeStep && (
          <>
            <FlexRow mb="xxlarge" justifyContent="space-between" alignItems="center">
              <Text fontWeight="bold">{activeStep.name} Settings:</Text>
              {activeStep.name === 'Custom Transaction Flags' && (
                <Button
                  onClick={() => {
                    setPageProps({ configurationSection: 'transactionFlags' })
                    handleNewCustomQuery()
                  }}
                >
                  + Add New
                </Button>
              )}
            </FlexRow>
            {activeStep.sections &&
              Object.entries(activeStep!.sections).map(([sectionKey, section]: any) => {
                const { enabled, kind, name } = section

                const defaultSectionConfig = defaultFlagSections[sectionKey]

                // compare anomaly flag config to scrutinize defaults
                const anomalySectionEdited =
                  kind === 'anomaly' && !isEqual(section, defaultSectionConfig)

                return (
                  <FlexRow
                    key={sectionKey}
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    py="small"
                  >
                    <FlexRow alignItems="center">
                      <Text color={enabled ? 'inherit' : 'neutral.2'}>
                        {name || formatName(sectionKey)}
                      </Text>
                      <ActiveDot ml="medium" mt="3px" hidden={!anomalySectionEdited || !enabled} />
                    </FlexRow>
                    <FlexRow alignItems="center">
                      <Switch
                        checked={enabled}
                        onChange={() =>
                          handleUpdateSection({ sectionKey, values: { enabled: !enabled } })
                        }
                      />
                      {kind === 'anomaly' && (
                        // @ts-ignore
                        <LinkButton
                          mx="auto"
                          ml="large"
                          onClick={() =>
                            openModal(
                              AnomalyEditModal,
                              {
                                title: formatName(sectionKey),
                                description: anomalyDescriptions[sectionKey],
                                configuration: section.config,
                                saveChanges: (config: Record<string, any>) =>
                                  handleUpdateSection({ sectionKey, values: { config } }),
                              },
                              'full',
                            )
                          }
                          disabled={!enabled}
                        >
                          <Text
                            fontSize="small"
                            fontWeight="normal"
                            color={enabled ? 'secondary.0' : 'neutral.2'}
                          >
                            Edit
                          </Text>
                        </LinkButton>
                      )}
                    </FlexRow>
                  </FlexRow>
                )
              })}
          </>
        )}
      </FlexColumn>
    </>
  )
}
