import React from 'react'
import { compact, kebabCase, camelCase } from 'lodash'
import { DonutChart, BarLineAreaChart, Table, TableConnected, RadialBarChart } from 'elements'
import {
  headerMap,
  insights,
  insightDescriptions,
  standardColumns,
  subTableCellStyle,
  subTableOptions,
} from '../../../constants'
import theme from '../../../../../theme'
import { ReportSection, Column, DataSection, SourceKind } from '../../../types'
import ReportTable from '../../common/ReportTable'
import { DateTime } from 'luxon'
import { drilldownReportSection } from './constants'

const { Row, Cell } = Table.Data

const { NumericCell, LedgerCell } = TableConnected as any

type Props = {
  formattedSummary: Record<string, any>
  sourceKind?: SourceKind
}

export default function getTransactionReportSection(props: Props): ReportSection {
  const { formattedSummary, sourceKind } = props
  const {
    filteredTransactions,
    formattedBankCCTxnCounts,
    transactionCountByBankCCAccount,
    sortedTxnsByMonth,
    lastReconciledData,
    bankRules,
    bankRuleScore,
  } = formattedSummary

  const txnByAccountTypeDataSections = [
    {
      description: insightDescriptions.transactionsByAccountType,
      gridColumn: '1',
      key: 'transactionByAccountTypeDescription',
      title: 'Description',
    },
    {
      insights: insights.transactionsByAccountType,
      gridRow: '1',
      gridColumn: '2',
      key: 'transactionByAccountTypeInsights',
      title: 'Insights',
    },
    {
      gridColumn: '1',
      gridRow: '2',
      key: 'transactionByAccountTypeChart',
      Chart: DonutChart,
      chartOptions: { legendTitle: 'Top 5 Account Types' },
      data: filteredTransactions.byAccountType
        .slice(0, 5)
        .map((t: any) => ({ name: t.accountType, data: t.totalTransactionCount })),
    },
    {
      key: 'transactionByAccountTypeTable',
      table: true,
      gridRow: '3',
      data: filteredTransactions.byAccountType,
      tableOptions: {
        highlightedColumn: headerMap.accountType,
        initialSortColumns: [{ id: 'totalTransactionCount', desc: true }],
        rowRoute:
          sourceKind && !sourceKind.includes('xlsx')
            ? (row: any) => `details?accountType=${row.original.accountType}`
            : null,
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const lastReconciledDataSections = [
    {
      description: insightDescriptions.lastReconciledTransactionDate,
      gridColumn: '1',
      key: 'lastReconciledTransactionDateDescription',
      title: 'Description',
    },
    {
      insights: insights.lastReconciledTransactionDate,
      gridRow: '1',
      gridColumn: '2',
      key: 'lastReconciledTransactionDateInsights',
      title: 'Insights',
    },
    {
      key: 'lastReconciledTransactionDateTable',
      gridRow: '2',
      table: true,
      data: lastReconciledData,
      tableOptions: {
        highlightedColumn: headerMap.accountName,
        initialSortColumns: [{ id: 'daysElapsed', desc: true }],
      },
    },
  ]

  const txnByTypeColumns: Column[] = [
    {
      Header: 'Type',
      accessor: 'transactionType',
      width: '200px',
    },
    {
      Header: 'Line Items',
      accessor: 'totalLines',
      sortType: 'basic',
      width: '125px',
      Cell: NumericCell,
      sortDescFirst: true,
    },
    { Header: 'Count', accessor: 'count', width: '125px', sortDescFirst: true, Cell: NumericCell },
    {
      Header: 'Amount',
      accessor: (row: any) => row.amount ?? row.transactionAmount,
      Cell: LedgerCell,
      sortDescFirst: true,
    },
  ]

  const txnByTypeDataSections = [
    {
      description: insightDescriptions.transactionByType,
      gridColumn: '1',
      key: 'transactionByTypeDescription',
      title: 'Description',
    },
    {
      insights: insights.transactionsByType,
      gridRow: '1',
      gridColumn: '2',
      key: 'transactionByTypeInsights',
      title: 'Insights',
    },
    {
      key: 'transactionByTypeTable',
      gridRow: '2',
      table: true,
      data: filteredTransactions.byType,
      columns: txnByTypeColumns,
      tableOptions: {
        highlightedColumn: 'Type',
        initialSortColumns: [{ id: 'count', desc: true }],
        rowRoute: (row: any) => `details?type=${row.original.transactionType}`,
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const txnCountBankCCAccountDataSections: DataSection[] = [
    {
      description: insightDescriptions.transactionsBankCreditCardAccount,
      gridColumn: '1',
      key: 'transactionByBankCCAccountDescription',
      title: 'Description',
    },
    {
      insights: insights.transactionsBankCreditCardAccount,
      gridRow: '1',
      gridColumn: '2',
      key: 'transactionByBankCCAccountInsights',
      title: 'Insights',
    },
    {
      key: 'transactionCountByBankCCAccountChart',
      gridRow: '2',
      gridColumn: '1',
      Chart: DonutChart,
      chartOptions: { legendTitle: 'Top 3 Accounts' },
      data: formattedBankCCTxnCounts.slice(0, 3).map((account: any) => ({
        name: account.accountName,
        data: account.count,
      })),
    },
    {
      key: 'transactionCountByBankCCAccountTable',
      gridRow: '3',
      table: true,
      data: formattedBankCCTxnCounts,
      columns: [
        { Header: 'Account', accessor: 'accountName' },
        { Header: 'Count', accessor: 'count', sortDescFirst: true, Cell: NumericCell },
      ],
      tableOptions: {
        highlightedColumn: 'Account',
        initialSortColumns: [{ id: 'count', desc: true }],
        onRowClick: (row: any) => row.toggleRowExpanded(),
        renderRowSubComponent: (row: any) => {
          return (
            <Row>
              <Cell colSpan={row.cells.length} style={subTableCellStyle}>
                <ReportTable
                  dataSection={{
                    key: row.original.accountName,
                    data: row.original.transactionTypeCounts,
                    tableOptions: {
                      ...subTableOptions,
                      rowRoute: (subRow: any) => {
                        return `details?account=${encodeURIComponent(
                          row.original.accountName,
                        )}&type=${encodeURIComponent(subRow.original.transactionType)}`
                      },
                    },
                    subReportSections: [drilldownReportSection],
                  }}
                />
              </Cell>
            </Row>
          )
        },
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const txnByMonthDataSections = [
    {
      description: insightDescriptions.transactionCountByMonth,
      gridColumn: '1',
      key: 'transactionCountByMonthDescription',
      title: 'Description',
    },
    {
      insights: insights.transactionCountByMonth,
      gridRow: '1',
      gridColumn: '2',
      key: 'transactionCountByMonthInsights',
      title: 'Insights',
    },
    {
      gridRow: '2',
      key: 'transactionCountByMonthChart',
      data: sortedTxnsByMonth.map((month: any) => ({
        x: month.transactionMonth,
        y: month.transactionCount,
      })),
      Chart: BarLineAreaChart,
    },
    {
      key: 'transactionCountByMonthTable',
      gridRow: '3',
      table: true,
      data: sortedTxnsByMonth.map((month: any) => {
        const startDate = DateTime.fromISO(`${month.transactionMonth}-01`)
        const endDate = startDate.plus({ months: 1 }).minus({ days: 1 })

        return {
          month: startDate.monthLong,
          count: month.transactionCount,
          startDate: startDate.toISODate(),
          endDate: endDate.toISODate(),
        }
      }),
      columns: [
        { Header: 'Month', accessor: 'month', disableSortBy: true },
        { Header: 'Count', accessor: 'count', sortDescFirst: true, Cell: NumericCell },
      ],
      tableOptions: {
        highlightedColumn: headerMap.transactionMonth,
        rowRoute: (row: any) =>
          `details?startDate=${row.original.startDate}&endDate=${row.original.endDate}`,
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const bankRuleSubReportSections = bankRules.map((category: any) => {
    const dataSections = [
      {
        key: `bankRule_${camelCase(category.transactionCategory)}`,
        table: true,
        data: category.transactions,
        columns: standardColumns,
      },
    ]

    return {
      label: category.transactionCategory,
      value: `bankRule_${camelCase(category.transactionCategory)}`,
      kind: 'insight',
      route: kebabCase(category.transactionCategory),
      dataSections,
      exportData: [{ sheetName: `Transactions ${category.transactionCategory}`, dataSections }],
    }
  })

  const bankRuleDataSections = [
    {
      description: insightDescriptions.bankRuleTransactions,
      gridColumn: '1',
      key: 'bankRuleTransactionsDescription',
      title: 'Description',
    },
    {
      insights: insights.bankRuleTransactions,
      gridRow: '1',
      gridColumn: '2',
      key: 'bankRuleTransactionsInsights',
      title: 'Insights',
    },
    {
      key: 'bankRuleTransactionsTable',
      gridRow: '2',
      table: true,
      data: bankRules,
      tableOptions: {
        highlightedColumn: headerMap.accountName,
        rowRoute: bankRules?.length
          ? (row: any) => kebabCase(row.original.transactionCategory)
          : null,
      },
      subReportSections: bankRuleSubReportSections,
    },
  ]

  const transactionDataSections = [
    {
      key: 'transactionByAccountType',
      insights: insights.transactionsByAccountType,
      title: 'Transaction by Account Type',
      detailRoute: `transaction-by-account-type`,
      Chart: DonutChart,
      chartOptions: { legendTitle: 'Top 5 Account Types' },
      data: filteredTransactions.byAccountType
        .slice(0, 5)
        .map((t: any) => ({ name: t.accountType, data: t.totalTransactionCount })),
      subReportSections: [
        {
          label: 'Transaction by Account Type',
          value: 'transactionByAccountType',
          route: 'transaction-by-account-type',
          kind: 'insight',
          dataSections: txnByAccountTypeDataSections,
        },
      ],
    },
    {
      key: 'lastReconciledTransactionDate',
      insights: insights.lastReconciledTransactionDate,
      title: 'Last Reconciled Transaction Date',
      detailRoute: 'last-reconciled-transaction-date',
      data: lastReconciledData,
      table: true,
      columns: [
        { Header: 'Account Type', accessor: 'accountName', disableSortBy: true },
        {
          Header: 'Days Elapsed',
          accessor: 'daysElapsed',
          disableSortBy: true,
          sortType: 'numericFirst',
          align: 'right',
        },
      ],
      tableOptions: {
        initialSortColumns: [{ id: 'daysElapsed', desc: true }],
        pageSize: 3,
        pagination: true,
        highlightedColumn: headerMap.accountType,
        preview: true,
        cellProps: () => {
          return {
            pt: 'xlarge',
            pb: 'xlarge',
          }
        },
      },
      subReportSections: [
        {
          label: 'Last Reconciled Transaction Date',
          value: 'lastReconciledTransactionDate',
          route: 'last-reconciled-transaction-date',
          kind: 'insight',
          dataSections: lastReconciledDataSections,
        },
      ],
    },
    {
      key: 'transactionByType',
      insights: insights.transactionsByType,
      title: 'Transaction by Type',
      data: filteredTransactions.byType,
      detailRoute: `transaction-by-type`,
      table: true,
      columns: [
        { Header: 'Type', accessor: 'transactionType' },
        { Header: 'Count', accessor: 'count', Cell: NumericCell },
        { Header: 'Amount', accessor: 'transactionAmount', Cell: LedgerCell },
      ],
      tableOptions: {
        initialSortColumns: [{ id: 'count', desc: true }],
        disableSortBy: true,
        pageSize: 3,
        pagination: true,
        preview: true,
        highlightedColumn: 'Type',
        cellProps: () => {
          return {
            pt: 'xlarge',
            pb: 'xlarge',
          }
        },
      },
      subReportSections: [
        {
          label: 'Transaction by Type',
          value: 'transactionByType',
          route: 'transaction-by-type',
          kind: 'insight',
          dataSections: txnByTypeDataSections,
        },
      ],
    },
    transactionCountByBankCCAccount
      ? {
          key: 'transactionCountByBankCCAccount',
          title: 'Transaction Count by Bank & Credit Card Account',
          insights: insights.transactionsBankCreditCardAccount,
          Chart: DonutChart,
          chartOptions: { legendTitle: 'Top 3 Accounts' },
          detailRoute: 'transaction-count-bank-cc-account',
          data: formattedBankCCTxnCounts.slice(0, 3).map((account: any) => ({
            name: account.accountName,
            data: account.count,
          })),
          subReportSections: [
            {
              label: 'Transaction Count by Bank & Credit Card Account',
              value: 'transactionCountByBankCCAccount',
              route: 'transaction-count-bank-cc-account',
              kind: 'insight',
              dataSections: txnCountBankCCAccountDataSections,
            },
          ],
        }
      : null,
    {
      key: 'transactionCountByMonth',
      insights: insights.transactionCountByMonth,
      title: 'Transaction by Month',
      detailRoute: `transaction-count-by-month`,
      data: sortedTxnsByMonth.map((month: any) => ({
        x: month.transactionMonth,
        y: month.transactionCount,
      })),
      Chart: BarLineAreaChart,
      subReportSections: [
        {
          label: 'Transaction Count by Month',
          value: 'transactionCountByMonth',
          route: 'transaction-count-by-month',
          kind: 'insight',
          dataSections: txnByMonthDataSections,
        },
      ],
    },
    bankRules?.length && {
      key: 'bankRuleTransactions',
      title: 'Bank Rule Transactions (Money Out)',
      insights: insights.bankRuleTransactions,
      Chart: RadialBarChart,
      chartOptions: {
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: { show: false },
                value: {
                  formatter: (val: number) => `${val}%`,
                  fontSize: theme.fontSizes.xgigantic,
                  fontWeight: theme.fontWeights.xxbold,
                },
              },
            },
          },
        },
      },
      data: [bankRuleScore],
      detailRoute: 'bank-rule',
      subReportSections: [
        {
          label: 'Bank Rule Transactions (Money Out)',
          value: 'bankRuleTransactions',
          route: 'bank-rule',
          kind: 'insight',
          dataSections: bankRuleDataSections,
        },
      ],
    },
  ]

  return {
    label: 'Transactions',
    value: 'transactionInsights',
    kind: 'insight',
    dataSections: compact(transactionDataSections),
  }
}
