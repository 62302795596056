import styled from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'

const Image = styled.img`
  ${compose(space, layout, typography, color, flexbox, grid, background, border, position, shadow)}
`

export default Image
