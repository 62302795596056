import React from 'react'

export type Company = {
  id: string
  rowId: string
  name: string
  legalName: string
}

export type Organization = {
  id: string
  rowId: string
  name: string
  legalName: string
  slug: string
  organizationSubscriptions?: {
    nodes: any[]
  }
}

export type BillingPlanTier = {
  name: string
  minCompanyCount: number
  maxCompanyCount: number
  price: number
}

export type BillingPlanConfig = {
  tiers?: BillingPlanTier[]
}

export type BillingPlan = {
  id: string
  rowId: string
  name: string
  type: string
  status: 'active' | 'inactive'
  stripePriceId: string
  slug: string
  config: BillingPlanConfig
}

export type ContextProps = {
  user: any
  setUser: (user: any) => void
  isLoggedIn: boolean
  isReady: boolean
  login(): Promise<void>
  logout(): void
  currentCompany: Company | null
  setCurrentCompany: (company: Company | null) => void
  currentOrg: Organization | null
  setCurrentOrg: (org: Organization) => void
  userIsCurrentOrgAdmin: boolean
  freeTrial: boolean
  trialDaysRemaining: number
  activeSubscription: boolean
  billingPlan: BillingPlan | null
  allowClientCreation: boolean
  activeCompanyCount: number
}

const defaultCtx = {
  user: {},
  setUser: () => {},
  isLoggedIn: false,
  isReady: false,
  login: async () => {},
  logout: () => {},
  currentCompany: { id: '', rowId: '', name: '', legalName: '' },
  setCurrentCompany: () => {},
  currentOrg: { id: '', rowId: '', name: '', legalName: '', slug: '' },
  setCurrentOrg: () => {},
  userIsCurrentOrgAdmin: false,
  freeTrial: false,
  trialDaysRemaining: 0,
  activeSubscription: true,
  allowClientCreation: true,
  billingPlan: null,
  activeCompanyCount: 0,
}

export default React.createContext<ContextProps>(defaultCtx)
