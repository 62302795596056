import React from 'react'
import { Modal, FlexRow, FlexColumn, Button, Text, Icon, Tooltip, List, ListItem } from 'elements'
import ChoiceButton from 'components/ui/input/ChoiceButton'
import FirstClientReportFileUpload from './FirstClientReportFileUpload'
import ModalContent from '../../app/ModalContent'
import ModalCloseButton from '../../app/ModalCloseButton'

export default function FirstClientUploadStep({
  onChange,
  submitting,
  files,
  setFiles,
  uploadSource,
  fileKinds,
  setFileKinds,
  sourceFileKindOptions,
  canContinue,
  onNext,
  onBack,
  onClose,
}) {
  return (
    <>
      <ModalContent pt="gigantic" px="gigantic" height="775px">
        <ModalCloseButton onClose={onClose} />
        <Text mb="xlarge">Step 3: Select file source</Text>
        <FlexRow mb="large">
          <ChoiceButton
            icon="document-add"
            selected={uploadSource === 'qbo'}
            onPress={() => onChange('qbo')}
            area="small"
          >
            QuickBooks® Online
          </ChoiceButton>
        </FlexRow>
        <FlexRow mb="xxlarge">
          <ChoiceButton
            icon="document-add"
            selected={uploadSource === 'desktop'}
            onPress={() => onChange('desktop')}
            area="small"
          >
            QuickBooks® Desktop
          </ChoiceButton>
        </FlexRow>
        <FlexRow mb="large" alignItems="center">
          <Text mr="large">Step 4: Upload Files</Text>
          <Icon
            size="medium"
            name="exclamation-circle"
            color="neutral.0"
            data-tip
            data-for="upload-tooltip"
          />
          <Tooltip
            id="upload-tooltip"
            place="right"
            backgroundColor="neutral.5"
            opacity="0.9"
            offset={{ bottom: '85px' }}
            padding="0px"
            arrowColor="transparent"
          >
            <FlexColumn color="background.0" px="xxlarge" py="xlarge">
              <Text color="inherit">The following files are required:</Text>
              <FlexRow pl="large">
                <List mb="none">
                  <ListItem kind="background">Account List</ListItem>
                  <ListItem kind="background">General Ledger</ListItem>
                  <ListItem kind="background">Customer Contact List</ListItem>
                  <ListItem kind="background">Vendor Contact List</ListItem>
                </List>
              </FlexRow>
            </FlexColumn>
          </Tooltip>
        </FlexRow>
        <FirstClientReportFileUpload
          submitting={submitting}
          files={files}
          setFiles={setFiles}
          uploadSource={uploadSource}
          fileKinds={fileKinds}
          setFileKinds={setFileKinds}
          sourceFileKindOptions={sourceFileKindOptions}
        />
      </ModalContent>
      <Modal.Footer
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="gigantic"
        pb="gigantic"
        width="100%"
      >
        <Button disabled={submitting} kind="alt-neutral" onPress={onBack}>
          Back
        </Button>
        <Button disabled={submitting || !canContinue} kind="primary" onPress={onNext}>
          Next
        </Button>
      </Modal.Footer>
    </>
  )
}
