import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { Formik } from 'formik'
import { FlexColumn, Text } from 'elements'
import StripeTriggerForm, { Values } from './StripeTriggerForm'
import { useApi, useNotifications } from 'hooks'
import StripeHistoryTable from './StripeHistoryTable'

type Props = {
  company: any
}

export default function StripeTrigger(props: Props) {
  const { company } = props

  const api = useApi()
  const notifications = useNotifications()

  const data = useFragment(
    graphql`
      fragment StripeTrigger_company on Company
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 }) {
        integrations {
          nodes {
            kind
            rowId
          }
        }
        companyUserFiles(after: $cursor, first: $count, orderBy: CREATED_AT_DESC) {
          edges {
            node {
              userFile {
                kind
                rowId
              }
            }
          }
        }
      }
    `,
    company,
  )

  const stripeIntegration = data.integrations.nodes.find(
    (integration: any) => integration.kind === 'stripe',
  )

  const handleSubmit = async (values: Values, { setSubmitting }: Record<string, any>) => {
    const { reportPeriod, timeZone } = values

    try {
      const res = await api.stripe.postData({
        integrationId: stripeIntegration.rowId,
        startDate: reportPeriod.interval.start.toISODate(),
        endDate: reportPeriod.interval.end.toISODate(),
        timeZone,
      })

      if (res.code === 200) {
        notifications.success('Stripe data run triggered successfully')
      } else {
        notifications.error('Stripe trigger unsuccessful')
      }

      setSubmitting(false)
    } catch (err) {
      console.error(err)

      notifications.error('Stripe trigger unsuccessful')
    }
  }

  return (
    <FlexColumn pt="gigantic">
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxxlarge">
        Stripe
      </Text>
      <FlexColumn pb="gigantic">
        <Formik onSubmit={handleSubmit} initialValues={{}}>
          <StripeTriggerForm />
        </Formik>
      </FlexColumn>
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxxlarge">
        Stripe Run History
      </Text>
      <StripeHistoryTable company={company} />
    </FlexColumn>
  )
}
