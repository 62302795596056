import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { Text, FlexColumn, Button, Divider } from 'elements'
import { useAnalytics, useAuth, useModals } from 'hooks'
import { Option, ReportPeriod } from '../../shared/types'
import PeriodSelect from './PeriodSelect'
import CompanySelect from './CompanySelect'
import WorkflowTemplateSelect from './WorkflowTemplateSelect'
import useReportCreate from '../report/hooks/useReportCreate'
import useRunReportWithIntegration from '../report/hooks/useRunReportWithIntegration'
import CreateReportModalContainer from 'components/report/create/CreateReportModalContainer'
import FirstClientModalContainer from '../company/create/FirstClientModalContainer'
import fetchReport from '../report/queries/fetchReport'
import LastSyncDate from './LastSyncDate'

export default function CompanyNavigation() {
  // analytics to be added back later
  // const analytics = useAnalytics()
  const { currentOrg } = useAuth()
  const { companyId = '', reportId = '' } = useParams<any>()
  const history = useHistory()
  const { openModal } = useModals()
  const analytics = useAnalytics()

  const [company, setCompany] = useState<any>(null)
  const [period, setPeriod] = useState<ReportPeriod | null>(null)
  const [workflowTemplate, setWorkflowTemplate] = useState<any>(null)
  const [submitting, setSubmitting] = useState(false)
  const [createReport] = useReportCreate()
  const [runReportWithIntegration] = useRunReportWithIntegration()
  const environment = useRelayEnvironment()

  // query for company and report workflow based on url params
  const data = useLazyLoadQuery<any>(
    graphql`
      query CompanyNavigationQuery($companyId: ID!, $reportId: ID!, $orgSlug: String!) {
        companyById(id: $companyId) {
          id
          name
          integrations(first: 100) @connection(key: "CompanyNavigation_integrations") {
            edges {
              node {
                id
                status
                config
                kind
              }
            }
          }
          ...PeriodSelect_company
        }
        reportById(id: $reportId) {
          id
          periodStart
          periodEnd
          workflowTemplateId
          ...LastSyncDate_report
        }
        organizationBySlug(slug: $orgSlug) {
          workflowTemplatesWithDefaults(first: 100, includeDeleted: NO, orderBy: CREATED_AT_DESC) {
            nodes {
              rowId
              id
              name
            }
          }
          ...CompanySelect_organization
          ...WorkflowTemplateSelect_organization
        }
      }
    `,
    {
      companyId,
      reportId,
      orgSlug: currentOrg!.slug,
    },
  )

  // if the url params (and therefore the data) change, update state based on that data
  useEffect(() => {
    if (data?.companyById) {
      setCompany(data.companyById)
    } else {
      setCompany(null)
    }

    if (data?.reportById) {
      setPeriod({ periodStart: data.reportById.periodStart, periodEnd: data.reportById.periodEnd })

      setWorkflowTemplate(
        data?.organizationBySlug?.workflowTemplatesWithDefaults?.nodes?.find(
          (template: any) => template.rowId === data.reportById.workflowTemplateId,
        ),
      )
    } else {
      setPeriod(null)
      setWorkflowTemplate(null)
    }
  }, [data])

  // changing company clears the other selects
  const handleCompanyChange = (option: Option) => {
    if (option.value === 'newCompany') {
      setCompany(option.value)

      openModal!(FirstClientModalContainer, {}, 'full')

      return
    }

    if (option.value === 'upgrade') {
      history.push(`/orgs/${currentOrg!.slug}/settings/billing`)

      return
    }

    history.push(`/orgs/${currentOrg!.slug}/companies/${option.value.id}`)
  }

  const handlePeriodChange = (period: ReportPeriod) => {
    setPeriod(period)
    setWorkflowTemplate(null)
  }

  const handleWorkflowTemplateChange = (option: Option) => {
    setWorkflowTemplate(option.value)
  }

  // A couple of possibilities on submit:
  // 1. If the report (workflow) already exists, navigate to that report.
  // 2. If there's a connected integration, create and run the report with that integration.
  // 3. If there's no connected integration, open the create report modal and run the report from there.
  const handleSubmit = async () => {
    setSubmitting(true)

    const connectedIntegration = data.companyById.integrations?.edges?.find(
      (edge: any) => edge.node.status === 'ENABLED',
    )?.node

    const noCurrentTenant =
      connectedIntegration?.kind === 'xero' && !connectedIntegration.config.xero.currentTenant

    const reportsPath = `/orgs/${currentOrg!.slug}/companies/${companyId}/reports`

    // existing report based on workflow and period
    const existingReport = await fetchReport({
      environment,
      companyId,
      periodStart: period!.periodStart,
      periodEnd: period!.periodEnd,
      workflowTemplateId: workflowTemplate.rowId,
    })

    if (existingReport) {
      history.push(`${reportsPath}/${existingReport.id}`)
    } else if (connectedIntegration && !noCurrentTenant) {
      // @ts-ignore
      const { report: newReport } = await createReport(
        {
          companyId: company.id,
          kind: 'anomaly:1',
          workflowTemplateId: workflowTemplate.id,
          periodStart: period!.periodStart,
          periodEnd: period!.periodEnd,
        },
        { includeDeleted: 'NO' },
      )

      // @ts-ignore
      await runReportWithIntegration({
        reportId: newReport.id,
        integrationId: connectedIntegration.id,
      })

      history.push(`${reportsPath}/${newReport.id}`)

      analytics.track('Report Created', { reportId: newReport.rowId })
    } else {
      openModal!(
        CreateReportModalContainer,
        { workflowTemplateId: workflowTemplate.id, reportPeriod: period },
        'full',
      )
    }

    setSubmitting(false)
  }

  if (!data) {
    return null
  }

  return (
    <FlexColumn width="100%">
      <FlexColumn mb="xlarge">
        <Text ml="xxsmall" mb="small" fontWeight="bold">
          Client
        </Text>
        <CompanySelect
          company={company}
          onCompanyChange={handleCompanyChange}
          organization={data.organizationBySlug}
        />
      </FlexColumn>
      <Divider mb="xlarge" />
      <FlexColumn mb="xlarge">
        <Text ml="xxsmall" mb="small" fontWeight="bold">
          Period
        </Text>
        <PeriodSelect
          company={data.companyById}
          period={period}
          onPeriodChange={handlePeriodChange}
        />
      </FlexColumn>
      <FlexColumn mb="xlarge">
        <Text ml="xxsmall" mb="small" fontWeight="bold">
          Workflow
        </Text>
        <WorkflowTemplateSelect
          workflowTemplate={workflowTemplate}
          onWorkflowTemplateChange={handleWorkflowTemplateChange}
          period={period}
          organization={data.organizationBySlug}
        />
      </FlexColumn>
      <Button
        kind="primary"
        disabled={!workflowTemplate || submitting}
        onClick={handleSubmit}
        mb="xlarge"
      >
        {submitting ? 'Submitting...' : 'Submit'}
      </Button>
      {data.reportById && <LastSyncDate report={data.reportById} />}
    </FlexColumn>
  )
}
