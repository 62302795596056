import React from 'react'
import { useDecision } from '@optimizely/react-sdk'
import { Button, FlexColumn, Text, FlexRow } from 'elements'
import Header from '../app/Header'
import { useAuth, useModals } from 'hooks'
import FirstClientModalContainer from '../company/create/FirstClientModalContainer'

export default function OrganizationHeader() {
  const { currentOrg, allowClientCreation } = useAuth()
  const { openModal } = useModals()
  const canViewIds = useDecision('canViewIds')[0].enabled

  const handleCreateCompany = () => {
    if (allowClientCreation) {
      openModal!(FirstClientModalContainer, {}, 'full')
    }
  }

  return (
    <FlexColumn>
      <Header>
        <FlexRow alignItems="center">
          <Text fontWeight="bold" fontSize="large" mr={canViewIds && 'large'}>
            {currentOrg?.name}
          </Text>
          {canViewIds && <Text>{currentOrg?.rowId}</Text>}
        </FlexRow>
        <Button onClick={handleCreateCompany} disabled={!allowClientCreation}>
          + New Client
        </Button>
      </Header>
    </FlexColumn>
  )
}
