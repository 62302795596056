import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { FlexRow, Table as Tables, Text } from 'elements'
import StripeHistoryContextMenu from './StripeHistoryContextMenu'
import { formatReportPeriod } from '../../../report/utils/formatting'

const { Row, Cell } = Tables.Card

type Props = {
  stripeFile: any
}

export default function StripeHistoryTableRow(props: Props) {
  const { stripeFile } = props

  const data = useFragment(
    graphql`
      fragment StripeHistoryTableRow_userFile on UserFile {
        createdAt
        meta
      }
    `,
    stripeFile,
  )

  const { startDate, endDate } = data.meta || {}

  return (
    <Row>
      <Cell>
        <FlexRow>
          <Text fontSize="large" lineHeight="large">
            {formatReportPeriod({ startDate, endDate }, { timeZone: 'UTC' })}
          </Text>
        </FlexRow>
      </Cell>
      <Cell>
        <FlexRow>
          <Text>{data.createdAt}</Text>
        </FlexRow>
      </Cell>
      <Cell>
        <FlexRow justifyContent="center">
          <StripeHistoryContextMenu stripeFile={stripeFile} />
        </FlexRow>
      </Cell>
    </Row>
  )
}
