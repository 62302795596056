import { graphql, useSubscription } from 'react-relay/hooks'

type Props = {
  reportId: string
  resolutionSection?: string
  resolutionAnomalyView?: string
}

const subscription = graphql`
  subscription ReportWatcherSubscription(
    $input: ReportSubscriptionInput!
    $resolutionSection: String
    $anomalies: [String]
  ) {
    reportUpdated(input: $input) {
      event
      report {
        status
        errors
        reviewStatus
        ...ReportResolution_report
        ...ReportResolutionTable_report
        ...ReportViewContainer_report
        ...WorkspaceHeader_report
        company {
          ...Integrations_company
          ...IntegrationChoices_company
        }
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
              nodes {
                id
                key
                ...StepHeader_reportWorkflowWorkspaceStep
                ...ReportStep_reportWorkflowWorkspaceStep
              }
            }
          }
        }
      }
    }
  }
`

export default function ReportWatcher(props: Props) {
  const { reportId, resolutionSection, resolutionAnomalyView } = props

  useSubscription({
    subscription,
    variables: {
      input: { reportId },
      resolutionSection,
      anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
    },
    onNext: (response: any, ...args) => {
      console.log('Updated report', response.reportUpdated?.report)
    },
  })

  // this component doesn't render anything
  return null
}
