import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import UploadZone from '../UploadZone'

type Props = {
  onUpload: Function
  multiple?: boolean
  allowedTypes: string[]
  pauseAfterUpload?: boolean
}

function FileUpload(props: Props & Record<string, any>) {
  const { onUpload, multiple, allowedTypes, pauseAfterUpload } = props

  const [isUploading, setIsUploading] = useState(false)
  const [fileNames, setFileNames] = useState<string[] | null>(null)

  // onDrop callback
  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      // set filenames for display
      setFileNames(acceptedFiles.map((file: any) => file.name))

      setIsUploading(true)
      try {
        await onUpload(multiple ? acceptedFiles : acceptedFiles[0])
        if (!pauseAfterUpload) {
          setIsUploading(false)
        }
      } catch (err) {
        // No longer uploading if there is an error regardless of pauseAfterUpload
        setIsUploading(false)
        throw err
      }
    },
    [multiple, pauseAfterUpload, onUpload],
  )

  // dropzone hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept: allowedTypes.map((type) => `.${type}`),
  })

  // drag status
  let uploadStatus = isDragActive ? 'drag-over' : 'init'

  if (isUploading) {
    uploadStatus = 'upload'
  }

  return (
    <div {...getRootProps()} style={{ outline: 'none' }}>
      <UploadZone
        {...props}
        allowedTypes={allowedTypes}
        status={uploadStatus}
        fileNames={fileNames}
      >
        <input {...getInputProps()} />
      </UploadZone>
    </div>
  )
}

FileUpload.propTypes = {
  pauseAfterUpload: PropTypes.bool,
  multiple: PropTypes.bool,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  onUpload: PropTypes.func.isRequired,
}

FileUpload.defaultProps = {
  pauseAfterUpload: false,
  multiple: false,
}

export default FileUpload
