import React from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import { Table as Tables, TablePagination } from 'elements'
import ModulesCompaniesTableRow from './ModulesCompaniesTableRow'

import { Sort, SortColumn } from '../../types/table'

const { Table, Head, HeadRow, HeadCell, Body } = Tables.Card

type Props = {
  organization: any
  sortCol?: SortColumn | null
  onSort: Function
}

const getColSort = (header: string, sortCol?: SortColumn | null): Sort | null => {
  if (!sortCol) {
    return null
  }

  const { column, order } = sortCol

  if (column === header) {
    return order
  }

  return null
}

export default function ModulesCompaniesTable(props: Props) {
  const { organization, sortCol, onSort } = props

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment ModulesCompaniesTable_organization on Organization
        @argumentDefinitions(
          cursor: { type: "Cursor" }
          count: { type: "Int", defaultValue: 10 }
          includeDeleted: { type: "IncludeDeletedOption", defaultValue: NO }
        )
        @refetchable(queryName: "ModulesCompaniesTablePaginationQuery") {
        slug
        companies(after: $cursor, first: $count, orderBy: $order, includeDeleted: $includeDeleted)
          @connection(key: "CompaniesTable_companies") {
          edges {
            node {
              id
              deletedAt
              ...ModulesCompaniesTableRow_company
            }
          }
        }
      }
    `,
    organization,
  )

  const edges = data?.companies?.edges || []

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell
            enableSortBy
            sort={getColSort('Client', sortCol)}
            onSort={(sort: Sort) => onSort({ column: 'Client', order: sort })}
          >
            Client
          </HeadCell>
        </HeadRow>
      </Head>
      <Body>
        {edges.map(({ node: company }: any, i: number) => (
          <ModulesCompaniesTableRow company={company} key={company.id} />
        ))}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={1} {...paginationProps} />
    </Table>
  )
}
