import { ReportSection } from '../../../types'

export const drilldownReportSection: ReportSection = {
  label: 'Details',
  value: 'details',
  kind: 'report',
  route: 'details',
  // data section will be added later based on query results
  dataSections: [],
  exportData: [],
  viewOptions: { disableViewModes: true },
}
