import styled from 'styled-components'
import SimpleTableFootcell from './SimpleTableFootCell'

const SimpleTableFoot = styled.tfoot`
  ${SimpleTableFootcell}:first-child {
    padding-left: 0;
  }

  ${SimpleTableFootcell}:last-child {
    padding-right: 0;
  }
`

export default SimpleTableFoot
