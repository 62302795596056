import React from 'react'
import ModulesCompanyHeader from './ModulesCompanyHeader'
import { SuspenseBoundary } from 'elements'

export default function ModulesCompanyHeaderContainer() {
  return (
    <SuspenseBoundary>
      <ModulesCompanyHeader />
    </SuspenseBoundary>
  )
}
