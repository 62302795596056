import styled from 'styled-components'
import SimpleTableCell from './SimpleTableCell'

const SimpleTableBody = styled.tbody`
  ${SimpleTableCell}:first-child {
    padding-left: 0;
  }

  ${SimpleTableCell}:last-child {
    padding-right: 0;
  }
`

export default SimpleTableBody
