import icons from './icons'

const sizeAndSpace = {
  none: 0,
  xxsmall: 2,
  xsmall: 4,
  small: 8,
  medium: 12,
  large: 16,
  xlarge: 24,
  xxlarge: 32,
  xxxlarge: 48,
  gigantic: 64,
  xgigantic: 128,
  xxgigantic: 256,
  xxxgigantic: 512,
}

// see https://styled-system.com
const theme: { [key: string]: any } = {
  // these are in px
  space: sizeAndSpace,
  sizes: sizeAndSpace,
  radii: {
    none: 0,
    xxsmall: 2,
    xsmall: 5,
    small: 9,
    medium: 12,
    large: 16,
    xlarge: 20,
    full: 99999,
  },
  borderWidths: { none: 0, xthin: 1, thin: 2, thick: 4, xthick: 6, xxthick: 8 },
  borders: {
    none: 'none',
    thinSolid: '1px solid #CDD4DE',
    thickDotted: '2px dashed rgba(180, 185, 196, 0.3)',
  },
  shadows: {
    none: 'none',
    low: '0px 1px 10px -4px rgba(0, 0, 0, 0.07)',
    medium: '0px 1px 15px rgba(0, 0, 0, 0.07)',
    high: '0px 2px 10px 2px rgba(0, 0, 0, .08)',
  },

  fontSizes: {
    xxsmall: '10px',
    xsmall: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '24px',
    xxxlarge: '30px',
    gigantic: '36px',
    xgigantic: '48px',
    xxgigantic: '60px',
    xxxgigantic: '72px',
  },
  fontWeights: { light: 400, medium: 600, bold: 700, xbold: 800, xxbold: 900 },
  fontFamily: 'Lato, Arial, sans-serif',
  lineHeights: {
    xxsmall: '11px',
    xsmall: '13px',
    small: '18px',
    medium: '20px',
    large: '24px',
    xlarge: '28px',
    xxlarge: '42px',
    xxxlarge: '38px',
    gigantic: '44px',
    xgigantic: '60px',
    xxgigantic: '72px',
    xxxgigantic: '90px',
  },
  iconSizes: {
    inherit: 'inherit',
    xxsmall: '10px',
    xsmall: '14px',
    small: '18px',
    medium: '24px',
    large: '30px',
    xlarge: '40px',
    xxlarge: '48px',
    gigantic: '80px',
  },
  chartSizes: {
    donut: {
      // interior space / donut width
      barWidth: {
        small: '80%',
        large: '50%',
      },
      chartWidth: '500px',
      chartHeight: '260px',
      titleOffsetX: 250,
      titleOffsetY: 25,
      legendOffsetY: 35,
      legendWidth: 250,
    },
  },

  // see https://coolors.co/05386b-3278bf-58da92-f5ec3d-fa9537-ff3e30
  colors: {
    nav: {
      default: '#192536',
      secondary: '#11376A',
      hover: '#5AC37B',
      active: '#0c2d56',
    },
    background: ['#FFFFFF', '#EAECF0', '#F9FAFB', '#EFF2F7', '#F7F8FA'],
    foreground: ['#192536', '#0c2d56', '#C0CADA', '#CDD4DE'],
    primary: ['#50C17A', '#F0F8F3', '#4DAC6B'],
    secondary: [
      '#1896CC',
      '#F6F9FF',
      '#11376A',
      '#BAE9FD',
      '#245598',
      '#00A6CC',
      '#1D406C',
      '#77C5E7',
      '#96CDFF',
    ],
    accent: {
      black: '#192536',
      darkblue: '#1D406B',
      lightblue: '#1896CC',
      purple: '#461F4F',
      altPurple: '#411D4C',
      orange: '#FF7048',
      grey: '#F9FAFB',
    },
    warning: ['#FFD242'],
    danger: ['#D84942'],
    success: ['#5AC37B'],
    neutral: [
      '#8A92A3',
      '#C0CADA',
      '#CDD4DE',
      '#8C979F',
      '#7F7F7F',
      '#4B4B4B',
      '#505256',
      '#F2F4F7',
      '#343435',
      '#667085',
      '#101828',
      '#D0D5DD',
    ],
    xero: ['#0D82A8', '#086482'],
  },

  icons,
  zIndices: {
    datepicker: 666,
    notification: 555,
    fullModal: 444,
    screenModal: 333,
    semiModal: 222,
    tooltip: 111,
  },
}

export default theme
