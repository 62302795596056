import React from 'react'
import { useParams } from 'react-router-dom'
import { FlexColumn } from 'elements'
import WorkspaceHeader from './WorkspaceHeader'
import StepHeader from './StepHeader'
import Header from '../../app/Header'

type Props = {
  report: any
  step: any
}

export default function WorkflowHeader(props: Props) {
  const { report, step } = props
  const { orgSlug, companyId, reportId } = useParams<any>()

  const reportUrl = `/orgs/${orgSlug}/companies/${companyId}/reports/${reportId}`

  return (
    <FlexColumn width="100%" zIndex="zIndices.header">
      <Header>
        <WorkspaceHeader report={report} reportUrl={reportUrl} />
      </Header>
      {step && (
        <Header>
          <StepHeader step={step} reportUrl={reportUrl} />
        </Header>
      )}
    </FlexColumn>
  )
}
