/**
 * @generated SignedSource<<2aab5d5017497bd7cf8311023a4965a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IncludeDeletedOption = "EXCLUSIVELY" | "INHERIT" | "NO" | "YES" | "%future added value";
export type CompletedWorkflowsPaginationQuery$variables = {
  count?: number | null;
  cursor?: any | null;
  includeDeleted?: IncludeDeletedOption | null;
  id: string;
};
export type CompletedWorkflowsPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CompletedWorkflowTable_company">;
  } | null;
};
export type CompletedWorkflowsPaginationQuery = {
  variables: CompletedWorkflowsPaginationQuery$variables;
  response: CompletedWorkflowsPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeDeleted"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "includeDeleted",
    "variableName": "includeDeleted"
  },
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "status": "completed"
    }
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v6/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompletedWorkflowsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "CompletedWorkflowTable_company"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompletedWorkflowsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "completedReports",
                "args": (v7/*: any*/),
                "concreteType": "ReportsConnection",
                "kind": "LinkedField",
                "name": "filteredReports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Report",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workflowSummary",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "periodStart",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "periodEnd",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deletedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "config",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportUserFilesConnection",
                            "kind": "LinkedField",
                            "name": "reportUserFiles",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReportUserFile",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserFile",
                                    "kind": "LinkedField",
                                    "name": "userFile",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "kind",
                                        "storageKey": null
                                      },
                                      (v9/*: any*/),
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportIntegrationsConnection",
                            "kind": "LinkedField",
                            "name": "reportIntegrations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReportIntegration",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Integration",
                                    "kind": "LinkedField",
                                    "name": "integration",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "owner",
                                        "plural": false,
                                        "selections": (v10/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "orderBy",
                                "value": "WORKSPACE_ORDER_ASC"
                              }
                            ],
                            "concreteType": "ReportWorkflowWorkspacesConnection",
                            "kind": "LinkedField",
                            "name": "reportWorkflowWorkspaces",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReportWorkflowWorkspace",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "orderBy",
                                        "value": "STEP_ORDER_ASC"
                                      }
                                    ],
                                    "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                                    "kind": "LinkedField",
                                    "name": "reportWorkflowWorkspaceSteps",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ReportWorkflowWorkspaceStep",
                                        "kind": "LinkedField",
                                        "name": "nodes",
                                        "plural": true,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v11/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sections",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "completedReports",
                "args": (v7/*: any*/),
                "filters": [
                  "input",
                  "orderBy",
                  "includeDeleted"
                ],
                "handle": "connection",
                "key": "CompletedWorkflowsTable_completedReports",
                "kind": "LinkedHandle",
                "name": "filteredReports"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a040d86e6bc2d7007f5db4dfa9daeb2c",
    "id": null,
    "metadata": {},
    "name": "CompletedWorkflowsPaginationQuery",
    "operationKind": "query",
    "text": "query CompletedWorkflowsPaginationQuery(\n  $count: Int = 10\n  $cursor: Cursor\n  $includeDeleted: IncludeDeletedOption\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CompletedWorkflowTable_company_1G22uz\n    id\n  }\n}\n\nfragment CompletedWorkflowTable_company_1G22uz on Company {\n  completedReports: filteredReports(input: {status: \"completed\"}, after: $cursor, first: $count, orderBy: CREATED_AT_DESC, includeDeleted: $includeDeleted) {\n    edges {\n      node {\n        id\n        ...WorkflowTableRow_report\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ReportContextMenu_report on Report {\n  rowId\n  id\n  name\n  status\n  deletedAt\n  config\n  company {\n    id\n  }\n  reportUserFiles {\n    nodes {\n      type\n      userFile {\n        id\n        rowId\n        kind\n        name\n        key\n      }\n      id\n    }\n  }\n  reportIntegrations {\n    nodes {\n      integration {\n        id\n        owner {\n          id\n        }\n      }\n      id\n    }\n  }\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      key\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          key\n          sections\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment WorkflowTableRow_report on Report {\n  rowId\n  id\n  name\n  status\n  workflowSummary\n  periodStart\n  periodEnd\n  deletedAt\n  ...ReportContextMenu_report\n}\n"
  }
};
})();

(node as any).hash = "765aa34241d4edefc018af6af47b6f0c";

export default node;
