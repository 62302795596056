import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { Dropdown, LinkButton, Icon } from 'elements'
import { useStorage } from 'hooks'

type Props = {
  stripeFile: any
}

export default function StripeHistoryContextMenu(props: Props) {
  const { stripeFile } = props
  const storage = useStorage()

  const data = useFragment(
    graphql`
      fragment StripeHistoryContextMenu_userFile on UserFile {
        rowId
      }
    `,
    stripeFile,
  )

  const actionOptions = [
    {
      label: 'Download',
      borderStyle: 'none',
      onSelect: () => {
        window.location.href = storage.url(data.rowId)
      },
    },
  ]

  return (
    <Dropdown
      position="bottom right"
      options={actionOptions}
      stopPropagation
      renderButton={() => (
        <LinkButton kind="transparent">
          <Icon name="dots-vertical" size="medium" color="neutral.9" />
        </LinkButton>
      )}
    />
  )
}
