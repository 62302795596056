export function getUserName(user: any): string {
  if (user.name) {
    return user.name
  }

  return user.userEmails?.nodes?.[0]?.email
}

export function getUserOrgRole(user: any, organization: any): string | undefined {
  return user?.organizationUsers?.nodes?.find(
    (node: any) => node.organization.rowId === organization?.rowId,
  )?.role
}

export function userIsOrgAdmin(user: any, organization: any): boolean {
  if (user?.isAdmin) {
    return true
  }

  const role = getUserOrgRole(user, organization)
  return role ? role.toUpperCase() === 'ADMIN' : false
}

export function userIsOrgMember(user: any, organization: any): boolean {
  const role = getUserOrgRole(user, organization)
  return role ? role.toUpperCase() === 'MEMBER' : false
}

export function getPrimaryEmail(user: any): string | null {
  return user.userEmails.nodes.find((email: any) => email.isPrimary)?.email || null
}
