import React, { Component } from 'react'
import { AnalyticsContext } from 'contexts'
import { Text, FlexColumn, Icon, Anchor } from 'elements'

export default class ErrorBoundary extends Component {
  static contextType = AnalyticsContext

  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.log('componentDidCatch', { error, info })
    this.context.track('Client Error', { message: error.message, ...info })
  }

  render() {
    if (this.state.hasError) {
      return (
        <FlexColumn p="xlarge" alignItems="center">
          <Icon name="exclamation-circle" color="neutral.2" size="xxlarge" mb="medium" />
          <Text color="neutral.0" mb="small">
            {this.props.message}
          </Text>
          <Text color="neutral.0">
            Please contact <Anchor href="mailto:hello@scrutinize.io">hello@scrutinize.io</Anchor>{' '}
            for help.
          </Text>
        </FlexColumn>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  message: 'Something went wrong.',
}
