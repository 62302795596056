import { SheetData } from '../../types'
import { anomalyDescriptions, standardColumns, codingAnomalies } from '../../constants'
import { sumBy, camelCase, flatten, uniqBy } from 'lodash'
import { ConfigSection } from '../../../../shared/types'
import { getAnomalyDataSections } from '../../utils/anomalies'
import { Anomaly } from '../../types'

type Props = {
  anomaly: Anomaly
  reportEndDate: string
  reportId: string
  configSections?: ConfigSection[]
}

export default function formatAnomalyForExport(props: Props): SheetData | null {
  const { anomaly, reportEndDate, reportId, configSections } = props

  const dataSections = getAnomalyDataSections({
    anomaly,
    context: 'export',
    configSections,
    reportEndDate,
    reportId,
  })

  const name = (anomaly.anomalyName || anomaly.reportName) as string

  if (!dataSections?.length) {
    return null
  }

  const withHeaders = dataSections.map((dataSection) => ({
    ...dataSection,
    columns: standardColumns,
  }))

  const key = camelCase(name)

  let transactionCount = sumBy(dataSections, (dataSection) => dataSection.data!.length)

  // for export data, coding anomaly data is already a flat list of transaction line items. We just have to count them by transactionId
  if (codingAnomalies.includes(key)) {
    const flatTxns = flatten(dataSections.map((dataSection) => dataSection.data))

    transactionCount = uniqBy(flatTxns, 'transactionId').length
  }

  const titleData = {
    'Anomaly Name': name,
    'Anomaly Type': anomaly.anomalyType,
    Transactions: transactionCount,
    Description: anomalyDescriptions[key],
  }

  return {
    sheetName: name,
    titleSection: { titleData },
    dataSections: withHeaders,
  }
}
