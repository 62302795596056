import { sumBy, get, isNil, round } from 'lodash'
import { TransactionLine, Transaction, DataSection } from '../../types'
import {
  typeOneTransactionTypes,
  typeOneIncomeTypes,
  splitLineTableTxnTypes,
} from '../../resolve/constants'
import { parseFormFloat } from '../../../../utils/form'

// returns path to be used by form
export function getLineFieldName(
  transactionValues: Transaction,
  line: TransactionLine,
  lineField: string,
): string {
  const lineIndex = transactionValues.transactionLines.indexOf(line)

  return `transactionLines[${lineIndex}].${lineField}`
}

export function getDiscountLineAmountField(transactionLines: any[]): string | null {
  const discountLine = transactionLines.find((line) => line.lineType === 'discount')
  const idx = transactionLines.indexOf(discountLine)

  // return null if no discount line found
  if (idx >= 0) {
    return `transactionLines[${idx}].lineAmount`
  }

  return null
}

const lineKeys = [
  'lineId',
  'lineNum',
  'lineDescription',
  'lineAccount',
  'lineAccountId',
  'lineItem',
  'lineItemId',
  'lineDebit',
  'lineCredit',
  'lineOriginalAmount',
  'lineAmount',
  'lineQuantity',
  'lineRate',
  'lineLinkedTransactionId',
  'lineLinkedTransactionLineId',
  'lineEntity',
  'lineEntityId',
  'lineType',
  'lineDate',
  'lineOpenBalance',
  'lineLinkedTransactionNum',
  'linePostingType',
  'lineEntityType',
]

export function getLineFieldNames(
  transactionValues: Transaction,
  line: TransactionLine,
): Record<string, string> {
  const res: any = {}

  lineKeys.forEach((key) => {
    res[`${key}Field`] = getLineFieldName(transactionValues, line, key)
  })

  return res
}

type SumLineAmountsProps = {
  transactionLines: TransactionLine[]
  rowIndex?: number
  newAmount?: number
  lineAmountField?: string
}

// the current transaction line amount will not have been updated yet, so pass it in
export function sumLineAmounts(props: SumLineAmountsProps): number {
  const { transactionLines, rowIndex, newAmount, lineAmountField = 'lineAmount' } = props

  // filter lines
  const sumLines = transactionLines.filter((line, idx) => {
    // always filter discount and deleted lines
    let shouldSum: boolean = line.lineType !== 'discount' && !line.deleteLine

    // only filter out current line if there is a new amount to add instead
    if (!isNil(newAmount)) {
      shouldSum = shouldSum && idx !== rowIndex
    }

    return shouldSum
  })

  const sum = sumBy(sumLines, lineAmountField)

  if (!isNil(newAmount)) {
    return sum + newAmount
  }

  return sum
}

type CalcTaxProps = {
  transaction: Transaction
  rowIndex?: number
  newAmount?: number
  lineAmountField?: string
}

export function calcTaxAmount(props: CalcTaxProps): number {
  const { transaction, rowIndex, newAmount, lineAmountField = 'lineAmount' } = props
  const { transactionLines, transactionTaxAfterDiscount, transactionTotalTaxRate } = transaction

  if (!transactionTotalTaxRate) {
    return 0
  }

  const taxRate = transactionTotalTaxRate / 100

  let taxAmount = 0

  const discountRate = transactionLines.find((line) => line.lineType === 'discount')?.lineRate

  transactionLines.forEach((line, idx) => {
    // don't sum discount lines, untaxable lines, or deleted lines
    if (line.lineType === 'discount' || !line.lineTaxable || line.deleteLine) {
      return
    }

    let lineAmount = line[lineAmountField] ?? 0

    // replace line amount with new amount for the current line
    if (!isNil(newAmount) && idx === rowIndex) {
      lineAmount = newAmount
    }

    if (transactionTaxAfterDiscount && discountRate) {
      const discountedLineAmt = lineAmount - lineAmount * discountRate
      const taxedLineAmt = discountedLineAmt * taxRate

      taxAmount = taxAmount + taxedLineAmt

      return
    }

    taxAmount = taxAmount + lineAmount * taxRate
  })

  return round(taxAmount, 2)
}

type TransactionAmountProps = {
  transaction: Transaction
  rowIndex?: number
  newAmount?: number
}

type TransactionAmounts = {
  discountAmount: number
  totalAmount: number
  totalTax: number
}

export function getTransactionAmounts(props: TransactionAmountProps): TransactionAmounts {
  const { transaction, rowIndex, newAmount } = props
  const { transactionLines } = transaction

  const sum = sumLineAmounts({ transactionLines, rowIndex, newAmount })
  const discountRate =
    transactionLines.find((line: any) => line.lineType === 'discount')?.lineRate || 0

  const discountAmount = round(sum * discountRate * -1, 2)

  const totalTax = calcTaxAmount({ transaction, rowIndex, newAmount })

  const totalAmount = round(sum + discountAmount + totalTax, 2)

  return { discountAmount, totalAmount, totalTax }
}

type UpdateTransactionAmountsProps = {
  row: any
  newAmount?: number
  formCtx: any
}
export function updateTransactionAmounts(props: UpdateTransactionAmountsProps): void {
  const { row, newAmount, formCtx } = props
  const { values, setFieldValue } = formCtx
  const { transactionLines, transactionTaxAmount } = values

  const rowIndex = transactionLines.indexOf(row.original)

  const { discountAmount, totalAmount, totalTax } = getTransactionAmounts({
    transaction: values,
    rowIndex,
    newAmount,
  })

  const discountAmountField = getDiscountLineAmountField(transactionLines)

  if (discountAmountField) {
    setFieldValue(discountAmountField, discountAmount)
  }

  if (!isNil(transactionTaxAmount)) {
    setFieldValue('transactionTaxAmount', totalTax)
  }

  setFieldValue('transactionAmount', totalAmount)
}

export function updateLineValues(
  lineValues: Partial<TransactionLine>,
  cellProps: any,
  formCtx: any,
): void {
  const { row } = cellProps
  const { values, setFieldValue } = formCtx

  for (const [key, value] of Object.entries(lineValues)) {
    const field = getLineFieldName(values, row.original, key)

    setFieldValue(field, value)
  }
}

export function updateLineItem(value: any, cellProps: any, formCtx: any) {
  const { row } = cellProps
  const { transactionType } = row.original
  const {
    itemId,
    itemName,
    itemRate,
    itemDescription,
    itemIncomeAccount,
    itemIncomeAccountId,
    itemExpenseAccount,
    itemExpenseAccountId,
  } = value

  const lineValues = {
    lineItem: itemName,
    lineItemId: itemId,
    // quantity resets to 1 when you change the item
    lineQuantity: 1,
    lineRate: itemRate || 0,
    lineAmount: itemRate || 0,
    lineDescription: itemDescription,
    lineAccount: typeOneIncomeTypes.includes(transactionType)
      ? itemIncomeAccount
      : itemExpenseAccount,
    lineAccountId: typeOneIncomeTypes.includes(transactionType)
      ? itemIncomeAccountId
      : itemExpenseAccountId,
  }

  updateLineValues(lineValues, cellProps, formCtx)
  updateTransactionAmounts({ row, newAmount: itemRate || 0, formCtx })
}

export function updateLineQuantity(value: any, cellProps: any, formCtx: any) {
  const { row } = cellProps
  const { values } = formCtx

  const { lineRateField } = getLineFieldNames(values, row.original)

  const quantity = parseFormFloat(value)
  const rate = get(values, lineRateField)

  const lineValues: Partial<TransactionLine> = {
    lineQuantity: quantity,
  }

  if (rate) {
    const multiplier = quantity || 1
    const newAmount = rate * multiplier
    lineValues.lineAmount = newAmount

    updateTransactionAmounts({ row, newAmount, formCtx })
  }

  updateLineValues(lineValues, cellProps, formCtx)
}

export function updateLineRate(value: any, cellProps: any, formCtx: any) {
  const { row } = cellProps
  const { values } = formCtx

  const { lineQuantityField } = getLineFieldNames(values, row.original)

  const rate = parseFormFloat(value)
  const quantity = get(values, lineQuantityField) || 1
  // if rate is null, set amount to zero
  const newAmount = rate ? rate * quantity : 0

  const lineValues = {
    lineRate: rate,
    lineAmount: newAmount,
  }

  updateLineValues(lineValues, cellProps, formCtx)

  updateTransactionAmounts({ row, newAmount, formCtx })
}

export function updateLineAmount(value: any, cellProps: any, formCtx: any): number {
  const { row } = cellProps

  const newAmount = parseFormFloat(value) || 0

  updateLineValues({ lineAmount: newAmount }, cellProps, formCtx)
  updateTransactionAmounts({ row, newAmount, formCtx })

  return newAmount
}

// update rate in addtion to amount for item-based
export function updateItemBasedLineAmount(value: any, cellProps: any, formCtx: any): void {
  const { row } = cellProps
  const { values } = formCtx

  const { lineQuantityField } = getLineFieldNames(values, row.original)
  const quantity = get(values, lineQuantityField) || 1

  const newAmount = updateLineAmount(value, cellProps, formCtx)

  updateLineValues({ lineRate: newAmount / quantity }, cellProps, formCtx)
}

export function updateJournalEntryLineDebit(value: any, cellProps: any, formCtx: any): void {
  const newDebit = parseFormFloat(value)
  const { values, setFieldValue } = formCtx
  const { transactionLines } = values

  // always set debit to convert from empty string to null
  updateLineValues({ lineDebit: newDebit }, cellProps, formCtx)

  // clear debit and set posting type if there's a debit
  if (!isNil(newDebit)) {
    const lineValues = {
      lineCredit: null,
      linePostingType: 'Debit',
      lineAmount: newDebit,
    }

    updateLineValues(lineValues, cellProps, formCtx)
  }

  const totalAmt = sumLineAmounts({
    transactionLines,
    rowIndex: cellProps.row.index,
    newAmount: newDebit || 0,
    lineAmountField: 'lineDebit',
  })

  setFieldValue('transactionAmount', totalAmt)
}

export function updateJournalEntryLineCredit(value: any, cellProps: any, formCtx: any): void {
  const newCredit = parseFormFloat(value)
  const { values, setFieldValue } = formCtx
  const { transactionLines } = values

  // always set credit to convert from empty string to null
  updateLineValues({ lineCredit: newCredit }, cellProps, formCtx)

  // clear debit and set posting type if there's a credit
  if (!isNil(newCredit)) {
    const lineValues = {
      lineDebit: null,
      linePostingType: 'Credit',
      lineAmount: newCredit,
    }

    updateLineValues(lineValues, cellProps, formCtx)

    // only update the total if a credit has been added
    const totalAmt = sumLineAmounts({
      transactionLines,
      rowIndex: cellProps.row.index,
      // remove current debit from total
      newAmount: 0,
      lineAmountField: 'lineDebit',
    })

    setFieldValue('transactionAmount', totalAmt)
  }
}

// set debit and credit equal to the total
export function updateTransferTotal(value: any, formCtx: any): void {
  const { values, setFieldValue } = formCtx
  const { transactionLines } = values

  const transactionTotal = parseFormFloat(value)

  setFieldValue('transactionAmount', transactionTotal)

  transactionLines.forEach((line: TransactionLine, idx: number) => {
    if (!isNil(line.lineDebit)) {
      setFieldValue(`transactionLines[${idx}].lineDebit`, transactionTotal)
    }

    if (!isNil(line.lineCredit)) {
      setFieldValue(`transactionLines[${idx}].lineCredit`, transactionTotal)
    }
  })
}

export function updateTransferLineAccount(value: any, cellProps: any, formCtx: any): void {
  const { accountName, accountId } = value
  const line = cellProps.row.original
  const { lineCredit, lineDebit } = line
  const { setFieldValue } = formCtx

  updateLineValues({ lineAccount: accountName, lineAccountId: accountId }, cellProps, formCtx)

  // set transaction level to/from account, which corresponds with the quickbooks data structure
  if (!isNil(lineCredit)) {
    setFieldValue('transactionFromAccount', accountName)
    setFieldValue('transactionFromAccountId', accountId)
  } else if (!isNil(lineDebit)) {
    setFieldValue('transactionToAccount', accountName)
    setFieldValue('transactionToAccountId', accountId)
  }
}

export function deleteLine(cellProps: any, formCtx: any) {
  const { values, setValues } = formCtx
  const { row } = cellProps

  // if the line was added in this session, we can just remove it
  if (row.original.addLine) {
    setValues({
      ...values,
      transactionLines: values.transactionLines.filter(
        (line: TransactionLine, idx: number) => idx !== row.index,
      ),
    })
  } else {
    updateLineValues({ deleteLine: true }, cellProps, formCtx)
  }

  // because we don't take sales tax into account (currently),

  updateTransactionAmounts({ row, formCtx })
}

// map dataSection keys to line details
const keyLineDetailTypes: Record<string, string> = {
  categoryDetails: 'AccountBasedExpenseLineDetail',
  itemDetails: 'ItemBasedExpenseLineDetail',
}

export function addLine(dataSection: DataSection, formCtx: any) {
  const { values, setValues } = formCtx
  const { transactionType, transactionLines } = values as Transaction

  const newLine: any = {
    addLine: true,
  }

  // for transactions that have item and account based tables, use the dataSection key
  // to determine the line type. lineDetailType must be specified for the transaction types
  // in this list.
  if (splitLineTableTxnTypes.includes(transactionType)) {
    newLine.lineDetailType = keyLineDetailTypes[dataSection.key!]
  } else if (typeOneTransactionTypes.includes(transactionType)) {
    newLine.lineDetailType = 'SalesItemLineDetail'
  } else if (transactionType === 'journalEntry') {
    newLine.lineDetailType = 'JournalEntryLineDetail'
  }

  const newValues = {
    ...values,
    transactionLines: [...transactionLines, newLine],
  }

  setValues(newValues)
}
