import React from 'react'
import {
  Button,
  Modal,
  FlexRow,
  Icon,
  Text,
  Tooltip,
  List,
  ListItem,
  FlexColumn,
  Anchor,
} from 'elements'
import ReportFileUpload from './ReportFileUpload'
import { uploadFileKindOptions } from '../constants'
import ModalContent from '../../app/ModalContent'

const helpLinks = {
  qbo: 'https://scrutinize.freshdesk.com/support/solutions/articles/150000000859',
  desktop: 'https://scrutinize.freshdesk.com/a/solutions/articles/150000000860',
}

export default function FileUploadSelectStep({
  onDone,
  onBack,
  onClose,
  files,
  setFiles,
  fileKinds,
  setFileKinds,
  submitting,
  uploadSource,
}) {
  const sourceFileKindOptions = uploadFileKindOptions[uploadSource]
  const canContinue = Object.keys(fileKinds).length === sourceFileKindOptions.length

  return (
    <>
      <FlexRow px="xxlarge" py="xlarge" justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          <Button
            kind="transparent"
            area="xxsmall"
            onPress={onBack}
            disabled={!onBack}
            opacity="1"
            mr="small"
          >
            <Icon name="arrow-left" mr="medium" color="neutral.2" />
            <Text fontWeight="bold" fontSize="large">
              Upload Files
            </Text>
          </Button>
          <Icon
            size="medium"
            name="exclamation-circle"
            color="neutral.0"
            data-tip
            data-for="upload-tooltip"
          />
          <Tooltip
            id="upload-tooltip"
            place="right"
            backgroundColor="neutral.5"
            opacity="0.9"
            offset={{ bottom: '85px' }}
            padding="0px"
            arrowColor="transparent"
          >
            <FlexColumn color="background.0" px="xxlarge" py="xlarge">
              <Text color="inherit">The following files are required:</Text>
              <FlexRow pl="large">
                <List mb="none">
                  <ListItem kind="background">Account List</ListItem>
                  <ListItem kind="background">General Ledger</ListItem>
                  <ListItem kind="background">Customer Contact List</ListItem>
                  <ListItem kind="background">Vendor Contact List</ListItem>
                </List>
              </FlexRow>
            </FlexColumn>
          </Tooltip>
        </FlexRow>
        <Button kind="transparent" area="xxsmall" onPress={onClose}>
          <Icon name="x" color="neutral.2" />
        </Button>
      </FlexRow>
      <ModalContent>
        <ReportFileUpload
          submitting={submitting}
          files={files}
          setFiles={setFiles}
          uploadSource={uploadSource}
          fileKinds={fileKinds}
          setFileKinds={setFileKinds}
          sourceFileKindOptions={sourceFileKindOptions}
        />
        <FlexRow px="xxlarge">
          <Text fontSize="small">
            Need help? Visit our file upload tutorial{' '}
            <Anchor target="_blank" href={helpLinks[uploadSource]}>
              here
            </Anchor>
            .
          </Text>
        </FlexRow>
      </ModalContent>
      <Modal.Footer display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Button disabled={submitting} onPress={onClose}>
          Cancel
        </Button>
        <Button disabled={submitting || !canContinue} kind="primary" onPress={onDone}>
          {submitting ? 'Saving...' : 'Submit'}
        </Button>
      </Modal.Footer>
    </>
  )
}
