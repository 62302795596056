/**
 * @generated SignedSource<<a731a4cc0f8b14b99f57d8c9845e71ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripeHistoryContextMenu_userFile$data = {
  readonly rowId: any;
  readonly " $fragmentType": "StripeHistoryContextMenu_userFile";
};
export type StripeHistoryContextMenu_userFile$key = {
  readonly " $data"?: StripeHistoryContextMenu_userFile$data;
  readonly " $fragmentSpreads": FragmentRefs<"StripeHistoryContextMenu_userFile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StripeHistoryContextMenu_userFile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    }
  ],
  "type": "UserFile",
  "abstractKey": null
};

(node as any).hash = "b894efe76b1ae7d5be28b003885c5729";

export default node;
