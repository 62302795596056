/**
 * @generated SignedSource<<ec7b911851409538314516399076e8a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportPeriod = {
  endDate?: string | null;
  startDate?: string | null;
};
export type TransactionDrilldownQuery$variables = {
  reportId: string;
  reportPeriod?: ReportPeriod | null;
  transactionProps?: any | null;
  accountProps?: any | null;
};
export type TransactionDrilldownQuery$data = {
  readonly reportById: {
    readonly sourceKind: string | null;
    readonly athenaGeneralLedgerTransactions: any | null;
  } | null;
};
export type TransactionDrilldownQuery = {
  variables: TransactionDrilldownQuery$variables;
  response: TransactionDrilldownQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountProps"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportPeriod"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionProps"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reportId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceKind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "accountProps",
          "variableName": "accountProps"
        },
        {
          "kind": "Variable",
          "name": "reportPeriod",
          "variableName": "reportPeriod"
        },
        {
          "kind": "Variable",
          "name": "transactionProps",
          "variableName": "transactionProps"
        }
      ],
      "kind": "ObjectValue",
      "name": "input"
    }
  ],
  "kind": "ScalarField",
  "name": "athenaGeneralLedgerTransactions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionDrilldownQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TransactionDrilldownQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0eb21508e16d33e27f1969d3e6e73208",
    "id": null,
    "metadata": {},
    "name": "TransactionDrilldownQuery",
    "operationKind": "query",
    "text": "query TransactionDrilldownQuery(\n  $reportId: ID!\n  $reportPeriod: ReportPeriod\n  $transactionProps: JSON\n  $accountProps: JSON\n) {\n  reportById(id: $reportId) {\n    sourceKind\n    athenaGeneralLedgerTransactions(input: {reportPeriod: $reportPeriod, transactionProps: $transactionProps, accountProps: $accountProps})\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c7989df2b5c4608eae4f93d2f1c8daa";

export default node;
