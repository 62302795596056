/**
 * @generated SignedSource<<2e609ecf293394f0e36d31c9cf8b0401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportResolutionTable_report$data = {
  readonly filteredReportResolutions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly anomalyTypes: ReadonlyArray<any | null> | null;
        readonly currentValue: any | null;
        readonly status: string | null;
        readonly updateStatus: string | null;
        readonly responseData: any | null;
        readonly resolutionKind: string | null;
      };
    }>;
  } | null;
  readonly resolutionProgress: number | null;
  readonly reportWorkflowWorkspaces: {
    readonly nodes: ReadonlyArray<{
      readonly reportWorkflowWorkspaceSteps: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly status: string | null;
        }>;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "ReportResolutionTable_report";
};
export type ReportResolutionTable_report$key = {
  readonly " $data"?: ReportResolutionTable_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportResolutionTable_report">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "filteredReportResolutions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "anomalies"
    },
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "resolutionSection"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ReportResolutionTableRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ReportResolutionTable_report",
  "selections": [
    {
      "alias": "filteredReportResolutions",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "anomalies",
              "variableName": "anomalies"
            },
            {
              "kind": "Variable",
              "name": "status",
              "variableName": "resolutionSection"
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "ReportResolutionsConnection",
      "kind": "LinkedField",
      "name": "__ReportResolutionTable_filteredReportResolutions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportResolutionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportResolution",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "anomalyTypes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currentValue",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updateStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "responseData",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resolutionKind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolutionProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "WORKSPACE_ORDER_ASC"
        }
      ],
      "concreteType": "ReportWorkflowWorkspacesConnection",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspaces",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportWorkflowWorkspace",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "STEP_ORDER_ASC"
                }
              ],
              "concreteType": "ReportWorkflowWorkspaceStepsConnection",
              "kind": "LinkedField",
              "name": "reportWorkflowWorkspaceSteps",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportWorkflowWorkspaceStep",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
    },
    (v1/*: any*/)
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "6dae27e22820e1ea245db9744c3ee223";

export default node;
