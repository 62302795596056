/**
 * @generated SignedSource<<80a8bb25f00ad1422fd2ec408ba9a010>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RelatedTransactionsTableQuery$variables = {
  anomalies?: ReadonlyArray<string | null> | null;
  transactionData?: any | null;
  reportId: string;
};
export type RelatedTransactionsTableQuery$data = {
  readonly reportById: {
    readonly id: string;
    readonly filteredReportResolutions: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly currentValue: any | null;
        readonly initialValue: any | null;
        readonly anomalyTypes: ReadonlyArray<any | null> | null;
        readonly status: string | null;
        readonly resolutionKind: string | null;
      }>;
    } | null;
  } | null;
};
export type RelatedTransactionsTableQuery = {
  variables: RelatedTransactionsTableQuery$variables;
  response: RelatedTransactionsTableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anomalies"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionData"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "reportId"
      }
    ],
    "concreteType": "Report",
    "kind": "LinkedField",
    "name": "reportById",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "anomalies",
                "variableName": "anomalies"
              },
              {
                "kind": "Variable",
                "name": "transactionData",
                "variableName": "transactionData"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "ReportResolutionsConnection",
        "kind": "LinkedField",
        "name": "filteredReportResolutions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportResolution",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initialValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "anomalyTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "resolutionKind",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RelatedTransactionsTableQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RelatedTransactionsTableQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "489cf5a7746dfa30cbbf0241778e3b28",
    "id": null,
    "metadata": {},
    "name": "RelatedTransactionsTableQuery",
    "operationKind": "query",
    "text": "query RelatedTransactionsTableQuery(\n  $anomalies: [String]\n  $transactionData: JSON\n  $reportId: ID!\n) {\n  reportById(id: $reportId) {\n    id\n    filteredReportResolutions(input: {anomalies: $anomalies, transactionData: $transactionData}) {\n      nodes {\n        id\n        currentValue\n        initialValue\n        anomalyTypes\n        status\n        resolutionKind\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0e49b54e7ae9ed4197ab5aad4a59fd7";

export default node;
