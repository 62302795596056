import React from 'react'
import { FlexRow, FlexColumn, LinkButton, Text, Image, IntuitConnect, Box } from 'elements'
import IntegrationStatus from './IntegrationStatus'

const headerColors: Record<string, string> = {
  disabled: 'neutral.1',
  enabled: 'primary.0',
  error: 'danger.0',
}

const BUTTON_LABELS: Record<string, string> = {
  disabled: 'Reconnect',
  error: 'Try again',
}

type Props = {
  userIntegration: any
  logo: any
  logoWidth?: string
  onConfigure?: Function
  onDelete: Function
  onConnect?: Function
  integrationKind: string
}

export default function IntegrationBox(props: Props) {
  const {
    userIntegration,
    logo,
    logoWidth = '100%',
    onConfigure,
    onDelete,
    onConnect,
    integrationKind,
  } = props

  const status = userIntegration?.status?.toLowerCase()

  let buttonContent = (
    <Text fontSize="small" color="inherit">
      Connect
    </Text>
  )

  if (BUTTON_LABELS[status]) {
    buttonContent = (
      <Text fontSize="small" color="inherit">
        {BUTTON_LABELS[status]}
      </Text>
    )
  } else if (integrationKind === 'intuit') {
    buttonContent = <IntuitConnect />
  }

  return (
    <FlexColumn height="250px">
      <FlexRow
        px="large"
        py="medium"
        borderRadius="9px 9px 0 0"
        bg={headerColors[status] || 'neutral.1'}
      >
        <IntegrationStatus status={status} userIntegration={userIntegration} />
      </FlexRow>
      <FlexColumn
        flex="1"
        border="2px solid"
        borderTop="none"
        borderColor="neutral.2"
        borderRadius="0 0 9px 9px"
      >
        <FlexRow flex="1" alignItems="center" justifyContent="center" px="xxlarge" py="large">
          <Image width={logoWidth} src={logo} />
        </FlexRow>
        <FlexRow
          height="35%"
          px="medium"
          borderTop="2px solid"
          borderColor="neutral.2"
          justifyContent="center"
          alignItems="center"
        >
          {status === 'enabled' ? (
            <>
              <LinkButton onClick={onDelete}>
                <Text fontSize="small" color="neutral.3">
                  Disconnect
                </Text>
              </LinkButton>

              {onConfigure ? (
                <LinkButton onClick={onConfigure}>
                  <Text fontSize="small" color="primary.0">
                    Configure
                  </Text>
                </LinkButton>
              ) : null}
            </>
          ) : (
            <Box>
              <LinkButton area="xxsmall" onClick={onConnect} color="primary.0">
                {buttonContent}
              </LinkButton>
            </Box>
          )}
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}
