/**
 * @generated SignedSource<<8b5f79b0b6df30a91d88ba32b15b49ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReportStatus = "DRAFT" | "ERROR" | "PENDING" | "PROCESSING" | "SUCCESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReportViewContainer_report$data = {
  readonly id: string;
  readonly rowId: any;
  readonly createdAt: any;
  readonly periodStart: any | null;
  readonly periodEnd: any | null;
  readonly config: any | null;
  readonly status: ReportStatus;
  readonly reviewStatus: string | null;
  readonly sourceKind: string | null;
  readonly reportIntegrations: {
    readonly nodes: ReadonlyArray<{
      readonly integration: {
        readonly id: string;
        readonly kind: string;
      } | null;
    }>;
  };
  readonly reportWorkflowWorkspaces: {
    readonly nodes: ReadonlyArray<{
      readonly key: string;
      readonly reportWorkflowWorkspaceSteps: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly key: string;
          readonly sections: any | null;
        }>;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowDashboard_report" | "ReportStatusDetails_report">;
  readonly " $fragmentType": "ReportViewContainer_report";
};
export type ReportViewContainer_report$key = {
  readonly " $data"?: ReportViewContainer_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportViewContainer_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportViewContainer_report",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceKind",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkflowDashboard_report"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportStatusDetails_report"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportIntegrationsConnection",
      "kind": "LinkedField",
      "name": "reportIntegrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportIntegration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Integration",
              "kind": "LinkedField",
              "name": "integration",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "WORKSPACE_ORDER_ASC"
        }
      ],
      "concreteType": "ReportWorkflowWorkspacesConnection",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspaces",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportWorkflowWorkspace",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "STEP_ORDER_ASC"
                }
              ],
              "concreteType": "ReportWorkflowWorkspaceStepsConnection",
              "kind": "LinkedField",
              "name": "reportWorkflowWorkspaceSteps",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportWorkflowWorkspaceStep",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sections",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "7196ba2b4003eda5c0fb28a330b49af0";

export default node;
