import React, { useState } from 'react'
import FirstClientUploadStep from './FirstClientUploadStep'
import { uploadFileKindOptions } from '../constants'
import { useHistory } from 'react-router-dom'
import { map } from 'lodash'
import { useAuth, useAnalytics, useFileUpload } from 'hooks'
import useReportCreate from '../hooks/useReportCreate'
import useReportAddFile from '../hooks/useReportAddFile'
import useReportRun from '../hooks/useReportRun'
import FirstClientSubmitStep from './FirstClientSubmitStep'

type Props = {
  step: number
  submitting: boolean
  setSubmitting: Function
  reportPeriod: any
  onReportPeriodChange: Function
  validReportPeriod: boolean
  workflowTemplate: any
  onWorkflowTemplateChange: Function
  onNext: Function
  onBack: Function
  onClose: Function
}

export default function FirstClientUploadReport(props: Props) {
  const {
    step,
    submitting,
    setSubmitting,
    reportPeriod,
    onReportPeriodChange,
    validReportPeriod,
    workflowTemplate,
    onWorkflowTemplateChange,
    onNext,
    onBack,
    onClose,
  } = props

  const [uploadSource, setUploadSource] = useState('qbo')
  const [files, setFiles] = useState([])
  const [fileKinds, setFileKinds] = useState({})
  const analytics = useAnalytics()
  const history = useHistory()
  const [createReport]: any = useReportCreate()
  const [addReportFile]: any = useReportAddFile()
  const [runReport]: any = useReportRun()
  const { currentCompany, currentOrg } = useAuth()
  const [uploadFiles]: any = useFileUpload()

  const handleCreate = async () => {
    setSubmitting(true)

    const { report } = await createReport(
      {
        companyId: currentCompany!.id,
        kind: 'anomaly:1',
        workflowTemplateId: workflowTemplate.id,
        periodStart: reportPeriod.interval.start.toISODate(),
        periodEnd: reportPeriod.interval.end.toISODate(),
      },
      { includeDeleted: 'NO' },
    )

    // build file descriptors
    const descriptors = map(fileKinds, (file, kind) => ({ kind, file }))

    // upload files
    const uploadResults = await uploadFiles(`reports/${report.rowId}/source`, descriptors)

    // create report inputs
    for (let i = 0; i < uploadResults.length; i++) {
      await addReportFile({
        type: 'SOURCE',
        reportId: report.rowId,
        userFileId: uploadResults[i].id,
      })
    }

    // call the runReport lambda, but don't wait for it since
    // we'll get updates via report subscription
    runReport({ reportId: report.id })

    analytics.track('Report Created', { reportId: report.rowId })
    analytics.track('First Client Flow Completed', {
      companyId: currentCompany!.id,
      reportId: report.rowId,
    })

    onClose()

    history.push(`/orgs/${currentOrg!.slug}/companies/${currentCompany!.id}/reports/${report.id}`)
  }

  const sourceFileKindOptions = uploadFileKindOptions[uploadSource]
  const canContinue = Object.keys(fileKinds).length === sourceFileKindOptions.length

  if (step === 2) {
    return (
      <FirstClientUploadStep
        onChange={setUploadSource}
        submitting={submitting}
        files={files}
        setFiles={setFiles}
        uploadSource={uploadSource}
        fileKinds={fileKinds}
        setFileKinds={setFileKinds}
        sourceFileKindOptions={sourceFileKindOptions}
        canContinue={canContinue}
        onNext={onNext}
        onBack={onBack}
        onClose={onClose}
      />
    )
  }

  return (
    <FirstClientSubmitStep
      onBack={onBack}
      onClose={onClose}
      submitting={submitting}
      reportPeriod={reportPeriod}
      onReportPeriodChange={onReportPeriodChange}
      valid={validReportPeriod}
      handleCreate={handleCreate}
      workflowTemplate={workflowTemplate}
      onWorkflowTemplateChange={onWorkflowTemplateChange}
    />
  )
}
