/**
 * @generated SignedSource<<0d26ff714582e754d479cc9fedf06056>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReportStatus = "DRAFT" | "ERROR" | "PENDING" | "PROCESSING" | "SUCCESS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkflowTableRow_report$data = {
  readonly rowId: any;
  readonly id: string;
  readonly name: string;
  readonly status: ReportStatus;
  readonly workflowSummary: any | null;
  readonly periodStart: any | null;
  readonly periodEnd: any | null;
  readonly deletedAt: any | null;
  readonly " $fragmentSpreads": FragmentRefs<"ReportContextMenu_report">;
  readonly " $fragmentType": "WorkflowTableRow_report";
};
export type WorkflowTableRow_report$key = {
  readonly " $data"?: WorkflowTableRow_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowTableRow_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkflowTableRow_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workflowSummary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportContextMenu_report"
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "867be8801c4212e4533efbcd5c2e889d";

export default node;
