import React, { useRef, useEffect, useState } from 'react'
import { TextInput, Box, Icon } from 'elements'

// medium icon size
const ICON_SIZE = 24

export default function IconInput({ icon, styleProps, ...props }) {
  const ref = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (ref) {
      setHeight(ref.current.offsetHeight)
    }
  }, [ref])

  return (
    <Box position="relative" ref={ref} color={styleProps?.color}>
      <Icon
        size="medium"
        position="absolute"
        // center the icon in the input
        top={`${height / 2 - ICON_SIZE / 2}px`}
        left="large"
        name={icon}
        color="inherit"
      />
      <TextInput pl="xxxlarge" color="inherit" {...props} />
    </Box>
  )
}
