import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useCompaniesCreateMutation($input: CreateCompaniesInput!) {
    createCompanies(input: $input) {
      insertedCompanies
    }
  }
`

export default function useCompaniesCreate() {
  return useMutation(mutation, 'createCompanies')
}
