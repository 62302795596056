import React, { useState } from 'react'
import { orderBy, isEqual, keys } from 'lodash'
import { FlexColumn, FlexRow, Text, Card, SuspenseBoundary } from 'elements'
import { formatName } from 'utils/string'
import { ResolutionAnomalyType } from '../types'
import SeverityCircle from '../../common/SeverityCircle'
import { anomalyInfo, duplicateAnomalies, codingAnomalies } from '../constants'
import RelatedTransactionsTable from './RelatedTransactionsTable'

type Props = {
  anomalyTypes: ResolutionAnomalyType[]
  reportId: string
  resolution: any
  onClose: () => boolean
  anomalyView?: string
  canEdit?: boolean
}

const Head = (props: any) => <Text fontWeight="bold" mb="small" lineHeight="large" {...props} />
const Body = (props: any) => <Text mb="xlarge" lineHeight="large" {...props} />

export default function AnomaliesInfo(props: Props) {
  const { anomalyTypes, reportId, resolution, onClose, anomalyView, canEdit } = props

  const tabs = orderBy(anomalyTypes, 'severity', 'desc')
    .filter((anomalyType) => keys(anomalyInfo).includes(anomalyType.type))
    .map((anomalyType) => ({
      label: formatName(anomalyType.type),
      value: anomalyType,
    }))

  const [selected, setSelected] = useState<ResolutionAnomalyType | null>(tabs[0]?.value || null)

  if (!selected) {
    return null
  }

  const displayRelatedTable =
    duplicateAnomalies.includes(selected.type) || codingAnomalies.includes(selected.type)

  return (
    <FlexColumn>
      <Text mb="medium">Anomalies</Text>
      <Card p="xxlarge">
        <FlexRow width="100%">
          <FlexColumn flex="1" mr="xxxlarge">
            {tabs.map((tab, idx) => (
              <FlexRow
                key={tab.label}
                p="large"
                mb={idx !== tabs.length - 1 && 'medium'}
                alignItems="center"
                borderRadius="small"
                onClick={() => setSelected(tab.value)}
                bg={isEqual(tab.value, selected) && 'neutral.7'}
              >
                <SeverityCircle
                  size="xlarge"
                  severity={tab.value.severity}
                  styleProps={{ boxProps: { mr: 'medium' } }}
                />
                <Text fontWeight="bold">{tab.label}</Text>
              </FlexRow>
            ))}
          </FlexColumn>
          <FlexColumn flex="3" pt="xsmall">
            <Text fontSize="xxlarge" lineHeight="xxlarge" fontWeight="bold" mb="xlarge">
              {formatName(selected.type)}
            </Text>
            <Head>What does this mean?</Head>
            <Body>{anomalyInfo[selected.type].meaning}</Body>
            <Head>Why is this an issue?</Head>
            <Body>{anomalyInfo[selected.type].reason}</Body>
            <Head>How to remedy?</Head>
            <Body>{anomalyInfo[selected.type].remedy}</Body>
            {displayRelatedTable && (
              <>
                <Head>Related Transactions</Head>
                <SuspenseBoundary>
                  <RelatedTransactionsTable
                    selectedAnomalyType={selected!}
                    resolution={resolution}
                    onClose={onClose}
                    reportId={reportId}
                    anomalyView={anomalyView}
                    canEdit={canEdit}
                  />
                </SuspenseBoundary>
              </>
            )}
          </FlexColumn>
        </FlexRow>
      </Card>
    </FlexColumn>
  )
}
