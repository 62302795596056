import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
// import Icon from '../Icon'
import Text from '../Text'
import styled from 'styled-components'
import { variant, compose } from 'styled-system'

const area = variant({
  prop: 'area',
  variants: {
    small: {
      fontSize: 'medium',
      px: 'medium',
      minHeight: '42px',
    },
    medium: {
      fontSize: 'medium',
      p: 'medium',
    },
    large: {
      fontSize: 'large',
      p: 'large',
    },
  },
})

const kind = variant({
  prop: 'kind',
  variants: {
    info: {
      color: 'foreground.0',
      bg: 'neutral.1',
    },
    success: {
      color: 'background.0',
      bg: 'success.0',
    },
    warning: {
      color: 'foreground.0',
      bg: 'warning.0',
    },
    danger: {
      color: 'background.0',
      bg: 'danger.0',
    },
  },
})

const MessageBox = styled(Box)(compose(area, kind))

function Message({ children, ...props }) {
  return (
    <MessageBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex="1"
      borderRadius="small"
      {...props}
    >
      {/* <Icon mr="small" name={iconName} color={iconColor} size={iconSize} /> */}
      <Text color="inherit" fontSize="inherit">
        {children || '--'}
      </Text>
    </MessageBox>
  )
}

Message.propTypes = {
  children: PropTypes.string.isRequired,
  kind: PropTypes.string,
  area: PropTypes.string,
}

Message.defaultProps = {
  kind: 'info',
  area: 'medium',
}

export default Message
