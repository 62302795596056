import React from 'react'
// @ts-ignore
import styled from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  // @ts-ignore
} from 'styled-system'
import { print, interactive, spin } from '../styles'
import { filterProps } from '../utils'
import theme from 'theme'

const StyledBox = filterProps(styled.div)`
  ${compose(space, layout, typography, color, flexbox, grid, background, border, position, shadow)}
  ${print}
  ${interactive}
  ${spin}

   ${({ gap }: any) => gap && `gap: ${theme.space[gap]}px;`}
  ${({ float }: any) => float && `float: ${float};`}
  ${({ whiteSpace }: any) => whiteSpace && `white-space: ${whiteSpace};`}
  
`

const Box = React.forwardRef((props: any, ref) => <StyledBox {...props} ref={ref} />)

export default Box
