import React, { lazy } from 'react'
import { round, merge } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'
import Text from '../Text'
import FlexColumn from '../FlexColumn'
import ToggleBox from '../ToggleBox'

const Chart = lazy(() => import('react-apexcharts'))

const defaultOptions = {
  xaxis: {
    labels: {
      show: false,
      hideOverlappingLabels: false,
      rotate: 0,
      trim: true,
    },
  },
  yaxis: {
    title: {
      offsetX: -5,
      offsetY: 0,
    },
    labels: {
      formatter: function (val) {
        return round(val, 0)
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: '70%',
      dataLabels: {
        position: 'top', // top, center, bottom
      },
      distributed: true,
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return round(val, 0).toLocaleString()
    },
    offsetY: -20,
  },
  legend: {
    show: true,
    itemMargin: { horizontal: theme.sizes.small, vertical: theme.sizes.small },
    fontSize: theme.fontSizes.small,
    labels: {
      colors: [theme.colors.foreground[0]],
    },
  },
  tooltip: {
    x: { show: true },
    y: {
      title: {
        formatter: () => {
          return null
        },
      },
    },
  },
}

const chartOptions = merge({}, baseChartOptions, defaultOptions)

export default function BarChart({
  options = defaultOptions,
  width,
  height,
  series,
  title,
  toggleBox,
  ...props
}) {
  return (
    <>
      {title ? (
        <Text fontSize="large" fontWeight="bold" ml="xsmall" mb="medium">
          {title}
        </Text>
      ) : null}

      <FlexColumn
        borderRadius="small"
        bg="background.0"
        px="medium"
        pt={toggleBox ? 'medium' : 'xxxlarge'}
      >
        {toggleBox ? (
          <ToggleBox
            ml="auto"
            mr="medium"
            tabs={toggleBox.tabs}
            currentTab={toggleBox.currentTab}
            onChange={toggleBox.onChange}
          />
        ) : null}
        <Chart
          options={merge({}, chartOptions, options)}
          series={series}
          type="bar"
          width={width}
          height={height}
        />
      </FlexColumn>
    </>
  )
}
