import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportRestoreMutation($input: RestoreReportInput!) {
    restoreReport(input: $input) {
      report {
        id
        rowId
        workflowSummary
        company {
          id
          ...PeriodSelect_company
        }
        ...WorkflowTableRow_report
      }
    }
  }
`

const updater = (store, { restoreReport }) => {
  const parentCompId = restoreReport.report.company.id
  const company = store.get(parentCompId)

  const payload = store.getRootField('restoreReport')
  const restoredReport = payload.getLinkedRecord('report')

  // add to tables where includeDeleted is false
  const activeConnection = ConnectionHandler.getConnection(
    company,
    'ActiveWorkflowsTable_activeReports',
    {
      input: { status: 'active' },
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const completedConnection = ConnectionHandler.getConnection(
    company,
    'CompletedWorkflowsTable_completedReports',
    {
      input: { status: 'completed' },
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const isComplete = restoreReport.report.workflowSummary.completion === 100

  if (isComplete && completedConnection) {
    const newEdge = ConnectionHandler.createEdge(
      store,
      completedConnection,
      restoredReport,
      'ReportEdge',
    )

    ConnectionHandler.insertEdgeBefore(completedConnection, newEdge)
  } else if (activeConnection) {
    const newEdge = ConnectionHandler.createEdge(
      store,
      activeConnection,
      restoredReport,
      'ReportEdge',
    )

    ConnectionHandler.insertEdgeBefore(activeConnection, newEdge)
  }
}

export default function useReportRestore() {
  return useMutation(mutation, 'restoreReport', { updater })
}
