import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { Select, Text } from 'elements'
import { Option } from '../../shared/types'
import { ReportPeriod } from '../../shared/types'

type Props = {
  workflowTemplate: any
  onWorkflowTemplateChange: Function
  requirePeriod?: boolean
  period?: ReportPeriod | null
  organization: any
}

export default function WorkflowTemplateSelect(props: Props) {
  const {
    workflowTemplate,
    onWorkflowTemplateChange,
    requirePeriod = true,
    period,
    organization,
  } = props

  const data = useFragment<any>(
    graphql`
      fragment WorkflowTemplateSelect_organization on Organization {
        workflowTemplatesWithDefaults(first: 100, includeDeleted: NO, orderBy: CREATED_AT_DESC)
          @connection(key: "WorkflowTemplateSelect_workflowTemplatesWithDefaults") {
          edges {
            node {
              id
              rowId
              name
            }
          }
        }
      }
    `,
    organization,
  )

  const options: Option[] =
    data?.workflowTemplatesWithDefaults?.edges?.map((edge: any) => ({
      label: edge.node.name,
      value: edge.node,
    })) || []

  let value = options.find((option) => option.value.id === workflowTemplate?.id)

  if (!value && workflowTemplate) {
    value = { label: `${workflowTemplate.name} (deleted)`, value: workflowTemplate }
  }

  return (
    // @ts-ignore
    <Select
      options={options}
      value={value}
      onChange={onWorkflowTemplateChange}
      placeholder="Select Workflow"
      disabled={requirePeriod && !period}
      formatOptionLabel={({ label }: Option) => (
        <Text truncate title={label}>
          {label}
        </Text>
      )}
    />
  )
}
