import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { variant, border, space, typography, color } from 'styled-system'
import { interactive } from '../styles'

const kind = variant({
  prop: 'kind',
  variants: {
    warning: {
      borderColor: 'warning.0',
      borderWidth: 'thin',
    },
    danger: {
      borderColor: 'danger.0',
      borderWidth: 'thin',
    },
  },
})

const area = variant({
  prop: 'area',
  variants: {
    small: {
      fontSize: 'small',
      px: 'medium',
      height: '42px',
      borderRadius: 'xsmall',
    },
    medium: {
      fontSize: 'large',
      px: 'medium',
      borderRadius: 'small',
      height: '50px',
    },
    large: {
      fontSize: 'large',
      px: 'medium',
      py: 'small',
      borderRadius: 'small',
    },
  },
})

const base = css`
  width: 100%;
  white-space: nowrap;
  vertical-align: middle;
  border-style: solid;
  border-color: ${themeGet('colors.foreground.3')};
  transition: all 200ms ease;
  font-family: inherit;

  &:focus {
    outline: 0;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${themeGet('colors.neutral.2')};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${themeGet('colors.neutral.2')};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${themeGet('colors.neutral.2')};
  }
`

const TextInput = styled.input`
  ${base}
  ${area}
  ${kind}
  ${border}
  ${space}
  ${typography}
  ${color}
  ${interactive}


  textarea& {
    height: ${({ height = '50' }) => height}px;
    line-height: 1.2;
    white-space: normal;
    padding: 0.5rem;
  }
`

TextInput.defaultProps = {
  area: 'medium',
  color: 'secondary.0',
  background: 'background.0',
}

export default TextInput
