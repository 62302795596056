import { useEffect } from 'react'

type ModalState = {
  isClosing: boolean
  onClose: Function
  onAfterClose: Function
  onMinimize: Function
  behavior: string
  setHistoryBlock: Function
  closeAllModals: Function
}

type CloseFunctionOpts = {
  force?: boolean // force the modal to close, disregarding shouldClose and dirty state
}

type MinimizeFunctionOpts = {}

type AllowedPath = {
  path: string
}

type PathCheck = {
  location: any
  path: string
  trailing?: boolean
}

type ModalBehavior = {
  shouldClose?: (params: CloseFunctionOpts) => boolean
  shouldMinimize?: (params: MinimizeFunctionOpts) => boolean
  isDirty?: boolean
  closingBehavior?: Function
  allowedPaths: Array<AllowedPath>
}

const isModalAllowed = (check: PathCheck): boolean => {
  const { location, path, trailing } = check

  if (!trailing && location.pathname.includes(path)) {
    return true
  }

  if (trailing && location.pathname.substr(location.pathname.length - path.length) === path) {
    return true
  }

  return false
}

export default function useModalBehavior(modal: ModalState, behavior?: ModalBehavior): ModalState {
  const modalBehavior = { ...modal }
  const { shouldClose, shouldMinimize = () => true, isDirty, allowedPaths, closingBehavior } =
    behavior || {}

  if (shouldClose) {
    modalBehavior.onClose = async (params: CloseFunctionOpts) => {
      if (params?.force === true || (await shouldClose(params))) {
        //function to execute before closing
        if (closingBehavior) {
          await closingBehavior()
        }
        modal.onClose()
        return true
      }
      return false
    }
  } else if (isDirty) {
    modalBehavior.onClose = async () => {
      if (window.confirm('You have unsaved changes. Would you like to navigate anyway?')) {
        if (closingBehavior) {
          await closingBehavior()
        }
        modal.onClose()
        return true
      }
      return false
    }
  } else if (closingBehavior) {
    modalBehavior.onClose = async () => {
      await closingBehavior()
      modal.onClose()
    }
  }

  if (shouldMinimize && modal.onMinimize) {
    modalBehavior.onMinimize = async (params: MinimizeFunctionOpts) => {
      if (await shouldMinimize(params)) {
        modal.onMinimize()
        return true
      }
      return false
    }
  }

  useEffect(() => {
    modal.setHistoryBlock((location: object) => {
      let isAllowedToNavigate = false

      if (allowedPaths) {
        isAllowedToNavigate = allowedPaths.some((opts) =>
          isModalAllowed({
            location,
            ...opts,
          }),
        )
        if (isAllowedToNavigate) {
          return undefined
        }
      }

      let isClosing = false

      if (isDirty) {
        isClosing = modalBehavior.onClose()
      } else {
        modalBehavior.onClose()
        return undefined
      }

      if (!isClosing) {
        return false
      }

      return undefined
    })
  }, [isDirty]) // eslint-disable-line

  return modalBehavior
}
