import { compact, xorWith, isEqual, isNil } from 'lodash'
import { DateTime } from 'luxon'
import { ConfigSection } from '../../../shared/types'
import { DataSection } from '../types'

export function filterByDaysElapsed(
  data: any[],
  reportEndDate: string,
  daysElapsed: number,
): any[] {
  const endDate = DateTime.fromISO(reportEndDate)

  if (!endDate.isValid) {
    throw new Error(`invalid end date format: ${endDate}`)
  }

  const filterDate = endDate.minus({ days: daysElapsed })

  //allow for nested data – the transaction-level data may be at a lower level
  if (data[0]?.data) {
    return data
      .map((datum) => ({
        ...datum,
        data: filterByDaysElapsed(datum.data, reportEndDate, daysElapsed),
      }))
      .filter((datum) => datum.data.length)
  }

  return data.filter((datum) => {
    const transactionDate = DateTime.fromISO(datum.transactionDate.substring(0, 10))

    if (!transactionDate.isValid) {
      throw new Error(`invalid transaction date format: ${transactionDate}`)
    }

    return transactionDate <= filterDate
  })
}

export function filterByAmount(data: any[], amount: number): any[] {
  if (data[0]?.data) {
    return data
      .map((datum) => ({ ...datum, data: filterByAmount(datum.data, amount) }))
      .filter((datum) => datum.data.length)
  }

  return data.filter((datum) => Math.abs(datum.transactionAmount) >= amount)
}

type ConfigDataSectionsProps = {
  configSections?: ConfigSection[]
  dataSections: DataSection[]
  reportEndDate: string
}

export function applyConfigDataSections(props: ConfigDataSectionsProps): any[] {
  const { configSections, dataSections, reportEndDate } = props

  if (!configSections?.length) {
    return dataSections
  }

  const configured = dataSections.map((dataSection) => {
    const configSection = configSections.find((configSection) => {
      return dataSection.key === configSection.name
    })

    if (!configSection) {
      return null
    }

    const { config } = configSection

    if (!config) {
      return dataSection
    }

    let data = [...dataSection.data!]

    if (!isNil(config.amount)) {
      data = filterByAmount(data, config.amount)
    }

    if (config.daysElapsed) {
      data = filterByDaysElapsed(data, reportEndDate, config.daysElapsed)
    }

    if (!data.length) {
      return null
    }

    return { ...dataSection, data, config }
  })

  return compact(configured)
}

type ConfigReportSectionsProps = {
  configSections?: ConfigSection[]
  reportSections: any[]
  reportEndDate: string
}

export function applyConfigReportSections(props: ConfigReportSectionsProps) {
  const { configSections, reportSections, reportEndDate } = props

  const configuredReportSections = reportSections.map((reportSection) => {
    //don't filter out transaction by type sections (which don't have template options)
    if (reportSection.kind === 'transactionByType') {
      return reportSection
    }

    const configuredDataSections = applyConfigDataSections({
      configSections,
      dataSections: reportSection.dataSections,
      reportEndDate,
    })

    if (configuredDataSections.length) {
      return { ...reportSection, dataSections: configuredDataSections }
    }

    return null
  })

  return compact(configuredReportSections)
}

//check if two arrays of objects are equivalent
export function equalGroups(groupOne: any[], groupTwo: any[]): boolean {
  if (groupOne.length !== groupTwo.length) {
    return false
  }

  const diff = xorWith(groupOne, groupTwo, isEqual)

  return diff.length === 0
}
