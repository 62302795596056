/**
 * @generated SignedSource<<71bbacbb03e21b0c068091e0f4d83408>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteIntegrationInput = {
  integrationId: string;
};
export type useIntegrationDeleteMutation$variables = {
  input: DeleteIntegrationInput;
};
export type useIntegrationDeleteMutation$data = {
  readonly deleteIntegration: {
    readonly integration: {
      readonly id: string;
      readonly company: {
        readonly id: string;
      } | null;
    };
  } | null;
};
export type useIntegrationDeleteMutation = {
  variables: useIntegrationDeleteMutation$variables;
  response: useIntegrationDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteIntegrationPayload",
    "kind": "LinkedField",
    "name": "deleteIntegration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Integration",
        "kind": "LinkedField",
        "name": "integration",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useIntegrationDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useIntegrationDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "15bee5feb3737513613204342b835d78",
    "id": null,
    "metadata": {},
    "name": "useIntegrationDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation useIntegrationDeleteMutation(\n  $input: DeleteIntegrationInput!\n) {\n  deleteIntegration(input: $input) {\n    integration {\n      id\n      company {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef83818bb12e64df775f5e93a6c880f6";

export default node;
