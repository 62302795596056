import React from 'react'
import { useFormikContext } from 'formik'
import { isNil } from 'lodash'
import { Button, Modal, FlexRow, Text, Icon, Anchor } from 'elements'
import ModalContent from 'components/app/ModalContent'
import AnomaliesInfo from '../resolve/AnomaliesInfo'
import TransactionInfo from '../resolve/TransactionInfo'
import { toTitleCase } from '../../../utils/string'
import ModalTransactionLineTables from '../resolve/ModalTransactionLineTables'
import { Transaction } from '../types'
import TransactionTotalAmount from '../resolve/TransactionTotalAmount'
import TransactionTaxRate from '../resolve/TransactionTaxRate'
import config from 'config'
import { getTransactionTypeRoute } from '../utils/formatting'

type Props = {
  resolution?: any
  modal: any
  reportId: string
}

export default function TransactionModalInfo(props: Props) {
  const { resolution, modal, reportId } = props

  const formCtx = useFormikContext()
  const transactionData = formCtx.initialValues as Transaction
  const { transactionNum, transactionType, transactionId } = transactionData

  const displayType = toTitleCase(transactionType)
  const displayNum = transactionNum ? `#${transactionNum}` : ''

  const handleClose = () => {
    modal.onClose()

    return true
  }

  const transactionTypeRoute = getTransactionTypeRoute(transactionType)

  return (
    <>
      <ModalContent bg="background.1" height="100%" px="gigantic">
        <FlexRow justifyContent="flex-end" pb="xxlarge">
          <Button kind="transparent" area="xxsmall" onClick={modal.onClose}>
            <Icon name="x" color="neutral.1" size="medium" />
          </Button>
        </FlexRow>

        <FlexRow justifyContent="space-between" mb="gigantic">
          <FlexRow alignItems="center">
            <Text fontSize="xgigantic" lineHeight="xgigantic" fontWeight="bold" mr="medium">
              {displayType} {displayNum}
            </Text>
            {transactionId && (
              <Anchor
                color="foreground.0"
                target="_blank"
                href={`${config.services.intuit.host}/${transactionTypeRoute}?txnId=${transactionId}`}
              >
                <Icon name="arrow-top-right-square" size="large" />
              </Anchor>
            )}
          </FlexRow>
          <TransactionTotalAmount
            transactionAmount={transactionData.transactionAmount}
            canEditAmount={false}
          />
        </FlexRow>
        <TransactionInfo
          transactionValues={transactionData}
          canEdit={false}
          styleProps={{ mb: 'xxlarge' }}
        />
        <ModalTransactionLineTables
          canEdit={false}
          anomalyTypes={resolution?.anomalyTypes}
          styleProps={{ boxProps: { mb: 'xxxlarge' } }}
        />
        {!isNil(transactionData.transactionTotalTaxRate) && (
          <FlexRow justifyContent="flex-end" mb="xxxlarge">
            <TransactionTaxRate />
          </FlexRow>
        )}
        {resolution && (
          <AnomaliesInfo
            anomalyTypes={resolution?.anomalyTypes}
            reportId={reportId}
            resolution={resolution}
            onClose={handleClose}
            canEdit={false}
          />
        )}
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer display="flex" alignItems="center" justifyContent="flex-end" modal={modal}>
        <Button kind="alt-neutral-2" onClick={modal.onClose} mr="gigantic">
          Close
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
