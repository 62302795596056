/**
 * @generated SignedSource<<ed760c60f2799e3a4b41cf8375b397e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportStatus = "DRAFT" | "ERROR" | "PENDING" | "PROCESSING" | "SUCCESS" | "%future added value";
export type RetryReportInput = {
  reportId: string;
};
export type useReportRetryMutation$variables = {
  input: RetryReportInput;
};
export type useReportRetryMutation$data = {
  readonly retryReport: {
    readonly report: {
      readonly status: ReportStatus;
    };
  } | null;
};
export type useReportRetryMutation = {
  variables: useReportRetryMutation$variables;
  response: useReportRetryMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReportRetryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetryReportPayload",
        "kind": "LinkedField",
        "name": "retryReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReportRetryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetryReportPayload",
        "kind": "LinkedField",
        "name": "retryReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "126c024f459bedef511497228cb921a3",
    "id": null,
    "metadata": {},
    "name": "useReportRetryMutation",
    "operationKind": "mutation",
    "text": "mutation useReportRetryMutation(\n  $input: RetryReportInput!\n) {\n  retryReport(input: $input) {\n    report {\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7697d0606cbe0aa765be25309f5d158";

export default node;
