/**
 * @generated SignedSource<<a876f1c7fb12b1eed1ced2e019be6232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type IntegrationStatus = "DISABLED" | "ENABLED" | "SUSPENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateClientStep_company$data = {
  readonly rowId: any;
  readonly id: string;
  readonly name: string;
  readonly integrations: {
    readonly nodes: ReadonlyArray<{
      readonly status: IntegrationStatus;
    }>;
  };
  readonly " $fragmentType": "CreateClientStep_company";
};
export type CreateClientStep_company$key = {
  readonly " $data"?: CreateClientStep_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateClientStep_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateClientStep_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "IntegrationsConnection",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Integration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "integrations(first:100)"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "6eaae3d53848a750f74dd4a4be7bf22b";

export default node;
