import React, { lazy } from 'react'
import { merge } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'

const Chart = lazy(() => import('react-apexcharts'))

const defaultOptions = {
  plotOptions: {
    radialBar: {
      hollow: {
        size: '60%',
      },
      track: {
        background: theme.colors.secondary[3],
      },
      dataLabels: {
        show: true,
        name: { show: true, fontSize: theme.fontSizes.medium, color: theme.colors.foreground[0] },
        value: {
          formatter: (value: any) => value,
          fontSize: theme.fontSizes.xlarge,
          color: theme.colors.foreground[0],
        },
      },
    },
  },
  legend: {
    show: false,
  },
  colors: [theme.colors.secondary[0], theme.colors.secondary[3]],
}

type Props = {
  options?: Record<string, any>
  width?: string
  height?: string
  series: number[]
}

export default function RadialBarChart(props: Props) {
  const { options = {}, width = '100%', height = '100%', series } = props

  return (
    <Chart
      type="radialBar"
      width={width}
      height={height}
      options={merge({}, baseChartOptions, defaultOptions, options)}
      series={series}
    />
  )
}
