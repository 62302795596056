import React from 'react'
import PageBody from 'components/app/PageBody'
import NotFound from 'components/router/NotFound'

type Props = {
  errorMessage?: string
}

export default function NotFoundPage(props: Props) {
  const { errorMessage } = props

  return (
    <PageBody
      renderHeader={() => null}
      renderContent={() => <NotFound errorMessage={errorMessage} />}
    />
  )
}
