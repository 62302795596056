import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useOrganizationInviteCreateMutation($input: CreateOrganizationInviteInput!) {
    createOrganizationInvite(input: $input) {
      organizationInvite {
        emailAddress
        intendedRole
        organizationId
        status
        organization {
          slug
          organizationInvites {
            nodes {
              emailAddress
              intendedRole
              status
            }
          }
        }
      }
    }
  }
`

export default function useOrganizationInviteCreate() {
  return useMutation(mutation, 'createOrganizationInvite')
}
