import React from 'react'

const CheckboxCell = React.forwardRef(({ indeterminate, onClick, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick(e)
          }
        }}
        {...rest}
      />
    </>
  )
})

export default CheckboxCell
