import { DateTime } from 'luxon'
import { headerMap } from '../constants'
import { toTitleCase, capitalizeAbbreviations } from '../../../utils/string'
import { Config } from '../../../shared/types'
import { transactionTypeRoutes } from '../constants'

type Options = { timeZone: string }

export function formatDate(date: string, options?: Options) {
  if (!date) {
    return null
  }

  const dt = DateTime.fromISO(date)

  if (dt.isValid) {
    if (options?.timeZone) {
      return dt.toLocaleString({ timeZone: options.timeZone })
    }

    return dt.toLocaleString()
  }

  return date
}

export function formatReportPeriod(
  value: string | { startDate: string; endDate: string; text?: string },
  options?: Options,
) {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  if (value.text) {
    return value.text
  }

  return [value.startDate, value.endDate]
    .filter(Boolean)
    .map((date) => formatDate(date, options))
    .join(' - ')
}

export function formatHeader(header: string): string {
  if (headerMap[header]) {
    return headerMap[header]
  }

  return capitalizeAbbreviations(toTitleCase(header))
}

const configFormatters: Record<string, Function> = {
  percentVariance: (val: number) => val / 100,
}

export function formatConfig(config: Config) {
  const formattedConfig: Config = { ...config }

  Object.keys(config).forEach((key) => {
    const formatter = configFormatters[key]
    const val = config[key]

    if (formatter && val) {
      formattedConfig[key] = formatter(val)
    }
  })

  return formattedConfig
}

export function getTransactionTypeRoute(transactionType: string): string {
  return transactionTypeRoutes[transactionType] || transactionType.toLowerCase()
}
