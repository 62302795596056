import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import useOutsideClick from '../../hooks/ui/useOutsideClick'
import theme from '../../theme'
import { PopupPosition } from '../shared/types'

type Props = {
  openOnMount?: boolean
  position?: PopupPosition
  renderContent: Function
  renderButton?: Function
  keepTooltipInside?: boolean
  stopPropagation?: boolean
}

const PopupCard = (props: Props) => {
  const {
    openOnMount = false,
    position,
    renderContent,
    renderButton,
    keepTooltipInside,
    stopPropagation,
  } = props

  const [isOpen, setIsOpen] = useState(openOnMount)
  const ref = useRef()

  useOutsideClick(ref, () => setIsOpen(false))

  const popupContentStyle = {
    border: 'none',
    background: 'transparent',
    boxShadow: 'none',
    padding: 0,
    minWidth: theme.sizes.xxgigantic,
  }

  const popupArrowStyle = {
    display: 'none',
  }

  const popupOverlayStyle = {
    display: 'none', // stop overlay from blocking scroll
  }

  return (
    // @ts-ignore
    <Popup
      open={isOpen}
      keepTooltipInside={keepTooltipInside}
      contentStyle={popupContentStyle}
      arrowStyle={popupArrowStyle}
      overlayStyle={popupOverlayStyle}
      // @ts-ignore
      trigger={() => renderButton({ isOpen })}
      position={position}
      on="click"
      onClose={(e: any) => {
        if (e && stopPropagation) {
          e.preventDefault()
          e.stopPropagation()
        }

        setIsOpen(false)
      }}
      onOpen={(e: any) => {
        if (e && stopPropagation) {
          e.preventDefault()
          e.stopPropagation()
        }

        // setTimeout here prevents the useOutsideClick
        // handler from closing the popup before it has
        // a chance to be open
        setTimeout(() => setIsOpen(true))
      }}
    >
      {renderContent(ref)}
    </Popup>
  )
}

PopupCard.propTypes = {
  renderButton: PropTypes.func.isRequired,
  position: PropTypes.string,
  keepTooltipInside: PropTypes.bool,
  openOnMount: PropTypes.bool,
  stopPropagation: PropTypes.bool,
}

PopupCard.defaultProps = {
  position: 'bottom left',
  fontWeight: 'medium',
}

export default PopupCard
