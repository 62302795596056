import React, { useState, useEffect } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useHistory, useParams } from 'react-router-dom'
import { FlexRow, FlexColumn, Button, Icon, Text, Circle, Tooltip } from 'elements'
import WorkspaceTabs from './WorkspaceTabs'
import { Option } from '../../../shared/types'
import { formatName } from 'utils/string'
import { handleReportExport } from '../utils/download'
import { useStorage, useAnalytics, usePage } from 'hooks'
import { getFlatConfigSections } from '../utils/sections'
import useReportResolutionsApply from '../resolve/hooks/useReportResolutionsApply'
import { workingReviewStatuses } from '../constants'

const ANOMALY_REPORT_KIND = 'scrutinize:json:anomaly-report:1'

type Props = {
  report: any
  reportUrl: string
}

export default function WorkspaceHeader(props: Props) {
  const { report, reportUrl } = props
  const { stepId } = useParams<any>()
  const history = useHistory()
  const storage = useStorage()
  const analytics = useAnalytics()
  const [, setWorking] = useState(null)
  const [applyProcessing, setApplyProcessing] = useState(false)
  const [applyResolutions, applyInFlight]: any = useReportResolutionsApply()

  const { pageProps } = usePage()
  const { resolutionSection, resolutionAnomalyView } = pageProps || {}

  const data = useFragment(
    graphql`
      fragment WorkspaceHeader_report on Report {
        id
        name
        config
        reviewStatus
        status
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            id
            name
            key
            reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
              nodes {
                id
                status
                sections
              }
            }
          }
        }
        reportUserFiles {
          nodes {
            type
            userFile {
              id
              rowId
              kind
              name
              key
            }
          }
        }
        pendingResolutions: reportResolutions(condition: { updateStatus: "pending" }) {
          totalCount
        }
      }
    `,
    report,
  )

  const workspaces = data.reportWorkflowWorkspaces?.nodes

  const currentWorkspace = workspaces?.find((workspace: any) =>
    workspace?.reportWorkflowWorkspaceSteps?.nodes?.some((step: any) => step.id === stepId),
  )

  const anomalyReportFile = data.reportUserFiles.nodes.find(
    (file: any) => file.userFile.kind === ANOMALY_REPORT_KIND,
  )

  let configSections = data.config?.sections

  // get flat list of config sections to configure report data. They used to be in a config
  // column on the reports table, but now they're nested in app_public.report_workflow_workspace_steps
  if (data.reportWorkflowWorkspaces?.nodes?.length) {
    configSections = getFlatConfigSections(data)
  }

  useEffect(() => {
    if (applyInFlight || workingReviewStatuses.includes(data.reviewStatus)) {
      setApplyProcessing(true)
    } else {
      setApplyProcessing(false)
    }
  }, [applyInFlight, data.reviewStatus])

  const successfulReport = data.status === 'SUCCESS'

  const hasPendingResolutions = data.pendingResolutions.totalCount > 0

  const handleSync = async () => {
    await applyResolutions(
      { reportId: data.id },
      {
        anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
        resolutionSection,
      },
    )

    analytics.track('Resolutions Synced', { reportId: data.id })
  }

  return (
    <>
      <FlexRow flex="1">
        <Button area="large" onClick={() => history.push(reportUrl)}>
          <Icon name="squares" size="medium" />
        </Button>
      </FlexRow>
      <FlexRow flex="1.5" justifyContent="center">
        <WorkspaceTabs
          options={workspaces?.map((workspace: any) => ({
            label: workspace.name || formatName(workspace.key),
            value: workspace,
          }))}
          value={currentWorkspace}
          onChange={(option: Option) => {
            // redirect to first step of the workspace
            const steps = option.value.reportWorkflowWorkspaceSteps?.nodes
            if (steps?.length) {
              history.push(`${reportUrl}/steps/${steps[0].id}`)
            }
          }}
        />
      </FlexRow>
      <FlexRow flex="1" justifyContent="flex-end">
        <Button
          mr="small"
          area="small"
          disabled={applyProcessing || !successfulReport}
          onClick={handleSync}
        >
          <FlexRow position="relative" width="100%" height="100%">
            <Icon name="sync-button" size="medium" />
            {hasPendingResolutions && (
              <>
                <Circle
                  position="absolute"
                  color="accent.orange"
                  size="small"
                  left="-13px"
                  bottom="-2px"
                  data-tip
                  data-for={`${data.id}-sync-tooltip`}
                />
                <Tooltip
                  id={`${data.id}-sync-tooltip`}
                  place="bottom"
                  role="tooltip"
                  backgroundColor="neutral.5"
                  opacity="0.9"
                  // offset={{ bottom: '85px' }}
                  padding="0px"
                  arrowColor="transparent"
                >
                  <FlexColumn p="small" zIndex="999">
                    <Text color="background.0" fontSize="small">
                      You have unsynced changes
                    </Text>
                  </FlexColumn>
                </Tooltip>
              </>
            )}
          </FlexRow>
        </Button>
        <Button
          area="small"
          disabled={!anomalyReportFile || applyProcessing || !successfulReport}
          onClick={() =>
            handleReportExport({
              anomalyReportId: anomalyReportFile.userFile.rowId,
              storage,
              reportName: data.name,
              setWorking,
              configSections,
              reportId: data.id,
            })
          }
        >
          <Icon
            name="arrow-down-tray"
            color="secondary.2"
            size="medium"
            style={{ 'stroke-width': '2' }}
          />
        </Button>
      </FlexRow>
    </>
  )
}
