import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useCompanyRestoreMutation($input: RestoreCompanyInput!) {
    restoreCompany(input: $input) {
      company {
        id
        deletedAt
        organization {
          id
          totalCompanies: companies(includeDeleted: NO) {
            totalCount
          }
          ...CompanySelect_organization
        }
      }
    }
  }
`

const updater = (store, { restoreCompany }) => {
  const parentOrgId = restoreCompany.company.organization.id

  const organization = store.get(parentOrgId)

  // remove from the companies table when filtered to
  // show only archived companies
  const tableConnection = ConnectionHandler.getConnection(
    organization,
    'CompaniesTable_companies',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'EXCLUSIVELY',
    },
  )

  if (tableConnection) {
    ConnectionHandler.deleteNode(tableConnection, restoreCompany.company.id)
  }
}

export default function useCompanyRestore() {
  return useMutation(mutation, 'restoreCompany', { updater })
}
