import { createGlobalStyle } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import theme from '../'

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`
  /* React Datepicker */
  .react-datepicker {
    box-shadow: rgba(128, 149, 160, 0.247059) 0px 7px 20px 0px;
    border: 2px solid ${theme.colors.foreground[3]};
    border-radius: ${theme.radii.small}px;
  }

  .react-datepicker-popper {
    z-index: ${theme.zIndices.datepicker};
  }

  .react-datepicker__header {
    background-color: ${theme.colors.background[1]};
    border-bottom: 2px solid ${theme.colors.foreground[3]};
    border-top-left-radius: ${theme.radii.small}px;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: ${theme.radii.small}px;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: ${theme.colors.background[1]};
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -3px;
    border-bottom-color: ${theme.colors.foreground[3]};
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
    border-width: 10px;
    left: -10px;
  }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    background-color: ${theme.colors.secondary[1]};
    color: ${theme.colors.background[0]};
  }

  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: ${theme.colors.secondary[1]};
  }

  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    background-color: ${theme.colors.background[1]};
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    background-color: ${theme.colors.secondary[0]};
    color: ${theme.colors.background[0]};
  }

  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background-color: ${theme.colors.secondary[0]};
  }

  .react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${theme.colors.secondary[0]};
    color: ${theme.colors.background[0]};
  }

  .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
    background-color: ${theme.colors.secondary[0]};
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover {
    background-color: ${theme.colors.secondary[1]};
  }

  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range {
    background-color: ${theme.colors.secondary[0]};
  }

  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range {
    background-color: ${theme.colors.background[0]};
    color: ${theme.colors.foreground[0]};
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: ${theme.colors.secondary[0]};
  }

  .react-datepicker__close-icon::after {
    background-color: ${theme.colors.secondary[0]};
    color: ${theme.colors.background[0]};
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__today-button {
    background-color: ${theme.colors.background[1]};
    border-top: 2px solid ${theme.colors.foreground[3]};
    border-bottom-left-radius: ${theme.radii.small}px;
    border-bottom-right-radius: ${theme.radii.small}px;
  }
`

export default GlobalStyle
