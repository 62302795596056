import styled from 'styled-components'
import { print } from '../styles'
import {
  compose,
  space,
  layout,
  typography,
  color,
  background,
  border,
  shadow,
  position,
} from 'styled-system'
import { alignToTextAlign } from './utils'

const DataTableCell = styled.td`
  &:first-child {
    padding-left: ${({ theme }) => theme.space.xlarge}px;
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.space.xlarge}px;
  }

  border-top-style: solid;
  text-align: ${(props) => alignToTextAlign(props.align)};

  ${print}
  ${compose(space, typography, color, layout, background, border, shadow, position)}
`

DataTableCell.defaultProps = {
  // padding-top is larger than bottom to make up
  // for the xsmall border-spacing defined by Table
  pt: 'medium',
  pb: 'medium',
  px: 'xlarge',
  borderColor: 'background.1',
  borderWidth: 'xthin',
  fontSize: 'small',
  bg: 'inherit',
}

export default DataTableCell
