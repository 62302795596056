import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import OrgUsersTable from './OrgUsersTable'
import InvitationsTable from './InvitationsTable'
import { FlexColumn, FlexRow } from 'elements'

export default function OrgUsersTableContainer({ organization }) {
  const data = useFragment(
    graphql`
      fragment OrganizationMembersContainer_organization on Organization {
        name
        id
        ...OrgUsersTable_organization
        organizationInvites {
          nodes {
            id
            emailAddress
            status
            intendedRole
          }
        }
      }
    `,
    organization,
  )

  const orgInvites = data.organizationInvites?.nodes

  return (
    <FlexColumn>
      <FlexRow mb="xxxlarge">
        <InvitationsTable orgInvites={orgInvites} />
      </FlexRow>
      <FlexRow>
        <OrgUsersTable organization={data} />
      </FlexRow>
    </FlexColumn>
  )
}
