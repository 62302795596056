import React from 'react'
import FlexColumn from '../FlexColumn'
import Icon from '../Icon'

const StyledIcon = ({ active, ...props }: any) => (
  <Icon
    {...props}
    size={active ? 'medium' : 'small'}
    opacity={active ? 1 : 0.3}
    height="small"
    mb={active ? 'none' : 'xxsmall'}
  />
)

export default function DataTableSortButton({ sort }: any) {
  return (
    <FlexColumn justifyContent="center" size="20px">
      {sort !== 'desc' && <StyledIcon name="angle-up" active={sort === 'asc'} />}
      {sort !== 'asc' && <StyledIcon name="angle-down" active={sort === 'desc'} />}
    </FlexColumn>
  )
}
