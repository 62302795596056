import React, { useEffect } from 'react'
import { useHistory, useParams, Route } from 'react-router-dom'
import { orderBy } from 'lodash'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useDecision } from '@optimizely/react-sdk'
import OrganizationMembersContainer from './members/OrganizationMembersContainer'
import OrgBillingContainer from './billing/OrgBillingContainer'
import OrgMfaContainer from './mfa/OrgMfaContainer'
import { usePage, useAuth } from 'hooks'
import EmptyState from '../common/EmptyState'
import CustomSwitch from '../router/CustomSwitch'
import { Box } from 'elements'

export default function SettingsContainer() {
  const { currentOrg, userIsCurrentOrgAdmin, user } = useAuth()
  const { setHeader } = usePage()
  const history = useHistory()
  const { orgSlug } = useParams()
  const [decision] = useDecision('canManageBilling')
  const manageBillingEnabled = decision.enabled

  const { organizationBySlug } = useLazyLoadQuery(
    graphql`
      query SettingsContainerQuery($orgSlug: String!) {
        organizationBySlug(slug: $orgSlug) {
          ...OrganizationMembersContainer_organization
          ...OrgBillingContainer_organization
        }
      }
    `,
    { orgSlug },
    // invites sometimes get stuck on pending before refreshing, so don't use cache
    { fetchPolicy: 'network-only' },
  )

  useEffect(() => {
    const settingsSections = [
      {
        label: 'Team',
        value: `/orgs/${currentOrg.slug}/settings/members`,
        onSelect() {
          history.push(this.value)
        },
      },
    ]

    // Show the MFA settings tab for users that logged in with email and password.
    // Sort by updatedAt, which will give us the most recent method they used to log in.
    const userAuths = orderBy(user.userAuthentications?.nodes, 'updatedAt', 'desc')
    if (userAuths[0]?.service === 'cognito') {
      settingsSections.push({
        label: 'Authentication',
        value: `/orgs/${currentOrg.slug}/settings/mfa`,
        onSelect() {
          history.push(this.value)
        },
      })
    }

    if (userIsCurrentOrgAdmin && manageBillingEnabled) {
      settingsSections.push({
        label: 'Billing',
        value: `/orgs/${currentOrg.slug}/settings/billing`,
        onSelect() {
          history.push(this.value)
        },
      })
    }

    setHeader({ settingsSections })
  }, [setHeader, currentOrg.slug, history, userIsCurrentOrgAdmin, manageBillingEnabled, user])

  return (
    <Box pt="xlarge">
      <CustomSwitch>
        <Route exact path="/orgs/:orgSlug/settings/members">
          <OrganizationMembersContainer organization={organizationBySlug} />
        </Route>
        <Route exact path="/orgs/:orgSlug/settings/mfa">
          <OrgMfaContainer organization={organizationBySlug} />
        </Route>
        <Route path="/orgs/:orgSlug/settings/billing">
          {userIsCurrentOrgAdmin ? (
            <OrgBillingContainer organization={organizationBySlug} />
          ) : (
            <EmptyState
              fontSize="xlarge"
              justifyContent="start"
              message="You must be an org admin to view billing details."
            />
          )}
        </Route>
      </CustomSwitch>
    </Box>
  )
}
