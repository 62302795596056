import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SuspenseBoundary, Box, FlexRow, FlexColumn, Image, Text, Divider } from 'elements'
import logo from 'theme/images/scrutinize-logo-256x256.png'
import CompanyNavigation from './CompanyNavigation'
import AppNavigation from './AppNavigation'
import AvatarSection from '../app/AvatarSection'

export const NAV_BAR_WIDTH = 300

export default function NavBar() {
  const history = useHistory()
  const { orgSlug } = useParams()

  return (
    <FlexColumn
      position="absolute"
      top="0"
      left="0"
      py="xlarge"
      px="xlarge"
      bg="background.0"
      overflow="auto"
      width="300px"
      height="100%"
      // same color border as page background for horizontal scroll tables
      borderRight="solid 2px"
      borderColor="background.1"
    >
      <FlexRow
        justifyContent="center"
        alignItems="center"
        mb="xxxlarge"
        onClick={() => history.push(`/orgs/${orgSlug}/dashboard`)}
      >
        <Image src={logo} width="20%" />
        <Text fontSize="xxlarge" fontWeight="bold">
          Scrutinize
        </Text>
      </FlexRow>
      <FlexColumn>
        <SuspenseBoundary>
          <CompanyNavigation />
        </SuspenseBoundary>
      </FlexColumn>
      <Divider mb="xxlarge" />
      <FlexColumn mb="xxlarge">
        <AppNavigation />
      </FlexColumn>
      <Box mt="auto">
        <AvatarSection />
      </Box>
    </FlexColumn>
  )
}
