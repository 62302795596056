import React from 'react'
import { orderBy } from 'lodash'
import { FlexRow, FlexColumn, Text, Tooltip } from 'elements'
import SeverityCircle from '../../common/SeverityCircle'
import { ResolutionAnomalyType } from '../types'
import { formatName } from 'utils/string'

// this is a react-table cell
type Props = {
  cell: any
  row: any
}

export default function AnomaliesCell(props: Props) {
  const { cell, row } = props
  const { anomalyCountHigh, anomalyCountMedium, anomalyCountLow } = cell.value

  const sortedAnomalyTypes = orderBy(row.original.anomalyTypes, 'severity', 'desc')

  return (
    <>
      <FlexRow
        justifyContent="center"
        alignItems="center"
        data-tip
        data-for={`${row.original.transactionId}-anomalies-tooltip`}
      >
        <FlexRow alignItems="center" px="small">
          <SeverityCircle severity={2} styleProps={{ boxProps: { mr: 'small' } }} />
          <Text>{anomalyCountHigh}</Text>
        </FlexRow>
        <FlexRow alignItems="center" px="small">
          <SeverityCircle severity={1} styleProps={{ boxProps: { mr: 'small' } }} />
          <Text>{anomalyCountMedium}</Text>
        </FlexRow>
        <FlexRow alignItems="center" px="small">
          <SeverityCircle severity={0} styleProps={{ boxProps: { mr: 'small' } }} />
          <Text>{anomalyCountLow}</Text>
        </FlexRow>
      </FlexRow>
      <Tooltip id={`${row.original.transactionId}-anomalies-tooltip`} role="tooltip" place="top">
        <FlexColumn p="small">
          {sortedAnomalyTypes.map((anomalyType: ResolutionAnomalyType, idx: number) => (
            <FlexRow
              key={[anomalyType.type, idx].join('-')}
              mb={idx === sortedAnomalyTypes.length - 1 ? null : 'medium'}
            >
              <SeverityCircle
                severity={anomalyType.severity}
                styleProps={{ boxProps: { mr: 'medium' } }}
              />
              <Text>{formatName(anomalyType.type)}</Text>
            </FlexRow>
          ))}
        </FlexColumn>
      </Tooltip>
    </>
  )
}
