import React from 'react'
import { get } from 'lodash'
import StandardCell from './StandardCell'

export default function DeepValueCell(props) {
  const { valuePath } = props.column

  // display a single property in a more complex value
  const value = get(props.cell.value, valuePath)

  return <StandardCell {...props} cell={{ ...props.cell, value }} />
}
