import { sumBy, flatten, flattenDeep, countBy, uniqBy } from 'lodash'
import { CodingAnomalyData, AnomalyTransaction } from '../../types'
import { codingAnomalies } from '../../constants'

type Props = {
  summary: any
  reportSections: any[]
  includeSeverity?: boolean
}

export default function configureSummary(props: Props) {
  const { summary, reportSections, includeSeverity } = props
  const { userActivity } = summary

  const configuredAnomalies: any[] = reportSections
    .filter((section) => section.kind === 'anomaly' && section.dataSections.length)
    .map((section: any) => {
      // anomalies sometimes have multiple data sections
      const data: any[] = flatten(section.dataSections.map((dataSection: any) => dataSection.data))

      const config = section.dataSections[0].config

      let amount: number | null = 0
      let count = 0

      // flatten the by-account list into transaction lines and count by transactionId. Amount is N/A for coding anomalies
      if (codingAnomalies.includes(section.value)) {
        const codingData = data as CodingAnomalyData[]
        amount = null

        const flatTxnLines = flatten(codingData.map((accountData) => accountData.data))

        count = uniqBy(flatTxnLines, 'transactionId').length
      } else {
        const transactionData = data as AnomalyTransaction[]

        amount = sumBy(transactionData, (txn: any) => Math.abs(txn.transactionAmount))
        count = transactionData.length
      }

      const configuredAnomaly = {
        anomalyName: section.label,
        count,
        amount,
      }

      const withSeverity = { severity: config?.severity, ...configuredAnomaly }

      return includeSeverity ? withSeverity : configuredAnomaly
    })

  //get  flat list of anomaly transactions from configured report sections
  const anomalyTransactionData = flattenDeep(
    reportSections
      .filter((reportSection) => reportSection.kind === 'anomaly')
      .map((reportSection) =>
        reportSection.dataSections.map((dataSection: any) => dataSection.data),
      ),
  )

  const countByUser = countBy(anomalyTransactionData, 'lastModifiedBy')

  const configuredUserActivity = userActivity.map((user: any) => ({
    ...user,
    anomalyCount: countByUser[user.userName] ?? 0,
  }))

  return { configuredAnomalies, configuredUserActivity }
}
