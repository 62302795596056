/**
 * @generated SignedSource<<8f61894bf787a58f5ecd29cc7e846815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCompaniesInput = {
  companiesInfo?: any | null;
  organizationId: string;
};
export type useCompaniesCreateMutation$variables = {
  input: CreateCompaniesInput;
};
export type useCompaniesCreateMutation$data = {
  readonly createCompanies: {
    readonly insertedCompanies: any | null;
  } | null;
};
export type useCompaniesCreateMutation = {
  variables: useCompaniesCreateMutation$variables;
  response: useCompaniesCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCompaniesPayload",
    "kind": "LinkedField",
    "name": "createCompanies",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedCompanies",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompaniesCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompaniesCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dd2d2482b3b7b3057bc4e8e270282833",
    "id": null,
    "metadata": {},
    "name": "useCompaniesCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useCompaniesCreateMutation(\n  $input: CreateCompaniesInput!\n) {\n  createCompanies(input: $input) {\n    insertedCompanies\n  }\n}\n"
  }
};
})();

(node as any).hash = "093e82c5a06bb53068745eed9f509963";

export default node;
