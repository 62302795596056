import React from 'react'
// @ts-ignore
import styled from 'styled-components'
// @ts-ignore
import css from '@styled-system/css'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon'
import Box from '../Box'
import FlexRow from '../FlexRow'
import Text from '../Text'

const StyledBox = styled(Box)(
  css({
    color: 'neutral.9',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    borderRadius: 'xsmall',
    backgroundColor: 'transparent',
    '.active &, &:hover': {
      backgroundColor: 'background.3',
    },
  }),
)

type Props = {
  to?: string
  href?: string
  icon: string
  label: string
}

export default function SideNavLink(props: Props) {
  const { to, icon, label, href } = props

  const navBox = (
    <StyledBox as={href && 'a'} href={href} target={href && '_blank'}>
      <FlexRow mr="medium">
        <Icon size="medium" name={icon} />
      </FlexRow>
      <Text color="inherit">{label}</Text>
    </StyledBox>
  )

  if (href) {
    return navBox
  }

  return (
    <NavLink activeClassName="active" to={to}>
      {navBox}
    </NavLink>
  )
}
