import styled from 'styled-components'
import { compose, color, space, layout, flexbox, position, typography } from 'styled-system'
import { filterProps } from '../utils'

const Link = filterProps(styled.a)`
  ${compose(color, layout, space, position, flexbox, typography)}

  text-decoration: none;
  transition: color 200ms ease;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:active {
    text-decoration: none;
  }
`

Link.defaultProps = {
  color: 'primary.0',
}

export default Link
