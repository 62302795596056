import React from 'react'
import { FlexColumn, Text, TextInputField, Message } from 'elements'
import { StyleProps } from '../../../../types/style'

type Props = {
  transactionNum: string | null
  canEdit: boolean
  styleProps?: StyleProps
}

export default function TransactionNum(props: Props) {
  const { transactionNum, canEdit, styleProps } = props

  return (
    <FlexColumn width="320px" {...styleProps?.boxProps}>
      <FlexColumn justifyContent="center">
        <Text fontSize="large" mb="small">
          Number
        </Text>
      </FlexColumn>
      <FlexColumn justifyContent="center">
        {canEdit ? (
          // @ts-ignore
          <TextInputField
            name="transactionNum"
            placeholder="Num"
            color="foreground.0"
            fontSize="medium"
            area="small"
          />
        ) : (
          <Message area="small">{transactionNum}</Message>
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
