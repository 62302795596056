import styled from 'styled-components'
import {
  compose,
  variant,
  border,
  space,
  color,
  flexbox,
  shadow,
  typography,
  layout,
} from 'styled-system'
import { itemBase, print } from '../styles'

const kind = variant({
  prop: 'kind',
  variants: {
    neutral: {
      color: 'neutral.4',
      bg: 'neutral.2',
      borderColor: 'neutral.2',
    },
    primary: {
      color: 'background.0',
      bg: 'primary.0',
      borderColor: 'primary.0',
    },
    danger: {
      color: 'background.0',
      bg: 'danger.0',
      borderColor: 'danger.0',
    },
    'alt-primary': {
      color: 'primary.0',
      bg: 'background.0',
      borderColor: 'primary.0',
    },
    'alt-danger': {
      color: 'danger.0',
      bg: 'background.0',
      borderColor: 'danger.0',
    },
  },
})

const area = variant({
  prop: 'area',
  variants: {
    small: {
      fontSize: 'small',
      height: 'xlarge',
      px: 'xsmall',
    },
    medium: {
      fontSize: 'medium',
      height: 'xxlarge',
      px: 'medium',
    },
  },
})

const Item = styled.div`
  ${itemBase}
  ${print}

  ${compose(
    // custom variants should go at the top so props they define
    // can be overridden on occasion when needed
    area,
    kind,
    border,
    space,
    flexbox,
    color,
    shadow,
    typography,
    layout,
  )}
`

Item.defaultProps = {
  kind: 'neutral',
  area: 'medium',
  borderRadius: 'xsmall',
  borderWidth: 'xthin',
  fontWeight: 'light',
}

export default Item
