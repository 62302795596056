import React, { useState } from 'react'
import { useModalBehavior, useAnalytics, useAuth, useNotifications } from 'hooks'
import { Button, Modal, ModalNavHeader, TextInput, Select, Text } from 'elements'
import ModalContent from 'components/app/ModalContent'
import useOrganizationInviteCreate from './hooks/useOrganizationInviteCreate'
import { validators } from 'utils/validation'

const roleOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Member', value: 'MEMBER' },
]

export default function InviteUsersModal({ modal }) {
  const [userEmail, setUserEmail] = useState(null)
  const [intendedRole, setIntendedRole] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const modalBehavior = useModalBehavior(modal, {})
  const [createOrganizationInvite] = useOrganizationInviteCreate()
  const analytics = useAnalytics()
  const { currentOrg, user } = useAuth()
  const notifications = useNotifications()

  const handleInviteUser = async () => {
    setSubmitting(true)

    try {
      const isValidEmail = await validators.email.isValid(userEmail)

      if (!isValidEmail) {
        const e = new Error('Please enter a valid email address.')
        e.name = 'invalidEmail'
        throw e
      }

      if (!intendedRole) {
        const e = new Error('Please select a role.')
        e.name = 'emptyRole'
        throw e
      }

      const { organizationInvite } = await createOrganizationInvite({
        organizationId: currentOrg.id,
        email: userEmail,
        intendedRole: intendedRole,
        invitedBy: user.name,
      })

      analytics.track('User Invited', {
        organizationSlug: organizationInvite.organization.slug,
        emailAddress: organizationInvite.emailAddress,
      })

      modal.onClose()

      notifications.success('Invitation sent.')
    } catch (error) {
      setSubmitError(error)

      setSubmitting(false)
    }
  }

  return (
    <Modal modal={modalBehavior}>
      <ModalNavHeader onClose={modal.onClose} titleText={`Invite a Member`} />
      <ModalContent>
        <TextInput
          value={userEmail}
          onChange={(event) => setUserEmail(event.target.value)}
          placeholder="Email Address"
          mb={submitError?.name === 'invalidEmail' ? 'xsmall' : 'xlarge'}
        />
        {submitError?.name === 'invalidEmail' ? (
          <Text fontSize="xsmall" color="danger.0" mb="large">
            {submitError.message}
          </Text>
        ) : null}
        <Select
          options={roleOptions}
          value={roleOptions.find((option) => option.value === intendedRole)}
          placeholder="Select Role"
          onChange={(option) => setIntendedRole(option.value)}
          mb={submitError?.name === 'emptyRole' ? 'xsmall' : '0px'}
        />
        {submitError?.name === 'emptyRole' ? (
          <Text fontSize="xsmall" color="danger.0">
            {submitError.message}
          </Text>
        ) : null}
      </ModalContent>
      <Modal.Footer display="flex" alignItems="center">
        <Button mr="medium" kind="secondary" onClick={handleInviteUser} disabled={submitting}>
          {!submitting ? 'Invite Member' : 'Submitting...'}
        </Button>
        <Button onClick={() => modal.onClose()} mr="xxxlarge">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
