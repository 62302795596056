import React from 'react'
import { useHistory } from 'react-router-dom'
import { FlexColumn, FlexRow, Text, Button } from 'elements'
import ModulesCompaniesContainer from './ModulesCompaniesContainer'
import { useAuth } from 'hooks'

export default function ModulesContainer() {
  const { currentOrg } = useAuth()
  const history = useHistory()

  return (
    <FlexColumn pt="gigantic">
      <FlexRow justifyContent="center" mb="gigantic">
        <Text fontWeight="bold" fontSize="xxxlarge">
          For Internal Use Only
        </Text>
      </FlexRow>
      <FlexColumn mb="gigantic">
        <ModulesCompaniesContainer />
      </FlexColumn>
      <FlexRow justifyContent="center" mb="gigantic">
        <Text fontWeight="bold" fontSize="xxxlarge">
          Firm Level
        </Text>
      </FlexRow>
      <FlexRow justifyContent="center">
        <Button
          width="25%"
          onClick={() => history.push(`/orgs/${currentOrg!.slug}/modules/create-companies`)}
        >
          Create Companies
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
