/**
 * @generated SignedSource<<9a14e6c429d83dc78b5aaf44cd4404c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeactivateHistoryTableRow_integrationScriptResult$data = {
  readonly createdAt: any;
  readonly dateThreshold: any | null;
  readonly kind: string;
  readonly reversed: boolean | null;
  readonly dataTable: string | null;
  readonly " $fragmentType": "DeactivateHistoryTableRow_integrationScriptResult";
};
export type DeactivateHistoryTableRow_integrationScriptResult$key = {
  readonly " $data"?: DeactivateHistoryTableRow_integrationScriptResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeactivateHistoryTableRow_integrationScriptResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeactivateHistoryTableRow_integrationScriptResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reversed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataTable",
      "storageKey": null
    }
  ],
  "type": "IntegrationScriptResult",
  "abstractKey": null
};

(node as any).hash = "8489516150dfa543b3481145d9bb0315";

export default node;
