import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { unsetSearchParams, setSearchParams } from 'utils/url'

export default function useQueryParams() {
  const history = useHistory()
  const { search, pathname } = useLocation()

  // convert search params to object, but memoize it so we're
  // not doing it on every render
  const params = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search])

  // function to add query params from the URL
  const addParams = useCallback(
    (params: Record<string, string>) => {
      const newSearch = setSearchParams(search, params)
      history.replace(`${pathname}?${newSearch}`)
    },
    [history, pathname, search],
  )

  // function to remove query params from the URL
  const removeParams = useCallback(
    (keys: string[]) => {
      const newSearch = unsetSearchParams(search, keys)
      history.replace(`${pathname}?${newSearch}`)
    },
    [history, pathname, search],
  )

  return { params, addParams, removeParams }
}
