import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { Select, Text } from 'elements'
import { Option } from '../../shared/types'
import { useAuth } from 'hooks'

type Props = {
  company: any
  onCompanyChange: any
  organization: any
}

export default function CompanySelect(props: Props) {
  const { company, onCompanyChange, organization } = props
  const { allowClientCreation } = useAuth()

  const data = useFragment(
    graphql`
      fragment CompanySelect_organization on Organization {
        companies(orderBy: NAME_ASC, includeDeleted: NO, first: 100)
          @connection(key: "CompanySelect_companies") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    organization,
  )

  if (!data) {
    return null
  }

  const companies = data.companies?.edges?.map((edge: any) => edge.node) || []

  const options: Option[] = [
    ...companies.map((company: any) => ({
      label: company.name,
      value: company,
    })),
  ]

  if (allowClientCreation) {
    options.unshift({ label: '+ Add a New Client', value: 'newCompany' })
  } else {
    options.unshift({ label: 'Upgrade to create a client', value: 'upgrade' })
  }

  const value = options.find((option) => {
    // return first option for newCompany value
    if (company === 'newCompany') {
      return true
    }

    return company?.id && option.value.id === company.id
  })

  return (
    // @ts-ignore
    <Select
      options={options}
      value={value}
      interactive={(provided: any, state: any) =>
        state.value === 'newCompany' || state.value === 'upgrade'
      }
      formatOptionLabel={(option: Option) => {
        const { label, value } = option

        if (value === 'newCompany' || value === 'upgrade') {
          return (
            <Text color="secondary.0" truncate mr="small" title={label}>
              {label}
            </Text>
          )
        }

        return (
          <Text truncate mr="small" title={label}>
            {label}
          </Text>
        )
      }}
      onChange={onCompanyChange}
      placeholder="Select Client"
    />
  )
}
