import React from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import FlexRow from '../FlexRow'
import FlexColumn from '../FlexColumn'
import Button from '../Button'
import Icon from '../Icon'

const StyledIcon = styled(Icon)(
  css({
    '.active &, &:hover': {
      color: 'secondary.0',
    },
  }),
)

export default function IconTabBar({ tabs, currentTab, onChange, ...props }) {
  return (
    <FlexRow alignItems="center" bg="background.0" borderRadius="small" {...props}>
      {tabs.map((tab) => {
        return (
          <FlexColumn key={tab.label}>
            <Button
              kind="basic-transparent"
              onClick={() => {
                if (tab.onClick) {
                  tab.onClick()
                } else {
                  onChange(tab)
                }
              }}
              area="xsmall"
              mr="xsmall"
              disabled={tab.disabled}
            >
              {tab.renderIcon ? (
                tab.renderIcon()
              ) : (
                <StyledIcon
                  size="medium"
                  color={tab.label === currentTab.label ? 'secondary.0' : 'foreground.3'}
                  name={tab.icon}
                />
              )}
            </Button>
          </FlexColumn>
        )
      })}
    </FlexRow>
  )
}
