import React from 'react'
import { usePaginationFragment, graphql } from 'react-relay/hooks'
import { compact } from 'lodash'
import { Table as Tables, FlexColumn } from 'elements'
import EmptyState from 'components/common/EmptyState'
import WorkflowTableRow from './WorkflowTableRow'
import { TablePagination } from 'elements'

const { Table, Body, Row, Cell } = Tables.Card

type Props = {
  company: any
  includeDeleted: boolean
}

export default function CompletedWorkflowTable(props: Props) {
  const { company, includeDeleted } = props

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment CompletedWorkflowTable_company on Company
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "CompletedWorkflowsPaginationQuery") {
        completedReports: filteredReports(
          input: { status: "completed" }
          after: $cursor
          first: $count
          orderBy: CREATED_AT_DESC
          includeDeleted: $includeDeleted
        ) @connection(key: "CompletedWorkflowsTable_completedReports") {
          edges {
            node {
              id
              ...WorkflowTableRow_report
            }
          }
        }
      }
    `,
    company,
  )

  if (!data) {
    return null
  }

  // compact for when record is deleted permanently (null node)
  const completedReports = compact(data.completedReports.edges.map((edge: any) => edge.node))

  return (
    <Table spacing="large">
      <Body>
        {!completedReports.length ? (
          <Row>
            <Cell colSpan={4}>
              <FlexColumn p="xxxlarge">
                <EmptyState message="No completed workflows" />
              </FlexColumn>
            </Cell>
          </Row>
        ) : (
          completedReports.map((report: any) => (
            <WorkflowTableRow report={report} key={report.id} includeDeleted={includeDeleted} />
          ))
        )}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={5} {...paginationProps} />
    </Table>
  )
}
