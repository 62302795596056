import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { Formik } from 'formik'
import TransactionModalInfo from '../view/TransactionModalInfo'

type Props = {
  modal: any
  platformTransactionId?: string
  globalId?: string
  reportId: string
  integrationId: string
  resolutionId?: string
}

export default function ReadOnlyResolutionModal(props: Props) {
  const { modal, platformTransactionId, globalId, reportId, integrationId, resolutionId } = props

  // fetch report resolution either by the resolution id or by the report and platform transaction id.
  // when the modal is opened from RelatedTransactionsTable, resolution id will be defined. When opening
  // from the anomaly by type view, the transaction id will be defined.
  const data = useLazyLoadQuery<any>(
    graphql`
      query ReadOnlyResolutionModalQuery(
        $platformTransactionId: String
        $globalId: String
        $reportId: ID!
        $resolutionId: ID
      ) {
        reportResolutionByData(
          input: {
            resolutionId: $resolutionId
            platformTransactionId: $platformTransactionId
            reportId: $reportId
            globalId: $globalId
          }
        ) {
          id
          currentValue
          initialValue
          resolutionKind
          status
          updateStatus
          anomalyTypes
          responseData
        }
      }
    `,
    { reportId, platformTransactionId, globalId, integrationId, resolutionId },
  )

  const resolution = data?.reportResolutionByData

  if (!resolution) {
    return null
  }

  const { transactionData } = resolution.initialValue

  return (
    // empty functions for onSubmit and validate because nothing is being edited here
    <Formik initialValues={transactionData} onSubmit={() => {}} validate={() => {}}>
      <TransactionModalInfo resolution={resolution} modal={modal} reportId={reportId} />
    </Formik>
  )
}
