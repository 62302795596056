import React from 'react'
import PageBody from 'components/app/PageBody'
import ModulesCompanyContainer from 'components/modules/ModulesCompanyContainer'
import ModulesCompanyHeaderContainer from 'components/modules/ModulesCompanyHeaderContainer'

export default function ModulesCompanyPage() {
  return (
    <PageBody
      renderHeader={() => <ModulesCompanyHeaderContainer />}
      renderContent={() => <ModulesCompanyContainer />}
    />
  )
}
