import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SemiModal from './SemiModal'
import FullModal from './FullModal'
import ScreenModal from './ScreenModal'
import useUserValidate from '../../hooks/app/useUserValidate'

const ESC_KEY = 27

const MODAL_BEHAVIORS = {
  semi: SemiModal,
  full: FullModal,
  screen: ScreenModal,
}

function Modal({ modal, children, ...props }) {
  // logout if no valid user
  useUserValidate({ modal })

  // Add ESC key listener
  useEffect(() => {
    // If pressed key is our target key then set to true
    const handleKeyDown = (event) => {
      if (modal.isFocused && event.keyCode === ESC_KEY) {
        modal.onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    // Remove event listeners on cleanup
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [modal])

  // get the correct component for rendering this behavior
  const Behavior = MODAL_BEHAVIORS[modal.behavior]

  return (
    <Behavior {...modal} {...props}>
      {children}
    </Behavior>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modal: PropTypes.shape({
    behavior: PropTypes.string.isRequired,
    isClosing: PropTypes.bool,
    isFocused: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onAfterClose: PropTypes.func.isRequired,
  }),
}

export default Modal
