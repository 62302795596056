import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'
import { ConnectionHandler } from 'react-relay'

const mutation = graphql`
  mutation useWorkflowTemplateDuplicateMutation($input: DuplicateWorkflowTemplateInput!) {
    duplicateWorkflowTemplate(input: $input) {
      workflowTemplate {
        rowId
        id
        name
        config
        organizationId
        organization {
          id
        }
      }
    }
  }
`

const updater = (store, { duplicateWorkflowTemplate }) => {
  const payload = store.getRootField('duplicateWorkflowTemplate')
  const newTemplate = payload.getLinkedRecord('workflowTemplate')

  const parentOrgId = duplicateWorkflowTemplate.workflowTemplate.organization.id
  const parentOrg = store.get(parentOrgId)

  const configConnection = ConnectionHandler.getConnection(
    parentOrg,
    'WorkflowConfiguration_workflowTemplatesWithDefaults',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const dropdownConnection = ConnectionHandler.getConnection(
    parentOrg,
    'WorkflowTemplateSelect_workflowTemplatesWithDefaults',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const connections = [configConnection, dropdownConnection]

  connections.filter(Boolean).forEach((connection) => {
    const newEdge = ConnectionHandler.createEdge(
      store,
      connection,
      newTemplate,
      'WorkflowTemplateEdge',
    )

    ConnectionHandler.insertEdgeBefore(connection, newEdge)
  })
}

export default function useWorkflowTemplateDuplicate() {
  return useMutation(mutation, 'duplicateWorkflowTemplate', { updater })
}
