/**
 * @generated SignedSource<<e2097d2af66ce22d5bdafb2520680fc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QboBulkDeactivateTrigger_company$data = {
  readonly integrations: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly kind: string;
      readonly " $fragmentSpreads": FragmentRefs<"DeactivateHistoryTable_integration">;
    }>;
  };
  readonly " $fragmentType": "QboBulkDeactivateTrigger_company";
};
export type QboBulkDeactivateTrigger_company$key = {
  readonly " $data"?: QboBulkDeactivateTrigger_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"QboBulkDeactivateTrigger_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QboBulkDeactivateTrigger_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationsConnection",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Integration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DeactivateHistoryTable_integration"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "fc822cdaa3ec003ebc087664ac7c3222";

export default node;
