import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { Dropdown, LinkButton, Icon } from 'elements'
import { useStorage, useNotifications } from 'hooks'
import useBulkDeactivateUndo from './hooks/useBulkDeactivateUndo'

type Props = {
  scriptResult: any
}

export default function DeactivateHistoryContextMenu(props: Props) {
  const { scriptResult } = props
  const storage = useStorage()
  const notifications = useNotifications()
  const [undoBulkDeactivate]: any = useBulkDeactivateUndo()

  const data = useFragment(
    graphql`
      fragment DeactivateHistoryContextMenu_integrationScriptResult on IntegrationScriptResult {
        id
        kind
        userFile {
          rowId
        }
      }
    `,
    scriptResult,
  )

  const actionOptions = [
    {
      label: 'Download',
      borderStyle: 'none',
      onSelect: () => {
        window.location.href = storage.url(data.userFile.rowId)
      },
    },
    !data.kind.includes('undo') && {
      label: 'Undo',
      borderStyle: 'none',
      onSelect: async () => {
        try {
          await undoBulkDeactivate({ scriptResultId: data.id })

          notifications.success('Undo successful')
        } catch (err) {
          console.error(err)

          notifications.error('Undo failed')
        }
      },
    },
  ]

  return (
    <Dropdown
      position="bottom right"
      options={actionOptions}
      stopPropagation
      renderButton={() => (
        <LinkButton kind="transparent">
          <Icon name="dots-vertical" size="medium" color="neutral.9" />
        </LinkButton>
      )}
    />
  )
}
