import React from 'react'
import { Modal, SuspenseBoundary } from 'elements'
import FirstClientModal from './FirstClientModal'

type Props = {
  modal: any
  integrationResult?: any
  companyId?: string
  firstClient?: boolean
  createClient?: boolean
}

export default function FirstClientModalContainer(props: Props) {
  const { modal, integrationResult, companyId, firstClient } = props

  return (
    <Modal modal={modal}>
      <SuspenseBoundary>
        <FirstClientModal
          modal={modal}
          integrationResult={integrationResult}
          companyId={companyId}
          firstClient={firstClient}
        />
      </SuspenseBoundary>
    </Modal>
  )
}
