import React from 'react'
import Header from 'components/app/Header'
import PageBody from 'components/app/PageBody'
import { FlexRow, SuspenseBoundary, TabBar } from 'elements'
import WorkflowConfigurationContainer from '../components/workflows/WorkflowConfigurationContainer'
import { usePage } from 'hooks'

const Content = () => {
  return (
    <FlexRow
      borderBottom="solid 1px"
      borderColor="neutral.1"
      bg="background.0"
      flex="1"
      // ignore page content padding
      ml="-32px"
      mr="-32px"
      justifyContent="space-between"
      height="100%"
    >
      <SuspenseBoundary>
        <WorkflowConfigurationContainer />
      </SuspenseBoundary>
    </FlexRow>
  )
}

export default function WorkflowConfigurationsPage() {
  const { pageProps } = usePage()

  const headerSections = pageProps?.configurationTabs

  return (
    <PageBody
      renderHeader={() => (
        <>
          <Header justifyContent="center">
            {headerSections && (
              <TabBar
                tabs={headerSections}
                value={pageProps?.configurationSection}
                onChange={(tab) => {
                  tab.onSelect()
                }}
                styleProps={{ color: 'neutral.3', borderColor: 'accent.lightblue' }}
              />
            )}
          </Header>
        </>
      )}
      renderContent={() => <Content />}
      pb="none"
    />
  )
}
