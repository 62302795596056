import { compact } from 'lodash'
import { ReportSection, SourceKind } from '../../../types'
import { ConfigSection, AnomalyReport } from '../../../../../shared/types'
import formatSummary from '../../summary/formatSummary'
import configureSummary from '../../summary/configureSummary'
import getAccountReportSection from './getAccountReportSection'
import getTransactionReportSection from './getTransactionReportSection'
import getVendorReportSection from './getVendorReportSection'
import getCustomerReportSection from './getCustomerReportSection'
import getUserReportSection from './getUserReportSection'
import getAnomalyReportSection from './getAnomalyReportSection'
import getAnomalyList from './getAnomalyList'

type Props = {
  report: AnomalyReport
  sourceKind?: SourceKind
  configSections?: ConfigSection[]
  reportId: string
  integrationId?: string
}

export default function getInsightReportSections(props: Props): ReportSection[] {
  const { report, sourceKind, configSections, reportId, integrationId } = props
  const { reportMetadata } = report

  const formattedSummary = formatSummary(reportMetadata)

  const anomalyList = getAnomalyList({
    report,
    configSections,
    reportId,
    integrationId,
    sourceKind,
  })

  //configure metadata based on anomaly details
  const { configuredAnomalies, configuredUserActivity } = configureSummary({
    summary: formattedSummary,
    reportSections: anomalyList,
    includeSeverity: true,
  })

  const transactionSection = getTransactionReportSection({ formattedSummary, sourceKind })

  const anomalySection = getAnomalyReportSection({ configuredAnomalies, anomalyList })

  const vendorSection = getVendorReportSection({ formattedSummary })

  const customerSection = getCustomerReportSection({ formattedSummary })

  const accountSection = getAccountReportSection({ formattedSummary })

  const userSection = getUserReportSection({ formattedSummary, configuredUserActivity })

  return compact([
    transactionSection,
    anomalySection,
    vendorSection,
    customerSection,
    accountSection,
    userSection,
  ])
}
