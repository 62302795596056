import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import Popup from 'reactjs-popup'
import useOutsideClick from '../../hooks/ui/useOutsideClick'
import Card from '../Card'
import SubnavButton from '../SubnavButton'
import theme from '../../theme'
import { PopupPosition } from '../shared/types'

type Props = {
  openOnMount?: boolean
  options: any[]
  onSelect?: Function
  position?: PopupPosition
  renderButton?: Function
  keepTooltipInside?: boolean
  stopPropagation?: boolean
  fontWeight?: string
}

const Dropdown = (props: Props) => {
  const {
    openOnMount = false,
    options,
    onSelect,
    position,
    renderButton,
    keepTooltipInside,
    stopPropagation,
    fontWeight,
  } = props

  const [isOpen, setIsOpen] = useState(openOnMount)
  const ref = useRef()

  useOutsideClick(ref, () => setIsOpen(false))

  const popupContentStyle = {
    border: 'none',
    background: 'transparent',
    boxShadow: 'none',
    padding: 0,
    minWidth: theme.sizes.xxgigantic,
  }

  const popupArrowStyle = {
    display: 'none',
  }

  const popupOverlayStyle = {
    display: 'none', // stop overlay from blocking scroll
  }

  return (
    // @ts-ignore
    <Popup
      open={isOpen}
      keepTooltipInside={keepTooltipInside}
      contentStyle={popupContentStyle}
      arrowStyle={popupArrowStyle}
      overlayStyle={popupOverlayStyle}
      // @ts-ignore
      trigger={() => renderButton({ isOpen })}
      position={position}
      on="click"
      onClose={(e: any) => {
        if (e && stopPropagation) {
          e.preventDefault()
          e.stopPropagation()
        }

        setIsOpen(false)
      }}
      onOpen={(e: any) => {
        if (e && stopPropagation) {
          e.preventDefault()
          e.stopPropagation()
        }

        // setTimeout here prevents the useOutsideClick
        // handler from closing the popup before it has
        // a chance to be open
        setTimeout(() => setIsOpen(true))
      }}
    >
      <Card
        ref={ref}
        boxShadow="medium"
        flexDirection="column"
        p="medium"
        minWidth="xxgigantic"
        mb="medium"
      >
        {options.map((opt, idx) => (
          <SubnavButton
            key={opt.label}
            fontWeight={fontWeight}
            mb={idx < options.length - 1 ? 'small' : undefined}
            disabled={opt.disabled || false}
            kind={opt.buttonKind || 'normal'}
            onClick={(e: any) => {
              setIsOpen(false)

              if (opt.onSelect) {
                opt.onSelect(e)
              }

              if (onSelect) {
                onSelect(opt, e)
              }
            }}
            {...omit(opt, ['label', 'value', 'onSelect'])}
          >
            {opt.label}
          </SubnavButton>
        ))}
      </Card>
    </Popup>
  )
}

Dropdown.propTypes = {
  renderButton: PropTypes.func.isRequired,
  position: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      onSelect: PropTypes.func,
    }),
  ),
  onSelect: PropTypes.func,
  keepTooltipInside: PropTypes.bool,
  openOnMount: PropTypes.bool,
  stopPropagation: PropTypes.bool,
}

Dropdown.defaultProps = {
  position: 'bottom left',
  fontWeight: 'medium',
}

export default Dropdown
