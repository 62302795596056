import { Interval } from 'luxon'
import { noonLocal, startOf, endOf } from 'utils/date'

const datePeriods = [
  // Custom - this is what it changes to if users override a macro

  // Today - starts today and ends today
  {
    label: 'Today',
    value: 'today',
    calculate: () => Interval.fromDateTimes(startOf(noonLocal(), 'day'), endOf(noonLocal(), 'day')),
  },

  // This Week - the most recent Sunday through the next Sunday
  {
    label: 'This Week',
    value: 'this-week',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal(), 'week'), endOf(noonLocal(), 'week')),
  },

  // This-week-to-date - the most recent Sunday through today
  {
    label: 'This Week-to-date',
    value: 'this-week-to-date',
    calculate: () => Interval.fromDateTimes(startOf(noonLocal(), 'week'), noonLocal()),
  },

  // This Month - the first of this month until the last day
  {
    label: 'This Month',
    value: 'this-month',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal(), 'month'), endOf(noonLocal(), 'month')),
  },

  // This-month-to-date - the first of this month until today
  {
    label: 'This Month-to-date',
    value: 'this-month-to-date',
    calculate: () => Interval.fromDateTimes(startOf(noonLocal(), 'month'), noonLocal()),
  },

  // This Quarter - the first day of this quarter until the last day of this quarter. Quarters are:
  //   First Quarter - January 1 through March 31
  //   Second Quarter - April 1 through June 30
  //   Third Quarter - July 1 through September 30
  //   Fourth Quarter - October 1 through December 31
  {
    label: 'This Quarter',
    value: 'this-quarter',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal(), 'quarter'), endOf(noonLocal(), 'quarter')),
  },

  // This-quarter-to-date - the start of the current quarter through today
  {
    label: 'This Quarter-to-date',
    value: 'this-quarter-to-date',
    calculate: () => Interval.fromDateTimes(startOf(noonLocal(), 'quarter'), noonLocal()),
  },

  // This Year - the start of this year until the end of this year
  {
    label: 'This Year',
    value: 'this-year',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal(), 'year'), endOf(noonLocal(), 'year')),
  },

  // This Year-to-date - the start of this year to today
  {
    label: 'This Year-to-date',
    value: 'this-year-to-date',
    calculate: () => Interval.fromDateTimes(startOf(noonLocal(), 'year'), noonLocal()),
  },

  // This Year-to-last-month - the start of this year to the end of last month
  {
    label: 'This Year-to-last-month',
    value: 'this-year-to-last-month',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal(), 'year'),
        endOf(noonLocal().minus({ months: 1 }), 'month'),
      ),
    disabled: noonLocal().month === 1,
  },

  // Yesterday - yesterday
  {
    label: 'Yesterday',
    value: 'yesterday',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal().minus({ days: 1 }), 'day'),
        endOf(noonLocal().minus({ days: 1 }), 'day'),
      ),
  },

  // Recent - the last five days
  {
    label: 'Recent',
    value: 'recent',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ days: 4 }), 'day'), noonLocal()),
  },

  // Last Week - the preceding Sunday through Saturday
  {
    label: 'Last Week',
    value: 'last-week',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal().minus({ weeks: 1 }), 'week'),
        endOf(noonLocal().minus({ weeks: 1 }), 'week'),
      ),
  },

  // Last Week-to-date - the preceding Sunday through today
  {
    label: 'Last Week-to-date',
    value: 'last-week-to-date',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ weeks: 1 }), 'week'), noonLocal()),
  },

  // Last Month - the first of the preceding month to the last day of that month
  {
    label: 'Last Month',
    value: 'last-month',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal().minus({ months: 1 }), 'month'),
        endOf(noonLocal().minus({ months: 1 }), 'month'),
      ),
  },

  // Last Month-to-date - the first of the preceding month to today
  {
    label: 'Last Month-to-date',
    value: 'last-month-to-date',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ months: 1 }), 'month'), noonLocal()),
  },

  // Last Quarter - the first of the preceding quarter to the last day of that quarter
  {
    label: 'Last Quarter',
    value: 'last-quarter',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal().minus({ quarters: 1 }), 'quarter'),
        endOf(noonLocal().minus({ quarters: 1 }), 'quarter'),
      ),
  },

  // Last Quarter-to-date - the first of the preceding quarter to today
  {
    label: 'Last Quarter-to-date',
    value: 'last-quarter-to-date',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ quarters: 1 }), 'quarter'), noonLocal()),
  },

  // Last Year  - the first of the preceding year to the last day of that year
  {
    label: 'Last Year',
    value: 'last-year',
    calculate: () =>
      Interval.fromDateTimes(
        startOf(noonLocal().minus({ years: 1 }), 'year'),
        endOf(noonLocal().minus({ years: 1 }), 'year'),
      ),
  },

  // Last Year-to-date - the first of the preceding year to today
  {
    label: 'Last Year-to-date',
    value: 'last-year-to-date',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ years: 1 }), 'year'), noonLocal()),
  },

  // Since 30 days ago - the preceding 30 days
  {
    label: 'Since 30 days ago',
    value: 'since-30-days-ago',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ days: 30 }), 'day'), noonLocal()),
  },

  // Since 60 days ago - the preceding 60 days
  {
    label: 'Since 60 days ago',
    value: 'since-60-days-ago',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ days: 60 }), 'day'), noonLocal()),
  },

  // Since 90 days ago - the preceding 90 days
  {
    label: 'Since 90 days ago',
    value: 'since-90-days-ago',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ days: 90 }), 'day'), noonLocal()),
  },

  // Since 365 days ago - the preceding 365 days
  {
    label: 'Since 365 days ago',
    value: 'since-365-days-ago',
    calculate: () =>
      Interval.fromDateTimes(startOf(noonLocal().minus({ days: 365 }), 'day'), noonLocal()),
  },
]

export default function calculateDatePeriods() {
  return datePeriods.map((period) => {
    const { label, value, calculate, disabled } = period
    return { label, value, interval: calculate(), disabled }
  })
}
