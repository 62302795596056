/**
 * @generated SignedSource<<d8da601c5493a10d454ee80b4d1a6217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type UserNotificationLevel = "ERROR" | "INFO" | "SUCCESS" | "%future added value";
export type UserNotificationsSubscription$variables = {};
export type UserNotificationsSubscription$data = {
  readonly currentUserNotificationsUpdated: {
    readonly event: string | null;
    readonly userNotification: {
      readonly level: UserNotificationLevel;
      readonly kind: string;
      readonly title: string;
      readonly meta: any | null;
    } | null;
  } | null;
};
export type UserNotificationsSubscription = {
  variables: UserNotificationsSubscription$variables;
  response: UserNotificationsSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNotificationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "currentUserNotificationsUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotification",
            "kind": "LinkedField",
            "name": "userNotification",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserNotificationsSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNotificationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "currentUserNotificationsUpdated",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotification",
            "kind": "LinkedField",
            "name": "userNotification",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de83539b95b0a2961b944311b6115c62",
    "id": null,
    "metadata": {},
    "name": "UserNotificationsSubscription",
    "operationKind": "subscription",
    "text": "subscription UserNotificationsSubscription {\n  currentUserNotificationsUpdated {\n    event\n    userNotification {\n      level\n      kind\n      title\n      meta\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ddd6897d40260f0d53f5eae1fceaf60";

export default node;
