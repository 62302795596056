import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth, useModalRoutes, useErrorHandler } from 'hooks'
import { Box } from 'elements'
import NavBar from 'components/app/NavBar'
import RequireAuth from 'components/router/RequireAuth'
import RequireOrg from 'components/router/RequireOrg'
import RequireCompany from 'components/router/RequireCompany'
import IndexRedirect from 'components/router/IndexRedirect'
import CustomSwitch from 'components/router/CustomSwitch'
import Notifications from 'components/app/Notifications'
import UserNotifications from 'components/app/UserNotifications'
import {
  RootPage,
  AuthPage,
  ReportPageContainer,
  OrganizationPage,
  CompanyPage,
  SettingsPage,
  ModulesPage,
  ModulesCompanyPage,
  CreateCompaniesPage,
} from 'pages'
import IntegrationsWatcher from 'components/integrations/IntegrationsWatcher'
import WorkflowConfigurationsPage from './pages/WorkflowConfigurationsPage'

export default function App() {
  useErrorHandler()
  useModalRoutes()
  const { isLoggedIn } = useAuth()

  return (
    <Box height="100vh">
      <CustomSwitch>
        <Route exact path="/">
          <RootPage />
        </Route>
        <Route exact path="/login">
          <Redirect to="/auth/login" />
        </Route>
        <Route path="/auth">
          <AuthPage />
        </Route>

        {/* hacky way to get the companyId and reportId when you're inside a report */}
        <Route path="/orgs/:orgSlug/:orgSection?/:companyId?/:reports?/:reportId?">
          <IndexRedirect path="/orgs/:orgSlug" to="/dashboard" />
          <RequireAuth>
            <IntegrationsWatcher />
            <RequireOrg>
              <NavBar />
              <CustomSwitch>
                <Route path="/orgs/:orgSlug/settings">
                  <IndexRedirect path="/orgs/:orgSlug/settings" to="/members" />
                  <SettingsPage />
                </Route>
                <Route path="/orgs/:orgSlug/modules">
                  <CustomSwitch>
                    <Route exact path="/orgs/:orgSlug/modules">
                      <ModulesPage />
                    </Route>
                    <Route path="/orgs/:orgSlug/modules/companies/:companyId">
                      <ModulesCompanyPage />
                    </Route>
                    <Route path="/orgs/:orgSlug/modules/create-companies">
                      <CreateCompaniesPage />
                    </Route>
                  </CustomSwitch>
                </Route>

                <Route path="/orgs/:orgSlug/configurations">
                  <WorkflowConfigurationsPage />
                </Route>
                <Route exact path="/orgs/:orgSlug/dashboard">
                  <OrganizationPage />
                </Route>
                <Route path="/orgs/:orgSlug/companies">
                  <CustomSwitch>
                    <Route path="/orgs/:orgSlug/companies/:companyId">
                      <RequireCompany>
                        <CustomSwitch>
                          <Route exact path="/orgs/:orgSlug/companies/:companyId">
                            <CompanyPage />
                          </Route>
                          <Route path="/orgs/:orgSlug/companies/:companyId/reports/:reportId/:steps?/:stepId?/:section?/:subSection?/:subSubSection?">
                            <ReportPageContainer />
                          </Route>
                        </CustomSwitch>
                      </RequireCompany>
                    </Route>
                  </CustomSwitch>
                </Route>
              </CustomSwitch>
            </RequireOrg>
          </RequireAuth>
        </Route>
      </CustomSwitch>
      <Notifications />
      {isLoggedIn ? <UserNotifications /> : null}
    </Box>
  )
}
