import React from 'react'
import { FormattedDate } from 'react-intl'
import { FlexColumn, Text, DatePicker, Message } from 'elements'
import { StyleProps } from '../../../../types/style'
import { toISODate, toISO } from '../../../../utils/date'

type Props = {
  transactionDate: string | null
  canEdit: boolean
  onChange?: Function
  styleProps?: StyleProps
}

export default function TransactionDate(props: Props) {
  const { transactionDate, canEdit, onChange, styleProps } = props

  return (
    <FlexColumn width="320px" {...styleProps?.boxProps}>
      <FlexColumn justifyContent="center">
        <Text fontSize="large" mb="small">
          Date
        </Text>
      </FlexColumn>
      <FlexColumn justifyContent="center">
        {canEdit ? (
          // @ts-ignore
          <DatePicker
            value={toISO(transactionDate!)}
            onChange={(value: any) => {
              if (onChange) {
                onChange('transactionDate', toISODate(value))
              }
            }}
            placeholder="MM/DD/YYYY"
            fontSize="medium"
            color="foreground.0"
            area="small"
          />
        ) : (
          <Message area="small">
            {transactionDate ? (
              <FormattedDate value={transactionDate} timeZone="utc" />
            ) : (
              <Text>--</Text>
            )}
          </Message>
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
