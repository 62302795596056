import React from 'react'
import { Form, useFormikContext } from 'formik'
import { FlexRow, FlexColumn, Button, Text, DatePicker, Select } from 'elements'
import IconInput from '../../../ui/input/IconInput'
import { Option } from '../../../../shared/types'

export type Values = {
  dateThreshold?: string
  entity?: 'vendor' | 'customer' | 'account'
}

export default function DeactivateTriggerForm() {
  const formCtx = useFormikContext()
  const { isSubmitting, setFieldValue } = formCtx
  const values = formCtx.values as Values

  const options = [
    { label: 'Accounts', value: 'account' },
    { label: 'Vendors', value: 'vendor' },
    { label: 'Customers', value: 'customer' },
  ]

  return (
    <Form>
      <FlexRow alignItems="center">
        <FlexColumn mr="xxlarge">
          <Text fontWeight="bold" fontSize="large" mb="large">
            Date
          </Text>
          <FlexColumn mb="large">
            <DatePicker
              value={values.dateThreshold || ''}
              placeholder="Date Threshold"
              onChange={(opt: any) => setFieldValue('dateThreshold', opt)}
              customInput={<IconInput icon="calendar" styleProps={{ color: 'neutral.0' }} />}
            />
          </FlexColumn>
          <Text fontWeight="bold" mb="large">
            Entity
          </Text>
          {/* @ts-ignore */}
          <Select
            options={options}
            value={options.find((opt) => opt.value === values.entity)}
            onChange={(opt: Option) => setFieldValue('entity', opt.value)}
            placeHolder="Select Entity"
          />
        </FlexColumn>
        <Button disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Posting...' : 'Post'}
        </Button>
      </FlexRow>
    </Form>
  )
}
