import { DateTime, Interval } from 'luxon'
import React, { useEffect, useState, ReactElement, useMemo } from 'react'
import { Icon, Select, FlexRow, FlexColumn, DatePicker } from 'elements'
import calculateDatePeriods from 'shared/datasets/date-periods'
import { Option } from '../../../shared/types'

const datePeriods = calculateDatePeriods()
const customOption = { label: 'Custom', value: 'custom' }
const options = [customOption, ...datePeriods]

function findPeriod(start: Date | null, end: Date | null) {
  if (!start || !end || start > end) {
    return customOption
  }

  const startDate = DateTime.fromJSDate(start)
  const endDate = DateTime.fromJSDate(end)

  const period = datePeriods.find((period) => {
    const { interval } = period

    return interval.start?.hasSame(startDate, 'day') && interval.end?.hasSame(endDate, 'day')
  })

  if (period) {
    return period
  }

  // return custom w/ these dates
  return {
    ...customOption,
    interval: Interval.fromDateTimes(startDate, endDate),
  }
}

type Props = {
  value: any
  disabled?: boolean
  onChange: Function
  styleProps?: Record<string, any>
  customInput?: ReactElement
  timeZoneValue?: string
  onTimeZoneChange?: Function
}

export default function DatePeriodInput(props: Props) {
  const {
    value,
    disabled,
    onChange,
    styleProps,
    customInput,
    timeZoneValue,
    onTimeZoneChange,
  } = props

  const [datePeriodStart, setReportPeriodStart] = useState(null)
  const [datePeriodEnd, setReportPeriodEnd] = useState(null)

  const timeZoneOpts = useMemo(
    () =>
      (Intl as any)
        .supportedValuesOf('timeZone')
        .map((zone: string) => ({ label: zone, value: zone })),
    [],
  )

  useEffect(() => {
    if (value && value.interval) {
      const { interval } = value
      setReportPeriodStart(interval.start.toJSDate())
      setReportPeriodEnd(interval.end.toJSDate())
    }
  }, [value])

  const handleIntervalChange = (option: any) => {
    if (option.value === 'custom') {
      setReportPeriodStart(null)
      setReportPeriodEnd(null)
      onChange(customOption)
    } else {
      onChange(option)
    }
  }

  const handleStartChange = (value: any) => {
    setReportPeriodStart(value)
    onChange(findPeriod(value, datePeriodEnd))
  }

  const handleEndChange = (value: any) => {
    setReportPeriodEnd(value)
    onChange(findPeriod(datePeriodStart, value))
  }

  return (
    <FlexColumn gap="medium">
      <FlexColumn>
        {/* @ts-ignore */}
        <Select
          disabled={disabled}
          options={options}
          value={value}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          isOptionDisabled={(option: any) => option.disabled}
          onChange={handleIntervalChange}
        />
      </FlexColumn>
      <FlexRow>
        <FlexColumn flex="1" mr="small">
          <DatePicker
            styleProps={styleProps?.datePicker}
            disabled={disabled}
            value={datePeriodStart}
            onChange={handleStartChange}
            placeholder="MM/DD/YYYY"
            customInput={customInput}
          />
        </FlexColumn>
        <FlexColumn mr="small" justifyContent="center">
          <Icon name="minus" color="neutral.0">
            –
          </Icon>
        </FlexColumn>
        <FlexColumn flex="1">
          <DatePicker
            styleProps={styleProps?.datePicker}
            disabled={disabled}
            value={datePeriodEnd}
            onChange={handleEndChange}
            placeholder="MM/DD/YYYY"
            customInput={customInput}
          />
        </FlexColumn>
      </FlexRow>
      {onTimeZoneChange && (
        // @ts-ignore
        <Select
          options={timeZoneOpts}
          value={timeZoneOpts.find((opt: Option) => opt.value === timeZoneValue)}
          onChange={(opt: Option) => {
            onTimeZoneChange(opt.value)
          }}
        />
      )}
    </FlexColumn>
  )
}
