/**
 * @generated SignedSource<<60c7ec5dba665fe196d85ac65b11654c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BillingPlanInterval = "MONTH" | "YEAR" | "%future added value";
export type BillingPlanType = "CUSTOM" | "STANDARD" | "%future added value";
export type UserOrganizationRole = "ADMIN" | "MEMBER" | "%future added value";
export type fetchOrgBySlugQuery$variables = {
  slug: string;
};
export type fetchOrgBySlugQuery$data = {
  readonly organizationBySlug: {
    readonly id: string;
    readonly rowId: any;
    readonly name: string;
    readonly legalName: string | null;
    readonly slug: string;
    readonly createdAt: any;
    readonly trialStartedAt: any | null;
    readonly trialEndedAt: any | null;
    readonly organizationSubscriptions: {
      readonly nodes: ReadonlyArray<{
        readonly config: any | null;
        readonly billingPlan: {
          readonly rowId: any;
          readonly slug: string;
          readonly name: string;
          readonly price: number;
          readonly type: BillingPlanType;
          readonly interval: BillingPlanInterval;
          readonly stripePriceId: string | null;
          readonly config: any | null;
        } | null;
        readonly updatedAt: any;
        readonly status: string;
        readonly endsAt: any | null;
        readonly deletedAt: any | null;
      }>;
    };
    readonly organizationCompanies: {
      readonly totalCount: number;
      readonly nodes: ReadonlyArray<{
        readonly organization: {
          readonly id: string;
        } | null;
        readonly company: {
          readonly id: string;
        } | null;
      }>;
    };
    readonly activeCompanies: {
      readonly totalCount: number;
    };
    readonly organizationUsers: {
      readonly nodes: ReadonlyArray<{
        readonly user: {
          readonly id: string;
        } | null;
        readonly role: UserOrganizationRole;
      }>;
    };
  } | null;
};
export type fetchOrgBySlugQuery = {
  variables: fetchOrgBySlugQuery$variables;
  response: fetchOrgBySlugQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialStartedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialEndedAt",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "includeDeleted",
    "value": "YES"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "UPDATED_AT_DESC"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripePriceId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v22 = [
  (v2/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": "activeCompanies",
  "args": [
    {
      "kind": "Literal",
      "name": "includeDeleted",
      "value": "NO"
    }
  ],
  "concreteType": "CompaniesConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": [
    (v21/*: any*/)
  ],
  "storageKey": "companies(includeDeleted:\"NO\")"
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchOrgBySlugQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "OrganizationSubscriptionsConnection",
            "kind": "LinkedField",
            "name": "organizationSubscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationSubscription",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPlan",
                    "kind": "LinkedField",
                    "name": "billingPlan",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationSubscriptions(includeDeleted:\"YES\",orderBy:\"UPDATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "OrganizationCompaniesConnection",
            "kind": "LinkedField",
            "name": "organizationCompanies",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCompany",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationCompanies(first:1)"
          },
          (v25/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUsersConnection",
            "kind": "LinkedField",
            "name": "organizationUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v26/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchOrgBySlugQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "OrganizationSubscriptionsConnection",
            "kind": "LinkedField",
            "name": "organizationSubscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationSubscription",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPlan",
                    "kind": "LinkedField",
                    "name": "billingPlan",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v11/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationSubscriptions(includeDeleted:\"YES\",orderBy:\"UPDATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "OrganizationCompaniesConnection",
            "kind": "LinkedField",
            "name": "organizationCompanies",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCompany",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationCompanies(first:1)"
          },
          (v25/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUsersConnection",
            "kind": "LinkedField",
            "name": "organizationUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e5fc4ae63d386780858b8f3c1bdc47e",
    "id": null,
    "metadata": {},
    "name": "fetchOrgBySlugQuery",
    "operationKind": "query",
    "text": "query fetchOrgBySlugQuery(\n  $slug: String!\n) {\n  organizationBySlug(slug: $slug) {\n    id\n    rowId\n    name\n    legalName\n    slug\n    createdAt\n    trialStartedAt\n    trialEndedAt\n    organizationSubscriptions(includeDeleted: YES, orderBy: UPDATED_AT_DESC) {\n      nodes {\n        config\n        billingPlan {\n          rowId\n          slug\n          name\n          price\n          type\n          interval\n          stripePriceId\n          config\n          id\n        }\n        updatedAt\n        status\n        endsAt\n        deletedAt\n        id\n      }\n    }\n    organizationCompanies(first: 1) {\n      totalCount\n      nodes {\n        organization {\n          id\n        }\n        company {\n          id\n        }\n        id\n      }\n    }\n    activeCompanies: companies(includeDeleted: NO) {\n      totalCount\n    }\n    organizationUsers {\n      nodes {\n        user {\n          id\n        }\n        role\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf17709f35da4b0a0a0b7013d213271f";

export default node;
