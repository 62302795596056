import React from 'react'
import FlexRow from '../FlexRow'
import Text from '../Text'
import { toInitials } from 'utils/string'

export default function Avatar(props) {
  return (
    <FlexRow
      size="36px"
      borderRadius="full"
      bg="neutral.1"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Text fontSize="small" fontWeight="bold">
        {toInitials(props.name)}
      </Text>
    </FlexRow>
  )
}
