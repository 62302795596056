import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string } from 'yup'
import { useApi, useAuth } from 'hooks'
import { createFormValidator, validators } from 'utils/validation'
import {
  Button,
  RouterLinkButton,
  LinkButton,
  Box,
  TextInputField,
  Text,
  Message,
  IntuitSignIn,
  Anchor,
  FlexColumn,
} from 'elements'
import XeroSignInButton from './XeroSignInButton'

const MFA_REQUIRED_ERROR = 'SMS_MFA'
const INTUIT_VERIFY_ERROR = 'Email address must be verified'

const StateMessage = ({ location }) => {
  if (location.state.message === INTUIT_VERIFY_ERROR) {
    return (
      <Message kind="warning" mb="medium">
        <FlexColumn>
          <Text mb="xsmall">
            Email address must be verified. Click{' '}
            <Anchor href="https://accounts.intuit.com/app/account-manager/security" target="_blank">
              here
            </Anchor>{' '}
            to verify.
          </Text>
        </FlexColumn>
      </Message>
    )
  }

  return (
    <Message kind="warning" mb="medium">
      {location.state.message}
    </Message>
  )
}

export default function Login() {
  const api = useApi()
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const [mfaRequired, setMfaRequired] = useState(false)

  // When the Multi Factor Authentication box is shown, require that field to have a value
  let validate

  if (mfaRequired) {
    validate = createFormValidator({
      email: validators.email.required('Please enter your email address'),
      password: string().required('Please enter your password'),
      mfa: string().required('Please enter your authentication code'),
    })
  } else {
    validate = createFormValidator({
      email: validators.email.required('Please enter your email address'),
      password: string().required('Please enter your password'),
    })
  }

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '', mfa: '' }}
        validate={validate}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const result = await api.auth.login(values)

            if (result.code === 200) {
              await auth.login()
              history.push('/')
            }
          } catch (error) {
            // Login failed because multi-factor authentication is enabled
            if (error?.response?.data?.message === MFA_REQUIRED_ERROR) {
              setFieldError('general', 'A login code has been sent to your phone.')
              setMfaRequired(true)
            } else {
              console.error('Error logging in', error)
              setFieldError('general', 'Incorrect email or password.')
            }
            setSubmitting(false)
          }
        }}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            {errors.general ? (
              <Message kind="warning" mb="xlarge">
                {errors.general}
              </Message>
            ) : null}
            {location.state?.message ? <StateMessage location={location} /> : null}
            <Box mb="xxxlarge">
              <Box mb="xlarge">
                <TextInputField label="Email" name="email" placeholder="email@example.com" />
              </Box>
              <Box>
                <TextInputField
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="password"
                />
              </Box>
              <Box mb="xxxlarge" mt="xlarge" display={mfaRequired ? 'block' : 'none'}>
                <TextInputField label="Single use code" name="mfa" placeholder="123456" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb="large">
              <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
                {isSubmitting ? 'Signing in...' : 'Sign In'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="medium">
        <Text fontSize="small" lineHeight="small">
          Signed up with QuickBooks?
        </Text>
        <LinkButton as="a" href={api.auth.getStrategyUrl('intuit-oauth2')} kind="transparent">
          <IntuitSignIn />
        </LinkButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="xxlarge">
        <Text fontSize="small" lineHeight="small">
          Signed up with Xero?
        </Text>
        <LinkButton as="a" href={api.auth.getStrategyUrl('xero-oauth2')} kind="transparent">
          <XeroSignInButton />
        </LinkButton>
      </Box>
      <Box display="flex" justifyContent="center" mb="small">
        <RouterLinkButton area="small" to="/auth/register">
          Sign Up
        </RouterLinkButton>
        <Text mx="xsmall">|</Text>
        <RouterLinkButton area="small" to="/auth/forgot-password">
          Forgot password?
        </RouterLinkButton>
      </Box>
    </>
  )
}
