import api from './api'

export default {
  optimizely: {
    logLevel: process.env.REACT_APP_OPTIMIZELY_LOG_LEVEL || 'error',
    sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
  },
  mixpanel: {
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
    projectToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
    apiHost: `${api.host}/proxy/mixpanel`,
  },
  syncFusion: {
    licenseKey: process.env.REACT_APP_SYNCFUSION_KEY,
  },
  intuit: {
    host: process.env.REACT_APP_INTUIT_HOST,
  },
}
