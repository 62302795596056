import { useEffect } from 'react'
import { useRelayEnvironment } from 'react-relay/hooks'
import { useHistory } from 'react-router-dom'
import fetchCurrentUser from '../../queries/fetchCurrentUser'

type Props = {
  modal?: any
}

export default function useUserValidate(props: Props) {
  const { modal } = props || {}

  const environment = useRelayEnvironment()
  const history = useHistory()

  useEffect(() => {
    const validateUser = async () => {
      const user = await fetchCurrentUser(environment)

      if (!user) {
        history.push('/auth/logout')

        if (modal) {
          modal.onClose()
        }
      }
    }

    validateUser()
  }, [environment, history, modal])
}
