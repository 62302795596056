import React from 'react'
import Box from '../Box'
import Spinner from '../Spinner'
import DelayRender from '../DelayRender'

const Loading = ({ delay, ...props }) => (
  <Box display="flex" justifyContent="center" alignContent="center" {...props}>
    <DelayRender delay={delay}>
      <Spinner />
    </DelayRender>
  </Box>
)

Loading.defaultProps = {
  delay: 1000,
}

export default Loading
