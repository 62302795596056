/**
 * @generated SignedSource<<8a56c80f3cb321f4675a7de5efafc086>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchReportQuery$variables = {
  companyId: string;
  periodStart?: any | null;
  periodEnd?: any | null;
  workflowTemplateId?: any | null;
};
export type fetchReportQuery$data = {
  readonly companyById: {
    readonly reports: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  } | null;
};
export type fetchReportQuery = {
  variables: fetchReportQuery$variables;
  response: fetchReportQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodEnd"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periodStart"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workflowTemplateId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "periodEnd",
          "variableName": "periodEnd"
        },
        {
          "kind": "Variable",
          "name": "periodStart",
          "variableName": "periodStart"
        },
        {
          "kind": "Variable",
          "name": "workflowTemplateId",
          "variableName": "workflowTemplateId"
        }
      ],
      "kind": "ObjectValue",
      "name": "condition"
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "CREATED_AT_DESC"
    }
  ],
  "concreteType": "ReportsConnection",
  "kind": "LinkedField",
  "name": "reports",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Report",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "fetchReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c38ebf309cb64bcca9d53c0566d8fd16",
    "id": null,
    "metadata": {},
    "name": "fetchReportQuery",
    "operationKind": "query",
    "text": "query fetchReportQuery(\n  $companyId: ID!\n  $periodStart: Date\n  $periodEnd: Date\n  $workflowTemplateId: UUID\n) {\n  companyById(id: $companyId) {\n    reports(condition: {periodStart: $periodStart, periodEnd: $periodEnd, workflowTemplateId: $workflowTemplateId}, orderBy: CREATED_AT_DESC) {\n      nodes {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "83498163b5fd9028dc0fbc75ce1d4712";

export default node;
