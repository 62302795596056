import React, { lazy } from 'react'
import { merge, max } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'
import { calcTimelineColWidth, getTicks } from 'utils/chart'
import { roundToNearest } from 'utils/math'
import { TimelineSeries } from 'types/chart'

const Chart = lazy(() => import('react-apexcharts'))

type Props = {
  series: TimelineSeries
  options?: Record<string, any>
  width?: string
  height?: string
  chartSpan?: 'semi' | 'full'
}

export default function BarLineAreaChart(props: Props) {
  const { options = {}, width = '100%', height = '100%', series, chartSpan = 'semi' } = props

  // create column and line plot for same data.
  const seriesData = [
    { type: 'column', data: series },
    { type: 'area', data: series },
  ]

  const maxValue = max(series.map(({ y }) => y)) ?? 0

  const defaultOptions = {
    chart: {
      type: 'line',
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        borderRadius: theme.radii.xsmall,
        // number of columns doesn't correspond to months, so use months instead
        columnWidth: calcTimelineColWidth({ chartSpan, series }),
      },
    },
    legend: { show: false },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          day: '',
          month: 'MMM',
        },
      },
    },
    yaxis: {
      tickAmount: getTicks(maxValue).amount,
      min: 0,
      max: roundToNearest(maxValue),
    },
    tooltip: {
      shared: false,
      x: {
        format: 'MMM yy',
      },
      y: {
        title: {
          // don't include series label
          formatter: () => null,
        },
      },
    },
    colors: [theme.colors.secondary[0]],
  }

  return (
    <Chart
      options={merge({}, baseChartOptions, defaultOptions, options)}
      series={seriesData}
      width={width}
      height={height}
    />
  )
}
