import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useRunReportWithIntegration() {
  return useMutation(
    graphql`
      mutation useRunReportWithIntegrationMutation($input: RunReportWithIntegrationInput!) {
        runReportWithIntegration(input: $input) {
          success
        }
      }
    `,
    'runReportWithIntegration',
  )
}
