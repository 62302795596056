import styled from 'styled-components'
import { space, color, flexbox, border, typography, variant, compose } from 'styled-system'
import { buttonBase } from '../styles'
import { filterProps } from '../utils'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      color: 'foreground.1',
      '&:hover': {
        color: 'foreground.0',
        backgroundColor: 'background.2',
      },
      '&:focus': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'neutral.7',
      },
    },
    danger: {
      color: 'danger.0',
      '&:hover': {
        color: 'background.0',
        backgroundColor: 'danger.0',
      },
      '&:focus': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'danger.0',
      },
    },
  },
})

const SubnavButton = filterProps(styled.button)`
  ${buttonBase}

  display: flex;
  text-align: left;
  justify-content: left;
  box-shadow: none;

  ${compose(kind, space, flexbox, color, border, typography)}
`

SubnavButton.defaultProps = {
  px: 'large',
  py: 'medium',
  borderRadius: 'xsmall',
  borderColor: 'transparent',
  fontSize: 'medium',
  fontWeight: 'medium',
  lineHeight: 'xsmall',
  display: 'flex',
  flex: '1 1 auto',
  kind: 'normal',
}

export default SubnavButton
