import React from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import { Table as Tables, TablePagination } from 'elements'
import DeactivateHistoryTableRow from './DeactivateHistoryTableRow'

const { Table, Head, HeadRow, HeadCell, Body } = Tables.Card

type Props = {
  integration: any
}

export default function DeactivateHistoryTable(props: Props) {
  const { integration } = props

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment DeactivateHistoryTable_integration on Integration
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "DeactivateHistoryTablePaginationQuery") {
        integrationScriptResults(after: $cursor, first: $count, orderBy: CREATED_AT_DESC)
          @connection(key: "DeactivateHistoryTable_integrationScriptResults") {
          edges {
            node {
              id
              platform
              ...DeactivateHistoryTableRow_integrationScriptResult
              ...DeactivateHistoryContextMenu_integrationScriptResult
            }
          }
        }
      }
    `,
    integration,
  )

  if (!data) {
    return null
  }

  const scriptResults = data.integrationScriptResults.edges
    .filter((edge: any) => edge.node.platform === 'intuit')
    .map((edge: any) => edge.node)

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell>Date Threshold</HeadCell>
          <HeadCell>Entity</HeadCell>
          <HeadCell>Kind</HeadCell>
          <HeadCell>Reversed</HeadCell>
          <HeadCell>Created At</HeadCell>
          <HeadCell />
        </HeadRow>
      </Head>
      <Body>
        {scriptResults.map((scriptResult: any) => (
          <DeactivateHistoryTableRow scriptResult={scriptResult} key={scriptResult.id} />
        ))}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={5} {...paginationProps} />
    </Table>
  )
}
