import React from 'react'
import { Box } from 'elements'

export default function ModalContent(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      p="xxlarge"
      overflowY="auto"
      pb="xgigantic"
      {...props}
    />
  )
}
