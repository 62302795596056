import React from 'react'
import { FlexRow, Icon, Text } from 'elements'

export default function ErrorBanner(props) {
  return (
    <FlexRow
      justifyContent="flex-start"
      alignItems="center"
      borderRadius="small"
      flex="0 0 auto"
      px="xlarge"
      py="medium"
      bg="secondary.0"
      color="background.0"
      {...props}
    >
      <Icon name="info-circle" size="medium" mr="medium" />
      <Text color="inherit" fontWeight="bold">
        {props.message}
      </Text>
    </FlexRow>
  )
}
