import React from 'react'
import { isFunction } from 'lodash'
import { FlexColumn, Text } from 'elements'

type Props = {
  message: string
  action?: any
  fontSize?: string
  justifyContent?: string
  color?: string
}

export default function EmptyState(props: Props) {
  const {
    message,
    action,
    fontSize = 'xxxlarge',
    justifyContent = 'center',
    color = 'neutral.2',
  } = props

  return (
    <FlexColumn flex="1" alignItems="center" justifyContent={justifyContent} maxHeight="50%">
      <Text color={color} fontSize={fontSize} lineHeight={fontSize} mb={action ? 'xlarge' : 'none'}>
        {message}
      </Text>
      {isFunction(action) ? action() : action}
    </FlexColumn>
  )
}
