/**
 * @generated SignedSource<<9e5d5030bc4c629c38a2ef42bd63b7bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyContextMenu_company$data = {
  readonly id: string;
  readonly deletedAt: any | null;
  readonly " $fragmentType": "CompanyContextMenu_company";
};
export type CompanyContextMenu_company$key = {
  readonly " $data"?: CompanyContextMenu_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyContextMenu_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyContextMenu_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "c5581579f26bd7a8a509571373cbce35";

export default node;
