import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { get } from 'lodash'
import { compose, typography, color, background, border } from 'styled-system'

const interactiveCss = css`
  cursor: pointer;
  &:hover {
    background: ${themeGet('colors.neutral.2')};
  }
`

const DataTableRow = styled.tr`
  ${compose(typography, color, background, border)}

  ${({ bg, theme }) => bg && `background: ${get(theme.colors, bg)} !important;`}}
  
  &:nth-child(2n-1) {
    background: ${themeGet('colors.neutral.7')};
  }

  background: ${themeGet('colors.background.0')};

  ${({ onClick }) => (onClick ? interactiveCss : null)}
`

export default DataTableRow
