import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import ModulesCompaniesTable from './ModulesCompaniesTable'
import { FlexColumn } from 'elements'
import { SortColumn } from '../../types/table'

const columnSorts: Record<string, string> = {
  Client: 'NAME',
  'Latest Period': 'LATEST_PERIOD_END',
}

const getOrder = (sortCol: SortColumn | null): string => {
  if (!sortCol?.order) {
    return 'CREATED_AT_DESC'
  }

  const { column, order } = sortCol

  return `${columnSorts[column]}_${order.toUpperCase()}`
}

export default function ModulesCompaniesContainer() {
  const params = useParams<any>()
  const slug = params.orgSlug || ''
  const [sortCol, setSortCol] = useState<SortColumn | null>(null)

  const data = useLazyLoadQuery<any>(
    graphql`
      query ModulesCompaniesContainerQuery($slug: String!, $order: [CompaniesOrderBy!]) {
        organizationBySlug(slug: $slug) {
          name
          id
          ...ModulesCompaniesTable_organization
        }
      }
    `,
    { slug, order: getOrder(sortCol) },
  )

  const organization = data?.organizationBySlug

  return (
    <FlexColumn>
      <ModulesCompaniesTable organization={organization} sortCol={sortCol} onSort={setSortCol} />
    </FlexColumn>
  )
}
