import React, { useState } from 'react'
import connectToQBO from 'theme/images/connect-to-quickbooks.svg'
import connectToQBOHover from 'theme/images/connect-to-quickbooks-hover.svg'
import connectTransparent from 'theme/images/connect-to-qb-transparent.svg'
import connectTransparentHover from 'theme/images/connect-to-qb-transparent-hover.svg'
import Image from '../Image'

type Props = {
  color?: 'green' | 'transparent'
  height?: string
}

export default function IntuitConnect(props: Props) {
  const { color = 'green', height = '100%' } = props

  const [hover, setHover] = useState(false)

  let src: any = null

  if (color === 'green') {
    if (hover) {
      src = connectToQBOHover
    } else {
      src = connectToQBO
    }
  } else if (color === 'transparent') {
    if (hover) {
      src = connectTransparentHover
    } else {
      src = connectTransparent
    }
  }

  return (
    <Image
      id="connectToQBO"
      src={src}
      height={height}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    />
  )
}
