import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useChangeOrgRoleMutation($input: UpdateOrganizationUserByIdInput!) {
    updateOrganizationUserById(input: $input) {
      organizationUser {
        role
        user {
          id
          name
        }
      }
    }
  }
`

export default function useChangeOrgRole() {
  return useMutation(mutation, 'updateOrganizationUserById')
}
