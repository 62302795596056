import React from 'react'
import { FlexRow } from 'elements'

export default function ContentHeader(props: any) {
  const { children, borderTop = true } = props

  return (
    <FlexRow
      borderTop={borderTop && 'solid 1px'}
      borderBottom="solid 1px"
      borderColor="neutral.1"
      px="xxlarge"
      py="20px"
      bg="background.0"
      mb="xlarge"
      // ignore page content padding
      ml="-32px"
      mr="-32px"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </FlexRow>
  )
}
