import React from 'react'
import Box from '../Box'

type Props = {
  progress: number
  width?: string
  ease?: number
}

export default function Progress(props: Props) {
  const { progress, width = '100%', ease = 200 } = props

  return (
    // @ts-ignore
    <Box position="relative" height="small" bg="neutral.2" width={width} borderRadius="small">
      <Box
        // @ts-ignore
        bg="secondary.0"
        position="absolute"
        top="0"
        left="0"
        height="small"
        style={{ transition: `all ${ease}ms ease` }}
        width={`${progress}%`}
        borderRadius="small"
      />
    </Box>
  )
}
