import React from 'react'
//@ts-ignore
import styled from 'styled-components'
import {
  compose,
  variant,
  border,
  space,
  color,
  flexbox,
  shadow,
  typography,
  layout,
  //@ts-ignore
} from 'styled-system'
import { omit } from 'lodash'
import { buttonBase, print } from '../styles'

const states = {
  '&:hover': {
    boxShadow: 'medium',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:active:focus': {
    boxShadow: 'none',
  },
}

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      color: 'foreground.0',
      bg: 'background.0',
      borderColor: 'neutral.2',
      ...states,
      '&:focus, &:active': {
        boxShadow: 'medium',
        borderColor: 'neutral.1',
      },
    },
    transparent: {
      color: 'foreground.0',
      bg: 'transparent',
      borderColor: 'transparent',
      ...states,
    },
    primary: {
      color: 'background.0',
      bg: 'primary.0',
      borderColor: 'primary.0',
      ...states,
    },
    secondary: {
      color: 'background.0',
      bg: 'secondary.0',
      borderColor: 'secondary.0',
      ...states,
    },
    neutral: {
      color: 'background.0',
      bg: 'neutral.0',
      borderColor: 'neutral.0',
      ...states,
    },
    dim: {
      color: 'background.0',
      bg: 'neutral.2',
      borderColor: 'neutral.2',
      ...states,
    },
    disabled: {
      color: 'neutral.2',
      bg: 'neutral.3',
      borderColor: 'neutral.3',
      opacity: 1,
      ...states,
    },
    danger: {
      color: 'background.0',
      bg: 'danger.0',
      borderColor: 'danger.0',
      ...states,
    },
    'alt-transparent': {
      color: 'background.0',
      bg: 'transparent',
      borderColor: 'background.0',
      ...states,
    },
    'basic-transparent': {
      color: 'foreground.0',
      borderColor: 'transparent',
      bg: 'transparent',
    },
    'icon-button': {
      color: 'foreground.3',
      borderColor: 'foreground.3',
      bg: 'transparent',
      ':hover': { borderColor: 'secondary.0', color: 'secondary.0' },
    },
    'alt-primary': {
      color: 'primary.0',
      bg: 'background.0',
      borderColor: 'primary.0',
      ...states,
    },
    'alt-secondary': {
      color: 'secondary.0',
      bg: 'background.0',
      borderColor: 'secondary.0',
      ...states,
    },
    'alt-secondary-2': {
      color: 'secondary.0',
      bg: 'secondary.3',
      border: 'transparent',
      ...states,
    },
    'alt-danger': {
      color: 'danger.0',
      bg: 'background.0',
      borderColor: 'danger.0',
      ...states,
    },
    'dark-outline': {
      color: 'accent.darkblue',
      bg: 'background.0',
      borderColor: 'accent.darkblue',
      ...states,
    },
    'alt-neutral': {
      color: 'neutral.0',
      bg: 'background.0',
      borderColor: 'neutral.1',
      ...states,
      '&:focus, &:active': {
        boxShadow: 'medium',
        borderColor: 'neutral.1',
      },
    },
    'alt-neutral-2': {
      color: 'accent.darkblue',
      bg: 'background.0',
      borderColor: 'neutral.2',
      ...states,
      '&:focus, &:active': {
        boxShadow: 'medium',
        borderColor: 'neutral.2',
      },
    },
    xero: {
      color: 'background.0',
      bg: 'xero.0',
      border: 'none',
      '&:hover': {
        bg: 'xero.1',
      },
    },
  },
})

const area = variant({
  prop: 'area',
  variants: {
    xxsmall: {
      py: 'none',
      px: 'none',
    },
    xsmall: {
      fontSize: 'xsmall',
      py: 'xsmall',
      px: 'xsmall',
    },
    small: {
      fontSize: 'medium',
      lineHeight: 'small',
      py: 'xsmall',
      px: 'large',
    },
    medium: {
      fontSize: 'medium',
      py: 'small',
      px: 'large',
    },
    large: {
      fontSize: 'medium',
      py: 'medium',
      px: 'large',
    },
    xlarge: {
      fontSize: 'medium',
      lineHeight: 'large',
      py: 'large',
      px: 'xlarge',
    },
  },
})

const shape = variant({
  prop: 'shape',
  variants: {
    block: {
      borderRadius: 'none',
    },
    'semi-block': {
      borderRadius: 'xsmall',
    },
    normal: {
      borderRadius: 'small',
    },
    rounded: {
      borderRadius: 'full',
    },
  },
})

const StyledButton = styled.button`
  ${buttonBase}
  ${print}

  ${compose(
    // custom variants should go at the top so props they define
    // can be overridden on occasion when needed
    area,
    kind,
    shape,
    border,
    space,
    flexbox,
    color,
    shadow,
    typography,
    layout,
  )}
`

function handleClick(event: any, props: any) {
  const { onClick, onPress, bindData } = props

  if (onPress) {
    event.preventDefault()
    onPress(bindData)
  }

  if (onClick) {
    onClick(event)
  }
}

const Button = React.forwardRef((props: any, ref: any) => (
  <StyledButton
    {...omit(props, ['onPress'])}
    ref={ref}
    onClick={(e: any) => handleClick(e, props)}
  />
))

Button.defaultProps = {
  kind: 'normal',
  area: 'medium',
  shape: 'normal',
  fontWeight: 'bold',
  borderWidth: 'thin',
  borderStyle: 'solid',
}

export default Button
