/**
 * @generated SignedSource<<65cc04fefe63ff159db192598a615c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateStripeIntegrationInput = {
  apiKey: string;
  companyId: string;
};
export type useStripeIntegrationCreateMutation$variables = {
  input: CreateStripeIntegrationInput;
};
export type useStripeIntegrationCreateMutation$data = {
  readonly createStripeIntegration: {
    readonly integration: {
      readonly id: string;
      readonly company: {
        readonly " $fragmentSpreads": FragmentRefs<"StripeIntegration_company">;
      } | null;
    };
  } | null;
};
export type useStripeIntegrationCreateMutation = {
  variables: useStripeIntegrationCreateMutation$variables;
  response: useStripeIntegrationCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStripeIntegrationCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStripeIntegrationPayload",
        "kind": "LinkedField",
        "name": "createStripeIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Integration",
            "kind": "LinkedField",
            "name": "integration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StripeIntegration_company"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStripeIntegrationCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStripeIntegrationPayload",
        "kind": "LinkedField",
        "name": "createStripeIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Integration",
            "kind": "LinkedField",
            "name": "integration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "IntegrationsConnection",
                    "kind": "LinkedField",
                    "name": "integrations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IntegrationsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Integration",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rowId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "config",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "integrations(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "StripeIntegration_integrations",
                    "kind": "LinkedHandle",
                    "name": "integrations"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08bfb3b095a49c637d333cd61ebe6509",
    "id": null,
    "metadata": {},
    "name": "useStripeIntegrationCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useStripeIntegrationCreateMutation(\n  $input: CreateStripeIntegrationInput!\n) {\n  createStripeIntegration(input: $input) {\n    integration {\n      id\n      company {\n        ...StripeIntegration_company\n        id\n      }\n    }\n  }\n}\n\nfragment StripeIntegration_company on Company {\n  integrations(first: 100) {\n    edges {\n      node {\n        id\n        rowId\n        kind\n        status\n        config\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "1c0257407af99337e62837ac5f264121";

export default node;
