import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useDecision } from '@optimizely/react-sdk'
import { FlexColumn, Text, FlexRow } from 'elements'
import Header from '../app/Header'

export default function ModulesCompanyHeader() {
  const { companyId } = useParams<Record<string, any>>()
  const canViewIds = useDecision('canViewIds')[0].enabled

  const data = useLazyLoadQuery<any>(
    graphql`
      query ModulesCompanyHeaderQuery($companyId: ID!) {
        companyById(id: $companyId) {
          name
          rowId
        }
      }
    `,
    { companyId },
  )

  if (!data) {
    return null
  }

  return (
    <FlexColumn>
      <Header>
        <FlexRow alignItems="center">
          <Text fontWeight="bold" fontSize="large" mr={canViewIds && 'large'}>
            {data.companyById.name}
          </Text>
          {canViewIds && <Text>{data.companyById.rowId}</Text>}
        </FlexRow>
      </Header>
    </FlexColumn>
  )
}
