import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const modalStyles = {
  screen: {
    right: '0',
    p: 'xlarge',
    bg: 'background.0',
    boxShadow: 'high',
  },
  default: {
    background:
      'linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,0.9) 20%,rgba(255,255,255,1) 100%)',
    right: '15px',
    p: 'xxlarge',
    pt: 'gigantic',
  },
}

const ModalFooter = ({ children, gradientColor, displayGradient, modal, ...props }) => {
  const style = modalStyles[modal?.behavior] || modalStyles.default

  return (
    <Box position="absolute" zIndex="1" bottom="0" left="0" {...style} {...props}>
      {children}
    </Box>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ModalFooter
