import React from 'react'
import { usePaginationFragment, graphql } from 'react-relay/hooks'
import { compact } from 'lodash'
import { Table as Tables, FlexColumn, FlexRow, Dropdown, Icon, LinkButton } from 'elements'
import EmptyState from 'components/common/EmptyState'
import WorkflowTableRow from './WorkflowTableRow'
import { TablePagination } from 'elements'

const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = Tables.Card

type Props = {
  company: any
  includeDeleted: boolean
  onArchiveToggle: Function
}

export default function ActiveWorkflowTable(props: Props) {
  const { company, includeDeleted, onArchiveToggle } = props

  const { data, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment ActiveWorkflowTable_company on Company
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "ActiveWorkflowsPaginationQuery") {
        activeReports: filteredReports(
          input: { status: "active" }
          after: $cursor
          first: $count
          orderBy: CREATED_AT_DESC
          includeDeleted: $includeDeleted
        ) @connection(key: "ActiveWorkflowsTable_activeReports") {
          edges {
            node {
              id
              ...WorkflowTableRow_report
            }
          }
        }
      }
    `,
    company,
  )

  if (!data) {
    return null
  }

  // compact for when record is deleted permanently (null node)
  const activeReports = compact(data.activeReports.edges.map((edge: any) => edge.node))

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell>Workflow</HeadCell>
          <HeadCell>Workflow Progress</HeadCell>
          <HeadCell>Period</HeadCell>
          <HeadCell />
          <HeadCell width="100px">
            <FlexRow alignItems="center" justifyContent="flex-end">
              <Dropdown
                position="bottom right"
                options={[
                  {
                    label: includeDeleted ? 'Hide archived' : 'Show archived',
                    onSelect: onArchiveToggle,
                  },
                ]}
                stopPropagation
                renderButton={() => (
                  // @ts-ignore
                  <LinkButton kind="transparent">
                    <Icon
                      size="medium"
                      name="cog"
                      color={includeDeleted ? 'secondary.0' : 'neutral.2'}
                    />
                  </LinkButton>
                )}
              />
            </FlexRow>
          </HeadCell>
        </HeadRow>
      </Head>
      <Body>
        {!activeReports.length ? (
          <Row>
            <Cell colSpan={5}>
              <FlexColumn p="xxxlarge">
                <EmptyState message="No active workflows" />
              </FlexColumn>
            </Cell>
          </Row>
        ) : (
          activeReports.map((report: any) => (
            <WorkflowTableRow report={report} key={report.id} includeDeleted={includeDeleted} />
          ))
        )}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={5} {...paginationProps} />
    </Table>
  )
}
