import React from 'react'
import { useFormikContext } from 'formik'
import { FlexRow, FlexColumn, Text, TextInputField } from 'elements'
import { formatAmount } from '../../../utils/string'
import { updateTransferTotal } from '../utils/resolutions/formUpdates'

type Props = {
  transactionAmount: number | null
  canEditAmount?: boolean
}

type SubProps = {
  transactionAmount: number | null
  formCtx?: any
}

const EditTotal = (props: SubProps) => {
  const { transactionAmount, formCtx } = props
  const { handleBlur } = formCtx

  return (
    <FlexRow alignItems="center">
      <Text fontSize="large" fontWeight="bold" mr="small" flex="1">
        Total Amount:
      </Text>
      <FlexColumn flex="1.5">
        {/* @ts-ignore */}
        <TextInputField
          name="transactionAmount"
          value={transactionAmount || ''}
          onBlur={(e: any) => {
            updateTransferTotal(e.target.value, formCtx)
            handleBlur(e)
          }}
          placeholder="Total Amount"
          color="foreground.0"
          fontSize="medium"
        />
      </FlexColumn>
    </FlexRow>
  )
}

const StaticTotal = (props: SubProps) => {
  const { transactionAmount } = props

  return (
    <FlexRow alignItems="end" height="100%">
      <Text fontSize="large" mr="xlarge" pb="xsmall">
        Total Amount
      </Text>
      <Text fontSize="gigantic" lineHeight="gigantic" fontWeight="xbold">
        {Number.isFinite(transactionAmount) ? formatAmount(transactionAmount!, '$') : '--'}
      </Text>
    </FlexRow>
  )
}

export default function TransactionTotalAmount(props: Props) {
  const { transactionAmount, canEditAmount } = props
  const formCtx = useFormikContext()

  if (canEditAmount) {
    return <EditTotal transactionAmount={transactionAmount} formCtx={formCtx} />
  }

  return <StaticTotal transactionAmount={transactionAmount} />
}
