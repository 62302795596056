import { ReportSection } from '../../../types'
import { insightDescriptions, insights } from '../../../constants'
import { StackedBarChart, TableConnected } from '../../../../../elements'
import { has, forOwn, filter, camelCase, kebabCase } from 'lodash'
import { drilldownReportSection } from './constants'

const { NumericCell } = TableConnected as any

type Props = {
  formattedSummary: Record<string, any>
  configuredUserActivity: any[]
}

type TransactionCountMap = {
  [key: string]: number
}

type UserTransactionMap = {
  [key: string]: Record<string, any>
}

/**
 * This spaghetti code is responsible for taking transaction type by user and organizing it in a way that fits
 * a stacked bar chart
 *
 * Input example:
 * [{
 *   userName: "Andrew",
 *   transactionTypes: [{transactionType: "Invoice", "transactionsCreated": 1, "transactionsModified": 1}]
 * }]
 *
 * Output example:
 *
 * formattedChartData: [{name: "Invoice", data: [2]}]
 * mostCommonTransactionTypes: ["Invoice", "Payment", "Bill"]
 * users: ["Andrew"]
 *
 * @param transactionTypeByUser
 */
export const formatTransactionsForChart = (transactionTypeByUser: any) => {
  let users: string[] = []
  let transactionCountMap: TransactionCountMap = {}
  let userTransactionMap: UserTransactionMap = {}

  // Creates two maps and an array
  // 1. Transaction types --> total occurrences.  Ex: {"Invoice": 20}
  // 2. Users --> transaction types --> # of occurrences.  Ex: {"Andrew": {"Invoice": 10}}
  // 3. Array of all usernames.  Ex: ["Andrew", "Jack"]
  transactionTypeByUser.forEach((c: any) => {
    users.push(c.userName)
    userTransactionMap[c.userName] = {}

    if (!c.transactionTypes) {
      return
    }

    c.transactionTypes.forEach((t: any) => {
      let initialValue = transactionCountMap[t.transactionType]
        ? transactionCountMap[t.transactionType]
        : 0
      let transactionTotal = t.transactionsCreated + t.transactionsModified
      transactionCountMap[t.transactionType] = initialValue + transactionTotal
      userTransactionMap[c.userName][t.transactionType] = transactionTotal
    })
  })

  // Maps transactions to a list of data points.  Ex: [{name: "Invoice", data: [2, 10, 30]}]
  const formattedChartData: any[] = []
  forOwn(transactionCountMap, (val, key) => {
    let dataValues: number[] = []

    // Iterate over each of the users to build a single array of data points for each transaction type
    users.forEach((u) => {
      // Check if the user has a count for this transaction type.  If so append the value, otherwise append 0
      let transactionMap = userTransactionMap[u]
      if (has(transactionMap, key)) {
        dataValues.push(transactionMap[key])
      } else {
        // If not, append 0
        dataValues.push(0)
      }
    })
    formattedChartData.push({
      name: key,
      data: dataValues,
    })
  })

  // This part's weird.. For the preview chart we want to only show the top 3 most frequently occurring transaction
  // types. To do this, we start by creating a Map and sorting it by number of occurrences of each transaction
  // in descending order. Then we convert the keys of that map to an array and return the first 3.
  // Input: [{Check: 1, Invoice: 10, Bill: 20, Payment: 30}]
  // Output: ["Invoice", "Bill", "Payment"]
  const sortedByTransactionCount = new Map(
    Object.entries(transactionCountMap).sort((a, b) => b[1] - a[1]),
  )
  const mostCommonTransactionTypes = Array.from(sortedByTransactionCount.keys()).slice(0, 3)

  return { formattedChartData, mostCommonTransactionTypes, users }
}

export default function getUserReportSection(props: Props): ReportSection {
  const { formattedSummary, configuredUserActivity } = props

  const { transactionTypeByUser } = formattedSummary

  const { formattedChartData, mostCommonTransactionTypes, users } = formatTransactionsForChart(
    transactionTypeByUser,
  )

  const userActivityColumns = [
    { Header: 'Name', accessor: 'userName' },
    {
      Header: 'Transactions Created',
      accessor: 'transactionCreatedCount',
      whiteSpace: 'normal',
      Cell: NumericCell,
    },
    {
      Header: 'Transactions Modified',
      accessor: 'transactionModifiedCount',
      whiteSpace: 'normal',
      Cell: NumericCell,
    },
    { Header: 'Anomaly Count', accessor: 'anomalyCount', Cell: NumericCell },
  ]

  return {
    label: 'Users',
    value: 'userInsights',
    kind: 'insight',
    dataSections: [
      {
        key: 'transactionTypeByUser',
        title: 'Transaction Type by User',
        insights: insights.transactionTypeByUser,
        detailRoute: 'transaction-type-by-user',
        Chart: StackedBarChart,
        chartOptions: {
          options: { xaxis: { categories: users.slice(0, 10) } },
        },
        data: filter(formattedChartData, (data) => {
          return mostCommonTransactionTypes.includes(data.name)
        }),
        subReportSections: [
          {
            label: 'Transaction Type By User',
            value: 'transactionTypeByUser',
            route: 'transaction-type-by-user',
            kind: 'insight',
            dataSections: [
              {
                key: 'transactionTypeByUserDescription',
                title: 'Description',
                description: insightDescriptions.transactionTypeByUser,
                gridRow: '1',
                gridColumn: '1',
              },
              {
                key: 'transactionTypeByUserInsights',
                title: 'Insights',
                insights: insights.transactionTypeByUser,
                gridRow: '1',
                gridColumn: '2',
              },
              {
                key: 'transactionTypeByUserDetailChart',
                Chart: StackedBarChart,
                gridRow: '2',
                chartOptions: { options: { xaxis: { categories: users.slice(0, 5) } } },
                data: filter(formattedChartData, (data) => {
                  return mostCommonTransactionTypes.includes(data.name)
                }),
              },
              {
                key: 'transactionVolumeByUser',
                table: true,
                data: configuredUserActivity,
                gridRow: '3',
                columns: userActivityColumns,
                tableOptions: {
                  initialSortColumns: [{ id: 'transactionCreatedCount', desc: true }],
                  highlightedColumn: 'Name',
                  rowRoute: (row: any) => kebabCase(row.original.userName),
                },
                subReportSections: configuredUserActivity.map((user) => {
                  const data =
                    transactionTypeByUser
                      .find((transTypeUser: any) => user.userName === transTypeUser.userName)
                      ?.transactionTypes?.filter(
                        (type: Record<string, any>) =>
                          type.transactionsCreated && type.transactionsModified,
                      ) || []

                  const dataSections = [
                    {
                      key: `${camelCase(user.userName)}-transactionTypeByUserTable`,
                      table: true,
                      tableOptions: {
                        initialSortColumns: [{ id: 'transactionsCreated', desc: true }],
                        highlightedColumn: 'Type',
                        rowRoute: (row: any) =>
                          `details?type=${row.original.transactionType}&createdBy=${user.userName}`,
                      },
                      data,
                      subReportSections: [drilldownReportSection],
                    },
                  ]

                  return {
                    label: user.userName,
                    value: camelCase(user.userName),
                    kind: 'insight',
                    route: kebabCase(user.userName),
                    dataSections,
                    exportData: [
                      { sheetName: `${user.userName} Transactions by Type`, dataSections },
                    ],
                  }
                }),
              },
            ],
          },
        ],
      },
    ],
  }
}
