import React, { useEffect } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow, Text, Icon, RouterLink, Button } from 'elements'
import { useAnalytics } from 'hooks'
import { downloadFile } from '../utils/download'
import { SheetData, ReportSection } from '../types'
import InsightSections from './insights/InsightSections'
import { dashboardPages } from '../constants'
import { startCase } from 'lodash'
import { genBackUrl } from 'utils/url'

type ExportProps = {
  reportName: string
  exportData: SheetData[]
  sectionName: string
  analytics: any
}

async function handleExport(props: ExportProps) {
  const { reportName, exportData, sectionName, analytics } = props

  const { exportReport } = await import('../export/exportReport')

  const blob = await exportReport(exportData)

  downloadFile(`Scrutinize - ${reportName} - ${sectionName}.xlsx`, blob)

  analytics.track('Report Downloaded', {
    fileName: sectionName,
  })
}

const displayExportOption = (reportSection: ReportSection) => {
  const { exportData, label } = reportSection

  // there are existing utils that can return an empty array for export data
  if (dashboardPages.includes(label) || (exportData && !exportData.length)) {
    return false
  }

  return true
}

type HeaderProps = {
  reportSection: ReportSection
  location: any
}

const Header = (props: HeaderProps & any) => {
  const { reportSection, location } = props
  const { parentSection, kind } = reportSection

  if (
    kind === 'insight' ||
    kind === 'anomaly' ||
    kind === 'transactionByType' ||
    kind === 'report'
  ) {
    if (parentSection) {
      return (
        <FlexRow {...props}>
          <RouterLink to={genBackUrl(location.pathname)} color="foreground.0">
            <FlexRow alignItems="center">
              <Icon name="arrow-left" mr="small" />
              <Text>{`Back to ${parentSection.label}`}</Text>
            </FlexRow>
          </RouterLink>
        </FlexRow>
      )
    }

    return (
      <Text
        fontSize="small"
        lineHeight="small"
        fontWeight="bold"
        color="neutral.3"
        mb="small"
        textTransform="uppercase"
      >
        {startCase(kind) + 's'}
      </Text>
    )
  }

  return null
}

type ReportContentProps = {
  report: any
  reportSection: ReportSection
}

export default function ReportContent(props: ReportContentProps) {
  const { report, reportSection } = props
  const { dataSections, label, exportData, parentSection } = reportSection

  const analytics = useAnalytics()
  const location = useLocation()

  const data = useFragment(
    graphql`
      fragment ReportContent_report on Report {
        rowId
        name
      }
    `,
    report,
  )

  useEffect(() => {
    const reportId = data?.rowId

    if (reportId) {
      analytics.track('Report Section Viewed', {
        reportId,
        section: label,
        parentSection: parentSection?.label || null,
      })
    }
  }, [data?.rowId, analytics, label, parentSection])

  const displayExport = displayExportOption(reportSection)

  return (
    <FlexColumn pt="xxlarge">
      <Header reportSection={reportSection} location={location} mb="small" />
      <FlexRow justifyContent={displayExport && 'space-between'} alignItems="center" mb="xlarge">
        <Text fontSize="32px" lineHeight="xxlarge" fontWeight="bold">
          {label}
        </Text>
        {displayExport && (
          <Button
            borderRadius="small"
            kind="alt-neutral-2"
            borderWidth="xthin"
            area="large"
            fontWeight="normal"
            onClick={() =>
              handleExport({
                reportName: data?.name,
                exportData: exportData || [{ sheetName: label, dataSections }],
                sectionName: label,
                analytics,
              })
            }
          >
            Export
          </Button>
        )}
      </FlexRow>
      <InsightSections dataSections={dataSections} reportSectionLabel={label} />
    </FlexColumn>
  )
}
