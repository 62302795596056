import React from 'react'
import { FlexColumn, FlexRow, Text, Select, Message } from 'elements'
import { Option } from '../../../../shared/types'
import { toTitleCase } from '../../../../utils/string'
import { getAccountOptions } from '../../utils/resolutions/dropdownOptions'
import { StyleProps } from '../../../../types/style'
import { bankAccountTypes } from '../constants'

type Props = {
  transactionValues: Record<string, any>
  canEdit: boolean
  accounts?: any[]
  onChange: Function
  styleProps?: StyleProps
}

const accountUpdateFields = {
  transactionAccount: 'accountName',
  transactionAccountId: 'accountId',
}

const transactionTypeLabels: { [key: string]: any } = {
  salesReceipt: 'Deposit To Account',
  refundReceipt: 'Refund From Account',
}

export default function TransactionAccount(props: Props) {
  const { canEdit, accounts, transactionValues, onChange, styleProps } = props
  const { transactionAccount, transactionAccountId, transactionType } = transactionValues

  const includedAccountTypes = transactionType === 'deposit' ? ['Bank'] : bankAccountTypes
  const accountOptions = canEdit ? getAccountOptions(accounts!, includedAccountTypes) : []

  const label = transactionTypeLabels[transactionType] || 'Bank/Credit Account'

  return (
    <FlexColumn width="320px" {...styleProps?.boxProps}>
      <FlexColumn justifyContent="center">
        <Text fontSize="large" mb="small">
          {label}
        </Text>
      </FlexColumn>
      <FlexColumn justifyContent="center">
        {canEdit ? (
          // @ts-ignore
          <Select
            value={accountOptions.find(
              (option: Option) => option.value.accountId === transactionAccountId,
            )}
            onChange={(option: any) => onChange(option, accountUpdateFields)}
            placeholder="Bank/Credit Account"
            options={accountOptions}
            formatOptionLabel={(option: Option, meta: any) => {
              const { label, value } = option
              const { context } = meta

              return (
                <FlexRow justifyContent="space-between">
                  <Text>{label}</Text>
                  {context === 'menu' && (
                    <Text fontStyle="italic" color="neutral.0" ml="small">
                      {toTitleCase(value.accountType)}
                    </Text>
                  )}
                </FlexRow>
              )
            }}
          />
        ) : (
          <Message area="small">{transactionAccount}</Message>
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
