import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportResolutionsApplyMutation(
    $input: ApplyReportResolutionsInput!
    $resolutionSection: String
    $anomalies: [String]
  ) {
    applyReportResolutions(input: $input) {
      report {
        resolutionProgress
        ...ReportResolution_report
        ...ReportResolutionTable_report
        ...WorkspaceHeader_report
      }
    }
  }
`

export default function useReportResolutionsApply() {
  return useMutation(mutation, 'applyReportResolutions')
}
