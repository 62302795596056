import React from 'react'
import { hot } from 'react-hot-loader'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { IntlProvider } from 'react-intl'
import ReactModal from 'react-modal'
import { registerLicense } from '@syncfusion/ej2-base'
import {
  AuthProvider,
  AnalyticsProvider,
  ModalsProvider,
  PageProvider,
  OptimizelyProvider,
} from 'providers'
import theme from 'theme'
import GlobalStyle from 'theme/global'
import logo from 'theme/images/scrutinize-logo-256x256.png'
import { environment } from './graphql/relay'
import App from './App'
import config from './config'

// Fixes this issue: https://github.com/reactjs/react-modal/issues/632
ReactModal.setAppElement('#root')

const helmetContext = {}

// register license for syncFusion library (custom query builder and other components)
// NOTE: all syncfusion libraries must be the same major version or the license key won't work
registerLicense(config.services.syncFusion.licenseKey)

export function Root() {
  return (
    <HelmetProvider context={helmetContext}>
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <RelayEnvironmentProvider environment={environment}>
            <Router>
              <AuthProvider>
                <OptimizelyProvider>
                  <Helmet
                    htmlAttributes={{ lang: 'en' }}
                    title="Anomaly detection for your accounting stack"
                    titleTemplate="%s - Scrutinize"
                    meta={[
                      {
                        name: 'description',
                        content: 'Anomaly detection for your accounting stack.',
                      },
                      { property: 'og:site_name', content: 'Scrutinize' },
                    ]}
                    link={[
                      { rel: 'icon', href: logo },
                      {
                        rel: 'stylesheet',
                        href: 'https://fonts.googleapis.com/css?family=Lato:400,600,700',
                      },
                    ]}
                  />
                  <AnalyticsProvider>
                    <ModalsProvider>
                      <PageProvider>
                        <App />
                      </PageProvider>
                    </ModalsProvider>
                  </AnalyticsProvider>
                  <GlobalStyle />
                </OptimizelyProvider>
              </AuthProvider>
            </Router>
          </RelayEnvironmentProvider>
        </ThemeProvider>
      </IntlProvider>
    </HelmetProvider>
  )
}

export default hot(module)(Root)
