import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useBulkDeactivateUndoMutation($input: UndoBulkDeactivateIntuitInput!) {
    undoBulkDeactivateIntuit(input: $input) {
      integrationScriptResult {
        integration {
          ...DeactivateHistoryTable_integration
        }
      }
    }
  }
`

export default function useBulkDeactivateUndo() {
  return useMutation(mutation, 'undoBulkDeactivateIntuit')
}
