import { kebabCase, camelCase } from 'lodash'
import { RadialBarChart, DonutChart, TableConnected } from 'elements'
import { ReportSection } from '../../../types'
import { insights, insightDescriptions } from '../../../constants'
import theme from 'theme'
import { drilldownReportSection } from './constants'

const { NumericCell, DateCell } = TableConnected as any

type Props = {
  formattedSummary: Record<string, any>
}

export default function getCustomerReportSection(props: Props): ReportSection | null {
  const { formattedSummary } = props

  const {
    filteredTransactions,
    transactionByCustomerList,
    customerUsage,
    percentageScores,
    customerList,
  } = formattedSummary

  const customerCompletenessDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.customerCompletenessScore,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.customerCompletenessScore,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      Chart: RadialBarChart,
      chartOptions: {
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: { show: false },
                value: {
                  formatter: (val: number) => `${val}%`,
                  fontSize: theme.fontSizes.xgigantic,
                  fontWeight: theme.fontWeights.xxbold,
                },
              },
            },
          },
        },
      },
      data: [percentageScores.customer],
      gridColumn: '1',
      gridRow: '2',
    },
  ]

  const customerUsageSubReportSections = customerUsage.map((category: any) => {
    const dataSections = [
      {
        key: `customerUsage_${camelCase(category.customerCategory)}`,
        table: true,
        data: category.customers,
        columns: [
          { Header: 'Name', accessor: 'customerDisplayName' },
          {
            Header: 'Date Created',
            accessor: 'customerCreatedTime',
            Cell: DateCell,
          },
          {
            Header: 'Date Modified',
            accessor: 'customerLastUpdatedTime',
            Cell: DateCell,
          },
        ],
        tableOptions: { highlightedColumn: 'Name' },
      },
    ]

    return {
      label: category.customerCategory,
      value: `customerUsage_${camelCase(category.customerCategory)}`,
      kind: 'insight',
      route: kebabCase(category.customerCategory),
      dataSections,
      exportData: [
        {
          sheetName: `Customers ${category.customerCategory}`,
          dataSections,
        },
      ],
    }
  })

  const customerUsageDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.customerUsage,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.customerUsage,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      Chart: DonutChart,
      chartOptions: {
        options: {
          colors: [theme.colors.secondary[0], theme.colors.secondary[8]],
        },
      },
      data: customerUsage
        .slice(0, 2)
        .map((category: any) => ({ name: category.customerCategory, data: category.count })),
      gridColumn: '1',
      gridRow: '2',
    },
    {
      table: true,
      data: customerUsage,
      columns: [
        {
          Header: 'Customers',
          accessor: 'customerCategory',
        },
        {
          Header: 'Count',
          accessor: 'count',
          sortDescFirst: true,
          Cell: NumericCell,
        },
      ],
      tableOptions: {
        highlightedColumn: 'Customers',
        rowRoute: customerList?.length
          ? (row: any) => kebabCase(row.original.customerCategory)
          : null,
      },
      subReportSections: customerUsageSubReportSections,
    },
  ]

  const transactionByCustomerDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.transactionByCustomer,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.transactionByCustomer,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      gridRow: '2',
      table: true,
      data: filteredTransactions.byCustomer,
      tableOptions: {
        rowRoute: (row: any) =>
          `details?entityName=${encodeURIComponent(row.original.customerName)}&entityType=customer`,
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const customerDataSections = [
    {
      key: 'customerCompletenessScore',
      title: 'Customer Profile Completeness Score',
      insights: insights.customerCompletenessScore,
      Chart: RadialBarChart,
      chartOptions: {
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: { show: false },
                value: {
                  formatter: (val: number) => `${val}%`,
                  fontSize: theme.fontSizes.xgigantic,
                  fontWeight: theme.fontWeights.xxbold,
                },
              },
            },
          },
        },
      },
      data: [percentageScores.customer],
      detailRoute: 'customer-completeness',
      subReportSections: [
        {
          label: 'Customer Profile Completeness Score',
          value: 'customerCompletenessScore',
          route: 'customer-completeness',
          kind: 'insight',
          dataSections: customerCompletenessDataSections,
          // manual export data for entire sheet to include completeness score as table
          exportData: [
            {
              sheetName: 'Customer Profile Completeness Score',
              dataSections: [
                ...customerCompletenessDataSections.slice(0, 2),
                {
                  data: [{ Score: `${percentageScores.customer}%` }],
                  table: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: 'customerUsage',
      title: 'Customer Usage',
      insights: insights.customerUsage,
      Chart: DonutChart,
      data: customerUsage
        ?.slice(0, 2)
        .map((category: any) => ({ name: category.customerCategory, data: category.count })),
      chartOptions: {
        options: {
          colors: [theme.colors.secondary[0], theme.colors.secondary[8]],
        },
      },
      detailRoute: 'customer-usage',
      subReportSections: [
        {
          label: 'Customer Usage',
          value: 'customerUsage',
          route: 'customer-usage',
          kind: 'insight',
          dataSections: customerUsageDataSections,
          subReportSections: customerUsageSubReportSections,
        },
      ],
    },
    {
      key: 'transactionByCustomer',
      title: 'Transaction Volume by Customer',
      insights: insights.transactionByCustomer,
      table: true,
      tableOptions: {
        initialSortColumns: [{ id: 'totalTransactionCount', desc: true }],
        highlightedColumn: 'Customer Name',
        pageSize: 3,
        pagination: true,
        preview: true,
        disableSortBy: true,
      },
      data: filteredTransactions.byCustomer,
      detailRoute: 'transaction-by-customer',
      subReportSections: [
        {
          label: 'Transaction Volume by Customer',
          value: 'transactionByCustomer',
          route: 'transaction-by-customer',
          kind: 'insight',
          dataSections: transactionByCustomerDataSections,
        },
      ],
    },
  ]

  if (transactionByCustomerList?.length) {
    return {
      label: 'Customers',
      value: 'customerInsights',
      kind: 'insight',
      dataSections: customerDataSections,
    }
  }

  return null
}
