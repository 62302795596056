import React from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import { Table as Tables, TablePagination } from 'elements'
import StripeHistoryTableRow from './StripeHistoryTableRow'

const { Table, Head, HeadRow, HeadCell, Body } = Tables.Card

type Props = {
  company: any
}

export default function StripeHistoryTable(props: Props) {
  const { company } = props

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment StripeHistoryTable_company on Company
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "StripeHistoryTablePaginationQuery") {
        companyUserFiles(after: $cursor, first: $count, orderBy: CREATED_AT_DESC)
          @connection(key: "StripeHistoryTable_companyUserFiles") {
          edges {
            node {
              userFile {
                id
                kind
                ...StripeHistoryTableRow_userFile
                ...StripeHistoryContextMenu_userFile
              }
            }
          }
        }
      }
    `,
    company,
  )

  if (!data) {
    return null
  }

  const stripeFiles = data.companyUserFiles.edges
    .map((edge: any) => edge.node.userFile)
    .filter((userFile: any) => userFile.kind === 'stripe:xlsx:stripe-data')

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell>Run Period</HeadCell>
          <HeadCell>Created At</HeadCell>
          <HeadCell />
        </HeadRow>
      </Head>
      <Body>
        {stripeFiles.map((file: any, idx: number) => (
          <StripeHistoryTableRow stripeFile={file} key={file.id} />
        ))}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={3} {...paginationProps} />
    </Table>
  )
}
