import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import Button from '../Button'
import Icon from '../Icon'
import Text from '../Text'
import Dropdown from '../Dropdown'

const modalStyles: Record<string, any> = {
  default: {
    fontSize: 'large',
    iconColor: 'neutral.2',
  },
  screen: {
    fontSize: 'xxlarge',
    iconColor: 'neutral.1',
    iconSize: 'medium',
  },
}

const ModalNavHeader = ({
  onClose,
  onBack,
  backText,
  titleText,
  styleProps,
  onAction,
  actionOptions,
  modal,
  ...containerProps
}: any) => {
  const modalStyle = modalStyles[modal?.behavior] || modalStyles.default
  const { iconColor, iconSize } = modalStyle
  const fontSize = styleProps?.titleText?.fontSize || modalStyle.fontSize

  const closeOnly = onClose && !backText && !titleText && !actionOptions

  return (
    <Box px="xxlarge" py="xlarge" {...containerProps}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={closeOnly ? 'flex-end' : 'space-between'}
      >
        {backText || titleText ? (
          <Button kind="transparent" area="xxsmall" onPress={onBack} disabled={!onBack} opacity="1">
            {onBack ? <Icon name="arrow-left" mr="medium" color={iconColor} /> : null}
            <Text fontWeight="bold" fontSize={fontSize}>
              {backText || titleText}
            </Text>
          </Button>
        ) : null}
        <Box display="flex" flexDirection="row">
          {actionOptions ? (
            <Dropdown
              position="bottom right"
              options={actionOptions}
              onSelect={onAction}
              renderButton={() => (
                <Button kind="transparent" area="xxsmall" mr={onClose ? 'xlarge' : '0'}>
                  <Icon name="dots-horizontal" color={iconColor} />
                </Button>
              )}
            />
          ) : null}
          {onClose ? (
            <Button kind="transparent" area="xxsmall" onPress={onClose}>
              <Icon name="x" color={iconColor} size={iconSize} />
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

ModalNavHeader.propTypes = {
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  backText: PropTypes.string,
  titleText: PropTypes.string,
  onAction: PropTypes.func,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
}

export default ModalNavHeader
