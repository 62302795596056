import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FlexRow from '../../FlexRow'
import Text from '../../Text'
import { formatAmount } from '../../../utils/string'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function LedgerCell(props) {
  const { value, cellProps } = props.cell
  const { onClick, cellRoute } = cellProps || {}
  const { align } = props.column
  const sectionHeader = props.row.rowProps?.sectionHeader

  const history = useHistory()
  const location = useLocation()

  let hasValue = false
  let ledgerValue

  if (Number.isFinite(value)) {
    ledgerValue = formatAmount(value)
    hasValue = true
  }

  if (sectionHeader && !hasValue) {
    return null
  }

  let cellClick = onClick

  if (cellRoute) {
    cellClick = () => history.push(`${location.pathname}/${cellRoute}`)
  }

  return (
    <FlexRow flex="1" justifyContent="flex-end" onClick={cellClick}>
      <FlexRow
        flex="1"
        justifyContent={align ? alignments[align] : 'space-between'}
        maxWidth="110px"
      >
        <Text fontSize="inherit" color="inherit" mr={alignments[align] && 'xsmall'}>
          {hasValue ? '$' : ''}
        </Text>
        <Text fontSize="inherit" color={hasValue ? 'inherit' : 'neutral.1'}>
          {hasValue ? ledgerValue : '--'}
        </Text>
      </FlexRow>
    </FlexRow>
  )
}
