import React from 'react'
// @ts-ignore
import styled from 'styled-components'
// @ts-ignore
import { variant, border, space, color, layout, flexbox, typography } from 'styled-system'
import { omit } from 'lodash'
import { buttonBase } from '../styles'
import { filterProps } from '../utils'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: 'inherit',
      },
    },
    primary: {
      color: 'primary.0',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    danger: {
      color: 'danger.0',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hover: {
      color: 'foreground.0',
      '&:hover': { color: 'accent.lightblue' },
    },
  },
})

const hoverColor = variant({
  prop: 'hoverColor',
  variants: {
    navHover: {
      '&:hover': {
        color: 'nav.default',
        textDecoration: 'none',
      },
    },
  },
})

const area = variant({
  prop: 'area',
  variants: {
    xxsmall: {
      py: 'none',
      px: 'none',
    },
    xsmall: {
      fontSize: 'xsmall',
      lineHeight: 'xsmall',
      py: 'xsmall',
      px: 'xsmall',
    },
    small: {
      fontSize: 'small',
      lineHeight: 'small',
    },
    medium: {
      fontSize: 'medium',
      lineHeight: 'medium',
    },
    large: {
      fontSize: 'large',
      lineHeight: 'large',
    },
  },
})

const StyledLinkButton = filterProps(styled.button)`
  /*
    custom variants should go at the top so props they define
    can be overridden on occasion when needed
  */
  ${buttonBase}
  ${area}
  ${kind}

  border-color: transparent;
  text-decoration: none;
  color: inherit;

  ${hoverColor}
  ${border}
  ${space}
  ${layout}
  ${flexbox}
  ${color}
  ${typography}
`

function handleClick(event: any, props: any) {
  const { onClick, onPress, bindData } = props

  if (onPress) {
    event.preventDefault()
    onPress(bindData)
  }

  if (onClick) {
    onClick(event)
  }
}

const LinkButton = React.forwardRef((props: any, ref) => (
  <StyledLinkButton
    {...omit(props, ['onPress'])}
    ref={ref}
    onClick={(e: any) => handleClick(e, props)}
  />
))

LinkButton.defaultProps = {
  kind: 'normal',
  area: 'medium',
  fontWeight: 'medium',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: 'inherit',
}

export default LinkButton
