import { graphql, useSubscription } from 'react-relay/hooks'
import { useNotifications, useModals } from 'hooks'
import ReportStatusDetailsModal from '../report/list/ReportStatusDetailsModal'

const subscription = graphql`
  subscription UserNotificationsSubscription {
    currentUserNotificationsUpdated {
      event
      userNotification {
        level
        kind
        title
        meta
      }
    }
  }
`

export default function UserNotifications() {
  const notifications = useNotifications()
  const { openModal } = useModals()

  useSubscription({
    subscription,
    onNext: (response, ...args) => {
      if (!response.currentUserNotificationsUpdated?.userNotification) {
        return
      }

      const { level, title, meta } = response.currentUserNotificationsUpdated.userNotification

      switch (level) {
        case 'SUCCESS':
          return notifications.success(title)
        case 'ERROR':
          return notifications.error(title, {
            onClick: () =>
              openModal(ReportStatusDetailsModal, { reportRowId: meta.reportId }, 'full'),
          })
        case 'INFO':
          return notifications.info(title)
        default:
          console.error(`Unknown notification level [${level}]`)
          return
      }
    },
  })

  // this component doesn't render anything
  return null
}
