import React from 'react'
import { FlexRow } from 'elements'

export const HEADER_HEIGHT = 75

export default function Header(props) {
  return (
    <FlexRow
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height={`${HEADER_HEIGHT}px`}
      p="large"
      bg="background.0"
      borderBottom="1px solid"
      borderColor="neutral.2"
      {...props}
    >
      {props.children}
    </FlexRow>
  )
}
