import React, { useState, useEffect } from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useHistory } from 'react-router-dom'
import SelectUploadSource from './SelectUploadSource'
import FileUploadReport from './FileUploadReport'
import ConnectionChoices from '../../integrations/ConnectionChoices'
import { useAuth, useAnalytics } from 'hooks'
import useReportCreate from '../hooks/useReportCreate'
import useRunReportWithIntegration from '../hooks/useRunReportWithIntegration'
import CreateReportDataSources from '../../integrations/CreateReportDataSources'
import { ReportPeriod } from '../../../shared/types'

type Props = {
  modal: any
  integrationResult?: any
  companyId: string
  reportPeriod: ReportPeriod
  workflowTemplateId: string
}

export default function CreateReportModal(props: Props) {
  const { modal, integrationResult, companyId, reportPeriod, workflowTemplateId } = props

  const { currentCompany, currentOrg } = useAuth()
  const [createReport]: any = useReportCreate()
  const [runReportWithIntegration]: any = useRunReportWithIntegration()
  const analytics = useAnalytics()
  const history = useHistory()

  const [choice, setChoice] = useState<any>(null)
  const [uploadSource, setUploadSource] = useState('qbo')
  const [step, setStep] = useState(0)
  const [noCurrentTenant, setNoCurrentTenant] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  // company from auth are null momentarily when returning from an integration,
  // which causes the query to throw an error. Pass query params in that situation
  const compId = currentCompany?.id || companyId

  const data = useLazyLoadQuery<any>(
    graphql`
      query CreateReportModalQuery($compId: ID!) {
        companyById(id: $compId) {
          ...CreateReportDataSources_company
          integrations(first: 100) {
            nodes {
              rowId
              id
              kind
              config
            }
          }
        }
      }
    `,
    { compId },
  )

  const integration = data?.companyById?.integrations?.nodes?.find(
    (integration: any) => integration.kind === integrationResult?.integrationKind,
  )

  useEffect(() => {
    // integration from the query is defined after connecting your integration
    if (!integration) {
      return
    }

    if (integration.kind === 'xero' && !integration.config.xero.currentTenant) {
      setNoCurrentTenant(true)
    }
  }, [integration])

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleNext = () => {
    setStep(step + 1)
  }

  const handleCreateIntegrationReport = async () => {
    setSubmitting(true)

    const { report } = await createReport(
      {
        companyId: compId,
        kind: 'anomaly:1',
        periodStart: reportPeriod.periodStart,
        periodEnd: reportPeriod.periodEnd,
        workflowTemplateId,
      },
      { includeDeleted: 'NO' },
    )

    // call the runReportWithIntegration lambda, but don't wait for it since
    // we'll get updates via report subscription
    runReportWithIntegration({
      reportId: report.id,
      integrationId: integration.id,
    })

    analytics.track('Report Created', { reportId: report.rowId })
    modal.onClose()

    history.push(`/orgs/${currentOrg!.slug}/companies/${compId}/reports/${report.id}`)
  }

  if (!data) {
    return null
  }

  if (step === 0) {
    return (
      <CreateReportDataSources
        onClose={modal.onClose}
        integrationResult={integrationResult}
        onNext={handleNext}
        onKindSelect={setChoice}
        company={data.companyById}
        onSubmit={handleCreateIntegrationReport}
        submitInFlight={submitting}
        reportPeriod={reportPeriod}
        workflowTemplateId={workflowTemplateId}
        noCurrentTenant={noCurrentTenant}
      />
    )
  }

  if (step === 1 && noCurrentTenant) {
    return (
      <ConnectionChoices
        integration={integration}
        onDone={handleCreateIntegrationReport}
        onClose={modal.onClose}
        submitting={submitting}
      />
    )
  }

  if (choice === 'upload') {
    if (step === 1) {
      return (
        <SelectUploadSource
          modal={modal}
          onNext={handleNext}
          onBack={handleBack}
          selected={uploadSource}
          onChange={setUploadSource}
        />
      )
    }

    return (
      <FileUploadReport
        modal={modal}
        onBack={handleBack}
        uploadSource={uploadSource}
        workflowTemplateId={workflowTemplateId}
        reportPeriod={reportPeriod}
      />
    )
  }
}
