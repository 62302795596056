import React from 'react'
import EmptyState from './EmptyState'
import { Table, FlexColumn } from 'elements'

const CardTable = Table.Card

type Props = {
  message?: string
  action?: any
  styleProps?: {
    fontSize?: string
    justifyContent?: string
    color?: string
  }
}

export default function TableEmptyState(props: Props) {
  const { styleProps, message = 'No data to display.' } = props

  return (
    <CardTable.Table>
      <CardTable.Row>
        <CardTable.Cell>
          <FlexColumn p="xxlarge">
            <EmptyState {...props} {...styleProps} message={message} />
          </FlexColumn>
        </CardTable.Cell>
      </CardTable.Row>
    </CardTable.Table>
  )
}
