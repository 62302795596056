import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexColumn, Text, Button, Message, Modal, FlexRow } from 'elements'
import ModalContent from 'components/app/ModalContent'
import ModalCloseButton from 'components/app/ModalCloseButton'
import { useAuth, useApi } from 'hooks'
import DataSources from './DataSources'
import { ReportPeriod } from '../../shared/types'

type Props = {
  onClose: Function
  integrationResult: any
  onNext: Function
  onKindSelect: Function
  company: any
  onSubmit: Function
  submitInFlight: boolean
  reportPeriod: ReportPeriod
  workflowTemplateId: string
  noCurrentTenant: boolean
}

type Kind = 'upload' | 'intuit' | 'xero'

export default function CreateReportDataSources(props: Props) {
  const {
    onClose,
    integrationResult,
    onNext,
    onKindSelect,
    company,
    onSubmit,
    submitInFlight,
    reportPeriod,
    workflowTemplateId,
    noCurrentTenant,
  } = props

  const { periodStart, periodEnd } = reportPeriod

  const { currentOrg } = useAuth()
  const api = useApi()

  const data = useFragment(
    graphql`
      fragment CreateReportDataSources_company on Company {
        rowId
        id
      }
    `,
    company,
  )

  const handleNext = (kind: Kind) => {
    onKindSelect(kind)

    if (kind !== 'upload') {
      const connectUrl = api.integration.getConnectUrl(data.rowId, kind, {
        modal: 'create-report',
        modalBehavior: 'full',
        orgSlug: currentOrg!.slug,
        companyId: data.id,
        periodStart,
        periodEnd,
        workflowTemplateId,
      })

      window.location.href = connectUrl
    } else {
      onNext()
    }
  }

  let submitText = 'Submit'

  if (submitInFlight) {
    submitText = 'Saving...'
  } else if (noCurrentTenant) {
    submitText = 'Next'
  }

  return (
    <>
      <ModalContent pt="gigantic" px="gigantic" height="550px">
        <ModalCloseButton onClose={onClose} />
        <Text mb="xlarge">Add a data source</Text>
        <FlexColumn mb="large">
          {integrationResult?.status === 'error' ? (
            <Message kind="danger" mb="xlarge">
              Your account could not be connected.
            </Message>
          ) : null}
          {integrationResult?.status === 'success' && (
            <Message kind="success" mb="xlarge">
              Your account was connected successfully!
            </Message>
          )}
          <DataSources handleNext={handleNext} />
        </FlexColumn>
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px="gigantic"
        pb="gigantic"
      >
        <Button disabled={submitInFlight} onPress={onClose} kind="alt-neutral">
          Cancel
        </Button>
        <FlexRow>
          {integrationResult?.status === 'success' && (
            <Button
              kind="primary"
              onClick={noCurrentTenant ? onNext : onSubmit}
              disabled={submitInFlight}
            >
              {submitText}
            </Button>
          )}
        </FlexRow>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
