import React from 'react'
import { FlexRow, Text, DelayRender } from 'elements'
import Header from './Header'
import PageBody from './PageBody'

export default function PageBodyLoading() {
  return (
    <PageBody
      renderHeader={() => (
        <>
          <DelayRender delay={1000}>
            <Header>
              <FlexRow>
                <Text color="neutral.0" fontSize="xlarge" lineHeight="xlarge" fontWeight="bold">
                  Loading...
                </Text>
              </FlexRow>
            </Header>
          </DelayRender>
        </>
      )}
      renderContent={() => null}
    />
  )
}
