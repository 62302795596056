import React from 'react'
import { FormattedDate } from 'react-intl'
import { FlexColumn, Text, Anchor, Icon, Button } from 'elements'

type Props = {
  periodStart: string
  periodEnd: string
  handleRetry: () => void
  submitting: boolean
}

export default function CellCountStatus(props: Props) {
  const { periodStart, periodEnd, handleRetry, submitting } = props

  const message = 'Xlsx upload exceeds maximum cell count for'

  return (
    <FlexColumn p="xxlarge" alignItems="center" color="danger.0">
      <Icon name="exclamation-circle" color="inherit" size="xxlarge" mb="medium" />
      <Text fontSize="xlarge" lineHeight="xlarge" color="inherit" mb="large">
        {message} period {'('}
        <FormattedDate value={periodStart} timeZone="utc" />
        {' - '}
        <FormattedDate value={periodEnd} timeZone="utc" />
        {').'}
      </Text>
      <Button onClick={handleRetry} disabled={submitting} mb="gigantic">
        Retry Workflow
      </Button>
      <Text textAlign="center" mb="xxxlarge">
        Please feel free to review our support documentation or create a ticket{' '}
        <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
          here.
        </Anchor>
      </Text>
    </FlexColumn>
  )
}
