import { camelCase, orderBy, kebabCase, compact } from 'lodash'
import { ReportSection, SourceKind } from '../../../types'
import { ConfigSection } from '../../../../../shared/types'
import { formatAnomalyForExport } from '../../../export/formatters'
import { getAnomalyDataSections } from '../../../utils/anomalies'

type AnomalySubSectionProps = {
  report: any
  configSections?: ConfigSection[]
  reportId: string
  integrationId?: string
  sourceKind?: SourceKind
}

export default function getAnomalyList(props: AnomalySubSectionProps): ReportSection[] {
  const { report, configSections, reportId, integrationId, sourceKind } = props
  const { reportMetadata, reportDetailData } = report

  const reportEndDate = reportMetadata.reportPeriod.endDate

  const anomalies = orderBy(reportDetailData.anomalies, ['anomalyName'], ['asc'])

  return (
    anomalies
      // remove anomaly types with no data and report anomalies
      .filter((anomaly) => anomaly.transactions?.length || anomaly.data?.length)
      .map((anomaly) => ({
        label: anomaly.anomalyName,
        value: camelCase(anomaly.anomalyName),
        route: kebabCase(anomaly.anomalyName),
        kind: 'anomaly',
        //data sections are configured here
        dataSections: getAnomalyDataSections({
          anomaly,
          context: 'table',
          configSections,
          reportEndDate,
          reportId,
          integrationId,
          sourceKind,
        }),
        exportData: compact([
          formatAnomalyForExport({ anomaly, reportEndDate, configSections, reportId }),
        ]),
      }))
  )
}
