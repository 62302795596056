import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { flatten, pick } from 'lodash'
import { ResolutionAnomalyType, Transaction, DataSection, Column } from '../types'
import { duplicateAnomalies, codingAnomalies } from '../constants'
import ReportTable from '../view/common/ReportTable'
import { reportResolutionColumns, typeOneLineCols } from './constants'
import { useModals } from 'hooks'
import ResolutionModalContainer from './ResolutionModalContainer'

type Props = {
  selectedAnomalyType: ResolutionAnomalyType
  resolution: any
  onClose: () => boolean
  reportId: string
  anomalyView?: string
  canEdit?: boolean
}

const duplicateHeaders = ['Date', 'Number', 'Amount']

// transaction data fields to condition the query
const anomalyTransactionFields: Record<string, string[]> = {
  duplicateBills: ['transactionNum', 'transactionEntityId'],
  duplicateChecks: ['transactionNum', 'transactionAccountId'],
  duplicateInvoices: ['transactionNum'],
  duplicateExpenses: [
    'transactionEntityId',
    'transactionNum',
    'transactionDate',
    'transactionAmount',
  ],
}

const filterByEntity = (
  resInitialData: Transaction,
  relatedResolutions: Record<string, any>[],
): Record<string, any>[] => {
  // most coding anomaly transactions have a transaction-level entity. If this resolution does,
  // we want the list of transactions that have the same entity id or have some lines that have that entity id
  if (resInitialData.transactionEntityId) {
    return relatedResolutions.filter((res: any) => {
      const listResInitialData = res.initialValue.transactionData

      return (
        listResInitialData.transactionEntityId === resInitialData.transactionEntityId ||
        listResInitialData.transactionLines.some(
          (line: any) => line.lineEntityId === resInitialData.transactionEntityId,
        )
      )
    })
  }

  // sometimes they have an entity for each line – in this case we want inconsistent coding transactions
  // for all of the entities, including transactions that have that entity at the line level
  const txnEntityIds = resInitialData.transactionLines.map((line) => line.lineEntityId)

  return relatedResolutions.filter((res) => {
    const listResInitialData = res.initialValue.transactionData

    return (
      txnEntityIds.includes(listResInitialData.transactionEntityId) ||
      listResInitialData.transactionLines.some((line: any) =>
        txnEntityIds.includes(line.lineEntityId),
      )
    )
  })
}

export default function RelatedTransactionsTable(props: Props) {
  const { selectedAnomalyType, resolution, onClose, reportId, anomalyView, canEdit } = props
  const { openModal }: any = useModals()

  let transactionData: Partial<Transaction> | null = null

  const resInitialData = resolution.initialValue.transactionData
  const txnFields = anomalyTransactionFields[selectedAnomalyType.type]

  if (txnFields) {
    transactionData = pick(resInitialData, txnFields)
  }

  const data = useLazyLoadQuery<any>(
    graphql`
      query RelatedTransactionsTableQuery(
        $anomalies: [String]
        $transactionData: JSON
        $reportId: ID!
      ) {
        reportById(id: $reportId) {
          id
          filteredReportResolutions(
            input: { anomalies: $anomalies, transactionData: $transactionData }
          ) {
            nodes {
              id
              currentValue
              initialValue
              anomalyTypes
              status
              resolutionKind
            }
          }
        }
      }
    `,
    { anomalies: [selectedAnomalyType.type], transactionData, reportId },
  )

  const resolutions = data?.reportById?.filteredReportResolutions?.nodes

  if (!resolutions) {
    return null
  }

  // filter out current resolution
  let relatedResolutions = resolutions.filter((node: any) => node.id !== resolution.id)

  // filter by entity for coding anomalies
  if (codingAnomalies.includes(selectedAnomalyType.type)) {
    relatedResolutions = filterByEntity(resInitialData, relatedResolutions)
  }

  const relatedDataSection: DataSection = {
    key: 'relatedDataTable',
    tableOptions: {
      tableProps: {
        border: 'solid 1px',
        borderColor: 'background.1',
        boxShadow: 'low',
      },
      onRowClick: (row: any) => {
        // onClose returns true if the the user confirms
        if (onClose()) {
          openModal(
            ResolutionModalContainer,
            {
              resolutionId: row.original.resolutionId,
              platformCompanyId: row.original.platformCompanyId,
              reportId,
              anomalyView,
              canEdit,
            },
            'screen',
          )
        }
      },
    },
  }

  if (duplicateAnomalies.includes(selectedAnomalyType.type)) {
    relatedDataSection.columns = reportResolutionColumns.filter((col: Column) =>
      typeof col.Header === 'string' ? duplicateHeaders.includes(col.Header) : false,
    )

    relatedDataSection.data = relatedResolutions?.map((resolution: any) => ({
      ...resolution.currentValue.transactionData,
      // resolution id and platform company id for modal querying
      resolutionId: resolution.id,
      platformCompanyId: resolution.currentValue.platformCompanyId,
    }))

    relatedDataSection.tableOptions!.emptyMessage =
      'There are no longer any duplicates for this transaction.'
  }

  if (codingAnomalies.includes(selectedAnomalyType.type)) {
    relatedDataSection.columns = typeOneLineCols

    // flatten to array of transaction lines
    relatedDataSection.data = flatten(
      relatedResolutions?.map((resolution: any) =>
        resolution.currentValue.transactionData.transactionLines.map((line: any) => ({
          ...line,
          // resolution id and platform company id for modal querying
          resolutionId: resolution.id,
          platformCompanyId: resolution.currentValue.platformCompanyId,
        })),
      ),
    )

    relatedDataSection.tableOptions!.emptyMessage = 'No lines to display.'
  }

  return <ReportTable dataSection={relatedDataSection} />
}
