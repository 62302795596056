import styled from 'styled-components'
import Text from '../Text'

const AnchorText = styled(Text)`
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

AnchorText.defaultProps = {
  color: 'secondary.0',
  as: 'a',
  fontSize: 'inherit',
}

export default AnchorText
