/**
 * @generated SignedSource<<46535aadf9fd87600420e2b53fdf96ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type IntegrationStatus = "DISABLED" | "ENABLED" | "SUSPENDED" | "%future added value";
export type IntegrationsWatcherSubscription$variables = {};
export type IntegrationsWatcherSubscription$data = {
  readonly currentUserIntegrationsUpdated: {
    readonly event: string | null;
    readonly integration: {
      readonly id: string;
      readonly rowId: any;
      readonly kind: string;
      readonly status: IntegrationStatus;
      readonly config: any | null;
    } | null;
  } | null;
};
export type IntegrationsWatcherSubscription = {
  variables: IntegrationsWatcherSubscription$variables;
  response: IntegrationsWatcherSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "IntegrationSubscriptionPayload",
    "kind": "LinkedField",
    "name": "currentUserIntegrationsUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "event",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Integration",
        "kind": "LinkedField",
        "name": "integration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IntegrationsWatcherSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IntegrationsWatcherSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9f686f2981999b38f42ad1bf36bd1822",
    "id": null,
    "metadata": {},
    "name": "IntegrationsWatcherSubscription",
    "operationKind": "subscription",
    "text": "subscription IntegrationsWatcherSubscription {\n  currentUserIntegrationsUpdated {\n    event\n    integration {\n      id\n      rowId\n      kind\n      status\n      config\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e61423a334405cdaf7f54233d051cd8d";

export default node;
