import React from 'react'
import { isNil } from 'lodash'
import FlexRow from '../../FlexRow'
import Text from '../../Text'
import { toTitleCase } from '../../../utils/string'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const getDisplayValue = (value, style) => {
  if (isNil(value) || (typeof value === 'string' && !value.length)) {
    return '--'
  }

  if (style?.titleCase) {
    return toTitleCase(value)
  }

  return value
}

export default function StandardCell(props) {
  const { value } = props.cell
  const { align, style } = props.column

  return (
    <FlexRow flex="1" justifyContent={align ? alignments[align] : 'flex-start'}>
      <Text fontSize="inherit" color="inherit" {...style?.text}>
        {getDisplayValue(value, style)}
      </Text>
    </FlexRow>
  )
}
