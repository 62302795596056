import React from 'react'
import Card from '../Card'
import Text from '../Text'
import Checkbox from '../Checkbox'
import FlexRow from '../FlexRow'
import FlexColumn from '../FlexColumn'

type Props = {
  allColumns: any[]
}

export default React.forwardRef((props: Props, ref: any) => {
  const { allColumns } = props

  return (
    <Card
      ref={ref}
      boxShadow="medium"
      flexDirection="column"
      p="medium"
      minWidth="300px"
      mb="medium"
    >
      <Text fontSize="large" fontWeight="bold" mb="large">
        Change columns
      </Text>
      <FlexColumn height="250px" flexWrap="wrap" gap="small">
        {allColumns.map((col: any) => (
          <FlexRow key={col.id}>
            <FlexRow mr="small">
              <Checkbox {...col.getToggleHiddenProps()} />
            </FlexRow>
            <Text>{col.Header}</Text>
          </FlexRow>
        ))}
      </FlexColumn>
    </Card>
  )
})
