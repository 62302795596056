import React from 'react'
import { Box, Button, Modal, Text, SuspenseBoundary, Message } from 'elements'
import DatePeriodInput from 'components/ui/input/DatePeriodInput'
import ModalContent from '../../app/ModalContent'
import ModalCloseButton from '../../app/ModalCloseButton'
import IconInput from '../../ui/input/IconInput'
import WorkflowTemplateSelectContainer from '../../app/WorkflowTemplateSelectContainer'
import { ReportPeriod } from 'shared/types'

type Props = {
  onBack: Function
  submitting: boolean
  reportPeriod: ReportPeriod
  onReportPeriodChange: Function
  valid: boolean
  handleCreate: Function
  onClose: Function
  workflowTemplate: any
  onWorkflowTemplateChange: Function
  integrationResult?: any
}

export default function FirstClientSubmitStep(props: Props) {
  const {
    onBack,
    submitting,
    reportPeriod,
    onReportPeriodChange,
    valid,
    handleCreate,
    onClose,
    workflowTemplate,
    onWorkflowTemplateChange,
    integrationResult,
  } = props

  const handleBack = () => {
    if (integrationResult) {
      onClose()
    } else {
      onBack()
    }
  }

  return (
    <>
      <ModalContent p="gigantic" height="775px">
        <ModalCloseButton onClose={onClose} />
        <Text mb="large">Step 3: Select workflow and date range</Text>
        {integrationResult?.status === 'success' ? (
          <Box>
            <Message kind="success" mb="xlarge">
              Your account was connected successfully!
            </Message>
          </Box>
        ) : null}
        <Box mb="xxxlarge">
          <SuspenseBoundary>
            <WorkflowTemplateSelectContainer
              workflowTemplate={workflowTemplate}
              onWorkflowTemplateChange={(option: any) => onWorkflowTemplateChange(option.value)}
              requirePeriod={false}
            />
          </SuspenseBoundary>
        </Box>
        <DatePeriodInput
          disabled={submitting}
          value={reportPeriod}
          onChange={onReportPeriodChange}
          customInput={<IconInput icon="calendar" styleProps={{ color: 'neutral.0' }} />}
        />
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px="gigantic"
        pb="gigantic"
      >
        <Button disabled={submitting} onPress={handleBack} kind="alt-neutral">
          {integrationResult ? 'Cancel' : 'Back'}
        </Button>
        <Button
          disabled={!valid || !workflowTemplate || submitting}
          kind="primary"
          onPress={handleCreate}
        >
          {submitting ? 'Saving...' : 'Submit'}
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
