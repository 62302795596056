import React from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import { FormattedDate } from 'react-intl'
import {
  Box,
  FlexColumn,
  Dropdown,
  LinkButton,
  Icon,
  Text,
  Table as Tables,
  TablePagination,
  FlexRow,
  Select,
} from 'elements'
import { useAuth, useNotifications } from 'hooks'
import useChangeOrgRole from './hooks/useChangeOrgRole'
import useRemoveOrgUser from './hooks/useRemoveOrgUser'

const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = Tables.Card

const roleOptions = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Member', value: 'MEMBER' },
]

export default function OrgUsersTable({ organization }) {
  const { user, userIsCurrentOrgAdmin } = useAuth()
  const [changeOrgRole] = useChangeOrgRole()
  const [removeOrgUser] = useRemoveOrgUser()
  const notifications = useNotifications()

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment OrgUsersTable_organization on Organization
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "OrgUsersTablePaginationQuery") {
        organizationUsers(after: $cursor, first: $count)
          @connection(key: "OrgUsersTable_organizationUsers") {
          edges {
            node {
              id
              role
              createdAt
              user {
                id
                name
              }
            }
          }
        }
      }
    `,
    organization,
  )

  const handleRemoveUser = (orgUser) => {
    const warning = `Are you sure you want to remove ${orgUser.user.name} from ${organization.name}?`

    if (window.confirm(warning)) {
      removeOrgUser({ id: orgUser.id })
      notifications.info(`${orgUser.user.name} has been removed.`)
    }
  }

  const handleChangeRole = async (orgUser, option) => {
    await changeOrgRole({
      id: orgUser.id,
      patch: { role: option.value },
    })

    notifications.info('Role changed.')
  }

  const orgUsers = data?.organizationUsers?.edges

  return (
    <FlexColumn>
      <Text fontSize="xlarge" fontWeight="bold" mb="large">
        Firm Members
      </Text>
      <Table spacing="large">
        <Head>
          <HeadRow>
            <HeadCell>Name</HeadCell>
            <HeadCell>Role</HeadCell>
            <HeadCell>Date Added</HeadCell>
            <HeadCell width="100px" />
          </HeadRow>
        </Head>
        <Body>
          {orgUsers.map(({ node: orgUser }) => {
            // this is to avoid a "cannot read property ~whatever~ of null" error when
            // removing user and refetching
            if (!orgUser) {
              return null
            }

            // userIsCurrentOrgAdmin refers to overall current user, while orgUser is the user in the row
            const isCurrentUser = orgUser.user.id === user.id
            const canEdit = userIsCurrentOrgAdmin && !isCurrentUser

            return (
              <Row key={orgUser.id}>
                <Cell>
                  <FlexRow alignItems="center">
                    <Icon name="user-circle" size="medium" mr="xlarge" color="foreground.0" />
                    <Text fontWeight="bold">{orgUser.user.name}</Text>
                    {isCurrentUser ? (
                      <Text ml="small" color="neutral.0" fontSize="small">
                        (Me)
                      </Text>
                    ) : null}
                  </FlexRow>
                </Cell>
                <Cell py="large">
                  <Box maxWidth="200px">
                    <Select
                      disabled={!canEdit}
                      options={roleOptions}
                      value={roleOptions.find((option) => option.value === orgUser?.role)}
                      onChange={(option) => {
                        handleChangeRole(orgUser, option)
                      }}
                    />
                  </Box>
                </Cell>
                <Cell>
                  <Text color="neutral.0">
                    <FormattedDate value={orgUser.createdAt} />
                  </Text>
                </Cell>
                {userIsCurrentOrgAdmin ? (
                  <Cell>
                    <Dropdown
                      position="bottom right"
                      options={[
                        {
                          label: 'Remove Member',
                          onSelect: () => handleRemoveUser(orgUser),
                          disabled: !canEdit,
                          buttonKind: 'danger',
                        },
                      ]}
                      renderButton={() => (
                        <LinkButton kind="transparent">
                          <Icon name="dots-horizontal" color="neutral.2" />
                        </LinkButton>
                      )}
                    />
                  </Cell>
                ) : (
                  <Cell />
                )}
              </Row>
            )
          })}
        </Body>
        <TablePagination Table={Tables.Card} colSpan={4} {...paginationProps} />
      </Table>
    </FlexColumn>
  )
}
