import React from 'react'
import ReactSwitch from 'react-switch'
import theme from 'theme'

type StyleProps = {
  onColor?: string
  offColor?: string
  checkedIcon?: boolean
  uncheckedIcon?: boolean
  height?: number
  width?: number
  handleDiameter?: number
}

type Props = {
  onChange: any
  checked: boolean
  styleProps?: StyleProps
}

export default function Switch(props: Props) {
  const { onChange, checked, styleProps } = props

  return (
    <ReactSwitch
      onChange={onChange}
      checked={checked}
      onColor={styleProps?.onColor || theme.colors.secondary[0]}
      offColor={styleProps?.offColor || theme.colors.neutral[2]}
      checkedIcon={styleProps?.checkedIcon || false}
      uncheckedIcon={styleProps?.uncheckedIcon || false}
      height={styleProps?.height || 20}
      handleDiameter={styleProps?.handleDiameter || 16}
      width={styleProps?.width || 36}
    />
  )
}
