import React from 'react'
import styled from 'styled-components'
import { compose, typography, color, background } from 'styled-system'

const StyledRow = styled.tr`
  ${compose(typography, color, background)}
`

function DataTableHeadRow({ accent, ...props }) {
  return <StyledRow bg={accent ? `accent.${accent}` : 'background.0'} {...props} />
}

DataTableHeadRow.defaultProps = {
  color: 'neutral.9',
}

export default DataTableHeadRow
