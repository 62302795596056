import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useApi, useAnalytics } from 'hooks'
import { createFormValidator, validators } from 'utils/validation'
import {
  Anchor,
  Button,
  RouterLinkButton,
  LinkButton,
  Box,
  TextInputField,
  Text,
  Message,
  IntuitSignIn,
} from 'elements'
import XeroSignInButton from './XeroSignInButton'

const validate = createFormValidator({
  email: validators.email.required('Please enter your email address'),
  phone: validators.phone.required('Please enter your phone number'),
  password: validators.password.required('Please enter your password'),
})

const passwordRules = [
  'Must be at least 8 characters',
  'Must contain a number',
  'Must contain an uppercase letter',
  'Must contain a lowercase letter',
  'Must contain a special character',
]

const renderPasswordRequirements = () => (
  <Box>
    {passwordRules.map((rule) => (
      <Text key={rule} fontSize="small" lineHeight="small">
        {rule}
      </Text>
    ))}
  </Box>
)

export default function Register() {
  const api = useApi()
  const history = useHistory()
  const analytics = useAnalytics()

  return (
    <>
      <Formik
        initialValues={{ email: '', phone: '', password: '' }}
        validate={validate}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const result = await api.auth.register(values)

            if (result.code === 200) {
              analytics.track('User Registered', { $email: values.email, provider: 'cognito' })

              history.push(`/auth/verify?email=${encodeURIComponent(values.email)}`)
            } else {
              let errorProps = { $email: values.email, provider: 'cognito' }

              if (result.error) {
                errorProps = { ...errorProps, ...result.error }
              }

              analytics.track('User Registered Error', errorProps)
            }
          } catch (error) {
            const { status, message } = error.response.data

            if (status === 'fail') {
              setFieldError('general', message)
            } else {
              setFieldError('general', 'An unknown error occurred.')
            }

            setSubmitting(false)
          }
        }}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            {errors.general ? (
              <Message kind="warning" mb="xlarge">
                {errors.general}
              </Message>
            ) : null}
            <Box mb="xxxlarge">
              <Box mb="xlarge">
                <TextInputField label="Email" name="email" placeholder="email@example.com" />
              </Box>
              <Box mb="xlarge">
                <TextInputField label="Phone" name="phone" placeholder="(123) 456-7890" />
              </Box>
              <Box>
                <TextInputField
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Enter password"
                  tooltip={renderPasswordRequirements}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb="large">
              <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
                {isSubmitting ? 'Registering...' : 'Register'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="medium">
        <Text fontSize="small" lineHeight="small">
          Signed up with QuickBooks?
        </Text>
        <LinkButton as="a" href={api.auth.getStrategyUrl('intuit-oauth2')} kind="transparent">
          <IntuitSignIn />
        </LinkButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="xxlarge">
        <Text fontSize="small" lineHeight="small">
          Signed up with Xero?
        </Text>
        <LinkButton as="a" href={api.auth.getStrategyUrl('xero-oauth2')} kind="transparent">
          <XeroSignInButton />
        </LinkButton>
      </Box>
      <Box mb="xxlarge">
        <Text textAlign="center" fontSize="small" lineHeight="small">
          By clicking Register you agree to the{' '}
          <Anchor href="https://www.scrutinize.io/privacy-policy" target="_blank">
            Privacy Policy
          </Anchor>{' '}
          and{' '}
          <Anchor href="https://www.scrutinize.io/terms-of-service" target="_blank">
            Terms of Service
          </Anchor>
          .
        </Text>
      </Box>
      <Box display="flex" justifyContent="center" mb="small">
        <RouterLinkButton area="small" to="/auth/login">
          Sign In
        </RouterLinkButton>
        <Text mx="xsmall">|</Text>
        <RouterLinkButton area="small" to="/auth/forgot-password">
          Forgot password?
        </RouterLinkButton>
      </Box>
    </>
  )
}
