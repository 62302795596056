/**
 * @generated SignedSource<<8e6da71bf5c07813623cc8babf3b6f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BulkDeactivateIntuitInput = {
  dateThreshold: string;
  entity: string;
  integrationId: string;
};
export type useBulkDeactivateMutation$variables = {
  input: BulkDeactivateIntuitInput;
};
export type useBulkDeactivateMutation$data = {
  readonly bulkDeactivateIntuit: {
    readonly integrationScriptResult: {
      readonly integration: {
        readonly " $fragmentSpreads": FragmentRefs<"DeactivateHistoryTable_integration">;
      } | null;
    };
  } | null;
};
export type useBulkDeactivateMutation = {
  variables: useBulkDeactivateMutation$variables;
  response: useBulkDeactivateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useBulkDeactivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkDeactivateIntuitPayload",
        "kind": "LinkedField",
        "name": "bulkDeactivateIntuit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IntegrationScriptResult",
            "kind": "LinkedField",
            "name": "integrationScriptResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Integration",
                "kind": "LinkedField",
                "name": "integration",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DeactivateHistoryTable_integration"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBulkDeactivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkDeactivateIntuitPayload",
        "kind": "LinkedField",
        "name": "bulkDeactivateIntuit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IntegrationScriptResult",
            "kind": "LinkedField",
            "name": "integrationScriptResult",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Integration",
                "kind": "LinkedField",
                "name": "integration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "IntegrationScriptResultsConnection",
                    "kind": "LinkedField",
                    "name": "integrationScriptResults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IntegrationScriptResultsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IntegrationScriptResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "platform",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dateThreshold",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reversed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataTable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserFile",
                                "kind": "LinkedField",
                                "name": "userFile",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rowId",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "integrationScriptResults(first:10,orderBy:\"CREATED_AT_DESC\")"
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "filters": [
                      "orderBy"
                    ],
                    "handle": "connection",
                    "key": "DeactivateHistoryTable_integrationScriptResults",
                    "kind": "LinkedHandle",
                    "name": "integrationScriptResults"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f674da3d500fbdd8648d2c456f06fa2",
    "id": null,
    "metadata": {},
    "name": "useBulkDeactivateMutation",
    "operationKind": "mutation",
    "text": "mutation useBulkDeactivateMutation(\n  $input: BulkDeactivateIntuitInput!\n) {\n  bulkDeactivateIntuit(input: $input) {\n    integrationScriptResult {\n      integration {\n        ...DeactivateHistoryTable_integration\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment DeactivateHistoryContextMenu_integrationScriptResult on IntegrationScriptResult {\n  id\n  kind\n  userFile {\n    rowId\n    id\n  }\n}\n\nfragment DeactivateHistoryTableRow_integrationScriptResult on IntegrationScriptResult {\n  createdAt\n  dateThreshold\n  kind\n  reversed\n  dataTable\n}\n\nfragment DeactivateHistoryTable_integration on Integration {\n  integrationScriptResults(first: 10, orderBy: CREATED_AT_DESC) {\n    edges {\n      node {\n        id\n        platform\n        ...DeactivateHistoryTableRow_integrationScriptResult\n        ...DeactivateHistoryContextMenu_integrationScriptResult\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "956901c0e3b9c0d362e78bec2c52dd05";

export default node;
