import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useIntegrationDeleteMutation($input: DeleteIntegrationInput!) {
    deleteIntegration(input: $input) {
      integration {
        id
        company {
          id
        }
      }
    }
  }
`

const updater = (store, { deleteIntegration }) => {
  const parentCompanyId = deleteIntegration.integration.company.id

  const parentCompany = store.get(parentCompanyId)

  const tableConnection = ConnectionHandler.getConnection(
    parentCompany,
    'Integrations_integrations',
    { count: 100 },
  )
  const reportModalConnection = ConnectionHandler.getConnection(
    parentCompany,
    'IntegrationChoices_integrations',
  )

  const navConnection = ConnectionHandler.getConnection(
    parentCompany,
    'CompanyNavigation_integrations',
  )

  const stripeConnection = ConnectionHandler.getConnection(
    parentCompany,
    'StripeIntegration_integrations',
  )

  const plaidConnection = ConnectionHandler.getConnection(
    parentCompany,
    'PlaidIntegrations_integrations',
  )

  const connections = [
    tableConnection,
    reportModalConnection,
    navConnection,
    stripeConnection,
    plaidConnection,
  ]

  connections.filter(Boolean).forEach((connection) => {
    ConnectionHandler.deleteNode(connection, deleteIntegration.integration.id)
  })
}

export default function useIntegrationDelete() {
  return useMutation(mutation, 'deleteIntegration', { updater })
}
