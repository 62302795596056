import { useDeleteMutation } from 'hooks'
import { graphql } from 'react-relay/hooks'

export default function useRemoveInvite() {
  return useDeleteMutation(
    graphql`
      mutation useRemoveInviteMutation($input: DeleteOrganizationInviteByIdInput!) {
        deleteOrganizationInviteById(input: $input) {
          deletedOrganizationInviteId
        }
      }
    `,
    'deletedOrganizationInviteId',
  )
}
