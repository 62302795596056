import React from 'react'
import { Form, useFormikContext } from 'formik'
import { FlexRow, FlexColumn, Button, Text, Checkbox } from 'elements'
import DatePeriodInput from 'components/ui/input/DatePeriodInput'
import IconInput from '../../../ui/input/IconInput'
import { PlaidAccount } from './types'

export type Values = {
  reportPeriod?: any
  accounts: PlaidAccount[]
}

export default function PlaidTriggerForm() {
  const formCtx = useFormikContext()
  const { isSubmitting, setFieldValue } = formCtx
  const values = formCtx.values as Values

  return (
    <Form>
      <FlexRow alignItems="center">
        <FlexColumn mr="xxlarge">
          <Text fontWeight="bold" fontSize="large" mb="large">
            Date
          </Text>
          <FlexColumn mb="large">
            <DatePeriodInput
              value={values.reportPeriod}
              onChange={(opt: any) => setFieldValue('reportPeriod', opt)}
              customInput={<IconInput icon="calendar" styleProps={{ color: 'neutral.0' }} />}
            />
          </FlexColumn>
          <Text fontWeight="bold" fontSize="large" mb="large">
            Accounts
          </Text>
          <FlexRow>
            {values.accounts.map((account, idx) => (
              <FlexRow
                mr="large"
                key={`${account.accountId}`}
                onClick={() => setFieldValue(`accounts[${idx}].checked`, !account.checked)}
              >
                <FlexColumn mr="xsmall">
                  <Checkbox checked={account.checked} />
                </FlexColumn>
                <Text>
                  {account.institutionName} {account.name} *{account.mask}
                </Text>
              </FlexRow>
            ))}
          </FlexRow>
        </FlexColumn>
        <Button disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Posting...' : 'Post'}
        </Button>
      </FlexRow>
    </Form>
  )
}
