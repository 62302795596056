import React, { useState } from 'react'
import { Modal, Button, ModalNavHeader } from 'elements'
import DatePeriodInput from 'components/ui/input/DatePeriodInput'
import datePeriods from 'shared/datasets/date-periods'
import IconInput from '../../ui/input/IconInput'
import ModalContent from '../../app/ModalContent'
import { ReportPeriod } from '../../../shared/types'

const DEFAULT_REPORT_PERIOD = datePeriods().find((p) => p.value === 'last-year')

type Props = {
  modal: any
  onPeriodCreate: (period: ReportPeriod) => void
}

export default function CreateReportPeriodModal(props: Props) {
  const { modal, onPeriodCreate } = props
  const [reportPeriod, setReportPeriod] = useState(DEFAULT_REPORT_PERIOD)

  const handlePeriodCreate = () => {
    if (reportPeriod) {
      const newPeriod = {
        periodStart: reportPeriod.interval.start.toISODate(),
        periodEnd: reportPeriod.interval.end.toISODate(),
      }

      onPeriodCreate(newPeriod)
    }

    modal.onClose()
  }

  return (
    <Modal modal={modal}>
      <ModalNavHeader onClose={modal.onClose} titleText="Select Period" />
      <ModalContent>
        <DatePeriodInput
          value={reportPeriod}
          onChange={setReportPeriod}
          customInput={<IconInput icon="calendar" styleProps={{ color: 'neutral.0' }} />}
        />
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        px="large"
      >
        <Button mr="medium" kind="primary" onPress={handlePeriodCreate}>
          Create Period
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </Modal>
  )
}
