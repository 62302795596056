import React from 'react'
import { FlexRow, Box } from 'elements'
import { DataSection } from '../../types'
import InsightCard from './InsightCard'

type Props = {
  dataSections: DataSection[]
  reportSectionLabel?: string
}

export default function InsightSections(props: Props) {
  const { dataSections, reportSectionLabel } = props

  return (
    <Box display="grid" gridRowGap="large" gridColumnGap="2%" gridTemplateColumns="49% 49%">
      {dataSections.map((dataSection, idx) => {
        const {
          // default to full width (col 1 to col 3)
          gridColumn = '1/3',
          gridRow = `${idx + 1}`,
          key,
        } = dataSection

        const isLast = idx === dataSections.length - 1

        return (
          <FlexRow
            gridColumn={gridColumn}
            gridRow={gridRow}
            key={`${key}${idx}`}
            mb={isLast && 'gigantic'}
          >
            <InsightCard dataSection={dataSection} reportSectionLabel={reportSectionLabel} />
          </FlexRow>
        )
      })}
    </Box>
  )
}
