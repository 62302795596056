/**
 * @generated SignedSource<<6753e52ea279c5a825256f7eb85aa0fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedWorkflowTable_company$data = {
  readonly completedReports: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"WorkflowTableRow_report">;
      };
    }>;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "CompletedWorkflowTable_company";
};
export type CompletedWorkflowTable_company$key = {
  readonly " $data"?: CompletedWorkflowTable_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedWorkflowTable_company">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "completedReports"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "includeDeleted"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CompletedWorkflowsPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "CompletedWorkflowTable_company",
  "selections": [
    {
      "alias": "completedReports",
      "args": [
        {
          "kind": "Variable",
          "name": "includeDeleted",
          "variableName": "includeDeleted"
        },
        {
          "kind": "Literal",
          "name": "input",
          "value": {
            "status": "completed"
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "ReportsConnection",
      "kind": "LinkedField",
      "name": "__CompletedWorkflowsTable_completedReports_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Report",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WorkflowTableRow_report"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "765aa34241d4edefc018af6af47b6f0c";

export default node;
