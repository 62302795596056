import React from 'react'
import FlexRow from '../../FlexRow'
import Text from '../../Text'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function NumericCell(props) {
  const { value } = props.cell
  const { align } = props.column
  const { sectionHeader } = props.row.rowProps

  const displayVal = Number.isFinite(value) && value.toLocaleString()

  if (sectionHeader && !displayVal) {
    return null
  }

  return (
    <FlexRow flex="1" justifyContent={align && alignments[align]}>
      <Text fontSize="inherit" color="inherit">
        {displayVal || '--'}
      </Text>
    </FlexRow>
  )
}
