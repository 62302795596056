/**
 * @generated SignedSource<<b9a8ce1af261611cf92f3cef51ce10a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowTemplateSelect_organization$data = {
  readonly workflowTemplatesWithDefaults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly rowId: any;
        readonly name: string | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "WorkflowTemplateSelect_organization";
};
export type WorkflowTemplateSelect_organization$key = {
  readonly " $data"?: WorkflowTemplateSelect_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowTemplateSelect_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "workflowTemplatesWithDefaults"
        ]
      }
    ]
  },
  "name": "WorkflowTemplateSelect_organization",
  "selections": [
    {
      "alias": "workflowTemplatesWithDefaults",
      "args": [
        {
          "kind": "Literal",
          "name": "includeDeleted",
          "value": "NO"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "WorkflowTemplatesConnection",
      "kind": "LinkedField",
      "name": "__WorkflowTemplateSelect_workflowTemplatesWithDefaults_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkflowTemplatesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorkflowTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__WorkflowTemplateSelect_workflowTemplatesWithDefaults_connection(includeDeleted:\"NO\",orderBy:\"CREATED_AT_DESC\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "49e40afb33495b1df1ac823d8fa30e47";

export default node;
