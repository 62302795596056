/**
 * @generated SignedSource<<358689a5815955b2aa5dddd2270c4e2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripeTrigger_company$data = {
  readonly integrations: {
    readonly nodes: ReadonlyArray<{
      readonly kind: string;
      readonly rowId: any;
    }>;
  };
  readonly companyUserFiles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly userFile: {
          readonly kind: string;
          readonly rowId: any;
        } | null;
      };
    }>;
  };
  readonly " $fragmentType": "StripeTrigger_company";
};
export type StripeTrigger_company$key = {
  readonly " $data"?: StripeTrigger_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"StripeTrigger_company">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rowId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StripeTrigger_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationsConnection",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Integration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "CompanyUserFilesConnection",
      "kind": "LinkedField",
      "name": "companyUserFiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyUserFilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyUserFile",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserFile",
                  "kind": "LinkedField",
                  "name": "userFile",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "4d9d391cd7c8b8a047f90cb26104a1b9";

export default node;
