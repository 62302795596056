import React from 'react'
import ReportPage from './ReportPage'
import { SuspenseBoundary } from 'elements'

export default function ReportPageContainer() {
  return (
    <SuspenseBoundary>
      <ReportPage />
    </SuspenseBoundary>
  )
}
