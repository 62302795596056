import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { capitalize } from 'lodash'
import { FlexRow, Table as Tables, Text } from 'elements'
import DeactivateHistoryContextMenu from './DeactivateHistoryContextMenu'

const { Row, Cell } = Tables.Card

type Props = {
  scriptResult: any
}

export default function DeactivateHistoryTableRow(props: Props) {
  const { scriptResult } = props

  const data = useFragment(
    graphql`
      fragment DeactivateHistoryTableRow_integrationScriptResult on IntegrationScriptResult {
        createdAt
        dateThreshold
        kind
        reversed
        dataTable
      }
    `,
    scriptResult,
  )

  const { dateThreshold, dataTable, reversed, kind, createdAt } = data

  return (
    <Row>
      <Cell>
        <Text fontSize="large" lineHeight="large">
          {dateThreshold}
        </Text>
      </Cell>
      <Cell>
        <Text fontSize="large" lineHeight="large">
          {`${capitalize(dataTable.replace('app_public.integration_', ''))}`}
        </Text>
      </Cell>
      <Cell>
        <Text fontSize="large" lineHeight="large">
          {capitalize(kind)}
        </Text>
      </Cell>
      <Cell>
        <Text fontSize="large" lineHeight="large">
          {reversed?.toString() || 'false'}
        </Text>
      </Cell>
      <Cell>
        <Text>{createdAt}</Text>
      </Cell>
      <Cell>
        <FlexRow justifyContent="center">
          <DeactivateHistoryContextMenu scriptResult={scriptResult} />
        </FlexRow>
      </Cell>
    </Row>
  )
}
