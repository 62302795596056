import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRelayEnvironment } from 'react-relay/hooks'
import { useAuth } from 'hooks'
import fetchOrgBySlug from 'queries/fetchOrgBySlug'
import PageBodyLoading from 'components/app/PageBodyLoading'
import NotFound from './NotFound'

export default function RequireOrg({ children }) {
  const environment = useRelayEnvironment()
  const { orgSlug, companyId } = useParams()
  const { isReady, isLoggedIn, currentOrg, setCurrentOrg, setCurrentCompany } = useAuth()
  const [invalidOrg, setInvalidOrg] = useState(false)

  useEffect(() => {
    const updateOrg = async () => {
      const org = await fetchOrgBySlug(environment, orgSlug)

      if (!org) {
        setInvalidOrg(true)
        return
      }

      setInvalidOrg(false)
      setCurrentOrg(org)
    }

    if (isReady && isLoggedIn && orgSlug) {
      updateOrg()
    }
  }, [setCurrentOrg, environment, orgSlug, isReady, isLoggedIn])

  // set current company to null if the companyId is not in the url – there shouldn't be a current company
  // when you're at the organization level
  useEffect(() => {
    if (!companyId) {
      setCurrentCompany(null)
    }
  }, [companyId, setCurrentCompany])

  if (invalidOrg) {
    return <NotFound errorMessage="We couldn't find that firm." />
  }

  if (!currentOrg) {
    return <PageBodyLoading />
  }

  return children
}
