import React, { useState, useEffect, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useAnalytics, useAuth } from 'hooks'
import { Modal } from 'elements'
import CreateClientStep from './CreateClientStep'
import FirstClientUploadReport from '../../report/create/FirstClientUploadReport'
import FirstClientSubmitStep from '../../report/create/FirstClientSubmitStep'
import datePeriods from 'shared/datasets/date-periods'
import useReportCreate from '../../report/hooks/useReportCreate'
import useRunReportWithIntegration from '../../report/hooks/useRunReportWithIntegration'
import FirstClientXeroConnectionChoices from '../../integrations/FirstClientXeroConnectionChoices'
import SelectDataSources from '../../integrations/SelectDataSources'

const DEFAULT_REPORT_PERIOD = datePeriods().find((p) => p.value === 'last-year')

const MODAL_WIDTH = '755px'

type Props = {
  modal: any
  integrationResult?: any
  firstClient?: boolean
  companyId?: string
}

export default function FirstClientModal(props: Props) {
  const { modal, integrationResult, firstClient } = props

  const [step, setStep] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [workflowTemplate, setWorkflowTemplate] = useState<any>(null)
  const [reportPeriod, setReportPeriod] = useState<any>(DEFAULT_REPORT_PERIOD)
  const [validReportPeriod, setValidReportPeriod] = useState(false)
  const [noCurrentTenant, setNoCurrentTenant] = useState(false)
  const [choice, setChoice] = useState<any>(null)
  const [companyId, setCompanyId] = useState(props.companyId || '')

  const [createReport]: any = useReportCreate()
  const [runReportWithIntegration]: any = useRunReportWithIntegration()
  const analytics = useAnalytics()
  const history = useHistory()
  const { currentCompany, currentOrg } = useAuth()

  useEffect(() => {
    setValidReportPeriod(reportPeriod?.interval)
  }, [reportPeriod])

  const data = useLazyLoadQuery<any>(
    graphql`
      query FirstClientModalQuery($companyId: ID!, $orgSlug: String!) {
        companyById(id: $companyId) {
          id
          organization {
            slug
          }
          ...CreateClientStep_company
          ...SelectDataSources_company
          integrations(first: 100) {
            nodes {
              id
              kind
              config
              ...FirstClientXeroConnectionChoices_integration
            }
          }
        }
        organizationBySlug(slug: $orgSlug) {
          ...BillingWarning_organization
        }
      }
    `,
    { companyId, orgSlug: currentOrg?.slug || '' },
  )

  const integration = data?.companyById?.integrations?.nodes?.find(
    (integration: any) => integration.kind === integrationResult?.integrationKind,
  )

  // set no current tenant for xero integrations
  useEffect(() => {
    // integration from the query is defined after connecting your integration
    if (!integration) {
      return
    }

    if (integration.kind === 'xero' && !integration.config.xero.currentTenant) {
      setNoCurrentTenant(true)
    }
  }, [integration])

  useEffect(() => {
    if (data?.companyById && !currentCompany) {
      history.push(`/orgs/${currentOrg?.slug}/companies/${data.companyById.id}`)
    }
  }, [data, currentCompany, currentOrg, history])

  if (!data) {
    return null
  }

  const handleNext = () => setStep(step + 1)
  const handleBack = () => setStep(step - 1)

  const handleCreateIntegrationReport = async () => {
    setSubmitting(true)

    const { report } = await createReport(
      {
        companyId: data.companyById.id,
        kind: 'anomaly:1',
        periodStart: reportPeriod.interval.start.toISODate(),
        periodEnd: reportPeriod.interval.end.toISODate(),
        workflowTemplateId: workflowTemplate.id,
      },
      { includeDeleted: 'NO' },
    )

    // call the runReportWithIntegration lambda, but don't wait for it since
    // we'll get updates via report subscription
    runReportWithIntegration({
      reportId: report.id,
      integrationId: integration.id,
    })

    analytics.track('Report Created', { reportId: report.rowId })
    analytics.track('First Client Flow Completed', {
      companyId: data.companyById.id,
      reportId: report.rowId,
    })

    modal.onClose()
    history.push(`/orgs/${currentOrg!.slug}/companies/${currentCompany!.id}/reports/${report.id}`)
  }

  let content: ReactElement | null = null

  if (step === 0 && !integrationResult) {
    content = (
      <CreateClientStep
        company={data.companyById}
        organization={data.organizationBySlug}
        onNext={handleNext}
        onClose={modal.onClose}
        firstClient={firstClient}
        onCompanyIdChange={setCompanyId}
      />
    )
  } else if (step === 1 || integrationResult?.status === 'error') {
    content = (
      <SelectDataSources
        onClose={modal.onClose}
        integrationResult={integrationResult}
        onBack={handleBack}
        onNext={handleNext}
        company={data.companyById}
        onKindSelect={setChoice}
      />
    )
  } else if (choice === 'upload') {
    content = (
      <FirstClientUploadReport
        step={step}
        submitting={submitting}
        setSubmitting={setSubmitting}
        onNext={handleNext}
        onBack={handleBack}
        onClose={modal.onClose}
        reportPeriod={reportPeriod}
        onReportPeriodChange={setReportPeriod}
        validReportPeriod={validReportPeriod}
        workflowTemplate={workflowTemplate}
        onWorkflowTemplateChange={setWorkflowTemplate}
      />
    )
  } else if (integration && noCurrentTenant) {
    content = (
      <FirstClientXeroConnectionChoices
        integration={integration}
        onDone={() => {
          setNoCurrentTenant(false)
        }}
        onBack={handleBack}
        onClose={modal.onClose}
      />
    )
  } else {
    content = (
      <FirstClientSubmitStep
        onBack={handleBack}
        onClose={modal.onClose}
        submitting={submitting}
        reportPeriod={reportPeriod}
        onReportPeriodChange={setReportPeriod}
        valid={validReportPeriod}
        handleCreate={handleCreateIntegrationReport}
        workflowTemplate={workflowTemplate}
        onWorkflowTemplateChange={setWorkflowTemplate}
        integrationResult={integrationResult}
      />
    )
  }

  return (
    <Modal modal={modal} width={MODAL_WIDTH}>
      {content}
    </Modal>
  )
}
