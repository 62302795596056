/**
 * @generated SignedSource<<1730e6e86045cdcac3dac831743bf686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportContent_report$data = {
  readonly rowId: any;
  readonly name: string;
  readonly " $fragmentType": "ReportContent_report";
};
export type ReportContent_report$key = {
  readonly " $data"?: ReportContent_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportContent_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportContent_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "16365ee4120aa9f4bc1cddb59e48d6fc";

export default node;
