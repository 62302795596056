/**
 * @generated SignedSource<<5366f3ab44cd554f29f566ae23a8784e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplyReportResolutionsInput = {
  reportId: string;
};
export type useReportResolutionsApplyMutation$variables = {
  input: ApplyReportResolutionsInput;
  resolutionSection?: string | null;
  anomalies?: ReadonlyArray<string | null> | null;
};
export type useReportResolutionsApplyMutation$data = {
  readonly applyReportResolutions: {
    readonly report: {
      readonly resolutionProgress: number | null;
      readonly " $fragmentSpreads": FragmentRefs<"ReportResolution_report" | "ReportResolutionTable_report" | "WorkspaceHeader_report">;
    };
  } | null;
};
export type useReportResolutionsApplyMutation = {
  variables: useReportResolutionsApplyMutation$variables;
  response: useReportResolutionsApplyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anomalies"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resolutionSection"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resolutionProgress",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "anomalies",
        "variableName": "anomalies"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "resolutionSection"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useReportResolutionsApplyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ApplyReportResolutionsPayload",
        "kind": "LinkedField",
        "name": "applyReportResolutions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ReportResolution_report"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ReportResolutionTable_report"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkspaceHeader_report"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useReportResolutionsApplyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ApplyReportResolutionsPayload",
        "kind": "LinkedField",
        "name": "applyReportResolutions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reviewStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AnomalyCount",
                "kind": "LinkedField",
                "name": "anomalyCounts",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ReportResolutionsConnection",
                "kind": "LinkedField",
                "name": "filteredReportResolutions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportResolutionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportResolution",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "anomalyTypes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currentValue",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updateStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "responseData",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "resolutionKind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "input"
                ],
                "handle": "connection",
                "key": "ReportResolutionTable_filteredReportResolutions",
                "kind": "LinkedHandle",
                "name": "filteredReportResolutions"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "WORKSPACE_ORDER_ASC"
                  }
                ],
                "concreteType": "ReportWorkflowWorkspacesConnection",
                "kind": "LinkedField",
                "name": "reportWorkflowWorkspaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportWorkflowWorkspace",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "STEP_ORDER_ASC"
                          }
                        ],
                        "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                        "kind": "LinkedField",
                        "name": "reportWorkflowWorkspaceSteps",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportWorkflowWorkspaceStep",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sections",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                      },
                      (v5/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "config",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFilesConnection",
                "kind": "LinkedField",
                "name": "reportUserFiles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportUserFile",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserFile",
                        "kind": "LinkedField",
                        "name": "userFile",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "pendingResolutions",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "condition",
                    "value": {
                      "updateStatus": "pending"
                    }
                  }
                ],
                "concreteType": "ReportResolutionsConnection",
                "kind": "LinkedField",
                "name": "reportResolutions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "reportResolutions(condition:{\"updateStatus\":\"pending\"})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "264d3983fec2c7d79a14d14f2594d39a",
    "id": null,
    "metadata": {},
    "name": "useReportResolutionsApplyMutation",
    "operationKind": "mutation",
    "text": "mutation useReportResolutionsApplyMutation(\n  $input: ApplyReportResolutionsInput!\n  $resolutionSection: String\n  $anomalies: [String]\n) {\n  applyReportResolutions(input: $input) {\n    report {\n      resolutionProgress\n      ...ReportResolution_report\n      ...ReportResolutionTable_report\n      ...WorkspaceHeader_report\n      id\n    }\n  }\n}\n\nfragment ReportResolutionTable_report on Report {\n  filteredReportResolutions(input: {anomalies: $anomalies, status: $resolutionSection}, first: 100) {\n    edges {\n      node {\n        id\n        anomalyTypes\n        currentValue\n        status\n        updateStatus\n        responseData\n        resolutionKind\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  resolutionProgress\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          status\n        }\n      }\n      id\n    }\n  }\n  id\n}\n\nfragment ReportResolution_report on Report {\n  id\n  reviewStatus\n  anomalyCounts {\n    type\n    count\n  }\n}\n\nfragment WorkspaceHeader_report on Report {\n  id\n  name\n  config\n  reviewStatus\n  status\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      id\n      name\n      key\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          status\n          sections\n        }\n      }\n    }\n  }\n  reportUserFiles {\n    nodes {\n      type\n      userFile {\n        id\n        rowId\n        kind\n        name\n        key\n      }\n      id\n    }\n  }\n  pendingResolutions: reportResolutions(condition: {updateStatus: \"pending\"}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8d078a3a5548b9943525541fa1bc04c";

export default node;
