import { useEffect } from 'react'
import CreateReportModalContainer from 'components/report/create/CreateReportModalContainer'
import FirstClientModalContainer from 'components/company/create/FirstClientModalContainer'
import IntegrationModalContainer from 'components/integrations/IntegrationModalContainer'
import useQueryParams from './useQueryParams'
import useModals from './useModals'
import { ModalBehavior } from '../../contexts/ModalsContext'

export default function useModalRoutes() {
  const { openModal } = useModals()
  const { params, removeParams } = useQueryParams()

  useEffect(() => {
    const { modal, ...query } = params
    const modalBehavior = query.modalBehavior as ModalBehavior

    const integrationResult = {
      status: query.integrationError ? 'error' : 'success',
      integrationId: query.integrationId,
      integrationKind: query.integrationKind,
      integrationError: query.integrationError,
    }

    if (modal) {
      switch (modal) {
        case 'create-report':
          openModal!(
            CreateReportModalContainer,
            {
              integrationResult,
              orgSlug: query.orgSlug,
              companyId: query.companyId,
              reportPeriod: { periodStart: query.periodStart, periodEnd: query.periodEnd },
              workflowTemplateId: query.workflowTemplateId,
            },
            modalBehavior,
          )
          removeParams([
            'modal',
            'modalBehavior',
            'integrationId',
            'integrationKind',
            'integrationError',
            'orgSlug',
            'companyId',
            'periodStart',
            'periodEnd',
            'workflowTemplateId',
          ])
          break

        case 'first-client':
          openModal!(
            FirstClientModalContainer,
            { integrationResult, companyId: query.companyId, orgSlug: query.orgSlug },
            modalBehavior,
          )

          removeParams([
            'modal',
            'modalBehavior',
            'integrationId',
            'integrationKind',
            'integrationError',
            'orgSlug',
            'companyId',
          ])
          break

        case 'integration-modal':
          openModal!(IntegrationModalContainer, { companyId: query.companyId }, modalBehavior)

          removeParams(['modal', 'modalBehavior', 'companyId'])

          break

        default:
          console.warn(`Unknown modal [${params.modal}]`)
          break
      }
    }
  }, [openModal, params, removeParams])
}
