import React, { useState } from 'react'
import intuitSignIn from 'theme/images/intuit-signIn.svg'
import intuitSignInHover from 'theme/images/intuit-signIn-hover.svg'
import Image from '../Image'

export default function IntuitSignIn() {
  const [hover, setHover] = useState(false)

  return (
    <Image
      id="signin"
      src={hover ? intuitSignInHover : intuitSignIn}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    />
  )
}
