import { compact, sortBy, flatten } from 'lodash'
import { applyConfigReportSections } from '../../utils/templates'
import { flattenReportSections } from '../../utils/sections'
import generateReportSections from '../../view/insights/sections/generateReportSections'
import { ConfigSection, AnomalyReport } from '../../../../shared/types'
import { SheetData } from '../../types'
import { dashboardPages } from '../../constants'

const excludedSectionKinds = ['report', 'transactionByType']

export default function formatReportForExport(
  output: AnomalyReport,
  reportId: string,
  configSections?: ConfigSection[],
): SheetData[] {
  const { reportMetadata } = output

  const reportSections = generateReportSections({
    report: output,
    configSections,
    reportId,
  })

  // exclude transaction by type and financial report tables
  const exportSections = reportSections.filter(
    (section) => !excludedSectionKinds.includes(section.kind),
  )

  const configuredExportSections = applyConfigReportSections({
    configSections,
    reportSections: exportSections,
    reportEndDate: reportMetadata.reportPeriod.endDate,
  })

  // flatten list so subsections are included
  const flatSections = flattenReportSections(configuredExportSections)

  // remove dashboard pages
  const filtered = flatSections.filter((section) => !dashboardPages.includes(section.label))

  // sort anomalies last
  const sortedSections = sortBy(filtered, (section) => section.kind !== 'insight')

  // filter out sections with explicitly no export data (empty array)
  // and map to export data
  const exportData = sortedSections.map((section) => {
    const { exportData, dataSections, label } = section

    if (exportData && !exportData.length) {
      return null
    }

    if (!exportData) {
      return { sheetName: label, dataSections }
    }

    return exportData
  })

  return flatten(compact(exportData))
}
