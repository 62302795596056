import React from 'react'
import { FlexRow } from 'elements'
import { paymentTransactionTypes } from './constants'
import { Transaction } from '../types'
import TransactionEntity from './transactionValues/TransactionEntity'
import TransactionDate from './transactionValues/TransactionDate'
import TransactionNum from './transactionValues/TransactionNum'
import TransactionAccount from './transactionValues/TransactionAccount'
import { BoxProps } from '../../../types/style'

type Props = {
  transactionValues: Transaction
  onChange?: Function
  canEdit: boolean
  // customers, vendors, and accounts must be defined if canEdit is true
  customers?: any[]
  vendors?: any[]
  accounts?: any[]
  styleProps: BoxProps
}

const includeAccountTypes = [
  'deposit',
  'salesReceipt',
  'refundReceipt',
  'expense',
  'check',
  'creditCardCredit',
  ...paymentTransactionTypes,
]

const excludeEntityTypes = ['deposit', 'transfer', 'journalEntry']

const excludeNumTypes = ['deposit', 'transfer', 'journalEntry']

// uneditable until we find a workaround for the quickbooks bug where billing addresses
// are not correctly updated.
const uneditableEntityTypes = ['bill', 'vendorCredit']

export default function TransactionInfo(props: Props) {
  const { transactionValues, onChange, customers, vendors, canEdit, accounts, styleProps } = props
  const { transactionType, transactionDate, transactionNum, transactionLines } = transactionValues

  const includeAccount = includeAccountTypes.includes(transactionType)
  const includeNum = !excludeNumTypes.includes(transactionType)
  const includeEntity = !excludeEntityTypes.includes(transactionType)

  // only update values that are present in the transaction
  const handleUpdateFields = (option: any, updateFields: Record<string, any>) => {
    Object.entries(updateFields)
      .filter(([key]) => Object.keys(transactionValues).includes(key))
      .forEach(([key, valueProp]) => {
        if (onChange) {
          onChange(key, option.value[valueProp])
        }
      })
  }

  const hasLinkedTransactions = transactionLines.some((line) => line.lineLinkedTransactionId)

  const canEditEntity =
    canEdit && !hasLinkedTransactions && !uneditableEntityTypes.includes(transactionType)

  return (
    <FlexRow alignItems="center" mb={styleProps?.mb}>
      {includeEntity && (
        <TransactionEntity
          canEdit={canEditEntity}
          customers={customers}
          vendors={vendors}
          transactionValues={transactionValues}
          onChange={handleUpdateFields}
          styleProps={{ boxProps: { mr: 'xxlarge' } }}
        />
      )}
      <TransactionDate
        transactionDate={transactionDate}
        onChange={onChange}
        canEdit={canEdit}
        styleProps={{ boxProps: { mr: 'xxlarge' } }}
      />
      {includeNum && (
        <TransactionNum
          transactionNum={transactionNum}
          canEdit={canEdit}
          styleProps={{ boxProps: { mr: 'xxlarge' } }}
        />
      )}
      {includeAccount && (
        <TransactionAccount
          transactionValues={transactionValues}
          canEdit={canEdit}
          accounts={accounts}
          onChange={handleUpdateFields}
        />
      )}
    </FlexRow>
  )
}
