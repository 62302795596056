import { fetchQuery, graphql } from 'react-relay/hooks'

type Props = {
  environment: any
  orgSlug: string
  templateName: string
}

export default async function fetchTemplateByName(props: Props) {
  const { environment, orgSlug, templateName } = props

  const data = await fetchQuery<any>(
    environment,
    graphql`
      query fetchTemplateByNameQuery($orgSlug: String!, $templateName: String) {
        organizationBySlug(slug: $orgSlug) {
          workflowTemplatesWithDefaults(condition: { name: $templateName }) {
            nodes {
              id
            }
          }
        }
      }
    `,
    { orgSlug, templateName },
  ).toPromise()

  return data.organizationBySlug?.workflowTemplatesWithDefaults?.nodes?.[0]
}
