import React from 'react'
import Box from '../Box'
import FlexRow from '../FlexRow'
import CardTableSortButton from './CardTableSortButton'
import { alignToFlex } from './utils'
import SimpleTableHeadCell from './SimpleTableHeadCell'
import { Sort } from '../../types/table'

type Props = {
  children?: any
  align?: string
  enableSortBy?: boolean
  sort?: Sort
  onSort?: (sort: Sort) => any
  centerSortButton?: boolean
}

function CardTableHeadCell(props: Props & any) {
  const { children, align, enableSortBy, sort, centerSortButton, onSort } = props

  return (
    <SimpleTableHeadCell {...props} textAlign={align || 'left'}>
      {centerSortButton ? (
        <FlexRow justifyContent="center" alignItems="center">
          <CardTableSortButton sort={sort} onSort={onSort} />
        </FlexRow>
      ) : (
        <FlexRow justifyContent={alignToFlex(align)} alignItems="center">
          <Box mr="medium">{children}</Box>
          {enableSortBy && <CardTableSortButton sort={sort} onSort={onSort} />}
        </FlexRow>
      )}
    </SimpleTableHeadCell>
  )
}

export default CardTableHeadCell
