import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexRow, Icon, Text } from 'elements'
import { getBillingPlanTier, getNextBillingPlanTier, getTierInfo } from '../../../utils/billing'

type Props = {
  organization: any
}

export default function BillingWarning(props: Props) {
  const { organization } = props

  const data = useFragment(
    graphql`
      fragment BillingWarning_organization on Organization {
        activeCompanies: companies(includeDeleted: NO) {
          totalCount
        }
        organizationSubscriptions(includeDeleted: YES, orderBy: UPDATED_AT_DESC) {
          nodes {
            billingPlan {
              rowId
              slug
              name
              price
              type
              interval
              stripePriceId
              config
            }
          }
        }
      }
    `,
    organization,
  )

  const billingPlan = data?.organizationSubscriptions?.nodes?.[0]?.billingPlan
  const activeCompanyCount = data.activeCompanies.totalCount

  const billingPlanTier = getBillingPlanTier(billingPlan, activeCompanyCount)
  const nextTier = getNextBillingPlanTier(billingPlan, billingPlanTier?.maxCompanyCount)
  const tierInfo = getTierInfo(nextTier)

  if (billingPlanTier && activeCompanyCount === billingPlanTier.maxCompanyCount) {
    return (
      <FlexRow mb="xxxlarge" color="neutral.9" alignItems="center">
        <Icon name="exclamation-circle" size="large" mr="medium" />
        <Text color="inherit" fontWeight="bold">
          Adding this client will upgrade you to the {tierInfo!.name} tier, and your new monthly
          rate of {tierInfo!.price} will take effect on your next billing cycle.
        </Text>
      </FlexRow>
    )
  }

  return null
}
