import React from 'react'
import { useFormikContext } from 'formik'
import FlexRow from '../../FlexRow'
import TextInputField from '../../TextInputField'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function InputCell(props) {
  const { column, cell, row } = props
  const { align, formProps, Header } = column
  const { fieldPlaceholder, handleUpdate, inputType, fieldName, getFieldName } = formProps

  const formCtx = useFormikContext()
  const { handleBlur } = formCtx
  const placeholder = fieldPlaceholder || Header

  const name = getFieldName ? getFieldName(row, formCtx) : fieldName

  return (
    <FlexRow justifyContent={align ? alignments[align] : 'flex-start'}>
      <TextInputField
        area="small"
        width="100%"
        name={name}
        type={inputType}
        value={cell.value ?? ''}
        placeholder={placeholder}
        onBlur={(e) => {
          handleUpdate(e.target.value, props, formCtx)
          // formik built-in blur handler
          handleBlur(e)
        }}
        color="foreground.0"
        fontSize="medium"
        // exclude long error with nested field names
        includeError={false}
        textAlign={align}
      />
    </FlexRow>
  )
}
