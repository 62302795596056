import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRelayEnvironment } from 'react-relay/hooks'
import { useAuth } from 'hooks'
import fetchCurrentCompany from 'queries/fetchCurrentCompany'
import PageBodyLoading from 'components/app/PageBodyLoading'
import { NotFoundPage } from 'pages'

export default function RequireCompany({ children }) {
  const environment = useRelayEnvironment()
  const { companyId } = useParams()
  const { isReady, isLoggedIn, currentCompany, setCurrentCompany } = useAuth()
  const [invalidCompany, setInvalidCompany] = useState(false)

  useEffect(() => {
    const updateCompany = async () => {
      const company = await fetchCurrentCompany(environment, companyId)

      if (!company) {
        setInvalidCompany(true)
        return
      }

      setInvalidCompany(false)

      setCurrentCompany(company)
    }

    if (isReady && isLoggedIn && companyId) {
      updateCompany()
    }
  }, [setCurrentCompany, environment, companyId, isReady, isLoggedIn])

  if (invalidCompany) {
    return <NotFoundPage errorMessage="We couldn't find that client." />
  }

  if (!currentCompany) {
    return <PageBodyLoading />
  }

  return children
}
