/**
 * @generated SignedSource<<b52968ba551d61b07dd316629688f2af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeactivateHistoryContextMenu_integrationScriptResult$data = {
  readonly id: string;
  readonly kind: string;
  readonly userFile: {
    readonly rowId: any;
  } | null;
  readonly " $fragmentType": "DeactivateHistoryContextMenu_integrationScriptResult";
};
export type DeactivateHistoryContextMenu_integrationScriptResult$key = {
  readonly " $data"?: DeactivateHistoryContextMenu_integrationScriptResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeactivateHistoryContextMenu_integrationScriptResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeactivateHistoryContextMenu_integrationScriptResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFile",
      "kind": "LinkedField",
      "name": "userFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IntegrationScriptResult",
  "abstractKey": null
};

(node as any).hash = "34da172f59f6a634d8ceb327f7dc35f6";

export default node;
