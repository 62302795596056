import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import Anchor from '../Anchor'
import Icon from '../Icon'
import Text from '../Text'
import FlexRow from '../FlexRow'

// formats an array of file extensions for use in sentences
// ex: ['csv', 'xlsx', 'json'] => "CSV, XLSX, or JSON"
const formatTypesList = (types) => {
  if (!types || !types.length) {
    return ''
  }

  const upped = types.map((t) => t.toUpperCase())

  if (upped.length <= 2) {
    return upped.join(' or ')
  }

  const last = upped.slice(-1)
  const rest = upped.slice(0, -1)

  // oxford comma because we're civilized
  return `${rest.join(', ')}, or ${last}`
}

// eslint-disable-next-line react/prop-types
const renderStatus = ({ onBrowse, status, allowedTypes, fileNames, uploadProgress }) => {
  switch (status) {
    case 'init':
      return (
        <React.Fragment>
          <Anchor color="secondary.0" onClick={onBrowse}>
            <FlexRow>
              <Icon name="cloud-upload" size="medium" mr="xlarge" />
              <Text color="inherit">
                Drop {formatTypesList(allowedTypes)} files here or click to browse files.
              </Text>
            </FlexRow>
          </Anchor>
        </React.Fragment>
      )
    case 'drag-over':
      return (
        <React.Fragment>
          <Icon name="cloud-upload" size="medium" color="secondary.0" mr="small" />
          <Text color="secondary.0">Drop {formatTypesList(allowedTypes)} files here</Text>
        </React.Fragment>
      )
    case 'upload':
      return (
        <React.Fragment>
          <Icon name="cloud-upload" size="medium" color="secondary.0" mr="medium" />
          <Box width="100%">
            <Box mb="small">
              {uploadProgress === 1 ? (
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="start">
                  <Icon name="check" color="neutral.2" size="medium" mr="small" />
                  <Text color="neutral.0">Uploaded {fileNames ? fileNames.join(', ') : ''}</Text>
                </Box>
              ) : (
                <Text color="neutral.0">Uploading {fileNames ? fileNames.join(', ') : ''}</Text>
              )}
            </Box>
            <Box position="relative" height="xsmall" bg="neutral.2" width="100%">
              <Box
                bg="secondary.0"
                position="absolute"
                top="0"
                left="0"
                height="xsmall"
                style={{ transition: 'all 200ms ease' }}
                width={`${(uploadProgress / 1) * 100}%`}
              />
            </Box>
          </Box>
        </React.Fragment>
      )
    default:
      throw new Error(`Unknown UploadZone status [${status}]`)
  }
}

const UploadZone = (props) => {
  const { onBrowse, allowedTypes, status, children, ...containerProps } = props

  return (
    <Box
      position="relative"
      px="gigantic"
      py="xlarge"
      bg="background.1"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...containerProps}
    >
      {children}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        {renderStatus(props)}
      </Box>
      {/*
        This overlay box is a hack to fix an issue with dragging over
        child text elements that causes the drag to be interrupted.
        See https://github.com/react-dropzone/react-dropzone/pull/949
       */}
      <Box
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        borderWidth={status === 'init' ? 'xthin' : 'thin'}
        borderColor="secondary.0"
        borderStyle={status === 'init' ? 'dashed' : 'solid'}
        borderRadius="small"
      />
    </Box>
  )
}

UploadZone.propTypes = {
  onBrowse: PropTypes.func,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.oneOf(['init', 'drag-over', 'upload']),
  fileNames: PropTypes.arrayOf(PropTypes.string),
  uploadProgress: PropTypes.number,
  // for including the hidden <input type="file"> child
  children: PropTypes.node,
}

UploadZone.defaultProps = {
  status: 'init',
  height: 'xgigantic',
}

export default UploadZone
