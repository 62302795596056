import React, { useEffect, useState } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexColumn, Text, Modal, Button } from 'elements'
import ChoiceButton from '../ui/input/ChoiceButton'
import ModalContent from '../app/ModalContent'
import ModalCloseButton from '../app/ModalCloseButton'
import useIntegrationUpdate from './hooks/useIntegrationUpdate'

export default function FirstClientXeroConnectionChoices({ integration, onDone, onBack, onClose }) {
  const [selected, setSelected] = useState(null)
  const [updateIntegration, isInFlight] = useIntegrationUpdate()

  const data = useFragment(
    graphql`
      fragment FirstClientXeroConnectionChoices_integration on Integration {
        rowId
        config
      }
    `,
    integration,
  )

  const configXero = data?.config?.xero
  const tenants = configXero?.tenants

  useEffect(() => {
    if (tenants?.length) {
      setSelected(tenants[0])
    }
  }, [tenants, setSelected])

  if (!tenants) {
    return null
  }

  const handleContinue = async () => {
    const newConfig = { xero: { ...configXero, currentTenant: selected } }

    await updateIntegration({
      rowId: data.rowId,
      patch: { config: newConfig },
    })

    onDone()
  }

  return (
    <>
      <ModalContent pt="gigantic" px="gigantic" height="550px">
        <ModalCloseButton onClose={onClose} />
        <Text mb="xlarge">Select a Xero organization to connect</Text>
        <FlexColumn>
          {tenants.map((tenant, idx) => (
            <ChoiceButton
              area="small"
              key={tenant.id}
              selected={tenant.id === selected?.id}
              icon="globe-alt"
              onPress={() => {
                setSelected(tenant)
              }}
              mb={idx !== tenants.length - 1 ? 'large' : null}
            >
              {tenant.tenantName}
            </ChoiceButton>
          ))}
        </FlexColumn>
      </ModalContent>
      <Modal.Footer
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="gigantic"
        pb="gigantic"
        width="100%"
      >
        <Button mr="medium" kind="alt-neutral" onClick={onBack}>
          Back
        </Button>
        <Button kind="primary" onClick={handleContinue} disabled={isInFlight}>
          Next
        </Button>
      </Modal.Footer>
    </>
  )
}
