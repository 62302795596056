import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useIntegrationUpdateMutation($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
        company {
          id
          ...Integrations_company
          ...IntegrationChoices_company
        }
      }
    }
  }
`

export default function useIntegrationUpdate() {
  return useMutation(mutation, 'updateIntegration')
}
