import React, { lazy } from 'react'
import { merge } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'
import Text from '../Text'
import FlexColumn from '../FlexColumn'
import { DateTime } from 'luxon'

const Chart = lazy(() => import('react-apexcharts'))

const defaultOptions = {
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 5,
      barHeight: '70%',
      distributed: true,
      dataLabels: { position: 'right' },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      //formatter instead of dateTimeFormatter so that tickAmount works
      formatter: (val) => DateTime.fromMillis(val).toISODate().substring(0, 10),
      offsetX: 30,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: true,
      maxWidth: 250,
    },
  },
  legend: {
    show: false,
    itemMargin: { horizontal: theme.sizes.small, vertical: theme.sizes.small },
    markers: { radius: theme.radii.xxsmall },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'right',
    formatter: (val) => {
      if (val[0] === val[1]) {
        return 'N/A'
      }
      return DateTime.fromMillis(val[1]).toISODate()
    },
  },
  tooltip: { enabled: false },
}

const chartOptions = merge({}, baseChartOptions, defaultOptions)

export default function TimelineChart({ options = defaultOptions, width, height, series, title }) {
  return (
    <>
      {title ? (
        <Text fontSize="large" fontWeight="bold" ml="xsmall" mb="medium">
          {title}
        </Text>
      ) : null}

      <FlexColumn borderRadius="small" bg="background.0" pt="large" px="medium">
        <Chart
          options={merge({}, chartOptions, options)}
          series={series}
          type="rangeBar"
          width={width}
          height={height}
        />
      </FlexColumn>
    </>
  )
}
