import React from 'react'
import { Formik } from 'formik'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexColumn, Text } from 'elements'
import DeactivateTriggerForm, { Values } from './DeactivateTriggerForm'
import { useNotifications } from 'hooks'
import useBulkDeactivate from './hooks/useBulkDeactivate'
import DeactivateHistoryTable from './DeactivateHistoryTable'

type Props = {
  company: any
}

export default function QboBulkDeactivateTrigger(props: Props) {
  const { company } = props

  const notifications = useNotifications()
  const [bulkDeactivate]: any = useBulkDeactivate()

  const data = useFragment(
    graphql`
      fragment QboBulkDeactivateTrigger_company on Company {
        integrations {
          nodes {
            id
            kind
            ...DeactivateHistoryTable_integration
          }
        }
      }
    `,
    company,
  )

  const qboIntegration = data.integrations.nodes.find(
    (integration: any) => integration.kind === 'intuit',
  )

  const handleSubmit = async (values: Values, { setSubmitting }: Record<string, any>) => {
    const { dateThreshold, entity } = values

    try {
      await bulkDeactivate({ integrationId: qboIntegration.id, dateThreshold, entity })

      notifications.success('Deactivation complete')
    } catch (err) {
      console.error(err)

      notifications.error('Deactivation error')
    }

    setSubmitting(false)
  }

  return (
    <FlexColumn pt="gigantic">
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxxlarge">
        QBO Bulk Deactivate
      </Text>
      <FlexColumn mb="gigantic">
        <Formik onSubmit={handleSubmit} initialValues={{}}>
          <DeactivateTriggerForm />
        </Formik>
      </FlexColumn>
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxlarge">
        Run History
      </Text>
      <DeactivateHistoryTable integration={qboIntegration} />
    </FlexColumn>
  )
}
