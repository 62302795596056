import React, { useState, useEffect } from 'react'
import { ceil } from 'lodash'
import { FlexColumn, FlexRow } from 'elements'
import ReportTable from './ReportTable'
import useWindowSize from '../../../../hooks/ui/useWindowSize'
import { DataSection, SectionViewOptions } from '../../types'
import { StyleProps } from '../../../../types/style'

type Props = {
  dataSections: DataSection[]
  viewOptions?: SectionViewOptions
  styleProps?: StyleProps
}

export default function ReportTables(props: Props) {
  const { dataSections, viewOptions, styleProps } = props

  const { width } = useWindowSize()
  const [twoColumns, setTwoColumns] = useState(viewOptions?.twoColumns)

  useEffect(() => {
    if (width && width < 1500) {
      setTwoColumns(false)
    } else {
      setTwoColumns(viewOptions?.twoColumns)
    }
  }, [width, viewOptions?.twoColumns])

  if (!dataSections) {
    return null
  }

  const split = viewOptions?.columnSplit ?? ceil(dataSections.length / 2)

  if (twoColumns && dataSections.length > 1) {
    const firstHalf = dataSections.slice(0, split)
    const secondHalf = dataSections.slice(split)

    return (
      <FlexRow {...styleProps?.boxProps}>
        <FlexColumn flex="1" mr="xxlarge">
          {firstHalf.map((dataSection, idx) => (
            <ReportTable
              key={`${dataSection.key}${idx}`}
              dataSection={dataSection}
              styleProps={{ boxProps: { mb: idx !== firstHalf.length - 1 ? 'xxlarge' : null } }}
            />
          ))}
        </FlexColumn>
        <FlexColumn flex="1">
          {secondHalf.map((dataSection, idx) => (
            <ReportTable
              key={`${dataSection.key}${idx}`}
              dataSection={dataSection}
              styleProps={{ boxProps: { mb: idx !== secondHalf.length - 1 ? 'xxlarge' : null } }}
            />
          ))}
        </FlexColumn>
      </FlexRow>
    )
  }

  return (
    <FlexColumn {...styleProps?.boxProps}>
      {dataSections.map((dataSection, idx) => (
        <ReportTable
          key={`${dataSection.key}${idx}`}
          dataSection={dataSection}
          styleProps={{ boxProps: { mb: idx !== dataSections.length - 1 ? 'xxlarge' : null } }}
        />
      ))}
    </FlexColumn>
  )
}
