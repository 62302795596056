import React from 'react'
import FlexRow from '../FlexRow'
import Button from '../Button'

export const DEFAULT_PAGE_SIZE = 10

export default function TablePagination({
  Table,
  colSpan,
  pageSize = DEFAULT_PAGE_SIZE,
  loadNext,
  hasNext,
  isLoadingNext,
}) {
  const { Foot, FootRow, FootCell } = Table

  if (!hasNext) {
    return null
  }

  return (
    <Foot>
      <FootRow>
        <FootCell colSpan={colSpan} py="none">
          <FlexRow width="100%" justifyContent="center">
            <Button
              width="100%"
              kind="transparent"
              disabled={isLoadingNext}
              onPress={() => loadNext(pageSize)}
            >
              {isLoadingNext ? 'Loading...' : 'Load More'}
            </Button>
          </FlexRow>
        </FootCell>
      </FootRow>
    </Foot>
  )
}
