import { graphql } from 'react-relay/hooks'
import { useDeleteMutation } from 'hooks'

export default function useRemoveOrgUser() {
  return useDeleteMutation(
    graphql`
      mutation useRemoveOrgUserMutation($input: DeleteOrganizationUserByIdInput!) {
        deleteOrganizationUserById(input: $input) {
          deletedOrganizationUserId
        }
      }
    `,
    'deletedOrganizationUserId',
  )
}
