import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useWorkflowTemplateArchiveMutation($input: ArchiveWorkflowTemplateInput!) {
    archiveWorkflowTemplate(input: $input) {
      workflowTemplate {
        id
        organization {
          id
        }
      }
    }
  }
`

const updater = (store, { archiveWorkflowTemplate }) => {
  const organization = store.get(archiveWorkflowTemplate.workflowTemplate.organization.id)

  const configConnection = ConnectionHandler.getConnection(
    organization,
    'WorkflowConfiguration_workflowTemplatesWithDefaults',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const dropdownConnection = ConnectionHandler.getConnection(
    organization,
    'WorkflowTemplateSelect_workflowTemplatesWithDefaults',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const connections = [configConnection, dropdownConnection]

  connections.filter(Boolean).forEach((connection) => {
    ConnectionHandler.deleteNode(connection, archiveWorkflowTemplate.workflowTemplate.id)
  })
}

export default function useReportTemplateArchive() {
  return useMutation(mutation, 'archiveWorkflowTemplate', { updater })
}
