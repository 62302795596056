import React, { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { mapKeys, startCase } from 'lodash'
import config from 'config'
import { AnalyticsContext } from 'contexts'
import { useAuth } from 'hooks'

const { projectToken, apiHost } = config.services.mixpanel

mixpanel.init(projectToken, {
  debug: config.services.mixpanel.debug,
  // DNT stops mixpanel from tracking anything,
  // so disable it in development so we can effectively test it
  ignore_dnt: config.app.dev,
  // proxy requests through the API to get around ad blockers
  api_host: apiHost,
})

function track(event, details) {
  if (projectToken) {
    // start case the detail keys for consistency
    const props = mapKeys(details, (value, key) => startCase(key))
    mixpanel.track(event, props)
  } else {
    console.log('TRACK', event, details)
  }
}

export default function AnalyticsProvider({ children }) {
  const { user } = useAuth()

  useEffect(() => {
    if (projectToken && user) {
      const email = user?.userEmails?.nodes?.[0]?.email

      // identify by rowId for easier lookup.
      // we can alias the global Id later if we need to
      mixpanel.identify(user.rowId)

      mixpanel.people.set({
        $name: user.name,
        $email: email,
      })

      mixpanel.register({
        $name: user.name,
        $email: email,
        Reporter: 'Web',
      })
    }
  }, [user])

  return <AnalyticsContext.Provider value={{ track }}>{children}</AnalyticsContext.Provider>
}
