import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useCompanyUpdateMutation($input: UpdateCompanyByIdInput!) {
    updateCompanyById(input: $input) {
      company {
        id
        name
      }
    }
  }
`

export default function useCompanyUpdate() {
  return useMutation(mutation, 'updateCompanyById')
}
