import React from 'react'
import { useFormikContext } from 'formik'
import ReportTables from '../view/common/ReportTables'
import ReportTable from '../view/common/ReportTable'
import { Transaction, ResolutionAnomalyType } from '../types'
import { Option } from '../../../shared/types'
import { getLineDataSections } from '../utils/resolutions/data'
import {
  getItemOptions,
  getAccountOptions,
  getEntityOptions,
} from '../utils/resolutions/dropdownOptions'
import {
  bankAccountTypes,
  transferTransactionTypes,
  splitLineTableTxnTypes,
  uneditableLineTxnTypes,
} from './constants'
import { Button, FlexColumn } from 'elements'
import { StyleProps } from '../../../types/style'
import { addLine } from '../utils/resolutions/formUpdates'

type Props = {
  items?: any[]
  accounts?: any[]
  customers?: any[]
  vendors?: any[]
  canEdit: boolean
  anomalyTypes?: ResolutionAnomalyType[]
  styleProps?: StyleProps
}

const emptyMessage = 'No lines to display.'

export default function ModalTransactionLineTables(props: Props) {
  const { items, accounts, customers, vendors, canEdit, anomalyTypes, styleProps } = props

  const formCtx = useFormikContext()
  const transaction = formCtx.values as Transaction
  const { transactionType } = transaction

  if (!canEdit) {
    return (
      <FlexColumn {...styleProps?.boxProps}>
        <ReportTables
          dataSections={getLineDataSections({
            transaction,
            canEdit,
            anomalyTypes,
            tableOptions: { emptyMessage },
          })}
        />
      </FlexColumn>
    )
  }

  let itemOptions: Option[] = []
  let accountOptions: Option[] = []

  // exclude options for read-only modal
  if (canEdit) {
    // only include items with expense accounts for bills
    if (splitLineTableTxnTypes.includes(transactionType)) {
      itemOptions = getItemOptions(items!, 'expense')
    } else {
      itemOptions = getItemOptions(items!)
    }

    // only bank type accounts for transfers
    if (transferTransactionTypes.includes(transactionType)) {
      accountOptions = getAccountOptions(accounts!, bankAccountTypes)
    } else {
      accountOptions = getAccountOptions(accounts!)
    }
  }

  const entityOptions = canEdit
    ? getEntityOptions({ customers: customers!, vendors: vendors! })
    : []

  // dropdown options for different columns
  const columnSelectProps: { [key: string]: any } = {
    'Product/Service': { options: itemOptions, optionProp: 'itemId', valueProp: 'lineItemId' },
    Account: { options: accountOptions, optionProp: 'accountId', valueProp: 'lineAccountId' },
    Name: { options: entityOptions, optionProp: 'id', valueProp: 'lineEntityId' },
  }

  const journalEntryAmountHeaders = ['Debits', 'Credits']

  const lineCellProps = (cell: any) => {
    const { row, column } = cell
    const selectProps = columnSelectProps[cell.column.Header]

    const cellProps: any = {
      editable: true,
    }

    if (
      journalEntryAmountHeaders.includes(column.Header) &&
      row.original.lineType === 'DescriptionOnly'
    ) {
      cellProps.editable = false
    }

    if (selectProps) {
      const { options, optionProp, valueProp } = selectProps

      cellProps.selectOptions = options
      cellProps.findValue = (value: any) => {
        return options.find((option: Option) => option.value[optionProp] === value[valueProp])
      }
    }

    return cellProps
  }

  const tableOptions = {
    emptyMessage,
    rowProps: (row: any) => ({ editable: row.original.lineType !== 'discount' }),
    cellProps: lineCellProps,
  }

  const canEditLines = !uneditableLineTxnTypes.includes(transactionType)

  const dataSections = getLineDataSections({
    transaction,
    tableOptions,
    canEdit,
    canEditLines,
    anomalyTypes,
  })

  return (
    <FlexColumn {...styleProps?.boxProps}>
      {dataSections.map((dataSection, idx) => (
        <FlexColumn mb={idx === dataSections.length - 1 ? null : 'xxxlarge'} key={dataSection.key}>
          <ReportTable dataSection={dataSection} styleProps={{ boxProps: { mb: 'medium' } }} />
          {canEditLines && (
            <Button
              kind="alt-neutral-2"
              width="8%"
              onClick={(e: any) => {
                e.preventDefault()

                addLine(dataSection, formCtx)
              }}
            >
              Add Line
            </Button>
          )}
        </FlexColumn>
      ))}
    </FlexColumn>
  )
}
