import React from 'react'
import PageBody from 'components/app/PageBody'
import SettingsHeader from 'components/organization/SettingsHeader'
import SettingsContainer from 'components/organization/SettingsContainer'

export default function SettingsPage() {
  return (
    <PageBody renderHeader={() => <SettingsHeader />} renderContent={() => <SettingsContainer />} />
  )
}
