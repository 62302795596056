/**
 * @generated SignedSource<<b03a4ae0b4683c568625a41932e40ea3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationStatus = "DISABLED" | "ENABLED" | "SUSPENDED" | "%future added value";
export type UpdateIntegrationInput = {
  clientMutationId?: string | null;
  patch: IntegrationPatch;
  rowId: any;
};
export type IntegrationPatch = {
  companyId?: any | null;
  config?: any | null;
  createdAt?: any | null;
  deletedAt?: any | null;
  errors?: any | null;
  kind?: string | null;
  ownerId?: any | null;
  rowId?: any | null;
  status?: IntegrationStatus | null;
  updatedAt?: any | null;
};
export type useIntegrationUpdateMutation$variables = {
  input: UpdateIntegrationInput;
};
export type useIntegrationUpdateMutation$data = {
  readonly updateIntegration: {
    readonly integration: {
      readonly id: string;
      readonly company: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"Integrations_company" | "IntegrationChoices_company">;
      } | null;
    } | null;
  } | null;
};
export type useIntegrationUpdateMutation = {
  variables: useIntegrationUpdateMutation$variables;
  response: useIntegrationUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useIntegrationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateIntegrationPayload",
        "kind": "LinkedField",
        "name": "updateIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Integration",
            "kind": "LinkedField",
            "name": "integration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Integrations_company"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "IntegrationChoices_company"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useIntegrationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateIntegrationPayload",
        "kind": "LinkedField",
        "name": "updateIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Integration",
            "kind": "LinkedField",
            "name": "integration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "IntegrationsConnection",
                    "kind": "LinkedField",
                    "name": "integrations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IntegrationsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Integration",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "config",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "integrations(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "Integrations_integrations",
                    "kind": "LinkedHandle",
                    "name": "integrations"
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "IntegrationChoices_integrations",
                    "kind": "LinkedHandle",
                    "name": "integrations"
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c6eed7d8253b4848baca8f9f39ca33c",
    "id": null,
    "metadata": {},
    "name": "useIntegrationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useIntegrationUpdateMutation(\n  $input: UpdateIntegrationInput!\n) {\n  updateIntegration(input: $input) {\n    integration {\n      id\n      company {\n        id\n        ...Integrations_company\n        ...IntegrationChoices_company\n      }\n    }\n  }\n}\n\nfragment IntegrationChoices_company on Company {\n  id\n  rowId\n  integrations(first: 100) {\n    edges {\n      node {\n        id\n        rowId\n        kind\n        config\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  organization {\n    slug\n    id\n  }\n}\n\nfragment Integrations_company on Company {\n  id\n  integrations(first: 100) {\n    edges {\n      node {\n        id\n        rowId\n        kind\n        config\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31ce2b9aa1c3471e24a6920bc794903e";

export default node;
