import React from 'react'
import { FlexColumn, FlexRow, Text, Card, Button } from 'elements'
import { KeyedOrganizationMetrics } from './types'
import { useAuth } from 'hooks'
import { getBillingPlanTier, getTierInfo } from '../../../utils/billing'

type Props = {
  working: boolean
  metrics: KeyedOrganizationMetrics
  handleManage: Function
}

export default function StandardSubscriptionCard(props: Props & Record<string, any>) {
  const { working, metrics, handleManage } = props
  const { billingPlan } = useAuth()

  const activeCompanies = metrics.clientCount?.value || 0
  const billingPlanTier = getBillingPlanTier(billingPlan, activeCompanies)
  const tierInfo = getTierInfo(billingPlanTier)

  if (!tierInfo) {
    return null
  }

  return (
    <Card
      bg="background.0"
      pb="xxxlarge"
      pt="xxxlarge"
      mb="xxxlarge"
      width="768px"
      flexDirection="column"
      {...props}
    >
      <FlexColumn justifyContent="center" pt="large" pb="large" alignItems="center">
        <Text fontSize="xxlarge" mb="large">
          You're on the <b>{tierInfo.name}</b> plan.
        </Text>
        <Text fontSize="large" mt="large" color="neutral.3" fontStyle="italic">
          The {tierInfo.name} plan costs {tierInfo.price} per month
        </Text>
        <Text fontSize="xxlarge" mt="xxlarge" mb="xxlarge">
          Past Month
        </Text>
        <FlexRow justifyContent="space-around">
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Clients
            </Text>
            <Text fontSize="large">{metrics?.clientCount?.value}</Text>
          </FlexColumn>
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Reports
            </Text>
            <Text fontSize="large">{metrics?.reportCount?.value}</Text>
          </FlexColumn>
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Anomalies Resolved
            </Text>
            <Text fontSize="large">{metrics?.anomaliesResolved?.value}</Text>
          </FlexColumn>
        </FlexRow>
        <FlexRow mt="xxlarge" justifyContent="center">
          <Button
            disabled={working}
            onClick={handleManage}
            kind="secondary"
            fontWeight="normal"
            ml="large"
          >
            {working ? 'Please wait...' : 'Manage Your Plan'}
          </Button>
        </FlexRow>
      </FlexColumn>
    </Card>
  )
}
