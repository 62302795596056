import React from 'react'
import { isNil } from 'lodash'
import { useFormikContext } from 'formik'
import { Text, FlexRow } from 'elements'
import { Transaction } from '../types'

export default function TransactionTaxRate() {
  const { values } = useFormikContext<Transaction>()
  const { transactionTotalTaxRate, transactionTaxAmount } = values

  return (
    <FlexRow>
      <Text mr="small">Tax Rate</Text>
      <Text fontWeight="bold" mr="xxlarge">
        {transactionTotalTaxRate}%
      </Text>
      <Text mr="small">Tax Amount</Text>
      {!isNil(transactionTaxAmount) && (
        <Text fontWeight="bold">
          ${transactionTaxAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </Text>
      )}
    </FlexRow>
  )
}
