import styled from 'styled-components'
import SimpleTableHeadCell from './SimpleTableHeadCell'

const SimpleTableHead = styled.thead`
  ${SimpleTableHeadCell}:first-child {
    padding-left: 0;
  }

  ${SimpleTableHeadCell}:last-child {
    padding-right: 0;
  }
`

export default SimpleTableHead
