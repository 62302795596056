import React from 'react'
import Box from '../Box'

const FlexRow = ({ children, ...props }) => {
  return (
    <Box display="flex" flexDirection="row" {...props}>
      {children}
    </Box>
  )
}

export default FlexRow
