import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { Dropdown, LinkButton, Icon } from 'elements'
import useCompanyArchive from '../hooks/useCompanyArchive'
import useCompanyRestore from '../hooks/useCompanyRestore'

export default function CompanyContextMenu({ company, companiesOrder }) {
  const [archiveCompany] = useCompanyArchive()
  const [restoreCompany] = useCompanyRestore()

  const { deletedAt } = useFragment(
    graphql`
      fragment CompanyContextMenu_company on Company {
        id
        deletedAt
      }
    `,
    company,
  )

  const actionOptions = [
    {
      label: deletedAt ? 'Restore' : 'Archive',
      kind: deletedAt ? 'normal' : 'danger',
      borderStyle: 'none',
      onSelect: () => {
        if (deletedAt) {
          return restoreCompany({ companyId: company.id })
        }

        return archiveCompany({ companyId: company.id }, { order: companiesOrder })
      },
    },
  ]

  return (
    <Dropdown
      position="bottom right"
      options={actionOptions}
      stopPropagation
      renderButton={() => (
        <LinkButton kind="transparent">
          <Icon name="dots-vertical" size="medium" color="neutral.9" />
        </LinkButton>
      )}
    />
  )
}
