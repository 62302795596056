import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useApi, useAuth, useQueryParams, useAnalytics } from 'hooks'
import { createFormValidator, validators } from 'utils/validation'
import { Button, Box, TextInputField, Message } from 'elements'
import VerifyResend from './VerifyResend'

const validate = createFormValidator({
  code: validators.verificationCode.required('Please enter your verification code'),
})

export default function Verify() {
  const api = useApi()
  const auth = useAuth()
  const history = useHistory()
  const analytics = useAnalytics()
  const { params } = useQueryParams()

  if (!params.email) {
    history.replace('/auth/login')
  }

  return (
    <Formik
      initialValues={{ code: '' }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const result = await api.auth.verify({
            email: params.email,
            code: values.code,
          })

          if (result.code === 200) {
            analytics.track('User Confirmed', { $email: params.email, provider: 'cognito' })

            await auth.login()

            history.push('/')
          } else {
            analytics.track('User Confirmed Error', { $email: params.email, provider: 'cognito' })
          }
        } catch (error) {
          setFieldError('general', 'Incorrect verification code.')
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form>
          {errors.general ? (
            <Message kind="warning" mb="xlarge">
              {errors.general}
            </Message>
          ) : null}
          <Message kind="info" mb="xlarge">
            Please check your email and enter your verification code below.
          </Message>
          <Box mb="xxxlarge">
            <Box mb="xlarge">
              <TextInputField
                type="password"
                label="Verification code"
                name="code"
                placeholder="Enter code"
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mb="large">
            <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
              {isSubmitting ? 'Verifying...' : 'Verify'}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" mb="small">
            <VerifyResend />
          </Box>
        </Form>
      )}
    </Formik>
  )
}
