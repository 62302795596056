export function alignToTextAlign(align) {
  return align || 'initial'
}

const flexAligns = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export function alignToFlex(align) {
  return flexAligns[align]
}
