import { ReportSection, SourceKind } from '../../../types'
import { ConfigSection, AnomalyReport } from '../../../../../shared/types'
import getDetailReportSections from './getDetailReportSections'
import getInsightReportSections from './getInsightReportSections'
import { addReportSectionCols } from '../../../utils/sections'

type ReportSectionProps = {
  report: AnomalyReport
  sourceKind?: SourceKind
  configSections?: ConfigSection[]
  reportId: string
  integrationId?: string
}

export default function generateReportSections(props: ReportSectionProps): ReportSection[] {
  const { report, sourceKind, configSections, reportId, integrationId } = props
  const { reportMetadata, reportDetailData } = report

  const reportEndDate = reportMetadata.reportPeriod.endDate

  const reportSections: ReportSection[] = []

  const insightReportSections = getInsightReportSections({
    report,
    sourceKind,
    configSections,
    reportId,
    integrationId,
  })

  reportSections.push(...insightReportSections)

  if (reportDetailData.reports) {
    const detailReportSections = getDetailReportSections({
      reports: reportDetailData.reports,
      configSections,
      reportEndDate,
      reportId,
      integrationId,
    })

    reportSections.push(...detailReportSections)
  }

  // loop through report sections, generate/standardize/merge dataSection columns
  return addReportSectionCols(reportSections)
}
