import React from 'react'
import { omit, mapKeys, mapValues } from 'lodash'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useQueryParams } from 'hooks'
import ReportContent from '../ReportContent'
import { ReportSection, DataSection, SourceKind } from '../../types'
import TransactionModalContainer from '../../view/TransactionModalContainer'
import { getStandardColumns } from '../../utils/tables'

type Props = {
  report: any
  reportSection: ReportSection
  sourceKind: SourceKind
}

export default function TransactionDrilldown(props: Props) {
  const { report, reportSection, sourceKind } = props
  const { reportId } = useParams<Record<string, any>>()
  const { params: queryParams = {} } = useQueryParams()

  const reportPeriod = queryParams?.startDate
    ? { startDate: queryParams.startDate, endDate: queryParams?.endDate }
    : null

  const accountProps = queryParams.accountType ? { accountType: queryParams.accountType } : null

  const queryTxnProps = omit(queryParams, ['accountType', 'startDate', 'endDate'])

  let transactionProps: Record<string, any> | null = null

  if (Object.keys(queryTxnProps).length) {
    transactionProps = mapKeys(queryTxnProps, (val, key) =>
      key === 'customerName' ? 'entityName' : key,
    )

    // To escape a single quote in Athena you have to change it to two single quotes
    transactionProps = mapValues(queryTxnProps, (val, key) =>
      key === 'entityName' ? val.replace(/'/g, "''") : val,
    )
  }

  const data: any = useLazyLoadQuery(
    graphql`
      query TransactionDrilldownQuery(
        $reportId: ID!
        $reportPeriod: ReportPeriod
        $transactionProps: JSON
        $accountProps: JSON
      ) {
        reportById(id: $reportId) {
          sourceKind
          athenaGeneralLedgerTransactions(
            input: {
              reportPeriod: $reportPeriod
              transactionProps: $transactionProps
              accountProps: $accountProps
            }
          )
        }
      }
    `,
    { reportId, reportPeriod, transactionProps, accountProps },
  )

  if (!data) {
    return null
  }

  const dataSections: DataSection[] = [
    {
      data: data.reportById?.athenaGeneralLedgerTransactions,
      columns: getStandardColumns(sourceKind),
      table: true,
      tableOptions: {
        emptyMessage: 'No transactions to display.',
        onRowClick:
          data.reportById?.sourceKind === 'qb:json'
            ? (row, rows, modalsCtx) => {
                const { openModal } = modalsCtx

                openModal!(
                  TransactionModalContainer,
                  {
                    platformTransactionId: row.original.id,
                    reportId,
                  },
                  'screen',
                )
              }
            : null,
      },
    },
  ]

  return (
    <ReportContent
      report={report}
      reportSection={{
        ...reportSection,
        dataSections,
      }}
    />
  )
}
