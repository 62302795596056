import { Sheet } from 'xlsx-populate'
import { DateTime } from 'luxon'
import { isFunction } from 'lodash'
import { defaultStyle } from '../export/theme'
import { Column } from '../types'
import { formatHeader } from '../utils/formatting'
import { TableConnected } from 'elements'
import { severitiesMap } from '../../common/constants'

// @ts-ignore
const { LedgerCell, SeverityCell } = TableConnected

// XLSX has a max sheet name length of 31 characters
const MAX_SHEET_NAME_LENGTH = 31

// headers here are already formatted, so 'transactionAmount', 'totalTransactionAmount', 'Amount'
// all become 'Amount'
const typeHeaders: Record<string, string[]> = {
  amount: ['Amount'],
  number: ['Num'],
}

export function toSafeSheetName(name: string): string {
  if (name?.length > MAX_SHEET_NAME_LENGTH) {
    return `${name.substr(0, MAX_SHEET_NAME_LENGTH - 3)}...`
  }

  return name || ''
}

export function setColWidths(sheet: Sheet, width: number, colNumber: number) {
  for (let i = 1; i <= colNumber; i++) {
    sheet.column(i).width(width)
  }
}

//filter out rows with zero for count or amount
export function transactionFilter(data: any[]) {
  if (!data) {
    return []
  }

  return data.filter(
    (row) =>
      (row.transactionAmount || row.totalTransactionAmount) &&
      (row.count || row.totalTransactionCount),
  )
}

export function getColumns(rows: object[], excludedColumns?: string[]): string[] {
  if (!rows?.length) {
    return []
  }
  const keys = Object.keys(rows[0])

  if (excludedColumns) {
    return keys.filter((key) => !excludedColumns.includes(key))
  }

  return keys
}

type StyleOptions = { columnNum: number }

export function setDefaultStyle(sheet: Sheet, options: StyleOptions): Sheet {
  for (let i = 1; i <= options.columnNum; i++) {
    sheet.column(i).style(defaultStyle)

    sheet.gridLinesVisible(false)
  }

  return sheet
}

export function formatDate(date: any): string | null {
  if (!date) {
    return null
  }

  const dateObj = DateTime.fromFormat(date, 'y-MM-dd TT')

  if (dateObj.isValid) {
    return dateObj.toISODate()
  }

  //different format than above
  return date
}

export function getAmountColIndex(headers: string[]): number | null {
  const match = headers.find((header) => typeHeaders.amount.includes(header))

  if (match) {
    //xlsx columns are 1-based
    return headers.indexOf(match) + 1
  }

  return null
}

type StyleHeaderOptions = {
  type: string
  style: Record<string, any>
  headers: string[]
  headerRowNum: number
}

//usually we can check if the cell below is a number, but transaction numbers
//are strings that get changed to numbers in excel (so they end up right-aligned).

//loop through header cells, styles all that have a certain type below them
export function styleHeaderByType(sheet: Sheet, options: StyleHeaderOptions): void {
  const { type, style, headers, headerRowNum } = options

  headers.forEach((header, i) => {
    //i + 1 bc excel is 1-based
    if (
      typeof sheet.cell(headerRowNum + 1, i + 1).value() === type ||
      typeHeaders[type]?.includes(header)
    ) {
      sheet.cell(headerRowNum, i + 1).style(style)
    }
  })
}

// columns have a Cell prop that is used to render it a certain way in the in-app tables. This should have
// a one-to-one relationship with cell styling and formatting in the xlsx exports
const cellStyleMap = new Map([
  [LedgerCell, { numberFormat: '$#,##0.00', horizontalAlignment: 'right' }],
])

type StyleColsByTypeProps = {
  sheet: Sheet
  columns: Column[]
  startRow: number
  endRow: number
}

export function styleColumnsByType(props: StyleColsByTypeProps): void {
  const { sheet, columns, startRow, endRow } = props

  columns.forEach((column, idx) => {
    const style = cellStyleMap.get(column.Cell)

    if (style) {
      // xlsx cols are 1-based
      const colIdx = idx + 1

      sheet.range(startRow, colIdx, endRow, colIdx).style(style)
    }
  })
}

export const severityFormatter = (value: number): string | null =>
  severitiesMap.get(value)?.fullText || null

// convert null amount cells to 0 to avoid Excel errors
const LedgerCellFormatter = (value: number | null): number => {
  if (!value) {
    return 0
  }

  return value
}

const cellFormatterMap = new Map()

cellFormatterMap.set(SeverityCell, severityFormatter)
cellFormatterMap.set(LedgerCell, LedgerCellFormatter)

type AccessedData = { values: any[][]; headers: string[] }

// return array of value arrays, plus headers
export function accessData(rows: Record<string, any>[], columns: Column[]): AccessedData {
  const values = rows.map((row) =>
    columns.map((column) => {
      const { accessor, Cell } = column

      const value = isFunction(accessor) ? accessor(row) : row[accessor as string]
      const formatter = cellFormatterMap.get(Cell)

      return formatter ? formatter(value) : value
    }),
  )

  const headers = columns.map((column) => {
    if (isFunction(column.Header)) {
      return column.Header()
    }

    return column.Header || ''
  })

  return { values, headers }
}

type GetDataProps = { rows: Record<string, any>[]; columns?: Column[]; excludedColumns?: string[] }

export function getData(props: GetDataProps): AccessedData {
  const { rows, columns, excludedColumns } = props

  if (columns) {
    return accessData(rows, columns)
  }

  const genColumns = getColumns(rows, excludedColumns)
  const headers = genColumns.map((col) => formatHeader(col))

  const values = rows.map((row) =>
    genColumns.map((column) => {
      const value = row[column]

      // do not pass object values
      if (value === null || typeof value !== 'object') {
        return value
      }

      return null
    }),
  )

  return { values, headers }
}
