import React from 'react'
import { Button, FlexColumn, Icon } from 'elements'

export default function ChoiceButton({ icon, selected, children, ...buttonProps }) {
  return (
    <Button
      justifyContent="flex-start"
      flex="1"
      kind={selected ? 'alt-secondary' : 'normal'}
      {...buttonProps}
    >
      {icon ? (
        <Icon name={icon} size="xlarge" mr="large" color={selected ? 'secondary.0' : 'neutral.1'} />
      ) : null}
      <FlexColumn
        flex="1"
        alignItems="flex-start"
        justifyContent="center"
        color={selected ? 'secondary.0' : 'neutral.1'}
      >
        {children}
      </FlexColumn>
    </Button>
  )
}
