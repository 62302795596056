import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string, ref } from 'yup'
import { useApi, useQueryParams } from 'hooks'
import { createFormValidator, validators } from 'utils/validation'
import { Button, Box, TextInputField, Message } from 'elements'
import VerifyResend from './VerifyResend'

const validate = createFormValidator({
  code: validators.verificationCode.required('Please enter your verification code'),
  password: validators.password.required('Please enter a password'),
  passwordConfirm: string().oneOf([ref('password')], 'Passwords do not match'),
})

export default function Verify() {
  const api = useApi()
  const history = useHistory()
  const { params } = useQueryParams()

  return (
    <Formik
      initialValues={{ code: '', password: '', passwordConfirm: '' }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const result = await api.auth.resetPassword({
            email: params.email,
            code: values.code,
            password: values.password,
          })

          if (result.code === 200) {
            history.push('/auth/login')
          }
        } catch (error) {
          setFieldError('general', 'Incorrect verification code.')
          setSubmitting(false)
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          {errors.general ? (
            <Message kind="warning" mb="xlarge">
              {errors.general}
            </Message>
          ) : null}
          <Message kind="info" mb="xlarge">
            We've emailed you a reset code. Enter it below to reset your password.
          </Message>
          <Box mb="xxxlarge">
            <Box mb="xlarge">
              <TextInputField
                type="password"
                label="Verification code"
                name="code"
                placeholder="Enter code"
              />
            </Box>
            <Box mb="xlarge">
              <TextInputField
                type="password"
                label="New password"
                name="password"
                placeholder="Enter password"
              />
            </Box>
            <Box>
              <TextInputField
                type="password"
                label="Confirm new password"
                name="passwordConfirm"
                placeholder="Enter password again"
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mb="large">
            <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
              {isSubmitting ? 'Resetting...' : 'Reset'}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" mb="small">
            <VerifyResend />
          </Box>
        </Form>
      )}
    </Formik>
  )
}
