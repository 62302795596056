import theme from 'theme'

export const toXlsxColor = (hex: string) => hex.replace('#', '')
export const toXlsxSize = (size: string) => parseInt(size.replace('px', ''), 10)

export const defaultStyle = {
  fill: toXlsxColor(theme.colors.background[0]),
  fontColor: toXlsxColor(theme.colors.foreground[0]),
  bold: false,
  fontSize: toXlsxSize(theme.fontSizes.xsmall),
  wrapText: true,
  border: false,
}

export const titleKeyStyle = {
  bold: true,
  fontSize: toXlsxSize(theme.fontSizes.small),
}

export const titleValueStyle = {
  fontSize: toXlsxSize(theme.fontSizes.small),
  verticalAlignment: 'top',
  horizontalAlignment: 'left',
}

export const headerStyle = {
  fontColor: toXlsxColor(theme.colors.background[0]),
  bold: true,
  fill: toXlsxColor(theme.colors.secondary[0]),
  verticalAlignment: 'top',
  border: true,
  borderColor: toXlsxColor(theme.colors.foreground[0]),
}

export const rowStyle = {
  border: true,
  borderColor: toXlsxColor(theme.colors.foreground[0]),
}
