/**
 * @generated SignedSource<<43892a95af8be449826de4bf35dca708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaidTrigger_company$data = {
  readonly integrations: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly kind: string;
      readonly config: any | null;
    }>;
  };
  readonly " $fragmentType": "PlaidTrigger_company";
};
export type PlaidTrigger_company$key = {
  readonly " $data"?: PlaidTrigger_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaidTrigger_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaidTrigger_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationsConnection",
      "kind": "LinkedField",
      "name": "integrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Integration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "config",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "0d3b0c892cca6401dc284ddc891d7def";

export default node;
