import React, { useState, useEffect } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { isEqual, pick } from 'lodash'
import { Select, Text } from 'elements'
import { Option } from '../../shared/types'
import { FormattedDate } from 'react-intl'
import { ReportPeriod } from '../../shared/types'
import CreateReportPeriodModal from '../report/create/CreateReportPeriodModal'
import { useModals } from 'hooks'

type Props = {
  company: any
  period: ReportPeriod | null
  onPeriodChange: (period: ReportPeriod) => void
}

export default function PeriodSelect(props: Props) {
  const { company, period, onPeriodChange } = props
  const { openModal } = useModals()
  const [options, setOptions] = useState<Option[]>([])

  const data = useFragment<any>(
    graphql`
      fragment PeriodSelect_company on Company {
        id
        filteredReports(input: { uniqueByPeriod: true }, orderBy: CREATED_AT_DESC, first: 100)
          @connection(key: "PeriodSelect_filteredReports") {
          edges {
            node {
              periodStart
              periodEnd
            }
          }
        }
      }
    `,
    company,
  )

  useEffect(() => {
    const periods =
      data?.filteredReports?.edges?.map((edge: any) =>
        pick(edge.node, ['periodStart', 'periodEnd']),
      ) || []

    const options = [
      {
        label: '+ Add a New Period',
        value: 'newPeriod',
      },
      ...periods.map((period: any) => ({
        label: period,
        value: period,
      })),
    ]

    setOptions(options)
  }, [data])

  let value = options.find((option) => isEqual(option.value, period))

  // for archived reports, period won't be in the list of options but we still want to display the period
  if (!value && period) {
    value = { label: period, value: period }
  }

  const handlePeriodCreate = (period: ReportPeriod) => {
    // new period is the same as an existing one
    const existingPeriod = options.find((option) => isEqual(option.value, period))

    if (!existingPeriod) {
      setOptions([...options, { label: period, value: period }])
    }

    onPeriodChange(period)
  }

  const handlePeriodChange = (option: Option) => {
    if (option.value === 'newPeriod') {
      openModal!(CreateReportPeriodModal, { onPeriodCreate: handlePeriodCreate }, 'full')
    } else {
      onPeriodChange(option.value)
    }
  }

  return (
    // @ts-ignore
    <Select
      options={options}
      value={value}
      onChange={handlePeriodChange}
      placeholder="Select Period"
      disabled={!company}
      interactive={(provided: any, state: any) => state.value === 'newPeriod'}
      formatOptionLabel={({ label, value }: Option) => {
        if (value === 'newPeriod') {
          return <Text color="secondary.0">{label}</Text>
        }

        return (
          <Text>
            <FormattedDate value={label.periodStart} timeZone="utc" /> –{' '}
            <FormattedDate value={label.periodEnd} timeZone="utc" />
          </Text>
        )
      }}
    />
  )
}
