/**
 * @generated SignedSource<<6f6dba461b6a577669b7c7c65ab0d0ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompaniesOrderBy = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DELETED_AT_ASC" | "DELETED_AT_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_PERIOD_END_ASC" | "LATEST_PERIOD_END_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "ORGANIZATION_ID_ASC" | "ORGANIZATION_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type ArchiveCompanyInput = {
  companyId: string;
};
export type useCompanyArchiveMutation$variables = {
  input: ArchiveCompanyInput;
  order?: ReadonlyArray<CompaniesOrderBy> | null;
};
export type useCompanyArchiveMutation$data = {
  readonly archiveCompany: {
    readonly company: {
      readonly id: string;
      readonly deletedAt: any | null;
      readonly organization: {
        readonly id: string;
        readonly totalCompanies: {
          readonly totalCount: number;
        };
        readonly " $fragmentSpreads": FragmentRefs<"CompaniesTable_organization">;
      } | null;
    };
  } | null;
};
export type useCompanyArchiveMutation = {
  variables: useCompanyArchiveMutation$variables;
  response: useCompanyArchiveMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "includeDeleted",
  "value": "NO"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": "totalCompanies",
  "args": [
    (v4/*: any*/)
  ],
  "concreteType": "CompaniesConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": "companies(includeDeleted:\"NO\")"
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompanyArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveCompanyPayload",
        "kind": "LinkedField",
        "name": "archiveCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompaniesTable_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompanyArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveCompanyPayload",
        "kind": "LinkedField",
        "name": "archiveCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "CompaniesConnection",
                    "kind": "LinkedField",
                    "name": "companies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompaniesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rowId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legalName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReportsConnection",
                                "kind": "LinkedField",
                                "name": "reports",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Report",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "workflowSummary",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": "latestReports",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "orderBy",
                                    "value": "CREATED_AT_DESC"
                                  }
                                ],
                                "concreteType": "ReportsConnection",
                                "kind": "LinkedField",
                                "name": "reports",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Report",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "periodStart",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "periodEnd",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "reports(orderBy:\"CREATED_AT_DESC\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "filters": [
                      "orderBy",
                      "includeDeleted"
                    ],
                    "handle": "connection",
                    "key": "CompaniesTable_companies",
                    "kind": "LinkedHandle",
                    "name": "companies"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8aecaf8a048261808727fbdac2fde74",
    "id": null,
    "metadata": {},
    "name": "useCompanyArchiveMutation",
    "operationKind": "mutation",
    "text": "mutation useCompanyArchiveMutation(\n  $input: ArchiveCompanyInput!\n  $order: [CompaniesOrderBy!]\n) {\n  archiveCompany(input: $input) {\n    company {\n      id\n      deletedAt\n      organization {\n        id\n        totalCompanies: companies(includeDeleted: NO) {\n          totalCount\n        }\n        ...CompaniesTable_organization\n      }\n    }\n  }\n}\n\nfragment CompaniesTableRow_company on Company {\n  id\n  rowId\n  name\n  legalName\n  createdAt\n  deletedAt\n  reports {\n    totalCount\n    nodes {\n      workflowSummary\n      id\n    }\n  }\n  latestReports: reports(orderBy: CREATED_AT_DESC) {\n    nodes {\n      periodStart\n      periodEnd\n      id\n    }\n  }\n}\n\nfragment CompaniesTable_organization on Organization {\n  slug\n  companies(first: 10, orderBy: $order, includeDeleted: NO) {\n    edges {\n      node {\n        id\n        deletedAt\n        ...CompaniesTableRow_company\n        ...CompanyContextMenu_company\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment CompanyContextMenu_company on Company {\n  id\n  deletedAt\n}\n"
  }
};
})();

(node as any).hash = "03c69e63245c348f65bbe8f58c0f4e54";

export default node;
