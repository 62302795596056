import React from 'react'
import styled from 'styled-components'
import { print } from '../styles'
import {
  compose,
  space,
  layout,
  typography,
  color,
  background,
  border,
  shadow,
  system,
  position,
} from 'styled-system'
import Box from '../Box'
import FlexRow from '../FlexRow'
import DataTableSortButton from './DataTableSortButton'
import { alignToFlex } from './utils'

const StyledTh = styled.th`
  ${print}
  ${compose(space, typography, color, layout, background, border, shadow, position)}
  ${system({ whiteSpace: true })}

  user-select: none;
`

function DataTableHeadCell({ children, align, disableSortBy, sort, centerSortButton, ...props }) {
  return (
    <StyledTh {...props} textAlign={align || 'left'}>
      {centerSortButton ? (
        <FlexRow justifyContent="center" alignItems="center">
          <DataTableSortButton sort={sort} />
        </FlexRow>
      ) : (
        <FlexRow justifyContent={alignToFlex(align)} alignItems="center">
          <Box mr="medium">{children}</Box>
          {!disableSortBy ? <DataTableSortButton sort={sort} /> : null}
        </FlexRow>
      )}
    </StyledTh>
  )
}

DataTableHeadCell.defaultProps = {
  py: 'large',
  px: 'xlarge',
  fontWeight: 'light',
  fontSize: 'xsmall',
  whiteSpace: 'nowrap',
  disableSortBy: false,
}

export default DataTableHeadCell
