import { kebabCase, camelCase } from 'lodash'
import { ReportSection, DataSection } from '../../../types'
import { StackedBarChart, DonutChart, TableConnected } from 'elements'
import { insights, insightDescriptions, headerMap } from '../../../constants'
import theme from 'theme'
import { drilldownReportSection } from './constants'

const { DateCell, NumericCell } = TableConnected as any

type Props = {
  formattedSummary: Record<string, any>
}

export default function getAccountReportSection(props: Props): ReportSection {
  const { formattedSummary } = props

  const {
    getAccountByTypeChartData,
    accountsUsage,
    accountByTypeCategories,
    formattedAccountsByType,
    accountList,
  } = formattedSummary

  const accountByTypeDataSections: DataSection[] = [
    {
      key: 'accountCountByTypeDescription',
      title: 'Description',
      description: insightDescriptions.accountCountByType,
      gridColumn: '1',
    },
    {
      key: 'accountCountByTypeInsights',
      title: 'Insights',
      insights: insights.accountCountByType,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      key: 'accountCountByTypeDetailChart',
      data: getAccountByTypeChartData({ first: 10 }),
      Chart: StackedBarChart,
      gridRow: '2',
      chartOptions: {
        options: { xaxis: { categories: accountByTypeCategories.slice(0, 10) } },
      },
    },
    {
      key: 'accountCountByTypeTable',
      data: formattedAccountsByType,
      gridRow: '3',
      table: true,
      tableOptions: {
        highlightedColumn: headerMap.accountType,
        initialSortColumns: [{ id: 'accountTypeInReportCount', desc: true }],
      },
    },
  ]

  const accountUsageSubReportSections = accountsUsage.map((category: any) => {
    const dataSections = [
      {
        key: `accountsUsage_${camelCase(category.accountCategory)}`,
        table: true,
        data: category.accounts,
        columns: [
          { Header: 'Name', accessor: 'accountName' },
          {
            Header: 'Date Created',
            accessor: 'accountCreatedTime',
            Cell: DateCell,
          },
          {
            Header: 'Date Modified',
            accessor: 'accountLastUpdatedTime',
            Cell: DateCell,
          },
        ],
        tableOptions: {
          highlightedColumn: 'Name',
          rowRoute: (row: any) => `details?account=${encodeURIComponent(row.original.accountName)}`,
        },
        subReportSections: [drilldownReportSection],
      },
    ]

    return {
      label: category.accountCategory,
      value: `accountsUsage_${camelCase(category.accountCategory)}`,
      kind: 'insight',
      route: kebabCase(category.accountCategory),
      dataSections,
      exportData: [
        {
          sheetName: `Accounts ${category.accountCategory}`,
          dataSections,
        },
      ],
    }
  })

  const accountUsageDataSections: DataSection[] = [
    {
      key: 'chartOfAccountsUsageDescription',
      title: 'Description',
      description: insightDescriptions.chartOfAccountsUsage,
      gridColumn: '1',
    },
    {
      key: 'chartOfAccountsUsageInsights',
      title: 'Insights',
      insights: insights.chartOfAccountsUsage,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      key: 'chartOfAccountsUsageChart',
      Chart: DonutChart,
      chartOptions: {
        options: {
          colors: [theme.colors.secondary[0], theme.colors.secondary[8]],
        },
      },
      gridRow: '2',
      gridColumn: '1',
      data: accountsUsage
        .slice(0, 2)
        .map((category: any) => ({ name: category.accountCategory, data: category.count })),
    },
    {
      key: 'chartOfAccountsUsageTable',
      table: true,
      gridRow: '3',
      data: accountsUsage,
      columns: [
        { Header: 'Accounts', accessor: 'accountCategory' },
        {
          Header: 'Count',
          accessor: 'count',
          sortDescFirst: true,
          Cell: NumericCell,
        },
      ],
      tableOptions: {
        highlightedColumn: 'Accounts',
        rowRoute: accountList?.length
          ? (row: any) => kebabCase(row.original.accountCategory)
          : null,
      },
      subReportSections: accountUsageSubReportSections,
    },
  ]

  const dataSections = [
    {
      key: 'accountCountByType',
      title: 'Account Count by Type',
      insights: insights.accountCountByType,
      Chart: StackedBarChart,
      chartOptions: {
        options: { xaxis: { categories: accountByTypeCategories.slice(0, 5) } },
      },
      detailRoute: 'account-count-by-type',
      data: getAccountByTypeChartData({ first: 5 }),
      subReportSections: [
        {
          label: 'Account Count by Type',
          value: 'accountCountByType',
          route: 'account-count-by-type',
          kind: 'insight',
          dataSections: accountByTypeDataSections,
        },
      ],
    },
    {
      key: 'chartOfAccountsUsage',
      title: 'Chart of Accounts Usage',
      insights: insights.chartOfAccountsUsage,
      Chart: DonutChart,
      detailRoute: 'account-usage',
      // used and unused
      data: accountsUsage
        .slice(0, 2)
        .map((category: any) => ({ name: category.accountCategory, data: category.count })),
      chartOptions: {
        options: {
          colors: [theme.colors.secondary[0], theme.colors.secondary[8]],
        },
      },
      subReportSections: [
        {
          label: 'Chart of Account Usage',
          value: 'chartOfAccountsUsage',
          route: 'account-usage',
          kind: 'insight',
          dataSections: accountUsageDataSections,
        },
      ],
    },
  ]

  return {
    label: 'Accounts',
    value: 'accountInsights',
    kind: 'insight',
    dataSections,
  }
}
