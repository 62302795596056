import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useOrganizationSetupMutation($input: SetupOrganizationInput!) {
    setupOrganization(input: $input) {
      organization {
        id
        name
        legalName
        slug
      }
    }
  }
`

export default function useOrganizationSetup() {
  return useMutation(mutation, 'setupOrganization')
}
