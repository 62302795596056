import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import Integrations from './Integrations'
import { useAuth } from 'hooks'
import { ModalNavHeader, Text, Modal, Button, FlexRow } from 'elements'
import ModalContent from '../app/ModalContent'
import ConnectionChoices from '../integrations/ConnectionChoices'

type Props = {
  modal: any
  companyId?: string
}

export default function IntegrationModal(props: Props) {
  const { modal, companyId } = props
  const { currentCompany } = useAuth()

  const data = useLazyLoadQuery<any>(
    graphql`
      query IntegrationModalQuery($companyId: ID!) {
        companyById(id: $companyId) {
          ...Integrations_company
          ...PlaidIntegrations_company
          ...StripeIntegration_company
          integrations(first: 100) {
            edges {
              node {
                rowId
                config
                kind
              }
            }
          }
        }
      }
    `,
    { companyId: currentCompany?.id || companyId },
  )

  if (!data) {
    return null
  }

  const xeroIntegration = data.companyById.integrations?.edges?.find(
    (edge: any) => edge.node.kind === 'xero',
  )?.node

  if (xeroIntegration && !xeroIntegration.config.xero.currentTenant) {
    return <ConnectionChoices integration={xeroIntegration} onClose={modal.onClose} />
  }

  return (
    <>
      <ModalNavHeader onClose={modal.onClose} titleText="Data Source Integrations" />
      <ModalContent pt="none">
        <Text mb="xxlarge">Select which data source you want to use for the workflow</Text>
        <Integrations company={data.companyById} />
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer display="flex" alignItems="center" justifyContent="flex-end" width="100%">
        <FlexRow justifyContent="flex-end">
          <Button onClick={modal.onClose} kind="primary">
            Done
          </Button>
        </FlexRow>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
