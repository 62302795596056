import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useWorkflowStepUpdate() {
  return useMutation(
    graphql`
      mutation useWorkflowStepUpdateMutation($input: UpdateReportWorkflowWorkspaceStepByIdInput!) {
        updateReportWorkflowWorkspaceStepById(input: $input) {
          reportWorkflowWorkspaceStep {
            ...WorkspaceStep_reportWorkflowWorkspaceStep
            ...StepHeader_reportWorkflowWorkspaceStep
          }
          reportWorkflowWorkspace {
            ...WorkflowWorkspace_reportWorkflowWorkspace
            report {
              ...WorkspaceHeader_report
            }
          }
        }
      }
    `,
    'updateReportWorkflowWorkspaceStepById',
  )
}
