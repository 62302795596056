import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTable, useSortBy, usePagination, useExpanded, useRowSelect } from 'react-table'
import Text from '../Text'
import TablePagination from '../TablePagination'
import useMountEffect from 'hooks/ui/useMountEffect'
import FlexColumn from '../FlexColumn'
import FlexRow from '../FlexRow'
import PopupCard from '../PopupCard'
import LinkButton from '../LinkButton'
import Icon from '../Icon'
import TableOptions from './TableOptions'
import useModals from 'hooks/ui/useModals'

const OVERFLOW_WORD_LENGTH = 15

const sortTypes = {
  alphanumericFalsyLast(rowA, rowB, columnId) {
    if (!rowA.values[columnId] && !rowB.values[columnId]) {
      return 0
    }

    if (!rowA.values[columnId]) {
      return 1
    }

    if (!rowB.values[columnId]) {
      return -1
    }

    return rowA.values[columnId].localeCompare(rowB.values[columnId])
  },

  // sort strings after numbers (e.g. 'N/A')
  numericFirst(rowA, rowB, columnId) {
    const valueA = rowA.values[columnId]
    const valueB = rowB.values[columnId]

    if (!Number.isFinite(valueA) && !Number.isFinite(valueB)) {
      return 0
    }

    if (!Number.isFinite(valueA)) {
      return -1
    }

    if (!Number.isFinite(valueB)) {
      return 1
    }

    return valueA - valueB
  },
}

function getColumnSort(column) {
  if (column.isSorted) {
    return column.isSortedDesc ? 'desc' : 'asc'
  }

  return undefined
}

function renderCell(cell) {
  //wraps on spaces and slashes
  const words = typeof cell.value === 'string' ? cell.value.split(/[\s+/]/g) : []

  if (cell.column.truncate) {
    return (
      <Text fontSize="inherit" color="inherit" truncate title={cell.value}>
        {cell.render('Cell')}
      </Text>
    )
  } else if (words.some((word) => word.length > OVERFLOW_WORD_LENGTH)) {
    // allow wrapping mid-word to fit these long boys
    return (
      <Text fontSize="inherit" color="inherit" title={cell.value} overflowWrap="anywhere">
        {cell.render('Cell')}
      </Text>
    )
  }

  return cell.render('Cell')
}

function TableConnected({ table, columns, data, tableOptions }) {
  const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = table
  const history = useHistory()
  const location = useLocation()
  const modalsCtx = useModals()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canNextPage,
    setPageSize,
    selectedFlatRows,
    state,
    toggleAllRowsExpanded,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      sortTypes,
      autoResetSortBy: false,
      autoResetExpanded: !tableOptions?.startExpanded,
      initialState: {
        pageSize: tableOptions?.pageSize,
        sortBy: tableOptions?.initialSortColumns || [],
        hiddenColumns:
          tableOptions?.hiddenColumns ||
          columns.filter((col) => col.defaultShow === false).map((col) => col.id || col.accessor) ||
          [],
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  )

  const { pageSize } = state

  const tableRows = tableOptions?.pagination ? page : rows

  const rowProps = tableOptions?.rowProps || (() => ({}))
  const cellProps = tableOptions?.cellProps || (() => ({}))
  const tableProps = tableOptions?.tableProps || {}

  const { onRowSelect, onRowClick, rowRoute } = tableOptions || {}

  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedFlatRows.map((row) => row.original))
    }
  }, [onRowSelect, selectedFlatRows])

  useMountEffect(() => {
    if (tableOptions?.startExpanded) {
      toggleAllRowsExpanded()
    }
  })

  return (
    <FlexColumn width="100%">
      {tableOptions?.includeConfig && (
        <FlexRow alignItems="center" justifyContent="flex-end" mb="medium">
          <PopupCard
            position="left top"
            renderButton={() => (
              <LinkButton kind="transparent">
                <Icon name="cog" size="medium" color="neutral.2" />
              </LinkButton>
            )}
            renderContent={(ref) => <TableOptions ref={ref} allColumns={allColumns} />}
          />
        </FlexRow>
      )}
      <FlexRow overflowX="auto" width="100%" borderRadius="small">
        <Table {...tableProps} {...getTableProps()}>
          {tableOptions?.noHeader ? null : (
            <Head>
              {headerGroups.map((headerGroup) => (
                <HeadRow
                  {...headerGroup.getHeaderGroupProps()}
                  accent={tableOptions?.accent}
                  key={headerGroup.headers[0].id}
                >
                  {headerGroup.headers.map((column) => (
                    <HeadCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      disableSortBy={column.disableSortBy || tableOptions?.disableSortBy}
                      sort={getColumnSort(column)}
                      width={column.width}
                      align={column.align}
                      whiteSpace={column.whiteSpace}
                      textAlign={column.textAlign}
                      centerSortButton={column.centerSortButton}
                      key={column.id}
                      position={column.sticky && 'sticky'}
                      top={column.stickyPosition?.top}
                      right={column.stickyPosition?.right}
                      bottom={column.stickyPosition?.bottom}
                      left={column.stickyPosition?.left}
                      zIndex={column.sticky && '3'}
                      bg="inherit"
                      {...column.style?.headCell}
                    >
                      {column.render('Header')}
                    </HeadCell>
                  ))}
                </HeadRow>
              ))}
            </Head>
          )}
          <Body {...getTableBodyProps()}>
            {!tableRows.length && tableOptions?.emptyState ? (
              <Row>
                <Cell colSpan={columns.length}>
                  <tableOptions.emptyState />
                </Cell>
              </Row>
            ) : (
              tableRows.map((row, i) => {
                prepareRow(row)
                // add custom row props for use in custom cells
                row.rowProps = rowProps(row)

                let rowClick = null

                if (onRowClick) {
                  rowClick = () => onRowClick(row, rows, modalsCtx)
                } else if (rowRoute) {
                  rowClick = () => history.push(`${location.pathname}/${rowRoute(row)}`)
                }

                return (
                  <React.Fragment key={row.id}>
                    <Row
                      onClick={rowClick}
                      // only change color for sub-component rendering, not sub-rows
                      bg={row.isExpanded && tableOptions.renderRowSubComponent && 'neutral.2'}
                      {...row.getRowProps(rowProps(row))}
                    >
                      {row.cells.map((cell) => {
                        cell.cellProps = cellProps(cell)

                        let color = 'neutral.10'
                        if (tableOptions.highlightedColumn) {
                          if (cell.column.Header !== tableOptions.highlightedColumn) {
                            color = 'neutral.9'
                          }
                        }

                        return (
                          <Cell
                            {...cell.getCellProps(cellProps(cell))}
                            status={cell.row.original.status}
                            align={cell.column.align}
                            color={color}
                            key={[row.id, cell.column.id]}
                            position={cell.column.sticky && 'sticky'}
                            top={cell.column.stickyPosition?.top}
                            right={cell.column.stickyPosition?.right}
                            bottom={cell.column.stickyPosition?.bottom}
                            left={cell.column.stickyPosition?.left}
                            bg="inherit"
                            zIndex={cell.column.sticky && '3'}
                            {...cell.column.style?.dataCell}
                          >
                            {renderCell(cell)}
                          </Cell>
                        )
                      })}
                    </Row>
                    {row.isExpanded &&
                      tableOptions.renderRowSubComponent &&
                      tableOptions.renderRowSubComponent(row)}
                  </React.Fragment>
                )
              })
            )}
          </Body>
          {/* tables that use query pagination should not have { pagination: true } because
      we don't want to use TableConnected's pageSize */}
          {(tableOptions?.pagination || tableOptions?.paginationProps) && (
            <TablePagination
              Table={table}
              colSpan={columns.length}
              loadNext={(inputPageSize) => {
                setPageSize(pageSize + inputPageSize)
              }}
              hasNext={tableOptions?.pagination && !tableOptions.preview ? canNextPage : false}
              pageSize={pageSize}
              {...tableOptions?.paginationProps}
            />
          )}
        </Table>
      </FlexRow>
    </FlexColumn>
  )
}

export default TableConnected
