/**
 * @generated SignedSource<<96590bd8e73eaad9582d59c240218066>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionModalQuery$variables = {
  platformTransactionId?: string | null;
  reportId: string;
};
export type TransactionModalQuery$data = {
  readonly transactionByReportAndId: {
    readonly id: string;
    readonly transactionData: any | null;
    readonly transactionType: string | null;
  } | null;
};
export type TransactionModalQuery = {
  variables: TransactionModalQuery$variables;
  response: TransactionModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "platformTransactionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "platformTransactionId",
            "variableName": "platformTransactionId"
          },
          {
            "kind": "Variable",
            "name": "reportId",
            "variableName": "reportId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "IntegrationTransaction",
    "kind": "LinkedField",
    "name": "transactionByReportAndId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transactionData",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transactionType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d4a01bacdf719bc3227f60560f71cc95",
    "id": null,
    "metadata": {},
    "name": "TransactionModalQuery",
    "operationKind": "query",
    "text": "query TransactionModalQuery(\n  $platformTransactionId: String\n  $reportId: ID!\n) {\n  transactionByReportAndId(input: {platformTransactionId: $platformTransactionId, reportId: $reportId}) {\n    id\n    transactionData\n    transactionType\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8b4d98659bfc4e6bb7d32d80eb9cb14";

export default node;
