import React, { useState } from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import {
  Text,
  Anchor,
  Table as Tables,
  TablePagination,
  FlexRow,
  LinkButton,
  Dropdown,
  Icon,
  FlexColumn,
  RouterLink,
} from 'elements'
import CompaniesTableRow from './CompaniesTableRow'
import { useModals, useMountEffect, useAuth } from 'hooks'
import EmptyState from 'components/common/EmptyState'
import FirstClientModalContainer from '../create/FirstClientModalContainer'
import { Sort, SortColumn } from '../../../types/table'
import { getOrder } from './CompaniesContainer'

const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = Tables.Card

type Props = {
  organization: any
  sortCol?: SortColumn | null
  onSort: Function
}

const getColSort = (header: string, sortCol?: SortColumn | null): Sort | null => {
  if (!sortCol) {
    return null
  }

  const { column, order } = sortCol

  if (column === header) {
    return order
  }

  return null
}

export default function CompaniesTable(props: Props) {
  const { organization, sortCol, onSort } = props

  const [includeDeleted, setIncludeDeleted] = useState(false)
  const { openModal } = useModals()
  const { freeTrial, activeSubscription, allowClientCreation } = useAuth()

  const handleCreateCompany = () => openModal!(FirstClientModalContainer, {}, 'full')

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment CompaniesTable_organization on Organization
        @argumentDefinitions(
          cursor: { type: "Cursor" }
          count: { type: "Int", defaultValue: 10 }
          includeDeleted: { type: "IncludeDeletedOption", defaultValue: NO }
        )
        @refetchable(queryName: "CompaniesTablePaginationQuery") {
        slug
        companies(after: $cursor, first: $count, orderBy: $order, includeDeleted: $includeDeleted)
          @connection(key: "CompaniesTable_companies") {
          edges {
            node {
              id
              deletedAt
              ...CompaniesTableRow_company
              ...CompanyContextMenu_company
            }
          }
        }
      }
    `,
    organization,
  )

  const edges = data?.companies?.edges || []
  const noCompanies = edges.length === 0

  useMountEffect(() => {
    if (noCompanies && allowClientCreation) {
      openModal!(FirstClientModalContainer, { firstClient: true }, 'full')
    }
  })

  const toggleArchived = () => {
    const inc = !includeDeleted
    setIncludeDeleted(inc)

    refetch({ includeDeleted: inc ? 'YES' : 'NO' }, { fetchPolicy: 'store-and-network' })
  }

  let emptyStateAction = (
    <Text color="neutral.0">
      <Anchor onClick={handleCreateCompany}>Create a client</Anchor> or{' '}
      <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
        learn how it works.
      </Anchor>
    </Text>
  )

  if (!allowClientCreation) {
    emptyStateAction = (
      <Text color="neutral.0">
        <RouterLink to={`/orgs/${data.slug}/settings/billing`} color="secondary.0">
          Upgrade
        </RouterLink>{' '}
        to create a client or{' '}
        <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
          learn how it works.
        </Anchor>
      </Text>
    )
  }

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell
            enableSortBy
            sort={getColSort('Client', sortCol)}
            onSort={(sort: Sort) => onSort({ column: 'Client', order: sort })}
          >
            Client
          </HeadCell>
          <HeadCell width="220px">Active Workflows</HeadCell>
          <HeadCell width="220px">Workflow Completion</HeadCell>
          <HeadCell
            enableSortBy
            sort={getColSort('Latest Period', sortCol)}
            onSort={(sort: Sort) => onSort({ column: 'Latest Period', order: sort })}
            width="220px"
          >
            Latest Period
          </HeadCell>
          <HeadCell
            enableSortBy
            sort={getColSort('Created At', sortCol)}
            onSort={(sort: Sort) => onSort({ column: 'Created At', order: sort })}
            width="220px"
          >
            Created At
          </HeadCell>
          <HeadCell width="100px">
            <FlexRow alignItems="center" justifyContent="flex-end">
              <Dropdown
                position="bottom right"
                options={[
                  {
                    label: includeDeleted ? 'Hide archived' : 'Show archived',
                    onSelect: toggleArchived,
                  },
                ]}
                stopPropagation
                renderButton={() => (
                  <LinkButton kind="transparent">
                    <Icon
                      size="medium"
                      name="cog"
                      color={includeDeleted ? 'secondary.0' : 'neutral.2'}
                    />
                  </LinkButton>
                )}
              />
            </FlexRow>
          </HeadCell>
        </HeadRow>
      </Head>
      <Body>
        {noCompanies ? (
          <Row>
            <Cell colSpan={5}>
              <FlexColumn p="xxxlarge">
                <EmptyState
                  message="Get started by adding your first client!"
                  action={emptyStateAction}
                />
              </FlexColumn>
            </Cell>
          </Row>
        ) : (
          edges.map(({ node: company }: any, i: number) => (
            <CompaniesTableRow
              disabled={!activeSubscription && !freeTrial}
              company={company}
              companiesOrder={getOrder(sortCol || null)}
              key={company.id}
            />
          ))
        )}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={5} {...paginationProps} />
    </Table>
  )
}
