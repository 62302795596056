import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks'
import { parseSearchParams } from 'utils/url'

export default function RootPage() {
  const { isReady, user } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const currentOrg = user?.organizationUsers?.nodes?.[0]?.organization
  let redirectPath

  if (currentOrg) {
    const orgPath = `orgs/${currentOrg.slug}`
    redirectPath = `/${orgPath}/dashboard`
  } else {
    redirectPath = '/auth/create-organization'
  }

  useEffect(() => {
    if (isReady) {
      if (user) {
        history.replace(redirectPath)
      } else {
        // not logged in, redirect to auth with any URL params as state
        history.replace({ pathname: '/auth/login', state: parseSearchParams(location.search) })
      }
    }
  }, [isReady, user, history, redirectPath, location])

  return null
}
