import { round, sum, max, minBy, maxBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { roundToNearest } from './math'
import { Series, TimelineSeries } from 'types/chart'

type ChartSpan = 'semi' | 'full'

type CalcColWidthProps = {
  chartSpan?: ChartSpan
  colNum: number
}

export function calcColumnWidth(props: CalcColWidthProps): string {
  const { chartSpan = 'semi', colNum } = props

  let colWidth = 0

  if (chartSpan === 'semi') {
    colWidth = 2.5 * colNum
  } else {
    colWidth = colNum
  }

  return `${colWidth}%`
}

type TimelineColWidthProps = {
  chartSpan?: ChartSpan
  series: TimelineSeries
}

export function calcTimelineColWidth(props: TimelineColWidthProps): string {
  const { chartSpan = 'semi', series } = props

  let colWidth = 0

  const firstMonth = minBy(series, (d) => DateTime.fromISO(d.x))
  const lastMonth = maxBy(series, (d) => DateTime.fromISO(d.x))

  if (firstMonth && lastMonth) {
    const diff = Interval.fromDateTimes(
      DateTime.fromISO(firstMonth.x),
      DateTime.fromISO(lastMonth.x),
    ).count('months')

    if (chartSpan === 'semi') {
      colWidth = diff * 2
    } else {
      colWidth = diff
    }
  }

  return `${colWidth}%`
}

export function getTicks(maxValue: number): { increment: number | undefined; amount: number } {
  const axisCeiling = roundToNearest(maxValue)

  const possibleIncrements: number[] = []
  for (let i = 0; i < 20; i++) {
    const firstDigits = ['1', '5']
    firstDigits.forEach((digit) => {
      possibleIncrements.push(parseInt(`${digit + '0'.repeat(i)}`, 10))
    })
  }

  const increment = possibleIncrements.find((increment) => axisCeiling / increment < 10)

  const tickAmount = increment ? axisCeiling / increment : 10

  const tick = { increment: increment, amount: tickAmount }

  return tick
}

//not using this now bc of difficulty with chart library, but I think it will come in handy
export function getTimelineTicks(durationInMonths: number): number {
  //every six months, halve the number of ticks
  const monthPeriod = 6

  const divisor = Math.ceil(durationInMonths / monthPeriod)

  return round(durationInMonths / divisor)
}

export function calcStackedMaxValue(series: Series): number {
  const stackTotals = [...Array(series[0].data.length)].map((_, idx) =>
    sum(series.map((seriesData) => seriesData.data[idx])),
  )

  return max(stackTotals) || 0
}
