/**
 * @generated SignedSource<<03919a48b3b2f28876a81f627faffd98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaidHistoryTableRow_userFile$data = {
  readonly createdAt: any;
  readonly meta: any | null;
  readonly " $fragmentType": "PlaidHistoryTableRow_userFile";
};
export type PlaidHistoryTableRow_userFile$key = {
  readonly " $data"?: PlaidHistoryTableRow_userFile$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaidHistoryTableRow_userFile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaidHistoryTableRow_userFile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meta",
      "storageKey": null
    }
  ],
  "type": "UserFile",
  "abstractKey": null
};

(node as any).hash = "7d15657afbc901f920f3ca317d5a615c";

export default node;
