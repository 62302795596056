import { graphql, fetchQuery } from 'react-relay/hooks'

export default async function fetchCurrentUser(environment) {
  const data = await fetchQuery(
    environment,
    graphql`
      query fetchCurrentUserQuery {
        currentUser {
          id
          rowId
          isAdmin
          name
          reportsByOwnerId {
            totalCount
          }
          userAuthentications {
            nodes {
              service
              updatedAt
            }
          }
          userEmails {
            nodes {
              email
              isPrimary
            }
          }
          organizationUsers {
            totalCount
            nodes {
              id
              role
              organization {
                id
                rowId
                name
                slug
                organizationCompanies(first: 1) {
                  totalCount
                  nodes {
                    company {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {},
  ).toPromise()

  return data?.currentUser
}
