import { omit, flatten, uniqBy } from 'lodash'
import { getColumns } from './tables'
import { ReportSection, DataSection } from '../types'
import { ConfigSection } from '../../../shared/types'

export function getFlatSubReportSections(reportSection: ReportSection): ReportSection[] {
  return flatten(
    reportSection.dataSections.map((dataSection) => dataSection.subReportSections || []),
  )
}

export function flattenReportSections(reportSections: ReportSection[]): ReportSection[] {
  const finalSections: any[] = []

  reportSections.forEach((section) => {
    const withoutSubsections = {
      ...section,
      dataSections: section.dataSections.map((dataSection) =>
        omit(dataSection, 'subReportSections'),
      ),
    }

    finalSections.push(withoutSubsections)

    const subReportSections = getFlatSubReportSections(section)

    finalSections.push(...flattenReportSections(subReportSections))
  })

  return finalSections
}

// generate columns for data sections early because in-app tables and exports are using them
export function addReportSectionCols(reportSections: ReportSection[]): ReportSection[] {
  return reportSections.map((reportSection) => {
    const dataSections = reportSection.dataSections.map((dataSection: any) => {
      const finalDataSection: DataSection = { ...dataSection }

      const { data, columns, mergeColumns } = dataSection

      finalDataSection.columns = data
        ? getColumns({
            dataObj: data[0],
            columns,
            mergeCols: mergeColumns,
          })
        : []

      if (dataSection.subReportSections) {
        finalDataSection.subReportSections = addReportSectionCols(dataSection.subReportSections)
      }

      return finalDataSection
    })

    return { ...reportSection, dataSections }
  })
}

type WorkspaceStep = {
  key: string
  name?: string
  description?: string
  sections: Record<string, any>
}

export function getFlatWorkspaceSteps(report: any): WorkspaceStep[] {
  return flatten(
    report.reportWorkflowWorkspaces.nodes.map(
      (workspace: any) => workspace.reportWorkflowWorkspaceSteps.nodes,
    ),
  )
}

function arrayifySections(sections: Record<string, any>): ConfigSection[] {
  let res: any[] = []

  Object.entries(sections).forEach(([key, props]) => {
    if (props.sections) {
      res = res.concat(arrayifySections(props.sections))
    }

    res.push({ ...omit(props, 'sections'), name: key })
  })

  return res
}

export function getFlatConfigSections(report: any): ConfigSection[] {
  const steps = getFlatWorkspaceSteps(report)

  const stepSections = flatten(steps.map((step) => arrayifySections(step.sections)))

  // add default and custom flags, which are in the report config
  const defaultFlags = report.config?.transactionFlags || {}
  const customFlags = report.config?.customTransactionFlags || {}

  const allFlags = { ...defaultFlags, ...customFlags }

  const flagSections = arrayifySections(allFlags)

  const sections = stepSections.concat(flagSections).filter((section) => section.enabled)

  return uniqBy(sections, 'name')
}
