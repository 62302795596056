import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import ReportViewContainer from './ReportViewContainer'
import ReportWatcher from '../ReportWatcher'
import NotFound from '../../router/NotFound'

type Props = {
  report: any
  step: any
}

export default function ReportContainer(props: Props) {
  const { report, step } = props

  const data = useFragment(
    graphql`
      fragment ReportContainer_report on Report {
        id
        ...ReportViewContainer_report
        ...ReportContent_report
        ...ReportResolution_report
        ...ReportResolutionTable_report
      }
    `,
    report,
  )

  if (!data) {
    return null
  }

  if (!data.id) {
    return <NotFound errorMessage="We couldn't find that workflow." />
  }

  return (
    <>
      <ReportWatcher reportId={data.id} />
      <ReportViewContainer report={data} step={step} />
    </>
  )
}
