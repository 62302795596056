import React, { useState } from 'react'
import SelectUploadSource from './SelectUploadSource'
import FileUploadReport from './FileUploadReport'
import { ReportPeriod } from '../../../shared/types'
import { Modal } from 'elements'

type Props = {
  modal: any
  reportId: string
  workflowTemplateId: string
  reportPeriod: ReportPeriod
}

export default function UploadRetryModal(props: Props) {
  const { modal, reportId, workflowTemplateId, reportPeriod } = props

  const [uploadSource, setUploadSource] = useState('qbo')
  const [step, setStep] = useState(0)

  const handleNext = () => setStep(step + 1)
  const handleBack = () => setStep(step - 1)

  const handleUploadChange = (choice: string) => setUploadSource(choice)

  let content = (
    <SelectUploadSource
      modal={modal}
      onNext={handleNext}
      selected={uploadSource}
      onChange={handleUploadChange}
    />
  )

  if (step === 1) {
    content = (
      <FileUploadReport
        modal={modal}
        onBack={handleBack}
        uploadSource={uploadSource}
        workflowTemplateId={workflowTemplateId}
        reportPeriod={reportPeriod}
        deleteReportId={reportId}
      />
    )
  }

  return <Modal modal={modal}>{content}</Modal>
}
