import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { withTheme } from 'styled-components'

const ScreenModal = ({ theme, children, title, onClose, isClosing, ...props }) => {
  return (
    <ReactModal
      contentLabel={title || 'Modal'}
      onRequestClose={onClose}
      isOpen={!isClosing}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false} // handled by Modal parent
      style={{
        overlay: {
          backgroundColor: 'rgba(38, 45, 63, 0.8)',
          zIndex: theme.zIndices.screenModal,
          border: 'transparent',
          padding: 0,
        },
        content: {
          position: 'relative',
          top: 'initial',
          left: 'initial',
          right: 'initial',
          bottom: 'initial',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          width: '100%',
          height: '100%',
          border: '0',
          background: 'transparent',
          overflow: 'hidden', // content is responsible for its own scrolling behavior
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: 0, // content is responsible for its own padding
        },
      }}
      {...props}
    >
      {children}
    </ReactModal>
  )
}

ScreenModal.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  isClosing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default withTheme(ScreenModal)
