import React from 'react'
import { Field } from 'formik'
import Box from '../Box'
import Text from '../Text'
import FieldLabel from '../FieldLabel'
import TextInput from '../TextInput'

export default function TextInputField({
  name,
  label,
  tooltip,
  includeError = true,
  ...inputProps
}: any) {
  return (
    <Field name={name}>
      {({ field, form, meta }: any) => {
        const error = meta.error || form.errors[field.name]
        const touched = meta.touched || form.touched[field.name]

        return (
          <Box {...inputProps}>
            {label ? <FieldLabel tooltip={tooltip}>{label}</FieldLabel> : null}
            <TextInput kind={touched && error ? 'danger' : undefined} {...field} {...inputProps} />
            {touched && error && includeError && (
              <Text fontSize="small" color="danger.0" mt="small">
                {error}
              </Text>
            )}
          </Box>
        )
      }}
    </Field>
  )
}
