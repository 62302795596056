import React from 'react'
import { Circle, Text } from 'elements'
import { severitiesMap } from './constants'
import { StyleProps } from 'types/style'

type Props = {
  severity: number
  showAbbreviation?: boolean
  size?: string
  styleProps?: StyleProps
}

export default function SeverityCircle(props: Props) {
  const { severity, showAbbreviation, styleProps, size = 'large' } = props

  return (
    <Circle
      color={severitiesMap.get(severity)?.color || 'transparent'}
      size={size}
      {...styleProps?.boxProps}
    >
      {showAbbreviation && (
        <Text fontSize="xxsmall" fontWeight="bold" color="background.0">
          {severitiesMap.get(severity)?.text}
        </Text>
      )}
    </Circle>
  )
}

SeverityCircle.defaultProps = {
  showAbbreviation: true,
}
