import { toTitleCase } from '../../../../utils/string'

export function getEntityField(entityType: string | null): string {
  if (entityType) {
    return `${toTitleCase(entityType)}`
  }

  return 'Entity'
}

type Action = 'review' | 'resolve' | 'undoReview' | 'undoResolve'

type ResolutionBehavior = {
  label: string
  action: Action
}

export function getResolutionBehavior(
  resolutionSection: string,
  editing?: boolean,
): ResolutionBehavior {
  if (resolutionSection === 'forReview') {
    if (editing) {
      return { label: 'Save', action: 'resolve' }
    }
    return { label: 'Mark as Reviewed', action: 'review' }
  }

  if (resolutionSection === 'reviewed') {
    return { label: 'Undo Review', action: 'undoReview' }
  }

  return { label: 'Undo Changes', action: 'undoResolve' }
}

export function parseResolutionError(resolution: any): any {
  const error =
    resolution.responseData?.responseError?.info?.error?.authResponse?.json?.Fault?.Error?.[0]

  if (error?.code >= 500) {
    return error
  }

  return null
}

const standardErrorMessage =
  'This transaction edit could not be performed. Please check the information and try again.'

export function getResolutionError(resolution: any): string {
  return parseResolutionError(resolution)?.Detail || standardErrorMessage
}

export function findReportIntegration(reportIntegrations: any, kind: string) {
  return reportIntegrations?.nodes?.find((node: any) => node.integration.kind === kind)
}

type IconProps = {
  name: string
  color: string
}

export function getResolutionStatusIcon(status: string, updateStatus: string): IconProps | null {
  if (status === 'forReview') {
    if (updateStatus === 'error') {
      return { name: 'exclamation-circle', color: 'danger.0' }
    }
  }

  if (status === 'resolved') {
    if (updateStatus === 'success') {
      return { name: 'check-circle', color: 'primary.0' }
    }

    return { name: 'clock', color: 'neutral.0' }
  }

  return null
}
