import React from 'react'
import { compact } from 'lodash'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { graphql, useFragment } from 'react-relay/hooks'
import { useDecision } from '@optimizely/react-sdk'
import { Dropdown, LinkButton, Icon } from 'elements'
import { useAnalytics, useStorage, useAuth } from 'hooks'
import useReportArchive from '../hooks/useReportArchive'
import useReportRestore from '../hooks/useReportRestore'
import useReportRetry from '../hooks/useReportRetry'
import useRetryReportWithIntegration from '../hooks/useRetryReportWithIntegration'
import useReportDelete from '../hooks/useReportDelete'
import { getFlatConfigSections } from '../utils/sections'
import { handleReportExport } from '../utils/download'

const ANOMALY_REPORT_KIND = 'scrutinize:json:anomaly-report:1'

function getReportType(report: any) {
  if (!report) {
    return null
  }

  const { reportUserFiles, reportIntegrations } = report

  const sourceFiles = reportUserFiles.nodes.filter((file: any) => file.type === 'SOURCE')

  if (
    reportIntegrations?.nodes?.length ||
    sourceFiles.every((file: any) => file.userFile.kind.includes('json'))
  ) {
    return 'integration'
  }

  if (sourceFiles.length) {
    return 'upload'
  }

  return null
}

type Props = {
  report: any
  setWorking: Function
  includeDeleted: boolean
}

export default function ReportContextMenu(props: Props) {
  const { includeDeleted } = props

  const { currentOrg } = useAuth()
  const storage = useStorage()
  const analytics = useAnalytics()
  const [archiveReport]: any = useReportArchive()
  const [restoreReport]: any = useReportRestore()
  const [retryReport]: any = useReportRetry()
  const [retryReportWithIntegration]: any = useRetryReportWithIntegration()
  const [deleteReport]: any = useReportDelete()
  const history = useHistory()
  const canReviewDownload = useDecision('canReviewDownload')[0].enabled

  const report = useFragment(
    graphql`
      fragment ReportContextMenu_report on Report {
        rowId
        id
        name
        status
        deletedAt
        config
        company {
          id
        }
        reportUserFiles {
          nodes {
            type
            userFile {
              id
              rowId
              kind
              name
              key
            }
          }
        }
        reportIntegrations {
          nodes {
            integration {
              id
              owner {
                id
              }
            }
          }
        }
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            key
            reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
              nodes {
                id
                key
                sections
              }
            }
          }
        }
      }
    `,
    props.report,
  )

  if (!report) {
    return null
  }

  let configSections = report.config?.sections

  // get flat list of config sections to configure report data. They used to be in a config
  // column on the reports table, but now they're nested in app_public.report_workflow_workspace_steps
  if (report.reportWorkflowWorkspaces?.nodes?.length) {
    configSections = getFlatConfigSections(report)
  }

  const anomalyReportFile = report.reportUserFiles.nodes.find(
    (file: any) => file.userFile.kind === ANOMALY_REPORT_KIND,
  )

  const { status, reportIntegrations } = report

  const isSuccessful = status === 'SUCCESS'
  const isUnsuccessful = status === 'ERROR'
  const integration = reportIntegrations?.nodes?.[0]?.integration

  const reportType = getReportType(report)

  const reportPath = `/orgs/${currentOrg!.slug}/companies/${report.company.id}/reports/${report.id}`

  const actionOptions = compact([
    isSuccessful
      ? {
          label: 'View',
          as: RouterLink,
          to: reportPath,
        }
      : null,
    isSuccessful
      ? {
          label: 'Download',
          onSelect: () => {
            handleReportExport({
              anomalyReportId: anomalyReportFile.userFile.rowId,
              storage,
              reportName: report.name,
              setWorking: props.setWorking,
              configSections,
              reportId: report.id,
            })
            analytics.track('Report Downloaded', { reportId: report.rowId })
          },
        }
      : null,
    isSuccessful && canReviewDownload
      ? {
          label: 'Review Download',
          kind: 'normal',
          onSelect: () =>
            handleReportExport({
              anomalyReportId: anomalyReportFile.userFile.rowId,
              storage,
              reportName: report.name,
              setWorking: props.setWorking,
              configSections,
              reportId: report.id,
              kind: 'review',
            }),
        }
      : null,
    isSuccessful || isUnsuccessful
      ? {
          label: 'Refresh',
          kind: 'normal',
          onSelect: async () => {
            analytics.track('Report Retried', { reportId: report.rowId })

            if (reportType === 'integration') {
              await retryReportWithIntegration({
                reportId: report.id,
                integrationId: integration.id,
              })
            } else {
              await retryReport({ reportId: report.id })
            }

            history.push(reportPath)
          },
        }
      : null,
    {
      label: report.deletedAt ? 'Restore' : 'Archive',
      kind: report.deletedAt ? 'normal' : 'danger',
      borderStyle: 'none',
      onSelect: () => {
        if (report.deletedAt) {
          analytics.track('Report Restored', { reportId: report.rowId })
          return restoreReport({ reportId: report.id })
        }

        analytics.track('Report Archived', { reportId: report.rowId })
        return archiveReport({ reportId: report.id })
      },
    },
    report.deletedAt && status === 'ERROR'
      ? {
          label: 'Delete Permanently',
          kind: 'danger',
          onSelect: () => {
            analytics.track('Report Deleted', { reportId: report.rowId })
            return deleteReport({ id: report.id, includeDeleted: includeDeleted ? 'YES' : 'NO' })
          },
        }
      : null,
  ])

  return (
    <Dropdown
      position="bottom right"
      options={actionOptions}
      stopPropagation
      renderButton={() => (
        // @ts-ignore
        <LinkButton kind="transparent">
          <Icon size="medium" name="dots-vertical" color="neutral.9" />
        </LinkButton>
      )}
    />
  )
}
