/**
 * @generated SignedSource<<b76536b4a8b840029abc294f833b7990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReadOnlyResolutionModalQuery$variables = {
  platformTransactionId?: string | null;
  globalId?: string | null;
  reportId: string;
  resolutionId?: string | null;
};
export type ReadOnlyResolutionModalQuery$data = {
  readonly reportResolutionByData: {
    readonly id: string;
    readonly currentValue: any | null;
    readonly initialValue: any | null;
    readonly resolutionKind: string | null;
    readonly status: string | null;
    readonly updateStatus: string | null;
    readonly anomalyTypes: ReadonlyArray<any | null> | null;
    readonly responseData: any | null;
  } | null;
};
export type ReadOnlyResolutionModalQuery = {
  variables: ReadOnlyResolutionModalQuery$variables;
  response: ReadOnlyResolutionModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "globalId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platformTransactionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resolutionId"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "globalId",
            "variableName": "globalId"
          },
          {
            "kind": "Variable",
            "name": "platformTransactionId",
            "variableName": "platformTransactionId"
          },
          {
            "kind": "Variable",
            "name": "reportId",
            "variableName": "reportId"
          },
          {
            "kind": "Variable",
            "name": "resolutionId",
            "variableName": "resolutionId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ReportResolution",
    "kind": "LinkedField",
    "name": "reportResolutionByData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initialValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resolutionKind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updateStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "anomalyTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "responseData",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReadOnlyResolutionModalQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReadOnlyResolutionModalQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "5a07a5ab627b84b005d651e10b8cad7c",
    "id": null,
    "metadata": {},
    "name": "ReadOnlyResolutionModalQuery",
    "operationKind": "query",
    "text": "query ReadOnlyResolutionModalQuery(\n  $platformTransactionId: String\n  $globalId: String\n  $reportId: ID!\n  $resolutionId: ID\n) {\n  reportResolutionByData(input: {resolutionId: $resolutionId, platformTransactionId: $platformTransactionId, reportId: $reportId, globalId: $globalId}) {\n    id\n    currentValue\n    initialValue\n    resolutionKind\n    status\n    updateStatus\n    anomalyTypes\n    responseData\n  }\n}\n"
  }
};
})();

(node as any).hash = "26a12ae37a3ac6ea90086f2a2948b0b6";

export default node;
