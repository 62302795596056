import React from 'react'
import { useDecision } from '@optimizely/react-sdk'
import PageBody from 'components/app/PageBody'
import ModulesOrganizationHeader from 'components/organization/ModulesOrganizationHeader'
import CreateCompaniesForm from 'components/modules/CreateCompaniesForm'

export default function CreateCompaniesPage() {
  const modulesEnabled = useDecision('canAccessModules')[0].enabled

  if (!modulesEnabled) {
    return null
  }

  return (
    <PageBody
      renderHeader={() => <ModulesOrganizationHeader />}
      renderContent={() => <CreateCompaniesForm />}
    />
  )
}
