import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportResolutionUpdateStatusesMutation(
    $input: UpdateResolutionStatusesInput!
    $resolutionSection: String
    $anomalies: [String]
  ) {
    updateResolutionStatuses(input: $input) {
      report {
        reviewStatus
        resolutionProgress
        ...ReportResolution_report
        ...ReportResolutionTable_report
        ...WorkspaceHeader_report
      }
    }
  }
`

export default function useReportResolutionUpdateStatuses() {
  return useMutation(mutation, 'updateResolutionStatuses')
}
