import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportResolutionUndoMutation(
    $input: UndoReportResolutionInput!
    $resolutionSection: String
    $anomalies: [String]
  ) {
    undoReportResolution(input: $input) {
      reportResolution {
        id
        currentValue
        initialValue
        resolutionKind
        status
        updateStatus
        anomalyTypes
        report {
          reviewStatus
          resolutionProgress
          ...ReportResolution_report
          ...ReportResolutionTable_report
          ...WorkspaceHeader_report
        }
      }
    }
  }
`

export default function useReportResolutionUndo() {
  return useMutation(mutation, 'undoReportResolution')
}
