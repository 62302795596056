import React from 'react'
import { useHistory } from 'react-router-dom'
import { graphql, useFragment } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useDecision } from '@optimizely/react-sdk'
import { FlexRow, Table as Tables, Text } from 'elements'

const { Row, Cell } = Tables.Card

type Props = {
  company: any
}

export default function ModulesCompaniesTableRow(props: Props) {
  const { company } = props

  const { orgSlug } = useParams<Record<string, any>>()
  const history = useHistory()
  const canViewIds = useDecision('canViewIds')[0].enabled

  const rowCompany = useFragment(
    graphql`
      fragment ModulesCompaniesTableRow_company on Company {
        id
        rowId
        name
        legalName
        createdAt
      }
    `,
    company,
  )

  const companyPath = `/orgs/${orgSlug}/modules/companies/${rowCompany.id}`

  const rowProps = {
    onClick: () => history.push(companyPath),
  }

  return (
    <Row {...rowProps}>
      <Cell>
        <FlexRow alignItems="center">
          <Text
            fontSize="large"
            fontWeight="bold"
            truncate
            title={rowCompany.name}
            lineHeight="large"
            mr={canViewIds && 'large'}
          >
            {rowCompany.name}
          </Text>
          {canViewIds && <Text>{rowCompany.rowId}</Text>}
        </FlexRow>
      </Cell>
    </Row>
  )
}
