import React from 'react'
import { useHistory } from 'react-router-dom'
import { FlexRow, Text, Avatar, Icon, FlexColumn } from 'elements'
import { useAuth, useAnalytics } from 'hooks'
import { getUserName, getPrimaryEmail } from 'utils/user'

export default function AvatarSection() {
  const { user } = useAuth()
  const history = useHistory()
  const analytics = useAnalytics()

  if (!user) {
    return null
  }

  const handleLogout = () => {
    history.push('/auth/logout')

    analytics.track('User Logout')
  }

  const username = getUserName(user)
  const email = getPrimaryEmail(user)

  return (
    <FlexRow alignItems="center" justifyContent="space-between" width="100%">
      <FlexRow>
        <Avatar name={username} mr="medium" />
      </FlexRow>
      <FlexColumn maxWidth="65%">
        <Text truncate title={username}>
          {username}
        </Text>
        <Text fontSize="small" color="neutral.0" truncate title={email}>
          {email}
        </Text>
      </FlexColumn>
      <Icon color="neutral.0" name="tray-arrow-right" size="small" onClick={handleLogout} />
    </FlexRow>
  )
}
