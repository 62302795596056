import styled from 'styled-components'
import { compose, variant, color, typography, space } from 'styled-system'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      color: 'neutral.8',
      '::marker': {
        color: 'foreground.3',
        fontSize: 'xlarge',
      },
    },
    primary: {
      color: 'primary.0',
    },
    danger: {
      color: 'danger.0',
    },
    background: {
      color: 'background.0',
    },
  },
})

const ListItem = styled.li`
  ${compose(kind, color, typography, space)}
`

ListItem.defaultProps = {
  kind: 'normal',
  mb: 'small',
  lineHeight: 'small',
}

export default ListItem
