import styled from 'styled-components'
import { compose, space, typography, color, layout, flexbox } from 'styled-system'
import React from 'react'

const StyledList = styled.ul`
  ${compose(color, flexbox, layout, space, typography)};
`

const List = ({ ordered, ...props }) => <StyledList as={ordered ? 'ol' : 'ul'} {...props} />

List.defaultProps = {
  fontSize: 'medium',
  p: 'none',
  mt: 'small',
  mb: 'small',
  ml: 'large',
}

export default List
