import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import ReactCreatableSelect from 'react-select/creatable'
import ReactAsyncSelect from 'react-select/async'
import ReactAsyncCreatableSelect from 'react-select/async-creatable'
import { v4 as uuid } from 'uuid'
import Icon from '../Icon'
import { defaultStyles } from './styles'
import { startCase } from 'lodash'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="angle-down" size="small" color="neutral.2" />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="x" size="xsmall" color="neutral.2" />
    </components.ClearIndicator>
  )
}

function buildV3CompatProps(key, v1Props) {
  const { value, styles, ...compatProps } = v1Props

  compatProps.value = value === '' ? null : value

  // V3 seems to have an issue with re-rendering when the value
  // is set to null or '', so use a key w/ the value to force a render
  compatProps.key = `${key}-${JSON.stringify(compatProps.value)}`

  // styles
  compatProps.styles = { ...defaultStyles(v1Props), ...styles }

  compatProps.components = { DropdownIndicator, ClearIndicator }

  // make selects work in modals and other close quarter
  // situations by using a portal
  compatProps.menuPortalTarget = document.body

  if ('disabled' in v1Props) {
    compatProps.isDisabled = v1Props.disabled
  }

  return compatProps
}

const Select = ({ isCreatable, isAsync, isSimple, ...rest }) => {
  // create a unique value for the key and save it in state
  const [key] = useState(uuid())

  // map old API to new one for backwards compatibility
  const compatProps = buildV3CompatProps(key, rest)

  const simpleProps = {}

  if (isSimple) {
    const { value, onChange } = rest
    simpleProps.getOptionLabel = (o) => o.label
    simpleProps.getOptionValue = (o) => o.value
    simpleProps.isOptionDisabled = (o) => o.disabled
    simpleProps.value = { value, label: startCase(value) }
    simpleProps.onChange = (o) => onChange(o.value)
  }

  if (isAsync && isCreatable) {
    return <ReactAsyncCreatableSelect {...compatProps} {...simpleProps} />
  }

  if (isAsync) {
    return <ReactAsyncSelect {...compatProps} {...simpleProps} />
  }

  if (isCreatable) {
    return <ReactCreatableSelect {...compatProps} {...simpleProps} />
  }

  return <ReactSelect {...compatProps} {...simpleProps} />
}

Select.propTypes = {
  isCreatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  isSimple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool, // alias for compatibility with other components
}

export default Select
