/**
 * @generated SignedSource<<500c48f408e0f223c853d9243243be72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReportStatus = "DRAFT" | "ERROR" | "PENDING" | "PROCESSING" | "SUCCESS" | "%future added value";
export type ReportUserFileType = "EXPORT" | "INPUT" | "OUTPUT" | "SOURCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkspaceHeader_report$data = {
  readonly id: string;
  readonly name: string;
  readonly config: any | null;
  readonly reviewStatus: string | null;
  readonly status: ReportStatus;
  readonly reportWorkflowWorkspaces: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
      readonly key: string;
      readonly reportWorkflowWorkspaceSteps: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly status: string | null;
          readonly sections: any | null;
        }>;
      };
    }>;
  };
  readonly reportUserFiles: {
    readonly nodes: ReadonlyArray<{
      readonly type: ReportUserFileType;
      readonly userFile: {
        readonly id: string;
        readonly rowId: any;
        readonly kind: string;
        readonly name: string;
        readonly key: string;
      } | null;
    }>;
  };
  readonly pendingResolutions: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "WorkspaceHeader_report";
};
export type WorkspaceHeader_report$key = {
  readonly " $data"?: WorkspaceHeader_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkspaceHeader_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkspaceHeader_report",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewStatus",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "WORKSPACE_ORDER_ASC"
        }
      ],
      "concreteType": "ReportWorkflowWorkspacesConnection",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspaces",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportWorkflowWorkspace",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "STEP_ORDER_ASC"
                }
              ],
              "concreteType": "ReportWorkflowWorkspaceStepsConnection",
              "kind": "LinkedField",
              "name": "reportWorkflowWorkspaceSteps",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportWorkflowWorkspaceStep",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sections",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportUserFilesConnection",
      "kind": "LinkedField",
      "name": "reportUserFiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportUserFile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserFile",
              "kind": "LinkedField",
              "name": "userFile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "pendingResolutions",
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "updateStatus": "pending"
          }
        }
      ],
      "concreteType": "ReportResolutionsConnection",
      "kind": "LinkedField",
      "name": "reportResolutions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "reportResolutions(condition:{\"updateStatus\":\"pending\"})"
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "3ca1223801361168faf4a384a660ec11";

export default node;
