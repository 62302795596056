import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string } from 'yup'
import { useApi } from 'hooks'
import { createFormValidator } from 'utils/validation'
import { Button, Box, TextInputField, Message } from 'elements'

const validate = createFormValidator({
  email: string().email('Email address is invalid').required('Please enter your email address'),
})

export default function ForgotPassword() {
  const api = useApi()
  const history = useHistory()

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const result = await api.auth.forgotPassword(values)

          if (result.code === 200) {
            history.push(`/auth/reset-password?email=${encodeURIComponent(values.email)}`)
          }
        } catch (error) {
          const { status, message } = error.response.data

          if (status === 'fail') {
            setFieldError('general', message)
          } else {
            setFieldError('general', 'An unknown error occurred.')
          }

          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form>
          {errors.general ? (
            <Message kind="warning" mb="xlarge">
              {errors.general}
            </Message>
          ) : null}
          <Message kind="info" mb="xlarge">
            Enter your email address and we'll help you create a new password.
          </Message>
          <Box mb="xxxlarge">
            <Box mb="xlarge">
              <TextInputField label="Email" name="email" placeholder="email@example.com" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mb="large">
            <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
              {isSubmitting ? 'Sending...' : 'Submit'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
