/**
 * @generated SignedSource<<b29da7075bffe247cadfaa58d7e39765>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StepHeader_reportWorkflowWorkspaceStep$data = {
  readonly id: string;
  readonly stepOrder: number | null;
  readonly status: string | null;
  readonly key: string;
  readonly reportWorkflowWorkspace: {
    readonly id: string;
    readonly workspaceOrder: number | null;
    readonly reportWorkflowWorkspaceSteps: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly key: string;
        readonly name: string | null;
        readonly stepOrder: number | null;
      }>;
    };
    readonly report: {
      readonly id: string;
      readonly reportWorkflowWorkspaces: {
        readonly nodes: ReadonlyArray<{
          readonly id: string;
          readonly workspaceOrder: number | null;
          readonly reportWorkflowWorkspaceSteps: {
            readonly nodes: ReadonlyArray<{
              readonly id: string;
              readonly status: string | null;
              readonly stepOrder: number | null;
            }>;
          };
        }>;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "StepHeader_reportWorkflowWorkspaceStep";
};
export type StepHeader_reportWorkflowWorkspaceStep$key = {
  readonly " $data"?: StepHeader_reportWorkflowWorkspaceStep$data;
  readonly " $fragmentSpreads": FragmentRefs<"StepHeader_reportWorkflowWorkspaceStep">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stepOrder",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workspaceOrder",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "STEP_ORDER_ASC"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StepHeader_reportWorkflowWorkspaceStep",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportWorkflowWorkspace",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspace",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "ReportWorkflowWorkspaceStepsConnection",
          "kind": "LinkedField",
          "name": "reportWorkflowWorkspaceSteps",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportWorkflowWorkspaceStep",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Report",
          "kind": "LinkedField",
          "name": "report",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": "WORKSPACE_ORDER_ASC"
                }
              ],
              "concreteType": "ReportWorkflowWorkspacesConnection",
              "kind": "LinkedField",
              "name": "reportWorkflowWorkspaces",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReportWorkflowWorkspace",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": (v5/*: any*/),
                      "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                      "kind": "LinkedField",
                      "name": "reportWorkflowWorkspaceSteps",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportWorkflowWorkspaceStep",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReportWorkflowWorkspaceStep",
  "abstractKey": null
};
})();

(node as any).hash = "980f60330b3d392324e0e8275dc86fb6";

export default node;
