import React from 'react'
import FlexRow from '../../FlexRow'
import Icon from '../../Icon'
import Text from '../../Text'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function ExpandSubComponentCell(props) {
  const { align, style } = props.column
  const { value } = props.cell
  const { isExpanded } = props.row

  return (
    <FlexRow justifyContent={align ? alignments[align] : 'flex-start'} alignItems="center">
      <Icon name={isExpanded ? 'angle-down' : 'angle-right'} mr="small" />
      <Text fontSize="inherit" color="inherit" {...style?.text}>
        {value}
      </Text>
    </FlexRow>
  )
}
