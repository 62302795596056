import React, { useEffect, useState } from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { keyBy } from 'lodash'
import { FlexColumn, FlexRow, Anchor } from 'elements'
import { useApi, useAuth, useNotifications } from 'hooks'
import TeamSubscriptionCard from './TeamSubscriptionCard'
import FreeSubscriptionCard from './FreeSubscriptionCard'
import EnterpriseSubscriptionCard from './EnterpriseSubscriptionCard'
import FreeTrialCard from './FreeTrialCard'
import StandardSubscriptionCard from './StandardSubscriptionCard'

const CHECKOUT_ERROR = 'There was a problem starting the Stripe checkout process.'
const MANAGE_ERROR = 'There was a problem starting the Stripe customer portal.'

type Props = {
  organization: any
}

export default function OrgBillingContainer(props: Props) {
  const { organization } = props
  const { currentOrg, freeTrial, activeSubscription, billingPlan } = useAuth()
  const api = useApi()
  const notifications = useNotifications()
  const [working, setWorking] = useState(false)

  const data = useFragment(
    graphql`
      fragment OrgBillingContainer_organization on Organization {
        monthlyMetrics {
          name
          value
        }
      }
    `,
    organization,
  )

  const handleCheckout = async () => {
    setWorking(true)

    try {
      const response = await api.stripe.createCheckoutSession({
        organizationId: currentOrg!.rowId,
      })

      if (!response.data?.url) {
        throw new Error(CHECKOUT_ERROR)
      }

      // redirect to stripe
      window.location.replace(response.data.url)
    } catch (error) {
      notifications.error(CHECKOUT_ERROR)
      setWorking(false)
    }
  }

  const handleManage = async () => {
    setWorking(true)

    try {
      const response = await api.stripe.createPortalSession({
        organizationId: currentOrg!.rowId,
      })

      if (!response.data?.url) {
        throw new Error(MANAGE_ERROR)
      }

      // redirect to stripe
      window.location.replace(response.data.url)
    } catch (error) {
      notifications.error(MANAGE_ERROR)
      setWorking(false)
    }
  }

  const keyedMetrics = keyBy(data?.monthlyMetrics, 'name')

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)

    if (query.get('status') === 'success') {
      notifications.success('Plan updated.')
    }

    if (query.get('status') === 'error') {
      notifications.error('Error updating plan.')
      console.error(query.get('error'))
    }
  }, [notifications])

  let Content = (
    <FreeSubscriptionCard working={working} isTrial={freeTrial} handleCheckout={handleCheckout} />
  )

  // display enterprise subscription card even if it has been deleted
  if (billingPlan?.type === 'CUSTOM') {
    Content = <EnterpriseSubscriptionCard metrics={keyedMetrics} />
  } else if (activeSubscription) {
    if (billingPlan?.slug === 'team-monthly') {
      Content = (
        <TeamSubscriptionCard
          working={working}
          metrics={keyedMetrics}
          handleManage={handleManage}
        />
      )
    } else {
      Content = (
        <StandardSubscriptionCard
          working={working}
          metrics={keyedMetrics}
          handleManage={handleManage}
        />
      )
    }
  } else if (freeTrial) {
    Content = <FreeTrialCard working={working} handleCheckout={handleCheckout} />
  }

  return (
    <FlexColumn mb="xxxlarge" alignItems="center">
      <FlexRow>{Content}</FlexRow>
      <Anchor href="https://www.scrutinize.io/pricing" target="_blank">
        Learn More
      </Anchor>
    </FlexColumn>
  )
}
