import React from 'react'
import FlexRow from '../../FlexRow'
import Icon from '../../Icon'
import { useFormikContext } from 'formik'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function DeleteCell(props) {
  const { column, row } = props
  const { align, handleDelete } = column
  const formCtx = useFormikContext()

  if (row.rowProps?.editable === false) {
    return null
  }

  return (
    <FlexRow flex="1" justifyContent={align ? alignments[align] : 'flex-start'}>
      <Icon
        name="trash"
        size="large"
        color="neutral.9"
        onClick={() => handleDelete(props, formCtx)}
      />
    </FlexRow>
  )
}
