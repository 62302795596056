import React, { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from 'elements'
import { useApi, useNotifications } from 'hooks'
import { Box, Button, TextInputField, Card, Text, Spinner } from '../../../elements'
import { Formik, Form } from 'formik'
import ChoiceButton from '../../ui/input/ChoiceButton'

export default function OrgMfaContainer() {
  const api = useApi()
  const notifications = useNotifications()
  const [phoneVerified, setPhoneVerified] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [mfaEnabled, setMfaEnabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    async function getAttributes() {
      try {
        const result = await api.auth.getUserAttributes({})
        if (result.data?.phone_number_verified === 'true') {
          setPhoneVerified(true)
        }
        setMfaEnabled(result.data?.mfa_enabled)
        setLoading(false)
      } catch (e) {
        notifications.error('Something went wrong.')
      }
    }
    getAttributes()
  }, [api.auth, notifications])

  const saveSettings = async () => {
    setSaving(true)
    try {
      const result = await api.auth.updateMfaSettings({ enabled: mfaEnabled })
      if (result.code === 200) {
        notifications.success('Settings saved.')
      } else {
        notifications.error('Something went wrong.')
      }
    } catch (e) {
      notifications.error('Something went wrong.')
    }
    setSaving(false)
  }

  let content

  if (!phoneVerified) {
    content = (
      <FlexColumn justifyContent="center" pt="large" pb="large" alignItems="center">
        <Text fontSize="xxlarge" mb="large">
          Phone verification required
        </Text>
        <Text
          fontSize="large"
          pl="xxxlarge"
          pr="xxxlarge"
          mt="xlarge"
          mb="xlarge"
          color="neutral.3"
          textAlign="center"
        >
          To enable multi-factor authentication when you login, we need to verify the phone number
          on your account.
        </Text>
        <Formik
          initialValues={{ code: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            if (codeSent) {
              // We sent them a code and now they're verifying that it's correct
              try {
                const result = await api.auth.verifyPhone(values)
                if (result.code === 200) {
                  notifications.success('Phone number verified.')
                  setPhoneVerified(true)
                }
              } catch (e) {
                console.error(e)
                notifications.error('Incorrect verification code.')
              }
              setSubmitting(false)
            } else {
              // We haven't sent them a code yet - fire off a text
              try {
                const result = await api.auth.sendPhoneVerification({})
                if (result.code === 200) {
                  notifications.success('A code has been sent to your phone.')
                  setCodeSent(true)
                }
              } catch (e) {
                console.error(e)
                notifications.error('Something went wrong.')
              }
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box mb="small">
                <TextInputField
                  disabled={!codeSent}
                  label="Verification Code"
                  name="code"
                  placeholder="123456"
                />
              </Box>
              <Box display="flex" flexDirection="column" mb="large">
                {codeSent && (
                  <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
                    {isSubmitting ? 'Verifying...' : 'Verify'}
                  </Button>
                )}
                {!codeSent && (
                  <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
                    {isSubmitting ? 'Sending Code...' : 'Send Code'}
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </FlexColumn>
    )
  }

  if (phoneVerified) {
    content = (
      <FlexColumn justifyContent="center" pt="large" pb="large" alignItems="center">
        <Text fontSize="xxlarge" mb="large">
          Multi-factor authentication settings
        </Text>
        <Text
          fontSize="large"
          pl="xxxlarge"
          pr="xxxlarge"
          mt="xlarge"
          mb="small"
          color="neutral.3"
          textAlign="center"
        >
          Enabling multi-factor authentication will help keep your account secure.
        </Text>
        <Text
          fontSize="large"
          pl="xxxlarge"
          pr="xxxlarge"
          mb="xlarge"
          color="neutral.3"
          textAlign="center"
        >
          When enabled, you'll be sent a one-time use code every time you login.
        </Text>
        <FlexRow mb="small">
          <ChoiceButton selected={mfaEnabled} icon="check" onClick={() => setMfaEnabled(true)}>
            Enabled
          </ChoiceButton>
        </FlexRow>
        <FlexRow mb="large">
          <ChoiceButton selected={!mfaEnabled} icon="x" onClick={() => setMfaEnabled(false)}>
            Disabled
          </ChoiceButton>
        </FlexRow>
        <FlexRow mb="large">
          <Button disabled={saving} onClick={saveSettings} kind="primary">
            {saving ? 'Saving...' : 'Save Settings'}
          </Button>
        </FlexRow>
      </FlexColumn>
    )
  }

  if (loading) {
    content = (
      <FlexRow justifyContent="center" width="100%" p="xxxlarge">
        <Spinner size="xxxlarge" />
      </FlexRow>
    )
  }

  return (
    <FlexColumn mb="xxxlarge" alignItems="center">
      <FlexRow>
        <Card
          bg="background.0"
          pb="xxxlarge"
          pt="xxxlarge"
          mb="xxxlarge"
          width="768px"
          flexDirection="column"
        >
          {content}
        </Card>
      </FlexRow>
    </FlexColumn>
  )
}
