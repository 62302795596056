import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useDecision } from '@optimizely/react-sdk'
import ContentHeader from '../../common/ContentHeader'
import { Button, FlexColumn, FlexRow, Text } from 'elements'
import ActiveWorkflowTable from './ActiveWorkflowTable'
import CompletedWorkflowTable from './CompletedWorkflowTable'
import IntegrationModalContainer from '../../integrations/IntegrationModalContainer'
import { useModals } from 'hooks'

type Props = {
  company: any
  includeDeleted: boolean
  onArchiveToggle: Function
}

export default function ActiveWorkflowDashboard(props: Props) {
  const { company, includeDeleted, onArchiveToggle } = props
  const { openModal } = useModals()
  const canViewIds = useDecision('canViewIds')[0].enabled

  const data = useFragment(
    graphql`
      fragment ActiveWorkflowDashboard_company on Company {
        rowId
      }
    `,
    company,
  )

  return (
    <FlexColumn>
      <ContentHeader borderTop={false}>
        <Text textTransform="uppercase" fontWeight="bold">
          Active Workflows
        </Text>
        {canViewIds && <Text>{data.rowId}</Text>}
        <Button onClick={() => openModal!(IntegrationModalContainer, {}, 'full')}>
          Data Integrations
        </Button>
      </ContentHeader>
      <FlexRow mb="xxlarge">
        <ActiveWorkflowTable
          company={company}
          includeDeleted={includeDeleted}
          onArchiveToggle={onArchiveToggle}
        />
      </FlexRow>
      <ContentHeader>
        <Text textTransform="uppercase" fontWeight="bold">
          Completed Workflows
        </Text>
      </ContentHeader>
      <FlexRow>
        <CompletedWorkflowTable company={company} includeDeleted={includeDeleted} />
      </FlexRow>
    </FlexColumn>
  )
}
