import React from 'react'
import { Modal, SuspenseBoundary } from 'elements'
import TransactionModal from './TransactionModal'

type Props = {
  modal: any
  platformTransactionId: string
  reportId: string
}

export default function TransactionModalContainer(props: Props) {
  const { modal, platformTransactionId, reportId } = props

  return (
    <Modal modal={modal}>
      <SuspenseBoundary>
        <TransactionModal
          modal={modal}
          platformTransactionId={platformTransactionId}
          reportId={reportId}
        />
      </SuspenseBoundary>
    </Modal>
  )
}
