import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import { usePage } from 'hooks'
import CompaniesTable from './CompaniesTable'
import { FlexColumn } from 'elements'
import { SortColumn } from '../../../types/table'

const columnSorts: Record<string, string> = {
  Client: 'NAME',
  'Latest Period': 'LATEST_PERIOD_END',
  'Created At': 'CREATED_AT',
}

export const getOrder = (sortCol: SortColumn | null): string => {
  if (!sortCol?.order) {
    return 'NAME_ASC'
  }

  const { column, order } = sortCol

  return `${columnSorts[column]}_${order.toUpperCase()}`
}

export default function CompaniesContainer() {
  const params = useParams<any>()
  const slug = params.orgSlug || ''
  const { setHeader, setPageProps } = usePage()
  const [sortCol, setSortCol] = useState<SortColumn | null>({ column: 'Client', order: 'asc' })

  const data = useLazyLoadQuery<any>(
    graphql`
      query CompaniesContainerQuery($slug: String!, $order: [CompaniesOrderBy!]) {
        organizationBySlug(slug: $slug) {
          name
          id
          ...CompaniesTable_organization
        }
      }
    `,
    { slug, order: getOrder(sortCol) },
  )

  const organization = data?.organizationBySlug

  useEffect(() => {
    if (organization) {
      setHeader({
        crumbs: [{ label: 'Clients', value: 'clients' }],
      })
    }
  }, [setHeader, setPageProps, organization])

  return (
    <FlexColumn>
      <CompaniesTable organization={organization} sortCol={sortCol} onSort={setSortCol} />
    </FlexColumn>
  )
}
