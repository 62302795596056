import React from 'react'
import FlexColumn from '../FlexColumn'
import Icon from '../Icon'
import { Sort } from '../../types/table'

const StyledIcon = ({ active, ...props }: any) => (
  <Icon
    {...props}
    size={active ? 'medium' : 'small'}
    opacity={active ? 1 : 0.7}
    height="small"
    mb={active ? 'none' : 'xxsmall'}
  />
)

type Props = {
  sort?: Sort | null
  onSort?: (sort: Sort | null) => any
}

export default function DataTableSortButton(props: Props) {
  const { sort, onSort } = props

  const handleSort = () => {
    if (!onSort) {
      return
    }

    if (sort === 'asc') {
      onSort('desc')
      return
    }

    if (sort === 'desc') {
      onSort(null)
      return
    }

    onSort('asc')
  }

  return (
    <FlexColumn justifyContent="center" size="20px" onClick={handleSort}>
      {sort !== 'desc' && <StyledIcon name="angle-up" active={sort === 'asc'} />}
      {sort !== 'asc' && <StyledIcon name="angle-down" active={sort === 'desc'} />}
    </FlexColumn>
  )
}
