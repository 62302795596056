/**
 * @generated SignedSource<<94dcd2da5f2b8781e53acfa72b7d0dcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportStatusDetailsContainerQuery$variables = {
  reportRowId: any;
};
export type ReportStatusDetailsContainerQuery$data = {
  readonly report: {
    readonly " $fragmentSpreads": FragmentRefs<"ReportStatusDetails_report">;
  } | null;
};
export type ReportStatusDetailsContainerQuery = {
  variables: ReportStatusDetailsContainerQuery$variables;
  response: ReportStatusDetailsContainerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportRowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "reportRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportStatusDetailsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "report",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReportStatusDetails_report"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportStatusDetailsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "report",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodEnd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowTemplate",
            "kind": "LinkedField",
            "name": "workflowTemplate",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportUserFilesConnection",
            "kind": "LinkedField",
            "name": "reportUserFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFile",
                    "kind": "LinkedField",
                    "name": "userFile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "meta",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportIntegrationsConnection",
            "kind": "LinkedField",
            "name": "reportIntegrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportIntegration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Integration",
                    "kind": "LinkedField",
                    "name": "integration",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3cb09e5ac477bca82ed1c2c9b6b085c3",
    "id": null,
    "metadata": {},
    "name": "ReportStatusDetailsContainerQuery",
    "operationKind": "query",
    "text": "query ReportStatusDetailsContainerQuery(\n  $reportRowId: UUID!\n) {\n  report(rowId: $reportRowId) {\n    ...ReportStatusDetails_report\n    id\n  }\n}\n\nfragment ReportStatusDetails_report on Report {\n  id\n  errors\n  periodStart\n  periodEnd\n  sourceKind\n  workflowTemplate {\n    id\n  }\n  reportUserFiles {\n    nodes {\n      type\n      userFile {\n        id\n        name\n        meta\n      }\n      id\n    }\n  }\n  reportIntegrations {\n    nodes {\n      integration {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b29cf0492d733b603896b01a02eb197";

export default node;
