import React from 'react'
import FlexRow from '../../FlexRow'
import Text from '../../Text'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

function formatValue(value) {
  const amount = parseFloat(value)

  return amount.toLocaleString(undefined, { style: 'percent' })
}

export default function PercentageCell(props) {
  const { value, cellProps } = props.cell
  const { align } = props.column
  const { sectionHeader } = props.row.rowProps

  let hasValue = false
  let percentValue

  if (Number.isFinite(value)) {
    percentValue = formatValue(value)
    hasValue = true
  }

  if (sectionHeader && !hasValue) {
    return null
  }

  return (
    <FlexRow
      flex="1"
      justifyContent={align ? alignments[align] : 'flex-end'}
      onClick={cellProps?.onClick}
    >
      <Text fontSize="inherit" color="inherit">
        {hasValue ? percentValue : '--'}
      </Text>
    </FlexRow>
  )
}
