/**
 * @generated SignedSource<<85ce8f94dd0b07fe5cc0207e929a7128>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportStatus = "DRAFT" | "ERROR" | "PENDING" | "PROCESSING" | "SUCCESS" | "%future added value";
export type RetryReportWithIntegrationInput = {
  integrationId: string;
  reportId: string;
};
export type useRetryReportWithIntegrationMutation$variables = {
  input: RetryReportWithIntegrationInput;
};
export type useRetryReportWithIntegrationMutation$data = {
  readonly retryReportWithIntegration: {
    readonly report: {
      readonly status: ReportStatus;
    };
  } | null;
};
export type useRetryReportWithIntegrationMutation = {
  variables: useRetryReportWithIntegrationMutation$variables;
  response: useRetryReportWithIntegrationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRetryReportWithIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetryReportWithIntegrationPayload",
        "kind": "LinkedField",
        "name": "retryReportWithIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRetryReportWithIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetryReportWithIntegrationPayload",
        "kind": "LinkedField",
        "name": "retryReportWithIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a14660bf3d4976807562aeb9779dc80",
    "id": null,
    "metadata": {},
    "name": "useRetryReportWithIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation useRetryReportWithIntegrationMutation(\n  $input: RetryReportWithIntegrationInput!\n) {\n  retryReportWithIntegration(input: $input) {\n    report {\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f1a2944ce88f05be9b143241708dce4";

export default node;
