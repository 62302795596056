import React from 'react'
import { useFormikContext } from 'formik'
import FlexRow from '../../FlexRow'
import FlexColumn from '../../FlexColumn'
import Select from '../../Select'

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default function SelectCell(props) {
  const { cell, column } = props
  const { align, formProps } = column
  const { handleUpdate, fieldPlaceholder, formatOptionLabel, selectStyles } = formProps
  const { selectOptions, findValue } = cell.cellProps

  const formCtx = useFormikContext()

  const placeholder = fieldPlaceholder || column.Header

  return (
    <FlexRow flex="1" justifyContent={align ? alignments[align] : 'flex-start'} width="100%">
      <FlexColumn width="100%">
        <Select
          value={findValue(cell.value)}
          onChange={(option) => handleUpdate(option.value, props, formCtx)}
          placeholder={placeholder}
          options={selectOptions}
          formatOptionLabel={formatOptionLabel}
          styles={selectStyles}
        />
      </FlexColumn>
    </FlexRow>
  )
}
