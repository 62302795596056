import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FormattedDate } from 'react-intl'
import { FlexRow, Text } from 'elements'

type Props = {
  report: any
}

export default function LastSyncDate(props: Props) {
  const { report } = props

  // fetch report last updated date. Once sync is two-way (push changes and refresh data), we'll
  // need to update this date
  const data = useFragment<any>(
    graphql`
      fragment LastSyncDate_report on Report {
        updatedAt
      }
    `,
    report,
  )

  return (
    <FlexRow width="100%" justifyContent="center" mb="xlarge">
      <Text color="neutral.0" fontSize="small" fontStyle="italic">
        Last data sync: <FormattedDate timeZone="utc" value={data.updatedAt} />
      </Text>
    </FlexRow>
  )
}
