import React from 'react'

export type ModalBehavior = 'semi' | 'full' | 'screen'

export type Modal = {
  modalType: string
  modalId: string
  modalBehavior: ModalBehavior
  modalProps?: object
  isClosing: boolean
  isFocused: boolean
}

export type ContextProps = {
  activeModalsOrder: string[]
  activeModals: Modal[]
  openModal: (
    modalType: Function,
    modalProps?: object,
    modalBehavior?: ModalBehavior,
  ) => Promise<Modal>
  closeModal: (modalId: string) => Promise<void>
  closeAllModals: () => Promise<void>
}

export default React.createContext<Partial<ContextProps>>({})
