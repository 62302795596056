/**
 * @generated SignedSource<<fa29300eaf9c6168dd650fad463ac2bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ResolutionModalQuery$variables = {
  slug: string;
  platformCompanyId?: string | null;
  resolutionId: string;
};
export type ResolutionModalQuery$data = {
  readonly organizationBySlug: {
    readonly integrationItems: {
      readonly nodes: ReadonlyArray<{
        readonly data: any | null;
      }>;
    };
    readonly integrationAccounts: {
      readonly nodes: ReadonlyArray<{
        readonly data: any | null;
      }>;
    };
    readonly integrationCustomers: {
      readonly nodes: ReadonlyArray<{
        readonly data: any | null;
      }>;
    };
    readonly integrationVendors: {
      readonly nodes: ReadonlyArray<{
        readonly data: any | null;
      }>;
    };
  } | null;
  readonly reportResolutionById: {
    readonly id: string;
    readonly currentValue: any | null;
    readonly initialValue: any | null;
    readonly resolutionKind: string | null;
    readonly status: string | null;
    readonly updateStatus: string | null;
    readonly anomalyTypes: ReadonlyArray<any | null> | null;
    readonly responseData: any | null;
  } | null;
};
export type ResolutionModalQuery = {
  variables: ResolutionModalQuery$variables;
  response: ResolutionModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platformCompanyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resolutionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "platformCompanyId",
        "variableName": "platformCompanyId"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "resolutionId"
    }
  ],
  "concreteType": "ReportResolution",
  "kind": "LinkedField",
  "name": "reportResolutionById",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initialValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolutionKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anomalyTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "responseData",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v5/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResolutionModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationItemsConnection",
            "kind": "LinkedField",
            "name": "integrationItems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationAccountsConnection",
            "kind": "LinkedField",
            "name": "integrationAccounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationAccount",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationCustomersConnection",
            "kind": "LinkedField",
            "name": "integrationCustomers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationCustomer",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationVendorsConnection",
            "kind": "LinkedField",
            "name": "integrationVendors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationVendor",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ResolutionModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationItemsConnection",
            "kind": "LinkedField",
            "name": "integrationItems",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationAccountsConnection",
            "kind": "LinkedField",
            "name": "integrationAccounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationAccount",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationCustomersConnection",
            "kind": "LinkedField",
            "name": "integrationCustomers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationCustomer",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "IntegrationVendorsConnection",
            "kind": "LinkedField",
            "name": "integrationVendors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IntegrationVendor",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "1a06e0edd7480c61a45bff12aa124b64",
    "id": null,
    "metadata": {},
    "name": "ResolutionModalQuery",
    "operationKind": "query",
    "text": "query ResolutionModalQuery(\n  $slug: String!\n  $platformCompanyId: String\n  $resolutionId: ID!\n) {\n  organizationBySlug(slug: $slug) {\n    integrationItems(condition: {platformCompanyId: $platformCompanyId}) {\n      nodes {\n        data\n        id\n      }\n    }\n    integrationAccounts(condition: {platformCompanyId: $platformCompanyId}) {\n      nodes {\n        data\n        id\n      }\n    }\n    integrationCustomers(condition: {platformCompanyId: $platformCompanyId}) {\n      nodes {\n        data\n        id\n      }\n    }\n    integrationVendors(condition: {platformCompanyId: $platformCompanyId}) {\n      nodes {\n        data\n        id\n      }\n    }\n    id\n  }\n  reportResolutionById(id: $resolutionId) {\n    id\n    currentValue\n    initialValue\n    resolutionKind\n    status\n    updateStatus\n    anomalyTypes\n    responseData\n  }\n}\n"
  }
};
})();

(node as any).hash = "f06aedf4037849f3d2e9355234fc7705";

export default node;
