import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { withTheme } from 'styled-components'

const FullModal = ({ theme, children, title, onClose, isClosing, width, height, ...props }) => {
  return (
    <ReactModal
      contentLabel={title || 'Modal'}
      onRequestClose={onClose}
      isOpen={!isClosing}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc={false} // handled by Modal parent
      style={{
        overlay: {
          backgroundColor: 'rgba(38, 45, 63, 0.8)',
          zIndex: theme.zIndices.fullModal,
          border: 'transparent',
          padding: '80px 20px',
        },
        content: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginBottom: '80px',
          height: height || 'auto',
          width,
          maxWidth: width ? null : '654px',
          maxHeight: 'calc(100vh - 160px)',
          top: '0',
          left: '0',
          right: '0',
          bottom: 'initial',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0',
          boxShadow: '0 2px 8px 2px rgba(0,0,0,0.08)',
          background: '#fff',
          overflow: 'hidden', // content is responsible for its own scrolling behavior
          WebkitOverflowScrolling: 'touch',
          borderRadius: '8px',
          outline: 'none',
          padding: 0, // content is responsible for its own padding
        },
      }}
      {...props}
    >
      {children}
    </ReactModal>
  )
}

FullModal.propTypes = {
  title: PropTypes.string,
  isClosing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  height: PropTypes.string,
}

export default withTheme(FullModal)
