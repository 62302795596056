import React from 'react'
import { useParams, Route } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import { FlexColumn } from 'elements'
import ModulesIntegrations from './ModulesIntegrations'
import CustomSwitch from '../router/CustomSwitch'
import PlaidTrigger from './integrations/plaid/PlaidTrigger'
import StripeTrigger from './integrations/stripe/StripeTrigger'
import QboBulkDeactivateTrigger from './integrations/quickbooks/QboBulkDeactivateTrigger'

export default function ModulesCompanyContainer() {
  const { companyId } = useParams<Record<string, any>>()

  const data = useLazyLoadQuery<any>(
    graphql`
      query ModulesCompanyContainerQuery($companyId: ID!) {
        companyById(id: $companyId) {
          ...ModulesIntegrations_company
          ...PlaidTrigger_company
          ...PlaidHistoryTable_company
          ...QboBulkDeactivateTrigger_company
          ...StripeTrigger_company
          ...StripeHistoryTable_company
        }
      }
    `,
    { companyId },
  )

  if (!data) {
    return null
  }

  return (
    <FlexColumn pt="gigantic">
      <CustomSwitch>
        <Route exact path="/orgs/:orgSlug/modules/companies/:companyId">
          <ModulesIntegrations company={data.companyById} />
        </Route>
        <Route exact path="/orgs/:orgSlug/modules/companies/:companyId/plaid">
          <PlaidTrigger company={data.companyById} />
        </Route>
        <Route exact path="/orgs/:orgSlug/modules/companies/:companyId/stripe">
          <StripeTrigger company={data.companyById} />
        </Route>
        <Route exact path="/orgs/:orgSlug/modules/companies/:companyId/quickbooks">
          <QboBulkDeactivateTrigger company={data.companyById} />
        </Route>
      </CustomSwitch>
    </FlexColumn>
  )
}
