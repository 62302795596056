import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useCompanyCreateMutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        rowId
        id
        name
        organization {
          id
          totalCompanies: companies(includeDeleted: NO) {
            totalCount
          }
          ...CompanySelect_organization
        }
        ...CompaniesTableRow_company
        ...CompanyContextMenu_company
      }
    }
  }
`

const updater = (store, { createCompany }) => {
  const payload = store.getRootField('createCompany')
  const newCompany = payload.getLinkedRecord('company')

  const parentOrgId = createCompany.company.organization.id
  const parentOrg = store.get(parentOrgId)

  const filters = [
    { orderBy: 'NAME_ASC', includeDeleted: 'NO' },
    { orderBy: 'NAME_ASC', includeDeleted: 'YES' },
  ]

  filters.forEach((filter) => {
    const connection = ConnectionHandler.getConnection(
      parentOrg,
      'CompaniesTable_companies',
      filter,
    )

    if (connection) {
      const newEdge = ConnectionHandler.createEdge(store, connection, newCompany, 'CompanyEdge')

      ConnectionHandler.insertEdgeBefore(connection, newEdge)
    }
  })
}

export default function useCompanyCreate() {
  return useMutation(mutation, 'createCompany', { updater })
}
