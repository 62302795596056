import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportDeleteMutation(
    $input: DeleteReportByIdInput!
    $includeDeleted: IncludeDeletedOption
  ) {
    deleteReportById(input: $input) {
      deletedReportId @deleteRecord
      company {
        ...ActiveWorkflowTable_company
        ...CompletedWorkflowTable_company
        ...PeriodSelect_company
      }
    }
  }
`

export default function useReportDelete() {
  return useMutation(mutation, 'deleteReportById')
}
