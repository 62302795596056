import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function DelayRender(props) {
  const delay = parseInt(props.delay, 10) || 0
  const [isReady, setIsReady] = useState(!(delay && delay > 0))
  const [timeoutId, setTimeoutId] = useState(null)

  useEffect(() => {
    if (!isReady && !timeoutId) {
      setTimeoutId(
        setTimeout(() => {
          setIsReady(true)
        }, delay),
      )
    }

    return () => timeoutId && clearTimeout(timeoutId)
  }, [isReady, timeoutId, delay])

  if (isReady) {
    return props.children
  }

  return null
}

DelayRender.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DelayRender
