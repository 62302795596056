import { Document, Packer, Paragraph, TextRun, ImageRun, AlignmentType, PageBreak } from 'docx'
import axios from 'axios'
import { flatten, camelCase } from 'lodash'
import scrutinizeLogo from 'theme/images/scrutinize-logo-256x256.png'
import { SheetData } from '../types'
import { AnomalyReport } from '../../../shared/types'
import { formatReportPeriod } from '../utils/formatting'
import { severityFormatter } from '../utils/xlsx'
import { anomalyInfo, metadataDescriptions } from '../constants'

const DISCLAIMER =
  'The information transmitted herein, including attachments, is intended only for the person(s) or entity to which it is addressed and may contain confidential and/or privileged material. Any review, retransmission, dissemination or other use of, or taking of any action in reliance upon this information by persons or entities other than the intended recipient is prohibited'

const summary = (
  companyName: string,
  period: string,
): string => `In this report, Scrutinize analyzed ${companyName}’s books for the period of ${period} using the ledger system as the primary source of data.
The review is broken into four sections below: General Comments, Flagged Transactions, Insights, and Financial Reports. Each section describes the data points that were reviewed and outlines any associated findings. The reference data for this review can be found in the supplementary Excel file that accompanies this report.
`

// see https://docx.js.org/#/usage
export default async function createReviewDoc(
  anomalyReport: AnomalyReport,
  sheetData: SheetData[],
  xlsxFileName: string,
): Promise<Blob> {
  const logoRes = await axios.get(scrutinizeLogo, {
    responseType: 'arraybuffer',
  })

  const { reportMetadata, reportDetailData } = anomalyReport

  const presentAnomalies = reportDetailData.anomalies.filter((anomaly) =>
    sheetData.find((sheet) => sheet.sheetName === anomaly.anomalyName),
  )

  const anomalyParagraphs = flatten(
    presentAnomalies.map((anomaly) => {
      const anomalyConfig = sheetData.find((sheet) => sheet.sheetName === anomaly.anomalyName)!
        .dataSections[0].config

      return [
        new Paragraph({ text: anomaly.anomalyName, style: 'heading3' }),
        new Paragraph({
          style: 'default',
          children: [
            new TextRun({ text: 'Description: ', italics: true }),
            new TextRun(anomalyInfo[camelCase(anomaly.anomalyName)]?.meaning || ''),
          ],
          spacing: {
            after: 150,
          },
        }),
        new Paragraph({
          style: 'default',
          children: [
            new TextRun({ text: 'Remediation: ', italics: true }),
            new TextRun(anomalyInfo[camelCase(anomaly.anomalyName)]?.remedy || ''),
          ],
          spacing: {
            after: 150,
          },
        }),
        new Paragraph({
          style: 'default',
          children: [
            new TextRun({ text: 'Severity: ', italics: true }),
            new TextRun(severityFormatter(anomalyConfig.severity) || ''),
          ],
          spacing: {
            after: 150,
          },
        }),
        new Paragraph({
          style: 'default',
          children: [
            new TextRun({ text: 'Count: ', italics: true }),
            new TextRun(anomaly.transactions?.length?.toString() || ''),
          ],
          spacing: {
            after: 150,
          },
        }),
        new Paragraph({
          style: 'default',
          children: [
            new TextRun({ text: 'Data: ' }),
            new TextRun({ text: xlsxFileName, italics: true }),
          ],
          spacing: {
            after: 420,
          },
        }),
      ]
    }),
  )

  const unusedEntitiesSheets = sheetData.filter(
    (sheet) => sheet.sheetName.includes('Unused') && sheet.dataSections[0].data?.length,
  )

  const unusedParagraphs = flatten(
    unusedEntitiesSheets.map((sheet) => [
      new Paragraph({ text: sheet.sheetName, style: 'heading3' }),
      new Paragraph({
        style: 'default',
        children: [
          new TextRun({ text: 'Description: ', italics: true }),
          new TextRun(metadataDescriptions[camelCase(sheet.sheetName)] || ''),
        ],
      }),
      new Paragraph({
        style: 'default',
        children: [
          new TextRun({ text: 'Count: ', italics: true }),
          new TextRun({ text: sheet.dataSections[0].data!.length.toString() }),
        ],
      }),
    ]),
  )

  const reportParagraphs = [
    new Paragraph({ text: 'Accounts Receivable Aging', style: 'heading3' }),
    new Paragraph({ text: 'Accounts Payable Aging', style: 'heading3' }),
    new Paragraph({ text: 'Balance Sheet Variance', style: 'heading3' }),
    new Paragraph({ text: 'Profit & Loss Variance', style: 'heading3' }),
  ]

  const styles = {
    paragraphStyles: [
      {
        id: 'heading1',
        paragraph: {
          alignment: AlignmentType.CENTER,
          spacing: {
            after: 420,
          },
        },
        run: {
          font: 'Calibri',
          size: 100,
          bold: true,
        },
      },
      {
        id: 'heading2',
        paragraph: {
          spacing: {
            after: 420,
          },
        },
        run: {
          font: 'Calibri',
          size: 50,
          bold: true,
        },
      },
      {
        id: 'heading3',
        run: {
          font: 'Calibri',
          size: 40,
          bold: true,
        },
      },
      {
        id: 'heading4',
        paragraph: {
          alignment: AlignmentType.CENTER,
          spacing: {
            after: 320,
          },
        },
        run: {
          font: 'Calibri',
          size: 40,
        },
      },
      {
        id: 'default',
        paragraph: {
          spacing: {
            after: 320,
          },
        },
        run: {
          font: 'Calibri',
          size: 26,
        },
      },
    ],
  }

  const sections = [
    {
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: new Uint8Array(logoRes.data),
              transformation: { width: 300, height: 300 },
            }),
          ],
          style: 'heading1',
        }),
        new Paragraph({ text: 'Scrutinize', style: 'heading1' }),
        new Paragraph({
          text: 'Assess Review',
          style: 'heading2',
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({ text: 'Client Name:', style: 'heading3', alignment: AlignmentType.CENTER }),
        new Paragraph({ text: reportMetadata.companyName, style: 'heading4' }),
        new Paragraph({ text: 'Period:', style: 'heading3', alignment: AlignmentType.CENTER }),
        new Paragraph({
          text: formatReportPeriod(reportMetadata.reportPeriod) || '',
          style: 'heading4',
          spacing: {
            after: 1200,
          },
        }),
        new Paragraph({
          children: [new TextRun({ text: DISCLAIMER, italics: true, size: 24 }), new PageBreak()],
        }),
        new Paragraph({ text: 'Summary', style: 'heading2' }),
        new Paragraph({
          text: summary(
            reportMetadata.companyName,
            formatReportPeriod(reportMetadata.reportPeriod) || '',
          ),
          style: 'default',
        }),
        new Paragraph({
          text: 'General Comments',
          style: 'heading2',
        }),
        new Paragraph({
          text: 'Flagged Transactions',
          style: 'heading2',
          spacing: {
            after: 320,
          },
        }),
        ...anomalyParagraphs,
        new Paragraph({
          text: 'Insights',
          style: 'heading2',
        }),
        ...unusedParagraphs,
        new Paragraph({
          text: 'Financial Reports',
          style: 'heading2',
        }),
        ...reportParagraphs,
      ],
    },
  ]

  const doc = new Document({
    styles,
    sections,
  })

  return Packer.toBlob(doc)
}
