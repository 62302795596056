/**
 * @generated SignedSource<<3649e3ea1f4ad5b602a7be496b9a66be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveReportInput = {
  reportId: string;
};
export type useReportArchiveMutation$variables = {
  input: ArchiveReportInput;
};
export type useReportArchiveMutation$data = {
  readonly archiveReport: {
    readonly report: {
      readonly id: string;
      readonly rowId: any;
      readonly company: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"PeriodSelect_company">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"WorkflowTableRow_report">;
    };
  } | null;
};
export type useReportArchiveMutation = {
  variables: useReportArchiveMutation$variables;
  response: useReportArchiveMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "uniqueByPeriod": true
    }
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodStart",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodEnd",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReportArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveReportPayload",
        "kind": "LinkedField",
        "name": "archiveReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PeriodSelect_company"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WorkflowTableRow_report"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReportArchiveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveReportPayload",
        "kind": "LinkedField",
        "name": "archiveReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Report",
            "kind": "LinkedField",
            "name": "report",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ReportsConnection",
                    "kind": "LinkedField",
                    "name": "filteredReports",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Report",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "filteredReports(first:100,input:{\"uniqueByPeriod\":true},orderBy:\"CREATED_AT_DESC\")"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": [
                      "input",
                      "orderBy"
                    ],
                    "handle": "connection",
                    "key": "PeriodSelect_filteredReports",
                    "kind": "LinkedHandle",
                    "name": "filteredReports"
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workflowSummary",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "config",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFilesConnection",
                "kind": "LinkedField",
                "name": "reportUserFiles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportUserFile",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserFile",
                        "kind": "LinkedField",
                        "name": "userFile",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportIntegrationsConnection",
                "kind": "LinkedField",
                "name": "reportIntegrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportIntegration",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Integration",
                        "kind": "LinkedField",
                        "name": "integration",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "WORKSPACE_ORDER_ASC"
                  }
                ],
                "concreteType": "ReportWorkflowWorkspacesConnection",
                "kind": "LinkedField",
                "name": "reportWorkflowWorkspaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportWorkflowWorkspace",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "STEP_ORDER_ASC"
                          }
                        ],
                        "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                        "kind": "LinkedField",
                        "name": "reportWorkflowWorkspaceSteps",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportWorkflowWorkspaceStep",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sections",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71381429ea5ca4955ab3591bbcffeffe",
    "id": null,
    "metadata": {},
    "name": "useReportArchiveMutation",
    "operationKind": "mutation",
    "text": "mutation useReportArchiveMutation(\n  $input: ArchiveReportInput!\n) {\n  archiveReport(input: $input) {\n    report {\n      id\n      rowId\n      company {\n        id\n        ...PeriodSelect_company\n      }\n      ...WorkflowTableRow_report\n    }\n  }\n}\n\nfragment PeriodSelect_company on Company {\n  id\n  filteredReports(input: {uniqueByPeriod: true}, orderBy: CREATED_AT_DESC, first: 100) {\n    edges {\n      node {\n        periodStart\n        periodEnd\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ReportContextMenu_report on Report {\n  rowId\n  id\n  name\n  status\n  deletedAt\n  config\n  company {\n    id\n  }\n  reportUserFiles {\n    nodes {\n      type\n      userFile {\n        id\n        rowId\n        kind\n        name\n        key\n      }\n      id\n    }\n  }\n  reportIntegrations {\n    nodes {\n      integration {\n        id\n        owner {\n          id\n        }\n      }\n      id\n    }\n  }\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      key\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          key\n          sections\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment WorkflowTableRow_report on Report {\n  rowId\n  id\n  name\n  status\n  workflowSummary\n  periodStart\n  periodEnd\n  deletedAt\n  ...ReportContextMenu_report\n}\n"
  }
};
})();

(node as any).hash = "2b8dfd02f943eb97b5fdd7e0ae2a672d";

export default node;
