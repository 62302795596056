import React from 'react'
import { ResolutionStatus } from '../components/report/types'
import { Tab } from '../shared/types'

type PageProps = {
  resolutionSection?: ResolutionStatus
  resolutionAnomalyView?: string
  activeCompanyCount?: number
  settingsSections?: any[]
  configurationTabs?: Tab[]
  configurationSection?: string
  activeWorkspaceKey?: string
  activeStepKey?: string
}

export type ContextProps = {
  pageProps: PageProps
  setPageProps: (pageProps: PageProps) => void
  // deprecated
  header: any
  setHeader: (header: any) => void
}

const defaultContext = {
  pageProps: {},
  setPageProps: () => {},
  header: null,
  setHeader: () => {},
}

export default React.createContext<ContextProps>(defaultContext)
