import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { graphql, useFragment } from 'react-relay/hooks'
import { FlexRow, FlexColumn, Progress, Table as Tables, Text, Icon } from 'elements'
import { formatReportPeriod } from '../../report/utils/formatting'
import ReportContextMenu from '../../report/list/ReportContextMenu'
import ReportWatcher from '../../report/ReportWatcher'

const { Row, Cell } = Tables.Card

type Props = {
  report: any
  includeDeleted: boolean
}

type WorkingState = {
  label: string
  progress: number
}

export default function WorkflowTableRow(props: Props) {
  const { report, includeDeleted } = props
  const { orgSlug, companyId } = useParams<any>()
  const history = useHistory()

  const [working, setWorking] = useState<WorkingState | null>(null)

  const data = useFragment(
    graphql`
      fragment WorkflowTableRow_report on Report {
        rowId
        id
        name
        status
        workflowSummary
        periodStart
        periodEnd
        deletedAt
        ...ReportContextMenu_report
      }
    `,
    report,
  )

  if (!data) {
    return null
  }

  const workflowPath = `/orgs/${orgSlug}/companies/${companyId}/reports/${data.id}`

  const isArchived = !!data.deletedAt

  const cellProps = {
    kind: isArchived ? 'inactive' : undefined,
  }

  const rowProps = {
    interactive: !isArchived,
    onClick: !isArchived ? () => history.push(workflowPath) : null,
  }

  let statusCell = null

  if (data.status === 'ERROR') {
    statusCell = (
      <FlexRow color="danger.0" alignItems="center">
        <Icon name="exclamation-circle" size="medium" mr="small" />
        <Text color="inherit">Error</Text>
      </FlexRow>
    )
  } else if (working) {
    statusCell = (
      <FlexColumn>
        <Text>{working.label}</Text>
        <Progress progress={working.progress} />
      </FlexColumn>
    )
  }

  return (
    <>
      <ReportWatcher reportId={data.id} />
      <Row {...rowProps}>
        <Cell {...cellProps}>
          <FlexRow alignItems="center">
            <Text fontSize="large" fontWeight="bold" truncate title={data.name} lineHeight="large">
              {data.name}
            </Text>
          </FlexRow>
        </Cell>
        <Cell {...cellProps}>
          <FlexRow width="260px" alignItems="center">
            <FlexRow mr="small" width={data.workflowSummary.completion !== 100 && '60%'}>
              {data.workflowSummary.completion === 100 ? (
                <Icon name="solid-check-circle" color="primary.0" />
              ) : (
                <Progress progress={data.workflowSummary.completion} />
              )}
            </FlexRow>
            <Text color={data.workflowSummary.completion === 100 ? 'primary.0' : 'foreground.0'}>
              {data.workflowSummary.completion}%
            </Text>
          </FlexRow>
        </Cell>
        <Cell {...cellProps}>
          <Text color="neutral.0">
            {formatReportPeriod({
              startDate: data.periodStart,
              endDate: data.periodEnd,
            })}
          </Text>
        </Cell>
        <Cell {...cellProps}>{statusCell}</Cell>
        <Cell {...cellProps}>
          <FlexRow justifyContent="flex-end">
            <ReportContextMenu
              report={data}
              setWorking={setWorking}
              includeDeleted={includeDeleted}
            />
          </FlexRow>
        </Cell>
      </Row>
    </>
  )
}
