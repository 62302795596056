import React, { useMemo } from 'react'
import { isNil } from 'lodash'
import { FlexColumn, TableConnected, Table, Text } from 'elements'
import EmptyState from '../../../common/EmptyState'
import { DataSection } from '../../types'
import { StyleProps } from 'types/style'
import { getColumns } from '../../utils/tables'

const defaultEmptyMessage = 'No data to display.'

type Props = {
  dataSection: DataSection
  styleProps?: StyleProps
}

export default function ReportTable(props: Props) {
  const { dataSection, styleProps } = props
  const { data, mergeColumns, tableOptions, title } = dataSection

  // table config (select columns) defaults to true for non-preview tables
  if (tableOptions && isNil(tableOptions.includeConfig) && !tableOptions.preview) {
    tableOptions.includeConfig = true
  }

  // without useMemo, mapping over the columns inside getColumns causes an infinite loop
  const columns = useMemo(
    () =>
      getColumns({
        dataObj: data?.[0] || {},
        columns: dataSection.columns,
        mergeCols: mergeColumns,
      }),
    [data, dataSection.columns, mergeColumns],
  )

  const options = useMemo(
    () => ({
      ...tableOptions,
      emptyState: () => (
        <EmptyState message={tableOptions?.emptyMessage || defaultEmptyMessage} fontSize="xlarge" />
      ),
    }),
    [tableOptions],
  )

  return (
    <FlexColumn {...props} {...styleProps?.boxProps}>
      <Text fontSize="large" fontWeight="bold" mb="large" ml="xsmall">
        {title}
      </Text>
      <TableConnected
        table={tableOptions?.kind || Table.Data}
        columns={columns}
        data={data}
        tableOptions={options}
      />
    </FlexColumn>
  )
}
