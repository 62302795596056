/**
 * @generated SignedSource<<3bb467e68f51c07ba681d3d47b7e1ec6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RestoreCompanyInput = {
  companyId: string;
};
export type useCompanyRestoreMutation$variables = {
  input: RestoreCompanyInput;
};
export type useCompanyRestoreMutation$data = {
  readonly restoreCompany: {
    readonly company: {
      readonly id: string;
      readonly deletedAt: any | null;
      readonly organization: {
        readonly id: string;
        readonly totalCompanies: {
          readonly totalCount: number;
        };
        readonly " $fragmentSpreads": FragmentRefs<"CompanySelect_organization">;
      } | null;
    };
  } | null;
};
export type useCompanyRestoreMutation = {
  variables: useCompanyRestoreMutation$variables;
  response: useCompanyRestoreMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "includeDeleted",
  "value": "NO"
},
v5 = {
  "alias": "totalCompanies",
  "args": [
    (v4/*: any*/)
  ],
  "concreteType": "CompaniesConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "companies(includeDeleted:\"NO\")"
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "NAME_ASC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompanyRestoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RestoreCompanyPayload",
        "kind": "LinkedField",
        "name": "restoreCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompanySelect_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompanyRestoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RestoreCompanyPayload",
        "kind": "LinkedField",
        "name": "restoreCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "CompaniesConnection",
                    "kind": "LinkedField",
                    "name": "companies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompaniesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "companies(first:100,includeDeleted:\"NO\",orderBy:\"NAME_ASC\")"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": [
                      "orderBy",
                      "includeDeleted"
                    ],
                    "handle": "connection",
                    "key": "CompanySelect_companies",
                    "kind": "LinkedHandle",
                    "name": "companies"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c7f06e6b8a215a49293974092c9c39d",
    "id": null,
    "metadata": {},
    "name": "useCompanyRestoreMutation",
    "operationKind": "mutation",
    "text": "mutation useCompanyRestoreMutation(\n  $input: RestoreCompanyInput!\n) {\n  restoreCompany(input: $input) {\n    company {\n      id\n      deletedAt\n      organization {\n        id\n        totalCompanies: companies(includeDeleted: NO) {\n          totalCount\n        }\n        ...CompanySelect_organization\n      }\n    }\n  }\n}\n\nfragment CompanySelect_organization on Organization {\n  companies(orderBy: NAME_ASC, includeDeleted: NO, first: 100) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14489a4268c665db852082a4af686316";

export default node;
