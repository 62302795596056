/**
 * @generated SignedSource<<380c5b80e08ca293652766e42670399d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LastSyncDate_report$data = {
  readonly updatedAt: any;
  readonly " $fragmentType": "LastSyncDate_report";
};
export type LastSyncDate_report$key = {
  readonly " $data"?: LastSyncDate_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"LastSyncDate_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LastSyncDate_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "064ba5f8bd40129135cb0ce8816e7bfa";

export default node;
