import React from 'react'
import { FlexColumn, Image, Text } from 'elements'
import gearsImg from 'theme/images/loading-gears.gif'

export default function ReportWorking() {
  return (
    <FlexColumn pt="xxxlarge" width="100%" height="100%" alignItems="center">
      <Text fontSize="xlarge" fontWeight="bold" mb="xxxlarge">
        Hang tight while we fetch the latest data for you!
      </Text>
      <Image src={gearsImg} />
    </FlexColumn>
  )
}
