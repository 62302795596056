import React, { lazy } from 'react'
import { merge } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'
import { SimpleSeries } from 'types/chart'
import { ApexOptions } from 'apexcharts'

const Chart = lazy(() => import('react-apexcharts'))

type Props = {
  series: SimpleSeries
  options?: Record<string, any>
  width?: string
  height?: string
  legendTitle?: string
  titleOffsetX?: number
  titleOffsetY?: number
  legendWidth?: number
  legendOffsetY?: number
}

const donutSizes = theme.chartSizes.donut

export default function DonutChart(props: Props) {
  const {
    series,
    options = {},
    width = donutSizes.chartWidth,
    height = donutSizes.chartHeight,
    legendTitle,
    titleOffsetX = donutSizes.titleOffsetX,
    titleOffsetY = donutSizes.titleOffsetY,
    legendWidth = donutSizes.legendWidth,
    legendOffsetY = donutSizes.legendOffsetY,
  } = props

  const defaultOptions: ApexOptions = {
    legend: {
      show: true,
      position: 'right',
      itemMargin: {
        vertical: 5,
      },
      markers: {
        offsetY: 1,
        offsetX: -5,
      },
    },
    labels: series.map((seriesData) => seriesData.name!),
    plotOptions: {
      pie: {
        donut: {
          size: theme.chartSizes.donut.barWidth.large,
          labels: {
            show: false,
            name: {
              show: true,
            },
            value: {
              show: true,
              fontSize: theme.fontSizes.gigantic,
              fontWeight: theme.fontWeights.bold,
              fontFamily: theme.fontFamily,
              color: theme.colors.foreground[0],
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ['transparent'],
    },
    colors: [
      theme.colors.secondary[6],
      theme.colors.secondary[4],
      theme.colors.secondary[0],
      theme.colors.secondary[7],
      theme.colors.secondary[8],
    ],
  }

  if (legendTitle) {
    defaultOptions.legend!.width = legendWidth
    defaultOptions.legend!.offsetY = legendOffsetY
    defaultOptions.title = {
      text: legendTitle,
      offsetX: titleOffsetX,
      offsetY: titleOffsetY,
    }
  }

  return (
    <Chart
      type="donut"
      width={width}
      height={height}
      options={merge({}, baseChartOptions, defaultOptions, options)}
      // donut chart takes array of numbers for the series
      series={series.map((seriesData) => seriesData.data)}
    />
  )
}
