import React from 'react'
import { startCase } from 'lodash'
import { Select, Text, FlexColumn, FlexRow, Message } from 'elements'
import { getEntityOptions } from '../../utils/resolutions/dropdownOptions'
import { getEntityField } from '../../utils/resolutions/general'
import { Option } from '../../../../shared/types'
import { StyleProps } from '../../../../types/style'

type Props = {
  canEdit: boolean
  customers?: any[]
  vendors?: any[]
  transactionValues: Record<string, any>
  onChange: Function
  styleProps?: StyleProps
}

const entityUpdateFields = {
  transactionEntityName: 'name',
  transactionEntityId: 'id',
  transactionEntityBillAddress: 'billAddress',
  transactionEntityShipAddress: 'shipAddress',
  transactionEntityEmail: 'email',
  transactionEntityType: 'type',
}

export default function TransactionEntity(props: Props) {
  const { canEdit, customers, vendors, transactionValues, onChange, styleProps } = props
  const { transactionEntityType, transactionEntityId, transactionEntityName } = transactionValues

  const entityField = getEntityField(transactionEntityType)
  const entityOptions = canEdit
    ? getEntityOptions({
        entityType: transactionEntityType,
        customers: customers!,
        vendors: vendors!,
      })
    : []

  return (
    <FlexColumn width="320px" {...styleProps?.boxProps}>
      <FlexColumn justifyContent="center">
        <Text fontSize="large" mb="small">
          {entityField}
        </Text>
      </FlexColumn>
      <FlexColumn gridColumn="2" justifyContent="center">
        {canEdit ? (
          // @ts-ignore
          <Select
            value={entityOptions.find((option) => option.value.id === transactionEntityId)}
            onChange={(option: any) => onChange(option, entityUpdateFields)}
            placeholder={entityField}
            options={entityOptions}
            formatOptionLabel={(option: Option, meta: any) => {
              const { label, value } = option
              const { context } = meta

              return (
                <FlexRow justifyContent="space-between">
                  <Text>{label}</Text>
                  {context === 'menu' && (
                    <Text fontStyle="italic" color="neutral.0" ml="small">
                      {startCase(value.type)}
                    </Text>
                  )}
                </FlexRow>
              )
            }}
          />
        ) : (
          <Message area="small">{transactionEntityName || '--'}</Message>
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
