import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { graphql, useFragment, useRelayEnvironment } from 'react-relay/hooks'
import { useStorage, usePage, useAnalytics } from 'hooks'
import { applyConfigReportSections } from '../utils/templates'
import generateReportSections from './insights/sections/generateReportSections'
import { findReportIntegration } from '../utils/resolutions/general'
import { getFlatConfigSections } from '../utils/sections'
import ReportStep from './ReportStep'
import WorkflowDashboard from './WorkflowDashboard'
import ReportStatusDetails from '../list/ReportStatusDetails'
import ReportWorking from './ReportWorking'
import { workingReportStatuses, workingReviewStatuses } from '../constants'
import { ReportSection } from '../types'
import fetchAnomalyReportFile from '../queries/fetchAnomalyReportFile'

type Props = {
  report: any
  step: any
}

export default function ReportViewContainer(props: Props) {
  const { report, step } = props

  const { orgSlug, companyId, reportId } = useParams<any>()
  const storage = useStorage()
  const analytics = useAnalytics()
  const { setHeader } = usePage()
  const [output, setOutput] = useState<any>(null)
  const [loadingPromise, setLoadingPromise] = useState<any>(null)
  const [sections, setSections] = useState<ReportSection[] | null>(null)
  const environment = useRelayEnvironment()

  const reportUrl = `/orgs/${orgSlug}/companies/${companyId}/reports/${reportId}`

  const data = useFragment(
    graphql`
      fragment ReportViewContainer_report on Report {
        id
        rowId
        createdAt
        periodStart
        periodEnd
        config
        status
        reviewStatus
        sourceKind
        ...WorkflowDashboard_report
        ...ReportStatusDetails_report
        reportIntegrations {
          nodes {
            integration {
              id
              kind
            }
          }
        }
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            key
            reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
              nodes {
                id
                key
                sections
              }
            }
          }
        }
      }
    `,
    report,
  )

  const reportWorking =
    workingReportStatuses.includes(data.status) || workingReviewStatuses.includes(data.reviewStatus)

  // only include read-only transaction modals for intuit integrations
  const intuitReportIntegration = findReportIntegration(data?.reportIntegrations, 'intuit')

  // load anomaly report
  useEffect(() => {
    // don't attempt to load file while report is working – it might be missing
    if (reportWorking || data.status === 'ERROR') {
      return
    }

    async function loadFile() {
      const anomalyReportFile = await fetchAnomalyReportFile({ environment, reportId })

      if (!anomalyReportFile) {
        setLoadingPromise(null)
        return
      }

      const file = await storage.get(anomalyReportFile.rowId)

      // display the output
      setOutput(file)

      // track it
      analytics.track('Report Viewed', { reportId: data.rowId })

      setLoadingPromise(null)
    }

    setLoadingPromise(loadFile())
  }, [storage, analytics, reportWorking, data.rowId, environment, reportId, data.status])

  // build report sections
  useEffect(() => {
    if (output) {
      const { reportMetadata } = output

      let configSections = data.config?.sections

      // get flat list of config sections to configure report data. They used to be in a config
      // column on the reports table, but now they're nested in app_public.report_workflow_workspace_steps
      if (data.reportWorkflowWorkspaces?.nodes?.length) {
        configSections = getFlatConfigSections(data)
      }

      const reportSections = generateReportSections({
        report: output,
        configSections,
        sourceKind: data.sourceKind,
        reportId: data.id,
        integrationId: intuitReportIntegration?.integration?.id,
      })

      const finalReportSections = applyConfigReportSections({
        configSections,
        reportSections,
        reportEndDate: reportMetadata.reportPeriod.endDate,
      })

      setSections(finalReportSections)
    }
  }, [output, data, reportUrl, setHeader, intuitReportIntegration])

  if (loadingPromise) {
    // Suspense: throw the promise
    throw loadingPromise
  }

  if (reportWorking) {
    return <ReportWorking />
  }

  if (data.status === 'ERROR') {
    return <ReportStatusDetails report={data} />
  }

  //null check for state
  if (!output || !sections) {
    return null
  }

  if (!step) {
    return <WorkflowDashboard report={data} />
  }

  return <ReportStep sections={sections} report={report} step={step} sourceKind={data.sourceKind} />
}
