import React from 'react'
import { useParams } from 'react-router-dom'
import { pick } from 'lodash'
import ReportContent from './ReportContent'
import { ReportSection, SourceKind } from '../types'
import TransactionDrilldown from './insights/TransactionDrilldown'
import { getFlatSubReportSections } from '../utils/sections'
import NotFound from '../../router/NotFound'
import { FlexColumn } from 'elements'

type Props = {
  route: string
  reportSection: ReportSection
  report: any
  sourceKind: SourceKind
}

/**
 * Reports have subsections.  And those subsections may have subsections.
 * So we recursively loop through them to gather all of them into a single array, which we then
 * map and render all of the routes for.
 */
function getAllSections(
  reportSection: ReportSection,
  route: string,
  parentSection?: ReportSection,
): ReportSection[] {
  let temp: ReportSection[] = []

  reportSection.route = route
  reportSection.parentSection = parentSection
  temp.push(reportSection)

  const subReportSections = getFlatSubReportSections(reportSection)
  subReportSections.forEach((section) => {
    // @ts-ignore
    temp = temp.concat(getAllSections(section, section.route, reportSection))
  })

  return temp
}

type SectionParams = {
  section?: string
  subSection?: string
  subSubSection?: string
}

type FindSectionProps = {
  params: SectionParams
  reportSection: ReportSection
  allReportSections: ReportSection[]
}

// find report section based on url routes (including parent routes because some of the leaf sections are
// just called 'details')
const findReportSection = (props: FindSectionProps): ReportSection | null => {
  const { params, reportSection, allReportSections } = props
  const { section, subSection, subSubSection } = params

  if (!section) {
    return reportSection
  }

  const result = allReportSections.find((listSection) => {
    if (subSubSection) {
      return listSection.route === subSubSection && listSection.parentSection!.route === subSection
    }

    if (subSection) {
      return listSection.route === subSection && listSection.parentSection!.route === section
    }

    return listSection.route === section
  })

  return result || null
}

export default function ReportSectionComponent(props: Props) {
  const { route, reportSection, report, sourceKind } = props
  const params = useParams<any>()
  const sectionParams = pick(params, ['section', 'subSection', 'subSubSection'])

  const allReportSections = getAllSections(reportSection, route)

  const displaySection = findReportSection({
    params: sectionParams,
    reportSection,
    allReportSections,
  })

  if (!displaySection) {
    return (
      <FlexColumn pt="5%">
        <NotFound errorMessage="We couldn't find that section." />
      </FlexColumn>
    )
  }

  if (displaySection.value === 'details') {
    return (
      <TransactionDrilldown
        report={report}
        reportSection={displaySection}
        sourceKind={sourceKind}
      />
    )
  }

  return <ReportContent report={report} reportSection={displaySection} />
}
