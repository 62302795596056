import React from 'react'
import { FlexRow, Text, Image } from 'elements'
import xeroLogo from 'theme/images/xeroLogo.svg'

export default function XeroSignInButton() {
  return (
    <FlexRow p="xsmall" justifyContent="center" alignItems="center">
      <Image size="24px" src={xeroLogo} mr="xsmall" />
      <Text fontSize="xxsmall" color="foreground.1">
        Sign in with Xero
      </Text>
    </FlexRow>
  )
}
