import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportArchiveMutation($input: ArchiveReportInput!) {
    archiveReport(input: $input) {
      report {
        id
        rowId
        company {
          id
          ...PeriodSelect_company
        }
        ...WorkflowTableRow_report
      }
    }
  }
`

const updater = (store, { archiveReport }) => {
  const parentCompId = archiveReport.report.company.id
  const company = store.get(parentCompId)

  // remove from workflows tables when includeDeleted is false
  const activeConnection = ConnectionHandler.getConnection(
    company,
    'ActiveWorkflowsTable_activeReports',
    {
      input: { status: 'active' },
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const completedConnection = ConnectionHandler.getConnection(
    company,
    'CompletedWorkflowsTable_completedReports',
    {
      input: { status: 'completed' },
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const connections = [activeConnection, completedConnection]

  connections.filter(Boolean).forEach((connection) => {
    ConnectionHandler.deleteNode(connection, archiveReport.report.id)
  })
}

export default function useReportArchive() {
  return useMutation(mutation, 'archiveReport', { updater })
}
