/**
 * @generated SignedSource<<c3c8684aa40704e8ffac86d7f9083013>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModulesIntegrations_company$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"PlaidTrigger_company">;
  readonly " $fragmentType": "ModulesIntegrations_company";
};
export type ModulesIntegrations_company$key = {
  readonly " $data"?: ModulesIntegrations_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModulesIntegrations_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModulesIntegrations_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaidTrigger_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "961bfea7bc98d6b56a86337ca82ed9db";

export default node;
