import React from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import ReportStatusDetails from './ReportStatusDetails'

export default function ReportStatusDetailsContainer({ reportRowId, ...props }) {
  const data = useLazyLoadQuery(
    graphql`
      query ReportStatusDetailsContainerQuery($reportRowId: UUID!) {
        report(rowId: $reportRowId) {
          ...ReportStatusDetails_report
        }
      }
    `,
    { reportRowId },
  )

  return <ReportStatusDetails report={data.report} {...props} />
}
