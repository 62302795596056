import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useHistory } from 'react-router-dom'
import { FlexColumn, FlexRow, Image } from 'elements'
import plaidLogo from 'theme/images/plaidLogo.svg'
import stripeLogo from 'theme/images/stripe-logo.svg'
import qbLogo from 'theme/images/qbLogo.svg'
import { useAuth } from 'hooks'

type Props = {
  company: any
}

export default function ModulesIntegrations(props: Props) {
  const { company } = props

  const history = useHistory()
  const { currentOrg } = useAuth()

  const data = useFragment(
    graphql`
      fragment ModulesIntegrations_company on Company {
        id
        ...PlaidTrigger_company
      }
    `,
    company,
  )

  return (
    <FlexColumn pt="gigantic">
      <FlexRow alignItems="center" gap="xxlarge">
        <FlexColumn
          width="250px"
          height="250px"
          border="2px solid"
          borderColor="neutral.2"
          borderRadius="small"
          onClick={() =>
            history.push(`/orgs/${currentOrg!.slug}/modules/companies/${data.id}/plaid`)
          }
        >
          <Image src={plaidLogo} />
        </FlexColumn>
        <FlexColumn
          width="250px"
          height="250px"
          border="2px solid"
          borderColor="neutral.2"
          borderRadius="small"
          justifyContent="center"
          px="large"
          onClick={() =>
            history.push(`/orgs/${currentOrg!.slug}/modules/companies/${data.id}/stripe`)
          }
        >
          <Image src={stripeLogo} />
        </FlexColumn>
        <FlexColumn
          width="250px"
          height="250px"
          border="2px solid"
          borderColor="neutral.2"
          borderRadius="small"
          justifyContent="center"
          px="large"
          onClick={() =>
            history.push(`/orgs/${currentOrg!.slug}/modules/companies/${data.id}/quickbooks`)
          }
        >
          <Image src={qbLogo} />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  )
}
