import { Box } from 'elements'
import { NAV_BAR_WIDTH } from './NavBar'
import PageContent from './PageContent'
import AppBanners from './AppBanners'
import useUserValidate from '../../hooks/app/useUserValidate'

export default function PageBody({ renderHeader, renderContent, ...props }) {
  // logout if no valid user
  useUserValidate()

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      overflow="auto"
      bg="background.4"
      height="100%"
      pl={`${NAV_BAR_WIDTH}px`}
      pb="xlarge"
      {...props}
    >
      <Box position="sticky">
        <AppBanners />
        {renderHeader && renderHeader()}
      </Box>
      <PageContent>{renderContent()}</PageContent>
    </Box>
  )
}
