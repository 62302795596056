import { ConfigSection } from '../../../shared/types'
import { formatReportForExport } from '../export/formatters'
import createReviewDoc from './createReviewDoc'

export function cleanFileName(value: string) {
  return value.replace(/[:/]+/g, '_')
}

export function downloadUrl(url: string, fileName: string) {
  const a = window.document.createElement('a')
  a.href = url

  if (fileName) {
    a.download = cleanFileName(fileName)
  }

  // Append anchor to body.
  document.body.appendChild(a)

  // Initiate download by simulating click
  a.click()

  // Remove anchor from body
  document.body.removeChild(a)
}

export function downloadFile(fileName: string, blob: Blob) {
  const url = window.URL.createObjectURL(blob)
  return downloadUrl(url, fileName)
}

type ExportProps = {
  anomalyReportId: string
  storage: any
  reportName: string
  reportId: string
  setWorking: Function
  configSections: ConfigSection[]
  kind?: 'review'
}

export async function handleReportExport(props: ExportProps) {
  const { anomalyReportId, storage, reportName, setWorking, configSections, reportId, kind } = props

  const working = { label: 'Downloading...', progress: 0 }

  setWorking(working)

  const { exportReport } = await import('../export/exportReport')
  setWorking({ ...working, progress: 20 })

  const output = await storage.get(anomalyReportId)
  setWorking({ ...working, progress: 60 })

  const reportExportData = formatReportForExport(output, reportId, configSections)
  setWorking({ ...working, progress: 80 })

  const xlsxFileName = `Scrutinize - ${reportName}.xlsx`

  if (kind === 'review') {
    const doc = await createReviewDoc(output, reportExportData, xlsxFileName)

    downloadFile(`Scrutinize – ${reportName} Review.docx`, doc)
  }

  const blob = await exportReport(reportExportData)
  setWorking({ ...working, progress: 90 })

  downloadFile(xlsxFileName, blob)
  setWorking({ ...working, progress: 100 })

  setWorking(null)
}
