import React, { useMemo, useEffect, MouseEvent, ReactElement } from 'react'
import { flatten } from 'lodash'
import ReportTable from '../view/common/ReportTable'
import { usePaginationFragment, graphql } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { TableConnected, Button } from 'elements'
import { getTransactionAnomalyCounts } from '../utils/resolutions/data'
import ResolutionRowSubTable from './ResolutionRowSubTable'
import { reportResolutionColumns } from './constants'
import TableEmptyState from '../../common/TableEmptyState'
import { useAnalytics, usePage } from 'hooks'
import { getRowRange } from 'utils/table'
import useReportResolutionsApply from '../resolve/hooks/useReportResolutionsApply'
import { ResolutionStatus, DataSection } from '../types'

const { CheckboxCell } = TableConnected as any

type Props = {
  report: any
  onRowSelect: Function
}

function createSelectColumn() {
  let lastSelectedId = ''

  return {
    accessor: 'select',
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <CheckboxCell {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row, rows, rowsById }: any) => {
      return (
        <CheckboxCell
          {...row.getToggleRowSelectedProps()}
          // allow shift+select to select row ranges
          onClick={(e: MouseEvent<HTMLInputElement>): void => {
            if (e.shiftKey) {
              const rowsToToggle = getRowRange(rows, row.id, lastSelectedId)
              const isCellSelected = rowsById[row.id].isSelected
              rowsToToggle.forEach((r) => r.toggleRowSelected(!isCellSelected))
            } else {
              row.toggleRowSelected()
            }

            lastSelectedId = row.id
          }}
        />
      )
    },
    disableSortBy: true,
    width: '10px',
    noTruncate: true,
  }
}

const DEFAULT_SUCCESS = 'All Transaction Flags in this section have been addressed.'
const STEP_COMPLETE_SUCCESS = 'All Transaction Flags in this period have been addressed.'
const SYNC_SUCCESS =
  'Congrats on working through all the Transaction Flags! Click the button below to sync your changes back to the ledger before moving on.'

export default function ReportResolutionTable(props: Props) {
  const { report, onRowSelect } = props
  const [applyResolutions, applyInFlight]: any = useReportResolutionsApply()
  const analytics = useAnalytics()
  const { stepId } = useParams<any>()

  const { pageProps } = usePage()
  const { resolutionSection, resolutionAnomalyView } = pageProps

  const {
    data,
    refetch,
    hasNext,
    hasPrevious,
    isLoadingNext,
    isLoadingPrevious,
    loadNext,
    loadPrevious,
  } = usePaginationFragment(
    graphql`
      fragment ReportResolutionTable_report on Report
        @refetchable(queryName: "ReportResolutionTableRefetchQuery")
        @argumentDefinitions(
          cursor: { type: "Cursor" }
          count: { type: "Int", defaultValue: 100 }
        ) {
        filteredReportResolutions(
          input: { anomalies: $anomalies, status: $resolutionSection }
          first: $count
          after: $cursor
        ) @connection(key: "ReportResolutionTable_filteredReportResolutions") {
          edges {
            node {
              id
              anomalyTypes
              currentValue
              status
              updateStatus
              responseData
              resolutionKind
            }
          }
        }
        resolutionProgress
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
              nodes {
                id
                status
              }
            }
          }
        }
      }
    `,
    report,
  )

  const resolutions = useMemo(
    () => data.filteredReportResolutions.edges.map((edge: any) => edge.node),
    [data],
  )

  const reportSteps = flatten(
    data.reportWorkflowWorkspaces?.nodes?.map(
      (workspace: any) => workspace?.reportWorkflowWorkspaceSteps?.nodes,
    ),
  )

  const currentStep: any = reportSteps.find((step: any) => step?.id === stepId)

  useEffect(() => {
    refetch(
      {
        anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
        resolutionSection,
      },
      { fetchPolicy: 'store-and-network' },
    )
  }, [resolutionAnomalyView, resolutionSection, refetch])

  const dataSection = useMemo<DataSection>(() => {
    const tableOptions = {
      renderRowSubComponent: (row: any) => {
        return <ResolutionRowSubTable row={row} reportId={data.id} />
      },
      onRowSelect,
      paginationProps: {
        hasNext,
        hasPrevious,
        isLoadingNext,
        isLoadingPrevious,
        loadNext,
        loadPrevious,
      },
      pageSize: 25,
      rowProps: (row: any) => {
        const { resolution } = row.original
        const { status, updateStatus } = resolution

        const props: any = {}

        if (status === 'forReview' && updateStatus === 'error') {
          props.border = 'solid 3px'
          props.borderColor = 'danger.0'
        }

        return props
      },
      initialSortColumns: [{ id: 'transactionDate' }, { id: 'anomalyCounts', desc: true }],
      onRowClick: (row: Record<string, any>, rows: Record<string, any>[]) => {
        rows.forEach((listRow) => {
          // exclude current row or it will stay expanded on click
          if (listRow.isExpanded && listRow.id !== row.id) {
            listRow.toggleRowExpanded()
          }
        })

        row.toggleRowExpanded()
      },
    }

    const columns = [...reportResolutionColumns]

    if (resolutionSection !== 'resolved') {
      columns.unshift(createSelectColumn())
    }

    const resolutionData = resolutions.map((resolution: any) => ({
      ...resolution.currentValue.transactionData,
      anomalyCounts: getTransactionAnomalyCounts(resolution.anomalyTypes),
      anomalyTypes: resolution.anomalyTypes,
      // pass through entire resolution for the modal to use
      resolution,
    }))

    return {
      data: resolutionData,
      columns,
      tableOptions,
    }
  }, [
    resolutionSection,
    data.id,
    resolutions,
    onRowSelect,
    hasNext,
    hasPrevious,
    isLoadingNext,
    isLoadingPrevious,
    loadNext,
    loadPrevious,
  ])

  const handleSync = async () => {
    await applyResolutions(
      { reportId: data.id },
      {
        anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
        resolutionSection,
      },
    )

    analytics.track('Resolutions Synced', { reportId: data.id })
  }

  const stepSuccessful = currentStep?.status === 'complete'
  const resolutionComplete = data.resolutionProgress === 100

  let successMessage = DEFAULT_SUCCESS

  if (resolutionComplete) {
    if (stepSuccessful) {
      successMessage = STEP_COMPLETE_SUCCESS
    } else {
      successMessage = SYNC_SUCCESS
    }
  }

  const sectionEmptyStates: Record<ResolutionStatus, ReactElement> = {
    forReview: (
      <TableEmptyState
        message={successMessage}
        action={
          successMessage === SYNC_SUCCESS && (
            <Button kind="primary" onClick={handleSync} disabled={applyInFlight}>
              {applyInFlight ? 'Syncing...' : 'Sync and Refresh'}
            </Button>
          )
        }
        styleProps={{
          color: 'primary.0',
          fontSize: successMessage === SYNC_SUCCESS ? 'large' : 'xxlarge',
        }}
      />
    ),
    reviewed: (
      <TableEmptyState
        message="No transactions have been marked as No Issue."
        styleProps={{ fontSize: 'xxlarge' }}
      />
    ),
    resolved: (
      <TableEmptyState
        message="No transactions have been Repaired."
        styleProps={{ fontSize: 'xxlarge' }}
      />
    ),
  }

  if (resolutions.length) {
    return <ReportTable dataSection={dataSection} />
  }

  if (resolutionSection) {
    return sectionEmptyStates[resolutionSection]
  }

  return null
}
