import { graphql } from 'react-relay/hooks'
import { ConnectionHandler } from 'react-relay'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useCompanyArchiveMutation($input: ArchiveCompanyInput!, $order: [CompaniesOrderBy!]) {
    archiveCompany(input: $input) {
      company {
        id
        deletedAt
        organization {
          id
          totalCompanies: companies(includeDeleted: NO) {
            totalCount
          }
          ...CompaniesTable_organization
        }
      }
    }
  }
`

const updater = (store, { archiveCompany }) => {
  const parentOrgId = archiveCompany.company.organization.id
  const organization = store.get(parentOrgId)

  // remove from the companies table when filtered to
  // not display deleted companies
  const tableConnection = ConnectionHandler.getConnection(
    organization,
    'CompaniesTable_companies',
    {
      orderBy: 'CREATED_AT_DESC',
      includeDeleted: 'NO',
    },
  )

  const dropdownConnection = ConnectionHandler.getConnection(
    organization,
    'CompanySelect_companies',
    {
      orderBy: 'NAME_ASC',
      includeDeleted: 'NO',
    },
  )

  const connections = [tableConnection, dropdownConnection]

  connections.filter(Boolean).forEach((connection) => {
    ConnectionHandler.deleteNode(connection, archiveCompany.company.id)
  })
}

export default function useCompanyArchive() {
  return useMutation(mutation, 'archiveCompany', { updater })
}
