import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'elements'

export default function SemiModalContainer({
  activeModals,
  closeModal,
  onModalClosed,
  closeAllModals,
  setHistoryBlock,
}) {
  if (!activeModals.length) {
    return null
  }

  return (
    <Box
      position="fixed"
      bottom="0"
      right="0"
      zIndex="10"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      pr="xxxlarge"
    >
      {activeModals.map((activeModal) => {
        const Modal = activeModal.modalType

        return (
          <Modal
            {...activeModal.modalProps}
            key={activeModal.modalId}
            modal={{
              behavior: activeModal.modalBehavior,
              isClosing: activeModal.isClosing,
              isFocused: activeModal.isFocused,
              onClose: () => closeModal(activeModal.modalId),
              onAfterClose: () => onModalClosed(activeModal.modalId),
              setHistoryBlock: (fn) => setHistoryBlock(activeModal.modalId, fn),
              closeAllOtherModals: () => closeAllModals(activeModal.modalId),
            }}
          />
        )
      })}
    </Box>
  )
}

SemiModalContainer.propTypes = {
  activeModals: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  setHistoryBlock: PropTypes.func.isRequired,
}
