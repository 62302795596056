import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useReportRetry() {
  return useMutation(
    graphql`
      mutation useReportRetryMutation($input: RetryReportInput!) {
        retryReport(input: $input) {
          report {
            status
          }
        }
      }
    `,
    'retryReport',
  )
}
