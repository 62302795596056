import React, { ReactElement } from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { filter } from 'lodash'
import { FlexColumn, FlexRow, Text, Table as Tables, Card, Button } from 'elements'
import ReportStatusUnknown from './ReportStatusUnknown'
import ErrorBanner from '../../common/ErrorBanner'
import ConnectionReportStatus from './ConnectionReportStatus'
import MissingDataStatus from './MissingDataStatus'
import ReportSizeStatus from './ReportSizeStatus'
import CellCountStatus from './CellCountStatus'
import { useModals } from 'hooks'
import UploadRetryModal from '../create/UploadRetryModal'
import useRetryReportWithIntegration from '../hooks/useRetryReportWithIntegration'

const { Table, Head, Body, Row, HeadCell, Cell } = Tables.Simple

const MISSING_COL_RATIO = 0.5
const MISSING_COLS_MESSAGE =
  'You seem to be missing many columns. Did you mean to select a different type of upload?'

function filterColumns(userFile: any, props: any) {
  if (!userFile?.meta?.columns) {
    return []
  }

  return filter(userFile.meta.columns, props)
}

function sortBySeverity(userFiles: any[]) {
  const props = { found: false, required: true }
  return userFiles.sort(
    (uf1, uf2) => filterColumns(uf2, props).length - filterColumns(uf1, props).length,
  )
}

function hasMissingRequiredColumns(sourceFiles: any[]) {
  return sourceFiles.some(
    (userFile) => filterColumns(userFile, { found: false, required: true }).length > 0,
  )
}

// map errors to content in order of priority
const ERROR_CONTENT: Record<string, Function> = {
  'response-error': ConnectionReportStatus,
  'query-count-exceeded': ReportSizeStatus,
  'missing-data': MissingDataStatus,
  'cell-count': CellCountStatus,
}

type ReportError = {
  code: string
  message: string
}

const getErrorContent = (
  errors: ReportError[],
  contentProps: Record<string, any>,
): ReactElement | null => {
  for (const error of errors) {
    const Content = ERROR_CONTENT[error.code]

    if (Content) {
      return <Content {...contentProps} />
    }
  }

  return null
}

type Props = {
  report: any
}

export default function ReportStatusDetails(props: Props) {
  const { report } = props

  const { openModal } = useModals()
  const [retryReportWithIntegration, retryInFlight]: any = useRetryReportWithIntegration()

  const data = useFragment(
    graphql`
      fragment ReportStatusDetails_report on Report {
        id
        errors
        periodStart
        periodEnd
        sourceKind
        workflowTemplate {
          id
        }
        reportUserFiles {
          nodes {
            type
            userFile {
              id
              name
              meta
            }
          }
        }
        reportIntegrations {
          nodes {
            integration {
              id
            }
          }
        }
      }
    `,
    report,
  )

  const sourceFiles = sortBySeverity(
    data.reportUserFiles.nodes
      .filter((node: any) => node.type === 'SOURCE')
      .map((node: any) => node.userFile),
  )

  const integration = data?.reportIntegrations?.nodes?.[0]?.integration

  let manyMissingCols = sourceFiles.some((file) => {
    const unfounds = filterColumns(file, { found: false, required: true })
    const requiredColumns = filterColumns(file, { required: true })

    if (requiredColumns.length === 0) {
      return false
    }

    return unfounds.length / requiredColumns.length > MISSING_COL_RATIO
  })

  const handleRetry = () => {
    // source kind is undefined if the error happened at the processReport stage, so use the presence of
    // integration to decide which refresh path to take
    if (integration?.id) {
      return retryReportWithIntegration({ reportId: data.id, integrationId: integration.id })
    }

    openModal!(
      UploadRetryModal,
      {
        workflowTemplateId: data.workflowTemplate.id,
        reportPeriod: { periodStart: data.periodStart, periodEnd: data.periodEnd },
        reportId: data.id,
      },
      'full',
    )

    return
  }

  let content: ReactElement | null = null

  // show missing columns info if we have it in the file meta
  if (hasMissingRequiredColumns(sourceFiles)) {
    content = (
      <FlexColumn p="xxlarge">
        {manyMissingCols ? (
          <FlexRow alignItems="center" justifyContent="space-between" mb="xxlarge">
            <ErrorBanner message={MISSING_COLS_MESSAGE} flex="5" mr="xgigantic" />
            <Button flex="1" onClick={handleRetry}>
              Retry Workflow
            </Button>
          </FlexRow>
        ) : null}
        {sourceFiles.map((file, idx) => {
          const unfounds = filterColumns(file, { found: false })

          if (!unfounds.length) {
            return null
          }

          return (
            <FlexColumn key={file.id} mb="xxlarge">
              <Text mb="medium">{file.name}</Text>
              <FlexColumn px="large">
                <Table>
                  <Head>
                    <Row>
                      <HeadCell>Column</HeadCell>
                      <HeadCell>Status</HeadCell>
                      <HeadCell>Required</HeadCell>
                    </Row>
                  </Head>
                  <Body>
                    {unfounds.map((col: any) => (
                      <Row key={col.name}>
                        <Cell>
                          <Text truncate title={col.name}>
                            {col.name}
                          </Text>
                        </Cell>
                        <Cell>Missing</Cell>
                        <Cell>
                          <Text color={col.required ? 'danger.0' : 'foreground.1'}>
                            {col.required ? 'Yes' : 'No'}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                </Table>
              </FlexColumn>
            </FlexColumn>
          )
        })}
      </FlexColumn>
    )
  } else if (data?.errors?.length) {
    const contentProps = {
      handleRetry: handleRetry,
      submitting: retryInFlight,
      errors: data.errors,
      periodStart: data.periodStart,
      periodEnd: data.periodEnd,
    }

    content = getErrorContent(data.errors, contentProps)
  }

  if (!content) {
    content = <ReportStatusUnknown handleRetry={handleRetry} submitting={retryInFlight} />
  }

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      mx="xgigantic"
      my="gigantic"
      minHeight="450px"
    >
      {content}
    </Card>
  )
}
