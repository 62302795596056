import { useEffect } from 'react'
import useApi from '../data/useApi'
import useAnalytics from './useAnalytics'

export default function useErrorHandler() {
  const analytics = useAnalytics()
  const api = useApi()

  useEffect(() => {
    // set up global error tracking
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      const info = {
        message: msg,
        url,
        lineNo,
        columnNo,
      }

      // track to server
      api.app.reportError(error || {}, info)

      // Somehow `error` is null. Unsure if local development thing or not.
      // This prevents a third error from being logged locally "no property `stack` of null"
      analytics.track!('Client Error', { ...(error || {}), ...info })

      return false
    }

    return () => {
      window.onerror = null
    }
  }, [analytics, api])
}
