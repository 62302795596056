/**
 * @generated SignedSource<<c2326fe5efe9590cf0dd340a062a5a85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModulesCompaniesTableRow_company$data = {
  readonly id: string;
  readonly rowId: any;
  readonly name: string;
  readonly legalName: string | null;
  readonly createdAt: any;
  readonly " $fragmentType": "ModulesCompaniesTableRow_company";
};
export type ModulesCompaniesTableRow_company$key = {
  readonly " $data"?: ModulesCompaniesTableRow_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModulesCompaniesTableRow_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModulesCompaniesTableRow_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "c5a97e807817e132b8fae2b8f7182d04";

export default node;
