/**
 * @generated SignedSource<<45cd73a8074c312b1601c9a5bac0ed79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateCompanyInput = {
  legalName?: string | null;
  name: string;
  organizationId: string;
};
export type useCompanyCreateMutation$variables = {
  input: CreateCompanyInput;
};
export type useCompanyCreateMutation$data = {
  readonly createCompany: {
    readonly company: {
      readonly rowId: any;
      readonly id: string;
      readonly name: string;
      readonly organization: {
        readonly id: string;
        readonly totalCompanies: {
          readonly totalCount: number;
        };
        readonly " $fragmentSpreads": FragmentRefs<"CompanySelect_organization">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"CompaniesTableRow_company" | "CompanyContextMenu_company">;
    };
  } | null;
};
export type useCompanyCreateMutation = {
  variables: useCompanyCreateMutation$variables;
  response: useCompanyCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "Literal",
  "name": "includeDeleted",
  "value": "NO"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": "totalCompanies",
  "args": [
    (v5/*: any*/)
  ],
  "concreteType": "CompaniesConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": "companies(includeDeleted:\"NO\")"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "NAME_ASC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompanyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCompanyPayload",
        "kind": "LinkedField",
        "name": "createCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompanySelect_organization"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompaniesTableRow_company"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompanyContextMenu_company"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompanyCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCompanyPayload",
        "kind": "LinkedField",
        "name": "createCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "CompaniesConnection",
                    "kind": "LinkedField",
                    "name": "companies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompaniesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "companies(first:100,includeDeleted:\"NO\",orderBy:\"NAME_ASC\")"
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "filters": [
                      "orderBy",
                      "includeDeleted"
                    ],
                    "handle": "connection",
                    "key": "CompanySelect_companies",
                    "kind": "LinkedHandle",
                    "name": "companies"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportsConnection",
                "kind": "LinkedField",
                "name": "reports",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Report",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workflowSummary",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "latestReports",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "CREATED_AT_DESC"
                  }
                ],
                "concreteType": "ReportsConnection",
                "kind": "LinkedField",
                "name": "reports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Report",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodEnd",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "reports(orderBy:\"CREATED_AT_DESC\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33b4720a571d0a165cbdaa88c46f47c3",
    "id": null,
    "metadata": {},
    "name": "useCompanyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useCompanyCreateMutation(\n  $input: CreateCompanyInput!\n) {\n  createCompany(input: $input) {\n    company {\n      rowId\n      id\n      name\n      organization {\n        id\n        totalCompanies: companies(includeDeleted: NO) {\n          totalCount\n        }\n        ...CompanySelect_organization\n      }\n      ...CompaniesTableRow_company\n      ...CompanyContextMenu_company\n    }\n  }\n}\n\nfragment CompaniesTableRow_company on Company {\n  id\n  rowId\n  name\n  legalName\n  createdAt\n  deletedAt\n  reports {\n    totalCount\n    nodes {\n      workflowSummary\n      id\n    }\n  }\n  latestReports: reports(orderBy: CREATED_AT_DESC) {\n    nodes {\n      periodStart\n      periodEnd\n      id\n    }\n  }\n}\n\nfragment CompanyContextMenu_company on Company {\n  id\n  deletedAt\n}\n\nfragment CompanySelect_organization on Organization {\n  companies(orderBy: NAME_ASC, includeDeleted: NO, first: 100) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "576697f1c0da7a9b5314db6bb174c937";

export default node;
