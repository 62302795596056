import React, { useEffect } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import { useAuth, usePage } from 'hooks'
import WorkflowConfiguration from './WorkflowConfiguration'

export default function WorkflowConfigurationContainer() {
  const { currentOrg } = useAuth()
  const { setPageProps } = usePage()

  useEffect(() => {
    setPageProps({
      configurationTabs: [
        {
          label: 'Workflows',
          value: 'workflows',
          onSelect() {
            setPageProps({ configurationSection: this.value })
          },
        },
        {
          label: 'Transaction Flags',
          value: 'transactionFlags',
          onSelect() {
            setPageProps({
              configurationSection: this.value,
              activeWorkspaceKey: 'transactionRepair',
              activeStepKey: 'customTransactionFlags',
            })
          },
        },
      ],
      configurationSection: 'workflows',
    })
  }, [setPageProps, currentOrg])

  const data = useLazyLoadQuery<any>(
    graphql`
      query WorkflowConfigurationContainerQuery($orgSlug: String!) {
        organizationBySlug(slug: $orgSlug) {
          ...WorkflowConfiguration_organization
        }
      }
    `,
    { orgSlug: currentOrg!.slug },
  )

  if (!data) {
    return null
  }

  return <WorkflowConfiguration organization={data.organizationBySlug} />
}
