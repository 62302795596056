import React, { ReactElement } from 'react'
import { Modal, SuspenseBoundary } from 'elements'
import ResolutionModal from './ResolutionModal'
import ReadOnlyResolutionModal from './ReadOnlyResolutionModal'

type Props = {
  modal: any
  platformTransactionId?: string
  globalId?: string
  resolutionId?: string
  reportId: string
  integrationId: string
  platformCompanyId?: string
  anomalyView?: string
  canEdit?: boolean
}

export default function ResolutionModalContainer(props: Props) {
  const {
    modal,
    resolutionId,
    platformTransactionId,
    globalId,
    reportId,
    integrationId,
    platformCompanyId,
    anomalyView,
    canEdit = true,
  } = props

  console.log(props)

  let modalProps: Record<string, any> = { modal, resolutionId, reportId }
  let ModalContent: (props: any) => ReactElement | null = ReadOnlyResolutionModal

  if (canEdit) {
    ModalContent = ResolutionModal

    modalProps = { ...modalProps, platformCompanyId, anomalyView }
  } else {
    modalProps = { ...modalProps, platformTransactionId, globalId, integrationId }
  }

  return (
    <Modal modal={modal}>
      <SuspenseBoundary>
        <ModalContent {...modalProps} />
      </SuspenseBoundary>
    </Modal>
  )
}
