import React from 'react'
import { useDecision } from '@optimizely/react-sdk'
import { FlexColumn, SideNavLink } from 'elements'
import { useAuth } from 'hooks'

type LinkProps = {
  path: string
  label: string
  icon: string
}

type OrgNavLinkProps = LinkProps & {
  organization: any
}

const OrgNavLink = (props: OrgNavLinkProps) => {
  const { path, organization, label, icon } = props

  if (!organization) {
    return null
  }

  return (
    <FlexColumn mb="small">
      <SideNavLink to={`/orgs/${organization.slug}/${path}`} label={label} icon={icon} />
    </FlexColumn>
  )
}

export default function AppNavigation() {
  const { currentOrg } = useAuth()
  const modulesEnabled = useDecision('canAccessModules')[0].enabled

  return (
    <FlexColumn>
      <OrgNavLink path="dashboard" label="Home" icon="home" organization={currentOrg} />
      <OrgNavLink
        path="configurations"
        label="Configurations"
        icon="adjustments"
        organization={currentOrg}
      />
      <OrgNavLink path="settings" label="Settings" icon="cog" organization={currentOrg} />
      <SideNavLink
        href="https://scrutinize.freshdesk.com/support/home"
        label="Support"
        icon="chat-bubble"
      />
      {modulesEnabled && (
        <OrgNavLink path="modules" label="Modules" icon="database" organization={currentOrg} />
      )}
    </FlexColumn>
  )
}
