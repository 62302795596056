import CardTableCell from './CardTableCell'
import CardTableRow from './CardTableRow'
import CardTableFootCell from './CardTableFootCell'
import DataTable from './DataTable'
import DataTableCell from './DataTableCell'
import DataTableHeadCell from './DataTableHeadCell'
import DataTableHeadRow from './DataTableHeadRow'
import DataTableRow from './DataTableRow'
import DataTableFootCell from './DataTableFootCell'
import SimpleTable from './SimpleTable'
import SimpleTableBody from './SimpleTableBody'
import SimpleTableCell from './SimpleTableCell'
import SimpleTableFoot from './SimpleTableFoot'
import SimpleTableFootCell from './SimpleTableFootCell'
import SimpleTableHead from './SimpleTableHead'
import SimpleTableHeadCell from './SimpleTableHeadCell'
import SimpleTableRow from './SimpleTableRow'
import CardTableHeadCell from './CardTableHeadCell'

const base = {
  Table: SimpleTable,
  Head: SimpleTableHead,
  HeadRow: SimpleTableRow,
  HeadCell: SimpleTableHeadCell,
  Body: SimpleTableBody,
  Row: SimpleTableRow,
  Cell: SimpleTableCell,
  Foot: SimpleTableFoot,
  FootRow: SimpleTableRow,
  FootCell: SimpleTableFootCell,
}

export default {
  Simple: base,
  Card: {
    ...base,
    Row: CardTableRow,
    Cell: CardTableCell,
    FootCell: CardTableFootCell,
    HeadCell: CardTableHeadCell,
  },
  Data: {
    ...base,
    Table: DataTable,
    HeadRow: DataTableHeadRow,
    HeadCell: DataTableHeadCell,
    Row: DataTableRow,
    Cell: DataTableCell,
    FootCell: DataTableFootCell,
  },
}
