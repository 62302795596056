/**
 * @generated SignedSource<<e2df81ed8c85b54a05d8acbe0d033a2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowConfigurationContainerQuery$variables = {
  orgSlug: string;
};
export type WorkflowConfigurationContainerQuery$data = {
  readonly organizationBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"WorkflowConfiguration_organization">;
  } | null;
};
export type WorkflowConfigurationContainerQuery = {
  variables: WorkflowConfigurationContainerQuery$variables;
  response: WorkflowConfigurationContainerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "orgSlug"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "includeDeleted",
    "value": "NO"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkflowConfigurationContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkflowConfiguration_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkflowConfigurationContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "WorkflowTemplatesConnection",
            "kind": "LinkedField",
            "name": "workflowTemplatesWithDefaults",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowTemplatesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "config",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "organizationId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "workflowTemplatesWithDefaults(first:100,includeDeleted:\"NO\",orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "orderBy",
              "includeDeleted"
            ],
            "handle": "connection",
            "key": "WorkflowConfiguration_workflowTemplatesWithDefaults",
            "kind": "LinkedHandle",
            "name": "workflowTemplatesWithDefaults"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fba07554520f449823d29f6a3e608b72",
    "id": null,
    "metadata": {},
    "name": "WorkflowConfigurationContainerQuery",
    "operationKind": "query",
    "text": "query WorkflowConfigurationContainerQuery(\n  $orgSlug: String!\n) {\n  organizationBySlug(slug: $orgSlug) {\n    ...WorkflowConfiguration_organization\n    id\n  }\n}\n\nfragment WorkflowConfiguration_organization on Organization {\n  workflowTemplatesWithDefaults(first: 100, orderBy: CREATED_AT_DESC, includeDeleted: NO) {\n    edges {\n      node {\n        id\n        name\n        config\n        organizationId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41e3beeedbf2cd46a67cf4e58eacf16a";

export default node;
