import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

export default function useReportAddFile() {
  return useMutation(
    graphql`
      mutation useReportAddFileMutation($input: AddReportUserFileInput!) {
        addReportUserFile(input: $input) {
          reportUserFile {
            type
            reportId
            userFileId
          }
        }
      }
    `,
    'reportUserFile',
  )
}
