import { useApi } from 'hooks'
import { useEffect } from 'react'

export default function useIntegrationVerify(integrationId) {
  const api = useApi()

  // verify integration on mount and if/when integrationId changes
  useEffect(() => {
    if (!integrationId) {
      return
    }

    const verifyIntegration = () => {
      api.integration.verify({ integrationId })
    }

    verifyIntegration()

    // also verify integration when window comes back into focus
    window.addEventListener('focus', verifyIntegration)

    return () => {
      window.removeEventListener('focus', verifyIntegration)
    }
  }, [api, integrationId])
}
