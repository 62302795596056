import React, { Suspense, ReactElement } from 'react'
import FlexRow from '../FlexRow'
import Loading from '../Loading'

type Props = {
  children: any
  fallback?: ReactElement
}

const DEFAULT_FALLBACK = (
  <FlexRow justifyContent="center" width="100%" p="medium">
    {/* @ts-ignore */}
    <Loading />
  </FlexRow>
)

function SuspenseBoundary(props: Props) {
  const { children, fallback = DEFAULT_FALLBACK } = props

  return <Suspense fallback={fallback}>{children}</Suspense>
}

export default SuspenseBoundary
