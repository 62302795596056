import React, { useState } from 'react'
import { Modal, ModalNavHeader, FlexRow, Text, Button, TextInput } from 'elements'
import ModalContent from 'components/app/ModalContent'
import theme from 'theme'
import { Box, Select } from '../../elements'

type Props = {
  modal: any
  title: string
  description: string
  configuration: Record<string, any>
  saveChanges: (config: Record<string, any>) => void
}

export default function AnomalyEditModal(props: Props) {
  const { modal, title, description, configuration, saveChanges } = props
  const [config, setConfig] = useState(configuration)

  const options = [
    { value: 0, label: 'Low' },
    { value: 1, label: 'Medium' },
    { value: 2, label: 'High' },
  ]

  // Get the value for the dropdown
  const severity = config.severity
  let value
  options.forEach((option) => {
    if (option.value === severity) {
      value = option
    }
  })

  const handleChange = (key: string, value: any) => {
    // Only allow whole numbers or empty strings
    if (!/^\d+$/.test(value) && value !== '') {
      return
    }

    let temp = { ...config }
    temp[key] = value
    setConfig(temp)
  }

  const handleSave = () => {
    Object.keys(config).forEach((key) => {
      let value = config[key]
      if (!/^\d+$/.test(value) || value === '') {
        config[key] = 0
      } else {
        config[key] = parseInt(value)
      }
    })

    saveChanges(config)
    modal.onClose()
  }

  return (
    <Modal modal={modal}>
      <ModalNavHeader
        onClose={modal.onClose}
        titleText={title}
        // @ts-ignore
        p="xxxlarge"
        pb="none"
      />
      <ModalContent pl="xxxlarge" pr="xxxlarge" pb="xxlarge" pt="none" mb="large">
        <FlexRow pb="xxlarge" pt="large">
          <Text>{description}</Text>
        </FlexRow>
        <FlexRow width="100%" alignItems="center" mb="xxlarge">
          <Box width="180px">
            <Text fontWeight="bold">Severity</Text>
          </Box>
          <Box width="100%">
            {/* @ts-ignore */}
            <Select
              value={value}
              options={options}
              onChange={(option: any) => handleChange('severity', option.value)}
              styles={{
                width: '100%',
                option: (provided: any, state: any) => ({
                  ...provided,
                  cursor: 'pointer',
                  backgroundColor: state.isFocused ? theme.colors.background[2] : 'transparent',
                }),
              }}
              formatOptionLabel={(option: any) => {
                return <Text>{option.label}</Text>
              }}
            />
          </Box>
        </FlexRow>
        <FlexRow width="100%" alignItems="center" mb="xxlarge">
          <Box width="180px">
            <Text fontWeight="bold">Amount</Text>
          </Box>
          <Box width="100%">
            <TextInput
              area="small"
              value={config.amount}
              onChange={(event: any) => handleChange('amount', event.target.value)}
              color="nav.default"
            />
          </Box>
        </FlexRow>
        <FlexRow width="100%" alignItems="center" mb="xxlarge">
          <Box width="180px">
            <Text fontWeight="bold">Days Elapsed</Text>
          </Box>
          <Box width="100%">
            <TextInput
              area="small"
              value={config.daysElapsed}
              onChange={(event: any) => handleChange('daysElapsed', event.target.value)}
              color="nav.default"
            />
          </Box>
        </FlexRow>
        <FlexRow justifyContent="flex-end" width="100%" mt="xlarge">
          <Button mr="medium" onClick={() => modal.onClose()}>
            Cancel
          </Button>
          <Button kind="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </FlexRow>
      </ModalContent>
    </Modal>
  )
}
