/**
 * @generated SignedSource<<e670016b9a86e366a4061f657165a5d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaidHistoryContextMenu_userFile$data = {
  readonly rowId: any;
  readonly " $fragmentType": "PlaidHistoryContextMenu_userFile";
};
export type PlaidHistoryContextMenu_userFile$key = {
  readonly " $data"?: PlaidHistoryContextMenu_userFile$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaidHistoryContextMenu_userFile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaidHistoryContextMenu_userFile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    }
  ],
  "type": "UserFile",
  "abstractKey": null
};

(node as any).hash = "f3f425108e4d558a0e2f664cba9289c5";

export default node;
