/**
 * @generated SignedSource<<912e476943b8d0204d31752da0097769>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectDataSources_company$data = {
  readonly rowId: any;
  readonly id: string;
  readonly " $fragmentType": "SelectDataSources_company";
};
export type SelectDataSources_company$key = {
  readonly " $data"?: SelectDataSources_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectDataSources_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectDataSources_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "7dbd891a7a2cf687c2972222d2004482";

export default node;
