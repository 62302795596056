import React from 'react'
import PageBody from 'components/app/PageBody'
import OrganizationHeader from 'components/organization/OrganizationHeader'
import CompaniesContainer from 'components/company/list/CompaniesContainer'

export default function OrganizationPage() {
  return (
    <PageBody
      renderHeader={() => <OrganizationHeader />}
      renderContent={() => <CompaniesContainer />}
    />
  )
}
