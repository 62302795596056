import React from 'react'
import { Form, useFormikContext } from 'formik'
import { FlexRow, FlexColumn, Button, Text } from 'elements'
import DatePeriodInput from 'components/ui/input/DatePeriodInput'
import IconInput from '../../../ui/input/IconInput'

export type Values = {
  reportPeriod?: any
  timeZone?: string
}

export default function StripeTriggerForm() {
  const formCtx = useFormikContext()
  const { isSubmitting, setFieldValue } = formCtx
  const values = formCtx.values as Values

  return (
    <Form>
      <FlexRow alignItems="center">
        <FlexColumn mr="xxlarge">
          <Text fontWeight="bold" fontSize="large" mb="large">
            Date
          </Text>
          <FlexColumn mb="large">
            <DatePeriodInput
              value={values.reportPeriod}
              onChange={(opt: any) => setFieldValue('reportPeriod', opt)}
              customInput={<IconInput icon="calendar" styleProps={{ color: 'neutral.0' }} />}
              onTimeZoneChange={(timeZone: string) => {
                setFieldValue('timeZone', timeZone)
              }}
            />
          </FlexColumn>
        </FlexColumn>
        <Button disabled={isSubmitting} type="submit">
          {isSubmitting ? 'Posting...' : 'Post'}
        </Button>
      </FlexRow>
    </Form>
  )
}
