import React from 'react'
import { Route } from 'react-router-dom'
import { Box, Card, Image } from 'elements'
import logo from 'theme/images/scrutinize-logo-text-512x108.png'
import Register from 'components/auth/Register'
import CreateOrganization from 'components/auth/CreateOrganization'
import Login from 'components/auth/Login'
import Logout from 'components/auth/Logout'
import Verify from 'components/auth/Verify'
import ForgotPassword from 'components/auth/ForgotPassword'
import ResetPassword from 'components/auth/ResetPassword'
import CustomSwitch from 'components/router/CustomSwitch'

export default function AuthPage() {
  return (
    <Box
      p="xxxlarge"
      bg="background.1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="100%"
    >
      <Card py="xxlarge" px="xxxlarge" flexDirection="column" width="100%" maxWidth="420px">
        <Box mb="xxlarge" display="flex" alignItems="center" justifyContent="center">
          <Image src={logo} alt="scrutinize logo" width="50%" />
        </Box>
        <CustomSwitch>
          <Route path="/auth/register">
            <Register />
          </Route>
          <Route path="/auth/login">
            <Login />
          </Route>
          <Route path="/auth/logout">
            <Logout />
          </Route>
          <Route path="/auth/verify">
            <Verify />
          </Route>
          <Route path="/auth/create-organization">
            <CreateOrganization />
          </Route>
          <Route path="/auth/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/auth/reset-password">
            <ResetPassword />
          </Route>
        </CustomSwitch>
      </Card>
    </Box>
  )
}
