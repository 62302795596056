import React from 'react'
import { useDecision } from '@optimizely/react-sdk'
import { SuspenseBoundary, Modal } from 'elements'
import IntegrationModal from './IntegrationModal'

type Props = {
  modal: any
  companyId?: string
}

export default function IntegrationModalContainer(props: Props) {
  const { modal, companyId } = props
  const plaidEnabled = useDecision('canCreatePlaidIntegration')[0].enabled

  // larger modal for internal plaid integration use, which needs several cards to display all
  // of the bank connections
  const modalHeight = plaidEnabled ? '675px' : '575px'
  const modalWidth = plaidEnabled ? '900px' : '700px'

  return (
    <Modal width={modalWidth} height={modalHeight} modal={modal}>
      <SuspenseBoundary>
        <IntegrationModal modal={modal} companyId={companyId} />
      </SuspenseBoundary>
    </Modal>
  )
}
