import React from 'react'
import FlexColumn from '../FlexColumn'

export default function Circle(props: any) {
  const { color, size = 'medium', children } = props

  return (
    <FlexColumn
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      bg={color}
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {children}
    </FlexColumn>
  )
}
