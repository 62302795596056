import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { NotFoundPage } from 'pages'

export default function CustomSwitch({ children }: any) {
  return (
    <Switch>
      {children}
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}
