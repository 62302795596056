import React, { useRef } from 'react'
import { FlexColumn, SuspenseBoundary } from 'elements'
import ErrorBoundary from '../common/ErrorBoundary'
import ResetScroll from '../router/ResetScroll'

export default function PageContent({ children, ...props }) {
  const ref = useRef()

  return (
    <FlexColumn flex="1 1 auto" overflow="auto" px="xxlarge" ref={ref} {...props}>
      <ResetScroll current={ref?.current} />
      <ErrorBoundary>
        <SuspenseBoundary>{children}</SuspenseBoundary>
      </ErrorBoundary>
    </FlexColumn>
  )
}
