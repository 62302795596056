import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { Formik } from 'formik'
import TransactionModalInfo from './TransactionModalInfo'

type Props = {
  modal: any
  platformTransactionId: string
  reportId: string
}

export default function TransactionModal(props: Props) {
  const { modal, platformTransactionId, reportId } = props

  // fetch transaction by id and report id
  const data = useLazyLoadQuery<any>(
    graphql`
      query TransactionModalQuery($platformTransactionId: String, $reportId: ID!) {
        transactionByReportAndId(
          input: { platformTransactionId: $platformTransactionId, reportId: $reportId }
        ) {
          id
          transactionData
          transactionType
        }
      }
    `,
    { reportId, platformTransactionId },
  )

  const transaction = data?.transactionByReportAndId

  if (!transaction) {
    return null
  }

  return (
    // empty functions for onSubmit and validate because nothing is being edited here
    <Formik initialValues={transaction.transactionData} onSubmit={() => {}} validate={() => {}}>
      <TransactionModalInfo modal={modal} reportId={reportId} />
    </Formik>
  )
}
