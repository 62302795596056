import React from 'react'
import { groupBy, camelCase, flatten } from 'lodash'
import { standardColumns, codingAnomalies, byAccountAnomalies, agingAnomalies } from '../constants'
import { DataSection, Anomaly, Column, TableOptions, RowClickFunction, SourceKind } from '../types'
import { ConfigSection } from '../../../shared/types'
import { TableConnected, Table } from 'elements'
import ReportTable from '../view/common/ReportTable'
import { applyConfigDataSections } from './templates'
import ResolutionModalContainer from '../resolve/ResolutionModalContainer'
import { getStandardColumns } from './tables'

const { Row, Cell } = Table.Data

type GetDataSectionsProps = {
  anomaly: Anomaly
  context: 'table' | 'export'
  configSections?: ConfigSection[]
  reportEndDate: string
  reportId: string
  integrationId?: string
  sourceKind?: SourceKind
}

type AnomalyTableOptionsProps = {
  anomaly: Anomaly
  columns?: Column[]
  reportId: string
  integrationId?: string
  sourceKind?: SourceKind
}

function getAnomalyTableOptions(props: AnomalyTableOptionsProps): TableOptions {
  const { anomaly, columns, reportId, integrationId, sourceKind } = props

  const name = anomaly.anomalyName || anomaly.reportName

  const resolutionRowClick: RowClickFunction = (row, rows, modalsCtx) => {
    const { openModal } = modalsCtx

    openModal!(
      ResolutionModalContainer,
      {
        platformTransactionId: row.original.transactionId,
        // global id used for missing-type anomalies
        globalId: row.original.globalId,
        reportId,
        integrationId,
        canEdit: false,
      },
      'screen',
    )
  }

  const renderRowSubComponent = (row: any) => {
    if (row.original.data) {
      const dataSection = {
        data: row.original.data,
        columns: columns || getStandardColumns(sourceKind),
        tableOptions: {
          highlightedColumn: 'Name',
          onRowClick: integrationId ? resolutionRowClick : null,
        },
      }

      return (
        <Row bg="background.1">
          <Cell colSpan={row.cells.length}>
            <ReportTable dataSection={dataSection} />
          </Cell>
        </Row>
      )
    }

    return null
  }

  if (codingAnomalies.includes(camelCase(name))) {
    return {
      kind: Table.Card,
      noHeader: true,
      renderRowSubComponent,
      onRowClick: (row: any) => row.toggleRowExpanded(),
    }
  }

  return {
    renderRowSubComponent,
    highlightedColumn: 'Name',
    // exclude qbo ar and ap aging reports because they reference transactions outside of the report period,
    // so they're not in the db
    onRowClick: integrationId && !anomaly.reportName ? resolutionRowClick : null,
  }
}

export function getAnomalyDataSections(props: GetDataSectionsProps): DataSection[] {
  const {
    anomaly,
    context,
    configSections,
    reportEndDate,
    reportId,
    integrationId,
    sourceKind,
  } = props

  const name = (anomaly.anomalyName || anomaly.reportName) as string
  const key = camelCase(name)

  let dataSections: DataSection[] = []

  //for these anomalies, we want the data sections grouped by account
  if (byAccountAnomalies.includes(name)) {
    const transactionsByAccount = groupBy(anomaly.transactions, 'transactionAccount')

    dataSections = Object.keys(transactionsByAccount).map((account) => ({
      data: transactionsByAccount[account],
      title: account,
      key,
      columns: getStandardColumns(sourceKind),
    }))
  } else if (codingAnomalies.includes(camelCase(name))) {
    dataSections = anomaly.data!.map((entity) => {
      const columns = [
        {
          Header: 'Account',
          accessor: 'account',
          //@ts-ignore
          Cell: TableConnected.ExpandSubComponentCell,
          style: { text: { fontWeight: 'bold' } },
        },
        {
          Header: 'Count',
          accessor: 'count',
          //@ts-ignore
          Cell: TableConnected.StandardCell,
        },
      ]

      const section: DataSection = {
        key,
        title: entity.entityName,
        data: entity.accountTransactions.map((account: any) => ({
          account: account.accountName,
          count: account.transactions.length,
          data: account.transactions,
        })),
        columns,
      }

      if (context === 'table') {
        return section
      }

      //for export, return flat list of lines instead of grouped by account
      return { ...section, data: flatten(section.data!.map((datum: any) => datum.data)) }
    })
  } else if (agingAnomalies.includes(camelCase(name))) {
    let columns = [
      ...getStandardColumns(sourceKind),
      {
        Header: 'Days Outstanding',
        accessor: 'daysOutstanding',
        //@ts-ignore
        Cell: TableConnected.NumericCell,
      },
    ]

    // qbo ar and ap aging reports (they have the reportName prop) don't have account info. They also don't
    // have info for the default hidden columns (class, location, tax info)
    if (anomaly.reportName) {
      columns = [
        ...standardColumns.filter((col) => col.Header !== 'Account' && col.defaultShow !== false),
        {
          Header: 'Days Outstanding',
          accessor: 'transactionPastDue',
          //@ts-ignore
          Cell: TableConnected.NumericCell,
        },
      ]
    }

    dataSections = [
      {
        data: anomaly.transactions || anomaly.data,
        key,
        columns,
      },
    ]
  } else {
    dataSections = [
      {
        data: anomaly.transactions!,
        key,
        columns: getStandardColumns(sourceKind),
      },
    ]
  }

  const configuredSections = applyConfigDataSections({
    configSections,
    dataSections,
    reportEndDate,
  })

  return configuredSections.map((section) => ({
    ...section,
    table: true,
    tableOptions: getAnomalyTableOptions({ anomaly, reportId, integrationId, sourceKind }),
  }))
}
