import React, { useEffect, useState } from 'react'
import { useApi, useQueryParams, useAnalytics } from 'hooks'
import { LinkButton } from 'elements'

const statusLabels = {
  idle: 'Resend code',
  sending: 'Sending...',
  success: 'Success!',
}

export default function VerifyResend() {
  const [status, setStatus] = useState('idle')
  const api = useApi()
  const analytics = useAnalytics()
  const { params } = useQueryParams()

  const resendVerificationCode = async () => {
    setStatus('sending')
    await api.auth.resendVerification({ email: params.email })

    analytics.track('User Resend Verification', { $email: params.email, provider: 'cognito' })
    setStatus('success')
  }

  useEffect(() => {
    let timeoutId

    if (status === 'success') {
      timeoutId = setTimeout(() => setStatus('idle'), 3000)
    }

    return () => clearTimeout(timeoutId)
  }, [status])

  return (
    <LinkButton area="small" onClick={resendVerificationCode}>
      {statusLabels[status]}
    </LinkButton>
  )
}
