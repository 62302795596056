import { graphql, useSubscription } from 'react-relay/hooks'

const subscription = graphql`
  subscription IntegrationsWatcherSubscription {
    currentUserIntegrationsUpdated {
      event
      integration {
        id
        rowId
        kind
        status
        config
      }
    }
  }
`

export default function IntegrationsWatcher() {
  useSubscription({
    subscription,
    onNext: (response, ...args) => {
      console.log('Updated integration', response.currentUserIntegrationsUpdated?.integration)
    },
  })

  // this component doesn't render anything
  return null
}
