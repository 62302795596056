import styled, { keyframes } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Spinner = styled.div`
  position: relative;
  z-index: 9999;
  border: 0.3em solid ${themeGet('colors.neutral.1')};
  border-bottom-color: ${themeGet('colors.secondary.0')};
  border-radius: 50%;
  margin: 0 auto;
  width: ${({ size = 'xxlarge' }) => themeGet(`space.${size}`)}px;
  height: ${({ size = 'xxlarge' }) => themeGet(`space.${size}`)}px;
  opacity: 0.5;
  animation: ${spin} 1s linear infinite;
`

export default Spinner
