import React, { useState, useEffect, useCallback } from 'react'
import { useRefetchableFragment, graphql } from 'react-relay/hooks'
import { usePlaidLink } from 'react-plaid-link'
import PlaidIntegrationBox from './PlaidIntegrationBox'
import { Text, FlexColumn, FlexRow, Button } from 'elements'
import plaidLogo from 'theme/images/plaidLogo.svg'
import { useAuth, useApi } from 'hooks'

type Props = {
  company: any
  onDelete: Function
}

export default function PlaidIntegrations(props: Props) {
  const { company, onDelete } = props
  const { currentCompany } = useAuth()
  const api = useApi()

  const [plaidLinkToken, setPlaidLinkToken] = useState(null)
  const [plaidConnecting, setPlaidConnecting] = useState(false)

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment PlaidIntegrations_company on Company
        @refetchable(queryName: "PlaidIntegrationsRefetchQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "Cursor" }
        ) {
        integrations(first: $count, after: $cursor)
          @connection(key: "PlaidIntegrations_integrations") {
          edges {
            node {
              id
              rowId
              kind
              status
              config
            }
          }
        }
      }
    `,
    company,
  )

  const plaidIntegrations = data.integrations.edges
    .filter((edge: any) => edge.node.kind === 'plaid')
    .map((edge: any) => edge.node)

  // the link flow returns a public token, which we exchange for an access token on the server side
  const plaidOnSuccess = useCallback(
    async (publicToken: any) => {
      const res = await api.plaid.callback({
        publicToken,
        companyId: currentCompany!.rowId,
      })

      setPlaidConnecting(false)

      if (res?.success) {
        refetch({ integrationCount: 100 }, { fetchPolicy: 'network-only' })
      }
    },
    [api, currentCompany, refetch],
  )

  const plaidOnExit = useCallback(
    async (error: any, metadata: any) => {
      if (error) {
        console.log(error)
      }

      setPlaidConnecting(false)
    },
    [setPlaidConnecting],
  )

  const plaidLink = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: plaidOnSuccess,
    onExit: plaidOnExit,
  })

  useEffect(() => {
    if (plaidLink.ready && plaidConnecting) {
      plaidLink.open()
    }
  }, [plaidLink, plaidConnecting])

  const handleConnect = async () => {
    if (!plaidLinkToken) {
      const { linkToken } = await api.plaid.createLinkToken()

      setPlaidLinkToken(linkToken)
    }

    setPlaidConnecting(true)
  }

  return (
    <FlexColumn>
      <Text fontSize="xxlarge" fontWeight="bold" mb="xxxlarge">
        Plaid Integrations
      </Text>
      <Button onClick={handleConnect} mb="xxxlarge" width="25%">
        + Add new
      </Button>
      <FlexRow gap="xxlarge" flexWrap="wrap">
        {plaidIntegrations.map((integration: any) => (
          <FlexColumn key={integration.id}>
            <PlaidIntegrationBox
              userIntegration={integration}
              logo={plaidLogo}
              logoWidth="75%"
              onDelete={() => onDelete(integration)}
            />
          </FlexColumn>
        ))}
      </FlexRow>
    </FlexColumn>
  )
}
