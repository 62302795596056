/**
 * @generated SignedSource<<ad356d42ac9d51f9708d4adee4a798fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DuplicateWorkflowTemplateInput = {
  id: string;
  name?: string | null;
  organizationId?: string | null;
};
export type useWorkflowTemplateDuplicateMutation$variables = {
  input: DuplicateWorkflowTemplateInput;
};
export type useWorkflowTemplateDuplicateMutation$data = {
  readonly duplicateWorkflowTemplate: {
    readonly workflowTemplate: {
      readonly rowId: any;
      readonly id: string;
      readonly name: string | null;
      readonly config: any | null;
      readonly organizationId: any | null;
      readonly organization: {
        readonly id: string;
      } | null;
    };
  } | null;
};
export type useWorkflowTemplateDuplicateMutation = {
  variables: useWorkflowTemplateDuplicateMutation$variables;
  response: useWorkflowTemplateDuplicateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateWorkflowTemplatePayload",
    "kind": "LinkedField",
    "name": "duplicateWorkflowTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkflowTemplate",
        "kind": "LinkedField",
        "name": "workflowTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWorkflowTemplateDuplicateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWorkflowTemplateDuplicateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8d020d1a664fd30b01e33ae16ee23e38",
    "id": null,
    "metadata": {},
    "name": "useWorkflowTemplateDuplicateMutation",
    "operationKind": "mutation",
    "text": "mutation useWorkflowTemplateDuplicateMutation(\n  $input: DuplicateWorkflowTemplateInput!\n) {\n  duplicateWorkflowTemplate(input: $input) {\n    workflowTemplate {\n      rowId\n      id\n      name\n      config\n      organizationId\n      organization {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee888298bf3fd4e67d1d3b75a2d02d37";

export default node;
