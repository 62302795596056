/**
 * @generated SignedSource<<3431011b428c698c5456fdc9d5a8aa07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportStep_reportWorkflowWorkspaceStep$data = {
  readonly key: string;
  readonly " $fragmentType": "ReportStep_reportWorkflowWorkspaceStep";
};
export type ReportStep_reportWorkflowWorkspaceStep$key = {
  readonly " $data"?: ReportStep_reportWorkflowWorkspaceStep$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportStep_reportWorkflowWorkspaceStep">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportStep_reportWorkflowWorkspaceStep",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    }
  ],
  "type": "ReportWorkflowWorkspaceStep",
  "abstractKey": null
};

(node as any).hash = "dfe7b7711dedeb5a8f52945590ef35a4";

export default node;
