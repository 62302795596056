import React from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useAuth } from 'hooks'
import WorkflowTemplateSelect from './WorkflowTemplateSelect'

type Props = {
  workflowTemplate: any
  onWorkflowTemplateChange: Function
  requirePeriod?: boolean
}

export default function WorkflowTemplateSelectContainer(props: Props) {
  const { currentOrg } = useAuth()

  const data = useLazyLoadQuery<any>(
    graphql`
      query WorkflowTemplateSelectContainerQuery($orgSlug: String!) {
        organizationBySlug(slug: $orgSlug) {
          ...WorkflowTemplateSelect_organization
        }
      }
    `,
    { orgSlug: currentOrg?.slug || '' },
  )

  if (!data) {
    return null
  }

  return <WorkflowTemplateSelect organization={data.organizationBySlug} {...props} />
}
