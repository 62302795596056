import React, { lazy } from 'react'
import { merge } from 'lodash'
import theme from 'theme'
import baseChartOptions from 'theme/baseChartOptions'
import { calcColumnWidth, getTicks, calcStackedMaxValue } from 'utils/chart'
import { roundToNearest } from 'utils/math'
import { Series } from 'types/chart'

const Chart = lazy(() => import('react-apexcharts'))

type Props = {
  options?: Record<string, any>
  width?: string
  height?: string
  series: Series
  title?: string
  chartSpan?: 'semi' | 'full'
}

// Note: each data array in the series must be the same length
export default function StackedBarChart(props: Props) {
  const { options = {}, width = '100%', height = '100%', series, chartSpan = 'semi' } = props

  if (!series?.length) {
    return null
  }

  const maxValue = calcStackedMaxValue(series)

  const defaultOptions = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        borderRadius: theme.radii.xsmall,
        borderRadiusApplication: 'end',
        columnWidth: calcColumnWidth({ chartSpan, colNum: series[0].data.length }),
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      itemMargin: {
        horizontal: theme.sizes.medium + theme.sizes.small,
      },
      markers: {
        radius: '100%',
        offsetX: `-${theme.sizes.small}`,
      },
    },
    yaxis: {
      tickAmount: getTicks(maxValue).amount,
      min: 0,
      max: roundToNearest(maxValue),
    },
  }

  return (
    <Chart
      options={merge({}, baseChartOptions, defaultOptions, options)}
      series={series}
      type="bar"
      width={width}
      height={height}
    />
  )
}
