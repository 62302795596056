/**
 * @generated SignedSource<<5c7cc776426abe7106825c69119bcc35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWorkflowTemplateByIdInput = {
  clientMutationId?: string | null;
  id: string;
  patch: WorkflowTemplatePatch;
};
export type WorkflowTemplatePatch = {
  config?: any | null;
  createdAt?: any | null;
  deletedAt?: any | null;
  description?: string | null;
  name?: string | null;
  organizationId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
};
export type useWorkflowTemplateUpdateMutation$variables = {
  input: UpdateWorkflowTemplateByIdInput;
};
export type useWorkflowTemplateUpdateMutation$data = {
  readonly updateWorkflowTemplateById: {
    readonly workflowTemplate: {
      readonly id: string;
      readonly organization: {
        readonly " $fragmentSpreads": FragmentRefs<"WorkflowConfiguration_organization">;
      } | null;
    } | null;
  } | null;
};
export type useWorkflowTemplateUpdateMutation = {
  variables: useWorkflowTemplateUpdateMutation$variables;
  response: useWorkflowTemplateUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "includeDeleted",
    "value": "NO"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useWorkflowTemplateUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkflowTemplatePayload",
        "kind": "LinkedField",
        "name": "updateWorkflowTemplateById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowTemplate",
            "kind": "LinkedField",
            "name": "workflowTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "WorkflowConfiguration_organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWorkflowTemplateUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkflowTemplatePayload",
        "kind": "LinkedField",
        "name": "updateWorkflowTemplateById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowTemplate",
            "kind": "LinkedField",
            "name": "workflowTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "WorkflowTemplatesConnection",
                    "kind": "LinkedField",
                    "name": "workflowTemplatesWithDefaults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkflowTemplatesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkflowTemplate",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "config",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "organizationId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "workflowTemplatesWithDefaults(first:100,includeDeleted:\"NO\",orderBy:\"CREATED_AT_DESC\")"
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": [
                      "orderBy",
                      "includeDeleted"
                    ],
                    "handle": "connection",
                    "key": "WorkflowConfiguration_workflowTemplatesWithDefaults",
                    "kind": "LinkedHandle",
                    "name": "workflowTemplatesWithDefaults"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a2b57ffafe9cb260e99cff2886646e7",
    "id": null,
    "metadata": {},
    "name": "useWorkflowTemplateUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useWorkflowTemplateUpdateMutation(\n  $input: UpdateWorkflowTemplateByIdInput!\n) {\n  updateWorkflowTemplateById(input: $input) {\n    workflowTemplate {\n      id\n      organization {\n        ...WorkflowConfiguration_organization\n        id\n      }\n    }\n  }\n}\n\nfragment WorkflowConfiguration_organization on Organization {\n  workflowTemplatesWithDefaults(first: 100, orderBy: CREATED_AT_DESC, includeDeleted: NO) {\n    edges {\n      node {\n        id\n        name\n        config\n        organizationId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "408999891b068c2f5edc14e6323839d3";

export default node;
