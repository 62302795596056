/**
 * @generated SignedSource<<cda17130577bdc89966795c766b45e4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserOrganizationRole = "ADMIN" | "MEMBER" | "%future added value";
export type UpdateOrganizationUserByIdInput = {
  clientMutationId?: string | null;
  id: string;
  patch: OrganizationUserPatch;
};
export type OrganizationUserPatch = {
  createdAt?: any | null;
  organizationId?: any | null;
  role?: UserOrganizationRole | null;
  rowId?: any | null;
  updatedAt?: any | null;
  userId?: any | null;
};
export type useChangeOrgRoleMutation$variables = {
  input: UpdateOrganizationUserByIdInput;
};
export type useChangeOrgRoleMutation$data = {
  readonly updateOrganizationUserById: {
    readonly organizationUser: {
      readonly role: UserOrganizationRole;
      readonly user: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
  } | null;
};
export type useChangeOrgRoleMutation = {
  variables: useChangeOrgRoleMutation$variables;
  response: useChangeOrgRoleMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useChangeOrgRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationUserPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationUserById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUser",
            "kind": "LinkedField",
            "name": "organizationUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useChangeOrgRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationUserPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationUserById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUser",
            "kind": "LinkedField",
            "name": "organizationUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edf3445b33f48ad834e29b3ccebf85e3",
    "id": null,
    "metadata": {},
    "name": "useChangeOrgRoleMutation",
    "operationKind": "mutation",
    "text": "mutation useChangeOrgRoleMutation(\n  $input: UpdateOrganizationUserByIdInput!\n) {\n  updateOrganizationUserById(input: $input) {\n    organizationUser {\n      role\n      user {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a57444a45e46c7b99a5ff16a7385d0c5";

export default node;
