import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useHistory, useLocation } from 'react-router-dom'
import { Card, Text, FlexRow, FlexColumn, Image, Button, Icon } from 'elements'
import * as images from 'theme/images/stepCardImages'
import { formatName } from 'utils/string'
import { colorToRGB } from 'utils/color'

type Props = {
  step: any
}

export default function WorkflowStep(props: Props) {
  const { step } = props
  const history = useHistory()
  const location = useLocation()

  const data = useFragment(
    graphql`
      fragment WorkspaceStep_reportWorkflowWorkspaceStep on ReportWorkflowWorkspaceStep {
        id
        key
        name
        stepOrder
        sections
        status
        description
      }
    `,
    step,
  )

  // @ts-ignore
  const imgSrc = images[data.key]

  const completed = data.status === 'complete'

  return (
    <Card
      flexDirection="column"
      width="290px"
      height="365px"
      border="solid 1px"
      borderColor="neutral.1"
      onClick={() => history.push(`${location.pathname}/steps/${data.id}`)}
    >
      <FlexColumn
        height="35%"
        bg="background.0"
        justifyContent="center"
        alignItems="center"
        borderRadius=" 9px 9px"
      >
        <Image src={imgSrc} height="65%" width="65%" />
      </FlexColumn>
      <FlexColumn
        bg={completed ? 'primary.1' : 'background.3'}
        height="65%"
        borderRadius="0 0 9px 9px"
        px="xlarge"
        py="large"
      >
        <Text
          color={completed ? 'primary.0' : 'secondary.0'}
          fontWeight="bold"
          fontSize="xsmall"
          mb="small"
        >
          Step {data.stepOrder + 1}
        </Text>
        <Text fontWeight="bold" fontSize="xxlarge" color="secondary.2" mb="large">
          {data.name || formatName(data.key)}
        </Text>
        {data.description && (
          <Text fontSize="small" lineHeight="small">
            {data.description}
          </Text>
        )}
        <FlexRow mt="auto">
          {completed ? (
            <FlexRow
              bg={colorToRGB('success.0', 0.3)}
              borderRadius="small"
              px="large"
              py="small"
              alignItems="center"
            >
              <Text color="primary.2" mr="small">
                Completed
              </Text>
              <Icon name="solid-check-circle" color="primary.2" size="medium" />
            </FlexRow>
          ) : (
            <Button>Open</Button>
          )}
        </FlexRow>
      </FlexColumn>
    </Card>
  )
}
