import React from 'react'
import { toast } from 'react-toastify'
import { Box, Icon, Text } from 'elements'

const renderMessage = (message, icon) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Icon size="small" color="background.0" name={icon} mr="medium" />
    <Text color="background.0">{message}</Text>
  </Box>
)

export const notifications = {
  info: (message, props) => toast.info(renderMessage(message, 'info-circle'), props),
  success: (message, props) => toast.success(renderMessage(message, 'check'), props),
  warn: (message, props) => toast.warn(renderMessage(message, 'exclamation-circle'), props),
  error: (message, props) => toast.error(renderMessage(message, 'exclamation-circle'), props),
}

export default function useNotifications() {
  return notifications
}
