// undefined/null -> null
// empty string -> null
// string with length -> number
// number -> number
export function parseFormNumber(
  value: string | number | null | undefined,
  parser: Function,
): number | null {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    if (value.length) {
      return parser(value)
    }

    return null
  }

  return value
}

export function parseFormInt(value: string | number): number | null {
  return parseFormNumber(value, parseInt)
}

export function parseFormFloat(value: string | number): number | null {
  return parseFormNumber(value, parseFloat)
}
