import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useHistory } from 'react-router-dom'
import { flatten } from 'lodash'
import { TabBar, FlexRow, Text, Button, Icon, Anchor } from 'elements'
import { Option } from '../../../shared/types'
import { formatName } from 'utils/string'
import useWorkflowStepUdate from '../hooks/useWorkflowStepUpdate'
import { colorToRGB } from 'utils/color'
import { useAuth, useNotifications } from 'hooks'
import { transactionRepairStepKeys } from '../constants'

type ButtonProps = {
  isComplete: boolean
  hasNext: boolean
  handleComplete: Function
  handleUndo: Function
  updateInFlight: boolean
  isTransactionRepair: boolean
}

const CompleteButtons = (props: ButtonProps) => {
  const {
    isComplete,
    hasNext,
    handleComplete,
    handleUndo,
    updateInFlight,
    isTransactionRepair,
  } = props

  const completedMessage = (
    <FlexRow
      bg={colorToRGB('success.0', 0.3)}
      borderRadius="small"
      px="large"
      py="small"
      alignItems="center"
    >
      <Text color="primary.2" mr="small">
        Completed
      </Text>
      <Icon name="solid-check-circle" color="primary.2" size="medium" />
    </FlexRow>
  )

  if (isTransactionRepair) {
    if (isComplete) {
      return completedMessage
    }

    return null
  }

  if (!isComplete) {
    let text = ''

    if (updateInFlight) {
      text = 'Saving...'
    } else if (hasNext) {
      text = 'Complete & continue'
    } else {
      text = 'Complete'
    }

    return (
      <Button kind="primary" onClick={handleComplete}>
        {text}
      </Button>
    )
  }

  return (
    <FlexRow alignItems="center">
      <Anchor color="neutral.9" onClick={handleUndo} mr="large" fontSize="small">
        Undo
      </Anchor>
      {completedMessage}
    </FlexRow>
  )
}

type Props = {
  step: any
  reportUrl: string
}

export default function StepHeader(props: Props) {
  const { step, reportUrl } = props
  const history = useHistory()
  const [updateStep, updateInFlight]: any = useWorkflowStepUdate()
  const { currentOrg, currentCompany } = useAuth()
  const notifications = useNotifications()

  const data = useFragment(
    graphql`
      fragment StepHeader_reportWorkflowWorkspaceStep on ReportWorkflowWorkspaceStep {
        id
        stepOrder
        status
        key
        reportWorkflowWorkspace {
          id
          workspaceOrder
          reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
            nodes {
              id
              key
              name
              stepOrder
            }
          }
          report {
            id
            reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
              nodes {
                id
                workspaceOrder
                reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
                  nodes {
                    id
                    status
                    stepOrder
                  }
                }
              }
            }
          }
        }
      }
    `,
    step,
  )

  const workspaceSteps = data.reportWorkflowWorkspace.reportWorkflowWorkspaceSteps.nodes

  const report = data.reportWorkflowWorkspace.report

  const allReportSteps: any[] = flatten(
    report.reportWorkflowWorkspaces.nodes.map((workspace: any) =>
      workspace.reportWorkflowWorkspaceSteps.nodes.map((step: any) => ({
        ...step,
        workspaceId: workspace.id,
        workspaceOrder: workspace.workspaceOrder,
      })),
    ),
  )

  const tabs = workspaceSteps.map((step: any) => ({
    label: step.name || formatName(step.key),
    value: step,
  }))

  const isComplete = data.status === 'complete'

  // find next incomplete step
  const nextStep = allReportSteps.find((step: any) => {
    const incomplete = step.status === 'incomplete'

    if (step.workspaceId === data.reportWorkflowWorkspace.id) {
      return incomplete && step.stepOrder > data.stepOrder
    }

    // if the step is in a different workspace, go to the first incomplete step in the next workspace
    return incomplete && step.workspaceOrder > data.reportWorkflowWorkspace.workspaceOrder
  })

  const handleComplete = async () => {
    const isFinalStep = allReportSteps.filter((step) => step.status === 'incomplete').length === 1

    await updateStep({
      id: data.id,
      patch: { status: 'complete' },
    })

    const companyPath = `/orgs/${currentOrg!.slug}/companies/${currentCompany!.id}`

    if (nextStep) {
      history.push(`${companyPath}/reports/${report.id}/steps/${nextStep.id}`)

      return
    }

    // final step complete
    if (isFinalStep) {
      history.push(companyPath)
      notifications.success('Workflow complete!')
    }
  }

  const handleUndo = () => {
    updateStep({
      id: data.id,
      patch: { status: 'incomplete' },
    })
  }

  return (
    <>
      {/* empty box for placement */}
      <FlexRow flex="1">{}</FlexRow>
      <FlexRow flex="1.5" justifyContent="center" alignItems="center">
        <TabBar
          tabs={tabs}
          value={tabs.find((tab: any) => tab.value.id === data.id)?.value}
          onChange={(tab: Option) => history.push(`${reportUrl}/steps/${tab.value.id}`)}
          styleProps={{ color: 'neutral.3', borderColor: 'accent.lightblue' }}
        />
      </FlexRow>
      <FlexRow justifyContent="flex-end" flex="1">
        <CompleteButtons
          isComplete={isComplete}
          hasNext={!!nextStep}
          handleComplete={handleComplete}
          handleUndo={handleUndo}
          updateInFlight={updateInFlight as boolean}
          isTransactionRepair={transactionRepairStepKeys.includes(data.key)}
        />
      </FlexRow>
    </>
  )
}
