import { round, sortBy, orderBy, startCase, isNil, omit, map, sum, concat } from 'lodash'
import { DateTime } from 'luxon'
import { formatReportPeriod, formatDate } from '../../utils/formatting'
import { calcDayInterval } from '../../../../utils/date'
import { xeroTransactionTypeNames, xeroAccountTypeNames } from '../../constants'
import { Series } from 'types/chart'

function transactionFilter(data: any) {
  if (!data) {
    return []
  }

  return data.filter((row: any) => row.transactionAmount || row.totalTransactionAmount)
}

export default function formatSummary(summary: any) {
  if (!summary) {
    return null
  }

  const {
    reportPeriod,
    reportDate,
    transactionByType,
    transactionByVendorList,
    transactionByCustomerList,
    transactionByAccountType,
    transactionVolumeByMonth = [],
    lastReconciledTransactionDateList = [],
    accountByTypeCount = [],
    totalAccountCount,
    accountsUsedInReportCount,
    accountList,
    vendorsInReportCount,
    totalVendorCount,
    vendorList,
    customersInReportCount,
    totalCustomerCount,
    customerList,
    transactionCountByBankCCAccount = [],
    bankRuleTransactions = {},
  } = summary

  //filter out rows with zero for count or amount
  const filteredTransactions: any = {}

  const formattedTransactionsByType = transactionFilter(transactionByType).map((txnByType: any) => {
    const transactionType =
      xeroTransactionTypeNames[txnByType.transactionType] || txnByType.transactionType

    const transactions = map(txnByType.transactions, (txn: any) => ({ ...txn, transactionType }))

    return { ...txnByType, transactionType, transactions }
  })

  filteredTransactions.byType = orderBy(formattedTransactionsByType, 'count', 'desc')

  filteredTransactions.byVendor = orderBy(
    transactionFilter(transactionByVendorList),
    'totalTransactionCount',
    'desc',
  )

  filteredTransactions.byCustomer = orderBy(
    transactionFilter(transactionByCustomerList),
    'totalTransactionCount',
    'desc',
  )

  filteredTransactions.byAccountType = orderBy(
    transactionFilter(transactionByAccountType),
    'totalTransactionCount',
    'desc',
  )

  const filteredAnomaliesByType = sortBy(
    summary.anomalyByType.filter((anomaly: any) => anomaly.count),
    (anomaly) => anomaly.anomalyName,
  )

  //format completeness scores
  const percentageScores = {
    customer: round(summary.customerProfileCompletenessScore * 100, 0),
    vendor: round(summary.vendorProfileCompletenessScore * 100, 0),
  }
  const completenessScores = [
    { entityName: 'Customer', score: `${percentageScores.customer} %` },
    { entityName: 'Vendor', score: `${percentageScores.vendor} %` },
  ]

  const accountsInReport = accountList?.filter((account: any) => account.usedInReport)
  const unusedAccounts = accountList?.filter(
    (account: any) => !account.usedInReport && account.accountActiveStatus,
  )
  // exclude unused and inactive accounts
  const filteredAccounts = accountList?.filter(
    (account: any) => account.usedInReport || account.accountActiveStatus,
  )

  //format account count
  const accountsUsage = !isNil(accountsUsedInReportCount)
    ? [
        {
          accountCategory: 'Used in Report',
          count: accountsUsedInReportCount,
          accounts: accountsInReport,
        },
        {
          accountCategory: 'Unused',
          count: unusedAccounts?.length,
          accounts: unusedAccounts,
        },
        {
          accountCategory: 'All',
          count: filteredAccounts?.length ?? totalAccountCount,
          accounts: filteredAccounts,
        },
      ]
    : []

  const vendorsInReport = vendorList?.filter((vendor: any) => vendor.usedInReport)
  const unusedVendors = vendorList?.filter(
    (vendor: any) => !vendor.usedInReport && vendor.vendorActiveStatus,
  )
  // exclude unused and inactive vendors
  const filteredVendors = vendorList?.filter(
    (vendor: any) =>
      vendor.vendorDisplayName !== 'Unspecified' &&
      (vendor.usedInReport || vendor.vendorActiveStatus),
  )

  const vendorUsage = !isNil(vendorsInReportCount)
    ? [
        {
          vendorCategory: 'Used in Report',
          count: vendorsInReportCount,
          vendors: vendorsInReport,
        },
        {
          vendorCategory: 'Unused',
          // we only have usable data for unused vendors when there's a vendor list
          count: unusedVendors?.length,
          vendors: unusedVendors,
        },
        {
          vendorCategory: 'All',
          count: filteredVendors?.length ?? totalVendorCount,
          vendors: filteredVendors,
        },
      ]
    : []

  const customersInReport = customerList?.filter((customer: any) => customer.usedInReport)
  const unusedCustomers = customerList?.filter(
    (customer: any) => !customer.usedInReport && customer.customerActiveStatus,
  )
  // exclude unused and inactive customers
  const filteredCustomers = customerList?.filter(
    (customer: any) =>
      customer.customerDisplayName !== 'Unspecified' &&
      (customer.usedInReport || customer.customerActiveStatus),
  )

  const customerUsage = !isNil(customersInReportCount)
    ? [
        {
          customerCategory: 'Used in Report',
          count: customersInReportCount,
          customers: customersInReport,
        },
        {
          customerCategory: 'Unused',
          count: unusedCustomers?.length,
          customers: unusedCustomers,
        },
        {
          customerCategory: 'All',
          count: filteredCustomers?.length ?? totalCustomerCount,
          customers: filteredCustomers,
        },
      ]
    : []

  //transaction count by month
  const sortedTxnsByMonth = orderBy(transactionVolumeByMonth, 'transactionMonth')

  const getDaysElapsed = (txnDate: string, endDate: string): string | number => {
    if (txnDate === 'N/A') {
      return 'N/A'
    }

    const txnDt = DateTime.fromISO(txnDate)
    const endDt = DateTime.fromISO(endDate)

    return calcDayInterval(txnDt, endDt)
  }

  //last reconciled transaction date
  const lastReconciledData = lastReconciledTransactionDateList.map((txn: any) => {
    const date = txn.lastReconciledTransactionDate.substring(0, 10)

    return {
      accountName: txn.accountName,
      daysElapsed: getDaysElapsed(date, reportPeriod.endDate),
      lastReconciledTransactionDate: date,
    }
  })

  //account count by type
  const formattedAccountsByType = accountByTypeCount.map((account: any) => ({
    accountType: xeroAccountTypeNames[account.accountName] || startCase(account.accountName),
    ...omit(account, 'accountName'),
  }))

  const sortedAccountsByType = orderBy(accountByTypeCount, 'accountTypeInReportCount', 'desc')

  const accountByTypeChartData: Series = [
    {
      name: 'Used',
      data: sortedAccountsByType.map((account: any) => account.accountTypeInReportCount ?? 0),
    },
    {
      name: 'Unused',
      data: sortedAccountsByType.map((account: any) => {
        const inReportCount = account.accountTypeInReportCount ?? 0
        return account.accountTypeCount - inReportCount
      }),
    },
  ]

  type GetAccountByTypeProps = {
    first: number
  }

  const getAccountByTypeChartData = (props: GetAccountByTypeProps): Series =>
    accountByTypeChartData.map((category) => ({
      ...category,
      data: category.data.slice(0, props.first),
    }))

  const accountByTypeCategories = sortedAccountsByType.map(
    (account: any) => xeroAccountTypeNames[account.accountName] || startCase(account.accountName),
  )

  const formattedReportDate = formatDate(reportDate, { timeZone: 'America/Chicago' })

  const formattedReportPeriod = formatReportPeriod(reportPeriod)

  const mappedBankCCTxns = transactionCountByBankCCAccount.map((account: any) => {
    const totalCount = sum(Object.values(account.transactionCounts))

    const transactionTypeCounts = Object.entries(account.transactionCounts)
      .map(([key, value]) => ({
        transactionType: key,
        count: value,
      }))
      .filter(({ count }) => count)

    return { accountName: account.accountName, count: totalCount, transactionTypeCounts }
  })

  const formattedBankCCTxnCounts = orderBy(mappedBankCCTxns, 'count', 'desc')

  // only an invalid id when vendorValidTaxId is false, not when null
  const invalidVendorTaxIds = vendorList
    ?.filter((vendor: any) => vendor.vendorValidTaxId === false)
    .map((vendor: any) => ({
      name: vendor.vendorDisplayName,
      taxId: vendor.vendorTaxId,
      createdAt: vendor.vendorCreatedTime,
      modifiedAt: vendor.vendorLastUpdatedTime,
    }))

  const hasBankRules =
    bankRuleTransactions?.bankRule?.length || bankRuleTransactions?.nonBankRule?.length

  const bankRules = hasBankRules
    ? [
        {
          transactionCategory: 'All',
          count: bankRuleTransactions?.bankRule?.length + bankRuleTransactions?.nonBankRule?.length,
          transactions: concat(bankRuleTransactions?.bankRule, bankRuleTransactions?.nonBankRule),
        },
        {
          transactionCategory: 'Bank Rule',
          count: bankRuleTransactions?.bankRule?.length,
          transactions: bankRuleTransactions?.bankRule,
        },
        {
          transactionCategory: 'Non Bank Rule',
          count: bankRuleTransactions?.nonBankRule?.length,
          transactions: bankRuleTransactions?.nonBankRule,
        },
      ]
    : []

  const bankRuleScore = hasBankRules
    ? Math.floor(
        (bankRuleTransactions?.bankRule?.length /
          (bankRuleTransactions?.bankRule?.length + bankRuleTransactions?.nonBankRule?.length)) *
          100,
      )
    : 0

  return {
    ...summary,
    filteredTransactions,
    filteredAnomaliesByType,
    percentageScores,
    completenessScores,
    accountsUsage,
    vendorUsage,
    customerUsage,
    vendorList,
    customerList,
    accountList,
    sortedTxnsByMonth,
    lastReconciledData,
    formattedAccountsByType,
    accountByTypeChartData,
    getAccountByTypeChartData,
    accountByTypeCategories,
    formattedReportPeriod,
    formattedReportDate,
    formattedBankCCTxnCounts,
    invalidVendorTaxIds,
    bankRuleScore,
    bankRules,
  }
}
