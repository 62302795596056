import React from 'react'
import { FlexColumn, Text, Anchor, Icon } from 'elements'

export default function ReportSizeStatus() {
  return (
    <FlexColumn p="xxlarge" alignItems="center" color="danger.0">
      <Icon name="exclamation-circle" color="inherit" size="xxlarge" mb="medium" />
      <Text fontSize="xlarge" lineHeight="xlarge" color="inherit" mb="large">
        This report period contains a volume of data that exceeds our system’s limits.
      </Text>
      <Text fontSize="small" color="neutral.0" mb="gigantic" textAlign="center">
        To ensure our system can effectively provide you with the data you need, please try
        rerunning this workflow for a shorter period.
      </Text>
      <Text textAlign="center" mb="xxxlarge">
        Please feel free to review our support documentation or create a ticket{' '}
        <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
          here.
        </Anchor>
      </Text>
    </FlexColumn>
  )
}
