import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { Formik } from 'formik'
import { flatten, pick } from 'lodash'
import { useApi, useNotifications } from 'hooks'
import { FlexColumn, Text } from 'elements'
import PlaidTriggerForm, { Values } from './PlaidTriggerForm'
import PlaidHistoryTable from './PlaidHistoryTable'

type Props = {
  company: any
}

export default function PlaidTrigger(props: Props) {
  const { company } = props
  const api = useApi()
  const notifications = useNotifications()

  const data = useFragment(
    graphql`
      fragment PlaidTrigger_company on Company {
        integrations {
          nodes {
            rowId
            kind
            config
          }
        }
      }
    `,
    company,
  )

  const plaidIntegrations = data.integrations.nodes.filter((int: any) => int.kind === 'plaid')

  const rawAccounts = flatten(
    plaidIntegrations.map((int: any) =>
      int.config.plaid.accounts.map((account: any) => ({
        ...account,
        institutionName: int.config.plaid.institutionName,
        integrationId: int.rowId,
        itemId: int.config.plaid.itemId,
      })),
    ),
  )

  const accounts = rawAccounts.map((account: any) => ({
    ...pick(account, ['name', 'mask', 'type', 'institutionName', 'integrationId', 'itemId']),
    accountId: account.account_id,
    subType: account.subtype,
    currentBalance: account.balances.current,
  }))

  const handleSubmit = async (values: Values, { setSubmitting }: Record<string, any>) => {
    const { reportPeriod, accounts } = values

    const res = await api.plaid.postTransactions({
      startDate: reportPeriod.interval.start.toISODate(),
      endDate: reportPeriod.interval.end.toISODate(),
      accounts: accounts.filter((account) => account.checked),
    })

    if (res.code === 200) {
      notifications.success('Plaid run triggered successfully')
    } else {
      notifications.error('Plaid run error')
    }

    setSubmitting(false)
  }

  return (
    <FlexColumn pt="gigantic">
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxxlarge">
        Plaid
      </Text>
      <FlexColumn mb="gigantic">
        <Formik onSubmit={handleSubmit} initialValues={{ accounts }}>
          <PlaidTriggerForm />
        </Formik>
      </FlexColumn>
      <Text fontSize="xxxlarge" fontWeight="bold" mb="xxlarge">
        Run History
      </Text>
      <PlaidHistoryTable company={company} />
    </FlexColumn>
  )
}
