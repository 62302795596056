import { ReactComponent as CheckCircle } from './icons/heroicons/outline/check-circle.svg'
import { ReactComponent as ChevronDown } from './icons/heroicons/outline/chevron-down.svg'
import { ReactComponent as ChevronRight } from './icons/heroicons/outline/chevron-right.svg'
import { ReactComponent as ChevronLeft } from './icons/heroicons/outline/chevron-left.svg'
import { ReactComponent as ChevronUp } from './icons/heroicons/outline/chevron-up.svg'
import { ReactComponent as Menu } from './icons/heroicons/outline/menu.svg'
import { ReactComponent as Check } from './icons/heroicons/outline/check.svg'
import { ReactComponent as Cog } from './icons/heroicons/outline/cog.svg'
import { ReactComponent as CloudUpload } from './icons/heroicons/outline/cloud-upload.svg'
import { ReactComponent as ExclamationCircle } from './icons/heroicons/outline/exclamation-circle.svg'
import { ReactComponent as InformationCircle } from './icons/heroicons/outline/information-circle.svg'
import { ReactComponent as Home } from './icons/heroicons/outline/home.svg'
import { ReactComponent as QuestionMarkCircle } from './icons/heroicons/outline/question-mark-circle.svg'
import { ReactComponent as Refresh } from './icons/heroicons/outline/refresh.svg'
import { ReactComponent as X } from './icons/heroicons/outline/x.svg'
import { ReactComponent as ArrowDown } from './icons/heroicons/outline/arrow-down.svg'
import { ReactComponent as ArrowRight } from './icons/heroicons/outline/arrow-right.svg'
import { ReactComponent as ArrowLeft } from './icons/heroicons/outline/arrow-left.svg'
import { ReactComponent as ArrowUp } from './icons/heroicons/outline/arrow-up.svg'
import { ReactComponent as ArrowCircleRight } from './icons/heroicons/outline/arrow-circle-right.svg'
import { ReactComponent as DocumentReport } from './icons/heroicons/outline/document-report.svg'
import { ReactComponent as DocumentAdd } from './icons/heroicons/outline/document-add.svg'
import { ReactComponent as Database } from './icons/heroicons/outline/database.svg'
import { ReactComponent as GlobeAlt } from './icons/heroicons/outline/globe-alt.svg'
import { ReactComponent as DotsHorizontal } from './icons/heroicons/outline/dots-horizontal.svg'
import { ReactComponent as DotsVertical } from './icons/heroicons/outline/dots-vertical.svg'
import { ReactComponent as Trash } from './icons/heroicons/outline/trash.svg'
import { ReactComponent as ChartBar } from './icons/heroicons/outline/chart-bar.svg'
import { ReactComponent as Hashtag } from './icons/heroicons/solid/hashtag.svg'
import { ReactComponent as Table } from './icons/heroicons/outline/table.svg'
import { ReactComponent as CurrencyDollar } from './icons/heroicons/outline/currency-dollar.svg'
import { ReactComponent as UserCircle } from './icons/heroicons/outline/user-circle.svg'
import { ReactComponent as User } from './icons/heroicons/outline/user.svg'
import { ReactComponent as CloudDownload } from './icons/heroicons/outline/cloud-download.svg'
import { ReactComponent as Adjustments } from './icons/heroicons/outline/adjustments.svg'
import { ReactComponent as Pencil } from './icons/heroicons/outline/pencil.svg'
import { ReactComponent as ClipboardCheck } from './icons/heroicons/outline/clipboard-check.svg'
import { ReactComponent as Clock } from './icons/heroicons/outline/clock.svg'
import { ReactComponent as Calendar } from './icons/heroicons/outline/calendar.svg'
import { ReactComponent as Minus } from './icons/heroicons/outline/minus.svg'
import { ReactComponent as SolidCheckCircle } from './icons/heroicons/solid/check-circle.svg'
import { ReactComponent as SolidCheck } from './icons/heroicons/solid/check.svg'
import { ReactComponent as Squares } from './icons/heroicons/outline/squares-2x2.svg'
import { ReactComponent as ChatBubble } from './icons/heroicons/outline/chat.svg'
import { ReactComponent as ArrowDownTray } from './icons/heroicons/outline/arrow-down-tray.svg'
import { ReactComponent as ArrowTopRightSquare } from './icons/heroicons/outline/arrow-top-right-on-square.svg'

// downloaded from figma, not heroicons
// https://www.figma.com/file/uMdmqgHFpv5q2AUIWXd58w/workflow-summary-%26-configuration?node-id=0-1&t=E6DynK56MfqBu4oO-0
import { ReactComponent as SyncButton } from './icons/other/sync-button.svg'
// https://www.figma.com/file/SMRQ2wsvShraDZM9tEs5Ds/Firm-Home-%26-Client-Overview?node-id=1-1377&t=OeDSIs03Gpu0qTzN-0
import { ReactComponent as TrayArrowRight } from './icons/other/tray-arrow-right.svg'

// see https://heroicons.dev/ for all of the icons available in this set
export default {
  'arrow-down': ArrowDown,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  'arrow-up': ArrowUp,
  'arrow-circle-right': ArrowCircleRight,
  'check-circle': CheckCircle,
  'angle-down': ChevronDown,
  'angle-right': ChevronRight,
  'angle-left': ChevronLeft,
  'angle-up': ChevronUp,
  bars: Menu,
  check: Check,
  'cloud-upload': CloudUpload,
  cog: Cog,
  'exclamation-circle': ExclamationCircle,
  'info-circle': InformationCircle,
  home: Home,
  'question-circle': QuestionMarkCircle,
  refresh: Refresh,
  x: X,
  'document-report': DocumentReport,
  'document-add': DocumentAdd,
  database: Database,
  'globe-alt': GlobeAlt,
  'dots-horizontal': DotsHorizontal,
  'dots-vertical': DotsVertical,
  trash: Trash,
  'chart-bar': ChartBar,
  hashtag: Hashtag,
  table: Table,
  'currency-dollar': CurrencyDollar,
  'user-circle': UserCircle,
  user: User,
  'cloud-download': CloudDownload,
  adjustments: Adjustments,
  pencil: Pencil,
  'clipboard-check': ClipboardCheck,
  clock: Clock,
  calendar: Calendar,
  minus: Minus,
  'solid-check-circle': SolidCheckCircle,
  'solid-check': SolidCheck,
  squares: Squares,
  'chat-bubble': ChatBubble,
  'sync-button': SyncButton,
  'arrow-down-tray': ArrowDownTray,
  'tray-arrow-right': TrayArrowRight,
  'arrow-top-right-square': ArrowTopRightSquare,
}
