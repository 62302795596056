import React from 'react'
import { FlexRow, Table, Button } from 'elements'
import ResolutionModalContainer from './ResolutionModalContainer'
import ReportTables from '../view/common/ReportTables'
import { getLineDataSections } from '../utils/resolutions/data'
import { getResolutionBehavior } from '../utils/resolutions/general'
import useReportResolutionUndo from './hooks/useReportResolutionUndo'
import useReportResolutionUpdateStatus from './hooks/useReportResolutionUpdateStatus'
import { useModals, useAnalytics, usePage } from 'hooks'
import { subTableOptions, subTableCellStyle } from '../constants'

const { Row, Cell } = Table.Data

type Props = {
  row: any
  reportId: string
}

export default function ResolutionRowSubTable(props: Props) {
  const { row, reportId } = props
  const { openModal } = useModals()
  const [updateResolutionStatus, updateStatusInFlight] = useReportResolutionUpdateStatus()
  const [undoResolution, undoInFlight] = useReportResolutionUndo()
  const analytics = useAnalytics()

  const { pageProps } = usePage()
  const { resolutionAnomalyView } = pageProps

  const working = undoInFlight || updateStatusInFlight

  const tableOptions = {
    ...subTableOptions,
    emptyMessage: 'No lines to display.',
  }

  const dataSections = getLineDataSections({
    transaction: row.original,
    canEdit: false,
    tableOptions,
    anomalyTypes: row.original.anomalyTypes,
  })

  const resolutionId = row.original.resolution.id

  const { label, action } = getResolutionBehavior(row.original.resolution.status)

  const handleResolution = async () => {
    switch (action) {
      case 'review':
        // @ts-ignore
        await updateResolutionStatus(
          { resolutionId, status: 'reviewed' },
          {
            resolutionSection: 'forReview',
            anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
          },
        )

        analytics.track('Resolution Reviewed', {
          resolutionId,
          platformTransactionId: row.original.transactionId,
          transactionType: row.original.transactionType,
          endpoint: row.original.transactionEndpoint,
        })

        break
      case 'undoReview':
        // @ts-ignore
        await updateResolutionStatus(
          { resolutionId, status: 'forReview' },
          {
            resolutionSection: 'reviewed',
            anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
          },
        )

        analytics.track('Resolution Review Undone', {
          resolutionId,
          platformTransactionId: row.original.transactionId,
          transactionType: row.original.transactionType,
          endpoint: row.original.transactionEndpoint,
        })

        break
      case 'undoResolve':
        // @ts-ignore
        await undoResolution(
          { resolutionId },
          {
            resolutionSection: 'resolved',
            anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
          },
        )

        analytics.track('Resolution Undone', {
          resolutionId,
          platformTransactionId: row.original.transactionId,
          transactionType: row.original.transactionType,
          endpoint: row.original.transactionEndpoint,
        })

        break
    }
  }

  return (
    <>
      <Row bg="background.0">
        <Cell colSpan={row.cells.length} style={subTableCellStyle}>
          <FlexRow mb="xxlarge">
            <ReportTables dataSections={dataSections} />
          </FlexRow>
          <FlexRow justifyContent="flex-end">
            <Button
              onClick={() =>
                openModal!(
                  ResolutionModalContainer,
                  {
                    resolutionId: row.original.resolution.id,
                    reportId,
                    platformCompanyId: row.original.resolution.currentValue.platformCompanyId,
                    anomalyView: resolutionAnomalyView,
                  },
                  'screen',
                )
              }
              kind="secondary"
              mr="medium"
            >
              View
            </Button>
            <Button onClick={handleResolution} disabled={working}>
              {!working ? label : 'Saving...'}
            </Button>
          </FlexRow>
        </Cell>
      </Row>
    </>
  )
}
