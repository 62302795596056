import React, { useState, useEffect } from 'react'
import Papa from 'papaparse'
import { mapKeys, camelCase, pick } from 'lodash'
import { FlexColumn, Text, Button, FileUpload, Table as Tables } from 'elements'
import { useAuth, useNotifications } from 'hooks'
import useCompaniesCreate from './hooks/useCompaniesCreate'

const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = Tables.Card

export default function CreateCompaniesForm() {
  const [submitting, setSubmitting] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [createdCompanies, setCreatedCompanies] = useState([])
  const { currentOrg } = useAuth()
  const [createCompanies, createInFlight]: any = useCompaniesCreate()
  const notifications = useNotifications()

  useEffect(() => {
    if (createInFlight) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [createInFlight])

  const handleSubmit = async () => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = async () => {
      if (reader.result) {
        try {
          const csvString = reader.result as string

          const res = Papa.parse(csvString, {
            header: true,
            skipEmptyLines: true,
          })

          const data = res.data as Record<string, any>[]

          const companiesInfo = data.map((companyInfo) =>
            pick(
              mapKeys(companyInfo, (val, key) => camelCase(key)),
              ['name', 'legalName'],
            ),
          )

          const { insertedCompanies } = await createCompanies({
            companiesInfo,
            organizationId: currentOrg!.id,
          })

          setCreatedCompanies(insertedCompanies)

          notifications.success('Companies created')
        } catch (err) {
          console.error(err)

          notifications.error('Company creation failed')
        }
      }
    }

    reader.readAsText(file)
  }

  return (
    <FlexColumn alignItems="center" pt="gigantic">
      <Text fontSize="xxlarge" fontWeight="bold" mb="medium">
        Upload CSV of client list
      </Text>
      <Text mb="gigantic">CSV should have two columns: Name and Legal Name</Text>
      <FlexColumn mb="xxlarge">
        <FileUpload
          disabled={submitting}
          allowedTypes={['csv']}
          height="large"
          onUpload={(selectedFile: any) => {
            setFile(selectedFile)
          }}
        />
      </FlexColumn>
      {file && (
        <FlexColumn>
          <Text mb="xxlarge">{file.name}</Text>
          <Button onClick={handleSubmit}>{submitting ? 'Submitting...' : 'Submit'}</Button>
        </FlexColumn>
      )}
      {!!createdCompanies.length && (
        <FlexColumn>
          <Text fontSize="xxlarge" fontWeight="bold" mb="xxlarge">
            Created Companies
          </Text>
          <Table spacing="large">
            <Head>
              <HeadRow>
                <HeadCell>Name</HeadCell>
                <HeadCell>Legal Name</HeadCell>
              </HeadRow>
            </Head>
            <Body>
              {createdCompanies.map((company: any) => (
                <Row>
                  <Cell>{company.name}</Cell>
                  <Cell>{company.legal_name}</Cell>
                </Row>
              ))}
            </Body>
          </Table>
        </FlexColumn>
      )}
    </FlexColumn>
  )
}
