import { graphql, fetchQuery } from 'react-relay/hooks'

type Props = {
  environment: any
  reportId: string
}

export default async function fetchAnomalyReportFile(props: Props): Promise<any> {
  const { environment, reportId } = props

  const data = await fetchQuery<any>(
    environment,
    graphql`
      query fetchAnomalyReportFileQuery($reportId: ID!) {
        reportById(id: $reportId) {
          outputFiles: reportUserFiles(condition: { type: OUTPUT }) {
            nodes {
              userFile {
                rowId
              }
            }
          }
        }
      }
    `,
    { reportId },
  ).toPromise()

  return data?.reportById?.outputFiles?.nodes?.[0]?.userFile
}
