import styled from 'styled-components'
import { compose, space, typography, color, layout, flexbox, border, position } from 'styled-system'
import { filterProps } from '../utils'

const Text = filterProps(styled.div)`

  ${({ truncate }) =>
    truncate
      ? `
max-width: 100%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;`
      : null}

  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace}` : '')};
  ${({ overflowWrap }) => (overflowWrap ? `overflow-wrap: ${overflowWrap}` : '')};
  ${({ textTransform }) => (textTransform ? `text-transform: ${textTransform}` : '')};
  ${({ textDecoration }) => (textDecoration ? `text-decoration: ${textDecoration}` : '')};

  ${compose(color, flexbox, layout, space, typography, border, position)}

`

Text.defaultProps = {
  fontSize: 'medium',
  lineHeight: 'medium',
  color: 'foreground.0',
  truncate: false,
}

export default Text
