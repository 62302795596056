import React from 'react'
import { Formik, Form } from 'formik'
import { useRelayEnvironment } from 'react-relay/hooks'
import { TextInputField, Modal, ModalNavHeader, Button } from 'elements'
import ModalContent from '../app/ModalContent'
import fetchTemplateByName from './queries/fetchTemplateByName'
import { useAuth } from 'hooks'

type Props = {
  onDone: (name: string) => Promise<any>
  modal: any
}

export default function ChangeTemplateNameModal(props: Props) {
  const { onDone, modal } = props
  const environment = useRelayEnvironment()
  const { currentOrg } = useAuth()

  const handleSubmit = async (values: Record<string, any>, setSubmitting: any) => {
    await onDone(values.name)

    modal.onClose()

    setSubmitting(false)
  }

  const validate = async (values: Record<string, any>) => {
    const errors: Record<string, any> = {}

    const sameNameTemplate = await fetchTemplateByName({
      environment,
      orgSlug: currentOrg!.slug,
      templateName: values.name,
    })

    if (sameNameTemplate) {
      errors.name = 'Please choose a unique workflow name'
    }

    return errors
  }

  return (
    <Modal modal={modal}>
      <Formik initialValues={{ name: '' }} onSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting }) => (
          <Form>
            <ModalNavHeader onClose={modal.onClose} titleText="Select Workflow Name" />
            <ModalContent>
              <TextInputField name="name" autoFocus placeholder="Name" mb="large" />
            </ModalContent>
            {/* @ts-ignore */}
            <Modal.Footer
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Button onClick={() => modal.onClose()}>Cancel</Button>
              <Button kind="primary" disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
              {/* @ts-ignore */}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
