import React from 'react'
import { useLocation } from 'react-router-dom'
import { usePage } from 'hooks'
import Header from '../app/Header'
import { TabBar } from 'elements'

export default function SettingsHeader() {
  const { pageProps } = usePage()
  const location = useLocation()

  const headerSections = pageProps?.settingsSections

  return (
    <Header justifyContent="center">
      {headerSections && (
        <TabBar
          tabs={headerSections}
          value={location.pathname}
          onChange={(tab) => {
            tab.onSelect()
          }}
          styleProps={{ color: 'neutral.3', borderColor: 'accent.lightblue' }}
        />
      )}
    </Header>
  )
}
