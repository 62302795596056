import styled from 'styled-components'
import { print } from '../styles'
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
} from 'styled-system'
import { has } from 'lodash'

const DataTable = styled.table`
  ${print}

  width: 100%;
  table-layout: fixed;

  ${compose(space, layout, typography, color, flexbox, grid, background, border, position, shadow)}

  border-collapse: separate;
  border-spacing: 0;

  overflow: ${(props) => (has(props, 'overflow') ? props.overflow : 'hidden')};
`

DataTable.defaultProps = {
  borderRadius: 'small',
  bg: 'background.0',
}

export default DataTable
