import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import theme from 'theme'

const Tooltip = styled(ReactTooltip)`
  opacity: ${({ opacity }) => opacity || '1'} !important;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor
      ? themeGet(`colors.${backgroundColor}`)
      : theme.colors.background[0]} !important;
  color: ${({ theme }) => theme.colors.foreground[0]} !important;
  box-shadow: ${({ theme }) => theme.shadows.low};
  z-index: ${({ theme }) => theme.zIndices.tooltip};
  border-radius: ${({ theme }) => theme.radii.xsmall};

  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
`

Tooltip.propTypes = {
  effect: PropTypes.string,
  delayHide: PropTypes.number,
  place: PropTypes.string,
  clickable: PropTypes.bool,
  arrowColor: PropTypes.string,
}

Tooltip.defaultProps = {
  effect: 'solid',
  delayHide: 100,
  place: 'top',
  clickable: false,
  role: 'tooltip',
  'aria-haspopup': 'true',
  arrowColor: theme.colors.background[0],
}

export default Tooltip
