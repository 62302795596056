import React, { forwardRef } from 'react'
import theme from 'theme'
import Box from '../Box'

export default forwardRef(({ name, size = 'small', style, spin, ...props }: any, ref) => {
  if (!theme.icons[name]) {
    throw new Error(`Icon [${name}] does not exist.`)
  }

  const IconComponent = theme.icons[name]
  const iconSize = theme.iconSizes[size] || size

  return (
    <Box
      className="elements-icon"
      ref={ref}
      display="flex"
      alignItems="center"
      justifyContent="center"
      size={iconSize}
      spin={spin}
      {...props}
    >
      <IconComponent width={iconSize} height={iconSize} style={style} />
    </Box>
  )
})
