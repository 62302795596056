/**
 * @generated SignedSource<<3a83fd3c318b4f29934ff718d51ca315>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteOrganizationUserByIdInput = {
  clientMutationId?: string | null;
  id: string;
};
export type useRemoveOrgUserMutation$variables = {
  input: DeleteOrganizationUserByIdInput;
};
export type useRemoveOrgUserMutation$data = {
  readonly deleteOrganizationUserById: {
    readonly deletedOrganizationUserId: string | null;
  } | null;
};
export type useRemoveOrgUserMutation = {
  variables: useRemoveOrgUserMutation$variables;
  response: useRemoveOrgUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteOrganizationUserPayload",
    "kind": "LinkedField",
    "name": "deleteOrganizationUserById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedOrganizationUserId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRemoveOrgUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRemoveOrgUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5422dc1e66d2d623affb44e906d22af8",
    "id": null,
    "metadata": {},
    "name": "useRemoveOrgUserMutation",
    "operationKind": "mutation",
    "text": "mutation useRemoveOrgUserMutation(\n  $input: DeleteOrganizationUserByIdInput!\n) {\n  deleteOrganizationUserById(input: $input) {\n    deletedOrganizationUserId\n  }\n}\n"
  }
};
})();

(node as any).hash = "97bcfb445a73abf506711999c60725ec";

export default node;
