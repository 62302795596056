import styled from 'styled-components'
import { compose, space, typography, color, variant } from 'styled-system'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      margin: 'none',
      mb: 'small',
      fontSize: 'medium',
      color: 'foreground.0',
    },
    secondary: {
      margin: 'none',
      mb: 'small',
      fontSize: 'medium',
      color: 'foreground.2',
    },
  },
})

const Paragraph = styled.p(compose(kind, space, typography, color))

Paragraph.defaultProps = {
  kind: 'normal',
}

export default Paragraph
