import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useBulkDeactivateMutation($input: BulkDeactivateIntuitInput!) {
    bulkDeactivateIntuit(input: $input) {
      integrationScriptResult {
        integration {
          ...DeactivateHistoryTable_integration
        }
      }
    }
  }
`

export default function useBulkDeactivate() {
  return useMutation(mutation, 'bulkDeactivateIntuit')
}
