import { Environment, RecordSource, Store, Observable } from 'relay-runtime'
import {
  RelayNetworkLayer,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
  perfMiddleware,
  retryMiddleware,
  cacheMiddleware,
  uploadMiddleware,
} from 'react-relay-network-modern'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import config from 'config'

const debugRelay = config.api.debug
const gqlEndpoint = `${config.api.host}/graphql`
const wsEndpoint = gqlEndpoint.replace(/^http/, 'ws') // http => ws, https => wss

let subscriptionClient

const subscribe = (request, variables) => {
  if (!subscriptionClient) {
    // NOTE: if we don't lazily create the subscription, there's a race condition
    // that can happen when the SubscriptionClient attempts to connect to the server
    // before the session cookie is set, causing all subscriptions to fail
    subscriptionClient = new SubscriptionClient(wsEndpoint, { reconnect: true })
  }

  const subscribeObservable = subscriptionClient.request({
    query: request.text,
    operationName: request.name,
    variables,
  })

  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from(subscribeObservable)
}

const network = new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 100, // max 100 requests
      ttl: 900000, // 15 minutes
    }),
    urlMiddleware({
      url: gqlEndpoint,
      credentials: 'include', // send cookies to API
    }),
    debugRelay ? loggerMiddleware() : null,
    debugRelay ? errorMiddleware() : null,
    debugRelay ? perfMiddleware() : null,
    !debugRelay
      ? retryMiddleware({
          fetchTimeout: 15000,
          retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
          beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
            if (attempt > 10) {
              abort()
            }

            window.forceRelayRetry = forceRetry
            console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.')
          },
          statusCodes: [500, 503, 504],
        })
      : null,
    uploadMiddleware(),
  ],
  {
    subscribeFn: subscribe,
  },
)

const source = new RecordSource()
const store = new Store(source)

export const environment = new Environment({ network, store })
