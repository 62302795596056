import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string } from 'yup'
import { Button, Box, TextInputField, Message } from 'elements'
import { useAuth } from 'hooks'
import { createFormValidator } from 'utils/validation'
import useOrganizationSetup from 'components/organization/hooks/useOrganizationSetup'

const validate = createFormValidator({
  name: string().required('Please enter the name of your firm'),
  legalName: string(),
})

export default function CreateOrganization() {
  const auth = useAuth()
  const history = useHistory()
  const [setupOrganization] = useOrganizationSetup()

  return (
    <Formik
      initialValues={{ name: '', legalName: '' }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const result = await setupOrganization({
            name: values.name,
            legalName: values.legalName,
          })

          if (result.organization) {
            // refresh login to include new org in auth rules
            await auth.login()
            history.push(`/orgs/${result.organization.slug}`)
          }
        } catch (error) {
          setFieldError('general', 'Error creating firm.')
          setSubmitting(false)
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          {errors.general ? (
            <Message kind="warning" mb="xlarge">
              {errors.general}
            </Message>
          ) : null}
          <Message kind="info" mb="xlarge">
            Please enter your firm details.
          </Message>
          <Box mb="xxxlarge">
            <Box mb="xlarge">
              <TextInputField label="Firm name" name="name" placeholder="Enter firm name" />
            </Box>
            <Box>
              <TextInputField
                label="Firm legal name (optional)"
                name="legalName"
                placeholder="Enter firm legal name"
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" mb="large">
            <Button disabled={isSubmitting} type="submit" kind="primary" flex="1">
              {isSubmitting ? 'Creating...' : 'Continue'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
