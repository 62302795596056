import React from 'react'
import { FlexRow } from 'elements'

const kinds = {
  neutral: {
    bg: 'neutral.1',
    color: 'foreground.0',
  },
  warning: {
    bg: 'warning.0',
    color: 'foreground.0',
  },
  danger: {
    bg: 'danger.0',
    color: 'background.0',
  },
}

export default function AppBanner({ children, kind, ...props }) {
  return (
    <FlexRow
      px="xxlarge"
      py="medium"
      alignItems="center"
      fontWeight="bold"
      bg={kinds[kind]?.bg}
      color={kinds[kind]?.color}
      {...props}
    >
      {children}
    </FlexRow>
  )
}
