/**
 * @generated SignedSource<<d21699dbf7b60ef9e00b672e0f7baf6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IncludeDeletedOption = "EXCLUSIVELY" | "INHERIT" | "NO" | "YES" | "%future added value";
export type CompanyContainerQuery$variables = {
  companyId: string;
  includeDeleted?: IncludeDeletedOption | null;
};
export type CompanyContainerQuery$data = {
  readonly companyById: {
    readonly " $fragmentSpreads": FragmentRefs<"ActiveWorkflowDashboard_company" | "ActiveWorkflowTable_company" | "CompletedWorkflowTable_company">;
  } | null;
};
export type CompanyContainerQuery = {
  variables: CompanyContainerQuery$variables;
  response: CompanyContainerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeDeleted"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v4 = {
  "kind": "Variable",
  "name": "includeDeleted",
  "variableName": "includeDeleted"
},
v5 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "CREATED_AT_DESC"
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "status": "active"
    }
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v7/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReportsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v2/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workflowSummary",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodEnd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportUserFilesConnection",
            "kind": "LinkedField",
            "name": "reportUserFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFile",
                    "kind": "LinkedField",
                    "name": "userFile",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportIntegrationsConnection",
            "kind": "LinkedField",
            "name": "reportIntegrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportIntegration",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Integration",
                    "kind": "LinkedField",
                    "name": "integration",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "WORKSPACE_ORDER_ASC"
              }
            ],
            "concreteType": "ReportWorkflowWorkspacesConnection",
            "kind": "LinkedField",
            "name": "reportWorkflowWorkspaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportWorkflowWorkspace",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "STEP_ORDER_ASC"
                      }
                    ],
                    "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                    "kind": "LinkedField",
                    "name": "reportWorkflowWorkspaceSteps",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportWorkflowWorkspaceStep",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sections",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  "input",
  "orderBy",
  "includeDeleted"
],
v13 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "status": "completed"
    }
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActiveWorkflowDashboard_company"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActiveWorkflowTable_company"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompletedWorkflowTable_company"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "activeReports",
            "args": (v6/*: any*/),
            "concreteType": "ReportsConnection",
            "kind": "LinkedField",
            "name": "filteredReports",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": "activeReports",
            "args": (v6/*: any*/),
            "filters": (v12/*: any*/),
            "handle": "connection",
            "key": "ActiveWorkflowsTable_activeReports",
            "kind": "LinkedHandle",
            "name": "filteredReports"
          },
          (v7/*: any*/),
          {
            "alias": "completedReports",
            "args": (v13/*: any*/),
            "concreteType": "ReportsConnection",
            "kind": "LinkedField",
            "name": "filteredReports",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": "completedReports",
            "args": (v13/*: any*/),
            "filters": (v12/*: any*/),
            "handle": "connection",
            "key": "CompletedWorkflowsTable_completedReports",
            "kind": "LinkedHandle",
            "name": "filteredReports"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5a336b1b84b5ec7132d6412e9dd18fe",
    "id": null,
    "metadata": {},
    "name": "CompanyContainerQuery",
    "operationKind": "query",
    "text": "query CompanyContainerQuery(\n  $companyId: ID!\n  $includeDeleted: IncludeDeletedOption\n) {\n  companyById(id: $companyId) {\n    ...ActiveWorkflowDashboard_company\n    ...ActiveWorkflowTable_company\n    ...CompletedWorkflowTable_company\n    id\n  }\n}\n\nfragment ActiveWorkflowDashboard_company on Company {\n  rowId\n}\n\nfragment ActiveWorkflowTable_company on Company {\n  activeReports: filteredReports(input: {status: \"active\"}, first: 10, orderBy: CREATED_AT_DESC, includeDeleted: $includeDeleted) {\n    edges {\n      node {\n        id\n        ...WorkflowTableRow_report\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment CompletedWorkflowTable_company on Company {\n  completedReports: filteredReports(input: {status: \"completed\"}, first: 10, orderBy: CREATED_AT_DESC, includeDeleted: $includeDeleted) {\n    edges {\n      node {\n        id\n        ...WorkflowTableRow_report\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ReportContextMenu_report on Report {\n  rowId\n  id\n  name\n  status\n  deletedAt\n  config\n  company {\n    id\n  }\n  reportUserFiles {\n    nodes {\n      type\n      userFile {\n        id\n        rowId\n        kind\n        name\n        key\n      }\n      id\n    }\n  }\n  reportIntegrations {\n    nodes {\n      integration {\n        id\n        owner {\n          id\n        }\n      }\n      id\n    }\n  }\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      key\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          key\n          sections\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment WorkflowTableRow_report on Report {\n  rowId\n  id\n  name\n  status\n  workflowSummary\n  periodStart\n  periodEnd\n  deletedAt\n  ...ReportContextMenu_report\n}\n"
  }
};
})();

(node as any).hash = "f91d1a77fb8225c3fa0b8b574d1a6e0c";

export default node;
