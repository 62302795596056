import React from 'react'
import FlexRow from '../../FlexRow'
import Icon from '../../Icon'
import Text from '../../Text'

const alignments: Record<string, string> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const getPadding = (base: number, depth: number, canExpand: boolean): number => {
  // increase padding when there's no icon
  if (!canExpand) {
    return (base + 5) * depth
  }

  return base * depth
}

export default function ExpandSubRowsHeader(props: any) {
  const { column, row, cell } = props
  const { align } = column
  const { isExpanded, getToggleRowExpandedProps, depth, canExpand } = row
  const { value } = cell

  return (
    <FlexRow
      justifyContent={align ? alignments[align] : 'flex-start'}
      alignItems="center"
      {...getToggleRowExpandedProps({ style: { paddingLeft: getPadding(20, depth, canExpand) } })}
    >
      {canExpand && <Icon name={isExpanded ? 'angle-down' : 'angle-right'} mr="small" />}
      <Text
        fontSize="inherit"
        color="inherit"
        fontWeight={(canExpand || row.original.summary) && 'bold'}
      >
        {value}
      </Text>
    </FlexRow>
  )
}
