import { useMutation as useRelayMutation } from 'react-relay/hooks'

export default function useDeleteMutation(query, deletedIdFieldName, options = {}) {
  const [mutate, isInFlight] = useRelayMutation(query)

  const simpleDelete = (input) =>
    new Promise((resolve, reject) => {
      mutate({
        variables: { input },
        onCompleted: resolve,
        configs: [
          {
            type: 'NODE_DELETE',
            deletedIDFieldName: deletedIdFieldName,
          },
        ],
      })
    })

  return [simpleDelete, isInFlight]
}
