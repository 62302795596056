import React, { useEffect, useState } from 'react'
import { FlexColumn, ModalNavHeader, Modal, Button } from 'elements'
import ChoiceButton from '../ui/input/ChoiceButton'
import ModalContent from '../app/ModalContent'
import useIntegrationUpdate from './hooks/useIntegrationUpdate'

type Props = {
  integration: any
  onClose: Function
  onDone?: Function
  submitting?: boolean
}

export default function ConnectionChoices(props: Props) {
  const { integration, onClose, onDone, submitting } = props

  const [selected, setSelected]: any = useState(null)
  const [updateIntegration, isInFlight]: any = useIntegrationUpdate()

  const configXero = integration?.config?.xero
  const tenants = configXero?.tenants

  useEffect(() => {
    if (tenants?.length) {
      setSelected(tenants[0])
    }
  }, [tenants, setSelected])

  if (!tenants) {
    return null
  }

  const handleContinue = async () => {
    const newConfig = { xero: { ...configXero, currentTenant: selected } }

    await updateIntegration({
      rowId: integration.rowId,
      patch: { config: newConfig },
    })

    if (onDone) {
      onDone()
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <ModalNavHeader titleText="Select a xero organization to connect" onClose={onClose} />
      <ModalContent mb="gigantic">
        <FlexColumn>
          {tenants.map((tenant: any, idx: number) => (
            // @ts-ignore
            <ChoiceButton
              key={tenant.id}
              selected={tenant.id === selected?.id}
              onPress={() => {
                setSelected(tenant)
              }}
              mb={idx !== tenants.length - 1 ? 'large' : null}
            >
              {tenant.tenantName}
            </ChoiceButton>
          ))}
        </FlexColumn>
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Button onClick={onClose}>Cancel</Button>
        <Button kind="primary" onClick={handleContinue} disabled={isInFlight}>
          {submitting ? 'Saving...' : 'Submit'}
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
