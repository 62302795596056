import styled from 'styled-components'
import {
  compose,
  border,
  space,
  layout,
  typography,
  color,
  flexbox,
  grid,
  position,
  shadow,
} from 'styled-system'

const Tray = styled.div`
  ${compose(border, space, layout, typography, color, flexbox, grid, position, shadow)}

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

Tray.defaultProps = {
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'high',
  borderRadius: 'small',
  backgroundColor: 'background.0',
  width: '100%',
}

export default Tray
