import { findKey } from 'lodash'

// examples
// Sandbox+Account+List.xlsx
// Sandbox+Customer+Contact+List.xlsx
// Sandbox+General+Ledger.xlsx
// Sandbox+Vendor+Contact+List.xlsx
const kindMatchers = {
  qbo: {
    'qb:xlsx:account-list': /account.list/i,
    'qb:xlsx:general-ledger': /general.ledger/i,
    'qb:xlsx:customer-contact-list': /customer.contact.list/i,
    'qb:xlsx:vendor-contact-list': /vendor.contact.list/i,
  },
  desktop: {
    'qb:xlsx:desktop-account-list': /account.list/i,
    'qb:xlsx:desktop-general-ledger': /general.ledger/i,
    'qb:xlsx:desktop-customer-contact-list': /customer.contact.list/i,
    'qb:xlsx:desktop-vendor-contact-list': /vendor.contact.list/i,
  },
}

export function guessFileKind(
  file: File,
  uploadSource: keyof typeof kindMatchers,
): string | undefined {
  if (!file?.name) {
    return undefined
  }

  return findKey(kindMatchers[uploadSource], (exp: RegExp) => exp.test(file.name))
}
