import React from 'react'
import { FlexColumn, Modal, ModalNavHeader, SuspenseBoundary } from 'elements'
import ReportStatusDetailsContainer from './ReportStatusDetailsContainer'

export default function ReportStatusDetailsModal({ modal, reportRowId }) {
  return (
    <Modal modal={modal}>
      <ModalNavHeader onClose={modal.onClose} titleText="Report Status" />
      <FlexColumn overflowY="auto">
        <SuspenseBoundary>
          <ReportStatusDetailsContainer reportRowId={reportRowId} />
        </SuspenseBoundary>
      </FlexColumn>
    </Modal>
  )
}
