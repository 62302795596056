import React from 'react'
import { QueryBuilderComponent, ColumnsModel, RuleModel } from '@syncfusion/ej2-react-querybuilder'
// this full css file is built using syncfusion's theme studio so that we can include custom styles
// see https://ej2.syncfusion.com/themestudio/?theme=material
import 'theme/css/query-builder.css'

type Props = {
  columnData: ColumnsModel[]
  width?: string
  rule?: RuleModel
  onRuleChange?: (data: any | undefined) => void
}

// set up our own element so that it can import the css
export default React.forwardRef((props: Props, ref: any) => {
  const { columnData, rule, onRuleChange, width = '100%' } = props

  return (
    <QueryBuilderComponent
      ref={ref}
      width={width}
      columns={columnData}
      rule={rule}
      ruleChange={onRuleChange}
    />
  )
})
