/**
 * @generated SignedSource<<8e9f26812581f491fc9a03adee9a6d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetupOrganizationInput = {
  legalName?: string | null;
  name: string;
};
export type useOrganizationSetupMutation$variables = {
  input: SetupOrganizationInput;
};
export type useOrganizationSetupMutation$data = {
  readonly setupOrganization: {
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly legalName: string | null;
      readonly slug: string;
    };
  } | null;
};
export type useOrganizationSetupMutation = {
  variables: useOrganizationSetupMutation$variables;
  response: useOrganizationSetupMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetupOrganizationPayload",
    "kind": "LinkedField",
    "name": "setupOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legalName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrganizationSetupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrganizationSetupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae2e194d494443762a63afc8f1107c6d",
    "id": null,
    "metadata": {},
    "name": "useOrganizationSetupMutation",
    "operationKind": "mutation",
    "text": "mutation useOrganizationSetupMutation(\n  $input: SetupOrganizationInput!\n) {\n  setupOrganization(input: $input) {\n    organization {\n      id\n      name\n      legalName\n      slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b8a8604ebc386727cfe35e5b2f414aa";

export default node;
