import React, { useState } from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { keyBy } from 'lodash'
import { useDecision } from '@optimizely/react-sdk'
import { FlexRow, FlexColumn, Text, Button, Icon } from 'elements'
import qbLogo from 'theme/images/qbLogo.svg'
import xeroLogo from 'theme/images/xeroLogo.svg'
import IntegrationBox from './IntegrationBox'
import { useApi, useAuth, useAnalytics } from 'hooks'
import useIntegrationDelete from './hooks/useIntegrationDelete'
import PlaidIntegrations from './PlaidIntegrations'
import StripeIntegration from './StripeIntegration'

const INTEGRATIONS = [
  { kind: 'intuit', logo: qbLogo },
  { kind: 'xero', logo: xeroLogo, logoWidth: '35%' },
]

export default function Integrations({ company }) {
  const xeroEnabled = useDecision('canCreateXeroReports')[0].enabled
  const plaidEnabled = useDecision('canCreatePlaidIntegration')[0].enabled
  const stripeEnabled = useDecision('canCreateStripeIntegration')[0].enabled

  const canViewIds = useDecision('canViewIds')[0].enabled

  const api = useApi()
  const { currentCompany } = useAuth()
  const analytics = useAnalytics()
  const [deleteIntegration] = useIntegrationDelete()

  const [plaidView, setPlaidView] = useState(false)
  const [stripeView, setStripeView] = useState(false)

  const data = useFragment(
    graphql`
      fragment Integrations_company on Company
        @refetchable(queryName: "IntegrationsRefetchQuery")
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 100 }
          cursor: { type: "Cursor" }
        ) {
        id
        integrations(first: $count, after: $cursor) @connection(key: "Integrations_integrations") {
          edges {
            node {
              id
              rowId
              kind
              config
              status
            }
          }
        }
      }
    `,
    company,
  )

  const integrations = data.integrations.edges.map((edge) => edge.node)

  const activeIntegration = integrations.find(
    (integration) => integration.status === 'ENABLED' && integration.kind !== 'plaid',
  )

  const integrationsByKind = keyBy(integrations, 'kind')

  const handleDelete = (integration) => {
    const warning =
      'Are you sure you want to delete this integration? Reports generated from this integration will not be deleted.'

    if (window.confirm(warning)) {
      deleteIntegration({ integrationId: integration.id })
      analytics.track('Integration Removed', { integrationId: integration.rowId })
    }
  }

  const handleConnect = (integration) => {
    const url = api.integration.getConnectUrl(currentCompany.rowId, integration.kind, {
      modal: 'integration-modal',
      modalBehavior: 'full',
      companyId: data.id,
    })
    window.location.href = url
  }

  if (plaidView && plaidEnabled) {
    return <PlaidIntegrations company={company} onDelete={handleDelete} />
  }

  if (stripeView && stripeEnabled) {
    return <StripeIntegration company={company} onDelete={handleDelete} />
  }

  return (
    <FlexColumn>
      <FlexRow flexWrap="wrap" gap="xxlarge">
        {INTEGRATIONS.map((integration) => {
          // return null for xero box if xero feature not enabled
          if (!xeroEnabled && integration.kind === 'xero') {
            return null
          }

          const userIntegration = integrationsByKind[integration.kind]

          return (
            <FlexColumn key={integration.kind} flex="1" flexWrap="wrap">
              <IntegrationBox
                userIntegration={userIntegration}
                logo={integration.logo}
                logoWidth={integration.logoWidth}
                onDelete={() => handleDelete(userIntegration)}
                onConnect={() => handleConnect(integration)}
                integrationKind={integration.kind}
              />
            </FlexColumn>
          )
        })}
      </FlexRow>
      {canViewIds && activeIntegration && (
        <FlexRow mt="medium">
          <Text fontWeight="bold" mr="small">
            Integration Id:
          </Text>
          <Text>{activeIntegration.rowId}</Text>
        </FlexRow>
      )}
      {plaidEnabled && (
        <FlexRow justifyContent="flex-end" alignItems="center" mt="large">
          <Button onClick={() => setPlaidView(true)}>
            <FlexRow alignItems="center">
              <Text mr="medium">Plaid</Text>
              <Icon name="arrow-right" />
            </FlexRow>
          </Button>
        </FlexRow>
      )}
      {stripeEnabled && (
        <FlexRow justifyContent="flex-end" alignItems="center" mt="large">
          <Button onClick={() => setStripeView(true)}>
            <FlexRow alignItems="center">
              <Text mr="medium">Stripe</Text>
              <Icon name="arrow-right" />
            </FlexRow>
          </Button>
        </FlexRow>
      )}
    </FlexColumn>
  )
}
