import { has } from 'lodash'
import {
  typeOneTransactionTypes,
  expenseTransactionTypes,
  transferTransactionTypes,
  typeOneLineCols,
  typeOneExpenseTypes,
  expenseLineCols,
  transferLineCols,
  paymentLineCols,
  billPaymentLineCols,
  depositLineCols,
  journalEntryLineCols,
  accountBasedBillCols,
  splitLineTableTxnTypes,
} from '../../resolve/constants'
import { ResolutionAnomalyType, Column, Transaction, DataSection } from '../../types'
import { TableConnected } from 'elements'
import { deleteLine } from './formUpdates'

const { DeleteCell } = TableConnected as any

type AnomalyCounts = {
  anomalyCountHigh: number
  anomalyCountMedium: number
  anomalyCountLow: number
}

export function getTransactionAnomalyCounts(anomalyTypes: ResolutionAnomalyType[]): AnomalyCounts {
  const anomalyCounts = { anomalyCountHigh: 0, anomalyCountMedium: 0, anomalyCountLow: 0 }

  anomalyTypes.forEach(({ severity }) => {
    if (severity === 0) {
      anomalyCounts.anomalyCountLow++
    }
    if (severity === 1) {
      anomalyCounts.anomalyCountMedium++
    }
    if (severity === 2) {
      anomalyCounts.anomalyCountHigh++
    }
  })

  return anomalyCounts
}

type GetLineColsProps = {
  transactionType: string
  // account vs item based
  detailType?: string
  canEdit?: boolean
  canEditLines?: boolean
  anomalyTypes?: ResolutionAnomalyType[]
}

// map arrays of transaction types to columns
const transactionTypeLineCols = new Map([
  [typeOneTransactionTypes, typeOneLineCols],
  [expenseTransactionTypes, expenseLineCols],
  [transferTransactionTypes, transferLineCols],
  [['payment'], paymentLineCols],
  [['billPayment'], billPaymentLineCols],
  [['deposit'], depositLineCols],
  [['journalEntry'], journalEntryLineCols],
])

const addEditStatus = (col: Column, transactionType: string, canEdit?: boolean) => {
  if (typeOneTransactionTypes.includes(transactionType)) {
    if (col.Header === 'Account') {
      return { ...col, editable: canEdit && typeOneExpenseTypes.includes(transactionType) }
    }
  }

  // only add editability if it's not already set and it has an edit cell
  if (!has(col, 'editable') && col.EditCell) {
    return { ...col, editable: canEdit }
  }

  return col
}

const addEditStatuses = (cols: Column[], transactionType: string, canEdit?: boolean) =>
  cols.map((col) => addEditStatus(col, transactionType, canEdit))

export function getLineColumns(props: GetLineColsProps): Column[] {
  const { transactionType, detailType, canEdit, canEditLines } = props

  let cols: Column[] = []

  if (splitLineTableTxnTypes.includes(transactionType)) {
    if (detailType === 'ItemBasedExpenseLineDetail') {
      cols = typeOneLineCols
    } else {
      cols = accountBasedBillCols
    }
  } else {
    transactionTypeLineCols.forEach((lineCols, txnTypes) => {
      if (txnTypes.includes(transactionType)) {
        cols = lineCols
      }
    })
  }

  cols = addEditStatuses(cols, transactionType, canEdit)

  if (canEdit && canEditLines) {
    cols.push({
      accessor: 'delete',
      Cell: DeleteCell,
      align: 'right',
      handleDelete: deleteLine,
      disableSortBy: true,
    })
  }

  return cols
}

type GetLineDataSectionsProps = {
  transaction: Transaction
  tableOptions?: any
  canEdit?: boolean
  canEditLines?: boolean
  anomalyTypes?: ResolutionAnomalyType[]
}

export function getLineDataSections(props: GetLineDataSectionsProps): DataSection[] {
  const { transaction, tableOptions = {}, canEdit, canEditLines, anomalyTypes } = props
  const { transactionType, transactionLines } = transaction

  const displayLines = transactionLines.filter((line) => !line.deleteLine)

  // display account-based lines for missing checks
  if (anomalyTypes?.[0]?.type === 'missingChecks') {
    return [
      {
        title: 'Category Details',
        data: displayLines,
        columns: getLineColumns({
          transactionType,
          detailType: 'AccountBasedExpenseLineDetail',
        }),
        tableOptions,
      },
    ]
  }

  if (splitLineTableTxnTypes.includes(transactionType)) {
    const accountDataSection = {
      title: 'Category Details',
      key: 'categoryDetails',
      data: displayLines.filter(
        (line: any) => line.lineDetailType === 'AccountBasedExpenseLineDetail',
      ),
      columns: getLineColumns({
        transactionType,
        detailType: 'AccountBasedExpenseLineDetail',
        canEdit,
        canEditLines,
      }),
      tableOptions,
    }

    const itemDataSection = {
      title: 'Item Details',
      key: 'itemDetails',
      data: displayLines.filter(
        (line: any) => line.lineDetailType === 'ItemBasedExpenseLineDetail',
      ),
      columns: getLineColumns({
        transactionType,
        detailType: 'ItemBasedExpenseLineDetail',
        canEdit,
        canEditLines,
      }),
      tableOptions,
    }

    const dataSections = [accountDataSection, itemDataSection]

    // in queue sub-table, only show tables that have lines (and empty account lines data section if no lines)
    if (!canEdit) {
      if (!displayLines) {
        return [accountDataSection]
      }

      return dataSections.filter((section) => section.data.length)
    }

    // for editable modal, always display account and item sections so that you can add either type of line
    return dataSections
  }

  return [
    {
      data: displayLines,
      columns: getLineColumns({ transactionType, canEdit, canEditLines, anomalyTypes }),
      tableOptions,
    },
  ]
}
