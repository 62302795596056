import React from 'react'
import PropTypes from 'prop-types'

export default function ScreenModalContainer({
  activeModals,
  closeModal,
  onModalClosed,
  setHistoryBlock,
}) {
  const activeModal = activeModals[0]
  const Modal = activeModal?.modalType

  if (!Modal) {
    return null
  }

  return (
    <Modal
      {...activeModal.modalProps}
      key={activeModal.modalId}
      modal={{
        behavior: activeModal.modalBehavior,
        isClosing: activeModal.isClosing,
        isFocused: activeModal.isFocused,
        onClose: () => closeModal(activeModal.modalId),
        onAfterClose: () => onModalClosed(activeModal.modalId),
        setHistoryBlock: (fn) => setHistoryBlock(activeModal.modalId, fn),
      }}
    />
  )
}

ScreenModalContainer.propTypes = {
  activeModals: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  setHistoryBlock: PropTypes.func.isRequired,
}
