import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth, useMountEffect } from 'hooks'
import { Box, Text } from 'elements'

export default function Logout() {
  const history = useHistory()
  const auth = useAuth()

  useMountEffect(() => {
    // log the user out on mount
    async function logout() {
      await auth.logout()

      // redirect to login page
      history.replace('/auth/login')
    }

    logout()
  })

  return (
    <Box display="flex" justifyContent="center">
      <Text>Logging out...</Text>
    </Box>
  )
}
