import axios, { AxiosResponse } from 'axios'
import { setQueryParams, unsetQueryParams } from 'utils/url'
import { PlaidAccount } from '../../components/modules/integrations/plaid/types'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
})

async function handleResult(request: Promise<AxiosResponse>): Promise<any> {
  const result = await request
  return result.data
}

const client = {
  app: {
    reportError({ stack, message, ...error }: any = {}, info: any = {}) {
      // NOTE: if the script throwing the error is loaded from a different domain,
      // the msg will be "Script error" and the rest of the args will be undefined for
      // cross-origin security reasons. See https://blog.sentry.io/2016/05/17/what-is-script-error
      const body = {
        ...info,
        error,
        stack,
        message,
        userAgent: window.navigator.userAgent,
        path: window.location.pathname,
      }

      // send the error to the server, but don't await it
      api.post(`/errors/report`, body)
    },
  },

  auth: {
    getStrategyUrl(strategy: string): string {
      const next = window.location.origin
      return `${process.env.REACT_APP_API_HOST}/auth/${strategy}?next=${next}`
    },

    register(...args: any[]) {
      return handleResult(api.post('/auth/cognito/register', ...args))
    },

    verify(...args: any[]) {
      return handleResult(api.post('/auth/cognito/verify', ...args))
    },

    resendVerification(...args: any[]) {
      return handleResult(api.post('/auth/cognito/resend-verification', ...args))
    },

    login(...args: any[]) {
      return handleResult(api.post('/auth/cognito/login', ...args))
    },

    logout(...args: any[]) {
      return handleResult(api.get('/auth/logout', ...args))
    },

    forgotPassword(...args: any[]) {
      return handleResult(api.post('/auth/cognito/forgot-password', ...args))
    },

    resetPassword(...args: any[]) {
      return handleResult(api.post('/auth/cognito/reset-password', ...args))
    },

    getUserAttributes(...args: any[]) {
      return handleResult(api.post('/auth/cognito/get-user-attributes', ...args))
    },

    sendPhoneVerification(...args: any[]) {
      return handleResult(api.post('/auth/cognito/send-phone-verification', ...args))
    },

    verifyPhone(...args: any[]) {
      return handleResult(api.post('/auth/cognito/verify-phone', ...args))
    },

    updateMfaSettings(...args: any[]) {
      return handleResult(api.post('/auth/cognito/update-mfa-settings', ...args))
    },
  },

  user: {
    upload(...args: any[]) {
      return handleResult(api.post('/user/uploads', ...args))
    },
    download(uploadId: string, ...args: any[]) {
      return handleResult(api.get(`/user/uploads/${uploadId}/object`))
    },
  },

  integration: {
    getConnectUrl(companyId: string, kind: string, params: any = {}): string {
      const href = unsetQueryParams(window.location.href, ['integrationError'])
      const next = setQueryParams(href, { integrationKind: kind, ...params })

      return `${
        process.env.REACT_APP_API_HOST
      }/integrations/${kind}/connect?companyId=${companyId}&next=${encodeURIComponent(next)}`
    },

    verify(...args: any[]) {
      return handleResult(api.post('/integrations/verify', ...args))
    },
  },

  plaid: {
    createLinkToken() {
      return handleResult(
        api.post('/integrations/plaid/create-link-token', { referrer: window.location.href }),
      )
    },
    callback(body: { publicToken: any; companyId: string }) {
      return handleResult(
        api.post('/integrations/plaid/callback', {
          ...body,
          referrer: window.location.href,
        }),
      )
    },
    postTransactions(body: { startDate: string; endDate: string; accounts: PlaidAccount[] }) {
      return handleResult(
        api.post('/integrations/plaid/plaid-start', {
          ...body,
          referrer: window.location.href,
        }),
      )
    },
  },

  stripe: {
    createCheckoutSession(...args: any[]) {
      const returnUrl = unsetQueryParams(window.location.href, ['status', 'error'])
      return handleResult(api.post('/stripe/create-checkout-session', { returnUrl, ...args[0] }))
    },
    createPortalSession(...args: any[]) {
      const returnUrl = unsetQueryParams(window.location.href, ['status', 'error'])
      return handleResult(api.post('/stripe/create-portal-session', { returnUrl, ...args[0] }))
    },
    postData(body: {
      startDate: string
      endDate: string
      timeZone?: string
      integrationId: string
    }) {
      return handleResult(
        api.post('/stripe/start', {
          ...body,
          referrer: window.location.href,
        }),
      )
    },
  },
}

export default function useApi() {
  return client
}
