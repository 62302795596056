import { sortBy } from 'lodash'
import { Option } from '../../../../shared/types'

type GetEntityOptionsProps = {
  entityType?: string | null
  customers: any[]
  vendors: any[]
}

export function getEntityOptions(props: GetEntityOptionsProps): any[] {
  const { entityType, customers, vendors } = props

  const customerOptions = sortBy(
    customers.map((customer) => ({
      label: customer.customerFullyQualifiedName,
      value: {
        id: customer.customerId,
        name: customer.customerFullyQualifiedName,
        type: 'customer',
        billAddress: customer.customerBillingAddressRaw,
        shipAddress: customer.customerShippingAddressRaw,
        email: customer.customerEmailAddress,
      },
    })),
    'label',
  )

  const vendorOptions = sortBy(
    vendors.map((vendor) => ({
      label: vendor.vendorFullyQualifiedName,
      value: {
        id: vendor.vendorId,
        name: vendor.vendorFullyQualifiedName,
        type: 'vendor',
        billAddress: vendor.vendorBillingAddressRaw,
        shipAddress: vendor.vendorShippingAddressRaw,
        email: vendor.vendorEmailAddress,
      },
    })),
    'label',
  )

  if (entityType === 'customer') {
    return customerOptions
  }

  if (entityType === 'vendor') {
    return vendorOptions
  }

  return [...customerOptions, ...vendorOptions]
}

export function getAccountOptions(accounts: any[], includedTypes?: string[]): Option[] {
  const accountOptions = accounts
    .filter((account: any) => !includedTypes || includedTypes.includes(account.accountType))
    .map((account: any) => ({
      label: account.accountName,
      value: account,
    }))

  return sortBy(accountOptions, 'label')
}

type ItemAccountType = 'income' | 'expense'

// only include items that have a linked account of the given type
export function getItemOptions(items: any[], itemAccountType?: ItemAccountType): Option[] {
  let filteredItems = items

  if (itemAccountType === 'income') {
    filteredItems = items.filter((item) => !!item.itemIncomeAccountId)
  }

  if (itemAccountType === 'expense') {
    filteredItems = items.filter((item) => !!item.itemExpenseAccountId)
  }

  const itemOptions = filteredItems.map((item) => ({ label: item.itemName, value: item }))

  return sortBy(itemOptions, 'label')
}
