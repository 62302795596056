import React from 'react'
import { pickBy, findKey } from 'lodash'
import { guessFileKind } from 'utils/automap'
import { Box, Text, Table as Tables, FileUpload, Select } from 'elements'

const { Table, Head, Body, Row, HeadCell, Cell } = Tables.Simple

export default function ReportFileUpload({
  submitting,
  files,
  setFiles,
  uploadSource,
  fileKinds,
  setFileKinds,
  sourceFileKindOptions,
}) {
  return (
    <Box display="flex" flexDirection="column" width="100%" py="xxlarge" overflowY="auto">
      <Box mb="medium">
        <FileUpload
          disabled={submitting}
          multiple
          allowedTypes={['xlsx']}
          onUpload={(selectedFiles) => {
            setFiles([...files, ...selectedFiles])

            // guess file kinds and set those too
            const selectedFileKinds = {}

            selectedFiles.forEach((file) => {
              const kind = guessFileKind(file, uploadSource)

              if (kind) {
                selectedFileKinds[kind] = file
              }
            })

            setFileKinds({ ...fileKinds, ...selectedFileKinds })
          }}
        />
      </Box>
      {files?.length ? (
        <Table>
          <Head>
            <Row>
              <HeadCell>File Name</HeadCell>
              <HeadCell>File Type</HeadCell>
            </Row>
          </Head>
          <Body>
            {files.map((file, idx) => (
              <Row key={[file.name, idx]}>
                <Cell>
                  <Text truncate title={file.name}>
                    {file.name}
                  </Text>
                </Cell>
                <Cell>
                  <Select
                    disabled={submitting}
                    isClearable
                    options={sourceFileKindOptions}
                    value={sourceFileKindOptions.find(
                      (opt) => opt.value === findKey(fileKinds, file),
                    )}
                    onChange={(option, change) => {
                      if (option) {
                        setFileKinds({ ...fileKinds, [option.value]: file })
                      } else {
                        setFileKinds(pickBy(fileKinds, (value) => value !== file))
                      }
                    }}
                  />
                </Cell>
              </Row>
            ))}
          </Body>
        </Table>
      ) : null}
    </Box>
  )
}
