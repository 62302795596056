import { TableConnected } from 'elements'
import { Column } from './types'

// @ts-ignore
const { StandardCell, DateCell, LedgerCell, NumericCell } = TableConnected

export const workspaceDescriptions: Record<string, string> = {
  transactionInsights:
    'Review the patterns associated with the type and volume of transactions in the period.',
  anomalyInsights:
    'Review patterns associated with the type and volume of transaction flags in the period.',
  userInsights:
    'Ensure that user activity patterns are correct based on roles and responsibilities.',
  defaultTransactionFlags:
    'Review flagged transactions by category for errors that require attention and remediation.',
  customTransactionFlags: 'Custom queries on general ledger data.',
  vendorInsights:
    'Review vendor usage patterns for opportunities to clean the vendor list, complete profiles, or assess the level of activity for each.',
  customerInsights:
    'Review customer usage patterns for opportunities to clean the customer list, complete profiles, or assess the level of activity for each.',
  accountInsights:
    'Review account usage patterns for opportunities to clean the account list or assess the level of activity for each.',
  arAging:
    "Ensure the health of the company's receivables, identify misapplications of receipts, and identify opportunities to collect outstanding balances.",
  apAging:
    "Ensure the accuracy of the company's payables, identify misapplications of payments, and uncover potentially incorrect balances.",
  profitAndLossVariance:
    'Review the period over period P&L for any large variances that could be due to duplication, missing data, or incorrectly coded transactions.',
  balanceSheetVariance:
    'Review the period over period B.S. for any large variances that could be due to duplication, missing data, or incorrectly coded transactions.',
}

//some duplicate descriptions with different keys for backwards compatability
export const anomalyDescriptions: Record<string, string> = {
  unreconciledBankTrans: 'Unreconciled bank transactions older than specific number of days.',
  unreconciledBankTransactions:
    'Unreconciled bank transactions older than specific number of days.',
  unclearedBankTrans: 'Unreconciled bank transactions older than specific number of days.',
  arAging: 'Outstanding accounts receivable older than specific number of days.',
  uncategorizedAssets: 'Transactions coded to "Uncategorized Assets" account.',
  unreconciledCcTrans: 'Unreconciled CC transactions older than specific number of days.',
  unreconciledCcTransactions: 'Unreconciled CC transactions older than specific number of days.',
  unclearedCcTrans: 'Unreconciled CC transactions older than specific number of days.',
  apAging: 'Outstanding accounts payable older than specific number of days.',
  vendorNamesInIncome: 'Transactions coded to an income account and tagged with Vendor name.',
  uncategorizedIncome: 'Transactions coded to "Uncategorized Income" account.		',
  missingInvoices: 'Gaps in the invoice register.',
  duplicateInvoices: 'Duplicates in the invoice register.',
  customerNamesInExpenses:
    'Transactions coded to an expense account and tagged with Customer name.',
  uncategorizedExpenses:
    'Transactions coded to "Uncategorized Expense", "Ask My Accountant", "Not Specified", or "Reconciliation Discrepancies".',
  missingChecks: 'Gaps in the check register.',
  duplicateChecks: 'Duplicates in the checks register.',
  duplicateBills: 'Duplicate bills for the same vendor.',
  checksToCashOrNoPayee: 'Checks made out to "Cash" or with no payee.',
  roundDollarPayments: 'Payments for round dollar amounts.',
  updatedSystemAdminTrans:
    'Manually updated transactions originally created by "System Administration" and "Online Banking Administration".',
  updatedSystemAdminTransactions:
    'Manually updated transactions originally created by "System Administration" and "Online Banking Administration".',
  expensesWithNoPayee: 'Expense transactions without a payee name.',
  nonNumericChecks: 'Checks with alphabetical or special characters in the number.',
  checksToVendorsWithBills:
    'Checks written against expense accounts for vendors with amounts in Accounts Payable.',
  accountsReceivableCredits: 'Credit memos or journal entries taken against accounts receivable.',
  depositsFromCustomersWithInvoices:
    'Deposits straight to income accounts for customers with amounts in Accounts Receivable.',
  debitsToIncome: 'Debits taken against income account types.',
  creditsToExpense: 'Credits taken against expense account types.',
  expensesToVendorsWithBills:
    'Expense transaction types associated with vendors with amounts in Accounts Payable.',
  duplicateExpenses: 'Duplicate expenses for the same vendor.',
  inconsistentVendorCoding: 'Vendor transactions inconsistently coded to accounts.',
  inconsistentCustomerCoding: 'Customer transactions inconsistently coded to accounts.',
  unappliedPayments:
    'Payment transactions where an outstanding amount has not been applied to a Customer invoice.',
  undepositedFunds: 'Payments and Sales Receipts that have not been added to a Deposit.',
  codedToParentAccount: 'Transactions that have been coded to parent accounts.',
}

export const metadataDescriptions: Record<string, string> = {
  accountsUnused:
    'Lists the total number of accounts in the chart of accounts that were not used inside the report period. Accounts that are not getting used recently should be inactivated to prevent clerical errors.',
  customersUnused:
    'Lists the total number of active customers in the ledger software that were not used inside the report period. Customers that are not getting used recently should be inactivated to prevent clerical errors.',
  vendorsUnused:
    'Lists the total number of active vendors in the ledger software that were not used inside the report period. Vendors that are not getting used recently should be inactivated to prevent clerical errors.',
}

type AnomalyInfoObject = {
  meaning: string
  reason: string
  remedy: string
}

export const anomalyInfo: Record<string, AnomalyInfoObject> = {
  accountsReceivableCredits: {
    meaning: 'Credit Memos or Journal Entry credits booked to the Accounts Receivable account.',
    reason:
      'Excessive credit entries booked to the Accounts Receivable account that are not related to receipt of payment can be a sign of fraudulent schemes or improper accounting procedures.',
    remedy:
      'Each non-payment credit to the Accounts Receivable account should have supporting documentation attached to the transaction that includes a sufficiently detailed explanation of the transaction and the identifies the responsible party.',
  },
  checksToCashOrNoPayee: {
    meaning: 'Check transaction type with the payee name "Cash" or with no payee name.',
    reason:
      'All transactions should have a name associated with them that corresponds to the other party in the transaction. Transactions without proper names are often clerical errors, but can be used to cover up fraud.',
    remedy: 'Update the name field of the transaction with the appropriate name.',
  },
  checksToVendorsWithBills: {
    meaning:
      'Checks written to Vendors that are booked directly to expense accounts for Vendors who also have Bills booked to the Accounts Payable account.',
    reason:
      'Vendor payments should be applied directly to their Bills instead of booked directly to an expense account. This pattern can signify overstated expenses, overstated Accounts Payable balances, incorrectly applied Vendor payments, or potentially fraudulent activity.',
    remedy:
      'If the Check was supposed to be a payment for a Bill that already exists in Accounts Payable, create a Vendor Credit for the same date as the Check and book it to the account where the original Check was booked. If the Check was for a Bill that has not been entered, enter the Bill as normal, then create a Vendor Credit for the same date as the Check and book it to the account where the original Check was booked. In either instance, attach supporting documentation on each transaction that references the other linked transactions.',
  },
  creditsToExpense: {
    meaning: 'Credits that are booked to expense account types.',
    reason:
      'A large volume of credits booked to expense accounts can signal incorrectly booked revenue, or excessive Vendor refunds linked to fraudulent purchasing schemes or control breakdowns.',
    remedy:
      'Review the credit to ensure it is booked to the appropriate account and has sufficient supporting documentation.',
  },
  customerNamesInExpenses: {
    meaning: 'Transactions booked to an expense account, but tagged with Customer name.',
    reason:
      'There is usually very little overlap between Vendor and Customer names. Often this signals that a deposit or return was incorrectly recorded.',
    remedy:
      'Review the transaction to determine whether it should be tagged to a Vendor name, or is incorrectly categorized as an Expense instead of a Refund to a Customer.',
  },
  debitsToIncome: {
    meaning: 'Debits that are booked to income account types.',
    reason:
      'A large volume of debits booked to income accounts can signal incorrectly booked expenses, or excessive refund activity linked to fraudulent Customer schemes or control breakdowns.',
    remedy:
      'Review the debit to ensure it is booked to the appropriate account and has sufficient supporting documentation.',
  },
  depositsFromCustomersWithInvoices: {
    meaning:
      'Deposits from Customers that are booked directly to income accounts for Customers who also have Invoices booked to the Accounts Receivable account.',
    reason:
      'Customer deposits should be applied directly to their Invoices instead of booked directly to an income account. This pattern can signify overstated revenue, overstated Accounts Receivable balances, incorrectly applied Customer deposits, or potentially fraudulent activity.',
    remedy:
      'If the Deposit was supposed to be a payment for an Invoice that already exists in Accounts Receivable, create a Credit Memo for the same date as the Deposit and book it to the account where the original Deposit was booked. If the Deposit was for an Invoice that has not been entered, enter the Invoice as normal, then create a Credit Memo for the same date as the Deposit and book it to the account where the original Deposit was booked. In either instance, attach supporting documentation on each transaction that references the other linked transactions.',
  },
  duplicateBills: {
    meaning:
      'Bill transaction types where the Vendor name and Bill transaction number are the same as other Bills.',
    reason:
      'All transactions should have unique identifying transaction numbers. Duplication is often a clerical error, but can signal more serious issues.',
    remedy:
      'Review the duplicate Bills and supporting documents to assess which one(s) may need to be updated or voided.',
  },
  duplicateChecks: {
    meaning:
      'Check transaction types from the same bank account that share the same transaction number as other Checks.',
    reason:
      'All transactions should have unique identifying transaction numbers. Duplication is often a clerical error, but can signal more serious issues.',
    remedy:
      'Review the duplicate Checks and supporting documents to assess which one(s) may need to be updated or voided.',
  },
  duplicateExpenses: {
    meaning:
      'Expense transaction types where the Vendor name, transaction number, transaction date, and transaction amount are the same as other Expenses.',
    reason: 'Duplication is often a clerical error, but can signal more serious issues.',
    remedy:
      'Review the duplicate Expenses and supporting documents to assess which one(s) may need to be updated or voided.',
  },
  duplicateInvoices: {
    meaning:
      'Invoice transaction types where the Invoice transaction number is the same as other Invoices.',
    reason:
      'All transactions should have unique identifying transaction numbers. Duplication is often a clerical error, but can signal more serious issues.',
    remedy:
      'Review the duplicate Invoices and supporting documents to assess which one(s) may need to be updated or voided.',
  },
  expensesToVendorsWithBills: {
    meaning: 'Expense transaction types associated with vendors with amounts in Accounts Payable.',
    reason:
      'Vendor payments should be applied directly to their Bills instead of booked directly to an expense account. This pattern can signify overstated expenses, overstated Accounts Payable balances, incorrectly applied Vendor payments, or potentially fraudulent activity.',
    remedy:
      'If the Expense was supposed to be a payment for a Bill that already exists in Accounts Payable, create a Vendor Credit for the same date as the Expense and book it to the account where the original Expense was booked. If the Expense was for a Bill that has not been entered, enter the Bill as normal, then create a Vendor Credit for the same date as the Expense and book it to the account where the original Expense was booked. In either instance, attach supporting documentation on each transaction that references the other linked transactions.',
  },
  expensesWithNoPayee: {
    meaning: 'Expense transaction type with the no payee name.',
    reason:
      'All transactions should have a name associated with them that corresponds to the other party in the transaction. Transactions without proper names are often clerical errors, but can be used to cover up fraud.',
    remedy: 'Update the name field of the transaction with the appropriate name.',
  },
  inconsistentCustomerCoding: {
    meaning:
      'Transaction tagged to a Customer where the account selected is inconsistent with where other transactions to this Customer are booked.',
    reason: 'This can often be a sign of a clerical error.',
    remedy:
      'Review the transactions for this Customer to ensure they are booked to the correct accounts.',
  },
  inconsistentVendorCoding: {
    meaning:
      'Transaction tagged to a Vendor where the account selected is inconsistent with where other transactions to this Vendor are booked.',
    reason: 'This can often be a sign of a clerical error.',
    remedy:
      'Review the transactions for this Vendor to ensure they are booked to the correct accounts.',
  },
  missingChecks: {
    meaning: 'Gaps in the sequence of numbers for Check transaction types.',
    reason:
      'This happens most often when someone deletes a Check that should have been voided. However, sometimes it is related to Checks that have been issued but not yet cashed by the recipient, which leads to understated expenses and overstated cash. In more serious cases, it can be used to cover up internal fraud.',
    remedy:
      'Review the physical check stock to see if the check number in question is still in your possession. If you find a physical check is missing, ask the members of the team who have access to the checks if they know how or why this happened. Then consider calling the bank to put a stop payment on that specific check number. If you find that the check was issued, but not recorded, create a Check transaction type to record the transaction in the ledger with the appropriate information. If the physical Check was voided or destroyed, create a Check transaction type with that number in the ledger, then void it out so the sequence is preserved.',
  },
  missingInvoices: {
    meaning: 'Gaps in the sequence of numbers for Invoice transaction types.',
    reason:
      'This happens most often when someone deletes an Invoice that should have been voided. However, sometimes it is related to invoices that have been issued outside the system, but not yet booked, which leads to understated income and accounts receivable. In more serious cases, it can be used to cover up internal fraud.',
    remedy:
      'Ask the members of the team who issue invoices if they know how or why this happened. If you find that the invoice was issued, but not recorded, create an Invoice transaction type to record the transaction in the ledger with the appropriate information. If the invoice needs to be voided, create an Invoice transaction type with that number in the ledger, then void it out so the sequence is preserved.',
  },
  nonNumericChecks: {
    meaning: 'Check transaction types with alphabetical or special characters in the number.',
    reason:
      'Checks issued from banks should only ever use the numerical transaction identifiers that are printed on the physical check stock. Using alphabetical or special characters usually signifies that the transaction was entered incorrectly as a Check transaction type when it should have been another transaction type, or that the issuer made a clerical error when recording the number.',
    remedy:
      'If the check numbering issue was due to clerical error, update the ledger with the correct number. If you find that it was entered incorrectly as a Check transaction type instead of another more appropriate type (like an Expense for example), void the Check transaction and recreate the transaction as the correct transaction type.',
  },
  uncategorizedAssets: {
    meaning: `Transactions booked to the "Uncategorized Assets" account.`,
    reason:
      'Transactions should be booked to descriptively named accounts and not parked in suspense accounts. Amounts left for in this account can signal poor internal communication and lack of attention to detail.',
    remedy:
      'Find the team member responsible for booking the transaction to this account, and ask them why it is there. Once the necessary level of information is provided, move the transaction to the correct account.',
  },
  uncategorizedExpenses: {
    meaning: `Transactions booked to the "Uncategorized Expense", "Ask My Accountant", "Not Specified", or "Reconciliation Discrepancies" accounts.`,
    reason:
      'Transactions should be booked to descriptively named accounts and not parked in suspense accounts. Amounts left for in this account can signal poor internal communication and lack of attention to detail.',
    remedy:
      'Find the team member responsible for booking the transaction to this account, and ask them why it is there. Once the necessary level of information is provided, move the transaction to the correct account.',
  },
  uncategorizedIncome: {
    meaning: `Transactions booked to the "Uncategorized Income" account.`,
    reason:
      'Transactions should be booked to descriptively named accounts and not parked in suspense accounts. Amounts left for in this account can signal poor internal communication and lack of attention to detail.',
    remedy:
      'Find the team member responsible for booking the transaction to this account, and ask them why it is there. Once the necessary level of information is provided, move the transaction to the correct account.',
  },
  unreconciledBankTransactions: {
    meaning: 'All unreconciled bank transactions.',
    reason:
      'These transactions should be inspected to identify whether they were incorrectly added to the bank account or if the account needs to be reconciled more frequently. If a transaction appears to have been unreconciled for a significant amount of time, it could be a duplicate, booked to the wrong bank account, or related to an issued check that was never cashed.',
    remedy:
      'Start by reconciling the bank account through the most recently closed period. Then, ensure that the transaction is booked to the correct bank account by reviewing the bank statements or other supporting documentation. Next, void the transaction if it is determined to be a duplicate. If it was related to payment that was lost in transit, void the initial payment in the ledger and bank, then reissue it to the other party.',
  },
  unreconciledCcTransactions: {
    meaning: 'All unreconciled credit card transactions.',
    reason:
      'These transactions should be inspected to identify whether they were incorrectly added to the credit card account or if the account needs to be reconciled more frequently. If a transaction appears to have been unreconciled for a significant amount of time, it could be a duplicate or booked to the wrong credit card account.',
    remedy:
      'Start by reconciling the credit card account through the most recently closed period. Then, ensure that the transaction is booked to the correct credit card account by reviewing the credit card statements or other supporting documentation. Next, void the transaction if it is determined to be a duplicate.',
  },
  updatedSystemAdminTransactions: {
    meaning:
      'Transactions originally created by "System Administration" and "Online Banking Administration" that have since been updated by other users.',
    reason:
      'Users should generally not have to update transactions created by these account types. Inspect these transactions for any suspicious signs that dates, amounts, or payees were updated after the fact.',
    remedy:
      'Review the transactions that were flagged to ensure the changes were correct and necessary.',
  },
  vendorNamesInIncome: {
    meaning: 'Transactions booked to an income account and tagged with Vendor name.',
    reason:
      'There is usually very little overlap between Vendor and Customer names. Often this signals that a deposit or return was incorrectly recorded.',
    remedy:
      'Review the transaction to determine whether it should be tagged to a Customer name, or is incorrectly categorized as Income instead of an Expense.',
  },
  unappliedPayments: {
    meaning:
      'Payment transactions that still have an open balance because they have not been fully applied against a receivable.',
    reason:
      'Often this is caused by not applying a payment against an oustanding receivable, but can also be a pre-payment or overpayment.',
    remedy:
      'Check outstanding receivables for the customer that made the payment to see if there are any balances the payment should be applied against. If not, document whether it is an overpayment or pre-payment so it can be refunded or applied at a later date.',
  },
  undepositedFunds: {
    meaning:
      'Funds that have been recorded as collected, but not deposited to a bank account. This can occur when a large cash balance or volume of checks accumulates at a location or when the payment application process is not being performed regularly.',
    reason:
      'Large balances in Undeposited Funds present a potential issue in understanding where the company’s cash is at any given time. If cash and checks are being stored physically at a location, it also presents a high risk of theft.',
    remedy:
      'Deposit collected funds into a bank account and then relieve the Undeposited Funds account for those amounts. Oustanding amounts should be tracked down and reconciled regularly to prevent application errors before they accumulate.',
  },
  codedToParentAccount: {
    meaning:
      'When an account in the chart of accounts has children (subsidiary) accounts, but the parent account has transactions coded to it.',
    reason:
      'The account with the highest level of granularity should be used to code transactions so the financial statements remain easy to read and understand.',
    remedy:
      'Code the transaction to a child account if present, or create one that properly describes the category of expense needed.',
  },
}

export const insights = {
  transactionsByAccountType: [
    `Income, Expense, Bank and Credit Card accounts usually have the highest volume of transactions. If other account types have higher transaction volumes, it can be a sign of incorrectly coded or missing transactions.`,
    `Account types with a low volume of transactions should be checked to see if any unused accounts can be made inactive to keep the Chart of Accounts clean.`,
  ],
  lastReconciledTransactionDate: [
    `Accounts where a large amount of time has elapsed since the last reconciliation should be examined to determine if there are missing transactions, why the last reconciled transaction is so old, or if the account is no longer in use.`,
  ],
  transactionCountByMonth: [
    `Growing transaction volume is usually a sign that you need to readdress the scope and price of your engagement. It can also help signal where capacity issues may arise. Shrinking volume could be a sign of missing transactions.`,
  ],
  transactionsByType: [
    `Understanding the volume of transactions by count, line item and amount can assist in the creation of proper scopes when prospecting new clients or reassessing the price being charged to existing clients. All three metrics should be used to weigh the level of complexity the client requires in their bookkeeping.`,
  ],
  transactionsBankCreditCardAccount: [
    `Identifying the source of transaction volume can be a helpful tool in assessing the level of complexity required to import the transactions into a ledger. For example, small banks that don't have a direct integration with the ledger will take more time than tech-forward institutions.`,
    `Whenever possible consolidate spend across bank and credit cards and inactivate unused accounts in the ledger.`,
  ],
  accountCountByType: [
    `Income, Expense, Bank and Credit Card accounts are usually the most commonly used account types. If other account types are being used more often, it can be a sign of incorrectly coded or missing transactions.`,
    `Account types with a lot of unused accounts should be checked to see if any unused accounts can be made inactive to keep the Chart of Accounts clean.`,
  ],
  chartOfAccountsUsage: [
    `Accounts that aren't frequently used should be turned off to keep the Chart of Accounts clean.`,
    `Check the Created At field for each Account to verify that all newly created Accounts are necessary.`,
  ],
  anomalyByType: [
    `Consistent anomalies can be a sign of training gaps or process breakdowns in the team's workflow.`,
    `Analyze the dollar-based impact of anomalies to ensure no materially adverse effects are impacting the quality of the financial statements.`,
  ],
  vendorCompletenessScore: [
    'Ensure that Vendor profile information is as complete as possible to make identity validation easier and reduce the risk of fraud.',
  ],
  vendorUsage: [
    `Vendors that aren't frequently used should be turned off to keep the Vendor list clean.`,
    'Check the Created At field for each Vendor to verify that all newly created Vendors are necessary.',
  ],
  transactionByVendor: [
    'Negotiating more favorable terms with the most used Vendors based on volume or amount spent could be beneficial to cashflow.',
    'Encouraging the client to get multiple quotes from Vendors can reduce costs and improve profitability.',
  ],
  customerCompletenessScore: [
    'Ensure that Customer profile information is as complete as possible to make identity validation easier and reduce the risk of fraud.',
  ],
  customerUsage: [
    `Customers that aren't frequently used should be turned off to keep the Customer list clean.`,
    'Check the Created At field for each Customer to verify that all newly created Customers are necessary.',
  ],
  transactionByCustomer: [
    'Negotiating more favorable terms with the Customers responsible for generating the most revenue can be beneficial to cashflow.',
    'Concentration risk can pose an issue for the company if too much income is being derived from one customer.',
  ],
  transactionTypeByUser: [
    "The person creating the most transaction volume in the books should be the primary one responsible for the quality of the client's books.",
    'The person creating transactions with the highest aggregate dollar impact should have their work reviewed for errors as any issues are likely to be material.',
  ],
  transactionVolumeByUser: [
    "Did anyone modify more transactions than they created? This shouldn't be happening on a regular basis as it means there is a lot of rework being done.",
    'What percentage of transactions that were created were later modified? This is a sign of rework and generally signifies inefficient processes or training gaps.',
  ],
  bankRuleTransactions: [
    'Using bank rules is a great way to improve efficiency and reduce manual coding of bank and credit card transactions.',
  ],
}

export const insightDescriptions = {
  accountCountByType: `lists each account type in the client's chart of accounts and the number of each type that are active. Accounts can often be combined to reduce unneeded granularity in the chart of accounts. Other custom fields are usually a better way to track granular data.`,
  bankRuleTransactions: `is the percentage of transactions where money was spent that were added by bank rules to Profit & Loss accounts.`,
  chartOfAccountsUsage: `lists the total number of accounts in the chart of accounts and the number that were used inside the report period. Accounts that are not getting used recently should be inactivated to prevent clerical errors.`,
  anomalyByType: `lists the count, dollar amount and severity of the various anomalies that were flagged in a given period. The fewer anomalous transactions the better. A single transaction may have multiple issues, so use the Anomaly Resolution queue to work through the individual transactions that are flagged.`,
  transactionsByAccountType: `lists the number of transactions in the period by the type of account. This gives sense of which accounts are most interacted with in the period. Knowing which parts of your financial statements have the most transaction activity can help you understand where there may be room for improvement and where errors are more likely to occur.`,
  lastReconciledTransactionDate: `lists each bank and credit card account as well as the date of the last reconciled transaction in each account and the number of days that have elapsed between that transaction and the end of the report period. A large number of days elapsed can signal that the account has not been reconciled in a long time or is no longer being used.`,
  transactionByType: `lists the number of transactions in the period by the type of account. This gives sense of which accounts are most interacted with in the period. Knowing which parts of your financial statements have the most transaction activity can help you understand where there may be room for improvement and where errors are more likely to occur.`,
  transactionCountByMonth: `lists the volume of transactions for each month in the report period to highlight any seasonality that may be present in the client's business. This is important to know for capacity planning at your firm (to handle the spikes in transaction volume) as well as cash flow planning for your client.`,
  transactionsBankCreditCardAccount: `lists the count and type of transaction for each bank and credit card account used in the period. This gives you a sense of which bank and credit card accounts have the most activity, and whether or not centralizing activity may reduce the ongoing account management.`,
  vendorCompletenessScore: `generates a score based on completeness of vendor profiles inside the ledger software. Complete profiles reduce the chance of error internally, and make fraud harder to commit.`,
  vendorUsage:
    'lists the total number of active vendors in the ledger software and the number that were used inside the report period. Vendors that are not getting used recently should be inactivated to prevent clerical errors.',
  transactionByVendor: `lists the count and amount of transactions for each active vendor in the report period. This can be used to understand where the majority of your client's money is going, and who to approach to negotiate more favorable terms for cash flow reasons.`,
  customerCompletenessScore:
    'generates a score based on completeness of customer profiles inside the ledger software. Complete profiles reduce the chance of error internally, and make fraud harder to commit.',
  customerUsage:
    'lists the total number of active customers in the ledger software and the number that were used inside the report period. Customers that are not getting used recently should be inactivated to prevent clerical errors.',
  transactionByCustomer: `lists the count and amount of transactions for each active customer in the report period. This can be used to understand where the majority of your client's money is coming from, and who to approach to negotiate more favorable terms for cash flow reasons.`,
  transactionVolumeByUser: `lists the count of transactions created, transactions modified, and anomalies for each active user in the period. This can help identify control or process breakdowns as well as any training gaps causing anomalies in the client data.`,
  transactionTypeByUser: `lists the count and type of transactions created by each user in the report period. This helps identify control breakage and capacity issues where the transaction load isn't being spread according to your internal assignment.`,
}

export const excludedColumns = ['anomalyDescription', 'transactions', 'globalId', 'transactionId']

export const standardColumns: Column[] = [
  { Header: 'Date', accessor: (row: any) => row.transactionDate ?? row.date, Cell: DateCell },
  {
    Header: 'Num',
    accessor: (row: any) => row.transactionNum || row.num,
    truncate: true,
    Cell: StandardCell,
  },
  { Header: 'Type', accessor: (row: any) => row.transactionType || row.type, Cell: StandardCell },
  {
    Header: 'Name',
    accessor: (row: any) => row.transactionEntityName ?? row.entityName,
    Cell: StandardCell,
  },
  { Header: 'Description', accessor: 'memoDescription', Cell: StandardCell },
  {
    Header: 'Account',
    accessor: (row: any) => row.transactionAccount ?? row.account,
    Cell: StandardCell,
  },
  {
    Header: 'Created By',
    accessor: (row: any) => row.createdBy || row.transactionCreatedBy,
    Cell: StandardCell,
  },
  {
    Header: 'Amount',
    accessor: (row: any) => row.transactionAmount ?? row.amount,
    Cell: LedgerCell,
    sortType: 'basic',
    sortDescFirst: true,
    align: 'right',
  },
  // still figuring out what to do with the GL location field
  // {
  //   Header: 'Location',
  //   accessor: 'transactionLocation',
  //   Cell: StandardCell,
  //   defaultShow: false,
  // },
  {
    Header: 'Class',
    id: 'transactionClass',
    accessor: (row: any) => row.transactionClass || row.class,
    Cell: StandardCell,
    defaultShow: false,
  },
  {
    Header: 'Tax Name',
    accessor: 'transactionTaxName',
    Cell: StandardCell,
    defaultShow: false,
  },
  {
    Header: 'Tax Amount',
    accessor: 'transactionTaxAmount',
    Cell: LedgerCell,
    defaultShow: false,
  },
  {
    Header: 'Open Balance',
    accessor: 'transactionOpenBalance',
    Cell: LedgerCell,
    defaultShow: false,
  },
]

//used for table headers
export const headerMap: Record<string, string> = {
  totalLines: 'Line Items',
  userName: 'Username',
  accountType: 'Account Type',
  accountName: 'Account Name',
  transactionCreatedCount: 'Transactions Created',
  transactionModifiedCount: 'Transactions Modified',
  totalTransactionCount: 'Count',
  transactionCount: 'Count',
  transactionAmount: 'Amount',
  totalTransactionAmount: 'Amount',
  accountTypeInReportCount: 'In Report',
  accountTypeCount: 'Total Count',
  lastReconciledTransactionDate: 'Date',
  anomalyName: 'Anomaly Name',
  anomalyType: 'Type',
  anomalyCount: 'Anomalies',
  transactionMonth: 'Month',
  transactionEntityName: 'Name',
  memoDescription: 'Description',
  transactionAccount: 'Account',
  transactionType: 'Type',
  transactionNum: 'Num',
  transactionDate: 'Date',
}

export const summaryReportSections = [
  { name: 'transactionByType', type: 'table' },
  { name: 'userActivity', type: 'table' },
  { name: 'transactionCountByMonth', type: 'table' },
  { name: 'lastReconciledTransactionDate', type: 'table' },
  { name: 'anomalyByType', type: 'table' },
  { name: 'chartOfAccountsUsage', type: 'table' },
  { name: 'transactionByAccountType', type: 'table' },
  { name: 'accountCountByType', type: 'table' },
]

export const blankTemplate = {
  name: 'blank',
  config: {
    sections: [],
  },
}

export const cellTypes: Record<string, Function> = {
  Amount: LedgerCell,
  Date: DateCell,
  'Created At': DateCell,
  'Modified At': DateCell,
  Count: NumericCell,
  'Total Count': NumericCell,
  'In Report': NumericCell,
  'Line Items': NumericCell,
  'Transactions Created': NumericCell,
  'Transactions Modified': NumericCell,
}

export const descColumns = [
  'Amount',
  'Count',
  'Total Count',
  'In Report',
  'Transactions Created',
  'Transactions Modified',
  'Anomalies',
]

export const xeroTransactionTypeNames: any = {
  ACCREC: 'AR Invoice',
  ACCPAY: 'AP Invoice',
  ACCRECCREDIT: 'AR Credit Note',
  ACCPAYCREDIT: 'AP Credit Note',
  ACCRECPAYMENT: 'Payment on an AR Invoice',
  ACCPAYPAYMENT: 'Payment on an AP Invoice',
  ARCREDITPAYMENT: 'AR Credit Note Payment',
  APCREDITPAYMENT: 'AP Credit Note Payment',
  CASHREC: 'Receive Money Bank Transaction',
  CASHPAID: 'Spend Money Bank Transaction',
  TRANSFER: 'Bank Transfer',
  ARPREPAYMENT: 'AR Prepayment',
  APPREPAYMENT: 'AP Prepayment',
  AROVERPAYMENT: 'AR Overpayment',
  APOVERPAYMENT: 'AP Overpayment',
  EXPCLAIM: 'Expense Claim',
  EXPPAYMENT: 'Expense Claim Payment',
  MANJOURNAL: 'Manual Journal',
  PAYSLIP: 'Payslip',
  WAGEPAYABLE: 'Payroll Payable',
  INTEGRATEDPAYROLLPE: 'Payroll Expense',
  INTEGRATEDPAYROLLPT: 'Payroll Payment',
  EXTERNALSPENDMONEY: 'Payroll Employee Payment',
  INTEGRATEDPAYROLLPTPAYMENT: 'Payroll Tax Payment',
  INTEGRATEDPAYROLLCN: 'Payroll Credit Note',
}

export const xeroAccountTypeNames: any = {
  BANK: 'Bank',
  CURRENT: 'Current Asset',
  CURRLIAB: 'Current Liability',
  DEPRECIATN: 'Depreciation',
  DIRECTCOSTS: 'Direct Costs',
  EQUITY: 'Equity',
  EXPENSE: 'Expense',
  FIXED: 'Fixed Asset',
  INVENTORY: 'Inventory Asset',
  LIABILITY: 'Liability',
  NONCURRENT: 'Non-current Asset',
  OTHERINCOME: 'Other Income',
  OVERHEADS: 'Overhead',
  PREPAYMENT: 'Prepayment',
  REVENUE: 'Revenue',
  SALES: 'Sale',
  TERMLIAB: 'Non-current Liability',
  PAYGLIABILITY: 'PAYG Liability',
  SUPERANNUATIONEXPENSE: 'Superannuation Expense',
  SUPERANNUATIONLIABILITY: 'Superannuation Liability',
  WAGESEXPENSE: 'Wages Expense',
}

export const codingAnomalies = ['inconsistentVendorCoding', 'inconsistentCustomerCoding']

export const byAccountAnomalies = [
  'Missing Checks',
  'Unreconciled Bank Transactions',
  'Unreconciled CC Transactions',
]

export const duplicateAnomalies = [
  'duplicateBills',
  'duplicateChecks',
  'duplicateInvoices',
  'duplicateExpenses',
]

export const agingAnomalies = ['arAging', 'apAging']

export const subTableOptions = {
  accent: 'grey',
  tableProps: {
    border: 'solid 1px',
    borderColor: 'background.1',
  },
  rowProps: (row: any) => ({ bg: 'background.0' }),
}

// style for the sub-table enclosing cell, not the cells inside the sub-table
export const subTableCellStyle = {
  padding: '36px',
}

export const uploadFileKindOptions: Record<string, any[]> = {
  qbo: [
    { label: 'Account List', value: 'qb:xlsx:account-list' },
    { label: 'General Ledger', value: 'qb:xlsx:general-ledger' },
    { label: 'Customer Contact List', value: 'qb:xlsx:customer-contact-list' },
    { label: 'Vendor Contact List', value: 'qb:xlsx:vendor-contact-list' },
  ],
  desktop: [
    { label: 'Account List', value: 'qb:xlsx:desktop-account-list' },
    { label: 'General Ledger', value: 'qb:xlsx:desktop-general-ledger' },
    { label: 'Customer Contact List', value: 'qb:xlsx:desktop-customer-contact-list' },
    { label: 'Vendor Contact List', value: 'qb:xlsx:desktop-vendor-contact-list' },
  ],
}

export const dashboardPages = [
  'Anomalies',
  'Vendors',
  'Customers',
  'Accounts',
  'Users',
  'Transactions',
]

export const transactionRepairStepKeys = [
  'flaggedTransactions',
  'informationRequests',
  'noIssue',
  'repaired',
]

export const workingReportStatuses = ['DRAFT', 'PROCESSING', 'PENDING']

export const workingReviewStatuses = ['processing', 'readyForRefresh', 'refreshing']

export const transactionTypeRoutes: Record<string, string> = {
  journalEntry: 'journal',
  payment: 'recvpayment',
}
