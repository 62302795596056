import React from 'react'
import { FlexColumn, Text, Anchor, Icon, Button } from 'elements'

type Props = {
  handleRetry: () => void
  submitting: boolean
}

export default function ConnectionReportStatus(props: Props) {
  const { handleRetry, submitting } = props

  return (
    <FlexColumn p="xxlarge" alignItems="center">
      <Icon name="exclamation-circle" color="danger.0" size="xxlarge" mb="medium" />
      <Text fontSize="large" color="danger.0" mb="large" textAlign="center">
        Connection Error
      </Text>
      <Text fontSize="small" color="neutral.0" mb="large" textAlign="center">
        Your integration could not be connected. Please reconnect and try again.
      </Text>
      <Button onClick={handleRetry} disabled={submitting} mb="gigantic">
        Retry Workflow
      </Button>
      <Text textAlign="center" mb="xxxlarge">
        Feel free to review our support documentation or create a ticket{' '}
        <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
          here.
        </Anchor>
      </Text>
    </FlexColumn>
  )
}
