import React from 'react'
import { pickBy, findKey } from 'lodash'
import { guessFileKind } from 'utils/automap'
import { Box, Text, FileUpload, Select, FlexColumn } from 'elements'

export default function FirstClientReportFileUpload({
  submitting,
  files,
  setFiles,
  uploadSource,
  fileKinds,
  setFileKinds,
  sourceFileKindOptions,
}) {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box mb="xxlarge">
        <FileUpload
          disabled={submitting}
          multiple
          allowedTypes={['xls', 'xlsx']}
          height="gigantic"
          onUpload={(selectedFiles) => {
            setFiles([...files, ...selectedFiles])

            // guess file kinds and set those too
            const selectedFileKinds = {}

            selectedFiles.forEach((file) => {
              const kind = guessFileKind(file, uploadSource)

              if (kind) {
                selectedFileKinds[kind] = file
              }
            })

            setFileKinds({ ...fileKinds, ...selectedFileKinds })
          }}
        />
      </Box>
      {files?.length ? (
        <Box display="grid" gridRowGap="medium" gridTemplateColumns="60% 40%">
          <Box gridRow="1" gridColumn="1">
            <Text color="foreground.2" fontWeight="bold">
              File Name
            </Text>
          </Box>
          <Box gridRow="1" gridColumn="2">
            <Text color="foreground.2" fontWeight="bold">
              File Type
            </Text>
          </Box>
          {files.map((file, idx) => (
            <React.Fragment key={`${file.name}${idx}`}>
              <FlexColumn gridRow={idx + 2} gridColumn="1" justifyContent="center">
                <Text fontSize="small" truncate title={file.name}>
                  {file.name}
                </Text>
              </FlexColumn>
              <Box gridRow={idx + 2} gridColumn="2">
                <FlexColumn>
                  <Select
                    disabled={submitting}
                    isClearable
                    options={sourceFileKindOptions}
                    value={sourceFileKindOptions.find(
                      (opt) => opt.value === findKey(fileKinds, file),
                    )}
                    onChange={(option, change) => {
                      if (option) {
                        setFileKinds({ ...fileKinds, [option.value]: file })
                      } else {
                        setFileKinds(pickBy(fileKinds, (value) => value !== file))
                      }
                    }}
                    formatOptionLabel={(option) => <Text fontSize="small">{option.label}</Text>}
                    control={{ minHeight: null }}
                  />
                </FlexColumn>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}
