import React from 'react'
import { FlexColumn, FlexRow, Icon, IntuitConnect, Button, LinkButton } from 'elements'

type Props = {
  handleNext: Function
}

export default function DataSources(props: Props) {
  const { handleNext } = props

  return (
    <FlexColumn>
      <FlexRow mb="large" alignItems="center">
        <Icon name="database" size="xlarge" color="neutral.1" mr="large" />
        <LinkButton onClick={() => handleNext('intuit')}>
          <IntuitConnect height="45px" />
        </LinkButton>
      </FlexRow>
      <FlexRow mb="large" alignItems="center">
        <Icon name="database" size="xlarge" color="neutral.1" mr="large" />
        <Button
          height="45px"
          width="279px"
          kind="xero"
          shape="semi-block"
          borderStyle="none"
          area="medium"
          fontSize="xlarge"
          onClick={() => handleNext('xero')}
        >
          Connect to Xero
        </Button>
      </FlexRow>
      <FlexRow alignItems="center">
        <Icon name="document-add" size="xlarge" color="neutral.1" mr="large" />
        <Button
          height="45px"
          width="279px"
          area="medium"
          fontSize="xlarge"
          onClick={() => handleNext('upload')}
        >
          Upload Files
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
