import React from 'react'
import { graphql, usePaginationFragment } from 'react-relay/hooks'
import { Table as Tables, TablePagination } from 'elements'
import PlaidHistoryTableRow from './PlaidHistoryTableRow'

const { Table, Head, HeadRow, HeadCell, Body } = Tables.Card

type Props = {
  company: any
}

export default function PlaidHistoryTable(props: Props) {
  const { company } = props

  const { data, refetch, ...paginationProps } = usePaginationFragment(
    graphql`
      fragment PlaidHistoryTable_company on Company
        @argumentDefinitions(cursor: { type: "Cursor" }, count: { type: "Int", defaultValue: 10 })
        @refetchable(queryName: "PlaidHistoryTablePaginationQuery") {
        companyUserFiles(after: $cursor, first: $count, orderBy: CREATED_AT_DESC)
          @connection(key: "PlaidHistoryTable_companyUserFiles") {
          edges {
            node {
              userFile {
                id
                kind
                ...PlaidHistoryTableRow_userFile
                ...PlaidHistoryContextMenu_userFile
              }
            }
          }
        }
      }
    `,
    company,
  )

  if (!data) {
    return null
  }

  const plaidFiles = data.companyUserFiles.edges
    .map((edge: any) => edge.node.userFile)
    .filter((userFile: any) => userFile.kind === 'plaid:csv:transaction-list')

  return (
    <Table spacing="large">
      <Head>
        <HeadRow>
          <HeadCell>Run Period</HeadCell>
          <HeadCell>Created At</HeadCell>
          <HeadCell />
        </HeadRow>
      </Head>
      <Body>
        {plaidFiles.map((file: any, idx: number) => (
          <PlaidHistoryTableRow plaidFile={file} key={file.id} />
        ))}
      </Body>
      <TablePagination Table={Tables.Card} colSpan={5} {...paginationProps} />
    </Table>
  )
}
