import { camelCase, kebabCase } from 'lodash'
import { RadialBarChart, DonutChart, TableConnected } from 'elements'
import { ReportSection } from '../../../types'
import { insights, insightDescriptions } from '../../../constants'
import theme from 'theme'
import { drilldownReportSection } from './constants'

const { DateCell, NumericCell } = TableConnected as any

type Props = {
  formattedSummary: Record<string, any>
}

export default function getVendorReportSection(props: Props): ReportSection | null {
  const { formattedSummary } = props

  const {
    filteredTransactions,
    transactionByVendorList,
    vendorUsage,
    percentageScores,
    vendorList,
  } = formattedSummary

  const vendorCompletenessDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.vendorCompletenessScore,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.vendorCompletenessScore,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      Chart: RadialBarChart,
      chartOptions: {
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: { show: false },
                value: {
                  formatter: (val: number) => `${val}%`,
                  fontSize: theme.fontSizes.xgigantic,
                  fontWeight: theme.fontWeights.xxbold,
                },
              },
            },
          },
        },
      },
      data: [percentageScores.vendor],
      gridColumn: '1',
      gridRow: '2',
    },
  ]

  const vendorUsageSubReportSections = vendorUsage.map((category: any) => {
    const dataSections = [
      {
        key: `vendorUsage_${camelCase(category.vendorCategory)}`,
        table: true,
        data: category.vendors,
        columns: [
          { Header: 'Name', accessor: 'vendorDisplayName' },
          {
            Header: 'Date Created',
            accessor: 'vendorCreatedTime',
            Cell: DateCell,
          },
          {
            Header: 'Date Modified',
            accessor: 'vendorLastUpdatedTime',
            Cell: DateCell,
          },
        ],
        tableOptions: { highlightedColumn: 'Name' },
      },
    ]

    return {
      label: category.vendorCategory,
      value: `vendorUsage_${camelCase(category.vendorCategory)}`,
      kind: 'insight',
      route: kebabCase(category.vendorCategory),
      dataSections,
      exportData: [{ sheetName: `Vendors ${category.vendorCategory}`, dataSections }],
    }
  })

  const vendorUsageDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.vendorUsage,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.vendorUsage,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      Chart: DonutChart,
      chartOptions: {
        options: {
          colors: [theme.colors.secondary[0], theme.colors.secondary[8]],
        },
      },
      data: vendorUsage
        .slice(0, 2)
        .map((category: any) => ({ name: category.vendorCategory, data: category.count })),
      gridColumn: '1',
      gridRow: '2',
    },
    {
      table: true,
      gridRow: '3',
      data: vendorUsage,
      columns: [
        {
          Header: 'Vendors',
          accessor: 'vendorCategory',
        },
        {
          Header: 'Count',
          accessor: 'count',
          sortDescFirst: true,
          Cell: NumericCell,
        },
      ],
      tableOptions: {
        highlightedColumn: 'Vendors',
        rowRoute: vendorList?.length ? (row: any) => kebabCase(row.original.vendorCategory) : null,
      },
      subReportSections: vendorUsageSubReportSections,
    },
  ]

  const transactionByVendorDataSections = [
    {
      title: 'Description',
      description: insightDescriptions.transactionByVendor,
      gridColumn: '1',
    },
    {
      title: 'Insights',
      insights: insights.transactionByVendor,
      gridRow: '1',
      gridColumn: '2',
    },
    {
      table: true,
      gridRow: '2',
      data: filteredTransactions.byVendor,
      tableOptions: {
        highlightedColumn: 'Vendor Name',
        rowRoute: (row: any) =>
          `details?entityName=${encodeURIComponent(row.original.vendorName)}&entityType=vendor`,
      },
      subReportSections: [drilldownReportSection],
    },
  ]

  const vendorDataSections = [
    {
      key: 'vendorCompletenessScore',
      title: 'Vendor Profile Completeness Score',
      insights: insights.vendorCompletenessScore,
      Chart: RadialBarChart,
      chartOptions: {
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: { show: false },
                value: {
                  formatter: (val: number) => `${val}%`,
                  fontSize: theme.fontSizes.xgigantic,
                  fontWeight: theme.fontWeights.xxbold,
                },
              },
            },
          },
        },
      },
      data: [percentageScores.vendor],
      detailRoute: 'vendor-completeness',
      subReportSections: [
        {
          label: 'Vendor Profile Completeness Score',
          value: 'vendorCompletenessScore',
          route: 'vendor-completeness',
          kind: 'insight',
          dataSections: vendorCompletenessDataSections,
          // manual export data for entire sheet, to include completeness score as table
          exportData: [
            {
              sheetName: 'Vendor Profile Completeness Score',
              dataSections: [
                ...vendorCompletenessDataSections.slice(0, 2),
                {
                  data: [{ Score: `${percentageScores.vendor}%` }],
                  table: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: 'vendorUsage',
      title: 'Vendor Usage',
      insights: insights.vendorUsage,
      Chart: DonutChart,
      data: vendorUsage
        ?.slice(0, 2)
        .map((category: any) => ({ name: category.vendorCategory, data: category.count })),
      chartOptions: {
        options: { colors: [theme.colors.secondary[0], theme.colors.secondary[8]] },
      },
      detailRoute: 'vendor-usage',
      subReportSections: [
        {
          label: 'Vendor Usage',
          value: 'vendorUsage',
          route: 'vendor-usage',
          kind: 'insight',
          dataSections: vendorUsageDataSections,
        },
      ],
    },
    {
      key: 'transactionByVendor',
      title: 'Transaction Volume by Vendor',
      insights: insights.transactionByVendor,
      table: true,
      tableOptions: {
        initialSortColumns: [{ id: 'totalTransactionCount', desc: true }],
        highlightedColumn: 'Vendor Name',
        pageSize: 3,
        pagination: true,
        preview: true,
        disableSortBy: true,
      },
      data: filteredTransactions.byVendor,
      detailRoute: 'transaction-by-vendor',
      subReportSections: [
        {
          label: 'Transaction Volume by Vendor',
          value: 'transactionByVendor',
          route: 'transaction-by-vendor',
          kind: 'insight',
          dataSections: transactionByVendorDataSections,
        },
      ],
    },
  ]

  if (transactionByVendorList?.length) {
    return {
      label: 'Vendors',
      value: 'vendorInsights',
      kind: 'insight',
      dataSections: vendorDataSections,
    }
  }

  return null
}
