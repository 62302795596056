import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useWorkflowTemplateUpdateMutation($input: UpdateWorkflowTemplateByIdInput!) {
    updateWorkflowTemplateById(input: $input) {
      workflowTemplate {
        id
        organization {
          ...WorkflowConfiguration_organization
        }
      }
    }
  }
`

export default function useWorkflowTemplateUpdate() {
  return useMutation(mutation, 'updateWorkflowTemplateById')
}
