import React from 'react'
import { OptimizelyProvider, createInstance, setLogLevel } from '@optimizely/react-sdk'
import { useAuth } from 'hooks'
import config from 'config'

const optimizely = createInstance({
  sdkKey: config.services.optimizely.sdkKey,
  datafileOptions: {
    // proxy requests through our server to avoid optimizely
    // domains being blocked by ad blockers
    urlTemplate: `${config.api.host}/proxy/optimizely/datafiles/%s.json`,
  },
})

setLogLevel(config.services.optimizely.logLevel)

export default function OptimizelyProviderFn({ children }) {
  const { isReady, user } = useAuth()

  if (!isReady) {
    return null
  }

  const email = user?.userEmails?.nodes?.[0]?.email

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{ id: user?.id, attributes: { email, isAdmin: user?.isAdmin } }}
    >
      {children}
    </OptimizelyProvider>
  )
}
