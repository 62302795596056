/**
 * @generated SignedSource<<1f3752d61b138cebfa15526ee8027471>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeriodSelect_company$data = {
  readonly id: string;
  readonly filteredReports: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly periodStart: any | null;
        readonly periodEnd: any | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "PeriodSelect_company";
};
export type PeriodSelect_company$key = {
  readonly " $data"?: PeriodSelect_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeriodSelect_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filteredReports"
        ]
      }
    ]
  },
  "name": "PeriodSelect_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "filteredReports",
      "args": [
        {
          "kind": "Literal",
          "name": "input",
          "value": {
            "uniqueByPeriod": true
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "ReportsConnection",
      "kind": "LinkedField",
      "name": "__PeriodSelect_filteredReports_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Report",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "periodStart",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "periodEnd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__PeriodSelect_filteredReports_connection(input:{\"uniqueByPeriod\":true},orderBy:\"CREATED_AT_DESC\")"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "8063131264efe6aa8c281f8170963de3";

export default node;
