import React from 'react'
import { FlexColumn, FlexRow, Text, Card, Button } from 'elements'

export default function EnterpriseSubscriptionCard({ metrics, ...props }) {
  return (
    <Card
      bg="background.0"
      pb="xxxlarge"
      pt="xxxlarge"
      mb="xxxlarge"
      width="768px"
      flexDirection="column"
      {...props}
    >
      <FlexColumn justifyContent="center" pt="large" pb="large" alignItems="center">
        <Text fontSize="xxlarge" mb="large">
          You're on the <b>Enterprise</b> plan.
        </Text>
        <Text fontSize="xxlarge" mt="xxlarge" mb="xxlarge">
          Past Month
        </Text>
        <FlexRow justifyContent="space-around">
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Clients
            </Text>
            <Text fontSize="large">{metrics?.clientCount?.value}</Text>
          </FlexColumn>
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Reports
            </Text>
            <Text fontSize="large">{metrics?.reportCount?.value}</Text>
          </FlexColumn>
          <FlexColumn alignItems="center" width="185px">
            <Text fontSize="xlarge" fontWeight="bold" mb="medium">
              Anomalies Resolved
            </Text>
            <Text fontSize="large">{metrics?.anomaliesResolved?.value}</Text>
          </FlexColumn>
        </FlexRow>
        <FlexRow mt="xxlarge">
          <Button
            as="a"
            href="mailto:hello@scrutinize.io?subject=Scrutinize%20Enterprise%20Info"
            kind="primary"
          >
            Contact Us
          </Button>
        </FlexRow>
      </FlexColumn>
    </Card>
  )
}
