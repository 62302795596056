import { useState } from 'react'
import useStorage, { FileDescriptor } from './useStorage'

export default function useFileUpload() {
  const [status, setStatus] = useState('idle')
  const [fileProgress, setFileProgress] = useState(0)
  const storage = useStorage()

  const handleUpload = async (path: string, files: FileDescriptor[]) => {
    setStatus('uploading')

    // set initial file progresses
    setFileProgress(0)

    const result = await storage.put(path, files, {
      onUploadProgress(progressEvent: ProgressEvent) {
        const percent = (progressEvent.loaded / progressEvent.total) * 100
        setFileProgress(percent)
      },
    })

    setStatus('complete')
    return result
  }

  return [handleUpload, { status, progress: fileProgress }]
}
