import React from 'react'
import { Modal, SuspenseBoundary } from 'elements'
import CreateReportModal from './CreateReportModal'

export default function CreateReportModalContainer({
  modal,
  companyId,
  integrationResult,
  workflowTemplateId,
  reportPeriod,
  orgSlug,
}) {
  return (
    <Modal modal={modal}>
      <SuspenseBoundary>
        <CreateReportModal
          modal={modal}
          integrationResult={integrationResult}
          companyId={companyId}
          workflowTemplateId={workflowTemplateId}
          reportPeriod={reportPeriod}
          orgSlug={orgSlug}
        />
      </SuspenseBoundary>
    </Modal>
  )
}
