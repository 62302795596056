/**
 * @generated SignedSource<<baeea9d0cd35817b756260b03bc2d832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillingPlanInterval = "MONTH" | "YEAR" | "%future added value";
export type BillingPlanType = "CUSTOM" | "STANDARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillingWarning_organization$data = {
  readonly activeCompanies: {
    readonly totalCount: number;
  };
  readonly organizationSubscriptions: {
    readonly nodes: ReadonlyArray<{
      readonly billingPlan: {
        readonly rowId: any;
        readonly slug: string;
        readonly name: string;
        readonly price: number;
        readonly type: BillingPlanType;
        readonly interval: BillingPlanInterval;
        readonly stripePriceId: string | null;
        readonly config: any | null;
      } | null;
    }>;
  };
  readonly " $fragmentType": "BillingWarning_organization";
};
export type BillingWarning_organization$key = {
  readonly " $data"?: BillingWarning_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingWarning_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingWarning_organization",
  "selections": [
    {
      "alias": "activeCompanies",
      "args": [
        {
          "kind": "Literal",
          "name": "includeDeleted",
          "value": "NO"
        }
      ],
      "concreteType": "CompaniesConnection",
      "kind": "LinkedField",
      "name": "companies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "companies(includeDeleted:\"NO\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeDeleted",
          "value": "YES"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "UPDATED_AT_DESC"
        }
      ],
      "concreteType": "OrganizationSubscriptionsConnection",
      "kind": "LinkedField",
      "name": "organizationSubscriptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationSubscription",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingPlan",
              "kind": "LinkedField",
              "name": "billingPlan",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interval",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripePriceId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "config",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "organizationSubscriptions(includeDeleted:\"YES\",orderBy:\"UPDATED_AT_DESC\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6dab816608200ecfa70f72cc17201716";

export default node;
