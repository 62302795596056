import React, { useState, useEffect } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks'
import { Button, Modal, TextInput, Text, FlexRow } from 'elements'
import ModalContent from 'components/app/ModalContent'
import ModalCloseButton from 'components/app/ModalCloseButton'
import useCompanyCreate from '../hooks/useCompanyCreate'
import useCompanyUpdate from '../hooks/useCompanyUpdate'
import BillingWarning from './BillingWarning'

type Props = {
  company: any
  organization: any
  onNext: Function
  onClose: Function
  firstClient?: boolean
  onCompanyIdChange: Function
}

export default function CreateClientStep(props: Props) {
  const { company, organization, onNext, onClose, firstClient, onCompanyIdChange } = props

  const [submitting, setSubmitting] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [createCompany]: any = useCompanyCreate()
  const [updateCompany]: any = useCompanyUpdate()
  const { currentOrg } = useAuth()
  const history = useHistory()

  const data = useFragment(
    graphql`
      fragment CreateClientStep_company on Company {
        rowId
        id
        name
        integrations(first: 100) {
          nodes {
            status
          }
        }
      }
    `,
    company,
  )

  const handleNext = async () => {
    setSubmitting(true)

    if (data) {
      if (data.name !== companyName) {
        await updateCompany({ id: data.id, patch: { name: companyName } })
      }
    } else {
      const { company } = await createCompany({
        name: companyName,
        organizationId: currentOrg!.id,
      })

      history.push(`/orgs/${currentOrg!.slug}/companies/${company.id}`)

      onCompanyIdChange(company.id)
    }

    onNext()
  }

  const canContinue = companyName.length

  // set company field to the current company name when returning from integration
  useEffect(() => {
    if (data) {
      setCompanyName(data.name)
    }
  }, [data])

  return (
    <>
      <ModalContent pt="gigantic" px="gigantic" height="550px">
        <ModalCloseButton onClose={onClose} />
        <FlexRow justifyContent="center">
          <Text fontSize="24px" fontWeight="bold" mb="large">
            {firstClient ? 'Welcome to Scrutinize' : 'Create a client'}
          </Text>
        </FlexRow>
        <FlexRow justifyContent="center">
          <Text fontSize="small" color="neutral.0" mb="xxxlarge">
            Let's get everything set up for your first workflow.
          </Text>
        </FlexRow>
        <Text mb="xlarge">Step 1: Add a client</Text>
        <FlexRow mb="xxxlarge">
          <TextInput
            value={companyName}
            autoFocus
            onChange={(event: any) => setCompanyName(event.target.value)}
            placeholder="Client Name"
          />
        </FlexRow>
        <BillingWarning organization={organization} />
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        px="gigantic"
        pb="gigantic"
      >
        <Button kind="primary" onClick={handleNext} disabled={submitting || !canContinue}>
          Next
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
