import { object, string } from 'yup'

// see https://stackoverflow.com/a/16699507
const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const validators = {
  password: string()
    .min(8, 'Must be at least 8 characters')
    .matches(/\d+/, 'Must contain a number')
    .matches(/[A-Z]+/, 'Must contain an uppercase letter')
    .matches(/[a-z]+/, 'Must contain a lowercase letter')
    .matches(/[^a-zA-Z\d]+/, 'Must contain a special character'),
  verificationCode: string(),
  email: string().email('Email address is invalid'),
  phone: string().matches(PHONE_NUMBER_REGEX, 'Phone number is invalid'),
}

export function createFormValidator(shape) {
  return async (form) => {
    try {
      const schema = object().shape(shape)
      await schema.validate(form, { abortEarly: false })
      return null
    } catch (error) {
      return (error.inner || []).reduce(
        (formError, innerError) => ({
          ...formError,
          [innerError.path]: innerError.message,
        }),
        {},
      )
    }
  }
}
