// gets a range of react-table rows between two row IDs
export function getRowRange(rows: any[], rowIdA: string, rowIdB: string) {
  const range: any[] = []
  let foundStart = false
  let foundEnd = false

  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index]

    if (row.id === rowIdA || row.id === rowIdB) {
      if (foundStart) {
        foundEnd = true
      }
      if (!foundStart) {
        foundStart = true
      }
    }

    if (foundStart) {
      range.push(row)
    }

    if (foundEnd) {
      break
    }
  }

  return range
}
