/**
 * @generated SignedSource<<adfb88b42b37d2d9820d9102c3dfd380>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrgInviteStatus = "ACCEPTED" | "CANCELED" | "EXPIRED" | "PENDING" | "%future added value";
export type UserOrganizationRole = "ADMIN" | "MEMBER" | "%future added value";
export type CreateOrganizationInviteInput = {
  email: string;
  intendedRole: UserOrganizationRole;
  invitedBy?: string | null;
  organizationId: string;
};
export type useOrganizationInviteCreateMutation$variables = {
  input: CreateOrganizationInviteInput;
};
export type useOrganizationInviteCreateMutation$data = {
  readonly createOrganizationInvite: {
    readonly organizationInvite: {
      readonly emailAddress: string;
      readonly intendedRole: UserOrganizationRole | null;
      readonly organizationId: any;
      readonly status: OrgInviteStatus | null;
      readonly organization: {
        readonly slug: string;
        readonly organizationInvites: {
          readonly nodes: ReadonlyArray<{
            readonly emailAddress: string;
            readonly intendedRole: UserOrganizationRole | null;
            readonly status: OrgInviteStatus | null;
          }>;
        };
      } | null;
    };
  } | null;
};
export type useOrganizationInviteCreateMutation = {
  variables: useOrganizationInviteCreateMutation$variables;
  response: useOrganizationInviteCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "intendedRole",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrganizationInviteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationInvitePayload",
        "kind": "LinkedField",
        "name": "createOrganizationInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationInvitesConnection",
                    "kind": "LinkedField",
                    "name": "organizationInvites",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationInvite",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrganizationInviteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationInvitePayload",
        "kind": "LinkedField",
        "name": "createOrganizationInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationInvitesConnection",
                    "kind": "LinkedField",
                    "name": "organizationInvites",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationInvite",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a67c5cea465c826cae0df919e746300",
    "id": null,
    "metadata": {},
    "name": "useOrganizationInviteCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useOrganizationInviteCreateMutation(\n  $input: CreateOrganizationInviteInput!\n) {\n  createOrganizationInvite(input: $input) {\n    organizationInvite {\n      emailAddress\n      intendedRole\n      organizationId\n      status\n      organization {\n        slug\n        organizationInvites {\n          nodes {\n            emailAddress\n            intendedRole\n            status\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce5c137f0a0cdbc42f510ed734ada223";

export default node;
