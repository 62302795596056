import React from 'react'
import { Circle, FlexRow, FlexColumn, Icon, Text, Box } from 'elements'
import { Option } from '../../../shared/types'
import { colorToRGB } from 'utils/color'
import { getWorkspaceCompletion } from '../utils/workflows'

type Props = {
  options: Option[]
  value?: any
  onChange: Function
}

type CircleProps = {
  isSelected: boolean
  isComplete: boolean
}

const TAB_BOX_WIDTH = 170
// distance from one end of tab box to the opposite side of the option circle, e.g. from the left side of the first box
// to the right side of the first option circle
const TAB_CIRCLE_OFFSET = 97

const OptionCircle = (props: CircleProps) => {
  const { isSelected, isComplete } = props

  if (isComplete) {
    return (
      <Circle size="xlarge" color="secondary.0" zIndex="1">
        <Icon name="solid-check" color="background.0" />
      </Circle>
    )
  }

  if (isSelected) {
    return (
      <Circle color={colorToRGB('secondary.0', 0.5)} size="xlarge" zIndex="1">
        <Circle size="18px" color="secondary.0">
          <Circle size="small" color="background.0" />
        </Circle>
      </Circle>
    )
  }

  return (
    <Circle color="neutral.2" size="xlarge" zIndex="1">
      <Circle size="small" color="background.0" />
    </Circle>
  )
}

const calcLineWidth = (options: Option[]): number => {
  const totalTabWidth = TAB_BOX_WIDTH * options.length

  return totalTabWidth - TAB_CIRCLE_OFFSET * 2
}

export default function WorkspaceTabs(props: Props) {
  const { options, value, onChange } = props

  return (
    <Box position="relative">
      {/* connecting line */}
      <Box
        height="xxsmall"
        width={calcLineWidth(options)}
        bg="neutral.2"
        position="absolute"
        top="25%"
        left={`${TAB_CIRCLE_OFFSET}px`}
      />
      <FlexRow>
        {options.map((option) => {
          const isSelected = option.value.id === value?.id
          const isComplete = getWorkspaceCompletion(option.value)

          return (
            <FlexColumn alignItems="center" width={`${TAB_BOX_WIDTH}px`} key={option.value.id}>
              <FlexRow mb="xsmall" onClick={() => onChange(option)}>
                <OptionCircle isSelected={isSelected} isComplete={isComplete} />
              </FlexRow>
              <FlexRow onClick={() => onChange(option)}>
                <Text color={isSelected ? 'secondary.0' : 'neutral.2'}>{option.label}</Text>
              </FlexRow>
            </FlexColumn>
          )
        })}
      </FlexRow>
    </Box>
  )
}
