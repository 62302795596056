/**
 * @generated SignedSource<<f575e69972018635f6d18aab414b863b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportResolution_report$data = {
  readonly id: string;
  readonly reviewStatus: string | null;
  readonly anomalyCounts: ReadonlyArray<{
    readonly type: string | null;
    readonly count: number | null;
  } | null> | null;
  readonly " $fragmentType": "ReportResolution_report";
};
export type ReportResolution_report$key = {
  readonly " $data"?: ReportResolution_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportResolution_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ReportResolutionRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ReportResolution_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnomalyCount",
      "kind": "LinkedField",
      "name": "anomalyCounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "daf60aac121738d827ae0bc6f1c1b3e2";

export default node;
