import styled from 'styled-components'
import { print } from '../styles'
import {
  compose,
  space,
  layout,
  typography,
  color,
  background,
  border,
  shadow,
} from 'styled-system'
import { alignToTextAlign } from './utils'

const SimpleTableHeadCell = styled.th`
  ${print}
  ${compose(space, typography, color, layout, background, border, shadow)}

  white-space: nowrap;
  text-align: ${(props) => alignToTextAlign(props.align)};

  &:first-child {
    padding-left: ${({ theme }) => theme.space.large}px;
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.space.large}px;
  }
`

SimpleTableHeadCell.defaultProps = {
  color: 'foreground.2',
  py: 'small',
  px: 'xlarge',
  textAlign: 'left',
  fontWeight: 'bold',
  fontSize: 'medium',
}

export default SimpleTableHeadCell
