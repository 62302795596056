import React from 'react'
import { pick } from 'lodash'
import { TableConnected, Icon, FlexRow, Text, Tooltip, Box } from 'elements'
import { Column } from '../types'
import AnomaliesCell from './AnomaliesCell'
import { getResolutionStatusIcon, getResolutionError } from '../utils/resolutions/general'
import theme from 'theme'
import {
  getLineFieldName,
  updateLineItem,
  updateLineQuantity,
  updateLineRate,
  updateLineAmount,
  updateItemBasedLineAmount,
  updateJournalEntryLineDebit,
  updateJournalEntryLineCredit,
  updateLineValues,
  updateTransferLineAccount,
} from '../utils/resolutions/formUpdates'
import { Option } from '../../../shared/types'
import { toTitleCase } from '../../../utils/string'

const {
  LedgerCell,
  PercentageCell,
  DateCell,
  StandardCell,
  InputCell,
  EditableCell,
  SelectCell,
  DeepValueCell,
} = TableConnected as any

const RateCell = (props: any) => {
  if (props.row.original.lineType === 'discount') {
    return <PercentageCell {...props} />
  }

  return <LedgerCell {...props} />
}

// transaction groups for UI
export const typeOneTransactionTypes = ['invoice', 'salesReceipt', 'creditMemo', 'refundReceipt']

export const typeOneIncomeTypes = ['invoice', 'salesReceipt', 'creditMemo', 'refundReceipt']
export const typeOneExpenseTypes = ['creditCardCredit', 'vendorCredit', 'bill']

export const expenseTransactionTypes = ['expense', 'check']

export const transferTransactionTypes = ['transfer', 'creditCardPayment']

export const uneditableLineTxnTypes = [...transferTransactionTypes, 'billPayment', 'deposit']

export const paymentTransactionTypes = ['payment', 'billPayment']

export const bankAccountTypes = ['Bank', 'CreditCard', 'OtherCurrentAsset']

export const splitLineTableTxnTypes = [
  'bill',
  'creditCardCredit',
  'expense',
  'vendorCredit',
  'check',
]

const expandedMenuSelectStyles = {
  width: '500px',
  // rest copied from default styles
  marginTop: 2,
  borderTop: 'none',
  boxShadow: theme.shadows.high,
}

const expandedSelectStyles = {
  menu: (provided: any) => ({ ...provided, ...expandedMenuSelectStyles }),
}

type OptionLabelProps = { label: string; context: string; labelDescription: string }

const FormattedOptionLabel = (props: OptionLabelProps) => {
  const { label, context, labelDescription } = props

  return (
    <FlexRow justifyContent="space-between">
      <Text>{label}</Text>
      {context === 'menu' && (
        <Text fontStyle="italic" color="neutral.0" ml="small">
          {labelDescription}
        </Text>
      )}
    </FlexRow>
  )
}

const descriptionColumn: Column = {
  Header: 'Description',
  accessor: 'lineDescription',
  width: '200px',
  Cell: EditableCell,
  EditCell: InputCell,
  formProps: {
    getFieldName: (row: any, formCtx: any) => {
      const { values } = formCtx

      return getLineFieldName(values, row.original, 'lineDescription')
    },
    handleUpdate: (value, cellProps, formCtx) =>
      updateLineValues({ lineDescription: value }, cellProps, formCtx),
  },
}

const amountColumn: Column = {
  Header: 'Amount',
  accessor: 'lineAmount',
  width: '100px',
  align: 'right',
  Cell: EditableCell,
  EditCell: InputCell,
  DefaultCell: LedgerCell,
  formProps: {
    getFieldName: (row: any, formCtx: any) => {
      const { values } = formCtx

      return getLineFieldName(values, row.original, 'lineAmount')
    },
    inputType: 'number',
    handleUpdate: updateLineAmount,
  },
}

const itemBasedAmountColumn = {
  ...amountColumn,
  formProps: { ...amountColumn.formProps, handleUpdate: updateItemBasedLineAmount },
}

const quantityColumn: Column = {
  Header: 'Qty',
  accessor: 'lineQuantity',
  // width: '100px',
  Cell: EditableCell,
  EditCell: InputCell,
  align: 'right',
  formProps: {
    getFieldName: (row: any, formCtx: any) => {
      const { values } = formCtx

      return getLineFieldName(values, row.original, 'lineQuantity')
    },
    inputType: 'number',
    handleUpdate: updateLineQuantity,
  },
}

const selectableAccountColumn: Column = {
  Header: 'Account',
  accessor: (row: any) => pick(row, ['lineAccount', 'lineAccountId']),
  Cell: EditableCell,
  EditCell: SelectCell,
  DefaultCell: DeepValueCell,
  valuePath: 'lineAccount',
  formProps: {
    selectStyles: expandedSelectStyles,
    formatOptionLabel: (option: Option, meta: any) => {
      const { label, value } = option
      const { context } = meta

      return (
        <FormattedOptionLabel
          label={label}
          context={context}
          labelDescription={toTitleCase(value.accountType)}
        />
      )
    },
    handleUpdate: (value, cellProps, formCtx) =>
      updateLineValues(
        { lineAccount: value.accountName, lineAccountId: value.accountId },
        cellProps,
        formCtx,
      ),
  },
}

const debitColumn: Column = {
  Header: 'Debits',
  accessor: 'lineDebit',
  Cell: EditableCell,
  EditCell: InputCell,
  DefaultCell: LedgerCell,
}

const creditColumn: Column = {
  Header: 'Credits',
  accessor: 'lineCredit',
  Cell: EditableCell,
  EditCell: InputCell,
  DefaultCell: LedgerCell,
}

const itemColumn: Column = {
  Header: 'Product/Service',
  accessor: (row: any) => pick(row, ['lineItem', 'lineItemId']),
  Cell: EditableCell,
  EditCell: SelectCell,
  DefaultCell: DeepValueCell,
  valuePath: 'lineItem',
  formProps: {
    getFieldName: (row: any, formCtx: any) => {
      const { values } = formCtx

      return getLineFieldName(values, row.original, 'lineItem')
    },
    selectStyles: expandedSelectStyles,
    formatOptionLabel: (option: Option, meta: any) => {
      const { label, value } = option
      const { context } = meta

      return (
        <FormattedOptionLabel
          label={label}
          context={context}
          labelDescription={value.itemDescription}
        />
      )
    },
    handleUpdate: updateLineItem,
  },
}

const accountColumn = { Header: 'Account', accessor: 'lineAccount' }

const rateColumn = {
  Header: 'Rate',
  accessor: 'lineRate',
  width: '100px',
  Cell: EditableCell,
  EditCell: InputCell,
  align: 'right',
  formProps: {
    getFieldName: (row: any, formCtx: any) => {
      const { values } = formCtx

      return getLineFieldName(values, row.original, 'lineRate')
    },
    inputType: 'number',
    handleUpdate: updateLineRate,
  },
  DefaultCell: RateCell,
}

export const typeOneLineCols: Column[] = [
  itemColumn,
  accountColumn,
  descriptionColumn,
  quantityColumn,
  rateColumn,
  itemBasedAmountColumn,
]

export const accountBasedBillCols: Column[] = [
  selectableAccountColumn,
  descriptionColumn,
  amountColumn,
]

export const expenseLineCols: Column[] = [selectableAccountColumn, amountColumn, descriptionColumn]

export const transferLineCols: Column[] = [
  {
    ...selectableAccountColumn,
    formProps: {
      ...selectableAccountColumn.formProps,
      handleUpdate: updateTransferLineAccount,
    },
  },
  { ...debitColumn, editable: false },
  { ...creditColumn, editable: false },
]

export const journalEntryLineCols: Column[] = [
  selectableAccountColumn,
  {
    ...debitColumn,
    formProps: {
      getFieldName: (row: any, formCtx: any) => {
        const { values } = formCtx

        return getLineFieldName(values, row.original, 'lineDebit')
      },
      handleUpdate: updateJournalEntryLineDebit,
    },
  },
  {
    ...creditColumn,
    formProps: {
      getFieldName: (row: any, formCtx: any) => {
        const { values } = formCtx

        return getLineFieldName(values, row.original, 'lineCredit')
      },
      handleUpdate: updateJournalEntryLineCredit,
    },
  },
  descriptionColumn,
  {
    Header: 'Name',
    accessor: (row: any) => pick(row, ['lineEntity', 'lineEntityId']),
    Cell: EditableCell,
    EditCell: SelectCell,
    DefaultCell: DeepValueCell,
    valuePath: 'lineEntity',
    width: '200px',
    formProps: {
      selectStyles: {
        menu: (provided: any) => ({
          ...provided,
          ...expandedMenuSelectStyles,
          position: 'absolute',
          right: '0',
        }),
      },
      formatOptionLabel: (option: Option, meta: any) => {
        const { label, value } = option
        const { context } = meta

        return (
          <FormattedOptionLabel
            label={label}
            context={context}
            labelDescription={toTitleCase(value.type)}
          />
        )
      },
      handleUpdate: (value: any, cellProps: any, formCtx: any) =>
        updateLineValues(
          {
            lineEntity: value.name,
            lineEntityId: value.id,
            lineEntityType: value.type,
          },
          cellProps,
          formCtx,
        ),
    },
  },
]

export const paymentLineCols: Column[] = [
  { Header: 'Description', accessor: 'lineDescription', Cell: StandardCell },
  {
    Header: 'Original Amount',
    accessor: 'lineOriginalAmount',
    Cell: LedgerCell,
  },
  {
    Header: 'Open Balance',
    accessor: 'lineOpenBalance',
    Cell: LedgerCell,
  },
  {
    Header: 'Payment',
    accessor: 'lineAmount',
    Cell: LedgerCell,
  },
]

export const billPaymentLineCols: Column[] = [
  { Header: 'Description', accessor: 'lineDescription', Cell: StandardCell },
  {
    Header: 'Original Amount',
    accessor: 'lineOriginalAmount',
    Cell: LedgerCell,
  },
  {
    Header: 'Payment',
    accessor: 'lineAmount',
    Cell: LedgerCell,
  },
]

export const depositLineCols: Column[] = [
  { Header: 'Received From', accessor: 'lineEntity', Cell: StandardCell },
  { Header: 'From Account', accessor: 'lineAccount', Cell: StandardCell },
  { Header: 'Date', accessor: 'lineDate', Cell: DateCell },
  { Header: 'Type', accessor: 'lineType', Cell: StandardCell },
  { Header: 'Memo', accessor: 'lineDescription', Cell: StandardCell },
  { Header: 'Ref #', accessor: 'lineLinkedTransactionNum', Cell: StandardCell },
  { Header: 'Amount', accessor: 'lineAmount', Cell: LedgerCell },
]

export const reportResolutionColumns: Column[] = [
  {
    accessor: 'status',
    Cell: ({ row }: any) => {
      const { resolution } = row.original
      const { status, updateStatus } = resolution
      const statusIcon = getResolutionStatusIcon(status, updateStatus)
      const errorMessage = getResolutionError(resolution)

      if (statusIcon) {
        return (
          <>
            {updateStatus === 'error' && (
              <Tooltip id={row.id} border borderColor={theme.colors.danger[0]}>
                <Box textAlign="center" width="200px">
                  <Text color="neutral.0">{errorMessage}</Text>
                </Box>
              </Tooltip>
            )}
            <Icon
              name={statusIcon.name}
              color={statusIcon.color}
              size="medium"
              data-tip
              data-for={row.id}
            />
          </>
        )
      }

      return null
    },
    disableSortBy: true,
    width: '24px',
  },
  {
    Header: 'Date',
    accessor: 'transactionDate',
    Cell: DateCell,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Number',
    accessor: 'transactionNum',
    Cell: StandardCell,
  },
  {
    Header: 'Type',
    accessor: 'transactionType',
    Cell: StandardCell,
    style: { titleCase: true },
  },
  {
    Header: 'Name',
    accessor: 'transactionEntityName',
    Cell: StandardCell,
  },
  {
    Header: 'Amount',
    accessor: 'transactionAmount',
    Cell: LedgerCell,
  },
  {
    Header: 'Anomalies',
    accessor: 'anomalyCounts',
    Cell: AnomaliesCell,
    align: 'center',
    width: '175px',
    sortType: (rowA: any, rowB: any, columnId: string) => {
      const rowAValue = rowA.values[columnId]
      const rowBValue = rowB.values[columnId]

      // sort by high first
      if (rowAValue.anomalyCountHigh > rowBValue.anomalyCountHigh) {
        return 1
      }

      if (rowBValue.anomalyCountHigh > rowAValue.anomalyCountHigh) {
        return -1
      }

      // then medium
      if (rowAValue.anomalyCountMedium > rowBValue.anomalyCountMedium) {
        return 1
      }

      if (rowBValue.anomalyCountMedium > rowAValue.anomalyCountMedium) {
        return -1
      }

      // then low
      if (rowAValue.anomalyCountLow > rowBValue.anomalyCountLow) {
        return 1
      }

      if (rowBValue.anomalyCountLow > rowAValue.anomalyCountLow) {
        return -1
      }

      return 0
    },
  },
]
