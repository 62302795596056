import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { forEach, isEqual, omit, some, without, mapValues, pick } from 'lodash'
import { useHistory } from 'react-router'
import { ModalsContext } from 'contexts'
import { Box } from 'elements'
import FullModalContainer from 'components/app/modals/FullModalContainer'
import SemiModalContainer from 'components/app/modals/SemiModalContainer'
import ScreenModalContainer from 'components/app/modals/ScreenModalContainer'

function updateFocused(activeModals, activeModalsOrder) {
  return mapValues(activeModals, (modal, modalId) => ({
    ...modal,
    isFocused: activeModalsOrder[0] === modalId,
  }))
}

function findActiveModal(activeModals, modalType, modalProps) {
  let isActive = false

  forEach(activeModals, (activeModal) => {
    if (modalType === activeModal.modalType && isEqual(modalProps, activeModal.modalProps)) {
      isActive = true
    }
  })

  return isActive
}

export default function ModalProvider({ children }) {
  const [state, setState] = useState({ activeModals: {}, activeModalsOrder: [] })
  const { activeModals, activeModalsOrder } = state
  const history = useHistory()

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const unblock = history.block((location) => {
      const isBlockedByModal = some(
        activeModals,
        (modal) => modal?.historyBlock?.(location) === false,
      )

      if (isBlockedByModal) {
        return false
      }
    })
    return unblock
  })

  const setHistoryBlock = (modalId, fn) => {
    setState((newState) => ({
      ...newState,
      activeModals: {
        ...newState.activeModals,
        [modalId]: {
          ...newState.activeModals[modalId],
          historyBlock: fn,
        },
      },
    }))
  }

  const openModal = (modalType, modalProps, modalBehavior = 'full') => {
    setState((newState) => {
      const isAlreadyActive = findActiveModal(newState.activeModals, modalType, modalProps)
      if (isAlreadyActive) {
        return newState
      }

      const modalId = uuid()

      const updatedOrder = [modalId, ...newState.activeModalsOrder]
      const updatedState = {
        ...newState.activeModals,
        [modalId]: {
          modalId,
          modalType,
          modalBehavior,
          modalProps,
          isClosing: false,
          isMinimized: false,
        },
      }

      return {
        activeModals: updateFocused(updatedState, updatedOrder),
        activeModalsOrder: updatedOrder,
      }
    })
  }

  // callback syntax is requierd when setting modal states due to the possibility of
  // multiple modals being opened at one time and closing all at the same time.
  // without callback syntax, the modals will flicker as they each change their status separately.
  const closeModal = (modalId) => {
    setState((newState) => {
      return {
        ...newState,
        activeModals: {
          ...newState.activeModals,
          [modalId]: {
            ...newState.activeModals[modalId],
            isClosing: true,
          },
        },
      }
    })
  }

  const onModalClosed = (modalId) => {
    setState((newState) => {
      const updatedState = omit(newState.activeModals, modalId)
      const updatedOrder = without(newState.activeModalsOrder, modalId)

      return {
        activeModalsOrder: updatedOrder,
        activeModals: updateFocused(updatedState, updatedOrder),
      }
    })
  }

  const minimizeModal = (modalId) => {
    setState((newState) => {
      return {
        ...newState,
        activeModals: {
          ...newState.activeModals,
          [modalId]: {
            ...newState.activeModals[modalId],
            isMinimized: !newState.activeModals[modalId].isMinimized,
          },
        },
      }
    })
  }

  const closeAllModals = (...exceptModalIds) => {
    setState((newState) => {
      const updatedOrder = newState.activeModalsOrder.filter((modalId) =>
        exceptModalIds.includes(modalId),
      )
      const updatedState = updateFocused(pick(newState.activeModals, exceptModalIds), updatedOrder)

      return {
        ...newState,
        activeModals: updatedState,
        activeModalsOrder: updatedOrder,
      }
    })
  }

  const groupedActiveModals = { semi: [], full: [], screen: [] }

  activeModalsOrder.forEach((modalId) => {
    const modal = activeModals[modalId]

    // if closing multiple modals, modal may be undefined.
    if (modal) {
      groupedActiveModals[modal.modalBehavior].push(modal)
    }
  })

  return (
    <ModalsContext.Provider value={{ activeModals, openModal, closeModal, closeAllModals }}>
      <Box>{children}</Box>

      <SemiModalContainer
        activeModals={groupedActiveModals.semi}
        openModal={openModal}
        closeModal={closeModal}
        onModalClosed={onModalClosed}
        minimizeModal={minimizeModal}
        setHistoryBlock={setHistoryBlock}
        closeAllModals={closeAllModals}
      />

      <ScreenModalContainer
        activeModals={groupedActiveModals.screen}
        openModal={openModal}
        closeModal={closeModal}
        onModalClosed={onModalClosed}
        setHistoryBlock={setHistoryBlock}
      />

      <FullModalContainer
        activeModals={groupedActiveModals.full}
        openModal={openModal}
        closeModal={closeModal}
        onModalClosed={onModalClosed}
        setHistoryBlock={setHistoryBlock}
      />
    </ModalsContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
