/**
 * @generated SignedSource<<27b70b0301ce091fed3e14eae33d957a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReportUserFileType = "EXPORT" | "INPUT" | "OUTPUT" | "SOURCE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReportStatusDetails_report$data = {
  readonly id: string;
  readonly errors: any | null;
  readonly periodStart: any | null;
  readonly periodEnd: any | null;
  readonly sourceKind: string | null;
  readonly workflowTemplate: {
    readonly id: string;
  } | null;
  readonly reportUserFiles: {
    readonly nodes: ReadonlyArray<{
      readonly type: ReportUserFileType;
      readonly userFile: {
        readonly id: string;
        readonly name: string;
        readonly meta: any | null;
      } | null;
    }>;
  };
  readonly reportIntegrations: {
    readonly nodes: ReadonlyArray<{
      readonly integration: {
        readonly id: string;
      } | null;
    }>;
  };
  readonly " $fragmentType": "ReportStatusDetails_report";
};
export type ReportStatusDetails_report$key = {
  readonly " $data"?: ReportStatusDetails_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportStatusDetails_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportStatusDetails_report",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowTemplate",
      "kind": "LinkedField",
      "name": "workflowTemplate",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportUserFilesConnection",
      "kind": "LinkedField",
      "name": "reportUserFiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportUserFile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserFile",
              "kind": "LinkedField",
              "name": "userFile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meta",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportIntegrationsConnection",
      "kind": "LinkedField",
      "name": "reportIntegrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportIntegration",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Integration",
              "kind": "LinkedField",
              "name": "integration",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "f76936b0951e86fc386076cc80cfe448";

export default node;
