/**
 * @generated SignedSource<<2defe59c53cf88fa06fee12167a15924>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ModulesCompanyHeaderQuery$variables = {
  companyId: string;
};
export type ModulesCompanyHeaderQuery$data = {
  readonly companyById: {
    readonly name: string;
    readonly rowId: any;
  } | null;
};
export type ModulesCompanyHeaderQuery = {
  variables: ModulesCompanyHeaderQuery$variables;
  response: ModulesCompanyHeaderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModulesCompanyHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModulesCompanyHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6db4578657ad07b6a9d608a68e4e5ab",
    "id": null,
    "metadata": {},
    "name": "ModulesCompanyHeaderQuery",
    "operationKind": "query",
    "text": "query ModulesCompanyHeaderQuery(\n  $companyId: ID!\n) {\n  companyById(id: $companyId) {\n    name\n    rowId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f013193fb2ff83fba5282a04f8b4494";

export default node;
