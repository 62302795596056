/**
 * @generated SignedSource<<688b7b235f80f65b9cd7a2de3ee974f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrgInviteStatus = "ACCEPTED" | "CANCELED" | "EXPIRED" | "PENDING" | "%future added value";
export type UserOrganizationRole = "ADMIN" | "MEMBER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembersContainer_organization$data = {
  readonly name: string;
  readonly id: string;
  readonly organizationInvites: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly emailAddress: string;
      readonly status: OrgInviteStatus | null;
      readonly intendedRole: UserOrganizationRole | null;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OrgUsersTable_organization">;
  readonly " $fragmentType": "OrganizationMembersContainer_organization";
};
export type OrganizationMembersContainer_organization$key = {
  readonly " $data"?: OrganizationMembersContainer_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationMembersContainer_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationMembersContainer_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrgUsersTable_organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationInvitesConnection",
      "kind": "LinkedField",
      "name": "organizationInvites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationInvite",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emailAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "intendedRole",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "5c3f15f50bf21b53b7a01a89339a6147";

export default node;
