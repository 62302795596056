import React, { useState, useEffect, useCallback } from 'react'
import { useRelayEnvironment } from 'react-relay/hooks'
import { AuthContext } from 'contexts'
import useApi from 'hooks/data/useApi'
import fetchCurrentUser from 'queries/fetchCurrentUser'
import { userIsOrgAdmin } from 'utils/user'
import { calculateTrialDaysRemaining } from '../utils/trial'
import { Company, Organization, BillingPlan } from '../contexts/AuthContext'

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState(null)
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null)
  const [currentOrg, setCurrentOrg] = useState<Organization | null>(null)
  const [isReady, setIsReady] = useState(false)
  const [freeTrial, setFreeTrial] = useState(false)
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0)
  const [activeSubscription, setActiveSubscription] = useState(false)
  const [billingPlan, setBillingPlan] = useState<BillingPlan | null>(null)
  const [allowClientCreation, setAllowClientCreation] = useState(false)
  const [activeCompanyCount, setActiveCompanyCount] = useState(0)

  const environment = useRelayEnvironment()
  const api = useApi()

  const login = useCallback(async () => {
    const currentUser = await fetchCurrentUser(environment)

    setUser(currentUser)
    setIsReady(true)
  }, [environment])

  const logout = useCallback(async () => {
    await api.auth.logout()
    setUser(null)
  }, [api, setUser])

  const handleSetCurrentOrg = useCallback(
    (org: any) => {
      if (currentOrg?.id !== org?.id) {
        setCurrentCompany(null)
        setCurrentOrg(org)

        const trialDays = calculateTrialDaysRemaining(org.trialEndedAt)

        const isFreeTrial = trialDays >= 0

        const orgSubscription = org?.organizationSubscriptions?.nodes?.[0]

        const isActiveSubscription = orgSubscription?.status === 'active'

        setActiveSubscription(isActiveSubscription)
        setBillingPlan(orgSubscription?.billingPlan || null)

        setFreeTrial(isFreeTrial)
        setTrialDaysRemaining(trialDays)

        setAllowClientCreation(isFreeTrial || isActiveSubscription)

        setActiveCompanyCount(org.activeCompanies?.totalCount || 0)
      }
    },
    [currentOrg],
  )

  useEffect(() => {
    login()
  }, [login])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn: !!user,
        isReady,
        login,
        logout,
        freeTrial,
        trialDaysRemaining,
        currentCompany,
        setCurrentCompany,
        currentOrg,
        setCurrentOrg: handleSetCurrentOrg,
        userIsCurrentOrgAdmin: userIsOrgAdmin(user, currentOrg),
        activeSubscription,
        billingPlan,
        allowClientCreation,
        activeCompanyCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
