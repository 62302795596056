import { graphql, fetchQuery } from 'react-relay/hooks'

export default async function fetchCurrentCompany(environment, companyId) {
  const data = await fetchQuery(
    environment,
    graphql`
      query fetchCurrentCompanyQuery($companyId: ID!) {
        companyById(id: $companyId) {
          id
          rowId
          name
          legalName
        }
      }
    `,
    { companyId },
  ).toPromise()

  return data?.companyById
}
