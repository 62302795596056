/**
 * @generated SignedSource<<5f671cf3447e33462ea703af3750f360>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowDashboard_report$data = {
  readonly reportWorkflowWorkspaces: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly key: string;
      readonly workspaceOrder: number | null;
      readonly " $fragmentSpreads": FragmentRefs<"WorkflowWorkspace_reportWorkflowWorkspace">;
    }>;
  };
  readonly " $fragmentType": "WorkflowDashboard_report";
};
export type WorkflowDashboard_report$key = {
  readonly " $data"?: WorkflowDashboard_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowDashboard_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkflowDashboard_report",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "WORKSPACE_ORDER_ASC"
        }
      ],
      "concreteType": "ReportWorkflowWorkspacesConnection",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspaces",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportWorkflowWorkspace",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workspaceOrder",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorkflowWorkspace_reportWorkflowWorkspace"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "0666f193740672037b6f97b1d8fc4efc";

export default node;
