import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexColumn } from 'elements'
import WorkflowWorkspace from './WorkflowWorkspace'

type Props = {
  report: any
}

export default function WorkflowDashboard(props: Props) {
  const { report } = props

  const data: any = useFragment(
    graphql`
      fragment WorkflowDashboard_report on Report {
        reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
          nodes {
            name
            key
            workspaceOrder
            ...WorkflowWorkspace_reportWorkflowWorkspace
          }
        }
      }
    `,
    report,
  )

  return (
    <FlexColumn>
      {data.reportWorkflowWorkspaces.nodes.map((workspace: any, idx: number) => (
        <FlexColumn mb="xxlarge" key={workspace.key}>
          <WorkflowWorkspace workspace={workspace} idx={idx} />
        </FlexColumn>
      ))}
    </FlexColumn>
  )
}
