import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { ReportSection, SourceKind } from '../types'
import ReportSectionComponent from './ReportSection'
import ReportResolution from '../resolve/ReportResolution'
import { transactionRepairStepKeys } from '../constants'
import TableEmptyState from '../../common/TableEmptyState'
import { FlexColumn } from 'elements'
import NotFound from '../../router/NotFound'

const REPORT_ROUTE = '/orgs/:orgSlug/companies/:companyId/reports/:reportId/:section?/:stepId?'

type Props = {
  sections: ReportSection[]
  report: any
  step: any
  sourceKind: SourceKind
}

export default function ReportStep(props: Props) {
  const { sections, report, step, sourceKind } = props

  const data: any = useFragment(
    graphql`
      fragment ReportStep_reportWorkflowWorkspaceStep on ReportWorkflowWorkspaceStep {
        key
      }
    `,
    step,
  )

  if (!data) {
    return (
      <FlexColumn pt="5%">
        <NotFound errorMessage="We couldn't find that section." />
      </FlexColumn>
    )
  }

  const stepKey = data.key

  if (transactionRepairStepKeys.includes(stepKey)) {
    return <ReportResolution report={report} />
  }

  const currentReportSection = sections.find((section) => section.value === stepKey)

  if (!currentReportSection) {
    return (
      <FlexColumn pt="xxlarge">
        <TableEmptyState />
      </FlexColumn>
    )
  }

  return (
    <ReportSectionComponent
      route={REPORT_ROUTE}
      reportSection={currentReportSection}
      report={report}
      sourceKind={sourceKind}
    />
  )
}
