/**
 * @generated SignedSource<<c25ce21118ac2df117cdfdf2fae50917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationStatus = "DISABLED" | "ENABLED" | "SUSPENDED" | "%future added value";
export type CompanyNavigationQuery$variables = {
  companyId: string;
  reportId: string;
  orgSlug: string;
};
export type CompanyNavigationQuery$data = {
  readonly companyById: {
    readonly id: string;
    readonly name: string;
    readonly integrations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly status: IntegrationStatus;
          readonly config: any | null;
          readonly kind: string;
        };
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PeriodSelect_company">;
  } | null;
  readonly reportById: {
    readonly id: string;
    readonly periodStart: any | null;
    readonly periodEnd: any | null;
    readonly workflowTemplateId: any | null;
    readonly " $fragmentSpreads": FragmentRefs<"LastSyncDate_report">;
  } | null;
  readonly organizationBySlug: {
    readonly workflowTemplatesWithDefaults: {
      readonly nodes: ReadonlyArray<{
        readonly rowId: any;
        readonly id: string;
        readonly name: string | null;
      }>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CompanySelect_organization" | "WorkflowTemplateSelect_organization">;
  } | null;
};
export type CompanyNavigationQuery = {
  variables: CompanyNavigationQuery$variables;
  response: CompanyNavigationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orgSlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "IntegrationsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Integration",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reportId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodStart",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodEnd",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workflowTemplateId",
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "orgSlug"
  }
],
v15 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v16 = {
  "kind": "Literal",
  "name": "includeDeleted",
  "value": "NO"
},
v17 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "CREATED_AT_DESC"
},
v18 = [
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkflowTemplate",
  "kind": "LinkedField",
  "name": "nodes",
  "plural": true,
  "selections": [
    (v19/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v21 = [
  (v15/*: any*/)
],
v22 = [
  (v15/*: any*/),
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "uniqueByPeriod": true
    }
  },
  (v17/*: any*/)
],
v23 = [
  (v15/*: any*/),
  (v16/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "NAME_ASC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": "integrations",
            "args": null,
            "concreteType": "IntegrationsConnection",
            "kind": "LinkedField",
            "name": "__CompanyNavigation_integrations_connection",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PeriodSelect_company"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LastSyncDate_report"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "WorkflowTemplatesConnection",
            "kind": "LinkedField",
            "name": "workflowTemplatesWithDefaults",
            "plural": false,
            "selections": [
              (v20/*: any*/)
            ],
            "storageKey": "workflowTemplatesWithDefaults(first:100,includeDeleted:\"NO\",orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanySelect_organization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkflowTemplateSelect_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompanyNavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "companyById",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v21/*: any*/),
            "concreteType": "IntegrationsConnection",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": "integrations(first:100)"
          },
          {
            "alias": null,
            "args": (v21/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CompanyNavigation_integrations",
            "kind": "LinkedHandle",
            "name": "integrations"
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "concreteType": "ReportsConnection",
            "kind": "LinkedField",
            "name": "filteredReports",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Report",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "filteredReports(first:100,input:{\"uniqueByPeriod\":true},orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "filters": [
              "input",
              "orderBy"
            ],
            "handle": "connection",
            "key": "PeriodSelect_filteredReports",
            "kind": "LinkedHandle",
            "name": "filteredReports"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "WorkflowTemplatesConnection",
            "kind": "LinkedField",
            "name": "workflowTemplatesWithDefaults",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowTemplatesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v19/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "workflowTemplatesWithDefaults(first:100,includeDeleted:\"NO\",orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v18/*: any*/),
            "filters": [
              "includeDeleted",
              "orderBy"
            ],
            "handle": "connection",
            "key": "WorkflowTemplateSelect_workflowTemplatesWithDefaults",
            "kind": "LinkedHandle",
            "name": "workflowTemplatesWithDefaults"
          },
          {
            "alias": null,
            "args": (v23/*: any*/),
            "concreteType": "CompaniesConnection",
            "kind": "LinkedField",
            "name": "companies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompaniesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "companies(first:100,includeDeleted:\"NO\",orderBy:\"NAME_ASC\")"
          },
          {
            "alias": null,
            "args": (v23/*: any*/),
            "filters": [
              "orderBy",
              "includeDeleted"
            ],
            "handle": "connection",
            "key": "CompanySelect_companies",
            "kind": "LinkedHandle",
            "name": "companies"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e240f7967055111965b51c8fff328a40",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "companyById",
            "integrations"
          ]
        }
      ]
    },
    "name": "CompanyNavigationQuery",
    "operationKind": "query",
    "text": "query CompanyNavigationQuery(\n  $companyId: ID!\n  $reportId: ID!\n  $orgSlug: String!\n) {\n  companyById(id: $companyId) {\n    id\n    name\n    integrations(first: 100) {\n      edges {\n        node {\n          id\n          status\n          config\n          kind\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...PeriodSelect_company\n  }\n  reportById(id: $reportId) {\n    id\n    periodStart\n    periodEnd\n    workflowTemplateId\n    ...LastSyncDate_report\n  }\n  organizationBySlug(slug: $orgSlug) {\n    workflowTemplatesWithDefaults(first: 100, includeDeleted: NO, orderBy: CREATED_AT_DESC) {\n      nodes {\n        rowId\n        id\n        name\n      }\n    }\n    ...CompanySelect_organization\n    ...WorkflowTemplateSelect_organization\n    id\n  }\n}\n\nfragment CompanySelect_organization on Organization {\n  companies(orderBy: NAME_ASC, includeDeleted: NO, first: 100) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment LastSyncDate_report on Report {\n  updatedAt\n}\n\nfragment PeriodSelect_company on Company {\n  id\n  filteredReports(input: {uniqueByPeriod: true}, orderBy: CREATED_AT_DESC, first: 100) {\n    edges {\n      node {\n        periodStart\n        periodEnd\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment WorkflowTemplateSelect_organization on Organization {\n  workflowTemplatesWithDefaults(first: 100, includeDeleted: NO, orderBy: CREATED_AT_DESC) {\n    edges {\n      node {\n        id\n        rowId\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "271442f4c7b8fa87a46a713405628a53";

export default node;
