import React from 'react'
import { FlexColumn, Text, Card, Button } from 'elements'

type Props = {
  working: boolean
  handleCheckout: Function
}

export default function FreeTrialCard(props: Props & Record<string, any>) {
  const { working, handleCheckout } = props

  return (
    <Card
      bg="background.0"
      pb="xxxlarge"
      pt="xxxlarge"
      mb="xxxlarge"
      width="768px"
      flexDirection="column"
      {...props}
    >
      <FlexColumn
        justifyContent="space-between"
        pt="large"
        pb="large"
        alignItems="center"
        height="200px"
      >
        <FlexColumn alignItems="center">
          <Text fontSize="xxlarge" mb="large">
            You're on the <b>Free Trial.</b>
          </Text>
          <Text fontSize="large" mt="large" color="neutral.3" fontStyle="italic">
            Upgrade your plan to continue using all of Scrutinize’s great features.
          </Text>
        </FlexColumn>
        <Button disabled={working} onClick={handleCheckout} kind="primary">
          {working ? 'Please wait...' : 'Upgrade Your Plan'}
        </Button>
      </FlexColumn>
    </Card>
  )
}
