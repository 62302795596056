/**
 * @generated SignedSource<<0585dcdbdfcdb75a2326bdaaf6f78eec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchAnomalyReportFileQuery$variables = {
  reportId: string;
};
export type fetchAnomalyReportFileQuery$data = {
  readonly reportById: {
    readonly outputFiles: {
      readonly nodes: ReadonlyArray<{
        readonly userFile: {
          readonly rowId: any;
        } | null;
      }>;
    };
  } | null;
};
export type fetchAnomalyReportFileQuery = {
  variables: fetchAnomalyReportFileQuery$variables;
  response: fetchAnomalyReportFileQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "reportId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "type": "OUTPUT"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchAnomalyReportFileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          {
            "alias": "outputFiles",
            "args": (v2/*: any*/),
            "concreteType": "ReportUserFilesConnection",
            "kind": "LinkedField",
            "name": "reportUserFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFile",
                    "kind": "LinkedField",
                    "name": "userFile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "reportUserFiles(condition:{\"type\":\"OUTPUT\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchAnomalyReportFileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Report",
        "kind": "LinkedField",
        "name": "reportById",
        "plural": false,
        "selections": [
          {
            "alias": "outputFiles",
            "args": (v2/*: any*/),
            "concreteType": "ReportUserFilesConnection",
            "kind": "LinkedField",
            "name": "reportUserFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportUserFile",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFile",
                    "kind": "LinkedField",
                    "name": "userFile",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "reportUserFiles(condition:{\"type\":\"OUTPUT\"})"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7e7d3c25f66ce5d727b350590e08795",
    "id": null,
    "metadata": {},
    "name": "fetchAnomalyReportFileQuery",
    "operationKind": "query",
    "text": "query fetchAnomalyReportFileQuery(\n  $reportId: ID!\n) {\n  reportById(id: $reportId) {\n    outputFiles: reportUserFiles(condition: {type: OUTPUT}) {\n      nodes {\n        userFile {\n          rowId\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ab69d9084dbd0c5a0dee8e64444c4f6";

export default node;
