import { graphql } from 'react-relay/hooks'
import { useMutation } from 'hooks'

const mutation = graphql`
  mutation useReportCreateMutation(
    $input: CreateReportInput!
    $includeDeleted: IncludeDeletedOption
  ) {
    createReport(input: $input) {
      report {
        id
        rowId
        company {
          ...PeriodSelect_company
          ...CompaniesTableRow_company
          ...ActiveWorkflowTable_company
          ...CompletedWorkflowTable_company
        }
        reportIntegrations {
          nodes {
            integration {
              id
            }
          }
        }
      }
    }
  }
`

export default function useReportCreate() {
  return useMutation(mutation, 'createReport')
}
