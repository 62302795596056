import React from 'react'
import { FormattedDate } from 'react-intl'
import { FlexColumn, Text, Anchor, Icon, Button } from 'elements'

type Props = {
  errors: any[]
  periodStart: string
  periodEnd: string
  handleRetry: () => void
  submitting: boolean
}

const MISSING_TXNS_MESSAGE = 'No general ledger transactions found'
const MISSING_ACCOUNTS_MESSAGE = 'No accounts found'

export default function MissingDataStatus(props: Props) {
  const { errors, periodStart, periodEnd, handleRetry, submitting } = props

  const missingTxnsError = errors.find((error) => error.message === MISSING_TXNS_MESSAGE)
  const missingAccountsError = errors.find((error) => error.message === MISSING_ACCOUNTS_MESSAGE)

  let message = 'Insufficient data for'

  if (missingTxnsError) {
    message = 'No transactions were found in this'
  } else if (missingAccountsError) {
    message = 'No accounts were found in this'
  }

  return (
    <FlexColumn p="xxlarge" alignItems="center" color="danger.0">
      <Icon name="exclamation-circle" color="inherit" size="xxlarge" mb="medium" />
      <Text fontSize="xlarge" lineHeight="xlarge" color="inherit" mb="large">
        {message} period {'('}
        <FormattedDate value={periodStart} timeZone="utc" />
        {' - '}
        <FormattedDate value={periodEnd} timeZone="utc" />
        {').'}
      </Text>
      <Button onClick={handleRetry} disabled={submitting} mb="gigantic">
        Retry Workflow
      </Button>
      <Text textAlign="center" mb="xxxlarge">
        Please feel free to review our support documentation or create a ticket{' '}
        <Anchor href="https://scrutinize.freshdesk.com/support/home" target="_blank">
          here.
        </Anchor>
      </Text>
    </FlexColumn>
  )
}
