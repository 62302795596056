/**
 * @generated SignedSource<<2b0afe0599a68773005a5eadadbd85d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCompanyByIdInput = {
  clientMutationId?: string | null;
  id: string;
  patch: CompanyPatch;
};
export type CompanyPatch = {
  createdAt?: any | null;
  deletedAt?: any | null;
  latestPeriodEnd?: any | null;
  legalName?: string | null;
  name?: string | null;
  organizationId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
};
export type useCompanyUpdateMutation$variables = {
  input: UpdateCompanyByIdInput;
};
export type useCompanyUpdateMutation$data = {
  readonly updateCompanyById: {
    readonly company: {
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
};
export type useCompanyUpdateMutation = {
  variables: useCompanyUpdateMutation$variables;
  response: useCompanyUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCompanyPayload",
    "kind": "LinkedField",
    "name": "updateCompanyById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompanyUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompanyUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e808a98cc22d1f4cd44714f181bad9e5",
    "id": null,
    "metadata": {},
    "name": "useCompanyUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useCompanyUpdateMutation(\n  $input: UpdateCompanyByIdInput!\n) {\n  updateCompanyById(input: $input) {\n    company {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a47dd48531eaac435d6c6247b7884e50";

export default node;
