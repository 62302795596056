import React from 'react'
import { FlexRow, Text, Icon } from 'elements'
import useIntegrationVerify from './hooks/useIntegrationVerify'

const icons = {
  disabled: 'question-circle',
  enabled: 'check',
  error: 'exclamation-circle',
}

const messages = {
  disabled: 'Disabled',
  enabled: 'Connected',
  error: 'An error occurred',
}

export default function IntegrationStatus({
  status,
  color = 'background.0',
  userIntegration,
  showConnected = null,
}) {
  useIntegrationVerify(userIntegration?.rowId)
  const integrationTitle =
    userIntegration?.config?.xero?.currentTenant?.tenantName ||
    userIntegration?.config?.intuit?.companyName ||
    userIntegration?.config?.plaid?.institutionName

  return (
    <FlexRow alignItems="center">
      <Icon name={icons[status] || 'question-circle'} size="small" color={color} mr="medium" />
      <Text fontSize="small" fontWeight="medium" color={color}>
        {showConnected || integrationTitle || messages[status] || 'Available'}
      </Text>
    </FlexRow>
  )
}
