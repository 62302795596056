import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks'

export default function RequireAuth({ children }) {
  const { isReady, isLoggedIn } = useAuth()
  const location = useLocation()

  if (!isReady) {
    // isLoggedIn will be false until the logged in
    // user is fetched, so wait until ready to perform
    // redirects and such
    return null
  }

  if (!isLoggedIn) {
    return <Redirect to={{ pathname: '/auth/login', state: { from: location.pathname } }} />
  }

  return children
}
