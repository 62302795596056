import { startCase } from 'lodash'
import { BillingPlan, BillingPlanTier } from '../contexts/AuthContext'

const MAX_BILL_COMPANY_COUNT = 50

export function getBillingPlanTier(
  billingPlan: BillingPlan | null,
  activeCompanies: number,
): BillingPlanTier | null {
  if (!billingPlan) {
    return null
  }

  return (
    billingPlan.config.tiers?.find((tier) => {
      if (activeCompanies > MAX_BILL_COMPANY_COUNT) {
        return tier.name === 'unlimited'
      }

      const { minCompanyCount, maxCompanyCount } = tier

      return activeCompanies >= minCompanyCount && activeCompanies <= maxCompanyCount
    }) || null
  )
}

export function getNextBillingPlanTier(
  billingPlan: BillingPlan | null,
  maxCompanyCount?: number,
): BillingPlanTier | null {
  if (!maxCompanyCount) {
    return null
  }

  return (
    billingPlan?.config?.tiers?.find((tier) => tier.minCompanyCount === maxCompanyCount + 1) || null
  )
}

type TierInfo = {
  name: string
  price: string
}

export function getTierInfo(tier: BillingPlanTier | null): TierInfo | null {
  if (!tier) {
    return null
  }

  return {
    name: startCase(tier.name),
    price: `$${tier.price / 100}`,
  }
}
