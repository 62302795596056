import styled from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  layout,
  shadow,
  space,
  typography,
  variant,
} from 'styled-system'
import { print } from '../styles'
import { alignToTextAlign } from './utils'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      bg: 'background.0',
    },
    secondary: {
      bg: 'secondary.0',
      color: 'background.0',
    },
    inactive: {
      bg: 'rgba(255, 255, 255, 0.3)',
      // '& > *:not(.popup-content)': { opacity: '0.5' },
    },
  },
})

const CardTableCell = styled.td`
  ${print}
  ${compose(kind, space, typography, color, layout, background, border, shadow)}

  &:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:first-child {
    padding-left: ${({ theme }) => theme.space.xlarge}px;
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.space.xlarge}px;
  }

  ${({ onClick }) => (onClick ? 'cursor: pointer;' : null)}
  text-align: ${(props) => alignToTextAlign(props.align)};

`

CardTableCell.defaultProps = {
  py: 'xlarge',
  px: 'xlarge',
  borderRadius: 'small',
  fontSize: 'medium',
  kind: 'normal',
}

export default CardTableCell
