import { get } from 'lodash'
import theme from 'theme'

export function hexToRGB(hex: string, opacity?: number): string {
  //remove hash, split into consituents
  const splitHex = hex.replace('#', '').match(/.{1,2}/g)

  if (!splitHex?.length) {
    return hex
  }

  const rgb = [
    parseInt(splitHex[0], 16),
    parseInt(splitHex[1], 16),
    parseInt(splitHex[2], 16),
  ].join(',')

  if (opacity) {
    return `rgba(${rgb},${opacity})`
  }

  return `rgb(${rgb})`
}

export function colorToRGB(color: string, opacity?: number) {
  const hex = get(theme.colors, color)

  return hexToRGB(hex, opacity)
}
