import styled, { css } from 'styled-components'
import {
  compose,
  border,
  space,
  layout,
  typography,
  color,
  flexbox,
  grid,
  position,
  variant,
  shadow,
} from 'styled-system'

const interactiveCss = css`
  cursor: pointer;
`

const Card = styled.div`
  ${compose(
    variant({
      prop: 'kind',
      variants: {
        default: {
          backgroundColor: 'background.0',
        },
        danger: {
          backgroundColor: 'danger.0',
          color: 'neutral.1',
        },
        inactive: {
          backgroundColor: 'neutral.2',
          color: 'neutral.1',
        },
        hover: {
          backgroundColor: 'background.0',
          border: 'solid 2px',
          borderColor: 'background.0',
          ':hover': { borderColor: 'secondary.0' },
        },
      },
    }),
    border,
    space,
    layout,
    typography,
    color,
    flexbox,
    grid,
    position,
    shadow,
  )}

  ${({ onClick }) => (onClick ? interactiveCss : null)}
`

Card.defaultProps = {
  display: 'flex',
  kind: 'default',
  boxShadow: 'medium',
  borderRadius: 'small',
  border: 'none',
}

export default Card
