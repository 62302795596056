import { useMemo } from 'react'
import config from 'config'
import useApi from './useApi'

export type FileDescriptor = {
  file: File
  kind: string
}

export default function useStorage() {
  const api = useApi()

  return useMemo(
    () => ({
      async put(path: string, files: FileDescriptor[], opts: any) {
        const data = new FormData()

        // add path to form data
        data.append('path', path)

        // add kinds to form data
        files.forEach((desc) => data.append('kind', desc.kind))

        // NOTE: file portion of FormData must be the last appended
        files.forEach((desc) => data.append('file', desc.file))

        const result = await api.user.upload(data, opts)
        return result.data
      },
      get(id: string) {
        return api.user.download(id)
      },
      url(id: string) {
        return `${config.api.host}/user/uploads/${id}/object?download=true`
      },
    }),
    [api],
  )
}
