import theme from '.'
import { ApexOptions } from 'apexcharts'

const baseChartOptions: ApexOptions = {
  chart: {
    width: '100%',
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: [theme.colors.foreground[0]],
        fontSize: theme.fontSizes.small,
        fontFamily: theme.fontFamily,
      },
    },
  },
  yaxis: {
    title: {
      style: {
        color: theme.colors.foreground[0],
        fontSize: theme.fontSizes.small,
        fontFamily: theme.fontFamily,
      },
    },
    labels: {
      style: {
        colors: [theme.colors.foreground[0]],
        fontSize: theme.fontSizes.small,
        fontFamily: theme.fontFamily,
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: theme.fontSizes.small,
      colors: [theme.colors.foreground[0]],
    },
  },
  fill: { opacity: 1 },
  legend: {
    fontSize: theme.fontSizes.small,
    fontFamily: theme.fontFamily,
  },
  colors: [theme.colors.secondary[4], theme.colors.secondary[0], theme.colors.secondary[3]],
  grid: {
    borderColor: theme.colors.neutral[7],
  },
  title: {
    style: {
      fontSize: theme.fontSizes.small,
      color: theme.colors.foreground[0],
      fontFamily: theme.fontFamily,
    },
  },
}

export default baseChartOptions
