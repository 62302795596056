/**
 * @generated SignedSource<<40572393a03965f0bee177d2812f57bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportResolutionTableRefetchQuery$variables = {
  anomalies?: ReadonlyArray<string | null> | null;
  count?: number | null;
  cursor?: any | null;
  resolutionSection?: string | null;
  id: string;
};
export type ReportResolutionTableRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ReportResolutionTable_report">;
  } | null;
};
export type ReportResolutionTableRefetchQuery = {
  variables: ReportResolutionTableRefetchQuery$variables;
  response: ReportResolutionTableRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anomalies"
},
v1 = {
  "defaultValue": 100,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resolutionSection"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "anomalies",
        "variableName": "anomalies"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "resolutionSection"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportResolutionTableRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ReportResolutionTable_report"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportResolutionTableRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ReportResolutionsConnection",
                "kind": "LinkedField",
                "name": "filteredReportResolutions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportResolutionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReportResolution",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "anomalyTypes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currentValue",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updateStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "responseData",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "resolutionKind",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "input"
                ],
                "handle": "connection",
                "key": "ReportResolutionTable_filteredReportResolutions",
                "kind": "LinkedHandle",
                "name": "filteredReportResolutions"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "resolutionProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "WORKSPACE_ORDER_ASC"
                  }
                ],
                "concreteType": "ReportWorkflowWorkspacesConnection",
                "kind": "LinkedField",
                "name": "reportWorkflowWorkspaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportWorkflowWorkspace",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "STEP_ORDER_ASC"
                          }
                        ],
                        "concreteType": "ReportWorkflowWorkspaceStepsConnection",
                        "kind": "LinkedField",
                        "name": "reportWorkflowWorkspaceSteps",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportWorkflowWorkspaceStep",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "reportWorkflowWorkspaces(orderBy:\"WORKSPACE_ORDER_ASC\")"
              }
            ],
            "type": "Report",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01f119355dc86e7fbcda395facc57584",
    "id": null,
    "metadata": {},
    "name": "ReportResolutionTableRefetchQuery",
    "operationKind": "query",
    "text": "query ReportResolutionTableRefetchQuery(\n  $anomalies: [String]\n  $count: Int = 100\n  $cursor: Cursor\n  $resolutionSection: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ReportResolutionTable_report_1G22uz\n    id\n  }\n}\n\nfragment ReportResolutionTable_report_1G22uz on Report {\n  filteredReportResolutions(input: {anomalies: $anomalies, status: $resolutionSection}, first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        anomalyTypes\n        currentValue\n        status\n        updateStatus\n        responseData\n        resolutionKind\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  resolutionProgress\n  reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {\n    nodes {\n      reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {\n        nodes {\n          id\n          status\n        }\n      }\n      id\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "6dae27e22820e1ea245db9744c3ee223";

export default node;
