import React from 'react'
import styled from 'styled-components'
import {
  compose,
  variant,
  border,
  space,
  color,
  flexbox,
  shadow,
  typography,
  layout,
} from 'styled-system'
import { omit } from 'lodash'
import { buttonBase, print } from '../styles'

const states = {
  '&:hover': {
    boxShadow: 'medium',
  },
  '&:focus, &:active': {
    boxShadow: 'small',
  },
  '&:active': {
    boxShadow: 'small',
  },
  '&:active:focus': {
    boxShadow: 'none',
    borderStyle: 'solid',
  },
}

const kind = variant({
  prop: 'kind',
  variants: {
    neutral: {
      color: 'neutral.4',
      bg: 'neutral.2',
      borderColor: 'neutral.2',
      ...states,
    },
    primary: {
      color: 'background.0',
      bg: 'primary.0',
      borderColor: 'primary.0',
      ...states,
    },
    danger: {
      color: 'background.0',
      bg: 'danger.0',
      borderColor: 'danger.0',
      ...states,
    },
    'alt-neutral': {
      color: 'neutral.1',
      bg: 'background.0',
      borderColor: 'neutral.2',
      ...states,
    },
    'alt-primary': {
      color: 'primary.0',
      bg: 'background.0',
      borderColor: 'primary.0',
      ...states,
    },
    'alt-danger': {
      color: 'danger.0',
      bg: 'background.0',
      borderColor: 'danger.0',
      ...states,
    },
  },
})

const area = variant({
  prop: 'area',
  variants: {
    small: {
      fontSize: 'small',
      height: 'xlarge',
      px: 'xsmall',
    },
    medium: {
      fontSize: 'medium',
      height: 'xxlarge',
      px: 'medium',
    },
  },
})

const StyledButton = styled.button`
  ${buttonBase}
  ${print}

  ${compose(
    // custom variants should go at the top so props they define
    // can be overridden on occasion when needed
    area,
    kind,
    border,
    space,
    flexbox,
    color,
    shadow,
    typography,
    layout,
  )}
`

function handleClick(event, props) {
  const { onClick, onPress, bindData } = props

  if (onPress) {
    event.preventDefault()
    onPress(bindData)
  }

  if (onClick) {
    onClick(event)
  }
}

const Button = React.forwardRef((props, ref) => (
  <StyledButton {...omit(props, ['onPress'])} ref={ref} onClick={(e) => handleClick(e, props)} />
))

Button.defaultProps = {
  kind: 'neutral',
  area: 'medium',
  borderRadius: 'xsmall',
  borderWidth: 'xthin',
  fontWeight: 'light',
  justifyContent: 'left',
}

export default Button
