import TableConnected from './TableConnected'
import LedgerCell from './cells/LedgerCell'
import DateCell from './cells/DateCell'
import StandardCell from './cells/StandardCell'
import ExpandSubComponentCell from './cells/ExpandSubComponentCell'
import ExpandSubRowsHeader from './cells/ExpandSubRowsHeader'
import PercentageCell from './cells/PercentageCell'
import CheckboxCell from './cells/CheckboxCell'
import InputCell from './cells/InputCell'
import SelectCell from './cells/SelectCell'
import EditableCell from './cells/EditableCell'
import DeepValueCell from './cells/DeepValueCell'
import SeverityCell from './cells/SeverityCell'
import NumericCell from './cells/NumericCell'
import DeleteCell from './cells/DeleteCell'

TableConnected.LedgerCell = LedgerCell
TableConnected.DateCell = DateCell
TableConnected.StandardCell = StandardCell
TableConnected.ExpandSubComponentCell = ExpandSubComponentCell
TableConnected.ExpandSubRowsHeader = ExpandSubRowsHeader
TableConnected.PercentageCell = PercentageCell
TableConnected.CheckboxCell = CheckboxCell
TableConnected.InputCell = InputCell
TableConnected.SelectCell = SelectCell
TableConnected.EditableCell = EditableCell
TableConnected.DeepValueCell = DeepValueCell
TableConnected.SeverityCell = SeverityCell
TableConnected.NumericCell = NumericCell
TableConnected.DeleteCell = DeleteCell

export default TableConnected
