import React, { useState } from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { FlexRow, FlexColumn, Text, Select, Button, SuspenseBoundary } from 'elements'
import { formatName } from 'utils/string'
import useReportResolutionUpdateStatuses from './hooks/useReportResolutionUpdateStatuses'
import { Option } from '../../../shared/types'
import { useAnalytics, usePage } from 'hooks'
import ReportResolutionTable from './ReportResolutionTable'

type Props = {
  report: any
}

type AnomalyCount = {
  type: string
  count: number
}

export default function ReportResolution(props: Props) {
  const { report } = props
  const [selectedRows, setSelectedRows] = useState([])
  const [updateResolutionStatuses, updateInFlight]: any = useReportResolutionUpdateStatuses()
  const { pageProps, setPageProps } = usePage()
  const { resolutionSection, resolutionAnomalyView } = pageProps

  const analytics = useAnalytics()

  const data = useFragment(
    graphql`
      fragment ReportResolution_report on Report
        @refetchable(queryName: "ReportResolutionRefetchQuery") {
        id
        reviewStatus
        anomalyCounts {
          type
          count
        }
      }
    `,
    report,
  )

  const anomalyOptions = data.anomalyCounts.map((anomalyCount: AnomalyCount) => ({
    label: formatName(anomalyCount.type),
    value: anomalyCount,
  }))

  const hasSelected = !!selectedRows.length

  return (
    <FlexColumn pt="xxlarge">
      <FlexRow alignItems="center" justifyContent="space-between" mb="xxxlarge">
        <FlexColumn width="30%" mr="xlarge" justifyContent="center">
          <Text fontStyle="italic" mb="xsmall">
            Sort by flag type
          </Text>
          {/* @ts-ignore */}
          <Select
            options={anomalyOptions}
            value={anomalyOptions.find(
              (option: Option) => option.value.type === resolutionAnomalyView,
            )}
            placeholder="Select Anomaly"
            onChange={(option: Option) =>
              setPageProps({ resolutionAnomalyView: option.value.type })
            }
            formatOptionLabel={(option: Option, meta: any) => {
              const { label, value } = option
              const { context } = meta

              return (
                <FlexRow justifyContent="space-between">
                  <Text>{label}</Text>
                  {context === 'menu' && <Text>{value.count}</Text>}
                </FlexRow>
              )
            }}
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow mb="medium">
        {hasSelected && resolutionSection !== 'resolved' && (
          <Button
            disabled={updateInFlight}
            onClick={async () => {
              const targetStatus = resolutionSection === 'forReview' ? 'reviewed' : 'forReview'

              await updateResolutionStatuses(
                {
                  reportId: data.id,
                  resolutionIds: selectedRows.map((row: any) => row.resolution.id),
                  status: targetStatus,
                },
                {
                  anomalies: resolutionAnomalyView === 'all' ? null : [resolutionAnomalyView],
                  resolutionSection,
                },
              )

              const message =
                resolutionSection === 'forReview'
                  ? 'Resolutions Reviewed'
                  : 'Resolutions Review Undone'

              analytics.track(message, { reportId: data.id })

              // selected rows are not automatically updating when the rows change sections
              setSelectedRows([])
            }}
          >
            {resolutionSection === 'forReview' ? 'Mark as Reviewed' : 'Undo Review'}
          </Button>
        )}
      </FlexRow>
      <SuspenseBoundary>
        <ReportResolutionTable report={report} onRowSelect={setSelectedRows} />
      </SuspenseBoundary>
    </FlexColumn>
  )
}
