import React from 'react'
import StandardCell from './StandardCell'

export default function EditableCell(props) {
  const { column, row, cell } = props
  const { EditCell, DefaultCell } = column

  if (column.editable && row.rowProps?.editable && cell.cellProps?.editable) {
    return <EditCell {...props} />
  }

  if (DefaultCell) {
    return <DefaultCell {...props} />
  }

  return <StandardCell {...props} />
}
