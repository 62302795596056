import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '../Box'
import Tray from '../Tray'

const AnimatedTray = styled(Tray)`
  @keyframes transitionIn {
    0% {
      opacity: 0;
      transform: translateY(24px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes transitionOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(24px);
    }
  }

  opacity: 1;
  transform: none;
  animation-name: ${({ isClosing }) => (isClosing ? 'transitionOut' : 'transitionIn')};
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.25s;
`

function SemiModal({ children, isClosing, onAfterClose, ...props }) {
  useEffect(() => {
    let timeoutId

    if (isClosing) {
      timeoutId = setTimeout(onAfterClose, 200)
    }

    // clear any lingering timeouts on unmount
    return () => timeoutId && clearTimeout(timeoutId)
  }, [onAfterClose, isClosing])

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      overflowY="hidden"
      px="small"
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        height="100%"
        width={['500px', '500px', '500px', '500px', '550px', '654px']}
      >
        <AnimatedTray top="gigantic" isClosing={isClosing}>
          {children}
        </AnimatedTray>
      </Box>
    </Box>
  )
}

SemiModal.propTypes = {
  children: PropTypes.node.isRequired,
  isClosing: PropTypes.bool,
  onAfterClose: PropTypes.func.isRequired,
}

export default SemiModal
