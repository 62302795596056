import { keyBy, merge, cloneDeep } from 'lodash'
import { formatHeader } from './formatting'
import { Column, Align, SourceKind } from '../types'
import { excludedColumns, cellTypes, descColumns, standardColumns } from '../constants'
import { TableConnected } from 'elements'

const { StandardCell, LedgerCell, PercentageCell, NumericCell } = TableConnected as any

const colAlignments: Map<Function, Align> = new Map([
  [LedgerCell, 'right'],
  [PercentageCell, 'right'],
  [NumericCell, 'right'],
])

export function generateColumns(dataObj: Record<string, any>): Column[] {
  const filteredKeys = Object.keys(dataObj).filter((key) => !excludedColumns.includes(key))

  return filteredKeys.map((key, i) => {
    const Header = formatHeader(key)
    const Cell = cellTypes[Header] || StandardCell

    const col: Column = {
      Header,
      accessor: key,
      Cell,
      sortDescFirst: descColumns.includes(Header),
    }

    const align = colAlignments.get(Cell)

    if (align) {
      col.align = align
    }

    return col
  })
}

export function mergeColumns(columnsA: Column[], columnsB?: Partial<Column>[]): Column[] {
  const keyedA = keyBy(columnsA, 'Header')
  const keyedB = keyBy(columnsB, 'Header')

  return Object.values(merge({}, keyedA, keyedB))
}

type GetColsProps = {
  dataObj?: Record<string, any>
  columns?: Column[]
  mergeCols?: Partial<Column>[]
}

export function getColumns(props: GetColsProps): Column[] {
  const { dataObj, mergeCols, columns } = props

  // add default standard cell and alignment by type
  if (columns) {
    return columns.map((col) => {
      const { columns, Cell, align } = col
      const newCol = cloneDeep(col)

      if (!align && colAlignments.get(Cell)) {
        newCol.align = colAlignments.get(Cell)
      }

      if (!Cell) {
        newCol.Cell = StandardCell
      }

      // tables support nested columns, see Balance Sheet Variance table
      if (columns?.length) {
        newCol.columns = getColumns({ columns: col.columns })
      }

      return newCol
    })
  }

  if (dataObj) {
    return mergeColumns(generateColumns(dataObj), mergeCols)
  }

  return []
}

// exclude default hidden columns for upload and xero reports – they don't have that info
export function getStandardColumns(sourceKind?: SourceKind): Column[] {
  if (sourceKind && sourceKind !== 'qb:json') {
    return standardColumns.filter((col) => col.defaultShow !== false)
  }

  return standardColumns
}
