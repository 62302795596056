import { graphql, fetchQuery } from 'react-relay/hooks'

export default async function fetchOrgBySlug(environment, slug) {
  const data = await fetchQuery(
    environment,
    graphql`
      query fetchOrgBySlugQuery($slug: String!) {
        organizationBySlug(slug: $slug) {
          id
          rowId
          name
          legalName
          slug
          createdAt
          trialStartedAt
          trialEndedAt
          organizationSubscriptions(includeDeleted: YES, orderBy: UPDATED_AT_DESC) {
            nodes {
              config
              billingPlan {
                rowId
                slug
                name
                price
                type
                interval
                stripePriceId
                config
              }
              updatedAt
              status
              endsAt
              deletedAt
            }
          }
          organizationCompanies(first: 1) {
            totalCount
            nodes {
              organization {
                id
              }
              company {
                id
              }
            }
          }
          activeCompanies: companies(includeDeleted: NO) {
            totalCount
          }
          organizationUsers {
            nodes {
              user {
                id
              }
              role
            }
          }
        }
      }
    `,
    { slug },
  ).toPromise()

  return data?.organizationBySlug
}
