import { lowerCase, camelCase } from 'lodash'
import { nameMap } from '../shared/constants'

const SEPARATOR_REGEX = /[^A-Za-z]+/g

export function toInitials(value: string | null): string {
  if (!value?.length) {
    return ''
  }

  let name = value

  // check for email address
  if (value.includes('@')) {
    // likely an email address, but could be something else
    // so handle an @ at the beginning too
    const emailParts = value.split('@').filter((p) => p.length)
    name = emailParts[0]
  }

  const nameParts = name.split(SEPARATOR_REGEX)

  return nameParts
    .filter((p) => p.length) // filter out any empty parts
    .map((p) => p.substr(0, 1)) // take first character of each good part
    .slice(0, 2) // get up to two initials
    .join('') // combine into string
    .toLocaleUpperCase() // convert to uppercase
}

export function toTitleCase(value: string): string {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via|with|from)$/i
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  const wordSeparators = /([ :–—-])/

  //separate camelCase into words
  const lower = lowerCase(value)

  return lower
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}

//capitalize ar, ap, cc
export function capitalizeAbbreviations(string: string): string {
  return string
    .replace(/^A[rp]\s/, (match) => match.toUpperCase())
    .replace(/\sA[rp]\s/g, (match) => match.toUpperCase())
    .replace(/^Cc\s/g, (match) => match.toUpperCase())
    .replace(/\sCc\s/g, (match) => match.toUpperCase())
    .replace(/(Id)(s)?/g, (match, p1, p2) => (p2 ? p1.toUpperCase().concat(p2) : p1.toUpperCase()))
}

export function formatName(name: string): string {
  const camelCaseName = camelCase(name)

  if (nameMap[camelCaseName]) {
    return nameMap[camelCaseName]
  }

  return capitalizeAbbreviations(toTitleCase(camelCaseName))
}

export function formatAmount(value: string | number, currencySign?: string): string {
  const amount = typeof value === 'string' ? parseFloat(value) : value

  let amountString = ''
  const sign = currencySign || ''

  if (amount >= 0) {
    amountString = amount.toLocaleString(undefined, { minimumFractionDigits: 2 })
  } else {
    amountString = `(${Math.abs(amount).toLocaleString(undefined, { minimumFractionDigits: 2 })})`
  }

  return `${sign}${amountString}`
}
