import React from 'react'
import Box from '../Box'

type Props = any

const FlexColumn = React.forwardRef((props: Props, ref) => (
  <Box display="flex" flexDirection="column" ref={ref} {...props} />
))

export default FlexColumn
