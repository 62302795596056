import { DateTime, DurationObjectUnits, Interval } from 'luxon'

type DateType = DateTime | Date | string
type ISODate = string

export function startOf(dt: DateTime, unit: keyof DurationObjectUnits): DateTime {
  if (unit === 'week') {
    // Have weeks start on Sunday
    return dt.startOf(unit).minus({ days: 1 })
  }

  return dt.startOf(unit)
}

export function endOf(dt: DateTime, unit: keyof DurationObjectUnits): DateTime {
  if (unit === 'week') {
    // Have weeks start on Sunday
    return dt.endOf(unit).minus({ days: 1 })
  }

  return dt.endOf(unit)
}

export function noonLocal() {
  return DateTime.local().set({
    hour: 12,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
}

export function calcDayInterval(dt1: DateTime, dt2: DateTime): number {
  const day1 = startOf(dt1, 'day')
  const day2 = startOf(dt2, 'day')

  return Interval.fromDateTimes(day1, day2).length('days')
}

export function calculateDaysSince(date: DateType): number {
  const today = DateTime.utc()
  const dt = toDateTime(date)

  return Math.round(today.diff(dt, 'days').days)
}

export function dateToLocale(date: string) {
  const dt = DateTime.fromISO(date)

  if (dt.isValid) {
    return dt.toLocaleString(DateTime.DATE_SHORT)
  }

  return date
}

export function toDateTime(value: DateType): DateTime {
  if (value instanceof DateTime) {
    return value
  }

  if (typeof value === 'string') {
    return DateTime.fromISO(value)
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value)
  }

  throw new Error(`Invalid date value [${value}]`)
}

export function toISODate(value: DateType): ISODate {
  return toDateTime(value).toISODate()
}

export function toISO(value: DateType, options?: { includeOffset: boolean }): ISODate {
  return toDateTime(value).toISO(options)
}
