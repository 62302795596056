import React, { useEffect, useRef, MouseEvent } from 'react'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'

type Props = {
  indeterminate?: boolean
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
  checked?: boolean
}

export default React.forwardRef((props: Props, ref: any) => {
  const { indeterminate } = props

  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <CheckBoxComponent ref={resolvedRef} {...props} />
})
