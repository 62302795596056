/**
 * @generated SignedSource<<ded07ec0c0c8c592eb6ee3a4d9a618d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowWorkspace_reportWorkflowWorkspace$data = {
  readonly key: string;
  readonly name: string | null;
  readonly reportWorkflowWorkspaceSteps: {
    readonly nodes: ReadonlyArray<{
      readonly name: string | null;
      readonly status: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"WorkspaceStep_reportWorkflowWorkspaceStep">;
    }>;
  };
  readonly report: {
    readonly rowId: any;
  } | null;
  readonly " $fragmentType": "WorkflowWorkspace_reportWorkflowWorkspace";
};
export type WorkflowWorkspace_reportWorkflowWorkspace$key = {
  readonly " $data"?: WorkflowWorkspace_reportWorkflowWorkspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkflowWorkspace_reportWorkflowWorkspace">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkflowWorkspace_reportWorkflowWorkspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "STEP_ORDER_ASC"
        }
      ],
      "concreteType": "ReportWorkflowWorkspaceStepsConnection",
      "kind": "LinkedField",
      "name": "reportWorkflowWorkspaceSteps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReportWorkflowWorkspaceStep",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorkspaceStep_reportWorkflowWorkspaceStep"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "reportWorkflowWorkspaceSteps(orderBy:\"STEP_ORDER_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Report",
      "kind": "LinkedField",
      "name": "report",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReportWorkflowWorkspace",
  "abstractKey": null
};
})();

(node as any).hash = "f23a974419d823f7e89474d9de0bfb8e";

export default node;
