import React from 'react'
import { graphql, useFragment } from 'react-relay/hooks'
import { FlexRow, Table as Tables, Text } from 'elements'
import PlaidHistoryContextMenu from './PlaidHistoryContextMenu'
import { formatReportPeriod } from '../../../report/utils/formatting'

const { Row, Cell } = Tables.Card

type Props = {
  plaidFile: any
}

export default function PlaidHistoryTableRow(props: Props) {
  const { plaidFile } = props

  const data = useFragment(
    graphql`
      fragment PlaidHistoryTableRow_userFile on UserFile {
        createdAt
        meta
      }
    `,
    plaidFile,
  )

  const { startDate, endDate } = data.meta

  return (
    <Row>
      <Cell>
        <FlexRow>
          <Text fontSize="large" lineHeight="large">
            {formatReportPeriod({ startDate, endDate })}
          </Text>
        </FlexRow>
      </Cell>
      <Cell>
        <FlexRow>
          <Text>{data.createdAt}</Text>
        </FlexRow>
      </Cell>
      <Cell>
        <FlexRow justifyContent="center">
          <PlaidHistoryContextMenu plaidFile={plaidFile} />
        </FlexRow>
      </Cell>
    </Row>
  )
}
