import { each } from 'lodash'

export function setQueryParams(url: string, params?: any): string {
  // parse URL
  const u = new URL(url)

  // set params
  each(params, (value, key) => u.searchParams.set(key, value))

  // stringify and return
  return u.toString()
}

export function unsetQueryParams(url: string, keys: string[]): string {
  // parse URL
  const u = new URL(url)

  // set params
  each(keys, (key) => u.searchParams.delete(key))

  // stringify and return
  return u.toString()
}

export function unsetSearchParams(search: string, keys: string[]): string {
  // parse URL
  const params = new URLSearchParams(search)

  // set params
  each(keys, (key) => params.delete(key))

  // stringify and return
  return params.toString()
}

export function setSearchParams(search: string, params: Record<string, string>): string {
  // parse URL
  const usp = new URLSearchParams(search)

  // set params
  each(params, (value, key) => usp.set(key, value))

  // stringify and return
  return usp.toString()
}

export function parseSearchParams(search: string): Record<string, string> {
  return Object.fromEntries(new URLSearchParams(search))
}

export function genBackUrl(url: string): string {
  return url.replace(/\/[^/]*\/?$/, '')
}
