/**
 * @generated SignedSource<<67b563e18d150d43324f157bcc986a3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchTemplateByNameQuery$variables = {
  orgSlug: string;
  templateName?: string | null;
};
export type fetchTemplateByNameQuery$data = {
  readonly organizationBySlug: {
    readonly workflowTemplatesWithDefaults: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    } | null;
  } | null;
};
export type fetchTemplateByNameQuery = {
  variables: fetchTemplateByNameQuery$variables;
  response: fetchTemplateByNameQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "orgSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "name",
          "variableName": "templateName"
        }
      ],
      "kind": "ObjectValue",
      "name": "condition"
    }
  ],
  "concreteType": "WorkflowTemplatesConnection",
  "kind": "LinkedField",
  "name": "workflowTemplatesWithDefaults",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowTemplate",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchTemplateByNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchTemplateByNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1fc1df0aa19f0d70432c9b8e0694209d",
    "id": null,
    "metadata": {},
    "name": "fetchTemplateByNameQuery",
    "operationKind": "query",
    "text": "query fetchTemplateByNameQuery(\n  $orgSlug: String!\n  $templateName: String\n) {\n  organizationBySlug(slug: $orgSlug) {\n    workflowTemplatesWithDefaults(condition: {name: $templateName}) {\n      nodes {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f06dd4fe637a333f753761ddcf467c2e";

export default node;
