import React, { useState } from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { FlexColumn, Text, Button, Message, Modal } from 'elements'
import ModalContent from 'components/app/ModalContent'
import ModalCloseButton from 'components/app/ModalCloseButton'
import { useAuth, useApi } from 'hooks'
import DataSources from './DataSources'

type Props = {
  onClose: Function
  integrationResult: any
  onBack: Function
  onNext: Function
  onKindSelect: Function
  company: any
}

type Kind = 'upload' | 'intuit' | 'xero'

export default function SelectDataSources(props: Props) {
  const { onClose, integrationResult, onBack, onNext, onKindSelect, company } = props
  const { currentOrg } = useAuth()
  const api = useApi()
  const [submitting, setSubmitting] = useState(false)

  const data = useFragment(
    graphql`
      fragment SelectDataSources_company on Company {
        rowId
        id
      }
    `,
    company,
  )

  const handleNext = (kind: Kind) => {
    setSubmitting(true)
    onKindSelect(kind)

    if (kind !== 'upload') {
      const connectUrl = api.integration.getConnectUrl(data.rowId, kind, {
        modal: 'first-client',
        modalBehavior: 'full',
        orgSlug: currentOrg!.slug,
        companyId: data.id,
      })

      window.location.href = connectUrl
    } else {
      onNext()
    }
  }

  return (
    <>
      <ModalContent pt="gigantic" px="gigantic" height="550px">
        <ModalCloseButton onClose={onClose} />
        <Text mb="xlarge">Step 2: Add a data source</Text>
        <FlexColumn mb="large">
          {integrationResult?.status === 'error' ? (
            <Message kind="danger" mb="xlarge">
              Your account could not be connected.
            </Message>
          ) : null}
          <DataSources handleNext={handleNext} />
        </FlexColumn>
      </ModalContent>
      {/* @ts-ignore */}
      <Modal.Footer display="flex" alignItems="center" width="100%" px="gigantic" pb="gigantic">
        <Button disabled={submitting} onPress={onBack} kind="alt-neutral">
          Back
        </Button>
        {/* @ts-ignore */}
      </Modal.Footer>
    </>
  )
}
