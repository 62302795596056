import React from 'react'
import { useFragment, graphql } from 'react-relay/hooks'
import { useDecision } from '@optimizely/react-sdk'
import { FlexColumn, FlexRow, Text } from 'elements'
import WorkspaceStep from './WorkspaceStep'
import { formatName } from 'utils/string'
import ContentHeader from '../../common/ContentHeader'

type Props = {
  workspace: any
  idx: number
}

export default function WorkflowWorkspace(props: Props) {
  const { workspace, idx } = props
  const canViewIds = useDecision('canViewIds')[0].enabled

  const data = useFragment(
    graphql`
      fragment WorkflowWorkspace_reportWorkflowWorkspace on ReportWorkflowWorkspace {
        key
        name
        reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
          nodes {
            name
            status
            ...WorkspaceStep_reportWorkflowWorkspaceStep
          }
        }
        report {
          rowId
        }
      }
    `,
    workspace,
  )

  const steps = data.reportWorkflowWorkspaceSteps.nodes

  const completedSteps = steps.filter((step: any) => step.status === 'complete')?.length
  const stepCompletion = `${completedSteps}/${steps.length}`

  return (
    <FlexColumn>
      <ContentHeader borderTop={idx === 0 ? false : true}>
        <Text fontWeight="bold" textTransform="uppercase">
          {data.name || formatName(data.key)}
        </Text>
        {canViewIds && idx === 0 && (
          <Text>
            <span style={{ fontWeight: 'bold' }}>Report Id: </span>
            {data.report.rowId}
          </Text>
        )}
        <Text color="secondary.0">{stepCompletion}</Text>
      </ContentHeader>
      <FlexRow mb="xxxlarge" flexWrap="wrap" gap="xlarge">
        {steps.map((step: any, idx: number) => (
          <FlexColumn key={step.name}>
            <WorkspaceStep step={step} />
          </FlexColumn>
        ))}
      </FlexRow>
    </FlexColumn>
  )
}
