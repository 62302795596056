import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { isFunction } from 'lodash'
import Box from '../Box'
import Text from '../Text'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const FieldLabel = ({ tooltip, ...props }) => {
  const [tooltipId] = useState(uuid())

  return (
    <Box display="flex" alignItems="center" mb="small">
      <Text display="inline-block" as="label" color="foreground.0" {...props} />
      {tooltip ? (
        <>
          <Icon
            ml="medium"
            size="xsmall"
            color="neutral.1"
            name="info-circle"
            data-tip
            data-for={tooltipId}
          />
          <Tooltip id={tooltipId} place="right">
            {isFunction(tooltip) ? tooltip() : tooltip}
          </Tooltip>
        </>
      ) : null}
    </Box>
  )
}

export default FieldLabel
