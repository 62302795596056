import React from 'react'
import { FormattedDate } from 'react-intl'
import Text from '../../Text'

export default function DateCell(props) {
  const { value } = props.cell

  let displayVal = ''

  if (!value || value === 'nan') {
    displayVal = '--'
  } else if (value === 'N/A') {
    displayVal = 'N/A'
  } else {
    // without setting the timezone, dates without time will be displayed as the previous day
    displayVal = <FormattedDate value={value} timeZone="utc" />
  }

  return (
    <Text fontSize="inherit" color="inherit">
      {displayVal}
    </Text>
  )
}
