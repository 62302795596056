import { DateTime } from 'luxon'

export const TRIAL_LENGTH_IN_DAYS = 14
export const TRIAL_NOTICE_THRESHOLD = 7
export const TRIAL_WARNING_THRESHOLD = 3

/**
 * Returns the number of days remaining in a user's free trial
 * @param createdAt
 * @returns {number}
 */
export function calculateTrialDaysRemaining(trialEnds) {
  const trialEnd = DateTime.fromISO(trialEnds)
  const today = DateTime.utc()

  // + 0 in case of -0
  return Math.ceil(trialEnd.diff(today, 'days').days) + 0
}
