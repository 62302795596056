import React from 'react'
import { Box, Button, Icon } from 'elements'

type Props = {
  onClose: Function
}

export default function ModalCloseButton(props: Props) {
  const { onClose } = props

  return (
    <Box position="absolute" top="xlarge" right="xxlarge">
      <Button kind="transparent" area="xxsmall" onPress={onClose}>
        <Icon name="x" color="neutral.2" />
      </Button>
    </Box>
  )
}
