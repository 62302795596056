import React, { useState } from 'react'
import { map } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useFileUpload, useAuth, useAnalytics } from 'hooks'
import useReportCreate from '../hooks/useReportCreate'
import useReportAddFile from '../hooks/useReportAddFile'
import useReportRun from '../hooks/useReportRun'
import useReportArchive from '../hooks/useReportArchive'
import FileUploadSelectStep from './FileUploadSelectStep'
import { ReportPeriod } from '../../../shared/types'

type Props = {
  modal: any
  onBack: Function
  uploadSource: string
  workflowTemplateId: string
  reportPeriod: ReportPeriod
  deleteReportId?: string
}

export default function FileUploadReport(props: Props) {
  const { modal, onBack, uploadSource, workflowTemplateId, reportPeriod, deleteReportId } = props

  const { currentOrg, currentCompany } = useAuth()
  const [files, setFiles] = useState([])
  const [fileKinds, setFileKinds] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [createReport]: any = useReportCreate()
  const [addReportFile]: any = useReportAddFile()
  const [runReport]: any = useReportRun()
  const [uploadFiles]: any = useFileUpload()
  const [archiveReport]: any = useReportArchive()
  const analytics = useAnalytics()
  const history = useHistory()

  const handleCreate = async () => {
    setSubmitting(true)

    const { report } = await createReport(
      {
        companyId: currentCompany!.id,
        kind: 'anomaly:1',
        workflowTemplateId,
        periodStart: reportPeriod.periodStart,
        periodEnd: reportPeriod.periodEnd,
      },
      { includeDeleted: 'NO' },
    )

    // build file descriptors
    const descriptors = map(fileKinds, (file, kind) => ({ kind, file }))

    // upload files
    const uploadResults = await uploadFiles(`reports/${report.rowId}/source`, descriptors)

    // create report inputs
    for (let i = 0; i < uploadResults.length; i++) {
      await addReportFile({
        type: 'SOURCE',
        reportId: report.rowId,
        userFileId: uploadResults[i].id,
      })
    }

    // // call the runReport lambda, but don't wait for it since
    // // we'll get updates via report subscription
    runReport({ reportId: report.id })

    analytics.track('Report Created', { reportId: report.rowId })
    modal.onClose({ force: true })

    history.push(`/orgs/${currentOrg!.slug}/companies/${currentCompany!.id}/reports/${report.id}`)

    if (deleteReportId) {
      archiveReport({ reportId: deleteReportId })
    }

    setSubmitting(false)
  }

  return (
    <FileUploadSelectStep
      onClose={modal.onClose}
      onBack={onBack}
      onDone={handleCreate}
      files={files}
      setFiles={setFiles}
      fileKinds={fileKinds}
      setFileKinds={setFileKinds}
      submitting={submitting}
      uploadSource={uploadSource}
    />
  )
}
