import React from 'react'
import { FormattedDate } from 'react-intl'
import {
  Text,
  Table as Tables,
  FlexColumn,
  Dropdown,
  LinkButton,
  Icon,
  Anchor,
  FlexRow,
  Button,
} from 'elements'
import { useAuth, useModals } from 'hooks'
import EmptyState from 'components/common/EmptyState'
import useRemoveInvite from './hooks/useRemoveInvite'
import InviteUsersModal from './InviteUsersModal'

const { Table, Head, HeadRow, HeadCell, Body, Row, Cell } = Tables.Card

export default function InvitationsTable({ orgInvites }) {
  const { userIsCurrentOrgAdmin } = useAuth()
  const [removeInvite] = useRemoveInvite()
  const { openModal } = useModals()

  const pendingInvites = orgInvites?.filter((invite) => invite?.status === 'PENDING')

  return (
    <FlexColumn>
      <FlexRow justifyContent="space-between" alignItems="center">
        <Text fontSize="xlarge" fontWeight="bold" mb="large">
          Pending Invitations
        </Text>
        <FlexColumn>
          {userIsCurrentOrgAdmin && (
            <Button onClick={() => openModal(InviteUsersModal, {}, 'full')}>+ New Member</Button>
          )}
        </FlexColumn>
      </FlexRow>
      <Table spacing="large">
        <Head>
          <HeadRow>
            <HeadCell>Email Address</HeadCell>
            <HeadCell>Intended Role</HeadCell>
            <HeadCell>Date Invited</HeadCell>
            <HeadCell width="100px" />
          </HeadRow>
        </Head>
        <Body>
          {pendingInvites?.length ? (
            pendingInvites.map((invite) => {
              //avoid error when deleting invitation
              if (!invite) {
                return null
              }

              return (
                <Row key={invite.id}>
                  <Cell>
                    <Text fontWeight="bold">{invite.emailAddress}</Text>
                  </Cell>
                  <Cell>
                    <Text color="neutral.0">{invite.intendedRole}</Text>
                  </Cell>
                  <Cell>
                    <Text color="neutral.0">
                      <FormattedDate value={invite.createdAt} />
                    </Text>
                  </Cell>
                  {userIsCurrentOrgAdmin ? (
                    <Cell>
                      <Dropdown
                        position="bottom right"
                        options={[
                          {
                            label: 'Cancel Invitation',
                            onSelect: () => {
                              if (
                                window.confirm('Are you sure you want to cancel this invitation?')
                              ) {
                                removeInvite({ id: invite.id })
                              }
                            },
                            buttonKind: 'danger',
                          },
                        ]}
                        renderButton={() => (
                          <LinkButton kind="transparent">
                            <Icon name="dots-horizontal" color="neutral.2" />
                          </LinkButton>
                        )}
                      />
                    </Cell>
                  ) : (
                    <Cell />
                  )}
                </Row>
              )
            })
          ) : (
            <Row>
              <Cell colSpan={4}>
                <FlexColumn p="large">
                  <EmptyState
                    message="No pending invitations."
                    fontSize="xlarge"
                    action={
                      userIsCurrentOrgAdmin ? (
                        <Text color="neutral.0">
                          <Anchor onClick={() => openModal(InviteUsersModal, {}, 'full')}>
                            Invite People
                          </Anchor>
                        </Text>
                      ) : null
                    }
                  />
                </FlexColumn>
              </Cell>
            </Row>
          )}
        </Body>
      </Table>
    </FlexColumn>
  )
}
