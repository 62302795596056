import React from 'react'
import FlexRow from '../../FlexRow'
import SeverityCircle from 'components/common/SeverityCircle'

export default function SeverityCell(props) {
  const { cell } = props

  return (
    <FlexRow justifyContent="center">
      <SeverityCircle severity={cell.value} showAbbreviation={false} />
    </FlexRow>
  )
}
