import React from 'react'
import SubscriptionBanner from './banners/SubscriptionBanner'
import { FlexColumn } from 'elements'

export default function AppBanners(props) {
  return (
    <FlexColumn>
      <SubscriptionBanner />
    </FlexColumn>
  )
}
