import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'
import {
  Card,
  FlexRow,
  FlexColumn,
  Text,
  List,
  ListItem,
  Box,
  Button,
  Table as Tables,
  TableConnected,
  RouterLink,
  DonutChart,
  StackedBarChart,
  BarLineAreaChart,
} from 'elements'
import { DataSection } from '../../types'
import EmptyState from '../../../common/EmptyState'

type Props = {
  dataSection: DataSection
  reportSectionLabel?: string
}

const DEFAULT_EMPTY_MESSAGE = 'No data to display.'

// set chart container box props based on chart type
const chartBoxProps: Map<Function, Record<string, any>> = new Map()

chartBoxProps.set(DonutChart, { alignItems: 'center' })
chartBoxProps.set(StackedBarChart, { py: 'medium' })
chartBoxProps.set(BarLineAreaChart, { py: 'medium' })

export default function InsightCard(props: Props) {
  const { dataSection, reportSectionLabel } = props

  const {
    title,
    insights,
    description,
    detailRoute,
    Chart,
    data,
    table,
    key,
    chartOptions,
    columns,
    gridColumn = '1/3',
  } = dataSection
  const history = useHistory()
  const location = useLocation()

  const tableOptions = { ...(dataSection.tableOptions || {}) }

  if (!tableOptions.emptyState) {
    tableOptions.emptyState = () => (
      <EmptyState message={tableOptions?.emptyMessage || DEFAULT_EMPTY_MESSAGE} />
    )
  }

  // table config (select columns) defaults to true for non-preview tables
  if (isNil(tableOptions.includeConfig) && !tableOptions.preview) {
    tableOptions.includeConfig = true
  }

  const Table = (
    <TableConnected
      table={tableOptions?.kind || Tables.Data}
      columns={columns}
      data={data}
      tableOptions={tableOptions}
    />
  )

  const tableOnly = !insights && !description && table

  const chartSpan = !insights && !description && gridColumn === '1/3' ? 'full' : 'semi'

  // Some tables, like Anomaly subreports, haves titles atteched to them.
  if (tableOnly) {
    if (title) {
      return (
        <FlexRow width="100%">
          <FlexColumn>
            <Text fontSize="xxlarge" fontWeight="bold" py="large">
              {title}
            </Text>
            {Table}
          </FlexColumn>
        </FlexRow>
      )
    }
    return Table
  }

  return (
    <Card
      px="xlarge"
      width="100%"
      boxShadow="none"
      border="thinSolid"
      minHeight="305px"
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexRow width="100%" height="100%">
        {(title || insights || description) && (
          <FlexColumn flex="1" py="xxlarge" pr="xlarge">
            <Text fontSize="xxlarge" fontWeight="bold" lineHeight="xxlarge">
              {title}
            </Text>
            {insights && (
              <List ordered={false} mt="xlarge">
                {insights?.map((insight, idx) => {
                  return (
                    <ListItem
                      mb="large"
                      ml="xsmall"
                      lineHeight="large"
                      key={key + 'description' + idx}
                    >
                      {insight}
                    </ListItem>
                  )
                })}
              </List>
            )}
            {description && (
              <Text lineHeight="large" mt="xlarge">
                <span style={{ fontWeight: 'bold' }}>{reportSectionLabel} </span>
                {description}
              </Text>
            )}
            <Box>
              {detailRoute && (
                <Button
                  onClick={() => history.push(`${location.pathname}/${detailRoute}`)}
                  borderRadius="small"
                  kind="alt-neutral-2"
                  borderWidth="xthin"
                  area="large"
                  fontWeight="normal"
                >
                  View Details
                </Button>
              )}
            </Box>
          </FlexColumn>
        )}
        {Chart && (
          <FlexColumn
            flex="1"
            justifyContent="center"
            {...chartBoxProps.get(Chart)}
            {...chartOptions?.styleProps?.boxProps}
          >
            {data?.length ? (
              <Chart
                series={data}
                options={chartOptions?.options}
                width={chartOptions?.width}
                height={chartOptions?.height}
                chartSpan={chartSpan}
                legendTitle={chartOptions?.legendTitle}
                titleOffsetX={chartOptions?.titleOffsetX}
                legendWidth={chartOptions?.legendWidth}
                legendOffsetY={chartOptions?.legendOffsetY}
              />
            ) : (
              <EmptyState message="No data to display." />
            )}
          </FlexColumn>
        )}
        {table && (
          <FlexColumn
            flex="1"
            my="xxlarge"
            alignItems="center"
            {...tableOptions?.styleProps?.boxProps}
          >
            <Box mb={tableOptions?.preview ? 'large' : null} border="thinSolid">
              {Table}
            </Box>
            {tableOptions?.preview && (
              <RouterLink to={`${location.pathname}/${detailRoute}`} color="secondary.0">
                Click to see expanded table
              </RouterLink>
            )}
          </FlexColumn>
        )}
      </FlexRow>
    </Card>
  )
}
