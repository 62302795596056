import React from 'react'
import { useHistory } from 'react-router-dom'
import { graphql, useFragment } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { sumBy } from 'lodash'
import { useAuth } from 'hooks'
import { FlexRow, Icon, Progress, Table as Tables, Text } from 'elements'
import CompanyContextMenu from './CompanyContextMenu'
import { formatReportPeriod, formatDate } from '../../report/utils/formatting'

const { Row, Cell } = Tables.Card

export default function CompaniesTableRow({ company, disabled, companiesOrder }) {
  const { userIsCurrentOrgAdmin } = useAuth()
  const { orgSlug } = useParams()
  const history = useHistory()

  const rowCompany = useFragment(
    graphql`
      fragment CompaniesTableRow_company on Company {
        id
        rowId
        name
        legalName
        createdAt
        deletedAt
        reports {
          totalCount
          nodes {
            workflowSummary
          }
        }
        latestReports: reports(orderBy: CREATED_AT_DESC) {
          nodes {
            periodStart
            periodEnd
          }
        }
      }
    `,
    company,
  )

  const companyPath = `/orgs/${orgSlug}/companies/${rowCompany.id}`
  const isArchived = !!rowCompany.deletedAt

  const rowProps = {
    onClick: !disabled && !isArchived && (() => history.push(companyPath)),
  }

  const cellProps = {
    kind: isArchived || disabled ? 'inactive' : undefined,
  }

  const activeWorkflows = rowCompany.reports.nodes.filter(
    (report) => report.workflowSummary.completion < 100,
  )

  let companyCompletion = 0

  if (activeWorkflows.length) {
    companyCompletion = Math.floor(
      sumBy(activeWorkflows, (report) => report.workflowSummary.completion) /
        activeWorkflows.length,
    )
  }

  const latestReport = rowCompany.latestReports?.nodes?.[0]

  return (
    <Row {...rowProps}>
      <Cell {...cellProps}>
        <FlexRow alignItems="center">
          <Text
            fontSize="large"
            fontWeight="bold"
            truncate
            title={rowCompany.name}
            lineHeight="large"
          >
            {rowCompany.name}
          </Text>
        </FlexRow>
      </Cell>
      <Cell {...cellProps}>
        <FlexRow>
          <Text fontWeight={activeWorkflows.length && 'bold'} mr="xsmall">
            {activeWorkflows.length || 'No'}
          </Text>
          <Text color={activeWorkflows.length ? 'neutral.0' : 'foreground.0'}>
            Active Workflows
          </Text>
        </FlexRow>
      </Cell>
      <Cell {...cellProps}>
        {activeWorkflows.length ? (
          <FlexRow alignItems="center">
            <FlexRow mr="small" width="100%">
              <Progress progress={companyCompletion} />
            </FlexRow>
            <Text>{companyCompletion}%</Text>
          </FlexRow>
        ) : (
          <FlexRow justifyContent="center">
            <Text>--</Text>
          </FlexRow>
        )}
      </Cell>
      <Cell {...cellProps}>
        <Text color="neutral.0">
          {latestReport &&
            formatReportPeriod({
              startDate: latestReport.periodStart,
              endDate: latestReport.periodEnd,
            })}
        </Text>
      </Cell>
      <Cell {...cellProps}>
        <Text color="neutral.0">{formatDate(rowCompany.createdAt)}</Text>
      </Cell>
      <Cell {...cellProps}>
        <FlexRow justifyContent="center">
          {userIsCurrentOrgAdmin ? (
            <CompanyContextMenu company={company} companiesOrder={companiesOrder} />
          ) : (
            <Icon name="angle-right" size="large" color="neutral.9" />
          )}
        </FlexRow>
      </Cell>
    </Row>
  )
}
