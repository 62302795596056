import { ceil, round } from 'lodash'

/**
 * Rounds a number to the nearest value appropriate
 * for use in the axis of a graph
 */
export function roundToNearest(v: number): number {
  const pos = Math.abs(v)
  const rounded = round(pos)
  const len = rounded.toString().length
  const ten = parseInt(`1${'0'.repeat(len)}`, 10)
  const nearest = ceil(pos / ten, 1) * ten

  return v < 0 ? -nearest : nearest
}
