import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { filterProps } from '../utils'

const hover = ({ interactive, onClick }) =>
  interactive || onClick
    ? css`3px solid ${themeGet('colors.secondary.0')};`
    : '3px solid transparent'

const CardTableRow = filterProps(styled.tr)`
  display: table-row;
  vertical-align: middle;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.02), 0px 6px 10px rgba(0, 0, 0, 0.03);

  ${({ interactive, onClick }) => (interactive || onClick ? 'cursor: pointer' : null)};

  & td {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;

    &:first-child {
      border-left: 3px solid transparent;
    }

    &:last-child {
      border-right: 3px solid transparent;
    }
  }

  &:hover td {
    border-top: ${hover};
    border-bottom: ${hover};

    &:first-child {
      border-left: ${hover};
    }

    &:last-child {
      border-right: ${hover};
    }
  }
`

export default CardTableRow
