/**
 * @generated SignedSource<<e554d9d6b4b52f84390ffb6bb62644b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkspaceStep_reportWorkflowWorkspaceStep$data = {
  readonly id: string;
  readonly key: string;
  readonly name: string | null;
  readonly stepOrder: number | null;
  readonly sections: any | null;
  readonly status: string | null;
  readonly description: string | null;
  readonly " $fragmentType": "WorkspaceStep_reportWorkflowWorkspaceStep";
};
export type WorkspaceStep_reportWorkflowWorkspaceStep$key = {
  readonly " $data"?: WorkspaceStep_reportWorkflowWorkspaceStep$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkspaceStep_reportWorkflowWorkspaceStep">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkspaceStep_reportWorkflowWorkspaceStep",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stepOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sections",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "ReportWorkflowWorkspaceStep",
  "abstractKey": null
};

(node as any).hash = "2d69cf22e499e0ba73b9c1c54743df2f";

export default node;
