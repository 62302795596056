import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay/hooks'
import ActiveWorkflowDashboard from './ActiveWorkflowDashboard'

export default function CompanyContainer() {
  const { companyId } = useParams()
  const [includeDeleted, setIncludeDeleted] = useState(false)

  const data = useLazyLoadQuery(
    graphql`
      query CompanyContainerQuery($companyId: ID!, $includeDeleted: IncludeDeletedOption) {
        companyById(id: $companyId) {
          ...ActiveWorkflowDashboard_company
          ...ActiveWorkflowTable_company
          ...CompletedWorkflowTable_company
        }
      }
    `,
    { companyId, includeDeleted: includeDeleted ? 'YES' : 'NO' },
  )

  if (!data) {
    return null
  }

  return (
    <ActiveWorkflowDashboard
      company={data.companyById}
      includeDeleted={includeDeleted}
      onArchiveToggle={() => setIncludeDeleted(!includeDeleted)}
    />
  )
}
