import React, { useEffect } from 'react'
import { useLazyLoadQuery, graphql } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { flatten } from 'lodash'
import PageBody from 'components/app/PageBody'
import WorkflowHeader from 'components/report/view/WorkflowHeader'
import ReportContainer from 'components/report/view/ReportContainer'
import { usePage } from 'hooks'

const KEY_RESOLUTION_SECTIONS = {
  flaggedTransactions: 'forReview',
  noIssue: 'reviewed',
  repaired: 'resolved',
}

export default function ReportPage() {
  const { reportId, stepId = '' } = useParams()
  const { pageProps, setPageProps } = usePage()
  const { resolutionSection } = pageProps

  const data = useLazyLoadQuery(
    graphql`
      query ReportPageQuery(
        $reportId: ID!
        $resolutionSection: String = "forReview"
        $anomalies: [String] = null
      ) {
        reportById(id: $reportId) {
          ...WorkspaceHeader_report
          ...ReportContainer_report
          reportWorkflowWorkspaces(orderBy: WORKSPACE_ORDER_ASC) {
            nodes {
              reportWorkflowWorkspaceSteps(orderBy: STEP_ORDER_ASC) {
                nodes {
                  id
                  key
                  ...StepHeader_reportWorkflowWorkspaceStep
                  ...ReportStep_reportWorkflowWorkspaceStep
                }
              }
            }
          }
        }
      }
    `,
    { reportId, resolutionSection },
  )

  const report = data?.reportById

  const flatSteps = flatten(
    data?.reportById?.reportWorkflowWorkspaces?.nodes?.map(
      (workspace) => workspace.reportWorkflowWorkspaceSteps.nodes,
    ),
  )

  const currentStep = flatSteps.find((step) => step.id === stepId)

  useEffect(() => {
    if (currentStep && Object.keys(KEY_RESOLUTION_SECTIONS).includes(currentStep.key)) {
      setPageProps({ resolutionSection: KEY_RESOLUTION_SECTIONS[currentStep.key] })
    }
  }, [currentStep, setPageProps])

  if (!data) {
    return null
  }

  return (
    <PageBody
      renderHeader={() => <WorkflowHeader step={currentStep} report={report} />}
      renderContent={() => <ReportContainer report={report} step={currentStep} />}
    />
  )
}
