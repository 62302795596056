import { createGlobalStyle } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import reactDatePicker from './css/react-datepicker'

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`

  html {
    font-size: 16px;
  }

  html, body, #root {
    height: 100%;
  }

  html, body {
    margin: 0;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  body {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin: 0;
    font-family: Lato, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    color: ${themeGet('colors.foreground.0')};
  }

  a {
    text-decoration: none;
  }

  .elements-icon svg {
    pointer-events: none;
  }

  /* don't allow body to scroll while modal is open */
  body.ReactModal__Body--open {
    overflow: hidden;
  }

  /**
    ReactModal
   */

  .ReactModalPortal > * {
    opacity: 0;
  }
  .ReactModal__Overlay {
    transition: opacity 200ms ease;
    &--after-open {
        opacity: 1;
    }
    &--before-close {
        opacity: 0;
    }

    @media print {
      position: static !important;
    }
  }

  .ReactModal__Content {
    @media print {
      position: static !important;
      max-height: 100% !important;
      box-shadow: none !important;
    }
  }

  /* Disable arrows for number inputs */
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ${reactDatePicker}
`

export default GlobalStyle
